import React from 'react';
import ReactDOM from "react-dom";
import moment from 'moment';

type RowParams = {
	data: Object;
    onClick: Function;
};

const CsUserRow = (props:RowParams) => {

    const onClicked = ()=>{
        props.onClick(props.data);
    }

    let lastOnLineDate = "-";
    if (props.data.LastLogin){
        lastOnLineDate = moment(props.data.LastLogin).format("MM/DD/YYYY hh:mm A");
    }

    // let deviceStatusIcon = "img/icon_device_inactive.png";
    // if (props.data.LastLogin){
    //     deviceStatusIcon = "img/icon_device_active.png";
    // }
    let superUserIcon = "";
    if (props.data.SuperUser){
        superUserIcon = "Y";
    } 
    
    return(
        <tr key={"classRow" + props.data.ID} class="nucleus-row " onClick={onClicked}>
            <td > <span>{props.data.FirstName + " " + props.data.LastName }</span></td>
            <td > <span>{ props.data.Email }</span></td>
            <td > <span>{ props.data.Telephone }</span></td>
            <td > <span>{ lastOnLineDate}</span></td>
            <td class="center-align">
                {/* <img class=" " src={deviceStatusIcon} />*/}
                <span>{ superUserIcon}</span>
            </td>
            <td class="center-align">
                {/* <img class=" " src={deviceStatusIcon} />*/}
                <span>{ props.data.Groups}</span>
            </td> 
        </tr>
    );
}

export default CsUserRow;
