import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

interface NucleusBaseContainerProps {
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

export const NucleusBaseContainer: React.FC<NucleusBaseContainerProps> = ({ children, style }) => {
    return (
        <Container style={style}>
            {children}
        </Container>
    );
};

export default NucleusBaseContainer;
