import React from 'react';

type appProps = {
	data: Object;
    onToggle: Function;
    mdmAccountApps: Boolean;
};

const AccountExternalAppTableRow = (props:appProps) => {
    
    const onClicked = (e)=>{
        console.log("onClicked", e.target.checked);
        let params = Object.assign({}, props.data);
        params.enabled = e.target.checked;
        props.onToggle(params);
        //setStateChecked(e.target.checked);
    }

    //const [stateChecked, setStateChecked] = useState(props.data.Active != null);
    const url = props.data.IconUrl;

    return(
        <tr  >
                <td class="" style={{display:'flex'}}>
                    {   (props.data.IconUrl) &&
                        <img
                            src={
                                props.data.IconUrl
                                //? props.data.IconUrl
                                //: "img/user_placeholder.png"
                            }
                            style={{
                                width: 50,
                                height: 50,
                                marginRight: 20,
                                marginLeft: 0,
                                borderRadius: 5,
                                objectFit: "cover",
                            }}
                        />
                    }
                    <span style={{ alignSelf: 'center'}}>
                        {props.data.Name}
                    </span>  
                </td>
                <td class="center-align" style={{textAlign:'start'}}>
                {
                    (!props.mdmAccountApps) &&
                    <div class="switch" style={{marginLeft:34}}>
                        <label>
                            <input type="checkbox" checked={props.data.Enabled != null} onChange={onClicked}/>
                            <span class="lever no-margin"></span>
                        </label>
                    </div>
                }
                </td>
            </tr>
    );
}

export default AccountExternalAppTableRow;
