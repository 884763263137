import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import AuthStore from "../stores/AuthStore";
import CareUserStore from "../stores/CareUserStore";
import CareAccountStore from "../stores/CareAccountStore";
import GeneralStore from "../stores/GeneralStore";
import AccountUserProfileEdit from "../components/AccountUserProfileEdit";
import Message from "../utils/Message";

class AccountUserProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            accountID : '',
            currentMode :  "Edit",
            linkModeLabel : "Cancel",
            userId : '',
            userFirstName : '',
            userLastName : '',
            userFullName : '',
            userEmail : '',
            userTelephone : '',
            userAddress : '',
            userCreated : '',
            userIsAdmin : false,
            userIsOnDuty: false,
            userAllPatientsAccess : false,
            userCanSendBroadcastMessage : false,
            userCanReceiveAlerts : false,
            userStaffAccess : false,
            userClientsAccess : false,
            userGroupsAccess : false,
            userQueueAccess : false,
            userMessagesAccess: false,
            userProfileCompleted : false,
            userBulletinBoardAccess : false,
            canImportClients: false,
            canRestartDevices: false,
            canDeletePatients: false,
            userImageUrl : 'img/user_placeholder.png',
            twoAuth: false,
        };

        this.onGetAccountUsersAction = this.onGetAccountUsersAction.bind(this);
        this.initData = this.initData.bind(this);

        this.handleProfileMode = this.handleProfileMode.bind(this);

        this.onAccountUserUpdatedAction = this.onAccountUserUpdatedAction.bind(this);

        this.handleRemoveUserModal = this.handleRemoveUserModal.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
        this.onAccountUserDeletedAction = this.onAccountUserDeletedAction.bind(this);

    }


    componentDidMount() {
        //Register Listener
        CareAccountStore.on("onGetAccountUsers", this.onGetAccountUsersAction);
        CareAccountStore.on("onAccountUserUpdated", this.onAccountUserUpdatedAction);
        CareAccountStore.on("onAccountUserDeleted", this.onAccountUserDeletedAction);
        CareAccountStore.on("onGetAccountData", this.onGetAccountDataAction);
        const {match} = this.props;

        console.log("UserProfile Current UserID", match.params.userId);
        console.log("AccountID", match.params.accountId);

        if (match.params.accountId != null){
            this.setState({
                accountID : match.params.accountId
            });
        }

        if (match.params.userId != null){
            //Get the User Data from the Store
            this.initData();
            this.setState({
                userId : match.params.userId
            });
        }else{
            //this.props.history.push("/users");
            GeneralStore.requestHandleBack();
        }

        window.$('.modal').modal({
              dismissible: true,
              complete: function() {  } // Callback for Modal close
            }
        );
        window.$('.tooltipped').tooltip({delay: 50});
        this.getAccountInfo()
    }

    getAccountInfo = async() => {
        const {match} = this.props;
        CareAccountStore.getAccountData({UserID : AuthStore.getCsUserID(), Token : AuthStore.getCsUserToken(), AccountID : match.params.accountId})
        //this.setState({twoAuth: accountData.TwoFactorAuth})
    }

    onGetAccountDataAction = (response) => {
		if (response && response.ok) {
            this.setState({twoAuth: response.TwoFactorAuth})
		} else {
			Message.show("Sorry, There was a problem getting the account information. Please try again.");
		}
	}

    componentWillUnmount() {
        //Remove Listener
        CareAccountStore.removeListener("onGetAccountUsers", this.onGetAccountUsersAction);
        CareAccountStore.removeListener("onAccountUserUpdated", this.onAccountUserUpdatedAction);
        CareAccountStore.removeListener("onAccountUserDeleted", this.onAccountUserDeletedAction);
        CareAccountStore.removeListener("onGetAccountData",this.onGetAccountDataAction);
        window.$('.tooltipped').tooltip('remove');
    }

    initData(){

        const {match} = this.props;
        let userData = CareAccountStore.getUserInfo(match.params.userId);
        if (userData != null){
            this.setState({
                userId : userData.UserID,
                userFirstName : userData.FirstName,
                userLastName : userData.LastName,
                userFullName : userData.FirstName + " " + userData.LastName,
                userEmail : userData.Email,
                userTelephone : userData.Telephone,
                userAddress : userData.Address,
                userCreated : userData.Created,
                userIsAdmin : userData.IsAdmin,
                userCanReceiveAlerts : userData.ReceiveEscalationCallRequest,
                userStaffAccess : userData.CanSeeStaffTab,
                userClientsAccess : userData.CanSeeClientsTab,
                userGroupsAccess : userData.CanAccessGroups,
                userQueueAccess : userData.CanSeeQueueTab,
                userMessagesAccess: userData.CanSeeMessageCenterTab,
                userAlertsAccess: userData.CanSeeAlertsTab,
                userPicturesAccess : userData.CanSeeMemoryBox,
                userBulletinBoardAccess : userData.CanSeeBulletinBoard,
                userCanSendBroadcastMessage : userData.CanSendMessageToAllPatients,
                userIsOnDuty: userData.IsOnDuty,
                accessDevices: userData.CanSeeDevicesTab,
                canDeletePatients: userData.CanDeletePatients,
                canDeleteDevices: userData.CanDeleteDevices,
                bargeInCallEnabled: userData.BargeInCall,
                canRestartDevices: userData.CanRestartDevices,
                userImageUrl: userData.UserImageThumb,
                accountEnableImportClients: userData.EnableImportClients,
                canImportClients: userData.CanImportClients,
            });
        } else {
            CareAccountStore.getAccountUsers({
                UserID : AuthStore.getCsUserID(),
                Token : AuthStore.getCsUserToken(),
                AccountID : match.params.accountId
            });
        }
    }

    onGetAccountUsersAction(response){
        console.log("onGetAccountUsersAction", response);
        if (response.ok){
            this.initData();
        }else{
            Message.show("Error getting user Information");
        }
    }

    onAccountUserUpdatedAction(response){

        console.log("onAccountUserUpdatedAction", response);
        console.log(response);
        //alert("Llego aqui");

        if (response.ok){

            Message.show("User successfully updated");
            //this.onGetAccountUsersAction(true);

            CareAccountStore.getAccountUsers({
                UserID : AuthStore.getCsUserID(),
                Token : AuthStore.getCsUserToken(),
                AccountID : this.state.accountID
            });

            GeneralStore.requestHandleBack();
        }
    }

    handleRemoveUserModal(){
        window.$('#modalDeleteUser').modal('open');
    }

    handleCancelDelete(){
      window.$('#modalDeleteUser').modal('close');
    }

    handleDeleteUser(){

        CareAccountStore.deleteAccountUser({
            UserID : this.state.userId
        });
    }

    onAccountUserDeletedAction(response){
        console.log("onAccountUserDeletedAction", response);

        window.$('#modalDeleteUser').modal('close');

        if (response.ok){
            Message.show("User successfully deleted");
            //this.onGetAccountUsersAction(true);

            CareAccountStore.getAccountUsers({
                UserID : AuthStore.getCsUserID(),
                Token : AuthStore.getCsUserToken(),
                AccountID : this.state.accountID
            });

            GeneralStore.requestHandleBack();
        } else {

            Message.show("Sorry, There was a problem deleting the user. Please try again.");
        }
    }

    handleProfileMode(){

        if ( this.state.currentMode == "Info"){
            this.setState({
                currentMode :  "Edit",
                linkModeLabel : "Cancel"
            });
        } else {

            GeneralStore.requestHandleBack();
            // Modified for this CS section
            // this.setState({
            //     currentMode :  "Info",
            //     linkModeLabel : "Edit"
            // });
        }
    }


    render() {

        console.log("RENDER USER PROFILE ", this.state.userCreated);
        let removeVisibility = ( this.state.currentMode == "Edit" ) ? " " :  " hide";

        return (
            <div class="nucleus-hard-container">
            <div class="nucleus-all-scrollable-page">
                <br/>
                <span class="nucleus-page-title">Edit Account User</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a class="nucleus-font nucleus-font-small nucleus-link" onClick={this.handleProfileMode}> {this.state.linkModeLabel}</a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a class={"nucleus-font nucleus-font-small nucleus-link" + removeVisibility } onClick={this.handleRemoveUserModal}>
                    <span class="txt-red">Remove</span>
                </a>

                <br/><br/>
                <div class="row">

                    { /*
                    <UserProfileInfo
                        currentMode={this.state.currentMode}
                        userId={this.state.userID}
                        userFullName={this.state.userFullName}
                        userEmail={this.state.userEmail}
                        userTelephone={this.state.userTelephone}
                        userAddress={this.state.userAddress}
                        userImageUrl={this.state.userImageUrl}
                        userIsAdmin={this.state.userIsAdmin}
                        userIsOnDuty={this.state.userIsOnDuty}
                        userProfileCompleted={this.state.userProfileCompleted}
                        userAllPatientsAccess={this.state.userAllPatientsAccess}
                        userCreated={this.state.userCreated}
                        adminAccess={this.state.adminAccess}
                    />
                    */}

                    <AccountUserProfileEdit
                        currentMode={this.state.currentMode}
                        userId={this.state.userId}
                        userFirstName={this.state.userFirstName}
                        userLastName={this.state.userLastName}
                        userEmail={this.state.userEmail}
                        userTelephone={this.state.userTelephone}
                        userAddress={this.state.userAddress}
                        userIsAdmin={this.state.userIsAdmin}
                        userIsOnDuty={this.state.userIsOnDuty}
                        userCanSendBroadcastMessage={this.state.userCanSendBroadcastMessage}
                        userCanReceiveAlerts={this.state.userCanReceiveAlerts}
                        userStaffAccess={this.state.userStaffAccess}
                        userClientsAccess={this.state.userClientsAccess}
                        userGroupsAccess={this.state.userGroupsAccess}
                        userQueueAccess={this.state.userQueueAccess}
                        userMessagesAccess={this.state.userMessagesAccess}
                        userAlertsAccess={this.state.userAlertsAccess}
                        userPicturesAccess={this.state.userPicturesAccess}
                        userBulletinBoardAccess={this.state.userBulletinBoardAccess}
                        devicesAccess={this.state.accessDevices}
                        canDeletePatients={this.state.canDeletePatients}
                        canDeleteDevices={this.state.canDeleteDevices}
                        bargeInCallEnabled={this.state.bargeInCallEnabled}
                        canRestartDevices={this.state.canRestartDevices}
                        canImportClients={this.state.canImportClients}
                        twoAuth={this.state.twoAuth}
                    />

                    <div id="modalDeleteUser" class="modal modalDeleteUser">
                        <div class="modal-content modal-content-delete center-align">
                            <h3 class="nucleus-page-subtitle"> Delete Account User</h3>
                            <p></p>
                            <div class="row">
                                <div class="col s12 center-align nucleus-font-medium">
                                  Are you sure you want to delete '{ this.state.userFullName }' user from the account?
                                  <br/>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col s12 center-align ">
                                    <p></p>
                                    <a class="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelDelete}>Cancel</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a class="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleDeleteUser}>Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default AccountUserProfile;
