import { EventEmitter } from "events";
//import Dispatcher from "../Dispatcher";
//import Config from "../Config";
import UserApi from "../apis/UserApi";
 
class CareUserStore extends EventEmitter {
	constructor() {
		super();

		this.UserApi = new UserApi(this);
		this.UsersList = localStorage.UsersList;
		this.UserName = localStorage.UserName || "Coordinator";
		this.UserAllPatientsAccess = localStorage.UserAllPatientsAccess || false;
		//console.warn(this);
	}

	getAllUsers(){
		return this.UsersList;
	}

	setCurrentUserName(userName){
		this.UserName = userName;
		this.saveToLocalStorage();
	}
	getCurrentUserName(){
		return this.UserName;
	}

	setUserAllPatientsAccess(access){
		this.UserAllPatientsAccess = access;
		this.saveToLocalStorage();
	}
	getUserAllPatientsAccess(){
		console.log("getUserAllPatientsAccess ", this.UserAllPatientsAccess);
		return this.UserAllPatientsAccess;
	}

	

	getUserData ({ UserID }) {
		console.log("getUserData ", UserID );

		this.UserApi.GetCareUserData({ UserID },(rsp) => {

			console.log("getUserData Response");
			console.log(rsp);
			if (rsp.ok){
				this.CareUserID = UserID;
			}
			this.emit("onUserGetData", rsp);
		});
	}

	UpdateUserData ({UserID , FirstName, LastName, Email, Telephone, Address, Password, IsAdmin, IsOnDuty}) {
		console.log("UpdateUserData ", UserID , FirstName, LastName, Email, Telephone, Address, Password, IsAdmin, IsOnDuty);

		this.UserApi.UpdateCareUserData({UserID , FirstName, LastName, Email, Telephone, Address, Password, IsAdmin, IsOnDuty},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onUserUpdateData", rsp);
		});
	}


	setUserOnDuty({ UserID, IsOnDuty}) {
		console.log("setUserOnDuty ", UserID, IsOnDuty);

		this.UserApi.setUserOnDuty({ UserID, IsOnDuty },(rsp) => {

			console.log("setUserOnDuty Response");
			console.log(rsp);

			//*-
			this.emit("onSetUserOnDuty", {
	      		ok : rsp.ok,
	      		onDuty : rsp.IsOnDuty,
	      		userId : UserID
	      	});
		});
	}

	setUserIsAdmin({ UserID, IsAdmin}) {
		console.log("setUserIsAdmin ", UserID, IsAdmin);

		this.UserApi.setUserIsAdmin({ UserID, IsAdmin },(rsp) => {

			// console.log("setUserIsAdmin Response");
			// console.log(rsp);
			
			this.emit("onSetUserIsAdminAction", {
	      		ok : rsp.ok,
	      		isAdmin : rsp.IsAdmin,
	      		userId : UserID
	      	});
		});
	}

	setSeeAllPatients({ UserID, CanSee}) {
		console.log("setSeeAllPatients ", UserID, CanSee);

		this.UserApi.SetSeeAllPatients({ UserID, CanSee },(rsp) => {

			console.log("setSeeAllPatients Response");
			console.log(rsp);

			//*-
			this.emit("onSetSeeAllPatients", {
	      		ok : rsp.ok,
	      		canSee : rsp.canSee,
	      		userId : UserID
	      	});
		});
	}

	getAllUsersData ({ UserID }) {
		console.log("getAllUsersData ", UserID );

		this.UserApi.getAllUsersData({ UserID },(rsp) => {

			console.log("getAllUsersData Response");
			console.log(rsp);

			if (rsp.ok){
				
				this.UsersList = rsp.users;
				this.saveToLocalStorage();
			}
			//*-
			this.emit("onGetAllUsersData", rsp.ok);
		});
	}

	CreateNewUser ({AccountID, FirstName, LastName, Email, Telephone, Address}) {
		console.log("CreateNewUser ", AccountID, FirstName, LastName, Email, Telephone, Address);

		this.UserApi.CreateNewUser({AccountID, FirstName, LastName, Email, Telephone, Address},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onCreateUserAction", rsp);
		});
	}

	DeleteUser({UserID}) {
		console.log("DeleteUser ", UserID);

		this.UserApi.DeleteUser({UserID},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onDeleteUserAction", rsp);
		});
	}

	getPossiblePatients ({ UserID }) {
		console.log("getPossiblePatients ", UserID );

		this.UserApi.GetPossiblePatients({ UserID },(rsp) => {

			console.log("getPossiblePatients Response");
			console.log(rsp);
			
			this.emit("onGetPossiblePatients", rsp);
		});
	}

	assignPatient({ UserID, PatientID }) {
		console.log("assignPatient ", UserID, PatientID );

		this.UserApi.AssignPatient({ UserID, PatientID },(rsp) => {

			console.log("assignPatient Response");
			console.log(rsp);
			
			this.emit("onAssignPatient", rsp);
		});
	}
	assignPatientsGroup({ UserID, PatientID }) {
		console.log("assignPatientsGroup ", UserID, PatientID );

		this.UserApi.AssignPatient({ UserID, PatientID },(rsp) => {

			console.log("assignPatientsGroup Response");
			console.log(rsp);
			
			this.emit("onAssignPatientsGroup", rsp);
		});
	}

	handleUnassignPatientModal({PatientID, Name}){
		console.log("handleUnassignPatientModal ", PatientID, Name);

		this.emit("onUnassignPatientModal", {
			PatientID : PatientID,
			Name : Name
		});
	}

	unassignPatient({ UserID, PatientID }) {
		console.log("unassignPatient ", UserID, PatientID );

		this.UserApi.UnassignPatient({ UserID, PatientID },(rsp) => {

			console.log("unassignPatient Response");
			console.log(rsp);
			
			this.emit("onUnassignPatient", rsp);
		});
	}
	unassignPatientsGroup({ UserID, PatientID }) {
		console.log("unassignPatientsGroup ", UserID, PatientID );

		this.UserApi.UnassignPatient({ UserID, PatientID },(rsp) => {

			console.log("unassignPatientsGroup Response");
			console.log(rsp);
			
			this.emit("onUnassignPatientsGroup", rsp);
		});
	}

	getAllPatientsForUser ({ UserID }) {
		console.log("getAllPatientsForUser ", UserID );

		this.UserApi.getAllPatientDataForUser({ UserID },(rsp) => {

			console.log("getAllPatientsForUser Response");
			console.log(rsp);

			
			this.emit("onGetAllPatientsForUser", rsp);
		});
	}

	setSeeAllPatients({ UserID, CanSee}) {
		console.log("setSeeAllPatients ", UserID, CanSee);

		this.UserApi.SetSeeAllPatients({ UserID, CanSee },(rsp) => {

			console.log("setSeeAllPatients Response");
			console.log(rsp);

			//*-
			this.emit("onSetSeeAllPatients", {
	      		ok : rsp.ok,
	      		canSee : rsp.canSee,
	      		userId : UserID
	      	});
		});
	}

	reSendEmail({ UserID }) {
		console.log("reSendEmail ", UserID );

		this.UserApi.RequestResendEmail({ UserID },(rsp) => {

			console.log("reSendEmail Response");
			console.log(rsp);

			
			this.emit("onResendEmail", rsp);
		});
	}

	saveToLocalStorage(){
		console.log("saveToLocalStorage");

		localStorage.UsersList = this.UsersList;
		localStorage.UserName = this.UserName;
		localStorage.UserAllPatientsAccess = this.UserAllPatientsAccess;
	}


}

const careUserStore = new CareUserStore;

export default careUserStore;