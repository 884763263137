import React from 'react';
import AuthStore from "../stores/AuthStore";
import CareAccountStore from "../stores/CareAccountStore";
import GeneralStore from "../stores/GeneralStore";
import classNames from 'classnames';
import Message from "../utils/Message";
import {isEmailValid} from "../utils/validators";
import TelephoneInputUnderline from '../components/UI/Inputs/TelephoneInputUnderlined';

class NewAccountUser extends React.Component {

    constructor() {
        super();

        this.state = {
            accountID : '',
            panel : "Form",
            newUserEmail : '',
            btnStyle : " disabled",
            errorFirstNameText : '',
            errorLastNameText : '',
            errorEmailText : 'Invalid Email',
            errorEmailVisibility : 'hide',
            errorTelephoneText : 'Telephone already registered',
            errorTelephoneVisibility : 'hide',
            newUserTelephone: '',
        };

        this.validateTxtInputs = this.validateTxtInputs.bind(this);
        this.onTelephoneInputChange = this.onTelephoneInputChange.bind(this);

        this.handleCreateAccountUser = this.handleCreateAccountUser.bind(this);
        this.onAccountUserCreatedAction = this.onAccountUserCreatedAction.bind(this);
        this.handleUserCreated = this.handleUserCreated.bind(this);
        this.TwoFactorEnabled = CareAccountStore.get2FactorAuthEnabled();
        console.log("this.TwoFactorEnabled", this.TwoFactorEnabled);

        this.txtNewUserFirstName = React.createRef();
        this.txtNewUserLastName = React.createRef();
        this.txtNewUserEmail = React.createRef();
        this.txtNewUserAddress = React.createRef();
        this.checkIsAdmin = React.createRef();
        this.checkStaffAccess = React.createRef();
        this.checkClientAccess = React.createRef();
        this.checkQueueAccess = React.createRef();
        this.checkMessagesAccess = React.createRef();
        this.checkAlertsAccess = React.createRef();
        this.checkMemoryBoxAccess = React.createRef();
        this.checkBulletinAccess = React.createRef();
        this.checkGroupsAccess = React.createRef();
    }


    componentDidMount() {
        CareAccountStore.on("onAccountUserCreated", this.onAccountUserCreatedAction);
        window.$('.tooltipped').tooltip({delay: 50});
        const {match} = this.props;
        if (match.params.accountId != null){
            this.setState({
                accountID : match.params.accountId
            });
        }
        CareAccountStore.getAllAccountsData({
            UserID: AuthStore.getCsUserID(),
            Token: AuthStore.getCsUserToken(),
        });
    }

    componentWillUnmount() {
        CareAccountStore.removeListener("onAccountUserCreated", this.onAccountUserCreatedAction);
        window.$('.tooltipped').tooltip('remove');
    }


    validateTxtInputs(e){
        let phoneLength = this.state.newUserTelephone.length;
        if (!this.TwoFactorEnabled && (phoneLength < 1)) {
            phoneLength = 1;
        }
        if (this.txtNewUserFirstName.current.value.length > 0
         && this.txtNewUserLastName.current.value.length > 0
         && this.txtNewUserEmail.current.value.length > 0
            && phoneLength > 0 ) {

            this.setState({
                btnStyle : ""
            });
        } else {

            this.setState({
                btnStyle : "disabled"
            });
        }
        if(this.txtNewUserFirstName.current.value.length > 0){
            this.setState({
                errorFirstNameText : ''
            });
        } 
        if(this.txtNewUserLastName.current.value.length > 0){
            this.setState({
                errorLastNameText : ''
            });
        }
        if (this.txtNewUserEmail.current.value.length > 0){
            this.setState({
                errorEmailVisibility : 'hide'
            });
        }

        if (this.state.newUserTelephone.length > 0){
            this.setState({
                errorTelephoneVisibility : 'hide'
            });
        }
    }

    onTelephoneInputChange(telephoneNumber){
        this.state.newUserTelephone = telephoneNumber;
        if (telephoneNumber.length > 0 && this.txtNewUserEmail.current.value.length > 0 && this.txtNewUserFirstName.current.value.length > 0 && this.txtNewUserLastName.current.value.length > 0){
            this.setState({
                btnStyle : "",
                errorTelephoneVisibility : 'hide'
            });
        }
    };

    handleCreateAccountUser(){
        if(!isEmailValid({email:this.txtNewUserEmail.current.value})){
            //Message.show('Ups! The email is not valid.');
            this.setState({
                errorEmailVisibility : '',
                errorTelephoneVisibility : 'hide',
                errorEmailText : 'Invalid Email'
            });
            return;
        }
        console.log("SENDING VALUE", this.state.newUserTelephone);
        let newUserTelephone = this.state.newUserTelephone;
        if (newUserTelephone === '+1') {
            newUserTelephone = '';
        }
        
        if (newUserTelephone.length > 0 ){
            newUserTelephone = newUserTelephone.replace(/[()-\s]/g, '');
            console.log("Le nombre est:", newUserTelephone, newUserTelephone.length);

            if (newUserTelephone.length < 12){
                this.setState({
                    errorEmailVisibility : 'hide',
                    errorTelephoneVisibility : '',
                    errorTelephoneText : 'Telephone must be at least 10 digits'
                });
                return;
            }
            let containsLetter = /[a-zA-Z]/.test(newUserTelephone);
            if (containsLetter){
                this.setState({
                    errorEmailVisibility : 'hide',
                    errorTelephoneVisibility : '',
                    errorTelephoneText : 'Invalid Telephone.'
                });
                return;
            } 
            // console.log("Le nombre finale est:", this.txtNewUserTelephone, this.txtNewUserTelephone.length);
            // const phoneNumberRegex = /^(?:\+\d{1,3})?\d{10,10}$/;   
            // if (!phoneNumberRegex.test(newUserTelephone)) {
            //     this.setState({
            //         errorEmailVisibility : 'hide',
            //         errorTelephoneVisibility : '',
            //         errorTelephoneText : 'Invalid Telephone.'
            //     });
            //     return;
            // }   
        }

        const newAccountUserData = {
            AccountID : this.state.accountID,
            FirstName : this.txtNewUserFirstName.current.value,
            LastName : this.txtNewUserLastName.current.value,
            Email : this.txtNewUserEmail.current.value,
            Telephone : newUserTelephone,
            Address : this.txtNewUserAddress.current.value + '',
            IsAdmin : this.checkIsAdmin.current.checked,
            //ReceiveEscalationAlerts : false, // this.refs.checkReceiveEscalation.current.checked,
            CanSeeStaffTab : this.checkStaffAccess.current.checked,
            CanSeeClientsTab : this.checkClientAccess.current.checked,
            CanSeeQueueTab : this.checkQueueAccess.current.checked,
            CanSeeMessageCenterTab : this.checkMessagesAccess.current.checked,
            CanSeeAlertsTab : this.checkAlertsAccess.current.checked,
            CanSeeMemoryBox : this.checkMemoryBoxAccess.current.checked,
            CanSeeBulletinBoard :  this.checkBulletinAccess.current.checked,
            CanAccessGroups :  this.checkGroupsAccess.current.checked
            //CanSendMessageToAllPatients : this.refs.checkMessageAllPatients.current.checked
        }

        this.setState({
            btnStyle : "disabled"
        });
        //Get the User Data from WS
        CareAccountStore.createNewUser(newAccountUserData);
    }

    onAccountUserCreatedAction(response){
        console.log("onAccountUserCreatedAction", response);
        if (response.ok){
            this.setState({
                panel : "Message",
                newUserEmail : this.txtNewUserEmail.current.value
            });

            GeneralStore.requestHandleBack();
            // window.location.assign("#/users" );
            setTimeout(function(){
                Message.show("User created");
            }, 100);

        } else if (response.emailRegistered) {
            this.setState({
                errorEmailVisibility : '',
                errorTelephoneVisibility : 'hide',
                errorEmailText : response.error
            });
        } else if (response.telephoneRegistered) {
            this.setState({
                errorEmailVisibility : 'hide',
                errorTelephoneVisibility : '',
                errorTelephoneText : response.error
            });
        } else {
            if (response.error){
                Message.show(response.error);
            }
            else {
                Message.show("Error creating new User");
            }

        }

        this.setState({
            btnStyle : ""
        });
    }

    handleUserCreated(){
        window.location.assign("#/accountDetails/" + this.state.accountID );
    }


    render() {

        let style = {
            height:window.innerHeight
        };

        let formVisibility = classNames({
            hide : (this.state.panel != "Form")
        });
        let messageVisibility = classNames({
            hide : (this.state.panel != "Message")
        });

        return (
            <div className="nucleus-hard-container">
            <div className="nucleus-all-scrollable-page">
                <p className="nucleus-page-title">New User</p>
                <br/>
                <div className={ "nucleus-user-form-pending nucleus-form-container " + formVisibility }>
                    <br/>
                    <div className="row">
                        <div className="col s2 m1 l1">

                        </div>
                        <div className="col s8 m8 l6">
                            <div className="row">
                                <div className="col s5 valgin-wrapper left-align">
                                    <span className="nucleus-labels">First Name</span> <span className="nucleus-labels txt-red">*</span>
                                </div>
                                <div className="col s7">
                                    <input 
                                        ref={this.txtNewUserFirstName} 
                                        type="text" 
                                        className="validate nucleus-input-form" 
                                        placeholder="First Name" 
                                        onChange={this.validateTxtInputs}
                                        onBlur={()=>{
                                            if(!this.txtNewUserFirstName.current.value){
                                                this.setState({
                                                    errorFirstNameText : 'First Name is required'
                                                });
                                            }
                                        }}
                                    />
                                    <span className={ "nucleus-form-error center-align " + String(this.state.errorFirstNameText ? "" : "hide") } > 
                                        {this.state.errorFirstNameText}
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s5 valgin-wrapper left-align">
                                    <span className="nucleus-labels">Last Name</span> <span className="nucleus-labels txt-red">*</span>
                                </div>
                                <div className="col s7">
                                    <input 
                                        ref={this.txtNewUserLastName}
                                        type="text" 
                                        className="validate nucleus-input-form" 
                                        placeholder="Last Name" 
                                        onChange={this.validateTxtInputs}
                                        onBlur={()=>{
                                            if(!this.txtNewUserLastName.current.value){
                                                this.setState({
                                                    errorLastNameText : 'Last Name is required'
                                                });
                                            }
                                        }}
                                    />
                                    <span className={ "nucleus-form-error center-align " + String(this.state.errorLastNameText ? "" : "hide") } > 
                                        {this.state.errorLastNameText}
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s5 left-align">
                                    <span className="nucleus-labels"> Email</span> <span className="nucleus-labels txt-red">*</span>
                                </div>
                                <div className="col s7">
                                    <input 
                                        ref={this.txtNewUserEmail}  
                                        type="email" 
                                        className="validate nucleus-input-form" 
                                        placeholder="Email" 
                                        onChange={this.validateTxtInputs}
                                        onBlur={()=>{
                                            if(!isEmailValid({email:this.txtNewUserEmail.current.value})){
                                                this.setState({
                                                    errorEmailVisibility : '',
                                                    errorEmailText : !this.txtNewUserEmail.current.value ? 'Email is required' : 'Invalid Email'
                                                });
                                            }
                                        }}
                                    />
                                    <span className={ "nucleus-form-error center-align " + this.state.errorEmailVisibility } > 
                                        {this.state.errorEmailText}
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s5 left-align">
                                    <span className=" nucleus-labels"> Telephone</span>
                                        {
                                            (this.TwoFactorEnabled) &&
                                            <span className="nucleus-labels txt-red">&nbsp;*</span>
                                        }
                                </div>
                                <div className="col s7">
                                    <TelephoneInputUnderline 
                                        inputId="txtNewUserTelephone" 
                                        fieldId="newPhone" 
                                        value="" 
                                        onChange={this.onTelephoneInputChange} 
                                        onBlur={this.validateTxtInputs} 
                                    />
                                    <br/>
                                    <span className={"nucleus-form-error left-align txt-black-forced " }>
                                        Click the country code icon for more country codes
                                    </span>
                                    <br/>
                                    <span className={"nucleus-form-error center-align " + this.state.errorTelephoneVisibility }>
                                        {this.state.errorTelephoneText}
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s5 left-align">
                                    <span className=" nucleus-labels"> Address</span>
                                </div>
                                <div className="col s7">
                                    <input 
                                        ref={this.txtNewUserAddress} 
                                        type="text" 
                                        className="validate nucleus-input-form" 
                                        placeholder="1234 Street Lane, New City, NC 00000" 
                                        onChange={this.validateTxtInputs}
                                    />
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col s5">
                                    <span className=" nucleus-labels"> Is Admin</span>
                                </div>
                                <div className="col s7">
                                    <div className="switch">
                                        <label>
                                            <input 
                                                ref={this.checkIsAdmin}
                                                type="checkbox" 
                                                className="filled-in" 
                                                id={"checkIsAdmin" + this.state.accountID } 
                                            />
                                            <label for={"checkIsAdmin"+ this.state.accountID }>&nbsp;</label>
                                        </label>
                                    </div>

                                </div>
                            </div>

                            {/*
                            // DEPRECATED - January 24th, 2018 - CARE-2304
                            <div className="row ">
                                <div className="col s5">
                                    <span className=" nucleus-labels"> Can Send Message to All Patients</span>
                                </div>
                                <div className="col s7">
                                   <span >
                                        <input ref="checkMessageAllPatients" type="checkbox" className="filled-in" id={"checkMsjBroadcast" + this.state.accountID }/>
                                        <label for={"checkMsjBroadcast" + this.state.accountID }>&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            */}


                            {/*
                            // DEPRECATED - January 17th, 2019 - CARE-4047
                            <div className="row  medium-space ">
                                <div className="col s5">
                                    <span className=" nucleus-labels"> Receive Escalation Alerts</span>
                                </div>
                                <div className="col s7">
                                   <span>
                                        <input ref="checkReceiveEscalation" type="checkbox" className="filled-in" id="checkReceiveEscalation"/>
                                        <label for="checkReceiveEscalation">&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            */}


                            <div className="row medium-space ">
                                <div className="col s5">
                                    <span className=" nucleus-labels">Can Access Staff Tab</span>
                                </div>
                                <div className="col s7">
                                   <span >
                                        <input 
                                            ref={this.checkStaffAccess} 
                                            type="checkbox" 
                                            className="filled-in" 
                                            id="checkStaffAccess" 
                                            defaultChecked={true} 
                                        />
                                        <label for="checkStaffAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            <div className="row medium-space ">
                                <div className="col s5">
                                    <span className=" nucleus-labels">Can Access Clients Tab</span>
                                </div>
                                <div className="col s7">
                                   <span >
                                        <input 
                                            ref={this.checkClientAccess}
                                            type="checkbox" 
                                            className="filled-in" 
                                            id="checkClientAccess" 
                                            defaultChecked={true} 
                                        />
                                        <label for="checkClientAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            <div className="row medium-space ">
                                <div className="col s5">
                                    <span className=" nucleus-labels">Can Access Queue Tab</span>
                                </div>
                                <div className="col s7">
                                   <span >
                                        <input 
                                            ref={this.checkQueueAccess}
                                            type="checkbox" 
                                            className="filled-in" 
                                            id="checkQueueAccess" 
                                            defaultChecked={true} 
                                        />
                                        <label for="checkQueueAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            <div className="row medium-space ">
                                <div className="col s5">
                                    <span className=" nucleus-labels"> Can Access Message Center Tab</span>
                                </div>
                                <div className="col s7">
                                   <span>
                                        <input 
                                            ref={this.checkMessagesAccess}
                                            type="checkbox" 
                                            className="filled-in" 
                                            id="checkMessagesAccess" 
                                            defaultChecked={true} 
                                        />
                                        <label for="checkMessagesAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            <div className="row medium-space ">
                                <div className="col s5">
                                    <span className=" nucleus-labels"> Can Access Alerts Tab</span>
                                </div>
                                <div className="col s7">
                                   <span>
                                        <input 
                                            ref={this.checkAlertsAccess}
                                            type="checkbox" 
                                            className="filled-in" 
                                            id="checkAlertsAccess" 
                                            defaultChecked={true} 
                                        />
                                        <label for="checkAlertsAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>

                            <div className="row medium-space ">
                                <div className="col s5">
                                    <span className=" nucleus-labels"> Can Access Memory Box Tab</span>
                                </div>
                                <div className="col s7">
                                   <span>
                                        <input 
                                            ref={this.checkMemoryBoxAccess}
                                            type="checkbox" 
                                            className="filled-in" 
                                            id="checkMemoryBoxAccess" 
                                            defaultChecked={true}
                                        />
                                        <label for="checkMemoryBoxAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>

                            <div className="row medium-space ">
                                <div className="col s5">
                                    <span className=" nucleus-labels"> Can Access Bulletin Board Tab</span>
                                </div>
                                <div className="col s7">
                                   <span>
                                        <input 
                                            ref={this.checkBulletinAccess}
                                            type="checkbox" 
                                            className="filled-in" 
                                            id="checkBulletinAccess" 
                                            defaultChecked={true}
                                        />
                                        <label for="checkBulletinAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>

                            <div className="row medium-space ">
                                <div className="col s5">
                                    <span className=" nucleus-labels"> Can Access Groups Tab</span>
                                </div>
                                <div className="col s7">
                                   <span>
                                        <input 
                                            ref={this.checkGroupsAccess}
                                            type="checkbox" 
                                            className="filled-in" 
                                            id="checkGroupsAccess" 
                                        />
                                        <label for="checkGroupsAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>


                        </div>
                        <div className="col s2 m3 l5">
                        </div>
                    </div>

                    <div className="fixed-action-btn btn-add-new">
                        <button className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped " 
                            type='button'
                            disabled={this.state.btnStyle} 
                            onKeyDown={()=>{}}
                            data-position="left" 
                            data-delay="50" 
                            data-tooltip="Save new User" 
                            onClick={this.handleCreateAccountUser}
                        >
                          <i className="material-icons">save</i>
                        </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div>

                <div id="nucleus-new-user-confirmation-pending" className={ "nucleus-new-user-form-pending " + messageVisibility }>
                    <div className="row">
                        <div className="col s12">
                            <p className=" nucleus-labels"> Complete! An account set up email has been sent to { this.state.newUserEmail }</p>
                            <button type='button' onKeyDown={()=>{}} className="nucleus-link nucleus-font-medium" onClick={this.handleUserCreated}>Go to Account Details </button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default NewAccountUser;
