import React from 'react';
import ReactDOM from "react-dom";
import moment from 'moment';

type RowParams = {
	data: Object;
    onClick: Function;
};


const ExternalAppRow = (props:RowParams) => {

    const onClicked = ()=>{
        props.onClick(props.data);
    }

    // let lastOnLineDate = "-";
    // if (props.data.LastLogin){
    //     lastOnLineDate = moment(props.data.LastLogin).format("MM/DD/YYYY hh:mm A");
    // }

    // let deviceStatusIcon = "img/icon_device_inactive.png";
    // if (props.data.LastLogin){
    //     deviceStatusIcon = "img/icon_device_active.png";
    // }
    return(
        <tr key={"classRow"} >
            <td style={{display:'flex'}}> 
                { (props.data.IconUrl) &&
                    <img
                        src={
                            props.data.IconUrl
                            //? props.data.IconUrl
                            //: "img/user_placeholder.png"
                        }
                        // ref={(img) => {
                        //     this.imgRef = img;
                        // }}
                        // onError={() => {
                        //     this.imgRef.src = "img/user_placeholder.png";
                        // }}
                        style={{
                            width: 50,
                            height: 50,
                            marginRight: 10,
                            marginLeft: 0,
                            borderRadius: 5,
                            objectFit: "cover",
                        }}
                    />
                }
                
                <span style={{ alignSelf: 'center'}}>
                    {props.data.Name }
                </span>
            </td>
            <td class="" > <span>{ props.data.AndroidIdentifier }</span></td>
            <td class="" style={{wordWrap:'break-word'}}> <span>{ props.data.IconUrl }</span></td>
            <td class="">
                <span class="nucleus-table-icon nucleus-link" onClick={onClicked}>
                    <i class="Tiny material-icons">view_headline</i>
                </span>
            </td>
        </tr>
    );
}

export default ExternalAppRow;
