import React from "react";
import { ProviderAvailableTabs } from "../utils/providerConstants";
import { useHistory } from "react-router-dom";
import { NucleusTabProps, UINucleusNavBar } from "../../../components/UI";
import { useNucleusProviders } from "../../../context-api/nucleusProvidersContext/NucleusProvidersContext";

type ProviderNavTabsProps = {
    handleTabs?: (tab: NucleusTabProps) => void;
    activeTab?: string;
}
const ProviderNavTabs = ({ handleTabs, activeTab }: ProviderNavTabsProps) => {
    const history = useHistory();
    const { dispatch, state: { provider: { data } } } = useNucleusProviders()
    const defaultHandler = (tab: NucleusTabProps) => {
        console.log('data', data);

        if (tab.route && tab.route.includes(':providerId') && data?.ID) {
            console.log('provider', data.ID);
            history.push(tab.route.replace(':providerId', data?.ID))
        } else {
            history.push('/providers')
        }
    };
    return (
        <div>
            <UINucleusNavBar
                key={activeTab}
                tabs={ProviderAvailableTabs}
                activeTab={activeTab}
                onTabChange={(tab) => {
                    if (handleTabs) handleTabs(tab)
                    defaultHandler(tab);
                }}
            />
        </div>
    );
};

export default ProviderNavTabs;
