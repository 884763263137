import { EventEmitter } from "events";
//import Dispatcher from "../Dispatcher";
 
class GeneralStore extends EventEmitter {
	constructor() {
		super();

		this.staffTab = localStorage.staffTab || 1;
		this.clientTab = localStorage.clientTab  || 1;
		this.clientInnerTab = localStorage.clientInnerTab  || 1;
		this.defaultScheduleModified = localStorage.defaultScheduleModified  || "false";
		this.PatientAssignList = [];
		this.PatientAssignNames = '';
		this.profileMode = localStorage.profileMode || "Info";

	}

	requestHandleBack () {
		console.log("requestHandleBack");

		this.emit("onRequestHandleBack");
	}
	
	openDeletePatientModal ({ PatientID }) {
		console.log("openDeletePatientModal", PatientID );

		this.emit("openDeletePatientModalAction", PatientID);
	}

	openDeleteUserModal ({ UserID }) {
		console.log("openDeleteUserModal", UserID );

		this.emit("openDeleteUserModalAction", UserID);
	}

	requestOpenDeleteModal ({ RequestID, UserID, PatientName }) {
		console.log("requestOpenDeleteModal", RequestID, UserID, PatientName );

		this.emit("onOpenDeleteCareItemModal", { requestID : RequestID, userID: UserID, patientName: PatientName});
	}

	requestOpenCaregiverAssignmentModal ({ AssignmentID, AssignmentPatientID, AssignmentMessage, AssignmentDate, AssignmentCheckIn, AssignmentCheckOut }) {
		console.log("requestOpenCaregiverAssignmentModal", AssignmentID, AssignmentPatientID, AssignmentMessage, ">", AssignmentDate, ">>", AssignmentCheckIn, ">>>", AssignmentCheckOut);

		this.emit("onOpenCaregiverAssignmentModal", { assignmentID : AssignmentID, assignmentPatientID:AssignmentPatientID, assignmentMessage:AssignmentMessage, assignmentDate:AssignmentDate, assignmentCheckIn:AssignmentCheckIn, assignmentCheckOut:AssignmentCheckOut});
	}

	requestOpenFamilyMemberModal ({ MemberID, MemberFirstName, MemberLastName, MemberEmail, MemberTelephone, MemberReceiveUpdates, MemberProfileCreated, AccountAutoAnswer, MemberAutoAnswer, MemberAdmin }) {
		console.log("requestOpenFamilyMemberModal", MemberID, MemberFirstName, MemberLastName, ">", MemberEmail, ">>", MemberTelephone, ">>>", MemberReceiveUpdates, ">>>>", MemberProfileCreated, ">>>>>", AccountAutoAnswer, ">>>>>>", MemberAutoAnswer, MemberAdmin);

		this.emit("onOpenFamilyMemberModal", { memberID : MemberID, memberFirstName:MemberFirstName, memberLastName:MemberLastName, memberEmail:MemberEmail, memberTelephone:MemberTelephone, memberReceiveUpdates:MemberReceiveUpdates, memberProfileCreated : MemberProfileCreated, AccountAutoAnswer : AccountAutoAnswer, MemberAutoAnswer:MemberAutoAnswer, MemberAdmin});
	}

	requestOpenEditNotesModal ({ NoteID, Note }) {
		console.log("requestOpenFamilyMemberModal", NoteID, Note);

		this.emit("onOpenEditNotesModal", { noteID : NoteID, note:Note });
	}

	setStaffTab ( tab) {
		console.log("setStaffTab", tab );

		this.staffTab = tab;
		this.saveToLocalStorage();
	}

	setProfileMode ( mode) {
		console.log("setProfileMode", mode );
		this.profileMode = mode;
		// Used to detect in the TopBar the "Go Back" action for info or edit mode
	}

	getProfileMode(){
		return this.profileMode;
	}

	cancelAccountUserPatientProfileEditMode(){
		console.log("cancelAccountUserPatientProfileEditMode", this.profileMode );
		this.emit("onGoBackOnPatientProfileEdit");
	}

	getStaffTab(){
		return this.staffTab;
	}

	setClientTab ( tab) {
		console.log("setClientTab", tab );

		this.clientTab = tab;
		this.saveToLocalStorage();
	}
	getClientTab(){
		//console.log("getClientTab", this.clientTab );
		return this.clientTab;
	}

	
	setClientInnerTab ( tab) {
		console.log("setClientInnerTab", tab );

		this.clientInnerTab = tab;
		this.saveToLocalStorage();
	}
	getClientInnerTab(){
		//console.log("getClientTab", this.clientInnerTab );
		return this.clientInnerTab;
	}

	setDefaultScheduleModified ( value) {
		console.log("setDefaultScheduleModified", value );

		this.defaultScheduleModified = value;
		this.saveToLocalStorage();
	}
	getDefaultScheduleModified(){
		//console.log("getDefaultScheduleModified", this.defaultScheduleModified );
		return this.defaultScheduleModified;
	}

	//AssignPatientToClient

	clearPatientAssignList(){
		this.PatientAssignList = [];
	}
	addPatientAssignList(patient){
		console.log("addPatientAssignList");
		console.log(patient);

		this.PatientAssignList.push(patient);

		this.emit("onPatientAssignListHasChanged", this.PatientAssignList.length );

		this.PatientAssignNames = "";
		for(var i = 0; i < this.PatientAssignList.length; i++){
			
			if ( i != 0){
				this.PatientAssignNames = this.PatientAssignNames + ", ";
			}
			this.PatientAssignNames = this.PatientAssignNames + this.PatientAssignList[i].Name ;
		}
		console.log("Names: ", this.PatientAssignNames);
	}

	removePatientAssignList(patientID){
		console.log("removePatientAssignList");
		console.log(patientID);
		let index = -1;
		this.PatientAssignNames = "";

		console.log("LENGTH ", this.PatientAssignList.length);
		for(var i = 0; i < this.PatientAssignList.length; i++){
			if (this.PatientAssignList[i].ID == patientID){
				index = i;
				console.log("Coincide en: ", i);
				break;
			}
		}
		console.log("Index es: ", index);
		if (index >= 0){
			this.PatientAssignList.splice(index, 1);
		}
		console.log("ARRAY ", this.PatientAssignList);
		this.emit("onPatientAssignListHasChanged", this.PatientAssignList.length );

		//Create SMS Receivers Names
		this.PatientAssignNames = "";
		for(var i = 0; i < this.PatientAssignList.length; i++){
			
			if ( i != 0){
				this.PatientAssignNames = this.PatientAssignNames + ", ";
			}
			this.PatientAssignNames = this.PatientAssignNames + this.PatientAssignList[i].Name ;
		}
		console.log("Names: ", this.PatientAssignNames);
	}

	getPatientAssignList(){
		return this.PatientAssignList.length;
	}

	getPatientAssignListData(){
		return this.PatientAssignList;
	}


	saveToLocalStorage(){
		console.log("saveToLocalStorage");

		localStorage.staffTab = this.staffTab;
		localStorage.clientTab = this.clientTab;
		localStorage.clientInnerTab = this.clientInnerTab;
		localStorage.defaultScheduleModified = this.defaultScheduleModified;
	}


}

const generalStore = new GeneralStore;

export default generalStore;