
import {
    IProviderAccount,
    INewProviderUser,
    IProviderData,
    IAccountCatalogListItem,
    ProviderStringsResponse,
    ProviderAccountGroupingStringsForm,
    ProviderEscalationConfigResponse, ProviderEscalationConfigForm,
    ProviderCallRequestConfigResponse, ProviderCallRequestConfigForm,
    ProviderFeaturesResponse
} from '../../../context-api/nucleusProvidersContext/NucleusProvidersContext';

import { httpApi as httpNodeApi } from "@nucleus-care/nucleuscare-backend-client";
import { IState, IUserState } from '../components/StateSelector/useStateSelector';

export const getProviderByID = async (providerId: string): Promise<IProviderData> => {
    const { data } = await httpNodeApi.get(`/providers/${providerId}`)
    return data
}
export const updateProviderByID = async (providerId: string, data: FormData): Promise<boolean> => {
    const response = await httpNodeApi.put(`/providers/${providerId}`, data)
    if (response.status >= 200 && response.status < 300) {
        return true
    }
    return false
}
export const updateProviderFeaturesByID = async (providerId: string, data: ProviderFeaturesResponse): Promise<boolean> => {
    try {
        await httpNodeApi.put(`/v2/providers/${providerId}/features`, data)
        return true;
    } catch (err) {
        console.error('Error on updateProviderFeaturesByID:', err);
        return false;
    }
}

export const getProviderFeatures = async (providerId: string): Promise<ProviderFeaturesResponse> => {
    const { data } = await httpNodeApi.get(`/v2/providers/${providerId}/features`)
    return data as ProviderFeaturesResponse;
}

export const getProviderAccounts = async (providerId: string): Promise<IProviderAccount[]> => {
    const { data } = await httpNodeApi.get(`/providers/${providerId}/accounts`)
    return data.providersAccounts
}
export const getProviderAccountsToSelect = async (providerId: string): Promise<IAccountCatalogListItem[]> => {
    const { data } = await httpNodeApi.get(`/providers/${providerId}/accounts_catalog`)
    return data.providersAccounts
}
export const addProviderAccounts = async (providerId: string, accountIds: string[]) => {
    const { data } = await httpNodeApi.post(`/providers/${providerId}/accounts`, { accountIds })
    const { accountsAdded } = data
    return accountsAdded
}
export const removeProviderAccount = async (providerId: string, accountId: string) => {
    const { data } = await httpNodeApi.delete(`/providers/${providerId}/accounts`, {
        data: {
            accountIds: [accountId]
        }
    })
    const { accountsRemoved } = data
    return accountsRemoved
}

export const getProviderStrings = async (providerId: string): Promise<any> => {
    const { data } = await httpNodeApi.get(`/providers/${providerId}/strings`)
    return data as ProviderStringsResponse
}

export const updateProviderStrings = async ({ providerId, form = { localeTag: 'EN', userRoleName: 'User', userRoleNamePlural: 'Users' } }: { providerId: string, form: { localeTag?: string, userRoleName?: string, userRoleNamePlural?: string } }): Promise<any> => {
    const { data } = await httpNodeApi.put(`/providers/${providerId}/strings`, form)
    return data
}

export const updateProviderAccountGroupingStrings = async ({ providerId, stringsForm }: { providerId: string, stringsForm: ProviderAccountGroupingStringsForm }): Promise<any> => {
    const { data } = await httpNodeApi.put(`/providers/${providerId}/account_grouping_strings`, stringsForm)
    return data
}

export const getProviderEscalationConfig = async (providerId: string): Promise<any> => {
    const { data } = await httpNodeApi.get(`/providers/${providerId}/escalation_config`)
    return data as ProviderEscalationConfigResponse;
}
export const updateProviderEscalationConfig = async ({ providerId, escalationConfigForm }: { providerId: string, escalationConfigForm: ProviderEscalationConfigForm }): Promise<any> => {
    try {
        const { data } = await httpNodeApi.put(`/providers/${providerId}/escalation_config`, escalationConfigForm)
        return data
    } catch (error) {
        return error
    }
}

export const getProviderCallRequestConfig = async (providerId: string): Promise<any> => {
    const { data } = await httpNodeApi.get(`/providers/${providerId}/call_request_config`)
    return data as ProviderCallRequestConfigResponse;
}
export const updateProviderCallRequestConfig = async ({ providerId, callRequestConfigForm }: { providerId: string, callRequestConfigForm: ProviderCallRequestConfigForm }): Promise<any> => {
    try {
        const { data } = await httpNodeApi.put(`/providers/${providerId}/call_request_config`, callRequestConfigForm)
        return data
    } catch (error) {
        return error
    }
}

export const getProviderUsers = async (providerId: string, callback: (response: any) => void): Promise<void> => {
    let responseData;
    httpNodeApi.get("providers/" + providerId + "/users")
        .then((response) => {
            const { data } = response;
            responseData = { ...data };
        })
        .catch((e) => {
            const { data } = e.response || { error: "Error on account/users" };
            responseData = {
                ...data,
                ok: false
            };
        })
        .finally(() => {
            callback(responseData);
        });
}

export const getProviderUserStates = async (providerId: string, providerUserId: string): Promise<IUserState[]> => {
    const { data } = await httpNodeApi.get(`providers/${providerId}/state-license/${providerUserId}`);
    return data;
}

export const addStateToProviderUser = async (providerId: string, providerUserId: string, states: IState[]) => {
    try {
        const buildStates = states.map(state => {
            return {
                UserID: providerUserId,
                StateID: state.ID,
            }
        })
        const { data } = await httpNodeApi.post(`providers/${providerId}/state-license`, buildStates);
        return data.states;
    } catch (e) {
        console.error('Error on addStateToProviderUser:', e);
        return [];
    }
}

export const removeProviderUserStates = async (providerId: string, providerUserId: string, states: IState[]) => {
    try {
        const buildStates = states.map(state => {
            return {
                UserID: providerUserId,
                StateID: state.ID,
            }
        })
        const removedStatesResponse = await httpNodeApi.delete(`providers/${providerId}/state-license`, { data: buildStates });
        return removedStatesResponse;
    } catch (e) {
        console.error('Error on removeProviderUserStates:', e);
        return [];
    }

}
export const addProviderUser = async (providerId: string, newProviderData: INewProviderUser, callback: (response: any) => void): Promise<void> => {
    let responseData;
    const selectedStates = newProviderData.states;
    delete newProviderData.states;
    if (!selectedStates?.length) {
        responseData = {
            ok: false,
            error: "Please select at least one state"
        };
    }
    try {
        const response = await httpNodeApi.post("providers/" + providerId + "/users", newProviderData);
        const { data } = response;
        await addStateToProviderUser(providerId, data.id, selectedStates ?? [])
        responseData = { ...data };
    } catch (e: any) {
        console.error('Error on addProviderUsers:', e);
        const { data } = e.response || { error: "Error on addProviderUsers" };
        responseData = {
            ...data,
            ok: false
        };
    } finally {
        callback(responseData);
    }
}

export const getProviderUserConfigData = async (providerId: string, providerUserId: string, callback: (response: any) => void): Promise<void> => {
    let responseData;
    httpNodeApi.get("providers/" + providerId + "/users/" + providerUserId)
        .then((response) => {
            const { data } = response;
            responseData = { ...data };
        })
        .catch((e) => {
            const { data } = e.response || { error: "Error on getProviderUserConfigData" };
            responseData = {
                ...data,
                ok: false
            };
        })
        .finally(() => {
            callback(responseData);
        });
}

export const updateProviderUser = async (providerId: string, providerUserId: string, selectedProviderData: INewProviderUser, callback: (response: any) => void): Promise<void> => {
    let responseData;
    try {
        const selectedStates = selectedProviderData.states;
        const statesToRemove = selectedProviderData.statesToRemove;
        delete selectedProviderData.states;
        delete selectedProviderData.statesToRemove;
        if (!selectedStates?.length) {
            responseData = {
                ok: false,
                error: "Please select at least one state"
            };
        }
        const response = await httpNodeApi.put("providers/" + providerId + "/users/" + providerUserId, selectedProviderData)
        const { data } = response
        if (statesToRemove?.length) {
            await removeProviderUserStates(providerId, providerUserId, statesToRemove);
        }
        await addStateToProviderUser(providerId, providerUserId, selectedStates ?? []);
        responseData = { ...data };
    } catch (e: any) {
        const { data } = e.response || { error: "Error on updateProviderUser" };
        responseData = {
            ...data,
            ok: false
        };
    }
    finally {
        callback(responseData);
    }
}