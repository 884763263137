import Config from "../Config";
import NucleusNetwork from "../utils/NucleusNetwork";

class QueueApi {

	constructor(context) {
		this.context = context;
	}

	//<----------------------------------Get All Patients Data--------------------------->
	//<------------------------------------- DEPRECATED --------------------------------->
	getCareCallRequestsQueue (data,callback) {
		let random_num = Math.floor(new Date().getTime() / 1000);

		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCall.asmx/GetCallRequestsQueue?rnd=" + random_num,
			data : data,
			callback : callback,
		});
	}

	//<----------------------------------Get All Patients Data--------------------------->
	getCareCallRequestsQueueForUser (data,callback) {
		let random_num = Math.floor(new Date().getTime() / 1000);

		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCall.asmx/GetCallRequestsQueueForUser?rnd=" + random_num,
			data : data,
			callback : callback,
		});
	}

	//<----------------------------------Report Call Handled--------------------------->
	ReportCallHandled (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCall.asmx/ReportCallHandled",
			data : data,
			callback : callback,
		});
	}

}


export default QueueApi;