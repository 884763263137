import React, { useEffect, useState } from 'react';
import AuthStore from '../stores/AuthStore';

function ReportLink({ to, label }) {
  return (
    <div
      style={{
        minWidth: 280,
        marginBottom: 20,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        tabIndex={0}
        onKeyDown={() => {}}
        style={{
          padding: 10,
          paddingLeft: 20,
          paddingRight: 20,
          borderRadius: 50,
          backgroundColor: '#0092FF',
          display: 'inline-flex',
          cursor: 'pointer',
          minHeight: 50,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          window.location.assign(to);
        }}
      >
        <div>
          <span
            className="nucleus-tools-page-text-title"
            style={{ color: '#fff' }}
          >
            {label}
          </span>
        </div>
        <div
          style={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 5,
          }}
        >
          <span
            className="nucleus-table-icon"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <i
              className="Tiny material-icons"
              style={{ color: '#fff', fontSize: 26 }}
            >
              chevron_right
            </i>
          </span>
        </div>
      </div>
    </div>
  );
}

function Reports() {
  useEffect(() => {
    if (!AuthStore.superUser()) {
      window.location.assign('#/dashboard');
    }
    //CareAccountStore.on("onGetFamilyMembersData", this.onGetFamilyMembersDataAction);
    return () => {
      //CareAccountStore.removeListener("onGetFamilyMembersData", this.onGetFamilyMembersDataAction);
    };
  }, []);

  return (
    <div className="nucleus-tools-container">
      <div className="nucleus-all-scrollable-page">
        <p className="nucleus-tools-page-title">
          <b>Reports</b>
        </p>
        <div>
          <ReportLink to="#/accountsUsageReport" label="Full Account Usage" />
          <ReportLink to="#/appsUsageReport" label="Apps Usage Report" />
          {/* <ReportLink to="#/deviceUsageReport" label="Device Usage Report" /> */}
        </div>
      </div>
    </div>
  );
}

export default Reports;
