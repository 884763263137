import styled from "styled-components";

import {
    IProviderUser,
    useNucleusProviders
  } from '../../../../context-api/nucleusProvidersContext/NucleusProvidersContext';

const NucleusNavBarContainer = styled.div`
    flex: 1;
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
    justify-content: flex-start;
    border-bottom: 2px solid #B4C6D3;
`;

const NucleusTab = styled.a<{ active?: boolean }>`
    color: #0A313F;
    margin-right: 30px;
    font-family: Fira Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    padding: 20px 0 8px 0;
    &:hover {
        cursor: pointer;
    }
    ${({ active }) => active && `
        border-bottom: 4px solid #0092FF;
        margin-bottom: -3px;
    `}
`;

export interface NucleusTabProps {
    route: string;
    title: string;
    titleInfo?:string|null;
    slug: string;
    disabled: boolean;
    fields?: string[];
}

export type NucleusNavBarProps = {
    tabs: NucleusTabProps[];
    onTabChange?: (tab: NucleusTabProps) => void;
    activeTab?: string;
}

export const UINucleusNavBar = ({ tabs = [], onTabChange, activeTab }: NucleusNavBarProps) => {

    const { state: { provider: { data } } } = useNucleusProviders();

    return (
        <NucleusNavBarContainer>
            {!!(tabs.length) && 
                tabs.map((tab) => { 
                    let titleInfo = "";
                    if (tab.titleInfo && tab.titleInfo == "provider-user-count" && data?.UsersCount != undefined){
                        titleInfo = " (" + data?.UsersCount + ")";
                    }
                    return(
                        <NucleusTab 
                            key={tab.slug} 
                            active={activeTab === tab.slug} 
                            onClick={() => onTabChange?.(tab)}
                        >
                            {tab.title + titleInfo}
                        </NucleusTab>
                    )}
                )
            }
        </NucleusNavBarContainer>
    )
};
