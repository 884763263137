import React, { useEffect, useState } from "react";
import NucleusButton from "../../../../components/NucleusButton";
import { useDispatch, useSelector } from "react-redux";
import {
  saveMdmPolicyData,
  createMdmPolicyData,
  resetMDMPolicyState,
  setPolicyScreen,
  resetSuccessFormState,
  setApps,
} from '@nucleus-care/nucleuscare-backend-client';
import AuthStore from "../../../../stores/AuthStore";
import { useHistory } from "react-router-dom";
import Message from "../../../../utils/Message";
import NucleusModal from "../../../../components/NucleusModal";
import UnsavedAlert from "./UnsavedAlert";
import { SpinnerCircular } from "spinners-react";

const MDMPolicyFooter = ({
  showUnsavedChangesAlert,
  setShowUnsavedChangesAlert,
  handleDontSave,
  setMainCancelClicked,
  disableButtons = false,
}) => {
  const { policyJSON, policyInformation, policyFormStatus } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.state
  );
  const {
    PolicyDetails: { PolicyTemplate },
  } = useSelector(({ mdmPolicy }) => mdmPolicy.data);

  const { loading, Policies } = useSelector(({ mdmPolicy }) => mdmPolicy.data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSuccessFormState());
  }, []);

  const [policyExistsModalIsOpen, setPolicyExistsModalIsOpen] = useState(false);

  // Actually implements the save
  const implementSave = () => {
    const modifiedPolicyJSON = { ...policyJSON };
    const policyName = policyInformation.Name;
    const policyVersion = Number(policyInformation.Version || 1);
    // DELETE EXISTING DEPRECATED PROPERTIES
    delete modifiedPolicyJSON.name;
    delete modifiedPolicyJSON.version;
    delete modifiedPolicyJSON.disableLockScreen;

    if (policyFormStatus.operationType === "UPDATE") {
      dispatch(
        saveMdmPolicyData({
          policyId: policyFormStatus.PolicyTemplateID,
          payload: {
            CSUserID: AuthStore.getUserAccountID(),
            Name: policyName,
            Version: policyVersion,
            PolicyDetails: modifiedPolicyJSON,
          },
        })
      );
    } else {
      dispatch(
        createMdmPolicyData({
          CSUserID: AuthStore.getUserAccountID(),
          Name: policyName,
          Version: policyVersion,
          PolicyDetails: {
            ...modifiedPolicyJSON,
            // version: 1
          },
        })
      );
    }
  };

  // Handles the general save scenario for when Save is clicked
  const handleSave = () => {
    // Policy must be provided a name
    if (!policyInformation.Name) {
      dispatch(
        setPolicyScreen({
          activeMDMScreen: "general",
          activeMainScreen: "policies",
        })
      );
      Message.error("Please provide a name for the policy");
      return;
    }

    // For a new policy, check if the name already exists
    if (
      policyFormStatus.operationType === "CREATE" &&
      Policies.some((policy) => policy.Name === policyInformation.Name)
    ) {
      setPolicyExistsModalIsOpen(true);
      return;
    }
    implementSave();
  };

  return (
    <div className="mdmPolicyFooterContainer">
      <div className="col s3 mdmPolicyFooterButtonContainer">
        {showUnsavedChangesAlert && (
          <UnsavedAlert
            handeDontSave={handleDontSave}
            handleSave={() => {
              handleSave();
            }}
            loading={loading}
            setShowUnsavedChangesAlert={setShowUnsavedChangesAlert}
            showUnsavedChangesAlert={showUnsavedChangesAlert}
          />
        )}

        {policyExistsModalIsOpen && (
          // TODO - For now reusing kiosk class names because we don't have general class names to apply
          // this should be fixed
          <NucleusModal setIsOpen={setPolicyExistsModalIsOpen}>
            <div className="kioskContainer">
              <label className="kioskWarning">WARNING</label>
              <p className="kioskTxt">
                {`A policy with the name "${policyInformation.Name}" already exists.`}
              </p>
              <p className="kioskTxt">Please use a different name.</p>
              <div className="modalActions">
                <div className="actionsContainer">
                  <button
                    className="cancelBtnKiosk"
                    onClick={() => {
                      setPolicyExistsModalIsOpen(false);
                    }}
                  >
                    DISMISS
                  </button>
                </div>
              </div>
            </div>
          </NucleusModal>
        )}

        <NucleusButton
          disabled={disableButtons || loading}
          label="Cancel"
          backGroundColor="#FFFFFF"
          action={() => {
            setMainCancelClicked(true);
            dispatch(resetMDMPolicyState());
            dispatch(
              setPolicyScreen({
                activeMainScreen: "policies",
                activeMDMScreen: "general",
              })
            );
          }}
        />
        <NucleusButton
          label="Save"
          disabled={disableButtons || loading}
          loading={loading}
          backGroundColor="#0A313F"
          action={handleSave}
        />
      </div>
    </div>
  );
};

export default MDMPolicyFooter;
