import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import AuthStore from "../stores/AuthStore";
import CareAccountStore from "../stores/CareAccountStore";
import GeneralStore from "../stores/GeneralStore";

import AccountPatientsTimezoneRow from "../components/AccountPatientsTimezoneRow";

import Message from "../utils/Message";


import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
	KeyboardDatePicker,
    KeyboardTimePicker,
	MuiPickersUtilsProvider
} from "@material-ui/pickers";

import { SpinnerCircular } from 'spinners-react';

import nucleusNetwork, {ControlSecret} from '../utils/NucleusNetwork';

class PatientTimezones extends React.Component {
    constructor(props){
        super(props);

        this.state = {

            patientsArray : [],
            loading :false,
            results :false,
            emptyError:false,
            searchText: "",

            sortPatientDesc : ' nucleus-icon-inactive hide ',
            sortPatientAsc : ' nucleus-icon-inactive ',

            modalBtnStyle: 'nucleus-submit-btn',

            selectedPatientID: null,
            selectedPatientName: null,
            selectedPatientTimezoneOffset:null,
            selectedPatientTimezoneString:null,

            modalLoadingText : ""

            // accountScheduleModified : (GeneralStore.getDefaultScheduleModified().toString() == "true"),
            // accountScheduleUpdateDefaultStyle : 'disabled', // (GeneralStore.getDefaultScheduleModified().toString() == "true") ? '' : 'disabled',

            // newScheduleButtonsStyle : '',

            // selectedScheduleID : '',
            // editScheduleButtonsStyle : '',

            // selectedScheduleData : '',
            // deleteScheduleBtnStyle : 'nucleus-submit-btn',

            // newScheduleItemError : ' hide',
            // editScheduleItemError : ' hide',

            // updateDefaultScheduleBtnStyle : 'nucleus-submit-btn',

        };

        this.sorteredArray1 = [];
        this.sorteredArray2 = [];

        this.onGetAccountPatientsTimezonesAction = this.onGetAccountPatientsTimezonesAction.bind(this);
        this.onUpdatePatientTimeZoneAction = this.onUpdatePatientTimeZoneAction.bind(this);
        this.handleSortPatientAsc = this.handleSortPatientAsc.bind(this);
        this.handleSortPatientDesc = this.handleSortPatientDesc.bind(this);
        this.resetSortIconsState = this.resetSortIconsState.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);

        // Timezones changes https://www.timeanddate.com/time/change/usa
        this.isDST = false;
        this.dstStartDate = null;
        this.dstEndDate = null;

        this.StandardTimezones = [
			{ valueString : "+1", name : "(GMT+01) Central European Time - Stockholm", shortName: "CET", selected:false},
			{ valueString : "+2", name : "(GMT+02) Israel Standard Time - Jerusalem",  shortName: "IST", selected:false},
			{ valueString : "-5", name : "(GMT-05) Eastern Standard Time - New York",  shortName: "EST", selected:false},
			{ valueString : "-6", name : "(GMT-06) Central Standard Time - Chicago",   shortName: "CST", selected:false},
			{ valueString : "-7", name : "(GMT-07) Mountain Standard Time - Salt Lake City", shortName: "MST", selected:false},
			{ valueString : "-8", name : "(GMT-08) Pacific Standard Time - Los Angeles", shortName: "PST", selected:false},
			{ valueString : "-9", name : "(GMT-09) Alaska Standard Time - Anchorage", shortName: "AKST", selected:false},
			{ valueString : "-10", name : "(GMT-10) Hawaii Standard Time - Honolulu", shortName: "HST", selected:false}
		];

		this.DaylightTimezones = [
			{ valueString : "+2", name : "(GMT+02) Central European Summer Time - Stockholm", shortName: "CEST", selected:false},
			{ valueString : "+3", name : "(GMT+03) Israel Daylight Time - Jerusalem", shortName: "IDT", selected:false},
			{ valueString : "-4", name : "(GMT-04) Eastern Daylight Time - New York", shortName: "EDT", selected:false},
			{ valueString : "-5", name : "(GMT-05) Central Daylight Time - Chicago", shortName: "CDT", selected:false},
			{ valueString : "-6", name : "(GMT-06) Mountain Daylight Time - Salt Lake City", shortName: "MDT", selected:false},
			{ valueString : "-7", name : "(GMT-07) Pacific Daylight Time - Los Angeles", shortName: "PDT", selected:false},
			{ valueString : "-8", name : "(GMT-08) Alaska Daylight Time - Anchorage", shortName: "AKDT", selected:false},
			{ valueString : "-9", name : "(GMT-09) Hawaii Daylight Time - Honolulu", shortName: "HDT", selected:false}
		];

        this.StandardTimezonesKeys = {
			"+1": "CET",
			"+2": "IST",
			"-5": "EST",
			"-6": "CST",
			"-7": "MST",
			"-8": "PST",
			"-9": "AKST",
			"-10": "HST"
		};

		this.DaylightTimezonesKeys = {
			"+2": "CEST",
			"+3": "IDT",
			"-4": "EDT",
			"-5": "CDT",
			"-6": "MDT",
			"-7": "PDT",
			"-8": "AKDT",
			"-9": "HDT"
        };

        this.filterTimezonesArray = [];
        this.messageFilterTimezonesArray = [];
        this.selectTimeZoneRef = React.createRef();
    }

    componentDidMount(){

        console.log("PatientTimezones componentDidMount", this.props.accountId);

        //Register Listener
        CareAccountStore.on("onGetAccountPatientsTimezones",this.onGetAccountPatientsTimezonesAction);
        CareAccountStore.on("onUpdatePatientTimeZone",this.onUpdatePatientTimeZoneAction);

        this.setState({
            loading : true
        });
        CareAccountStore.getAccountPatientsTimezones({
            UserID : AuthStore.getCsUserID(),
            Token : AuthStore.getCsUserToken(),
            AccountID : this.props.accountId
        });

        this.getDSTDates();
        window.$('.modal').modal({
            dismissible: false,
            complete: () => {  } // Callback for Modal close
        });
    }

    componentWillUnmount(){
        //Remove Listener
        CareAccountStore.removeListener("onGetAccountPatientsTimezones",this.onGetAccountPatientsTimezonesAction);
        CareAccountStore.removeListener("onUpdatePatientTimeZone",this.onUpdatePatientTimeZoneAction);
    }

    getDSTDates=()=>{
        console.log("PatientTimezones getDSTDates");
		let currentDate = new Date();
        let currentYear = currentDate.getFullYear();

        // https://gist.github.com/danalloway/17b48fddab9028432c68

        // DST Start
        let firstOfMarch = new Date(currentYear, 2, 1);
        let daysUntilFirstSundayInMarch = (7 - firstOfMarch.getDay()) % 7;
        let secondSundayInMarch = firstOfMarch.getDate() + daysUntilFirstSundayInMarch + 7;
        this.dstStartDate = new Date(currentYear, 2, secondSundayInMarch);

        // DST End
        let firstOfNovember = new Date(currentYear, 10, 1);
        let daysUntilFirstSundayInNov = (7 - firstOfNovember.getDay()) % 7;
        let firstSundayInNovember = firstOfNovember.getDate() + daysUntilFirstSundayInNov;
        this.dstEndDate = new Date(currentYear, 10, firstSundayInNovember);

        //console.log("DST Start", this.dstStartDate);
        //console.log("DST End", this.dstEndDate);

        let isDST = false;
		let today = new Date();
		if (this.dstStartDate != null && this.dstEndDate != null && today >= this.dstStartDate && today <= this.dstEndDate){
			isDST = true;
            this.isDST = true;
		}
        console.log("getIsDST =>", isDST);
        this.filterTimezonesArray = isDST ? this.DaylightTimezones : this.StandardTimezones;


	}

    onGetAccountPatientsTimezonesAction(response) {
		console.log("onGetAccountPatientsTimezonesAction");
		console.log(response);

		if (response.ok) {

            this.state.patientsArray = [];

            this.sorteredArray1 =  [].concat(response.Data);
            this.sorteredArray1 =  this.sorteredArray1.sort((a, b) => {
                let textA = a.PatientName ? a.PatientName.toLowerCase() : "";
                let textB = b.PatientName ? b.PatientName.toLowerCase() : "";
                if (textB < textA) //sort string ascending
                    return -1
                if (textB > textA)
                    return 1
                return 0
            });

            this.sorteredArray2 = [].concat(response.Data);
            this.sorteredArray2 = this.sorteredArray2.sort((a, b) => {
                let textA = a.PatientName ? a.PatientName.toLowerCase() : "";
                let textB = b.PatientName ? b.PatientName.toLowerCase() : "";
                if (textA < textB) //sort string ascending
                    return -1
                if (textA > textB)
                    return 1
                return 0
            });

            this.setState({
				patientsArray: this.sorteredArray2,
                loading: false,
                sortPatientAsc  : ' nucleus-icon-active ',
                sortPatientDesc : ' nucleus-icon-inactive hide '
			});

		} else {
			Message.show("Error getting account patients, please try again.");
		}
	}

    handleSortPatientDesc(){
        console.log("handleSortPatientDesc");
        this.state.patientsArray = [];

        this.resetSortIconsState();

        this.setState({
            sortPatientAsc : ' nucleus-icon-inactive hide ',
            sortPatientDesc : ' nucleus-icon-active ',
            patientsArray : this.sorteredArray1
        });
    }

    handleSortPatientAsc(){
        console.log("handleSortPatientAsc");
        this.state.patientsArray = [];
        this.resetSortIconsState();
        this.setState({
            sortPatientAsc : ' nucleus-icon-active ',
            sortPatientDesc : ' nucleus-icon-inactive hide ',
            patientsArray : this.sorteredArray2
        });
    }

    resetSortIconsState(){
        console.log("resetSortIconsState");

        this.setState({

            sortPatientAsc : ' nucleus-icon-inactive ',
            sortPatientDesc : ' nucleus-icon-inactive hide '
        });
    }

    onRowClicked = (patientData)=>{
        console.log("onRowClicked patientData", patientData);

        this.setState({
            selectedPatientID: patientData.ID,
            selectedPatientName: patientData.PatientName,
            selectedPatientTimezoneOffset:patientData.TimezoneOffset,
            selectedPatientTimezoneString:patientData.TimezoneString
        });
        window.$("#modalPatientTimezone").modal("open");

        if (patientData.TimezoneOffset != null ){
            let defaultTimezone = patientData.TimezoneOffset.toString();
            console.log("TIMEZONE FORMAT 1 :", defaultTimezone);
            this.selectTimeZoneRef.current.value = defaultTimezone;
        }
        else{
            let today = new Date();
            let currentTimezone = (today.getTimezoneOffset() / 60) * -1;

            let formatedTimezone = "" + currentTimezone;
            if (currentTimezone > 0) {
                formatedTimezone = "+" + currentTimezone;
            }
            console.log("TIMEZONE FORMAT 2 :", formatedTimezone);
            this.selectTimeZoneRef.current.value = formatedTimezone;
        }
    }

    handleCancelUpdateTimezone=()=>{
        window.$("#modalPatientTimezone").modal("close");
        this.setState({
            selectedPatientID: null,
            selectedPatientName: null,
            selectedPatientTimezoneOffset:null,
            selectedPatientTimezoneString:null
        });
    }

    handleUpdateTimezone = ()=>{
        console.log("handleUpdateTimezone");
        console.log(this.selectTimeZoneRef.current.value);

        if (this.selectTimeZoneRef.current.value != null && this.selectTimeZoneRef.current.value != ""){
            let timezoneOffset = "".concat(this.selectTimeZoneRef.current.value);
            let timezoneName = this.isDST ? this.DaylightTimezonesKeys[timezoneOffset] : this.StandardTimezonesKeys[timezoneOffset] ;

            console.log("Data", timezoneOffset, timezoneName);
            let timezoneOffsetParam = ~~timezoneOffset;

            this.setState({
                modalBtnStyle: 'nucleus-submit-btn-disabled',
                modalLoadingText : "Updating Patient timezone and patient messages...",
                loading:true
            });
            CareAccountStore.UpdatePatientTimeZone({
                UserID : AuthStore.getCsUserID(),
                Token : AuthStore.getCsUserToken(),
                PatientID : this.state.selectedPatientID,
                TimeZoneString : timezoneName,
                GMTOffset : timezoneOffsetParam
            });
        }
        else{
            Message.show("Please select the new timezone");
        }
    }

    onUpdatePatientTimeZoneAction(response){
        console.log("onUpdatePatientTimeZoneAction", response);

        this.setState({
            modalBtnStyle: 'nucleus-submit-btn',
            modalLoadingText : "",
            loading: false
        });

        if (response.ok){
            Message.show("Patient Timezone updated!");
            CareAccountStore.getAccountPatientsTimezones({
                UserID : AuthStore.getCsUserID(),
                Token : AuthStore.getCsUserToken(),
                AccountID : this.props.accountId,
                loading: true
            });
        }
        else{
            Message.show("Error updating patient Tiemzone, please try again");
        }

        this.handleCancelUpdateTimezone();
    }

    handleEditClicked = (data) => {
		//window.location.assign("#/patientProfile/" + this.props.PatientID + "/null" );
		//console.log("handleRowClicked", this.props.data);
		this.onRowClicked && this.onRowClicked(data);
	}

    getTableRows = () => {
        console.log("render getTableRows>");

        let patientRowArray = [];
        if (this.state.patientsArray && this.state.patientsArray.length > 0){

            let array = [].concat(this.state.patientsArray);
            //let arrayToExport = [];
            //let deviceData = {};
            array.map((patient, key) => {
                //deviceData = Object.assign({},device);
                patientRowArray.push(
                    <AccountPatientsTimezoneRow key={key} data={patient} editOnClick={this.handleEditClicked} type="timezone" />
                );
                //arrayToExport.push(deviceData);
            });
            return (
                <tbody class="tbody-ss">
                    {patientRowArray}
                </tbody>
            );
        }
        return(
            <tbody class="center-align">
                <tr style={{borderBottomStyle:"none"}}>
                    <td colSpan="6" class="center-align ">
                        <br/><br/><br/>
                        <span class="nucleus-table-header-medium text-gray"> {`There are no ${this.props.patientsLabel} for this account`} </span>
                        <br/><br/><br/>
                    </td>
                </tr>
            </tbody>
        );
    }


    render()
    {
        let tableResultsView = ' hide';
		let tableLoadingView = '';
		if (this.state.loading){
			tableResultsView = ' hide';
			tableLoadingView = '';
		} else {
			tableResultsView = '';
			tableLoadingView = 'hide';
		}

        if (this.filterTimezonesArray  != null && this.filterTimezonesArray .length && this.messageFilterTimezonesArray.length == 0) {
            this.filterTimezonesArray .map((timezone, key) => {
                //console.log("Mapping ->", timezone);
                this.messageFilterTimezonesArray.push(<option key={key} value={timezone.valueString}>{timezone.name}</option>);
            });
        }

        return (
            <div class="">
                <table class="bordered highlight nucleus-table">
                    <thead class="thead-ss">
                        <tr>
                            <th class="table-col-35 left-align">
                                <span class="nucleus-table-header-medium">Patient Name</span>
                                <a class={ "nucleus-font-medium " + this.state.sortPatientAsc } onClick={this.handleSortPatientDesc} > &nbsp;▲</a>
                                <a class={ "nucleus-font-medium " + this.state.sortPatientDesc }  onClick={this.handleSortPatientAsc} >&nbsp;▼</a>
                            </th>
                            <th class="table-col-20 center-align">
                                <span class="nucleus-table-header-medium">Timezone Offset</span>
                                {/* <a class={ "nucleus-font-medium " + this.state.sortDeviceNameAsc } onClick={this.handleSortDeviceNameDesc} > &nbsp;▲</a>
                                <a class={ "nucleus-font-medium " + this.state.sortDeviceNameDesc }  onClick={this.handleSortDeviceNameAsc} >&nbsp;▼</a> */}
                            </th>

                            <th class="table-col-35 center-align">
                                <span class="nucleus-table-header-medium">Timezone Name</span>
                                {/* <a class={ "nucleus-font-medium " + this.state.sortVersionAsc } onClick={this.handleSortVersionDesc} > &nbsp;▲</a>
                                <a class={ "nucleus-font-medium " + this.state.sortVersionDesc }  onClick={this.handleSortVersionAsc} >&nbsp;▼</a> */}
                            </th>

                            <th class="table-col-10 center-align">
                                <span class="nucleus-table-header-medium">
                                    Edit
                                    {/* <span
                                        class="nucleus-table-icon tooltipped"
                                        data-delay="50"
                                        data-position="top"
                                        data-tooltip="Remove"
                                    >
                                        <i class="Tiny material-icons">edit</i>
                                    </span> */}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    {
                        this.getTableRows()
                    }
                </table>
                <div class={"row center-align " + tableLoadingView}>
                    <br />
                    <br />
                    <br />
                    <SpinnerCircular
                        color="#2096F3"
                        secondaryColor='rgba(0,0,0,0.16)'
                        size='50'
                        thickness='100'
                        />

                    <br />
                    <br />
                    <br />
                </div>

                <div id="modalPatientTimezone" class="modal modalPatientTimezone">
                    <div class="modal-content nucleus-modal-wrapper-scrollable2">
                        <img
                            class="responsive-img img-close-modal"
                            src="img/close_modal.png"
                            onClick={this.handleCancelUpdateTimezone}
                        />
                        <h3 class="nucleus-page-subtitle"> Set Patient Timezone</h3>
                        <br />
                        <br />
                        <div class="row">
                            <div class="col s12 no-margin">
                                <span class="nucleus-labels">
                                    You are updating the timezone of { this.state.selectedPatientName}
                                </span>
                            </div>
                            <div class="col s12 no-margin">
                                <span class="nucleus-labels">
                                    Current Timezone Offset: &nbsp; { this.state.selectedPatientTimezoneOffset}
                                </span>
                            </div>

                            <div class="col s12 no-margin">
                                <span class="nucleus-labels">
                                    Current Timezone Name: &nbsp; { this.state.selectedPatientTimezoneString}
                                </span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col s12 no-margin">
                                <span class="nucleus-labels">
                                    Please select one of the following options listed bellow:
                                </span>
                                <br/>
                                <select
									ref={this.selectTimeZoneRef}
									class="browser-default nucleus-select select-large"
								>
									{this.messageFilterTimezonesArray}
								</select>
                            </div>

                            <div class="input-field col s12 no-margin">
                                <br />
                                <div class="row center-align">
                                    <span class="nucleus-labels">
                                        {this.state.modalLoadingText}
                                        <br/>
                                    </span>
                                    <div class="col s6 center-align no-margin">
                                        <p></p>
                                        <a class={"txt-white nucleus-font-medium " + this.state.modalBtnStyle }  onClick={this.handleCancelUpdateTimezone}>Cancel</a>
                                    </div>
                                    <div class="col s6 center-align no-margin">
                                        <p></p>
                                        <a class={"txt-white nucleus-font-medium " + this.state.modalBtnStyle } onClick={this.handleUpdateTimezone}>Confirm</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default PatientTimezones;
