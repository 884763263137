import React from "react";

import { useState, useEffect, useRef } from "react";

import NucleuscareConnectData, {
  getExternalAppsConfigThunk,
  enableExternalAppsThunk,
  RootState,
} from '@nucleus-care/nucleuscare-backend-client';
import { useSelector, useDispatch } from "react-redux";
import Message from "../utils/Message";

const AppsTab = (props) => {
  const accountConfig = useSelector((state: RootState) => {
    //console.log("state.accountExternalApps.config", state.accountExternalApps.config);
    return state.accountExternalApps.config;
  });
  const dispatch = useDispatch();

  const [enabledAppFeature, setEnabledAppFeature] = useState(false);

  const handleExternalAppsEnabled = (e) => {
    console.log("handleExternalAppsEnabled ", e.target.checked);
    dispatch(
      enableExternalAppsThunk({
        AccountID: props.AccountID,
        Enabled: e.target.checked,
      })
    );
    console.log("handleAppsEnabled ", e.target.checked);
  };

  useEffect(() => {
    console.log("ClassSession useEffect");
    console.log("AccountID", props.AccountID);

    dispatch(
      getExternalAppsConfigThunk({
        AccountID: props.AccountID,
      })
    );
  }, []);

  useEffect(() => {
    if (!accountConfig.getPending && accountConfig.getComplete) {
      setEnabledAppFeature(accountConfig.ExternalAppsEnabled);
    }
  }, [accountConfig.getPending, accountConfig.getComplete]);

  useEffect(() => {
    console.log("ExternalApps useEffect accountConfig Status");
    console.log("accountConfig", accountConfig);

    if (accountConfig.updateComplete && !accountConfig.updatePending) {
      Message.show("Configuration updated");
      dispatch(
        getExternalAppsConfigThunk({
          AccountID: props.AccountID,
        })
      );
    }
  }, [accountConfig.updatePending, accountConfig.updateComplete]);

  return (
    <div class="border1">
      <div class="col s4 m4 l4 xl4 no-margin">
        <div class="row vbottom-align no-margin top-separated">
          <div class="row bit-top-separated">
            <div class="col s9">
              <span class=" nucleus-labels">Enable Apps</span>
            </div>
            <div class="col s2">
              <div class="switch a-more-right">
                <label>
                  <input
                    disabled={
                      accountConfig.updatePending || accountConfig.getPending
                    }
                    type="checkbox"
                    checked={enabledAppFeature}
                    onChange={handleExternalAppsEnabled}
                  />
                  <span class="lever nucleus-check"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col s4 m4 l4 xl4 no-margin"></div>

      <div class="col s4 m4 l4 xl4 no-margin"></div>
    </div>
  );
};

export default AppsTab;
