import React from 'react';
import ReactDOM from "react-dom";

import Message from "../utils/Message";
import CareAccountStore from "../stores/CareAccountStore";

class AccountBioDeviceItemRow extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            status : false, 
        };

        //this.dateOptions = { day: "2-digit", month: "2-digit", year : "numeric" };
        //this.timeOptions = { hour12 : true, hour: "2-digit", minute: "2-digit" };
        
        this.handleRowClicked = this.handleRowClicked.bind(this);

        // DEPRECATED Nov 20th 2019, Change on Bluetooth Biometric Devices
        // https://nucleusintercom.atlassian.net/browse/CARE-6158
        //this.handleModifyButton = this.handleModifyButton.bind(this);
        //this.handleRemoveButton = this.handleRemoveButton.bind(this);
        
    }

    componentDidMount()
    {
        //console.log(this.state.patientID);
        
        //window.$('.tooltipped').tooltip({delay: 50});
    }

    componentWillUnmount()
    {
        //window.$('.tooltipped').tooltip('remove');
    }

    handleRowClicked(){
        //window.location.assign("#/patientProfile/" + this.props.PatientID + "/null" );
    }

    //this.handleModifyButton = this.handleModifyButton.bind(this);
    //this.handleRemoveButton = this.handleRemoveButton.bind(this);

    // handleModifyButton(){
        
    //     CareAccountStore.handleOpenEditBioDeviceModal({
    //         Mac : this.props.Mac,
    //         Type : this.props.Type
    //     });
    // }


    // handleRemoveButton(){

    //     CareAccountStore.handleOpenDeleteBioDeviceModal({
    //         Mac : this.props.Mac
    //     });
    // }

    

    render()
    {
        /*
        let deviceStatusIcon = "img/icon_device_inactive.png";
        let deviceLastCheckIn = "Button not assigned";

        deviceStatusIcon = (this.props.Online) ? "img/icon_device_active.png" : "img/icon_device_inactive.png";
        deviceLastCheckIn = "Button Offline";

        if (this.props.LastCheckIn){
            deviceLastCheckIn = new Date(this.props.LastCheckIn);
            deviceLastCheckIn = deviceLastCheckIn.toLocaleDateString('en-US', this.dateOptions) + " " + deviceLastCheckIn.toLocaleTimeString('en-US', this.timeOptions);
        }
        */ 

        return (

            <tr >
                
                <td class="">{ this.props.Mac }</td>
                <td class="">{ this.props.Name }</td>
                <td class="">{ this.props.PatientName }</td>
                <td class="center-align">{ this.props.TimesUsed }</td>
                <td class="">{ this.props.LastUsed }</td>
                {/*
                //this.handleModifyButton = this.handleModifyButton.bind(this);
                //this.handleRemoveButton = this.handleRemoveButton.bind(this);
                
                <td class="center-align">
                    <span class="nucleus-table-icon nucleus-link tooltipped" data-position="top" data-tooltip="Edit">
                        <i class="Tiny material-icons" onClick={this.handleModifyButton}>edit</i>
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span class="nucleus-table-icon nucleus-link tooltipped" data-position="top" data-tooltip="Remove">
                        <i class="Tiny material-icons" onClick={this.handleRemoveButton}>not_interested</i>
                    </span>
                </td>
                */}
            </tr>
               
        );
    }
}

export default AccountBioDeviceItemRow;
