import { useSelector } from 'react-redux';

export function useClassSessionPluralLabelSelector () {
  return useSelector(({ accountClassSessionConfig }) => {
    if (!accountClassSessionConfig) return ''
    const { config } = accountClassSessionConfig
    if (!config) return ''
    const { TabNameLabelPlural = '' } = config
    return TabNameLabelPlural
  })
}

export function useClassSessionAccessSelector () {
  return useSelector(({ accountClassSessionConfig }) => {
    if (!accountClassSessionConfig) return false
    const { config } = accountClassSessionConfig
    if (!config) return false
    const { Enabled = false } = config
    return Enabled
  })
}

export function useAccountClassSessionConfigSelector () {
  return useSelector(({ accountClassSessionConfig }) => {
    console.log("accountGlobalSelector", accountClassSessionConfig);
    return accountClassSessionConfig;
  })
}

export function useHasSalesforceIntegration () {
  return useSelector(({ accountIntegrationConfig }) => {
    console.log("accountIntegrationConfig", accountIntegrationConfig);
    return accountIntegrationConfig.config.salesforce;
  })
}
