import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import AuthStore from "../stores/AuthStore";
import CareAccountStore from "../stores/CareAccountStore";

import Message from '../utils/Message';
import AccountDeviceItemRow from './AccountDeviceItemRow';

class AccountDevices extends React.Component {

    constructor(props){
        super(props);

        this.state ={
            deviceSearchText: "",

            sortPatientDesc : ' nucleus-icon-inactive hide ',
            sortPatientAsc : ' nucleus-icon-inactive ',

            sortDeviceNameDesc: ' nucleus-icon-inactive hide ',
            sortDeviceNameAsc: ' nucleus-icon-inactive ',

            sortConnectedDesc : ' nucleus-icon-inactive hide ',
            sortConnectedAsc : ' nucleus-icon-inactive ',

            sortMacDesc : ' nucleus-icon-inactive hide ',
            sortMacAsc : ' nucleus-icon-inactive ',

            sortPreNameDesc : ' nucleus-icon-inactive hide ',
            sortPreNameAsc : ' nucleus-icon-inactive ',

            sortVersionDesc : ' nucleus-icon-inactive hide ',
            sortVersionAsc : ' nucleus-icon-inactive ',

            sortBatteryDesc : ' nucleus-icon-inactive hide ',
            sortBatteryAsc : ' nucleus-icon-inactive ',

            sortStatusDesc : ' nucleus-icon-inactive hide ',
            sortStatusAsc : ' nucleus-icon-inactive ',

            sortTimeZoneDesc : ' nucleus-icon-inactive hide ',
            sortTimeZoneAsc : ' nucleus-icon-inactive ',
            actionActivated: false,

            selectedDeviceDetails : {
                DeviceInfo:{},
                DeviceDetails:{}
            }
        }
        this.onGetAccountDevicesAction = this.onGetAccountDevicesAction.bind(this);
        this.handleDeviceSearchText = this.handleDeviceSearchText.bind(this);

        this.sorteredArray1 = [];
        this.sorteredArray2 = [];
        this.sorteredArray3 = [];
        this.sorteredArray4 = [];
        this.sorteredArray5 = [];
        this.sorteredArray6 = [];

        this.sorteredArrayMac1 = [];
        this.sorteredArrayMac2 = [];

        this.sorteredArrayPreName1 = [];
        this.sorteredArrayPreName2 = [];

        this.sorteredArrayVersion1 = [];
        this.sorteredArrayVersion2 = [];

        this.sorteredArrayBattery1 = [];
        this.sorteredArrayBattery2 = [];

        this.sorteredArrayStatus1 = [];
        this.sorteredArrayStatus2 = [];

        this.sorteredArrayTimeZone1 = [];
        this.sorteredArrayTimeZone2 = [];

        this.handleSortPatientDesc = this.handleSortPatientDesc.bind(this);
        this.handleSortPatientAsc = this.handleSortPatientAsc.bind(this);

        this.handleSortDeviceNameDesc = this.handleSortDeviceNameDesc.bind(this);
        this.handleSortDeviceNameAsc = this.handleSortDeviceNameAsc.bind(this);

        this.handleSortConnectedDesc = this.handleSortConnectedDesc.bind(this);
        this.handleSortConnectedAsc = this.handleSortConnectedAsc.bind(this);

        this.handleSortMacDesc = this.handleSortMacDesc.bind(this);
        this.handleSortMacAsc = this.handleSortMacAsc.bind(this);

        this.handleSortPreNameDesc = this.handleSortPreNameDesc.bind(this);
        this.handleSortPreNameAsc = this.handleSortPreNameAsc.bind(this);

        this.handleSortVersionDesc = this.handleSortVersionDesc.bind(this);
        this.handleSortVersionAsc = this.handleSortVersionAsc.bind(this);

        this.handleSortBatteryDesc = this.handleSortBatteryDesc.bind(this);
        this.handleSortBatteryAsc = this.handleSortBatteryAsc.bind(this);

        this.handleSortStatusDesc = this.handleSortStatusDesc.bind(this);
        this.handleSortStatusAsc = this.handleSortStatusAsc.bind(this);

        this.handleSortTimeZoneDesc = this.handleSortTimeZoneDesc.bind(this);
        this.handleSortTimeZoneAsc = this.handleSortTimeZoneAsc.bind(this);

        this.onRowClicked = this.onRowClicked.bind(this);
        this.handleCloseDeviceDetailsModal = this.handleCloseDeviceDetailsModal.bind(this);

        this.onGetDeviceInfoAction = this.onGetDeviceInfoAction.bind(this);
    }

    componentDidMount(){
        //Register Listener
        CareAccountStore.on("onGetAccountDevices", this.onGetAccountDevicesAction);
        CareAccountStore.on("onGetDeviceInfo", this.onGetDeviceInfoAction);
        window.$('.modal').modal({
            dismissible: false,
            complete: () => {  } // Callback for Modal close
        });
    }

    componentWillUnmount(){
        //Remove Listener
        CareAccountStore.removeListener("onGetAccountDevices",this.onGetAccountDevicesAction);
        CareAccountStore.removeListener("onGetDeviceInfo", this.onGetDeviceInfoAction);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.actionActivated) {
            this.sorteredArray1 = this.sorteredArray1;
            this.sorteredArray2 = this.sorteredArray2;
            this.sorteredArray3 = this.sorteredArray3;
            this.sorteredArray4 = this.sorteredArray4;
            this.sorteredArray5 = this.sorteredArray5;
            this.sorteredArray6 = this.sorteredArray6;

            this.sorteredArrayMac1 = this.sorteredArrayMac1;
            this.sorteredArrayMac2 = this.sorteredArrayMac2 ;

            this.sorteredArrayPreName1 = this.sorteredArrayPreName1;
            this.sorteredArrayPreName2 = this.sorteredArrayPreName2;

            this.sorteredArrayVersion1 = this.sorteredArrayVersion1;
            this.sorteredArrayVersion2 = this.sorteredArrayVersion2;

            this.sorteredArrayBattery1 = this.sorteredArrayBattery1;
            this.sorteredArrayBattery2 = this.sorteredArrayBattery2;

            this.sorteredArrayStatus1 = this.sorteredArrayStatus1;
            this.sorteredArrayStatus2 = this.sorteredArrayStatus2;

            this.sorteredArrayTimeZone1 = this.sorteredArrayTimeZone1;
            this.sorteredArrayTimeZone2 = this.sorteredArrayTimeZone2;
            this.setState({
                sortPatientDesc : prevState.sortPatientDesc,
                sortPatientAsc : prevState.sortPatientAsc,

                sortDeviceNameDesc: prevState.sortDeviceNameDesc,
                sortDeviceNameAsc: prevState.sortDeviceNameAsc,

                sortConnectedDesc : prevState.sortConnectedDesc,
                sortConnectedAsc : prevState.sortConnectedAsc,

                sortMacDesc : prevState.sortMacDesc,
                sortMacAsc : prevState.sortMacAsc,

                sortPreNameDesc : prevState.sortPreNameDesc,
                sortPreNameAsc : prevState.sortPreNameAsc,

                sortVersionDesc : prevState.sortVersionDesc,
                sortVersionAsc : prevState.sortVersionAsc,

                sortBatteryDesc : prevState.sortBatteryDesc,
                sortBatteryAsc : prevState.sortBatteryAsc,

                sortStatusDesc : prevState.sortStatusDesc,
                sortStatusAsc : prevState.sortStatusAsc,

                sortTimeZoneDesc : prevState.sortTimeZoneDesc,
                sortTimeZoneAsc :prevState.sortTimeZoneAsc,
                actionActivated: false
            });
        }
    }

    handleAction = () => {
        this.setState({actionActivated: true});
    }

    refreshDevices = () => {
        CareAccountStore.getAccountDevices(
            {
                UserID: AuthStore.getCsUserID(),
                Token: AuthStore.getCsUserToken(),
                AccountID : this.props.accountId
            }
        );
        this.setState({actionActivated: true});
    }
    onRowClicked(deviceData){
        console.log("onRowClicked", deviceData);
        window.$('#modalDeviceDetails').modal('open');
        document.addEventListener("keydown", this.escFunction);
        let device = Object.assign({}, deviceData);
        try {
            device.DeviceDetails = {};
            if (device.DeviceInfo2 && device.DeviceInfo2 != "" && device.DeviceInfo2 != "{}"){
                device.DeviceInfo2 = JSON.parse(device.DeviceInfo2);
            }
            if (device.HardwareInfo && device.HardwareInfo != "" && device.HardwareInfo != "{}"){
                device.DeviceDetails = JSON.parse(device.HardwareInfo);
            }
        } catch (error) {
            console.error("Error T")
            device.DeviceInfo = {};
            device.DeviceDetails = {};
        }

        CareAccountStore.getDeviceInfo({
            UserID: AuthStore.getCsUserID(),
			Token: AuthStore.getCsUserToken(),
            DeviceID : deviceData.ID
        });

        this.setState({
            selectedDeviceDetails : device
        });
    }

    onGetDeviceInfoAction(deviceData){
        //console.log("onGetDeviceInfoAction", deviceData);

        let wifiInfo = {};
        let hexnodeInfo = {};
        try {
            if (deviceData && deviceData.HardwareInfo){
                wifiInfo = JSON.parse(deviceData.HardwareInfo);
                //console.log("WifiInfo", wifiInfo);
                if (wifiInfo.wifi){
                    //console.log("Ready", wifiInfo.wifi);
                } else{
                    wifiInfo = wifiInfo;
                    //console.log("Ready 2", wifiInfo);
                }
            }
            if (deviceData && deviceData.HexnodeInfo){
                hexnodeInfo = JSON.parse(deviceData.HexnodeInfo);
                //console.log("hexnodeInfo", hexnodeInfo);
                if (hexnodeInfo.device){
                    hexnodeInfo = hexnodeInfo.device;
                }
            }
        } catch (error) {
            console.log("updateDeviceDetails error:", error);
        }
        let tempDeviceDetails = Object.assign({}, this.state.selectedDeviceDetails);
        tempDeviceDetails.DeviceDetails = {};
        tempDeviceDetails.DeviceDetails.Wifi = wifiInfo.wifi;
        tempDeviceDetails.DeviceDetails.SerialNumber = hexnodeInfo.serial_number;

        this.setState({
            selectedDeviceDetails : tempDeviceDetails
        });
    }

    handleCloseDeviceDetailsModal(){
        console.log("handleCloseDeviceDetailsModal");

        window.$('#modalDeviceDetails').modal('close');
        //setStateDetailsModalOpen(false);
        this.setState({
            DeviceInfo:{},
            DeviceDetails:{}
        }, () => {
            document.removeEventListener("keydown", this.escFunction);
        });
    }

    escFunction =(event) => {
        console.log(event.key)
        if (event.key === "Escape") {
          this.handleCloseDeviceDetailsModal()
        }
    }

    onGetAccountDevicesAction(response) {
        console.log("onGetAccountDevicesAction");
        console.log(response);

        this.setState({
            loading:false
        });

        if (response && response.ok) {
            this.setState({
                accountDevicesArray: [],
                accountDevices: 0,
            });

            this.sorteredArray1 =  [].concat(response.deviceList);
            /*this.sorteredArray1 =  this.sorteredArray1.sort((a, b) => {
                let textA = (a.PatientFirstName + " " + a.PatientLastName).toLowerCase();
                let textB = (b.PatientFirstName + " " + b.PatientLastName).toLowerCase();
                if (textB < textA) //sort string ascending
                    return -1
                if (textB > textA)
                    return 1
                return 0
            });*/

            this.setState({
                accountDevicesArray: response.deviceList,
                accountDevices: response.deviceList.length,


            });

            this.sorteredArray2 = [].concat(response.deviceList);
           /* this.sorteredArray2 = this.sorteredArray2.sort((a, b) => {
                let textA = (a.PatientFirstName + " " + a.PatientLastName).toLowerCase();
                let textB = (b.PatientFirstName + " " + b.PatientLastName).toLowerCase();
                if (textA < textB) //sort string ascending
                    return -1
                if (textA > textB)
                    return 1
                return 0
            });*/

            this.sorteredArray3 = [].concat(response.deviceList);
            this.sorteredArray4 = [].concat(response.deviceList);

            this.sorteredArray3 = this.sorteredArray3.sort((a, b) => {
                let textA = a.DeviceName.toLowerCase();
                let textB = b.DeviceName.toLowerCase();
                if (textB < textA) //sort string ascending
                    return -1
                if (textB > textA)
                    return 1
                return 0
            });

            this.sorteredArray4 = this.sorteredArray4.sort((a, b) => {
                let textA = a.DeviceName.toLowerCase();
                let textB = b.DeviceName.toLowerCase();
                if (textA < textB) //sort string ascending
                    return -1
                if (textA > textB)
                    return 1
                return 0
            });

            this.sorteredArray5 = [].concat(response.deviceList);
            this.sorteredArray6 = [].concat(response.deviceList);

            this.sorteredArray5 = this.sorteredArray1.sort((a, b) => (new Date(b.DeviceInfoLastCheckinTime)) - (new Date(a.DeviceInfoLastCheckinTime)));
            this.sorteredArray6 = this.sorteredArray2.sort((a, b) => (new Date(a.DeviceInfoLastCheckinTime)) - (new Date(b.DeviceInfoLastCheckinTime)));

            this.sorteredArrayMac1 = [].concat(response.deviceList);
            this.sorteredArrayMac2 = [].concat(response.deviceList);

            /*this.sorteredArrayMac1 = this.sorteredArrayMac1.sort((a, b) => {
                let textA = a.Mac.toLowerCase();
                if (a.HexnodeID != null){
                    textA = a.HexnodeID;
                }
                let textB = b.Mac.toLowerCase();
                if (b.HexnodeID != null){
                    textA = b.HexnodeID;
                }
                if (textB < textA) //sort string ascending
                    return -1
                if (textB > textA)
                    return 1
                return 0
            });*/

            /*this.sorteredArrayMac2 = this.sorteredArrayMac2.sort((a, b) => {
                let textA = a.Mac.toLowerCase();
                if (a.HexnodeID != null){
                    textA = a.HexnodeID;
                }
                let textB = b.Mac.toLowerCase();
                if (b.HexnodeID != null){
                    textA = b.HexnodeID;
                }
                if (textA < textB) //sort string ascending
                    return -1
                if (textA > textB)
                    return 1
                return 0
            });*/

            this.sorteredArrayPreName1 = [].concat(response.deviceList);
            this.sorteredArrayPreName2 = [].concat(response.deviceList);

            this.sorteredArrayPreName1 = this.sorteredArrayPreName1.sort((a, b) => {
                let textA = a.PreAssignedName ? a.PreAssignedName.toLowerCase() : "";
                let textB = b.PreAssignedName ? b.PreAssignedName.toLowerCase() : "";
                if (textB < textA) //sort string ascending
                    return -1
                if (textB > textA)
                    return 1
                return 0
            });

            this.sorteredArrayPreName2 = this.sorteredArrayPreName2.sort((a, b) => {
                let textA = a.PreAssignedName ? a.PreAssignedName.toLowerCase() : "";
                let textB = b.PreAssignedName ? b.PreAssignedName.toLowerCase() : "";
                if (textA < textB) //sort string ascending
                    return -1
                if (textA > textB)
                    return 1
                return 0
            });

            this.sorteredArrayVersion1 = [].concat(response.deviceList);
            this.sorteredArrayVersion2 = [].concat(response.deviceList);

            this.sorteredArrayVersion1 = this.sorteredArrayVersion1.sort((a, b) => {
                let textA = "";
                if (a.DeviceInfoAppVersion){
                    textA = a.DeviceInfoAppVersion;
                }
                let textB = "";
                if (b.DeviceInfoAppVersion){
                    textB = b.DeviceInfoAppVersion;
                }
                if (textB < textA) //sort string ascending
                    return -1
                if (textB > textA)
                    return 1
                return 0
            });

            this.sorteredArrayVersion2 = this.sorteredArrayVersion2.sort((a, b) => {
                let textA = "";
                if (a.DeviceInfoAppVersion){
                    textA = a.DeviceInfoAppVersion;
                }
                let textB = "";
                if (b.DeviceInfoAppVersion){
                    textB = b.DeviceInfoAppVersion;
                }
                if (textA < textB) //sort string ascending
                    return -1
                if (textA > textB)
                    return 1
                return 0
            });

            this.sorteredArrayBattery1 = [].concat(response.deviceList);
            this.sorteredArrayBattery2 = [].concat(response.deviceList);

            this.sorteredArrayBattery1 = this.sorteredArrayBattery1.sort((a, b) => {
                let textA = 0;
                if (a.Battery){
                    textA = a.Battery;
                }
                let textB = 0;
                if (b.Battery){
                    textB = b.Battery;
                }
                if (textB < textA) //sort string ascending
                    return -1
                if (textB > textA)
                    return 1
                return 0
            });

            this.sorteredArrayBattery2 = this.sorteredArrayBattery2.sort((a, b) => {
                let textA = 0;
                let textB = 0;
                if (a.Battery){
                    textA = a.Battery;
                }
                if (b.Battery){
                    textB = b.Battery;
                }
                if (textA < textB) //sort string ascending
                    return -1
                if (textA > textB)
                    return 1
                return 0
            });

            this.sorteredArrayStatus1 = [].concat(response.deviceList);
            this.sorteredArrayStatus2 = [].concat(response.deviceList);

            this.sorteredArrayStatus1 = this.sorteredArrayStatus1.sort((a, b) => {
                let textA = 0;
                if (a.DeviceInfoOnline){
                    textA = 1;
                }
                let textB = 0;
                if (b.DeviceInfoOnline){
                    textB = 1;
                }
                if (textB < textA) //sort string ascending
                    return -1
                if (textB > textA)
                    return 1
                return 0
            });

            this.sorteredArrayStatus2 = this.sorteredArrayStatus2.sort((a, b) => {
                let textA = 0;
                if (a.DeviceInfoOnline){
                    textA = 1;
                }
                let textB = 0;
                if (b.DeviceInfoOnline){
                    textB = 1;
                }
                if (textA < textB) //sort string ascending
                    return -1
                if (textA > textB)
                    return 1
                return 0
            });

            this.sorteredArrayTimeZone1 = [].concat(response.deviceList);
            this.sorteredArrayTimeZone2 = [].concat(response.deviceList);

            this.sorteredArrayTimeZone1 = this.sorteredArrayTimeZone1.sort((a, b) => {
                let textA = a.DeviceInfoTimezone ? a.DeviceInfoTimezone.toLowerCase() : "";
                let textB = b.DeviceInfoTimezone ? b.DeviceInfoTimezone.toLowerCase() : "";
                if (textB < textA) //sort string ascending
                    return -1
                if (textB > textA)
                    return 1
                return 0
            });

            this.sorteredArrayTimeZone2 = this.sorteredArrayTimeZone2.sort((a, b) => {
                let textA = a.DeviceInfoTimezone ? a.DeviceInfoTimezone.toLowerCase() : "";
                let textB = b.DeviceInfoTimezone ? b.DeviceInfoTimezone.toLowerCase() : "";
                if (textA < textB) //sort string ascending
                    return -1
                if (textA > textB)
                    return 1
                return 0
            });

            // this.devicesInfoReloaded = this.devicesInfoReloaded + 1;
            // if (this.devicesInfoReloaded == 1) {
            //     //Message.show("Devices information reloaded.");
            // }
            // if (this.state.missingBiometricDevicesInfo) {
            //     this.refreshBiometricDevicesData();
            // }
        } else {
            Message.show("Error getting account devices, please try again.");
        }
    }


   handleDeviceSearchText(e) {
        this.setState({
            deviceSearchText: e.target.value,
        });
    }

    handleSortPatientDesc(){
        console.log("handleSortPatientDesc");
        this.devicesRendered = false;
        this.setState({accountDevicesArray: []});

        this.resetSortIconsState();

        this.sorteredArray1 =  this.sorteredArray1.sort((a, b) => {
            let textA = (a.PatientFirstName + " " + a.PatientLastName).toLowerCase();
            let textB = (b.PatientFirstName + " " + b.PatientLastName).toLowerCase();
            if (textB < textA) //sort string ascending
                return -1
            if (textB > textA)
                return 1
            return 0
        });

        this.setState({
            sortPatientAsc : ' nucleus-icon-inactive hide ',
            sortPatientDesc : ' nucleus-icon-active ',
            accountDevicesArray : this.sorteredArray1
        });
        this.devicesRendered = false;
    }

    handleSortPatientAsc(){
        console.log("handleSortPatientAsc");
        this.devicesRendered = false;
       this.setState({accountDevicesArray: []});
        this.resetSortIconsState();

        this.sorteredArray2 = this.sorteredArray2.sort((a, b) => {
            let textA = (a.PatientFirstName + " " + a.PatientLastName).toLowerCase();
            let textB = (b.PatientFirstName + " " + b.PatientLastName).toLowerCase();
            if (textA < textB) //sort string ascending
                return -1
            if (textA > textB)
                return 1
            return 0
        });

        this.setState({
            sortPatientAsc : ' nucleus-icon-active ',
            sortPatientDesc : ' nucleus-icon-inactive hide ',
            accountDevicesArray : this.sorteredArray2
        });
        this.devicesRendered = false;
    }

    handleSortDeviceNameDesc(){
        console.log("handleSortDeviceNameDesc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortDeviceNameAsc: ' nucleus-icon-inactive hide ',
            sortDeviceNameDesc: ' nucleus-icon-active ',
            accountDevicesArray : this.sorteredArray3
        });

        this.devicesRendered = false;
    }

    handleSortDeviceNameAsc(){
        console.log("handleSortDeviceNameAsc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortDeviceNameAsc: ' nucleus-icon-active ',
            sortDeviceNameDesc: ' nucleus-icon-inactive hide ',

            accountDevicesArray : this.sorteredArray4
        });
        this.devicesRendered = false;
    }

    handleSortConnectedDesc(){
        console.log("handleSortConnectedDesc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortConnectedAsc : ' nucleus-icon-inactive hide ',
            sortConnectedDesc : ' nucleus-icon-active ',
            accountDevicesArray : this.sorteredArray5
        });
        this.devicesRendered = false;

    }

    handleSortConnectedAsc(){
        console.log("handleSortConnectedAsc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();

        this.setState({
            sortConnectedAsc : ' nucleus-icon-active ',
            sortConnectedDesc : ' nucleus-icon-inactive hide ',
            accountDevicesArray : this.sorteredArray6
        });
        this.devicesRendered = false;
    }

    handleSortMacAsc(){
        console.log("handleSortMacAsc");
        this.devicesRendered = false;
        this.setState({accountDevicesArray: []});
        this.resetSortIconsState();

        this.sorteredArrayMac1 = this.sorteredArrayMac1.sort((a, b) => {
            let textA = a.HexnodeID;
            let textB = b.HexnodeID;
            if (textA < textB) //sort string ascending
                return -1
            if (textA > textB)
                return 1
            return 0
        });

        this.setState({
            sortMacDesc : ' nucleus-icon-active hide ',
            sortMacAsc : ' nucleus-icon-active ',
            accountDevicesArray : this.sorteredArrayMac1
        });
        this.devicesRendered = false;
    }

    handleSortMacDesc(){
        console.log("handleSortMacDesc");
        this.devicesRendered = false;
        this.setState({accountDevicesArray: []});
        this.resetSortIconsState();

        this.sorteredArrayMac2 = this.sorteredArrayMac2.sort((a, b) => {
            let textA = a.HexnodeID;
            let textB = b.HexnodeID;
            if (textB < textA) //sort string ascending
                return -1
            if (textB > textA)
                return 1
            return 0
        });

        this.setState({
            sortMacDesc : ' nucleus-icon-active ',
            sortMacAsc : ' nucleus-icon-active hide ',
            accountDevicesArray : this.sorteredArrayMac2
        });
        this.devicesRendered = false;
    }

    handleSortPreNameAsc(){
        console.log("handleSortPreNameAsc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortPreNameDesc : ' nucleus-icon-active hide ',
            sortPreNameAsc : ' nucleus-icon-active ',
            accountDevicesArray : this.sorteredArrayPreName2
        });
        this.devicesRendered = false;
    }

    handleSortPreNameDesc(){
        console.log("handleSortPreNameDesc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortPreNameDesc : ' nucleus-icon-active ',
            sortPreNameAsc : ' nucleus-icon-active hide ',
            accountDevicesArray : this.sorteredArrayPreName1
        });
        this.devicesRendered = false;
    }


    handleSortVersionAsc(){
        console.log("handleSortVersionAsc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortVersionDesc : ' nucleus-icon-active hide ',
            sortVersionAsc : ' nucleus-icon-active ',
            accountDevicesArray : this.sorteredArrayVersion2
        });
        this.devicesRendered = false;
    }

    handleSortVersionDesc(){
        console.log("handleSortVersionDesc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortVersionDesc : ' nucleus-icon-active ',
            sortVersionAsc : ' nucleus-icon-active hide ',
            accountDevicesArray : this.sorteredArrayVersion1
        });
        this.devicesRendered = false;
    }

    handleSortBatteryAsc(){
        console.log("handleSortBatteryAsc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortBatteryDesc : ' nucleus-icon-active hide ',
            sortBatteryAsc : ' nucleus-icon-active ',
            accountDevicesArray : this.sorteredArrayBattery2
        });
        this.devicesRendered = false;
    }

    handleSortBatteryDesc(){
        console.log("handleSortBatteryDesc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortBatteryDesc : ' nucleus-icon-active ',
            sortBatteryAsc : ' nucleus-icon-active hide ',
            accountDevicesArray : this.sorteredArrayBattery1
        });
        this.devicesRendered = false;
    }

    handleSortStatusAsc(){
        console.log("handleSortStatusAsc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortStatusDesc : ' nucleus-icon-active hide ',
            sortStatusAsc : ' nucleus-icon-active ',
            accountDevicesArray : this.sorteredArrayStatus2
        });
        this.devicesRendered = false;
    }

    handleSortStatusDesc(){
        console.log("handleSortStatusDesc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortStatusDesc : ' nucleus-icon-active ',
            sortStatusAsc : ' nucleus-icon-active hide ',
            accountDevicesArray : this.sorteredArrayStatus1
        });
        this.devicesRendered = false;
    }

    handleSortTimeZoneAsc(){
        console.log("handleSortTimeZoneAsc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortTimeZoneDesc : ' nucleus-icon-active hide ',
            sortTimeZoneAsc : ' nucleus-icon-active ',
            accountDevicesArray : this.sorteredArrayTimeZone2
        });
        this.devicesRendered = false;
    }

    handleSortTimeZoneDesc(){
        console.log("handleSortTimeZoneDesc");
        this.devicesRendered = false;
        this.state.accountDevicesArray = [];
        this.resetSortIconsState();
        this.setState({
            sortTimeZoneDesc : ' nucleus-icon-active ',
            sortTimeZoneAsc : ' nucleus-icon-active hide ',
            accountDevicesArray : this.sorteredArrayTimeZone1
        });
        this.devicesRendered = false;
    }

    resetSortIconsState(){
        console.log("resetSortIconsState");

        this.setState({

            sortPatientAsc : ' nucleus-icon-inactive ',
            sortPatientDesc : ' nucleus-icon-inactive hide ',

            sortDeviceNameAsc : ' nucleus-icon-inactive ',
            sortDeviceNameDesc : ' nucleus-icon-inactive hide ',

            sortConnectedAsc : ' nucleus-icon-inactive ',
            sortConnectedDesc : ' nucleus-icon-inactive hide ',

            sortMacAsc : ' nucleus-icon-inactive ',
            sortMacDesc : ' nucleus-icon-inactive hide ',

            sortPreNameAsc : ' nucleus-icon-inactive ',
            sortPreNameDesc : ' nucleus-icon-inactive hide ',

            sortVersionAsc : ' nucleus-icon-inactive ',
            sortVersionDesc : ' nucleus-icon-inactive hide ',

            sortBatteryAsc : ' nucleus-icon-inactive ',
            sortBatteryDesc : ' nucleus-icon-inactive hide ',

            sortStatusAsc : ' nucleus-icon-inactive ',
            sortStatusDesc : ' nucleus-icon-inactive hide ',

            sortTimeZoneAsc : ' nucleus-icon-inactive ',
            sortTimeZoneDesc : ' nucleus-icon-inactive hide ',
        });
    }

   render()
   {
    let accountDevicesList = [];
    let deviceTextToSearch = this.state.deviceSearchText.toLowerCase();

    if (this.state.accountDevicesArray != null) {
        let deviceMac = "";
        this.state.accountDevicesArray.map((device, key) => {
            deviceMac = device.Mac ? device.Mac.toLowerCase() : device.Mac;
            device.PreAssignedName || (device.PreAssignedName = "");
            device.Serial || (device.Serial = "");

            //console.log("device", device.PreAssignedName)
            //console.log("deviceSearchText", device.PreAssignedName.indexOf(deviceTextToSearch));
            let patientName = (device.PatientFirstName + " " + device.PatientLastName).toLowerCase();
            let version = device.DeviceInfoAppVersion || "";

            if (deviceTextToSearch.length == 0){
                const isChecked = this.props.selectedDevicesToMove[device.ID] ? true : false
                accountDevicesList.push(
                    <AccountDeviceItemRow
                    key={key}
                    DeviceAssigned={device.DeviceAssigned}
                    ID={device.ID}
                    Mac={device.Mac}
                    DeviceUID={device.DeviceUID || ""}
                    Serial={device.Serial || ""}
                    HexnodeID={device.HexnodeID || ""}
                    MdmDeviceID={device.MdmDeviceID || ""}
                    MdmID={device.MdmID || ""}
                    Battery={device.Battery || ""}
                    PatientName={device.PatientFirstName + " " + device.PatientLastName}
                    PreAssignedName={device.PreAssignedName}
                    DeviceName={device.DeviceName}

                    DeviceInfoOnline={device.DeviceInfoOnline}
                        DeviceInfoLastCheckinTime={device.DeviceInfoLastCheckinTime}
                        DeviceInfoTimezone={device.DeviceInfoTimezone}
                        DeviceInfoTimezoneOffset={device.DeviceInfoTimezoneOffset}
                        DeviceInfoAppVersion={device.DeviceInfoAppVersion}
                        DeviceInfo2={device.DeviceInfo2}

                    //DeviceInfo={device.DeviceInfo}
                    ShellVersion={device.ShellVersion}
                    RemoteLogs={device.RemoteLogs}
                    //DeviceOS={device.OS}
                    //HardwareInfo={device.HardwareInfo}
                    handleAction={this.handleAction}
                    isCheckedToMove={isChecked}
                    onCheckToMove={()=>{
                        this.props.setCheckedDevices(device)
                    }}
                    onClick={()=>{
                        this.onRowClicked(device);
                    }}
                    />
                );
            }
            else if (
                (patientName.indexOf(deviceTextToSearch) >= 0) ||
                // DEPRECATED 6/16/2023 (device.Mac && device.Mac.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
                (device.HexnodeID && (device.HexnodeID.toString()).indexOf(deviceTextToSearch) >= 0) ||
                (device.MdmDeviceID && (device.MdmDeviceID.toString()).indexOf(deviceTextToSearch) >= 0) ||
                (device.DeviceName && device.DeviceName.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
                (device.PreAssignedName &&  device.PreAssignedName.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
                (version &&  version.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
                (deviceTextToSearch == "online" && device.DeviceInfoOnline) ||
                (deviceTextToSearch == "offline" && !device.DeviceInfoOnline)
            ) {
                const isChecked = this.props.selectedDevicesToMove[device.ID] ? true : false
                accountDevicesList.push(
                    <AccountDeviceItemRow
                    key={key}
                    DeviceAssigned={device.DeviceAssigned}
                    ID={device.ID}
                    Mac={device.Mac}
                    DeviceUID={device.DeviceUID || ""}
                    Serial={device.Serial || ""}
                    HexnodeID={device.HexnodeID || ""}
                    MdmDeviceID={device.MdmDeviceID || ""}
                    MdmID={device.MdmID || ""}
                    Battery={device.Battery || ""}
                    PatientName={device.PatientFirstName + " " + device.PatientLastName}
                    PreAssignedName={device.PreAssignedName}
                    DeviceName={device.DeviceName}

                    DeviceInfoOnline={device.DeviceInfoOnline}
                        DeviceInfoLastCheckinTime={device.DeviceInfoLastCheckinTime}
                        DeviceInfoTimezone={device.DeviceInfoTimezone}
                        DeviceInfoTimezoneOffset={device.DeviceInfoTimezoneOffset}
                        DeviceInfoAppVersion={device.DeviceInfoAppVersion}
                        DeviceInfo2={device.DeviceInfo2}

                    //DeviceInfo={device.DeviceInfo}
                    ShellVersion={device.ShellVersion}
                    RemoteLogs={device.RemoteLogs}
                    //DeviceOS={device.OS}
                    //HardwareInfo={device.HardwareInfo}
                    handleAction={this.handleAction}
                    isCheckedToMove={isChecked}
                    onCheckToMove={()=>{
                        console.log("onCheckToMove");
                        this.props.setCheckedDevices(device)
                    }}
                    onClick={()=>{
                        this.onRowClicked(device);
                    }}
                    />
                );
            }
        });
    }

    return (
        <div class="">
            <div class="row no-margin a-bit-lower">
                <div class="col s10 m10 no-margin left-align flex ">
                    <span class="nucleus-labels-height a-bit-lower">
                        <img
                            src="img/search_icon.svg"
                            style={{paddingRight: "10px", paddingTop: "5px"}}
                            width="28"
                        />
                    </span>
                    <input
                        ref="txtDeviceSearch"
                        type="text"
                        class="nucleus-input-form"
                        onChange={this.handleDeviceSearchText}
                        placeholder="Patient Name / ID / Device Name / Pre A Name / Version / 'offline' / 'online'"
                        style={{width:640}}
                    />
                    <span
                        class="nucleus-table-icon tooltipped devices-refresh "
                        data-delay="50"
                        data-position="top"
                        data-tooltip="Refresh"
                        onClick={this.refreshDevices}
                    >
                        <img
                            src="img/refresh.svg"
                            class="nucleus-link"
                            width="28"
                        />
                    </span>
                </div>
                <div class="col s2 m2 no-margin left-align "></div>
            </div>
            <div>
                <p style={{fontWeight:'bold',fontSize:16, color:"#0A313F"}}>
                {`(${accountDevicesList.length}) Results ${!!(this.state.deviceSearchText != "" && !!(this.state.deviceSearchText.toString().length >= 1)) ? "for "+"\""+this.state.deviceSearchText+"\"" : ""}`}
                </p>
            </div>
            <div class="row nucleus-fixed-container nucleus-table-container-s">
            <style>
                {`
                .nucleus-table-container-s{
                    overflow-y: scroll;
                    height: 55vh !important;
                }
                `}
            </style>
            <table class="b-table b-table-hover b-table-active" style={{}}>
                <thead class="sticky-thead">
                    <tr>
                        <th class="b-col-auto">
                            <span class="nucleus-table-header-medium"></span>
                        </th>
                        <th class="b-col-1">
                            <span class="nucleus-table-header-medium"> ID</span>
                            <a class={ "nucleus-font-medium " + this.state.sortMacAsc } onClick={this.handleSortMacDesc}> &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + this.state.sortMacDesc }  onClick={this.handleSortMacAsc}>&nbsp;▼</a>
                        </th>
                        <th class="b-col-2">
                            <span class="nucleus-table-header-medium">Patient Name</span>
                            <a class={ "nucleus-font-medium " + this.state.sortPatientAsc } onClick={this.handleSortPatientDesc}> &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + this.state.sortPatientDesc }  onClick={this.handleSortPatientAsc}>&nbsp;▼</a>
                        </th>
                        <th class="b-col-2">
                            <span class="nucleus-table-header-medium">Device Name</span>
                            <a class={ "nucleus-font-medium " + this.state.sortDeviceNameAsc } onClick={this.handleSortDeviceNameDesc}> &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + this.state.sortDeviceNameDesc }  onClick={this.handleSortDeviceNameAsc}>&nbsp;▼</a>
                        </th>
                        {/* <th class="table-col-10 left-align">
                            <span class="nucleus-table-header-medium">Pre A. Name</span>
                            <a class={ "nucleus-font-medium " + this.state.sortPreNameAsc } onClick={this.handleSortPreNameDesc} > &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + this.state.sortPreNameDesc }  onClick={this.handleSortPreNameAsc} >&nbsp;▼</a>
                        </th> */}
                        <th class="b-col-auto">
                            <span class="nucleus-table-header-medium">Version</span>
                            <a class={ "nucleus-font-medium " + this.state.sortVersionAsc } onClick={this.handleSortVersionDesc}> &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + this.state.sortVersionDesc }  onClick={this.handleSortVersionAsc}>&nbsp;▼</a>
                        </th>
                        {/*
                        <th class="table-col-5 center-align">
                            <span class="nucleus-table-header-medium">Wifi</span>
                        </th>
                        */}
                        <th class="b-col-auto">
                            <span class="nucleus-table-header-medium">Status</span>
                            <a class={ "nucleus-font-medium " + this.state.sortStatusAsc } onClick={this.handleSortStatusDesc}> &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + this.state.sortStatusDesc }  onClick={this.handleSortStatusAsc}>&nbsp;▼</a>
                        </th>
                        <th class="b-col-auto center-align">
                            <span class="nucleus-table-header-medium">Battery</span>
                            <a class={ "nucleus-font-medium " + this.state.sortBatteryAsc } onClick={this.handleSortBatteryDesc}> &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + this.state.sortBatteryDesc }  onClick={this.handleSortBatteryAsc}>&nbsp;▼</a>
                        </th>
                        <th class="b-col-auto">
                            <span class="nucleus-table-header-medium">Connected on</span>
                            <a class={ "nucleus-font-medium " + this.state.sortConnectedAsc } onClick={this.handleSortConnectedDesc}> &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + this.state.sortConnectedDesc }  onClick={this.handleSortConnectedAsc}>&nbsp;▼</a>
                        </th>
                        {/*
                        <th class="table-col-5 center-align">
                            <span class="nucleus-table-header-medium">Timezone</span>
                            <a class={ "nucleus-font-medium " + this.state.sortTimeZoneAsc } onClick={this.handleSortTimeZoneDesc} > &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + this.state.sortTimeZoneDesc }  onClick={this.handleSortTimeZoneAsc} >&nbsp;▼</a>
                        </th>
                        */}
                        <th class="b-col-auto">
                            <span class="nucleus-table-header-medium">Details</span>
                        </th>
                        {
                         //REMOVED LOG AND MOVE FROM DEVICES TAB 02/06/2023
                        /*<th class="">
                            <span class="nucleus-table-header-medium">Log</span>
                        </th>
                        <th class="">
                            <span class="nucleus-table-header-medium">Move</span>
                         </th>*/
                        }
                        <th class="b-col-auto">
                            <span class="nucleus-table-header-medium" style={{display:'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                <span
                                    class="nucleus-table-icon tooltipped"
                                    data-delay="50"
                                    data-position="top"
                                    data-tooltip="Remove"
                                >
                                    <i class="Tiny material-icons">delete</i>
                                </span>
                                <span
                                    class="nucleus-table-icon tooltipped "
                                    data-delay="50"
                                    data-position="top"
                                    data-tooltip="Remote restart"
                                >
                                    <img
                                        src="img/icon_device_restart.png"
                                        class="nucleus-link"
                                        width="28"
                                    />
                                </span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>{accountDevicesList}</tbody>
            </table>
            </div>
            <div id="modalDeviceDetails" class="modal modalDeviceDetails">
                <div class="modal-content nucleus-modal-wrapper-scrollable2">
                    <img class="responsive-img img-close-modal" src="img/close_modal.png" onClick={this.handleCloseDeviceDetailsModal}/>
                    <h3 class="nucleus-page-subtitle"> Device Details</h3>
                    <br/><br/>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500"> Pre Assigned Name:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {this.state.selectedDeviceDetails.PreAssignedName} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500"> Timezone:</span>
                        </div>
                        <div class="col s8">
                            {/* <span class="nucleus-labels"> {moment(this.state.selectedDeviceDetails.Created).format("MM/DD/YYYY hh:mm A")} </span> */}
                            <span class="nucleus-labels"> {this.state.selectedDeviceDetails.DeviceInfoTimezone} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500"> Battery:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(this.state.selectedDeviceDetails.Battery ? (this.state.selectedDeviceDetails.Battery + " %") : "-") } </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500"> Wifi:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(this.state.selectedDeviceDetails.DeviceDetails ? this.state.selectedDeviceDetails.DeviceDetails.Wifi : "")} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">Brand:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {this.state.selectedDeviceDetails.DeviceInfo2 ? this.state.selectedDeviceDetails.DeviceInfo2.brand : ""} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">Model:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {this.state.selectedDeviceDetails.DeviceInfo2 ? this.state.selectedDeviceDetails.DeviceInfo2.model : ""} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">SIM Number:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(this.state.selectedDeviceDetails.DeviceDetails ? this.state.selectedDeviceDetails.DeviceDetails.Sim: "")} </span>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">Hexnode Info</span>
                        </div>
                        <div class="col s8">
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">OS version:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {((this.state.selectedDeviceDetails.DeviceInfo2) ? (this.state.selectedDeviceDetails.DeviceInfo2.systemName + " " + this.state.selectedDeviceDetails.DeviceInfo2.systemVersion): "")} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">Serial Number:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(this.state.selectedDeviceDetails.DeviceDetails ? this.state.selectedDeviceDetails.DeviceDetails.SerialNumber : "")} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">SIM Carrier Network:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(this.state.selectedDeviceDetails.DeviceDetails ? this.state.selectedDeviceDetails.DeviceDetails.SimCarrier : "")} </span>
                        </div>
                    </div>
                    {/* {
                        generateDeviceDetails()
                    } */}
                </div>
            </div>
        </div>
       )
   }

}
export default AccountDevices;
