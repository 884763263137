export const NucleusConfigSelect = ({
  selectId,
  selectOptions,
  selectedValue,
  handleSelectChange,
  defaultValue,
}: {
  selectId: string;
  selectOptions: { name: string; value: string; disabled?: boolean }[];
  selectedValue: string;
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue?: string;
}) => {
  return (
    <select id={selectId} name={selectId} value={selectedValue} className="nucleusInputSelect" onChange={handleSelectChange}>
      {defaultValue && (
        <option value="" disabled selected>
          {defaultValue}
        </option>
      )}
      {selectOptions.map((option) => (
        <option key={option.value} value={option.value} disabled={option.disabled} style={{ color: '#FE3824' }}>
          {option.name}
        </option>
      ))}
    </select>
  );
};
