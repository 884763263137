import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #0192ff;
  background-image: url("./img/nucleus_big_white_logo.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  @media screen and (max-width: 960px) {
    background-size: cover;
  }
`;
export const BlueContainer = styled.div`
  border-radius: 25px;
  width: 80vw;
  height: 80vh;
  justify-content: center;
  align-items: center;
`;
export const SpacingComponent = styled.div`
  flex: 1;
  @media screen and (max-width: 1360px) {
    display: none;
  }
`;
export const DownloadImage = styled.img`
  position: absolute;
  height: 520px;
  bottom: 10%;
  right: 0;
  @media screen and (max-width: 1360px) {
    height: 380px;
    bottom: 25%;
  }
  @media screen and (max-width: 1095px) {
    height: 280px;
    bottom: 25%;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
DownloadImage.defaultProps = {
  src: "./img/download-update-pc.png",
};
export const NucleusLogoImage = styled.img`
  height: 40px;
  width: 400px;
  @media screen and (max-width: 960px) {
    height: 20px;
    width: 200px;
  }
`;
NucleusLogoImage.defaultProps = {
  src: "./img/nucleuscare_logo_name_white_yellow.png",
};
export const ContentContainer = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  padding-left: 100px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    align-items: center;
    padding-left: 0px;
  }
`;
