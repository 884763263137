import { BasePageTitle } from "../Base";


type NucleusNavBarProps = {
    title?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}
export const UINucleusPageTitle = ({ title = "", style, children }: NucleusNavBarProps) => {
    return (
        <BasePageTitle style={style}>
            {title || children}
        </BasePageTitle>
    )
};
