import React from 'react';
import ReactDOM from "react-dom";
import Message from "../utils/Message";

import { useState, useEffect, useRef } from 'react';

import NucleuscareConnectData,
{
	RootState,
	getClassSessionConfig,
	accountSliceClean,
	enableClassSession,
	updateReminders,
	updateConfig
} from '@nucleus-care/nucleuscare-backend-client';
import {
	useSelector,
	useDispatch
	//DefaultRootState 
} from 'react-redux';

type AccountDataParams = {
	AccountID: string;
};

const ClassSession = (props: AccountDataParams) => {

	const [reminderBeforeDays, setDays] = useState(0);
	const [reminderBeforeMinutes, setMinutes] = useState(0);

	const [tabNameLabel, setTabNameLabel] = useState("Class");
	const [tabNameLabelPlural, setTabNameLabelPlural] = useState("Classes");
	const [hostLabel, setHostLabel] = useState("Instructor");
	// const firstTime = useRef(true);
	// firstTime.current = true;
	const [maxParticipants, setMaxParticipants] = useState(8);
	const [minMinutesToEdit, setMinMinutesToEdit] = useState(60);

	// const updatePhotoTimer = props.SwitchPhotoTime * 500 * 60;
	const dispatch = useDispatch();

	const accountConfig = useSelector((state: RootState) => {
		//console.log("ClassSession useSelector", state);
		return state.accountClassSessionConfig.config;
	});

	useEffect(() => {
		console.log("ClassSession useEffect");
		console.log("AccountID", props.AccountID);

		dispatch(
			getClassSessionConfig({
				AccountID: props.AccountID
			})
		);
	}, []);

	useEffect(() => {
		console.log("ClassSession useEffect accountConfig Status");
		console.log("accountConfig", accountConfig);

		if (accountConfig.complete && !accountConfig.pending){
			Message.show("Configuration updated");
			dispatch(
				getClassSessionConfig({
					AccountID: props.AccountID
				})
			);
		}
	}, [accountConfig.pending, accountConfig.complete])

	useEffect(() => {
		console.log("ClassSession useEffect accountConfig Values");
		console.log("accountConfig", accountConfig);

		setDays(accountConfig.ReminderBeforeDays);
		setMinutes(accountConfig.ReminderBeforeMinutes);
		setTabNameLabel(accountConfig.TabNameLabel);
		setTabNameLabelPlural(accountConfig.TabNameLabelPlural);
		setHostLabel(accountConfig.HostLabel);
		setMaxParticipants(accountConfig.MaxParticipants);
		setMinMinutesToEdit(accountConfig.MinMinutesToEdit);

	}, [accountConfig.ReminderBeforeDays, 
		accountConfig.ReminderBeforeMinutes,
		accountConfig.TabNameLabel,
		accountConfig.TabNameLabelPlural,
		accountConfig.HostLabel,
		accountConfig.MaxParticipants,
		accountConfig.MinMinutesToEdit
	])


	const handleClassesEnabled = (e) => {
		console.log("handleClassesEnabled ", e.target.checked);
		dispatch(
			enableClassSession({
				AccountID: props.AccountID,
				Enabled : e.target.checked
			})
		);
	}

	const handleDaysChanged = (e) => {
		console.log("handleDaysChanged ", e.target.value);
		setDays(e.target.value);
	}
	const handleUpdateReminderDays = () => {
		console.log("handleUpdateReminderDays ", reminderBeforeDays);
		let n1 = reminderBeforeDays || 0;
		//let n2 = reminderBeforeMinutes || 0;
		dispatch(
			updateConfig({
				AccountID: props.AccountID,
				ReminderBeforeDays : parseInt(n1),
				//ReminderBeforeMinutes: parseInt(n2)
			})
		);
	}
	
	const handleMinutesChanged = (e) => {
		console.log("handleMinutesChanged ", e.target.value);
		setMinutes(e.target.value);
	}
	const handleUpdateReminderMinutes = () => {
		console.log("handleUpdateReminderMinutes ", reminderBeforeMinutes);
		//let n1 = reminderBeforeDays || 0;
		let n2 = reminderBeforeMinutes || 0;
		dispatch(
			updateConfig({
				AccountID: props.AccountID,
				//ReminderBeforeDays : parseInt(n1),
				ReminderBeforeMinutes: parseInt(n2)
			})
		);
	}

	const handleTabNameLabelChanged = (e) => {
		console.log("handleTabNameLabelChanged ", e.target.value);
		setTabNameLabel(e.target.value);
	}
	const handleUpdateTabNameLabel = () => {
		console.log("handleUpdateTabNameLabel ", tabNameLabel);
		dispatch(
			updateConfig({
				AccountID: props.AccountID,
				TabNameLabel : tabNameLabel
			})
		);
	}

	const handleTabNameLabelPluralChanged = (e) => {
		console.log("handleTabNameLabelPluralChanged ", e.target.value);
		setTabNameLabelPlural(e.target.value);
	}
	const handleUpdateTabNameLabelPlural = () => {
		console.log("handleUpdateTabNameLabelPlural ", tabNameLabelPlural);
		dispatch(
			updateConfig({
				AccountID: props.AccountID,
				TabNameLabelPlural : tabNameLabelPlural
			})
		);
	}

	const handleHostLabelChanged = (e) => {
		console.log("handleHostLabelChanged ", e.target.value);
		setHostLabel(e.target.value);
	}
	const handleUpdateHostLabel = () => {
		console.log("handleUpdateHostLabel ", hostLabel);
		dispatch(
			updateConfig({
				AccountID: props.AccountID,
				HostLabel : hostLabel
			})
		);
	}

	const handleMaxParticipantsChanged = (e) => {
		console.log("handleMaxParticipantsChanged ", e.target.value);
		if (!isNaN(e.target.value)){
			if (e.target.value< 0 || e.target.value > 16){
				Message.show("Please enter a value between 1 and 16");
			}else{
				setMaxParticipants(e.target.value);
			}
		}
		else if (e.target.value == ""){
			Message.show("Please enter a value between 1 and 16");
		}
	}
	const handleUpdateMaxParticipants = () => {
		console.log("handleUpdateMaxParticipants ", maxParticipants);
		let n = maxParticipants || 0;
		if (n == 0){
			Message.show("Please enter a value between 1 and 16");
			return;
		}
		
		dispatch(
			updateConfig({
				AccountID: props.AccountID,
				MaxParticipants : parseInt(n)
			})
		);
	}

	const handleMinMinutesToEdit = (e) => {
		console.log("handleMinMinutesToEdit ", e.target.value);
		if (!isNaN(e.target.value)){
			setMinMinutesToEdit(e.target.value);
		}
	}

	const handleUpdateMinMinutesToEdit = () => {
		console.log("handleUpdateMinMinutesToEdit ", minMinutesToEdit);
		let mins = minMinutesToEdit || 0;
		dispatch(
			updateConfig({
				AccountID: props.AccountID,
				MinMinutesToEdit : parseInt(mins)
			})
		);
	}

	return (
		<div class="border1">
                
			<div class="col s4 m4 l4 xl4 no-margin">
				<div class="row vbottom-align no-margin top-separated">
					<div class="row bit-top-separated">
						<div class="col s9">
							<span class=" nucleus-labels">Enabled</span>
						</div>
						<div class="col s2">
							<div class="switch a-more-right">
								<label 
									//key={isHipaaCompliant}
									>
									<input
										type="checkbox"
										checked={accountConfig.Enabled}
										onChange={handleClassesEnabled}
									/>
									<span class="lever nucleus-check"></span>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div class="col s12 no-margin top-separated-20">
					<div class="col s6 no-margin">
						<span class="nucleus-labels-small a-more-right">
						Reminder days before
						</span>
					</div>
					<div class="col s3 margin-left no-margin-right" 
						//key={this.state.autoLogoutTime}
						>
						<input
							//ref="txtReminderDaysBefore"
							value={reminderBeforeDays}
							type="text"
							class="validate nucleus-input-form-small accountTextFull2"
							//defaultValue={this.state.autoLogoutTime}
							placeholder="Days"
							disabled={!accountConfig.Enabled}
							onChange={handleDaysChanged}
						/>
					</div>
					<div class="col s2 no-margin center-align">
						<a
							class="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-margin-right"
							onClick={handleUpdateReminderDays}
							disabled={!accountConfig.Enabled}
						>
							<i class="material-icons nucleus-floating-btn-icon">save</i>
						</a>
					</div>
				</div>
				<div class="col s12 no-margin top-separated-20">
					<div class="col s6 no-margin">
						<span class="nucleus-labels-small a-more-right">
						Reminder mins before
						</span>
					</div>
					<div class="col s3 margin-left no-margin-right" >
						<input
							value={reminderBeforeMinutes}
							type="text"
							class="validate nucleus-input-form-small accountTextFull2"
							placeholder="Minutes"
							disabled={!accountConfig.Enabled}
							onChange={handleMinutesChanged}
						/>
					</div>
					<div class="col s2 no-margin center-align">
						<a
							class="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-margin-right"
							onClick={handleUpdateReminderMinutes}
							disabled={!accountConfig.Enabled}
						>
							<i class="material-icons nucleus-floating-btn-icon">save</i>
						</a>
					</div>
				</div>
			</div> 
	   
			<div class="col s4 m4 l4 xl4 no-margin">
				<div class="col s12 no-margin top-separated-20">
					<div class="col s6 no-margin">
						<span class="nucleus-labels-small a-more-right">Tab Name (Label)</span>
					</div>
					<div class="col s3 margin-left no-margin-right" >
						<input
							value={tabNameLabel}
							type="text"
							class="validate nucleus-input-form-small accountTextFull2"
							placeholder="Label"
							disabled={!accountConfig.Enabled}
							onChange={handleTabNameLabelChanged}
						/>
					</div>
					<div class="col s2 no-margin center-align">
						<a
							class="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-margin-right"
							onClick={handleUpdateTabNameLabel}
							disabled={!accountConfig.Enabled}
						>
							<i class="material-icons nucleus-floating-btn-icon">save</i>
						</a>
					</div>
				</div>
				<div class="col s12 no-margin top-separated-20">
					<div class="col s6 no-margin">
						<span class="nucleus-labels-small a-more-right">Tab Name Plural (Label)</span>
					</div>
					<div class="col s3 margin-left no-margin-right" >
						<input
							value={tabNameLabelPlural}
							type="text"
							class="validate nucleus-input-form-small accountTextFull2"
							placeholder="Plural Label"
							disabled={!accountConfig.Enabled}
							onChange={handleTabNameLabelPluralChanged}
						/>
					</div>
					<div class="col s2 no-margin center-align">
						<a
							class="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-margin-right"
							onClick={handleUpdateTabNameLabelPlural}
							disabled={!accountConfig.Enabled}
						>
							<i class="material-icons nucleus-floating-btn-icon">save</i>
						</a>
					</div>
				</div>

				<div class="col s12 no-margin top-separated-20">
					<div class="col s6 no-margin">
						<span class="nucleus-labels-small a-more-right">Host Name (Label)</span>
					</div>
					<div class="col s3 margin-left no-margin-right" >
						<input
							value={hostLabel}
							type="text"
							class="validate nucleus-input-form-small accountTextFull2"
							placeholder="Name"
							disabled={!accountConfig.Enabled}
							onChange={handleHostLabelChanged}
						/>
					</div>
					<div class="col s2 no-margin center-align">
						<a
							class="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-margin-right"
							onClick={handleUpdateHostLabel}
							disabled={!accountConfig.Enabled}
						>
							<i class="material-icons nucleus-floating-btn-icon">save</i>
						</a>
					</div>
				</div>
			</div>

			<div class="col s4 m4 l4 xl4 no-margin">
				<div class="col s12 no-margin top-separated-20">
					<div class="col s6 no-margin">
						<span class="nucleus-labels-small">
						Participant(s) Limit
						</span>
					</div>
					<div class="col s3 margin-left no-margin-right" 
						//key={this.state.autoLogoutTime}
						>
						<input
							//ref="txtMaxParticipants"
							value={maxParticipants}
							type="text"
							class="validate nucleus-input-form-small accountTextFull2"
							placeholder="Number"
							disabled={!accountConfig.Enabled}
							onChange={handleMaxParticipantsChanged}
						/>
					</div>
					<div class="col s2 no-margin center-align">
						<a
							class="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-margin-right"
							onClick={handleUpdateMaxParticipants}
							disabled={!accountConfig.Enabled}
						>
							<i class="material-icons nucleus-floating-btn-icon">save</i>
						</a>
					</div>
				</div>

				<div class="col s12 no-margin top-separated-20">
					<div class="col s6 no-margin">
						<span class="nucleus-labels-small">
						Minutes before updates stop
						</span>
					</div>
					<div class="col s3 margin-left no-margin-right" 
						//key={this.state.autoLogoutTime}
						>
						<input
							//ref="txtMaxParticipants"
							value={minMinutesToEdit}
							type="text"
							class="validate nucleus-input-form-small accountTextFull2"
							placeholder="Minutes"
							disabled={!accountConfig.Enabled}
							onChange={handleMinMinutesToEdit}
						/>
					</div>
					<div class="col s2 no-margin center-align">
						<a
							class="btn-floating waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-margin-right"
							onClick={handleUpdateMinMinutesToEdit}
							disabled={!accountConfig.Enabled}
						>
							<i class="material-icons nucleus-floating-btn-icon">save</i>
						</a>
					</div>
				</div>

			</div>

		</div>
	);
	
}
// class ClassSession extends React.Component {
    
// 	constructor(props){
//         super(props);

//         this.state = {
//             accountScheduleArray : [],
//         };
//     }

//     componentDidMount(){
//         //Register Listener
//     }

//     componentWillUnmount(){
        
//     }

//     render()
//     {
//         return (
 
//         );
//     }
// }

export default ClassSession;