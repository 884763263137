import React from 'react';
import Message from "../utils/Message";
import { Redirect } from 'react-router';

import CarePatientStore from "../stores/CarePatientStore";
import GeneralStore from "../stores/GeneralStore";

class CareFamilyMemberItemRow extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            MemberID : this.props.MemberID

        };
        //console.log(this.state.patientID);
        ////this.handleDeleteAssigment = this.handleDeleteAssigment.bind(this);
    }

    componentDidMount()
    {
        //console.log("this.state.MemberID", this.state.MemberID);
        /*
        let patientInfo = CarePatientStore.getPatientInfo(this.props.PatientID);
        console.log("PatientInfo:", patientInfo);
        if (patientInfo){
            this.setState({
                assignmentPatientName : patientInfo.Name
            });
        }
        */
    }

    componentWillUnmount()
    {

    }

    handleRowClicked = () => {

        GeneralStore.requestOpenFamilyMemberModal({
            MemberID : this.props.MemberID,
            MemberFirstName : this.props.FirstName,
            MemberLastName : this.props.LastName,
            MemberEmail : this.props.Email,
            MemberTelephone : this.props.Telephone,
            //MemberReceiveUpdates : this.props.ReceiveUpdates,
            MemberProfileCreated : this.props.ProfileCreated,
            AccountAutoAnswer : this.props.AccountAutoAnswer,
            MemberAutoAnswer : this.props.AutoAnswer,
            MemberAdmin: this.props.Admin
        });
    }


    render()
    {

        //let receiveUpdates = this.props.ReceiveUpdates ? "Y" : "N";
        let profileCompleted = this.props.ProfileCreated ? "Active" : "Pending";

        let telephone = this.props.Telephone;
        if (telephone != null && telephone.length > 18){
            telephone = telephone.substring(0, 18) + " ...";
        }

        return (

            <tr onClick={this.handleRowClicked}>
                
                <td class="nucleus-row" >
                    <div style={{display:'flex',alignItems: 'center',height:"100%"}}>
                    {/* FamilyImageRow */}
                    <img 
                        src={this.props.UserImageThumb ? this.props.UserImageThumb : 'img/user_placeholder.png'} 
                        style={{width:50,height:50, marginRight:10, borderRadius:25, objectFit:'cover'}} 
                    />
                    { this.props.Name }
                    </div>
                </td>
                <td class="nucleus-row" >{ telephone }</td>
                <td class="nucleus-row" >{ this.props.Email }</td>
                
                <td class="nucleus-row center-align" >{ profileCompleted }</td>
                <td class="nucleus-row center-align" >{ this.props.Admin ? "Y" : "" }</td>
            </tr>
               
        );
    }
}

export default CareFamilyMemberItemRow;