import React, { useEffect, useState } from 'react';
import cx from 'classnames';
const NucleusButtonV2 = ({
  styleType = 'primary',
  children,
  extraStyle,
  ...props
}) => {
  const [styleTypeState, setStyleTypeState] = useState({
    backgroundColor: '#0A313F',
    color: '#fff',
  });
  useEffect(() => {
    switch (styleType) {
      case 'primary':
        setStyleTypeState({
          backgroundColor: '#0A313F',
          color: '#fff',
          ...extraStyle,
        });
        break;
      case 'secondary':
        setStyleTypeState({
          backgroundColor: '#E5E5E5',
          color: '#0A313F',
          border: '2px solid #0A313F',
          ...extraStyle,
        });
        break;
      case 'clear':
        setStyleTypeState({
          backgroundColor: 'transparent',
          color: '#0092FF',
          border: 'none',
          ...extraStyle,
        });
      case 'outline':
        setStyleTypeState({
          backgroundColor: 'transparent',
          color: '#0092FF',
          border: '1px solid #3C92F7',
          ...extraStyle,
        });
        break;
      default:
        setStyleTypeState({
          backgroundColor: '#0A313F',
          color: '#fff',
          border: '2px solid #0A313F',
        });
    }
  }, [styleType, extraStyle]);
  return (
    <button
      style={{
        padding: 10,
        minWidth: 140,
        maxWidth: 230,
        borderRadius: 35,
        fontWeight: 'bold',
        ...styleTypeState,
      }}
      {...props}
    >
      {children}
    </button>
  );
};

export default NucleusButtonV2;
