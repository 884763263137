import { CSSProperties, FC } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 100px;
  cursor: pointer;
  padding: 15px 20px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:hover {
    opacity: ${(props) => (props.disabled ? 0.5 : 0.8)};
  }
`;

const Text = styled.p<{ numberOfLines?: number }>`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  ${(props) =>
    props.numberOfLines === 1 &&
    `
      -webkit-line-clamp: 1;
      white-space: nowrap;
    `}
`;

const ChevronContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
`;

const withChevronStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  containerStyle?: CSSProperties;
  textStyle?: CSSProperties;
  text: string;
  onClick?: () => void;
  showChevron?: boolean;
  numberOfLines?: number;
}
export const Base: FC<ButtonProps> = ({
  containerStyle,
  textStyle,
  text,
  onClick,
  showChevron = false,
  disabled,
  numberOfLines
}) => {
  return (
    <Container disabled={disabled} onClick={() => {
      if (disabled) return
      if (onClick) onClick()
    }} style={showChevron ? { ...withChevronStyle, ...containerStyle } : containerStyle}>
      <Text numberOfLines={numberOfLines} style={textStyle}>{text}</Text>
      {!!showChevron && <ChevronContainer>
        <i
          className="Tiny material-icons"
          style={{ color: '#0092FF', fontSize: 24, fontWeight: 'bold' }}
        >
          chevron_right
        </i>
      </ChevronContainer>}
    </Container>
  );
};

export default Base;
