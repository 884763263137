import React, { useEffect } from "react";
import { fetchMdmAccounts, fetchMdmAccountsDevicesCount } from '@nucleus-care/nucleuscare-backend-client';
import NucleusTable from "../../../../components/NucleusTable";
import { useDispatch, useSelector } from "react-redux";
import { GoToArrow } from "../../../../components/NucleusTable/assets/NucleusTableSVGs";
import { SpinnerCircular } from "spinners-react";
import { useHistory } from "react-router-dom";

const MdmAccountsTable = ({ searchText }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchMdmAccounts());
    dispatch(fetchMdmAccountsDevicesCount())
  }, []);


  const { 
    fetchAccounts,
    fetchMdmAccountsDevicesCountState,
  } = useSelector(({ mdmAccounts }) => mdmAccounts.data);

  const {
    loading: loadingAccounts,
    mdmAccounts,
  } = fetchAccounts;

  const {
    loading: loadingDevicesCount,
    mdmAccountsDeviceCounts,
  } = fetchMdmAccountsDevicesCountState;

  // map the account IDs to the device counts for efficient lookup
  const mdmAccountsDevicesCountMap = mdmAccountsDeviceCounts.reduce((acc, curr) => {
    acc[curr.mdmAccountId] = curr.deviceCount;
    return acc;
  }, {});

  const tableData = mdmAccounts
    // filter by search text
    .filter((account) => {
      return (
        account.Name.toLowerCase().includes(searchText.toLowerCase()) ||
        account.EnterpriseName.toLowerCase().includes(searchText.toLowerCase()) ||
        account.CareAccountName.toLowerCase().includes(searchText.toLowerCase())
      );
    })
    // select the fields we want to display
    .map((account) => {
      return {
        ID: account.ID,
        Name: account.Name,
        EnterpriseName: account.EnterpriseName,
        CareAccountID: account.CareAccountID,
        CareAccountName: account.CareAccountName,
        DeviceCount: mdmAccountsDevicesCountMap[account.ID] || 0,
      };
    })
    // sort by Name, case insensitive
    .sort((a, b) => {
      return a.Name.localeCompare(b.Name);
    });
    
  const caseInsensitiveSort = (rowA, rowB, columnId) => {
    const a = rowA.values[columnId];
    const b = rowB.values[columnId];
    return a.localeCompare(b);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "Name",
      sortType: caseInsensitiveSort,
    },
    {
      Header: "Number of Devices",
      accessor: "DeviceCount",
      Cell: ({ cell }) => {
        const deviceCount = cell.row.original.DeviceCount;
        const deviceCountString = deviceCount.toLocaleString();
        // render the text somewhere in the middle of the column, but keep it right aligned within that space
        return (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}></div> {/* Left empty column */}
            <div style={{ flex: 1, textAlign: "right" }}>{deviceCountString}</div>
            <div style={{ flex: 1 }}></div> {/* Middle empty column */}
            <div style={{ flex: 1 }}></div> {/* Middle empty column */}
            <div style={{ flex: 1 }}></div> {/* Middle empty column */}
            <div style={{ flex: 1 }}></div> {/* Middle empty column */}
            <div style={{ flex: 1 }}></div> {/* Middle empty column */}
          </div>
        );
      },
    },
    {
      Header: "Enterprise",
      accessor: "EnterpriseName",
      sortType: caseInsensitiveSort,
    },
    {
      Header: "Go to Nucleus Account",
      disableSortBy: true,
      Cell: ({ cell }) => {
        const careAccountId = cell.row.original.CareAccountID;      
        // If CareAccountID doesn't exist, don't render anything
        if (!careAccountId) {
          return null;
        }
      
        const handleArrowClick = (e) => {
          e.stopPropagation();
          history.push(`/accountDetails/${careAccountId}`);
        };
      
        return (
          <div 
            onClick={handleArrowClick} 
            // add padding to make the clickable area larger
            style={{ display: 'inline-block', cursor: 'pointer' }}
          >
            <GoToArrow className="nucleus-ml-2" />
          </div>
        );
      }
    },
  ];

  const handleRowClick = (rowInfo) => {
    const rowMdmAccountId = rowInfo.row.original.ID;
    history.push(`/policies/mdmAccountDetails/${rowMdmAccountId}`);
  };

  return (
    <div>
      <NucleusTable onRowClick={handleRowClick} columns={columns} data={tableData} />
      {(loadingAccounts || loadingDevicesCount) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SpinnerCircular
            color="#2096F3"
            secondaryColor="rgba(0,0,0,0.16)"
            size="50"
            thickness="100"
          />
        </div>
      )}
    </div>
  );
};

export default MdmAccountsTable;
