import React from 'react';

const NucleusControlledSelect = ({
  register,
  name,
  id,
  label,
  options,
  error,
  clearErrors,
  setValue,
  valueAccessor = 'value',
  labelAccessor = 'label',
  loadingOptions,
  noLabel = false,
  mainContainerStyle,
  saveHandler,
  saveText,
  saveStyle,
  ...props
}) => {
  const handleSelectChange = (e) => {
    const value = e.target.value;
    console.log('handleSelectChange', name, value);
    if (error) {
      console.log('handleSelectChange=>error');
      clearErrors(name);
    }
    setValue(name, value);
  };

  // PLEASE DO NOT MODIFY THIS STYLES
  // PLEASE DO NOT MODIFY THIS STYLES
  // PLEASE DO NOT MODIFY THIS STYLES
  // These styles are made to look good depending on the styles of their parent component outside of this component.
  // Try to modify the style of the parent component if you are having errors when making this component visible
  return (
    <React.Fragment>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          // minHeight: 70,
          opacity: props?.disabled ? 0.4 : 1,
          backgroundColor: '#fff',
          borderRadius: 8,
          ...mainContainerStyle,
        }}
      >
        {!!!noLabel && (
          <label
            // PLEASE DO NOT MODIFY THIS STYLES
            style={{
              minWidth: 130,
              textAlign: 'start',
              fontSize: 16,
              color: '#0A313F',
            }}
            htmlFor={name}
            id={id}
          >
            {label}
          </label>
        )}
        <select
          style={{
            width: '%100',
            height: '30px',
            display: 'block',
            borderRadius: 8,
            border: error ? '1px solid #FE3824' : 'none',
          }}
          name={name}
          id={id}
          {...props}
          {...register}
          onChange={handleSelectChange}
        >
          <option disabled key={'defaultValue'} value={''}>
            Select...
          </option>
          {!loadingOptions &&
            options.map((option) => (
              <option key={option[valueAccessor]} value={option[valueAccessor]}>
                {option[labelAccessor]}
              </option>
            ))}
        </select>
        {!!saveHandler && (
          <span
            onClick={saveHandler}
            role="button"
            style={{
              alignSelf: 'center',
              cursor: 'pointer',
              color: '#0092FF',
              textAlign: 'center',
              fontWeight: 'bold',
              // marginLeft: 10,
              paddingLeft: 10,
              paddingRight: 10,
              ...saveStyle,
            }}
          >
            {saveText ? saveText : 'Save'}
          </span>
        )}
      </div>
      {error && (
        <p
          // PLEASE DO NOT MODIFY THIS STYLES
          style={{
            color: '#FE3824',
            fontWeight: 'bold',
            marginTop: 5,
            textAlign: 'end',
          }}
        >
          {error.message}
        </p>
      )}
    </React.Fragment>
  );
};

export default NucleusControlledSelect;
