import React, { useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useNucleusProviders } from '../../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { getProviderByID, getProviderFeatures } from '../utils/providerApi';

const ProviderDetailsWrapper = ({ children }) => {
    const route = useRouteMatch();
    const history = useHistory();
    const { dispatch, state } = useNucleusProviders()
    const { provider: { data: providerData, state: { error, loading, success } } } = state
    useEffect(() => {
        const providerId = route.params.providerId
        if (providerData?.ID === providerId) return
        console.log('route.params.providerId: ' + providerId);
        if (!providerId && !loading && (error || !success)) {
            history.replace('/providers')
            return
        }
        async function getProvider(providerId) {
            dispatch({
                type: 'fetch_provider',
                payload: {
                    error: false,
                    loading: true,
                    success: false,
                    provider: null,
                    features: null
                }
            })
            const provider = await getProviderByID(providerId)
            const providerFeatures = await getProviderFeatures(providerId)
            console.log('provider.id: ' + provider);
            dispatch({
                type: 'fetch_provider',
                payload: {
                    error: false,
                    loading: false,
                    success: true,
                    provider: provider,
                    features: providerFeatures
                }
            })
            if (!provider || !provider.ID) {
                return history.replace('/providers')
            }
        }
        getProvider(providerId)

    }, [route])
    return (
        <React.Fragment>{children}</React.Fragment>
    )
}

export default ProviderDetailsWrapper