import React, { useEffect, useState } from "react";
import classNames from "classnames";
import NucleusLabel from "../NucleusLabel";
import { useSelector } from "react-redux";
import cx from "classnames";

const NucleusCheckBox = ({
  label,
  name,
  checked,
  onChange,
  error,
  containerClassName,
  isDefaultLabel,
  nucleusLabelClassName,
  onFocus,
  onClickClear,
  activeBadge,
  labelMinWidth,
  hideClear,
  ...props
}) => {
  const { policyJSON } = useSelector(({ mdmPolicy }) => mdmPolicy.state);
  return (
    <div className={classNames(containerClassName, "checkboxContainer")}>
      {!!!isDefaultLabel && (
        <NucleusLabel
          labelMinWidth={labelMinWidth}
          className={nucleusLabelClassName}
          label={label}
          htmlFor={name}
        />
      )}
      <div
        style={{
          position: "absolute",
          display: "flex",
        }}
      >
        {activeBadge && (
          <span
            style={{
              position: "absolute",
              left: -4,
              top: -4,
              // top: "-10px",
              width: 0,
              height: 0,
              borderLeft: "10px solid #00C444",
              borderBottom: "10px solid transparent",
              // borderRight: "10px solid transparent",
              borderRight: "10px solid transparent",
            }}
          ></span>
        )}
      </div>
      <input
        {...props}
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        onChange={onChange}
        className={classNames(`filled-in ${error ? "invalid" : ""}`)}
      />
      <label className={"text-center-align"} htmlFor={name}>
        {!!isDefaultLabel && label}
      </label>
      <div style={{ minWidth: 60 }}>
        <div
          style={{
            display: cx(!props.disabled && !hideClear && onClickClear ? "flex" : "none"),
            justifyContent: "center",
            alignItems: "center",
          }}
          className="nucleus-ml-2"
        >
          <a onClick={onClickClear} role="button" style={{ cursor: "pointer" }}>
            Clear
          </a>
        </div>
      </div>
    </div>
  );
};

export default NucleusCheckBox;
