import React from 'react';
import ReactDOM from "react-dom";

import Message from "../utils/Message";
import CareAccountStore from "../stores/CareAccountStore";

class AccountScheduleItemRow extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            scheduleID : this.props.ID 
        };

        this.dateOptions = { day: "2-digit", month: "2-digit", year : "numeric" };
        this.timeOptions = { hour12 : true, hour: "2-digit", minute: "2-digit" };
        
        
        this.handleRowClicked = this.handleRowClicked.bind(this);


        this.handleRemoveSchedule = this.handleRemoveSchedule.bind(this);
        this.handleModifySchedule = this.handleModifySchedule.bind(this);
    }

    componentDidMount()
    {
        //console.log(this.state.patientID);
        this.setState({
            scheduleID : this.props.ID
        });
        //window.$('.tooltipped').tooltip({delay: 50});
    }

    componentWillUnmount()
    {
        //window.$('.tooltipped').tooltip('remove');
    }

    handleRowClicked(){
        //window.location.assign("#/patientProfile/" + this.props.PatientID + "/null" );
    }


    handleRemoveSchedule(){

        let fromDateTime = new Date('1970-01-01T' + this.props.From);
        let fromTime  =  fromDateTime.toLocaleTimeString('en-US', this.timeOptions);

        let toDateTime = new Date('1970-01-01T' + this.props.To);
        let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);

        CareAccountStore.handleOpenDeleteScheduleModal({
            ID : this.props.ID,
            Day : this.props.Day,
            From : fromTime,
            To : toTime
        });
    }

    handleModifySchedule(){

        let fromDateTime = new Date('1970-01-01T' + this.props.From);
        let fromTime  =  fromDateTime.toLocaleTimeString('en-US', this.timeOptions);

        let toDateTime = new Date('1970-01-01T' + this.props.To);
        let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
        
        CareAccountStore.handleOpenEditScheduleModal({
            ID : this.props.ID,
            Day : this.props.Day,
            From : fromTime,
            To : toTime
        });
    }



    render()
    {

        let fromDateTime = new Date('1970-01-01T' + this.props.From);
        let fromTime  =  fromDateTime.toLocaleTimeString('en-US', this.timeOptions);

        let toDateTime = new Date('1970-01-01T' + this.props.To);
        let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);

        let dayName = "Sunday";
        switch(this.props.Day){
            case "0":
                dayName = "Sunday";
                break;
            case "1":
                dayName = "Monday";
                break;
            case "2":
                dayName = "Tuesday";
                break;
            case "3":
                dayName = "Wednesday";
                break;
            case "4":
                dayName = "Thursday";
                break;
            case "5":
                dayName = "Friday";
                break;
            case "6":
                dayName = "Saturday";
                break;
        }
        
        //console.log("From 1",fromDateTime );
        //console.log("From 2",fromTime );

        return (

            <tr>
                
                <td class="">{ dayName }</td>
                <td class="">{ fromTime }</td>
                <td class="">{ toTime }</td>
                <td class="center-align">
                    <span class="nucleus-table-icon nucleus-link tooltipped" data-position="top" data-tooltip="Edit">
                        <i class="Tiny material-icons" onClick={this.handleModifySchedule}>edit</i>
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span class="nucleus-table-icon nucleus-link tooltipped" data-position="top" data-tooltip="Remove">
                        <i class="Tiny material-icons" onClick={this.handleRemoveSchedule}>delete</i>
                    </span>

                </td>
            </tr>
               
        );
    }
}

export default AccountScheduleItemRow;
