import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import AuthStore from "../stores/AuthStore";
import CareAccountStore from "../stores/CareAccountStore";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { LineChart } from '@mui/x-charts/LineChart';
import * as am5percent from "@amcharts/amcharts5/percent";

class AccountStats extends React.Component {
    constructor(props){
        super(props);

        this.onGetDailyCallsAction = this.onGetDailyCallsAction.bind(this);
        this.onGetDailyHandledCallsAction = this.onGetDailyHandledCallsAction.bind(this);

        this.onGetFamilyMembersAction = this.onGetFamilyMembersAction.bind(this);
        this.onGetMessagesReportAction = this.onGetMessagesReportAction.bind(this);

        this.onGetFamilyCallsAction = this.onGetFamilyCallsAction.bind(this);
        this.onGetMediaItemsPerDayReportAction = this.onGetMediaItemsPerDayReportAction.bind(this);

				this.root1 = null;
        this.root2 = null;
        this.root3 = null;
        this.root4 = null;
        this.root5 = null;
        this.root6 = null;

				this.chartSeriesColumnsConfig = {
					fillOpacity: 0.5,
					strokeWidth: 2,
					cornerRadiusTL: 5,
					cornerRadiusTR: 5
				}
    }

    componentDidMount(){
         //Register Listener
        CareAccountStore.on("onGetDailyCallsAction", this.onGetDailyCallsAction);
        CareAccountStore.on("onGetDailyHandledCallsAction", this.onGetDailyHandledCallsAction);
        CareAccountStore.on("onGetFamilyMembers", this.onGetFamilyMembersAction);
        CareAccountStore.on("onGetMessagesReport", this.onGetMessagesReportAction);
        CareAccountStore.on("onGetFamilyCalls", this.onGetFamilyCallsAction);
        CareAccountStore.on("onGetMediaItemsPerDayReport", this.onGetMediaItemsPerDayReportAction);


        CareAccountStore.getDailyCalls({
            AccountID : this.props.accountId//'31DF0A14-25EF-4654-B7AE-D188B1C4BD8B'
        });

        CareAccountStore.getDailyHandledCalls({
            AccountID : this.props.accountId//'31DF0A14-25EF-4654-B7AE-D188B1C4BD8B'
        });

        CareAccountStore.getFamilyMembers({
            AccountID : this.props.accountId
        });

        CareAccountStore.getMessagesReport({
            AccountID : this.props.accountId
        });

        CareAccountStore.getFamilyCalls({
            AccountID : this.props.accountId
        });

        CareAccountStore.getMediaItemsPerDayReport({
            AccountID : this.props.accountId
        });
    }

    componentWillUnmount(){
         //Remove Listener
        CareAccountStore.removeListener("onGetDailyCallsAction", this.onGetDailyCallsAction);
        CareAccountStore.removeListener("onGetDailyHandledCallsAction", this.onGetDailyHandledCallsAction);
        CareAccountStore.removeListener("onGetFamilyMembers", this.onGetFamilyMembersAction);
        CareAccountStore.removeListener("onGetMessagesReport", this.onGetMessagesReportAction);
        CareAccountStore.removeListener("onGetFamilyCalls", this.onGetFamilyCallsAction);
        CareAccountStore.removeListener("onGetMediaItemsPerDayReport", this.onGetMediaItemsPerDayReportAction);
    
				if (this.root1) {
					this.root1.dispose();
				}
				if (this.root2) {
					this.root2.dispose();
				}
				if (this.root3) {
					this.root3.dispose();
				}
				if (this.root4) {
					this.root4.dispose();
				}
				if (this.root5) {
					this.root5.dispose();
				}
				if (this.root6) {
					this.root6.dispose();
				}
			}


     onGetDailyCallsAction(data){

        this.setState({
            chartData1 : data.calls
        });
				if (this.root1) {
					this.root1.dispose();
				}
        this.drawChartCallsRequested(data.calls);
    }

    onGetDailyHandledCallsAction(data){
        // this.setState({
        //     chartData2 : data.calls
        // });
				if (this.root2) {
					this.root2.dispose();
				}
        this.drawChartCallsHandled(data.calls);
    }

    onGetFamilyMembersAction(data){
        //console.log("onGetFamilyMembersAction", data);

        if (data.ok){
					if (this.root3) {
						this.root3.dispose();
					}
            this.drawChartFamilyMembers(data.active, data.pending);
        }
    }

    onGetMessagesReportAction(data){
        console.log("onGetMessagesReportAction", data);

        if (data.ok){
					if (this.root4) {
						this.root4.dispose();
					}
            this.drawChartMessagesReport(data.Data);
        }
    }

    onGetFamilyCallsAction(data){
        //console.log("onGetFamilyCallsAction", data);

        if (data.ok){
					if (this.root5) {
						this.root5.dispose();
					}
            this.drawChartFamilyCalls(data.calls);
        }
    }

    onGetMediaItemsPerDayReportAction(data){
        //console.log("onGetMediaItemsPerDayReportAction", data);

        if (data.ok){
					if (this.root6) {
						this.root6.dispose();
					}
            this.drawChartMediaBoxItems(data.MemoryBox);
        }
    }

    drawChartCallsRequested(calls){

        console.log("drawChartCallsRequested");
        //console.log(calls);

        const root = am5.Root.new("chartdiv");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

				const chartRootConfig = {
					panY: false,
					layout: root.verticalLayout,
					maxTooltipDistance: 0,
					arrangeTooltips: false
				};

        let chart = root.container.children.push( 
            am5xy.XYChart.new(root, chartRootConfig) 
        );

				// Define data
				let data = calls;
				// Create Y-axis
				let yAxis = chart.yAxes.push(
					am5xy.ValueAxis.new(root, {
						renderer: am5xy.AxisRendererY.new(root, {})
					})
				);
		
				// Create X-Axis
				let xAxis = chart.xAxes.push(
					am5xy.CategoryAxis.new(root, {
						renderer: am5xy.AxisRendererX.new(root, {}),
						categoryField: "date"
					})
				);
				xAxis.data.setAll(data);
		
				// Create series
				let series1 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Calls",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "request",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0x018FFF),
    				stroke: am5.color(0x018FFF)
					})
				);
				series1.data.setAll(data);
				series1.set("active", am5.color(0x018FFF)); // set Series color to red
				series1.columns.template.setAll(this.chartSeriesColumnsConfig);
		
				let series2 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Emergency",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "emergency",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0xFFCD01),
    				stroke: am5.color(0xFFCD01)
					})
				);
				series2.data.setAll(data);
				series2.set("fill", am5.color("#FFCD01")); // set Series color to green
				series2.columns.template.setAll(this.chartSeriesColumnsConfig);

				// Add legend
				let legend = chart.children.push(am5.Legend.new(root, {}));
				legend.data.setAll(chart.series.values);
		
				// Add cursor
				chart.set("cursor", am5xy.XYCursor.new(root, {
					maxTooltipDistance: 0,
							arrangeTooltips: false
				}));
		
				this.root1 = root;


        // var chart = AmCharts.makeChart("chartdiv", {
        //     "type": "serial",
        //     "addClassNames": true,
        //     "theme": "light",
        //     "autoMargins": false,
        //     "marginLeft": 30,
        //     "marginRight": 8,
        //     "marginTop": 10,
        //     "marginBottom": 26,
        //     "balloon": {
        //         "adjustBorderColor": false,
        //         "horizontalPadding": 10,
        //         "verticalPadding": 8,
        //         "color": "#ffffff"
        //     },
        //     "dataProvider": calls ,
        //
        //     "valueAxes": [{
        //         "axisAlpha": 0,
        //         "position": "left"
        //     }],
        //     "startDuration": 0,
        //     "graphs": [{
        //         "id": "graph1",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "lineColor":"#FA6500",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Emergency",
        //         "valueField": "emergency",
        //         "dashLengthField": "dashLengthLine"
        //     }, {
        //         "id": "graph2",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Request",
        //         "valueField": "request",
        //         "dashLengthField": "dashLengthLine"
        //     }],
        //     "categoryField": "date",
        //     "categoryAxis": {
        //         "gridPosition": "start",
        //         "axisAlpha": 0,
        //         "tickLength": 0,
        //         "parseDates": true
        //     },
        //     "export": {
        //         "enabled": true
        //     }
        // });
    }

    drawChartCallsHandled(calls){

        console.log("drawChartCallsHandled");
        //console.log(calls);

        const root = am5.Root.new("chartdiv2");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

				const chartRootConfig = {
					panY: false,
					layout: root.verticalLayout,
					maxTooltipDistance: 0,
					arrangeTooltips: false
				};

        let chart = root.container.children.push( 
            am5xy.XYChart.new(root, chartRootConfig) 
        );

				// Define data
				let data = calls;
				// Create Y-axis
				let yAxis = chart.yAxes.push(
					am5xy.ValueAxis.new(root, {
						renderer: am5xy.AxisRendererY.new(root, {})
					})
				);
		
				// Create X-Axis
				let xAxis = chart.xAxes.push(
					am5xy.CategoryAxis.new(root, {
						renderer: am5xy.AxisRendererX.new(root, {}),
						categoryField: "date"
					})
				);
				xAxis.data.setAll(data);
		
				// Create series
				let series1 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Answered",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "answered",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0x018FFF),
    				stroke: am5.color(0x018FFF)
					})
				);
				series1.data.setAll(data);
				series1.set("active", am5.color(0x018FFF)); // set Series color to red
				series1.columns.template.setAll(this.chartSeriesColumnsConfig);
		
				let series2 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Not Answered",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "not_answered",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0xFFCD01),
    				stroke: am5.color(0xFFCD01)
					})
				);
				series2.data.setAll(data);
				series2.set("fill", am5.color("#FFCD01")); // set Series color to green
				series2.columns.template.setAll(this.chartSeriesColumnsConfig);

				// Add legend
				let legend = chart.children.push(am5.Legend.new(root, {}));
				legend.data.setAll(chart.series.values);
		
				// Add cursor
				chart.set("cursor", am5xy.XYCursor.new(root, {
					maxTooltipDistance: 0,
							arrangeTooltips: false
				}));
		
				this.root2 = root;

        // var chart = AmCharts.makeChart("chartdiv2", {
        //     "type": "serial",
        //     "addClassNames": true,
        //     "theme": "light",
        //     "autoMargins": false,
        //     "marginLeft": 30,
        //     "marginRight": 8,
        //     "marginTop": 10,
        //     "marginBottom": 26,
        //     "balloon": {
        //         "adjustBorderColor": false,
        //         "horizontalPadding": 10,
        //         "verticalPadding": 8,
        //         "color": "#ffffff"
        //     },
        //     "dataProvider": calls ,
        //
        //     "valueAxes": [{
        //         "axisAlpha": 0,
        //         "position": "left"
        //     }],
        //     "startDuration": 0,
        //     "graphs": [{
        //         "id": "graph1",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "lineColor":"#FA6500",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Not Answered",
        //         "valueField": "not_answered",
        //         "dashLengthField": "dashLengthLine"
        //     }, {
        //         "id": "graph2",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Answered",
        //         "valueField": "answered",
        //         "dashLengthField": "dashLengthLine"
        //     }],
        //     "categoryField": "date",
        //     "categoryAxis": {
        //         "gridPosition": "start",
        //         "axisAlpha": 0,
        //         "tickLength": 0,
        //         "parseDates": true
        //     },
        //     "export": {
        //         "enabled": true
        //     }
        // });
    }

    drawChartFamilyMembers(active, pending){

        console.log("drawChartFamilyMembers");
        console.log(active, pending);

				const data =[
					{
						 state : 'active',
						 value : active
					},
					{
						state: 'pending',
						value: pending
					}
				]

				let root = am5.Root.new("chartFamilyMembers");
				var chart = root.container.children.push( 
					am5percent.PieChart.new(root, {
						layout: root.verticalHorizontal,
						radius: am5.percent(60),
						innerRadius: am5.percent(50)
					}) 
				);
				var series = chart.series.push(
					am5percent.PieSeries.new(root, {
						name: "Series",
						categoryField: "state",
						valueField: "value"
					})
				);
				series.set("active", am5.color(0x018FFF));
				series.data.setAll(data);
				var legend = chart.children.push(am5.Legend.new(root, {
					centerX: am5.percent(50),
					x: am5.percent(50),
					layout: root.horizontalLayout
				}));
				
				legend.data.setAll(series.dataItems);
				this.root3 = root;

        // var chart = AmCharts.makeChart( "chartFamilyMembers", {
        //   "type": "pie",
        //   "theme": "light",
        //   "dataProvider": [ {
        //     "title": "Active",
        //     "value": active
        //   }, {
        //     "title": "Pending",
        //     "value": pending
        //   } ],
        //   "titleField": "title",
        //   "valueField": "value",
        //   "labelRadius": 5,
        //   "startDuration": 0,
        //
        //   "radius": "42%",
        //   "innerRadius": "60%",
        //   "labelText": "[[title]]",
        //   "export": {
        //     "enabled": true
        //   },
        //     colors : ["#018FFF", "#FFCD01"]
        // } );
    }

    drawChartMessagesReport(messages){

        console.log("drawChartMessagesReport");
        //console.log(messages);

				const root = am5.Root.new("chartMessages");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

				const chartRootConfig = {
					panY: false,
					layout: root.verticalLayout,
					maxTooltipDistance: 0,
					arrangeTooltips: false
				};

        let chart = root.container.children.push( 
            am5xy.XYChart.new(root, chartRootConfig) 
        );

				// Define data
				let data = messages;
				// Create Y-axis
				let yAxis = chart.yAxes.push(
					am5xy.ValueAxis.new(root, {
						renderer: am5xy.AxisRendererY.new(root, {})
					})
				);
		
				// Create X-Axis
				let xAxis = chart.xAxes.push(
					am5xy.CategoryAxis.new(root, {
						renderer: am5xy.AxisRendererX.new(root, {}),
						categoryField: "date"
					})
				);
				xAxis.data.setAll(data);
		
				// Create series
				let series1 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Messages Read",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "MessageRead",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0x018FFF),
    				stroke: am5.color(0x018FFF)
					})
				);
				series1.data.setAll(data);
				series1.set("active", am5.color(0x018FFF)); // set Series color to red
				series1.columns.template.setAll(this.chartSeriesColumnsConfig);
		
				let series2 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Messages Not Read",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "MessageNotRead",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0xFFCD01),
    				stroke: am5.color(0xFFCD01)
					})
				);
				series2.data.setAll(data);
				series2.set("fill", am5.color("#FFCD01")); // set Series color to green
				series2.columns.template.setAll(this.chartSeriesColumnsConfig);

				// Add legend
				let legend = chart.children.push(am5.Legend.new(root, {}));
				legend.data.setAll(chart.series.values);
		
				// Add cursor
				chart.set("cursor", am5xy.XYCursor.new(root, {
					maxTooltipDistance: 0,
							arrangeTooltips: false
				}));
		
				this.root4 = root;

        // var chart = AmCharts.makeChart("chartMessages", {
        //     "type": "serial",
        //     "addClassNames": true,
        //     "theme": "light",
        //     "autoMargins": false,
        //     "marginLeft": 30,
        //     "marginRight": 8,
        //     "marginTop": 10,
        //     "marginBottom": 26,
        //     "balloon": {
        //         "adjustBorderColor": false,
        //         "horizontalPadding": 10,
        //         "verticalPadding": 8,
        //         "color": "#ffffff"
        //     },
        //     "dataProvider": messages ,
        //
        //     "valueAxes": [{
        //         "axisAlpha": 0,
        //         "position": "left"
        //     }],
        //     "startDuration": 0,
        //     "graphs": [{
        //         "id": "graph1",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "lineColor":"#FA6500",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Message Not Read",
        //         "valueField": "MessageNotRead",
        //         "dashLengthField": "dashLengthLine"
        //     }, {
        //         "id": "graph2",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Message Read",
        //         "valueField": "MessageRead",
        //         "dashLengthField": "dashLengthLine"
        //     }],
        //     "categoryField": "date",
        //     "categoryAxis": {
        //         "gridPosition": "start",
        //         "axisAlpha": 0,
        //         "tickLength": 0,
        //         "parseDates": true
        //     },
        //     "export": {
        //         "enabled": true
        //     }
        // });
    }

        drawChartFamilyCalls(calls){

        //console.log("drawChartFamilyCalls", calls);

				const root = am5.Root.new("chartFamilyCalls");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

				const chartRootConfig = {
					panY: false,
					layout: root.verticalLayout,
					maxTooltipDistance: 0,
					arrangeTooltips: false
				};

        let chart = root.container.children.push( 
            am5xy.XYChart.new(root, chartRootConfig) 
        );

				// Define data
				let data = calls;
				// Create Y-axis
				let yAxis = chart.yAxes.push(
					am5xy.ValueAxis.new(root, {
						renderer: am5xy.AxisRendererY.new(root, {})
					})
				);
		
				// Create X-Axis
				let xAxis = chart.xAxes.push(
					am5xy.CategoryAxis.new(root, {
						renderer: am5xy.AxisRendererX.new(root, {}),
						categoryField: "date"
					})
				);
				xAxis.data.setAll(data);
		
				// Create series
				let series1 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Connected",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "calls_success",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0x018FFF),
    				stroke: am5.color(0x018FFF)
					})
				);
				series1.data.setAll(data);
				series1.set("active", am5.color(0x018FFF)); // set Series color to red
				series1.columns.template.setAll(this.chartSeriesColumnsConfig);
		
				let series2 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Failed",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "calls_fail",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0xFFCD01),
    				stroke: am5.color(0xFFCD01)
					})
				);
				series2.data.setAll(data);
				series2.set("fill", am5.color("#FFCD01")); // set Series color to green
				series2.columns.template.setAll(this.chartSeriesColumnsConfig);

				// Add legend
				let legend = chart.children.push(am5.Legend.new(root, {}));
				legend.data.setAll(chart.series.values);
		
				// Add cursor
				chart.set("cursor", am5xy.XYCursor.new(root, {
					maxTooltipDistance: 0,
							arrangeTooltips: false
				}));
		
				this.root5 = root;

        // var chart = AmCharts.makeChart("chartFamilyCalls", {
        //     "type": "serial",
        //     "addClassNames": true,
        //     "theme": "light",
        //     "autoMargins": false,
        //     "marginLeft": 30,
        //     "marginRight": 8,
        //     "marginTop": 10,
        //     "marginBottom": 26,
        //     "balloon": {
        //         "adjustBorderColor": false,
        //         "horizontalPadding": 10,
        //         "verticalPadding": 8,
        //         "color": "#ffffff"
        //     },
        //     "dataProvider": calls ,
        //
        //     "valueAxes": [{
        //         "axisAlpha": 0,
        //         "position": "left"
        //     }],
        //     "startDuration": 0,
        //     "graphs": [{
        //         "id": "graph1",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "lineColor":"#FA6500",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Calls Fail",
        //         "valueField": "calls_fail",
        //         "dashLengthField": "dashLengthLine"
        //     }, {
        //         "id": "graph2",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Calls Success",
        //         "valueField": "calls_success",
        //         "dashLengthField": "dashLengthLine"
        //     }],
        //     "categoryField": "date",
        //     "categoryAxis": {
        //         "gridPosition": "start",
        //         "axisAlpha": 0,
        //         "tickLength": 0,
        //         "parseDates": true
        //     },
        //     "export": {
        //         "enabled": true
        //     }
        // });
    }


        drawChartMediaBoxItems(mediaPerDayArray){

					//console.log("drawChartMediaBoxItems", mediaPerDayArray);

					const root = am5.Root.new("chartDailyPhotosUploaded");
					root.setThemes([
							am5themes_Animated.new(root)
					]);

					const chartRootConfig = {
						panY: false,
						layout: root.verticalLayout,
						maxTooltipDistance: 0,
						arrangeTooltips: false
					};

					let chart = root.container.children.push( 
							am5xy.XYChart.new(root, chartRootConfig) 
					);

					// Define data
					let data = mediaPerDayArray;
					// Create Y-axis
					let yAxis = chart.yAxes.push(
						am5xy.ValueAxis.new(root, {
							renderer: am5xy.AxisRendererY.new(root, {})
						})
					);
			
					// Create X-Axis
					let xAxis = chart.xAxes.push(
						am5xy.CategoryAxis.new(root, {
							renderer: am5xy.AxisRendererX.new(root, {}),
							categoryField: "date"
						})
					);
					xAxis.data.setAll(data);
			
					// Create series
					let series1 = chart.series.push(
						am5xy.ColumnSeries.new(root, {
							name: "Items/Pictures upload per day",
							xAxis: xAxis,
							yAxis: yAxis,
							valueYField: "Count",
							valueXField: "date",
							categoryXField: "date",
							fill: am5.color(0x018FFF),
							stroke: am5.color(0x018FFF)
						})
					);
					series1.data.setAll(data);
					series1.set("active", am5.color(0x018FFF)); // set Series color to red
					series1.columns.template.setAll(this.chartSeriesColumnsConfig);
			
					// let series2 = chart.series.push(
					// 	am5xy.ColumnSeries.new(root, {
					// 		name: "Failed",
					// 		xAxis: xAxis,
					// 		yAxis: yAxis,
					// 		valueYField: "calls_fail",
					// 		valueXField: "date",
					// 		categoryXField: "date",
					// 		fill: am5.color(0xFFCD01),
					// 		stroke: am5.color(0xFFCD01)
					// 	})
					// );
					// series2.data.setAll(data);
					// series2.set("fill", am5.color("#FFCD01")); // set Series color to green
					// series2.columns.template.setAll(this.chartSeriesColumnsConfig);

					// Add legend
					let legend = chart.children.push(am5.Legend.new(root, {}));
					legend.data.setAll(chart.series.values);
			
					// Add cursor
					chart.set("cursor", am5xy.XYCursor.new(root, {
						maxTooltipDistance: 0,
								arrangeTooltips: false
					}));
			
					this.root6 = root;

        // var chart = AmCharts.makeChart("chartDailyPhotosUploaded", {
        //     "type": "serial",
        //     "addClassNames": true,
        //     "theme": "light",
        //     "autoMargins": false,
        //     "marginLeft": 30,
        //     "marginRight": 8,
        //     "marginTop": 10,
        //     "marginBottom": 26,
        //     "balloon": {
        //         "adjustBorderColor": false,
        //         "horizontalPadding": 10,
        //         "verticalPadding": 8,
        //         "color": "#ffffff"
        //     },
        //     "dataProvider": mediaPerDayArray ,
        //
        //     "valueAxes": [{
        //         "axisAlpha": 0,
        //         "position": "left"
        //     }],
        //     "startDuration": 0,
        //     "graphs": [{
        //         "id": "graph1",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "lineColor":"#018FFF",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Items",
        //         "valueField": "Count",
        //         "dashLengthField": "dashLengthLine"
        //     }],
        //     "categoryField": "date",
        //     "categoryAxis": {
        //         "gridPosition": "start",
        //         "axisAlpha": 0,
        //         "tickLength": 0,
        //         "parseDates": true
        //     },
        //     "export": {
        //         "enabled": true
        //     }
        // });

    }


    render()
    {
        return (
            <div class="row content">
                <div class="row no-margin">
                    <div class="col s6 center-align">
                        <div class="nucleus-chart center-align">
                            <div class="nucleus-chart-title valign-wrapper">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Daily Call Request</span>
                            </div>
                            <div id="chartdiv" class="nucleus-chart-body">

                            </div>
                            {/* <p class="nucleus-font nucleus-font-small left-align">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-orange"> &bull; Emergency Calls</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-yellow"> &bull; Requested Calls</span>
                            </p> */}
                        </div>
                    </div>
                    <div class="col s6 center-align">
                        <div class="nucleus-chart center-align">
                            <div class="nucleus-chart-title valign-wrapper">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Daily Calls Made</span>
                            </div>
                            <div id="chartdiv2" class="nucleus-chart-body">

                            </div>
                            {/* <p class="nucleus-font nucleus-font-small left-align">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-orange"> &bull; Not Answered</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-yellow"> &bull; Answered</span>
                            </p> */}
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row content">
                    <div class="col s6 center-align">
                        <div class="nucleus-chart center-align">
                            <div class="nucleus-chart-title valign-wrapper">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Family Members</span>
                            </div>
                            <div id="chartFamilyMembers" class="nucleus-chart-body">

                            </div>
                            {/* <p class="nucleus-font nucleus-font-small left-align">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="color-nucleus-blue"> &bull; Active Family Members</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="color-nucleus-yellow"> &bull; Pending Family Members</span>
                            </p> */}
                        </div>
                    </div>
                    <div class="col s6 center-align">
                        <div class="nucleus-chart center-align">
                            <div class="nucleus-chart-title valign-wrapper">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Daily Messages</span>
                            </div>
                            <div id="chartMessages" class="nucleus-chart-body">

                            </div>
                            {/* <p class="nucleus-font nucleus-font-small left-align">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-yellow "> &bull;Message Read</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-orange"> &bull;Message Not Read</span>
                            </p> */}
                        </div>
                    </div>
                </div>

                <div class="row content">
                    <div class="col s6 center-align">
                        <div class="nucleus-chart center-align">
                            <div class="nucleus-chart-title valign-wrapper">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Family Call logs</span>
                            </div>
                            <div id="chartFamilyCalls" class="nucleus-chart-body">

                            </div>
                            {/* <p class="nucleus-font nucleus-font-small left-align">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-yellow "> &bull; Call success</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-orange"> &bull; Call fail</span>
                            </p> */}
                        </div>
                    </div>
                    <div class="col s6 center-align">
                        <div class="nucleus-chart center-align">
                            <div class="nucleus-chart-title valign-wrapper">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Daily Photo upload per Day</span>
                            </div>
                            <div id="chartDailyPhotosUploaded" class="nucleus-chart-body">

                            </div>
                            {/* <p class="nucleus-font nucleus-font-small left-align">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="color-nucleus-blue"> &bull;Items uploaded</span>
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default AccountStats;
