import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ProviderNavTabs from './components/ProviderNavTabs'
import { UIButton, UICard, UICheckbox, UINucleusContainer } from '../../components/UI'
import { UINucleusContentContainer } from '../../components/UI/NucleusContainer/Content'
import { UINucleusPageTitle } from '../../components/UI/NucleusPageTitle/Default'
import { ProviderFeaturesResponse, useNucleusProviders } from '../../context-api/nucleusProvidersContext/NucleusProvidersContext'
import { NucleusControlledWhiteBgInput } from './../../components/NucleusControlledInput';
import { useForm } from "react-hook-form";
import ProviderDetailsWrapper from './components/ProviderDetailsWrapper'
import { SpinnerCircular } from 'spinners-react'
import ImageUploader from '../../components/ImageUploader'
import { getProviderByID, getProviderFeatures, updateProviderByID, updateProviderFeaturesByID } from './utils/providerApi'
import Message from '../../utils/Message'
import ProviderEscalationConfig from './components/ProviderEscalationConfig';
import ProviderCallRequestConfig from './components/ProviderCallRequestConfig';
import styled from 'styled-components'

type formPayloadProps = {
  name: string,
  defaultScheduleSlotLengthInMinutes: string | number,
  providerLogo?: File,
  fileExtension?: string
}

const ProviderSettings = () => {
  const defaultPointClickCareValues = useMemo(() => {
    return {
      PointClickCareCheckBox: false,
      PointClickCareLabel: 'PointClickCare',
      PointClickCarePackageName: 'com.pointclickcare.crmandroid'
    }
  }, [])
  const { dispatch, state: { provider: { data, features: providerFeatures, state: { loading } } } } = useNucleusProviders()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    watch
  } = useForm();
  const onSetProviderDetails = async (formData) => {
    if (!data?.ID) return
    let providerUpdatedError = false
    if (
      data?.Name !== watch('ProviderName') ||
      data?.DefaultScheduleSlotLengthInMinutes !== watch('SchedulingSlotLength') ||
      watch('ProviderLogo')
    ) {
      const formPayload = new FormData();
      formPayload.append('name', formData.ProviderName);
      formPayload.append('defaultScheduleSlotLengthInMinutes', formData.SchedulingSlotLength);

      if (formData.ProviderLogo) {
        formPayload.append('providerLogo', formData.ProviderLogo, formData.ProviderLogo.name);
        formPayload.append('fileExtension', formData.ProviderLogo.name.split('.').pop());
      }
      const response = await updateProviderByID(data?.ID, formPayload)
      if (!response) {
        providerUpdatedError = true
      }
    }

    let providerFeatureError = false
    if (providerFeatures?.PointClickCareButton !== watch('PointClickCareCheckBox') ||
      providerFeatures?.PointClickCareButtonLabel !== watch('PointClickCareLabel') ||
      providerFeatures?.PointClickCareButtonPackageName !== watch('PointClickCarePackageName')) {
      const featuresBody: ProviderFeaturesResponse = {
        PointClickCareButton: formData.PointClickCareCheckBox,
        PointClickCareButtonLabel: formData.PointClickCareLabel,
        PointClickCareButtonPackageName: formData.PointClickCarePackageName
      }
      const featuresResponse = await updateProviderFeaturesByID(data?.ID, featuresBody)
      if (!featuresResponse) {
        providerFeatureError = true
      }
    }
    if (providerUpdatedError || providerFeatureError) {
      Message.show('Failed to update provider details')
    } else {
      Message.show('Provider updated successfully')
    }

    dispatch({
      type: 'fetch_provider',
      payload: {
        error: false,
        loading: true,
        success: false,
        provider: null,
        features: null
      }
    })
    const provider = await getProviderByID(data?.ID)
    const providerFeaturesRes = await getProviderFeatures(data?.ID)
    setValue('ProviderLogo', undefined);
    dispatch({
      type: 'fetch_provider',
      payload: {
        error: false,
        loading: false,
        success: true,
        provider: provider,
        features: providerFeaturesRes
      }
    })
  }
  useEffect(() => {
    setIsLoading(loading);
  }, [loading])
  useEffect(() => {
    if (data) {
      setValue('ProviderName', data?.Name)
      setValue('SchedulingSlotLength', data?.DefaultScheduleSlotLengthInMinutes)
      setValue('ProviderLogo', undefined)
    }
  }, [data])
  useEffect(() => {
    if (providerFeatures) {
      setValue('PointClickCareCheckBox', providerFeatures?.PointClickCareButton || defaultPointClickCareValues.PointClickCareCheckBox)
      setValue('PointClickCareLabel', providerFeatures?.PointClickCareButtonLabel || defaultPointClickCareValues.PointClickCareLabel)
      setValue('PointClickCarePackageName', providerFeatures?.PointClickCareButtonPackageName || defaultPointClickCareValues.PointClickCarePackageName)
    }
  }, [providerFeatures])

  const disableForm = useCallback(() => {
    if (loading || isLoading) {
      return true
    }
    return false
  }, [isLoading])

  const disableSubmit = useCallback(() => {
    if (isLoading) return true
    if (watch('ProviderLogo')) return false
    return (
      data?.Name === watch('ProviderName') &&
      data?.DefaultScheduleSlotLengthInMinutes === watch('SchedulingSlotLength') &&
      (providerFeatures?.PointClickCareButton === watch('PointClickCareCheckBox')) &&
      (providerFeatures?.PointClickCareButtonLabel === watch('PointClickCareLabel') || watch('PointClickCareLabel') === defaultPointClickCareValues.PointClickCareLabel) &&
      (providerFeatures?.PointClickCareButtonPackageName === watch('PointClickCarePackageName') || watch('PointClickCarePackageName') === defaultPointClickCareValues.PointClickCarePackageName)
    );
  }, [data, providerFeatures, isLoading, watch('ProviderName'), watch('SchedulingSlotLength'), watch('ProviderLogo'), watch('PointClickCareCheckBox'), watch('PointClickCareLabel'), watch('PointClickCarePackageName')])

  return (
    <ProviderDetailsWrapper>
      <UINucleusContainer>
        <UINucleusPageTitle style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          Provider: {data?.Name}
          {<UINucleusContentContainer style={{ display: 'flex', alignItems: "center" }}>
            {!!isLoading && <SpinnerCircular
              color="#2096F3"
              secondaryColor="rgba(0,0,0,0.16)"
              size="30"
              thickness={100}
            />}
          </UINucleusContentContainer>}
        </UINucleusPageTitle>
        <ProviderNavTabs activeTab='provider-settings' />
        <UINucleusContentContainer style={{ flexDirection: 'row', display: 'inline-flex', paddingBottom: 36, columnGap: 30 }}>
          <UINucleusContentContainer style={{ paddingTop: 30 }}>
            <UICard
              title='Set Provider Details'
              cardHeaderStyle={{
                fontFamily: "Barlow",
                fontSize: "20px",
                fontStyle: "italic",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left"
              }}
              style={{
                backgroundColor: "#EBF2FE",
                width: '28vw',
                paddingBottom: 20
              }}
            >

              <UINucleusContentContainer>
                <form onSubmit={handleSubmit(onSetProviderDetails)}>
                  <div>
                    <ImageUploader
                      onImageChange={() => { }}
                      setFileData={(file) => { setValue('ProviderLogo', file) }}
                      register={register('ProviderLogo')}
                      key={'provider-logo'}
                      defaultImageUrl={!!(data?.Logo && !isLoading) ? data.Logo : null}
                      errorMessage={''}
                    />
                  </div>
                  <br />
                  <NucleusControlledWhiteBgInput
                    disabled={disableForm()}
                    label={"Name:"}
                    name={"ProviderName"}
                    register={register("ProviderName", { required: true })}
                    error={errors.ProviderName}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    onChange={(e) => {
                      setValue('ProviderName', e.target.value)
                    }}
                    inputStyle={{
                      minHeight: 48
                    }}
                    containerStyle={{
                      marginBottom: 48,
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  />
                  <NucleusControlledWhiteBgInput
                    disabled={disableForm()}
                    label={"Default Scheduling Slot Length (minutes):"}
                    name={"SchedulingSlotLength"}
                    register={register("SchedulingSlotLength")}
                    error={errors.SchedulingSlotLength}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    onChange={(e) => {
                      setValue('SchedulingSlotLength', e.target.value)
                    }}
                    inputStyle={{
                      minHeight: 48
                    }}
                    containerStyle={{
                      marginBottom: 18,
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  />
                  <InputContainer>
                    <InputLabel>
                      {'PointClickCare Button:'}
                    </InputLabel>
                    <CheckBoxContainer>
                      <CheckBoxLabel>Enable</CheckBoxLabel>
                      <UICheckbox
                        disabled={disableForm()}
                        key={'PointClickCareCheckBox'}
                        checked={!!watch('PointClickCareCheckBox')}
                        onClick={() => setValue('PointClickCareCheckBox', !watch('PointClickCareCheckBox'))}
                        {...register('PointClickCareCheckBox')}
                      />
                    </CheckBoxContainer>
                  </InputContainer>
                  {!!watch('PointClickCareCheckBox') && <>
                    <NucleusControlledWhiteBgInput
                      disabled={disableForm()}
                      label={"PointClickCare Label:"}
                      name={"PointClickCareLabel"}
                      register={register("PointClickCareLabel")}
                      error={errors.PointClickCareLabel}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      onChange={(e) => {
                        setValue('PointClickCareLabel', e.target.value)
                      }}
                      inputStyle={{
                        minHeight: 48
                      }}
                      containerStyle={{
                        marginTop: 18,
                        marginBottom: 18,
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    />
                    <NucleusControlledWhiteBgInput
                      disabled={disableForm()}
                      label={"PointClickCare Package name:"}
                      name={"PointClickCarePackageName"}
                      register={register("PointClickCarePackageName")}
                      error={errors.PointClickCarePackageName}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      onChange={(e) => {
                        setValue('PointClickCarePackageName', e.target.value)
                      }}
                      inputStyle={{
                        minHeight: 48
                      }}
                      containerStyle={{
                        marginTop: 18,
                        marginBottom: 18,
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    />
                  </>
                  }
                  <br />
                  <UIButton
                    disabled={disableSubmit()}
                    onClick={handleSubmit(onSetProviderDetails)}
                    text='Save'
                    variant={disableSubmit() === true ? 'disabled' : 'primary'}
                  />
                </form>
              </UINucleusContentContainer>
            </UICard>
          </UINucleusContentContainer>
          <ProviderEscalationConfig />
          <ProviderCallRequestConfig />
        </UINucleusContentContainer>
      </UINucleusContainer>
    </ProviderDetailsWrapper>
  )
}


const InputContainer = styled.div`
  min-height: 70px;
`;
const InputLabel = styled.label`
  min-width: 130px;
  text-align: start;
  font-size: 16px;
  color: #0A313F;
  font-family: Barlow;
  margin-bottom: 2%;
`;
const CheckBoxLabel = styled.label`
  color: #0A313F;
  font-family: Barlow;
  font-size: 16px;
`
const CheckBoxContainer = styled.div`
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 10px;
`

export default ProviderSettings