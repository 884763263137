import { useState } from 'react';
import { SpinnerCircular, SpinnerCircularFixed, SpinnerDiamond, SpinnerInfinity, SpinnerRomb, SpinnerRoundFilled } from 'spinners-react';
import styled from 'styled-components';

const MainContainer = styled.div`
  background-color: #ebf2fe;
  padding: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const SwitchBody = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
`;

const SwitchLabel = styled.label`
  position: relative;
  margin-right: 20px;
  color: #0a313f;
  font-size: 16px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span<{ $checked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ $checked }) => ($checked ? '#2196F3' : '#ccc')};
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: ${({ $checked }) => ($checked ? '20px' : '4px')};
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;
export const SwitchContainer = ({ onChange, checked, label, loading }: { label: string; onChange: (checked: boolean) => void; checked?: boolean; loading?: boolean }) => {
  const handleToggle = () => {
    const nextState = !checked;
    onChange(nextState);
  };

  return (
    <MainContainer>
      <SwitchLabel>{label}</SwitchLabel>
      {!loading ? (
        <SwitchBody>
          <SwitchInput type="checkbox" checked={checked} onChange={handleToggle} />
          <Slider $checked={checked} />
        </SwitchBody>
      ) : (
        <SpinnerCircular size={30} color="#0A313F" />
      )}
    </MainContainer>
  );
};
