import { useEffect, useState } from 'react'
import { IAccountCatalogListItem, IProviderAccount, useNucleusProviders } from '../../../context-api/nucleusProvidersContext/NucleusProvidersContext'
import { UINucleusContainer } from '../../../components/UI'
import NucleusSearchInput from '../../../components/NucleusSearchInput'
import { UINucleusContentContainer } from '../../../components/UI/NucleusContainer/Content'
import NucleusTable from '../../../components/NucleusTable'
import { useForm } from 'react-hook-form'
import { getProviderAccountsToSelect } from '../utils/providerApi'
import { UICheckbox } from '../../../components/UI/Checkbox'
import { SpinnerCircular } from 'spinners-react'

import styled from 'styled-components';

const SelectOptionsText = styled.form`
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
`;

const SelectProviderAccounts = ({ setSelectedIDs, setInvalidAccounts }) => {
    const fastFilter = true;
    const [searchText, setSearchText] = useState<string>('')
    const { register, setValue, watch, handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {
        state: {
            provider: {
                data
            }
        }
    } = useNucleusProviders();
    
    const [selectedAccountsState, setSelectedAccounts] = useState<IAccountCatalogListItem[]>([]);
    const [selectedInvalidAccounts, setSelectedInvalidAccounts] = useState<string[]>([]);

    const [accountsData, setAccountsData] = useState<IAccountCatalogListItem[]>([]);

    useEffect(() => {
        const ids = Object.keys(selectedAccountsState).map((key) => selectedAccountsState[key].AccountID) as string[]
        setSelectedIDs(ids);
        setInvalidAccounts(selectedInvalidAccounts.length);
    }, [selectedAccountsState]);

    const handleSelectAll = () => {
        const accountIDs = accountsData.filter(a => !a.AlreadyConnected).map((account) => account.AccountID);

        const areAllDevicesSelected = accountIDs.every((id) =>
            selectedAccountsState.map((account) =>
                account.AccountID,
            ).includes(id),
        );

        if (!areAllDevicesSelected) {
            setSelectedAccounts(accountsData);
        } else {
            setSelectedAccounts([]);
        }
    };

    const SelectAllAccountsComponent = () => {
        const selectedAccountIDs = selectedAccountsState.map((account) => account.AccountID);
        const accountIDs = accountsData.filter(a => !a.AlreadyConnected).map((account) => account.AccountID);
        const areAllSelected = accountIDs.every((id) =>
            selectedAccountIDs.includes(id),
        );
        const isAllSelected = accountIDs.length > 0 && areAllSelected;
        return (
            <div
                style={{
                    marginLeft: 6
                }}
            >
                <UICheckbox
                    checked={isAllSelected}
                    onClick={() => {
                        handleSelectAll();
                    }}
                />
            </div>
        );
    };

    useEffect(() => {
        if (!data?.ID) return
        async function fetchProviderAccounts(providerId) {
            setIsLoading(true)
            const providersAccounts = await getProviderAccountsToSelect(providerId)
            setAccountsData(providersAccounts)
            setIsLoading(false)
        }
        fetchProviderAccounts(data.ID)
    }, [data]);
    
    const handleSubmitSearch = (data) => {
        console.log('handleSubmitSearch', data);
        setSearchText(data.search);
    };

    const handleOnCheckAccount = (providerAccount: IAccountCatalogListItem) => {
        if (providerAccount.AlreadyConnected) return;

        if (providerAccount.State == null){
            if (selectedInvalidAccounts.includes(providerAccount.AccountID)){
                setSelectedInvalidAccounts((prevState) => prevState.filter((accountId) => accountId !== providerAccount.AccountID));
            }
            else{
                setSelectedInvalidAccounts((prevState) => [...prevState, providerAccount.AccountID])
            }
        }

        const index = selectedAccountsState.findIndex((account) => account.AccountID === providerAccount.AccountID)
        if (index > -1) {
            setSelectedAccounts((prevState) => prevState.filter((account) => account.AccountID !== providerAccount.AccountID))
        } else {
            setSelectedAccounts((prevState) => [...prevState, providerAccount])
        }
    }

    const columns = [
        {
            Header: () => {
                return <SelectAllAccountsComponent />
            },
            accessor: "ID",
            Cell: ({ row }) => {
                const accountOriginal = row.original as IAccountCatalogListItem
                const index = selectedAccountsState.findIndex((account) => account.AccountID === row.original.AccountID)
                const checked = index > -1
                return (
                    !row.original.AlreadyConnected ?
                        <UICheckbox
                            disabled={row.original.AlreadyConnected}
                            checked={checked}
                            onClick={() => handleOnCheckAccount(accountOriginal)}
                        />
                        : ""
                )
            },
            disableSortBy: true,
        },
        {
            Header: "Account Name",
            accessor: "AccountName",
            Cell:({row, value}) =>{
                const index = selectedAccountsState.findIndex((account) => account.AccountID === row.original.AccountID)
                const checked = (index > -1);
                let textColor = (checked && row.original.State == null) ? '#FE3824' : '#0A313F';
                return (
                    <span style={{ color: textColor }}>
                        {value}
                    </span>
                );
            }
        },
        {
            Header: "Residents",
            accessor: "PatientCount",
            centerAlign: true,
            Cell:({row, value}) =>{
                const index = selectedAccountsState.findIndex((account) => account.AccountID === row.original.AccountID)
                const checked = (index > -1);
                let textColor = (checked && row.original.State == null) ? '#FE3824' : '#0A313F';
                return (
                    <span style={{ color: textColor }}>
                        {value}
                    </span>
                );
            }
        },
        {
            Header: "Total Devices",
            accessor: "DeviceCount",
            centerAlign: true,
            Cell:({row, value}) =>{
                const index = selectedAccountsState.findIndex((account) => account.AccountID === row.original.AccountID)
                const checked = (index > -1);
                let textColor = (checked && row.original.State == null) ? '#FE3824' : '#0A313F';
                return (
                    <span style={{ color: textColor }}>
                        {value}
                    </span>
                );
            }
        },
        {
            Header: "Online Devices",
            accessor: "OnlineDeviceCount",
            centerAlign: true,
            Cell:({row, value}) =>{
                const index = selectedAccountsState.findIndex((account) => account.AccountID === row.original.AccountID)
                const checked = (index > -1);
                let textColor = (checked && row.original.State == null) ? '#FE3824' : '#0A313F';
                return (
                    <span style={{ color: textColor }}>
                        {value}
                    </span>
                );
            }
        },
        {
            Header: "Users",
            accessor: "UsersCount",
            centerAlign: true,
            Cell:({row, value}) =>{
                const index = selectedAccountsState.findIndex((account) => account.AccountID === row.original.AccountID)
                const checked = (index > -1);
                const invalid = (checked && row.original.State == null);
                let textColor = invalid ? '#FE3824' : '#0A313F';
                return (
                    <span>
                        <span style={{ color: textColor }}>
                            {value}
                        </span>
                        {
                            (invalid) && 
                                <img
                                alt='Invalid account to add (Missing State)'
                                src="img/warning_small_icon.png"
                                style={{width:12, height:12, marginLeft:5}}
                            />
                        }
                    </span>
                );
            }
        },
    ];

    const getWarningMessage = (accountsNumber)=>{
        if (accountsNumber === 1){
            return ' account has no state assigned to it and can not be added.';
        } else {
            return ' accounts have no state assigned to them and can not be added.'
        }
    }

    return (
        <UINucleusContainer style={{
            padding: 10,
            display: 'flex',
            minHeight: '35vh',
            maxHeight: '70vh',
        }}>
            <UINucleusContentContainer style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div>
                    <form onSubmit={handleSubmit(handleSubmitSearch)}>
                        <NucleusSearchInput
                            name='search'
                            value={watch('search')}
                            register={register('search', {
                                onChange: () => {
                                    setValue('search', watch('search'))
                                    if (fastFilter) {
                                        setSearchText(watch('search'))
                                    }
                                }
                            })}
                            placeholder='Filter by Group/Name'
                        />
                    </form>
                </div>
                <div style={{ marginTop: 10, marginBottom: 5 }}>
                    <SelectOptionsText>Select from the options below:</SelectOptionsText>
                </div>
                <div style={{
                    flex: 1,
                    overflowY: 'scroll',
                    backgroundColor: 'white',
                    borderRadius: 10,
                }}>
                    <NucleusTable
                        tableStyles={{
                            backgroundColor: 'white',
                            borderRadius: 10
                        }}
                        checkForDisabled={({ row }) => row.original.AlreadyConnected}
                        disabledKey={'AlreadyConnected'}
                        tHeadStyle={{
                            position: 'sticky',
                            backgroundColor: 'white',
                            zIndex: 999,
                            top: 0,
                            borderRadius: 10
                        }}
                        tbodyStyles={{
                            overflowY: 'scroll',
                            backgroundColor: 'white',
                            borderRadius: 10
                        }}

                        columns={columns}
                        onRowClick={({ row: { original } }) => {
                            handleOnCheckAccount(original)
                        }}
                        data={
                            accountsData
                                .filter(data =>
                                    data.AccountName.toLowerCase().includes(searchText.toLowerCase())
                                    || data.AccountID.toLowerCase().includes(searchText.toLowerCase()))
                        }
                    />
                    {
                        isLoading && <UINucleusContentContainer centerContent style={{ marginTop: 10 }}>
                            <SpinnerCircular
                                color="#2096F3"
                                secondaryColor='rgba(0,0,0,0.16)'
                                size='50'
                                thickness={100}
                            />
                        </UINucleusContentContainer>
                    }
                </div>
                { (selectedInvalidAccounts.length > 0) &&
                    <span>
                        <img
                            alt='Invalid account to add (Missing State)'
                            src="img/warning_small_icon.png"
                            style={{width:12, height:12, marginLeft:5, marginRight:5}}
                        />
                        <WarningLabel>{'Error ' + selectedInvalidAccounts.length + getWarningMessage(selectedInvalidAccounts.length) }</WarningLabel>
                    </span>
                }
            </UINucleusContentContainer>
        </UINucleusContainer>
    )
}

export default SelectProviderAccounts;

const WarningLabel = styled.label`
  font-size: 12px;
  color: #FE3824;
  font-family: 'Fira Sans'; 
`;