import React from 'react';

export const SuccessIcon = ({ size = 12 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.68071 0.182903C4.86271 -0.0609677 7.11809 -0.0609677 9.3001 0.182903C10.1193 0.274463 10.8385 0.717483 11.2934 1.36086L5.63706 7.0172L3.89172 5.27186C3.68473 5.06487 3.34913 5.06487 3.14214 5.27186C2.93515 5.47885 2.93515 5.81445 3.14214 6.02144L5.26227 8.14156C5.46926 8.34855 5.80486 8.34855 6.01185 8.14156L11.7471 2.40631C11.763 2.48374 11.7757 2.56237 11.785 2.64206C12.0459 4.87308 12.0459 7.12692 11.785 9.35794C11.6331 10.6561 10.5908 11.6728 9.3001 11.8171C7.11809 12.061 4.86271 12.061 2.68071 11.8171C1.38999 11.6728 0.347662 10.6561 0.195826 9.35794C-0.0651126 7.12692 -0.0651125 4.87308 0.195826 2.64206C0.347662 1.34386 1.38999 0.32716 2.68071 0.182903Z" fill="#008000" />
        </svg>
    );
}

export const ErrorIcon = ({ size = 12, fill = '#FE3824' }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM6 8C5.58579 8 5.25 8.33579 5.25 8.75C5.25 9.16421 5.58579 9.5 6 9.5C6.41421 9.5 6.75 9.16421 6.75 8.75C6.75 8.33579 6.41421 8 6 8ZM6 2.5C5.75454 2.5 5.55039 2.67688 5.50806 2.91012L5.5 3V6.5L5.50806 6.58988C5.55039 6.82312 5.75454 7 6 7C6.24546 7 6.44961 6.82312 6.49194 6.58988L6.5 6.5V3L6.49194 2.91012C6.44961 2.67688 6.24546 2.5 6 2.5Z" fill={fill} />
        </svg>
    );
}

export const GoToArrow = ({ onClick, size = 20, outlineColor = '#0092FF' }: { onClick?: () => void, size?: number, outlineColor?: string }) => {
    return (
        <svg onClick={onClick} width={size} height={size + 2} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.29 8.17005L5.69999 2.07005C2.94999 0.620046 -0.0400073 3.55005 1.34999 6.33005L2.96999 9.57005C3.41999 10.47 3.41999 11.53 2.96999 12.43L1.34999 15.67C-0.0400073 18.45 2.94999 21.37 5.69999 19.93L17.29 13.83C19.57 12.63 19.57 9.37005 17.29 8.17005Z" stroke={outlineColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export const CopyIcon = ({ onClick = () => { }, size = 16, fill = '#0092FF' }) => {
    return (
        <svg onClick={onClick} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M10.6666 0.666748H2.66665C1.92998 0.666748 1.33331 1.26341 1.33331 2.00008V11.3334H2.66665V2.00008H10.6666V0.666748ZM12.6666 3.33341H5.33331C4.59665 3.33341 3.99998 3.93008 3.99998 4.66675V14.0001C3.99998 14.7367 4.59665 15.3334 5.33331 15.3334H12.6666C13.4033 15.3334 14 14.7367 14 14.0001V4.66675C14 3.93008 13.4033 3.33341 12.6666 3.33341ZM12.6666 14.0001H5.33331V4.66675H12.6666V14.0001Z" fill={fill} />
            </g>
            <defs>
                <clipPath>
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export const TrashIcon = ({ onClick = () => { }, size = 21, fill = '#FF4C3F' }) => {
    return (
        <svg onClick={onClick} width={size} height={size} viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1991 6.11916C20.1776 6.11916 20.1453 6.11916 20.1129 6.11916C14.4152 5.52753 8.72822 5.30427 3.09507 5.8959L0.897829 6.11916C0.445456 6.16381 0.0469366 5.82893 0.00385344 5.36008C-0.0392297 4.89124 0.283894 4.48938 0.725496 4.44472L2.92274 4.22147C8.65283 3.61867 14.4583 3.85309 20.2745 4.44472C20.7161 4.48938 21.0392 4.9024 20.9961 5.36008C20.9638 5.79544 20.6084 6.11916 20.1991 6.11916Z" fill={fill} />
            <path d="M6.73753 4.98983C6.69445 4.98983 6.65137 4.98983 6.59751 4.97866C6.16668 4.90052 5.8651 4.46517 5.94049 4.01865L6.17745 2.55631C6.34978 1.48467 6.58674 0 9.09629 0H11.9182C14.4386 0 14.6756 1.54048 14.8371 2.56747L15.0741 4.01865C15.1495 4.47633 14.8479 4.91169 14.4171 4.97866C13.9755 5.0568 13.5554 4.74424 13.4908 4.29772L13.2538 2.84654C13.103 1.87537 13.0707 1.6856 11.929 1.6856H9.10706C7.9654 1.6856 7.94386 1.84188 7.7823 2.83538L7.53457 4.28656C7.46995 4.69959 7.12528 4.98983 6.73753 4.98983Z" fill={fill} />
            <path d="M13.9636 23.9997H7.04874C3.28974 23.9997 3.13895 21.8453 3.02047 20.1039L2.32037 8.86279C2.28806 8.40511 2.63272 8.00326 3.07432 7.96977C3.5267 7.94745 3.90367 8.2935 3.93599 8.75118L4.63609 19.9923C4.75457 21.689 4.79765 22.3253 7.04874 22.3253H13.9636C16.2255 22.3253 16.2685 21.689 16.3763 19.9923L17.0764 8.75118C17.1087 8.2935 17.4964 7.94745 17.938 7.96977C18.3796 8.00326 18.7243 8.39395 18.692 8.86279L17.9919 20.1039C17.8734 21.8453 17.7226 23.9997 13.9636 23.9997Z" fill={fill} />
            <path d="M12.2934 17.8617H8.70677C8.26515 17.8617 7.89894 17.4822 7.89894 17.0245C7.89894 16.5668 8.26515 16.1873 8.70677 16.1873H12.2934C12.735 16.1873 13.1013 16.5668 13.1013 17.0245C13.1013 17.4822 12.735 17.8617 12.2934 17.8617Z" fill={fill} />
            <path d="M13.199 13.3964H7.81358C7.37197 13.3964 7.00577 13.0168 7.00577 12.5591C7.00577 12.1015 7.37197 11.7219 7.81358 11.7219H13.199C13.6406 11.7219 14.0068 12.1015 14.0068 12.5591C14.0068 13.0168 13.6406 13.3964 13.199 13.3964Z" fill={fill} />
        </svg>
    );
}

export const ArrowIcon = ({ onClick, size = 21, color = "#0092FF" }) => {
    return (
        <svg onClick={onClick} width={size} height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4289 15.6854C13.2234 15.6854 13.018 15.6097 12.8558 15.4475C12.5422 15.1339 12.5422 14.6149 12.8558 14.3013L18.8461 8.31106L12.8558 2.32079C12.5422 2.00722 12.5422 1.4882 12.8558 1.17463C13.1694 0.86106 13.6884 0.86106 14.0019 1.17463L20.5653 7.73798C20.8789 8.05155 20.8789 8.57056 20.5653 8.88413L14.0019 15.4475C13.8397 15.6097 13.6343 15.6854 13.4289 15.6854Z" fill={color} />
            <path d="M19.8092 9.11997H1.61125C1.16793 9.11997 0.800293 8.75233 0.800293 8.30901C0.800293 7.86568 1.16793 7.49805 1.61125 7.49805H19.8092C20.2525 7.49805 20.6201 7.86568 20.6201 8.30901C20.6201 8.75233 20.2525 9.11997 19.8092 9.11997Z" fill={color} />
        </svg>
    );
}