import { useState } from "react"

export const useHoverToast = () => {
    const [showToast, setShowToast] = useState(false);
    const [coords, setCoords] = useState({ x: 0, y: 0 });

    const handleMouseEnter = (e: React.MouseEvent<HTMLSpanElement>) => {
        const { offsetX, offsetY } = e.nativeEvent;
        setCoords({ x: offsetX, y: offsetY });
        setShowToast(true);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLSpanElement>) => {
        const { offsetX, offsetY } = e.nativeEvent;
        setCoords({ x: offsetX, y: offsetY });
    };

    const handleMouseLeave = () => {
        setShowToast(false);
    };
    return { showToast, handleMouseEnter, handleMouseMove, handleMouseLeave, coords };
};
