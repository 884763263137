import { useDispatch } from "react-redux";
import NucleusInput from "../../../../components/NucleusInput";
import NucleusLabel from "../../../../components/NucleusLabel";
import Select from "../../../../components/Select";
import React, { useEffect, useState } from "react";
import { setNetworkConfigurationsInPolicyJSON } from '@nucleus-care/nucleuscare-backend-client';
import message from "../../../../utils/Message";

const MDMNewNetworkForm = ({ closeModalAction }) => {
  const dispatch = useDispatch();
  // NewNetwork form
  const [networkGUID, setNetworkGUID] = useState("");
  const [networkName, setNetworkName] = useState("");
  const [networkSSID, setNetworkSSID] = useState("");
  const [networkSecurity, setNetworkSecurity] = useState(""); 
  const [networkType, setNetworkType] = useState("WiFi"); //always??;
  const [wifiConfigFormData, setWifiConfigFormData] = useState("");
  const [wifiPassword, setWifiPassword] = useState("");
  // NewNetwork form
  const resetValues = () => {
    setNetworkGUID("");
    setNetworkName("");
    setNetworkSSID("");
    setNetworkSecurity("");
    setNetworkType("WiFi");
    setWifiConfigFormData("");
    setWifiPassword("");
  };

  const securityOptions = [
    { name: "None", value: "None" },
    { name: "WEP-PSK", value: "WEP-PSK" },
    { name: "WPA-PSK", value: "WPA-PSK" },
    { name: "WPA-EAP", value: "WPA-EAP" },
    { name: "WEP-8021X", value: "WEP-8021X" },
  ];
  const handleSecurity = (e) => {
    setNetworkSecurity(e.target.value);
  };

  const [macRandomizationOptions, setRandomizationOptions] = useState([
    { name: "Hardware", value: "Hardware" },
    { name: "Automatic", value: "Automatic" },
  ]);
  const [randomization, setRandomization] = useState("Automatic");
  const handleRandomization = (e) => {
    setRandomization(e.target.value);
  };
  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  useEffect(() => {
    setNetworkGUID(uuidv4());
  }, []);
  const buildNetworkObject = () => {
    const network = {
      GUID: networkGUID,
      Name: networkName,
      Type: "WiFi",
      WiFi: {
        SSID: networkSSID,
        Name: networkName,
        Security: networkSecurity,
        Passphrase: wifiPassword,
        AutoConnect: true, // ALWAYS
        MACAddressRandomizationMode: randomization,
      },
    };
    return network;
  };
  const handleSave = () => {
    console.log()
    if (!networkName) {
      message.error("Network name cannot be empty");
      return;
    }
    if (!networkSSID) {
      message.error("Network SSID cannot be empty");
      return;
    }
    if (!networkSecurity) {
      message.error("A network security must be selected");
      return;
    }
    if (networkSecurity !== 'None' && !wifiPassword) {
      message.error('Passphrase cannot be empty if security is selected');
      return;
    }

    const network = buildNetworkObject();
    console.log("clicked save", network);
    dispatch(setNetworkConfigurationsInPolicyJSON([network]));
    message.show("Network saved");
    closeModalAction(false);
    resetValues();
  };

  return (
    <div
      style={{
        // display: "flex",
        alignItems: "left",
        width: "100%",
        flexDirection: "column",
        marginTop: '30px',
      }}
    >
      <span
        style={{
          color: "#0A313F",
          fontSize: 24,
          marginTop: 5,
          marginBottom: 40,
          fontWeight:'bold'
        }}
      >
        Add a New Network
      </span>
      <div style={{paddingBottom:24}}>
      <div style={{alignSelf:'center'}}>
      <NucleusInput
        onChange={(e) => {
          setNetworkName(e.target.value);
        }}
        value={networkName}
        label={"Name"}
        placeholder={"Empty"}
        style={{ backgroundColor: 'white', width: '170px', borderRadius: '5px', paddingLeft:7}}
      />
      <NucleusInput
        onChange={(e) => {
          setNetworkSSID(e.target.value);
        }}
        value={networkSSID}
        label={"SSID"}
        placeholder={"Empty"}
        style={{ backgroundColor: 'white', width: '170px', borderRadius: '5px', paddingLeft:7}}
      />
      <div
        style={{
          display: "flex",
          flex:1,
          flexDirection: "row",
          marginRight: "auto",
          textAlign: "left",
          marginBottom: '40px'
        }}
      >
        
        <NucleusLabel
          label={"Security:"}
          labelMinWidth={192}
          style={{marginLeft: 2}}
        />
        <Select
          className="x"
          id="security"
          name={"security"}
          value={networkSecurity}
          onChange={handleSecurity}
          style={{width: '178px', right: '9px'}}
        >
          <option value="" disabled selected>
            Select
          </option>
          {securityOptions.map((option) => {
            return (
              <option key={option.name} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </Select>
      </div>
      <NucleusInput
        width='100%'
        onChange={(e) => {
          setWifiPassword(e.target.value);
        }}
        value={wifiPassword}
        label={"Passphrase"}
        placeholder={"Empty"}
        style={{ backgroundColor: 'white', width: '170px', borderRadius: '5px', paddingLeft:7}}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginRight: "auto",
          textAlign: "left",
        }}
      >
        <NucleusLabel
          label={"MAC Randomization Mode:"}
          labelMinWidth={192}
          style={{marginLeft: 2, verticalAlign: 'bottom'}}
        />
        <span>
        <Select
          className="x"
          id="randomization"
          name={"randomization"}
          value={randomization}
          onChange={handleRandomization}
          style={{width: '178px', right: '9px'}}
        >
          {macRandomizationOptions.map((option) => {
            return (
              <option key={option.name} value={option.value}>
                {option.name}
              </option>
            );
          })}
          </Select>
          </span>
      </div>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          marginTop:32
        }}
      >
        <div class="col s5 no-margin nucleus-btn-center ">
          <button
            onClick={() => {
              closeModalAction(false);
              resetValues();
            }}
            style={{
              marginTop: "20px",
              backgroundColor: "#E5E5E5",
              borderRadius: 50,
              color: "#0A313F",
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "#0A313F",
            }}
            className="btn"
          >
            {"CANCEL"}
          </button>
        </div>
        <div class="col s5 no-margin nucleus-btn-center ">
          <button
            onClick={() => handleSave()}
            style={{
              marginTop: "20px",
              backgroundColor: "#0A313F",
              borderRadius: 50,
            }}
            className="btn"
          >
            {"SAVE"}
          </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default MDMNewNetworkForm;
