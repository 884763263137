import { toast } from "react-toastify";

//Message
class Message {

	// show (text, time = 3000) {
	// 	// console.log("Message Show");
	// 	// console.log(text, time);
	// 	//Materialize.toast(text, time, 'rounded');
	// 	toast(text, {
	// 		position: "top-right",
	// 		autoClose: time,
	// 		hideProgressBar: false,
	// 		closeOnClick: true,
	// 		pauseOnHover: true,
	// 		draggable: true,
	// 		progress: undefined,
	// 		theme: "dark",
	// 	});
	// }

	show (text, time = 3000) {
		toast.info(text, {
			position: "top-right",
			autoClose: time,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: true,
			progress: undefined,
			theme: "colored",
		});
	}

	warning (text, time = 3000) {
		toast.warn(text, {
			position: "top-right",
			autoClose: time,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: true,
			progress: undefined,
			theme: "colored",
		});
	}

	error (text, time = 3000) {
		toast.error(text, {
			position: "top-right",
			autoClose: time,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: true,
			progress: undefined,
			theme: "colored",
		});
	}

}

const message = new Message();
export default message;
