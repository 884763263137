import React from 'react';
import ReactDOM from "react-dom";



import Message from "../utils/Message";
import CareAccountStore from "../stores/CareAccountStore";
import message from '../utils/Message';
import MdmDeviceApi from '../apis/MdmDeviceApi';

class AccountDeviceItemRow extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			deviceID : this.props.ID,
			patientName:this.props.PatientName,
			status : false, 
			remoteLogsEnabled : false,
			isCheckedToMove : false
		};
		//console.log(this.state.patientID);
		this.handleRowClicked = this.handleRowClicked.bind(this);

		this.handleDownloadLogs = this.handleDownloadLogs.bind(this);
		this.handleDeleteDevice = this.handleDeleteDevice.bind(this);

		this.handleDeviceReboot = this.handleDeviceReboot.bind(this);
	}

	componentDidMount()
	{
		//console.log("AccountDeviceItemRow componentDidMount >", this.props.ID);
		this.setState({
			deviceID : this.props.ID,
			remoteLogsEnabled : this.props.RemoteLogs,
			isCheckedToMove : this.props.isCheckedToMove
		});
		//window.$('.tooltipped').tooltip({delay: 50});
	}

	componentWillUnmount()
	{
		//window.$('.tooltipped').tooltip('remove');
	}

	handleRowClicked(){
		//window.location.assign("#/patientProfile/" + this.props.PatientID + "/null" );
		//console.log("handleRowClicked", this.props);
		this.props.onClick && this.props.onClick();
	}

	handleDownloadLogs(){
		// Functionality changed on Nov 8th for adding a modal
		// let macString = this.props.Mac.replace(/:/g, '');
		// var url = "https://analytics.nucleuslife.io/upload/" + macString + ".txt";
		// window.open(url, '_blank');

		CareAccountStore.handleOpenDownloadDeviceLogsModal({
			DeviceMac : this.props.Mac
		});
	}

	handleDeleteDevice(){
		CareAccountStore.handleOpenDeleteDeviceModal({
			DeviceID : this.props.ID,
			DeviceMac : this.props.Mac,
			DeviceName : this.props.DeviceName,
            PreAssignedName: this.props.PreAssignedName,
			DeviceAssigned : this.props.DeviceAssigned
		});
		this.props.handleAction()
	}

	handleDeviceReboot(){
		if(this.props.HexnodeID) {
		CareAccountStore.requestRebootDevice({
			Mac : this.props.Mac,
			DeviceID : this.props.ID,
		});
		} else {
            const mdmDeviceApi = new MdmDeviceApi();
            mdmDeviceApi.requestDeviceReboot({
                MdmID: this.props.MdmID
            }, (response) => {
                console.log("requestDeviceReboot response", response);
                if (response.ok) {
                    message.show("Device reboot request has been sent", "success");
                } else {
                    message.show("Failed to send device reboot request", "error");
                }
            });
        }
		this.props.handleAction()
	}

	handleCheckRemoteLogs = (value) =>{
		console.log("this has changed ", value);

		CareAccountStore.handleEnabledRemoteLogs({
			DeviceID: this.props.ID,
			Enabled: value
		});

	}
	handleCheckMoveDevices = (event) => {
		console.log("handleCheckMoveDevices checked ", event);
		this.props.onCheckToMove()
	}

	render()
	{

		let patientName = this.props.PatientName;
		if (patientName != null && patientName.length > 25){
				patientName = patientName.substring(0, 25) + " ...";
		}
		if (!this.props.DeviceAssigned){
			patientName = "N/A";
		}

		let deviceName = this.props.DeviceName;
		if (deviceName != null && deviceName.length > 18){
				deviceName = deviceName.substring(0, 18) + " ...";
		}

		//console.log("DEVICE INFO>>> ", this.props.DeviceInfo);
		let deviceStatusIcon = "img/icon_device_inactive.png";
		let deviceLastCheckIn = "Device not assigned";

		//let deleteDeviceVisibility = " ";
		//let rebootDeviceVisibility = " hide";
		//let rebootDeviceSpace = " ";
		let deviceVersion = "";
		let timeZone = "";

				//console.log(this.props.DeviceInfo);
				deviceStatusIcon = (this.props.DeviceInfoOnline) ? "img/icon_device_active.png" : "img/icon_device_inactive.png";
				deviceLastCheckIn = (this.props.DeviceInfoLastCheckinTime && this.props.DeviceInfoLastCheckinTime.length > 0) ? this.props.DeviceInfoLastCheckinTime : "Device Offline";
				//deleteDeviceVisibility = " hide";
				try {
					if (this.props.DeviceInfoLastCheckinTime ){
						let lastDate = new Date(this.props.DeviceInfoLastCheckinTime)
						//lastDate.setHours(lastDate.getHours() + ( this.props.DeviceInfoTimezoneOffset ));
						deviceLastCheckIn = 
						lastDate.toLocaleDateString("en-US", { year: '2-digit', month: '2-digit', day: 'numeric' }) + 
						" " + 
						lastDate.toLocaleTimeString("en-US", { hour12 : true, hour: "2-digit", minute: "2-digit" }) ;
					}
				} catch (error) {
					
				}

				deviceVersion = (this.props.DeviceInfoAppVersion || "");
				timeZone = this.props.DeviceInfoTimezone;
		
		if (this.props.DeviceInfo2 != "{}" && this.props.DeviceInfo2 != "" && this.props.DeviceInfo2 != null){
			let deviceInfo2 = JSON.parse(this.props.DeviceInfo2);

			deviceVersion = deviceInfo2.systemName + " " + deviceInfo2.systemVersion + ", " + (deviceInfo2.model ? (deviceInfo2.model + ", ") : "")  + deviceVersion;
			if (deviceInfo2.brand == "goldtek"){
				deviceVersion = "Legacy " + deviceVersion;
			}
		}

		// let defaultValueCheckbox = '';
		// if (this.props.RemoteLogs) {
		// 	defaultValueCheckbox = "checked";
		// }
		
		// Deprecated
		// let wifi = "";
		// if (this.props.HardwareInfo){
		// 	wifi = JSON.parse(this.props.HardwareInfo);
		// 	try{
		// 		wifi = JSON.parse(wifi);
		// 	}catch(e){}
		// 	if (wifi.wifi){
		// 		wifi = wifi.wifi;
		// 	}
		// }

		let deviceIdentifier = "";
		(this.props.Mac) && (deviceIdentifier = this.props.Mac);
		(this.props.HexnodeID) && (deviceIdentifier = this.props.HexnodeID);
		(this.props.MdmDeviceID) && (deviceIdentifier = this.props.MdmDeviceID);

		return (
			<tr>
				<td class="">
					{
						(this.props.DeviceAssigned) &&
						<div class="center-align">
							<input 
						checked={!!this.props.isCheckedToMove}
						disabled={this.props.DeviceAssigned ? true : false}
						ref="checkMoveDevice"
						type="checkbox" 
						class="filled-in"
						id={"checkMoveDevice" + this.props.ID}
						onChange={this.handleCheckMoveDevices} 
							/>
							<label 
								style={{left:"10%"}}
								class="center-align"
								for={"checkMoveDevice" + this.props.ID}>
								</label>
						</div>
					}
					{
						(!this.props.DeviceAssigned) &&
						<span class="nucleus-table-icon nucleus-link" style={{ padding: 3 }}>
							{this.props.isCheckedToMove && 
							<i class="Tiny material-icons"
								onClick={()=>{
									this.setState({
										isCheckedToMove : false
									});
									this.handleCheckMoveDevices();
								}}>
								check_box
							</i>
						}
						{!this.props.isCheckedToMove && 
							<i class="Tiny material-icons"
								onClick={()=>{
									this.setState({
										isCheckedToMove : true
									});
									this.handleCheckMoveDevices();
								}}>
								check_box_outline_blank
							</i>
						}
						</span>
					}						
				</td>
				<td class="b-col-2">
					{deviceIdentifier}
				</td>
				<td class="b-col-2">{patientName}</td>
				<td class="">{deviceName}</td>
				{/* <td class="table-col-10">{this.props.PreAssignedName}</td> */}
				<td class="b-col">{deviceVersion}{this.props.ShellVersion ? " - " + this.props.ShellVersion : ""}</td>
				{/* 
				<td class="table-col-5" style={{textAlign : 'center'}}>{wifi}</td> 
				*/}
				<td class="">
					<img class=" " src={deviceStatusIcon} />
				</td>
				<td class="table-col-6" style={{textAlign : 'center'}}>
					{	
						this.props.Battery ? this.props.Battery + "%" : "-"
					}
				</td>
				<td class="">{deviceLastCheckIn}</td>
				{/* <td class="table-col-5" style={{textAlign : 'center'}}>
					{timeZone}
				</td> */}

				<td class="">
					<span class="nucleus-table-icon nucleus-link" onClick={this.handleRowClicked}>
						<i class="Tiny material-icons">view_headline</i>
					</span>
				</td>

				{
					//REMOVED LOG ROW FROM DEVICES TAB 02/06/2023
					/*<td class="">
						{/* <span
							class="nucleus-table-icon nucleus-link tooltipped"
							data-delay="50"
							data-position="top"
							data-tooltip="Download Device Logs"
						> 
							<i
								class="Tiny material-icons nucleus-link"
								onClick={this.handleDownloadLogs}
							>
								file_download
							</i>
						
								
						<span class="" style={{ padding: 3 }}>
							{this.state.remoteLogsEnabled && 
								<i class="Tiny material-icons"
									onClick={()=>{
										this.setState({
											remoteLogsEnabled : false
										});
										this.handleCheckRemoteLogs(false);
									}}>
									check_box
								</i>
							}
							{!this.state.remoteLogsEnabled && 
								<i class="Tiny material-icons"
									onClick={()=>{
										this.setState({
											remoteLogsEnabled : true
										});
										this.handleCheckRemoteLogs(true);
									}}>
									check_box_outline_blank
								</i>
							}
							
						</span>
					</td>*/
				}
				{
					//REMOVED MOVE ROW FROM DEVICES TAB 02/06/2023
					/*<td class="">
						{
							(this.props.DeviceAssigned) &&
							<div class="center-align">
								<input 
							checked={this.props.isCheckedToMove}
							disabled={this.props.DeviceAssigned ? true : false}
							ref="checkMoveDevice"
							type="checkbox" 
							class="filled-in"
							id={"checkMoveDevice" + this.props.ID}
							onChange={this.handleCheckMoveDevices} 
								/>
								<label 
									style={{left:"10%"}}
									class="center-align"
									for={"checkMoveDevice" + this.props.ID}>
									</label>
							</div>
						}
						{
							(!this.props.DeviceAssigned) &&
							<span class="nucleus-table-icon nucleus-link" style={{ padding: 3 }}>
								{this.props.isCheckedToMove && 
								<i class="Tiny material-icons"
									onClick={()=>{
										this.setState({
											isCheckedToMove : false
										});
										this.handleCheckMoveDevices();
									}}>
									check_box
								</i>
							}
							{!this.props.isCheckedToMove && 
								<i class="Tiny material-icons"
									onClick={()=>{
										this.setState({
											isCheckedToMove : true
										});
										this.handleCheckMoveDevices();
									}}>
									check_box_outline_blank
								</i>
							}
							</span>
						}						
					</td>*/
				}
				<td class="">
					<span style={{display:'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
					<span
						class="nucleus-table-icon nucleus-link tooltipped"
						data-delay="50"
						data-position="top"
						data-tooltip="Remove"
					>
						<i
							class="Tiny material-icons"
							onClick={this.handleDeleteDevice}
						>
							delete
						</i>
					</span>
					<span
						class="nucleus-table-icon nucleus-link tooltipped "
						style={{marginLeft:3}}
						data-delay="50"
						data-position="top"
						data-tooltip="Remote restart"
					>
						{
						(this.props.DeviceOS != "ios") && 
							<img
							src="img/icon_device_restart.png"
							class="nucleus-link"
							onClick={this.handleDeviceReboot}
							width="28"
							/>
						}
						{
						(this.props.DeviceOS == "ios") && 
							<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						}
					</span>
					</span>
				</td>
			</tr>
		);
	}
}

export default AccountDeviceItemRow;
