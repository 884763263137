export const SortArrowDown = () => (
  <svg
    style={{ transform: "scaleY(-1)", marginLeft: 5 }}
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="12" height="9" fill="#0092FF" />
      <g id="1. MDM Policies" clipPath="url(#clip0_0_1)">
        <rect
          width="1321"
          height="952"
          transform="translate(-173 -216)"
          fill="white"
        />
        <path
          id="Triangle 17"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.59313 1.56961C5.79254 1.29044 6.20746 1.29044 6.40687 1.56961L10.4353 7.20938C10.6717 7.54031 10.4351 8 10.0284 8L1.9716 8C1.56491 8 1.32835 7.54032 1.56473 7.20938L5.59313 1.56961Z"
          fill="#0092FF"
          stroke="#0092FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect
            width="1321"
            height="952"
            fill="white"
            transform="translate(-173 -216)"
          />
        </clipPath>
      </defs>
    </svg>
  </svg>
);
export const SortArrowUp = () => (
  <svg
    style={{ marginLeft: 5 }}
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="9" fill="#0092FF" />
    <g id="1. MDM Policies" clipPath="url(#clip0_0_1)">
      <rect
        width="1321"
        height="952"
        transform="translate(-173 -216)"
        fill="white"
      />
      <path
        id="Triangle 17"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59313 1.56961C5.79254 1.29044 6.20746 1.29044 6.40687 1.56961L10.4353 7.20938C10.6717 7.54031 10.4351 8 10.0284 8L1.9716 8C1.56491 8 1.32835 7.54032 1.56473 7.20938L5.59313 1.56961Z"
        fill="#0092FF"
        stroke="#0092FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect
          width="1321"
          height="952"
          fill="white"
          transform="translate(-173 -216)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const SortNeutral = () => (
  <svg
    style={{ marginLeft: 5, marginBottom:-4 }}
    width="12"
    height="18"
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="CombinedArrows" clipPath="url(#clip0_0_3)">
      {/* Down Arrow */}
      <path
        id="TriangleDown"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59313 1.56961C5.79254 1.29044 6.20746 1.29044 6.40687 1.56961L10.4353 7.20938C10.6717 7.54031 10.4351 8 10.0284 8L1.9716 8C1.56491 8 1.32835 7.54032 1.56473 7.20938L5.59313 1.56961Z"
        fill="#C4C4C4"
        stroke="#C4C4C4"
      />
      {/* Up Arrow */}
      <path
        id="TriangleUp"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59313 16.4304C5.79254 16.7096 6.20746 16.7096 6.40687 16.4304L10.4353 10.7906C10.6717 10.4597 10.4351 10 10.0284 10L1.9716 10C1.56491 10 1.32835 10.4597 1.56473 10.7906L5.59313 16.4304Z"
        fill="#C4C4C4"
        stroke="#C4C4C4"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_3">
        <rect
          width="1321"
          height="952"
          fill="white"
          transform="translate(-173 -216)"
        />
      </clipPath>
    </defs>
  </svg>
);


export const GoToArrow = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6284 14.7459C12.423 14.7459 12.2176 14.6702 12.0554 14.508C11.7418 14.1945 11.7418 13.6755 12.0554 13.3619L18.0457 7.3716L12.0554 1.38134C11.7418 1.06776 11.7418 0.548749 12.0554 0.235178C12.3689 -0.0783928 12.888 -0.0783928 13.2015 0.235178L19.7649 6.79853C20.0785 7.1121 20.0785 7.63111 19.7649 7.94468L13.2015 14.508C13.0393 14.6702 12.8339 14.7459 12.6284 14.7459Z"
      fill="#0092FF"
    />
    <path
      d="M19.0089 8.18442H0.81096C0.367635 8.18442 0 7.81678 0 7.37346C0 6.93014 0.367635 6.5625 0.81096 6.5625H19.0089C19.4522 6.5625 19.8199 6.93014 19.8199 7.37346C19.8199 7.81678 19.4522 8.18442 19.0089 8.18442Z"
      fill="#0092FF"
    />
  </svg>
);
