import React, { useEffect } from 'react'
import AuthStore from '../stores/AuthStore'
import { useDispatch } from 'react-redux'

export function authenticateBeforeRendering(Component) {
  return function AuthenticateBeforeRendering (props) {
    const dispatch = useDispatch()
    useEffect(() => {
      AuthStore.getMe(dispatch)
    }, [])
    return (
      <Component {...props} />
    )
  }
}

