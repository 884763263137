import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
  getAccountCustomLinkButtonData,
  updateAccountCustomLinkButtonData,
  resetCustomLinkButtonState,
} from '@nucleus-care/nucleuscare-backend-client';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Message from '../utils/Message';
import NucleusCard from './NucleusCard';
import CareAccountStore from '../stores/CareAccountStore';

import { NucleusControlledWhiteBgInput } from './NucleusControlledInput';

const LinkTab = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useForm();

  // get the link button template
  const templates = CareAccountStore.getAvailableTemplates();
  const linkButtonTemplate = templates.find(
    (template) => template.Name === 'Link Button Template',
  );
  const linkButtomTemplateExampleImageUrl = linkButtonTemplate?.ExampleImageUrl;

  useEffect(() => {
    dispatch(
      getAccountCustomLinkButtonData({
        AccountID: props.AccountID,
      }),
    );

    // reset the state when the component unmounts
    return () => {
      dispatch(resetCustomLinkButtonState());
    };
  }, []);

  const accountCustomLinkButtonSliceData = useSelector((state) => {
    return state.accountCustomLinkButton;
  });

  const [logoUpdated, setLogoUpdated] = useState(false); // whether the logo has been updated
  const [customLinkButtonImage, setCustomLinkButtonImage] = useState(undefined); // stores the image currently uploaded by the user
  const [customLinkButtonImageLocalUrl, setCustomLinkButtonImageLocalUrl] =
    useState(undefined); // stores the local url of the image currently uploaded by the user
  const [missingImageError, setMissingImageError] = useState(undefined); // manual error for missing image

  // when the config state is updated, update the form values
  useEffect(() => {
    if (!accountCustomLinkButtonSliceData?.config) {
      return;
    }
    const config = accountCustomLinkButtonSliceData.config;
    setValue('ButtonText', config.CustomLinkButtonText);
    setValue('ButtonUrl', config.CustomLinkButtonUrl);
    setValue('ButtonImageUrl', config.CustomLinkButtonImageUrl);
  }, [accountCustomLinkButtonSliceData.config]);

  // for when the update action completes
  useEffect(() => {
    if (!accountCustomLinkButtonSliceData?.updateData) {
      return;
    }

    const updateData = accountCustomLinkButtonSliceData.updateData;

    // if the action completed succesfully
    if (updateData.complete) {
      setLogoUpdated(false); // reset the logo updated flag to false
      Message.show('Link Button succesfully updated');
    }

    // if the action failed
    if (updateData.error != '') {
      Message.error('Error updating Link Button');
    }
  }, [accountCustomLinkButtonSliceData.updateData]);

  const selectedLogoRef = useRef(null); // reference to the hidden input component for changing the logo
  const handleLogoChange = (event) => {
    // if more than one file is selected, display an error
    if (event.target.files.length > 1) {
      Message.error('Please select only one file');
      return;
    }

    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      setCustomLinkButtonImage(file);
      setCustomLinkButtonImageLocalUrl(URL.createObjectURL(file));
      setMissingImageError(undefined); // Clear the error (that might have existed) if the user selects a file
      setLogoUpdated(true); // set the logo updated flag
    }
  };

  const handleCustomLinkDataSubmit = () => {
    // TODO - add the image check
    const hasImage = customLinkButtonImage || watch('ButtonImageUrl');
    if (!hasImage) {
      setMissingImageError({
        message: 'Logo image is required',
      });
    }

    const customLinkButtonText = watch('ButtonText');
    const customLinkButtonUrl = watch('ButtonUrl');

    // TODO add customLinkButtonImage check back in
    if (!customLinkButtonText || !customLinkButtonUrl || !hasImage) {
      Message.error('Please fill in all the required fields');
      return;
    }

    const accountCustomLinkButtonRequestPayload = {
      AccountID: props.AccountID,
      CustomLinkButtonText: watch('ButtonText'),
      CustomLinkButtonUrl: watch('ButtonUrl'),
      CustomLinkButtonImage: logoUpdated ? customLinkButtonImage : undefined, // only send the logo if it has been updated
    };

    dispatch(
      updateAccountCustomLinkButtonData(accountCustomLinkButtonRequestPayload),
    );
  };

  return (
    <div
      style={{
        width: '25%',
        display: 'flex',
        marginBottom: 30,
      }}
    >
      <NucleusCard
        style={{
          minWidth: 300,
          paddingBottom: 20,
          paddingTop: 20,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleSubmit(handleCustomLinkDataSubmit)}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <label
              style={{
                fontWeight: 600, // bold text
                fontStyle: 'italic',
                minWidth: 250,
                textAlign: 'center',
                fontSize: 15,
                color: '#0A313F',
              }}
            >
              {'Set Link Button Template Details'}
            </label>

            <div style={{ height: 15 }} />

            {/* Surrounding whitebox for image upload */}
            <div
              style={{
                backgroundColor: 'white',
                padding: '10',
                margin: '5',
                border: missingImageError ? '1px solid #FE3824' : 'none', // red border if there is an error
                borderRadius: '5px',
                display: 'flex', // Flexbox
                flexDirection: 'column', // Stack items vertically
                alignItems: 'center', // Center items horizontally
                justifyContent: 'center', // Center items vertically
              }}
            >
              <p>Upload an Image</p>

              {/* box for logo */}
              <div
                style={{
                  width: '200px',
                  height: '100px',
                  backgroundColor:
                    customLinkButtonImageLocalUrl || watch('ButtonImageUrl')
                      ? 'transparent'
                      : '#D3D3D3',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                }}
              >
                {/* If there is a local url display that, otherwise display the url from the form, otherwise display the word 'Logo' */}
                {customLinkButtonImageLocalUrl || watch('ButtonImageUrl') ? (
                  <img
                    src={
                      customLinkButtonImageLocalUrl || watch('ButtonImageUrl')
                    }
                    alt="Selected Logo"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                ) : (
                  'Logo'
                )}
              </div>

              <p style={{ fontSize: '12px' }}>
                Minimum Resolution of 500 x 250
              </p>

              {/* Button to trigger click of hidden input element to upload logo */}
              <button
                onClick={() => selectedLogoRef.current.click()}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#0292ff',
                  cursor: 'pointer',
                }}
                type="button"
              >
                Change Logo
              </button>

              {/* Hidden input for changing logo that gets triggered by the button click */}
              <input
                id="logoFile"
                ref={selectedLogoRef}
                type="file"
                style={{ display: 'none' }}
                onChange={handleLogoChange}
                accept={'image/png, image/jpeg'}
              />
            </div>

            {/* Error message for missing image */}
            {missingImageError ? (
              <p
                style={{
                  color: '#FE3824',
                  fontWeight: 'bold',
                  marginTop: 5,
                  textAlign: 'end',
                }}
              >
                {missingImageError.message}
              </p>
            ) : (
              <div style={{ height: '30px' }} /> // Placeholder separator
            )}

            {/* Input for button text */}

            <NucleusControlledWhiteBgInput
              label={'Button Text: '}
              name={'ButtonText'}
              register={register('ButtonText', {
                required: 'Text Field is required',
              })}
              error={errors.ButtonText}
              clearErrors={clearErrors}
              containerStyle={{
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            />

            {/* separator */}
            <div style={{ height: 15 }} />

            {/* Input for button url */}
            <NucleusControlledWhiteBgInput
              // disabled={showSpinner}
              mainContainerStyle={{ width: '100%' }}
              label={'Button URL:'}
              name={'ButtonUrl'}
              register={register('ButtonUrl', {
                required: 'Button URL is required',
              })}
              error={errors.ButtonUrl}
              clearErrors={clearErrors}
              containerStyle={{
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            />

            {/* separator */}
            <div style={{ height: 15 }} />

            {/* Save button */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <button
                style={{
                  backgroundColor: 'transparent',
                  border: '2px solid #0092ff',
                  borderRadius: '20px',
                  color: '#0092ff',
                  fontSize: '16px',
                  padding: '7px 60px',
                  cursor: 'pointer',
                  outline: 'none',
                }}
                type="submit"
                // onClick not needed since the form triggers a submit handler when submit elements are clicked
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </NucleusCard>
      {/* add component showing the example image */}
      <img
        src={linkButtomTemplateExampleImageUrl}
        style={{
          marginLeft: '30px',
          width: '600px',
          height: '300px',
        }}
      />
    </div>
  );
};

export default LinkTab;
