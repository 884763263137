import { useEffect } from 'react';

import {
	setLocalEnv,
	setDevEnv,
	setTestEnv,
	setProdEnv
} from '@nucleus-care/nucleuscare-backend-client';
import {
	useDispatch
} from 'react-redux';

import api from './httpLegacyClient'

const BackendClientLauncher = () => {

	const dispatch = useDispatch();

	useEffect(() => {
		const environment = process.env.REACT_APP_ENVIRONMENT;
		console.log("BackendClientLauncher environment ", environment);

		switch (environment) {
			case "local":
				api.defaults.baseURL = 'https://care-dev.nucleuslife.io/ws'
				dispatch(setLocalEnv());
				break;
			case "development":
				api.defaults.baseURL = 'https://care-dev.nucleuslife.io/ws'
				dispatch(setDevEnv());
				break;
			case "test":
				api.defaults.baseURL = 'https://care-test.nucleuslife.io/ws'
				dispatch(setTestEnv());
				break;
			default:
				api.defaults.baseURL = 'https://care.nucleuslife.io/ws'
				dispatch(setProdEnv())
				break;
		}


	}, []);

	return (
		null
	);

}


export default BackendClientLauncher;
