import React, { useEffect, useState } from "react";
import NucleusInput from "../NucleusInput";
import { useDispatch, useSelector } from "react-redux";
import {
  addPolicyInformationProperties,
  addPolicyJSONProperties,
  removePolicyInformationProperty,
  removePolicyJSONProperty,
} from '@nucleus-care/nucleuscare-backend-client';

const MDMNucleusLabelInput = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  error,
  onFocus,
  activeBadge,
  onClickClear,
  setPropertyValue,
  fromPolicyInformation,
  disabled,
  style,
  className,
  label1 = "",
  label2 = "",
  hideClear,
  ...props
}) => {
  const { policyJSON, policyInformation, policyFormStatus } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.state
  );
  const dispatch = useDispatch();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [disableInputs, setDisableInputs] = useState(false);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    if (fromPolicyInformation) {
      if ([name] in policyInformation) {
        setInputValue(policyInformation[name]);
      }
      return;
    }
    if ([name] in policyJSON) setInputValue(policyJSON[name]);
  }, [policyJSON, policyInformation]);
  const onFocusAction = () => {
    if (onFocus) return onFocus();
    setIsInputFocused(true);
  };
  useEffect(() => {
    if (!inputValue || inputValue === "") {
      setInputValue("");
      if (fromPolicyInformation) {
        removePolicyInformationProperty(name);
        setInputValue("");
        return;
      }
      setInputValue("");
      dispatch(removePolicyJSONProperty(name));
    }
  }, [inputValue]);
  useEffect(()=>{
    if(disabled || !policyFormStatus?.isEditable){
      setDisableInputs(true)
    }else {
      setDisableInputs(false)
    }
  },[policyFormStatus, disabled])
  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          display: "flex",
        }}
      >
        {"maximumTimeToLock" in policyJSON && (
          <span
            style={{
              position: "absolute",
              left: -4,
              top: -4,
              // top: "-10px",
              width: 0,
              height: 0,
              borderLeft: "10px solid #00C444",
              borderBottom: "10px solid transparent",
              // borderRight: "10px solid transparent",
              borderRight: "10px solid transparent",
            }}
          ></span>
        )}
      </div>
      <label className="mdmText">{label1}</label>
      <div className="counterContainer" style={{ minWidth: 120 }}>
        <input
          onFocus={() => {
            if (fromPolicyInformation) {
              dispatch(
                addPolicyInformationProperties({ [name]: inputValue || "" })
              );
            } else {
              dispatch(addPolicyJSONProperties({ [name]: inputValue || "" }));
            }
          }}
          onChange={(e) => {
            dispatch(
              addPolicyJSONProperties({
                maximumTimeToLock: e.target.value,
              })
            );
          }}
          value={inputValue}
          className={className ? className : "text-center-align"}
          type="number"
          name="maximumTimeToLock"
          disabled={disableInputs}
        />
      </div>
      <label className="mdmText">{label2}</label>
      <div
        style={{
          display: (disableInputs || hideClear) ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="nucleus-ml-2"
      >
        <a
          onClick={() => {
            if (fromPolicyInformation) {
              removePolicyInformationProperty(name);
              setInputValue("");
              return;
            }
            setInputValue("");
            dispatch(removePolicyJSONProperty(name));
          }}
          role="button"
          style={{ cursor: "pointer" }}
        >
          Clear
        </a>
      </div>
    </React.Fragment>
  );
};

export default MDMNucleusLabelInput;
