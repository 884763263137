import Config from "../Config";
import NucleusNetwork from "../utils/NucleusNetwork";
import api from '../utils/httpLegacyClient'

class PatientApi {

	constructor(context) {
		this.context = context;
	}

	//<----------------------------------Get All Patients Data--------------------------->
	//<-------------------------------------  DEPRECATED -------------------------------->
	getAllPatientData (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/GetPatients",
			data : data,
			callback : callback,
		});
	}

	AssignPatientMember(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "FamilyMember.asmx/AddToPatient",
		  data: data,
		  callback: callback,
		});
	  }

	//<----------------------------Get All Patients Data By User------------------------->
	getAllPatientDataForUser (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/GetPatientsForUser",
			data : data,
			callback : callback,
		});
	}

	
	//<----------------------------------Get User Data-------------------------------->
	GetCarePatientData (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/GetPatientData",
			data : data,
			callback : callback,
		});
	}

	GetCarePatientProfileData (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/GetPatientProfileData",
			data : data,
			callback : callback,
		});
	}

	//<----------------------------------Update Patient Data----------------------------->
	UpdateCarePatientData (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/UpdatePatient",
			data : data,
			callback : callback,
		});
	}

	UploadCarePatientImage(data, callback) {
		console.log("CS:UploadCarePatientImage", data);
		const body = new FormData()
		body.append('PatientID', data.PatientID)
		body.append('File', data.File)
		api({
		  url: '/CarePatient.asmx/UploadPicture',
		  method: 'post',
		  data: body
		})
		.then((response) => {
			callback(response.data)
		})
		.catch((e) => {
			console.log("Error on CarePatient.asmx/UploadPicture", e);
			callback({
				ok:false,
				...e
			})
		})
	}
	

	//<----------------------------------Create New Patient--------------------------->
	CreateNewPatient (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/AddPatient",
			data : data,
			callback : callback,
		});
	}

	//<---------------------------------Delete Patient--------------------------------->
	// DEPRECATED: Moved to node
	DeletePatient (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/DeletePatient",
			data : data,
			callback : callback,
		});
	}

	CheckExistingTelephone(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "FamilyMember.asmx/IsAlreadyExists",
		  data: data,
		  callback: callback,
		});
	  }

	//<-----------------------Get Available Devices for Patient----------------------->
	GetCareDevices (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareDevice.asmx/GetDevices",
			data : data,
			callback : callback,
		});
	}


	//<-------------------------- Update Device Name ------------------------->
	UpdateDeviceName (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareDevice.asmx/UpdateDeviceName",
			data : data,
			callback : callback,
		});
	}

	
	//<-----------------------Unassign Device To Patient ----------------------->
	UnassignDevicePatient (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareDevice.asmx/UnassignPatientDevice",
			data : data,
			callback : callback,
		});
	}
	//<-----------------------Request Reboot Device ----------------------->
	RequestRebootDevice (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareDevice.asmx/RebootDevice",
			data : data,
			callback : callback,
		});
	}

	//<-------------------------Report CareCall Started ----------------------->
	ReportCareCallStarted (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCall.asmx/ReportCallStarted",
			data : data,
			callback : callback,
		});
	}

	//<-------------------------Report CareCall Ended ----------------------->
	ReportCareCallEnded (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCall.asmx/ReportCallEnded",
			data : data,
			callback : callback,
		});
	}

	GetLocalesData(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CareCS.asmx/GetLocales",
		  data: data,
		  callback: callback,
		});
	  }

	GetPatientRequestLogs(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/GetPatientRequestCallLogs",
		  data: data,
		  callback: callback,
		});
	  }

	//<---------------------------Get Call Log Notes --------------------------->
	GetCallLogNotes (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCall.asmx/GetCallLogNotes",
			data : data,
			callback : callback,
		});
	}


	//<-----------------------Update Call Log Notes --------------------------->
	UpdateCallLogNotes (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCall.asmx/UpdateCallLogNotes",
			data : data,
			callback : callback,
		});
	}


	//<-----------------------Get Patient Assignments --------------------------->
	GetPatientAssignments (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/ListAssignments",
			data : data,
			callback : callback,
		});
	}

	
	//<-----------------------Get Patient FamilyMembers --------------------------->
	GetPatientFamilyMembers (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "FamilyMember.asmx/List",
			data : data,
			callback : callback,
		});
	}

	//<-----------------------Create New Family Member --------------------------->
	CreateNewFamilyMember (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "FamilyMember.asmx/Add",
			data : data,
			callback : callback,
		});
	}

	//<-------------------------Update Family Member ----------------------------->
	UpdateFamilyMember(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "FamilyMember.asmx/UpdateProfileFromWeb",
		  data: data,
		  callback: callback,
		});
	  }

	//<--------------------------Delete Family Member --------------------------->
	DeleteFamilyMember (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "FamilyMember.asmx/Delete",
			data : data,
			callback : callback,
		});
	}

	//<--------------------------------Request Resend Email----------------------------->
	RequestResendEmail (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "FamilyMember.asmx/ReSendWelcomeEmail",
			data : data,
			callback : callback,
		});
	}


	//<--------------------------Get Patient Notes ------------------------------>
	GetPatientNotes (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/GetPatientNotes",
			data : data,
			callback : callback,
		});
	}
	//<--------------------------Add Patient Note ------------------------------>
	AddPatientNote (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/AddPatientNote",
			data : data,
			callback : callback,
		});
	}

	//<------------------------Update Patient Note ------------------------------>
	UpdatePatientNote (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/UpdatePatientNote",
			data : data,
			callback : callback,
		});
	}


	//<------------------------Delete Patient Note ------------------------------>
	DeletePatientNote (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/DeletePatientNote",
			data : data,
			callback : callback,
		});
	}

	//<-------------------------Send Message to Device -------------------------->
	SendMessageToDevice (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/SendMessageToDevice",
			data : data,
			callback : callback,
		});
	}

	//<-------------------------Get Patient Messages ---------------------------->
	GetPatientMessages (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/GetPatientMessages",
			data : data,
			callback : callback,
		});
	}

	// * * * * * * * * * * * * * * * * * * * * * * * * * 
	// New backend endpoints calls
	// * * * * * * * * * * * * * * * * * * * * * * * * * 
	  UpdatePatientConfigString(data, endpoint, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + endpoint,
		  data: data,
		  callback: callback,
		});
	  }

	  
	  // * * * * * * * * * * * * * * * * * * * * * * * * * 
	  UpdatePatientConfigFlag(data, endpoint, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + endpoint,
		  data: data,
		  callback: callback,
		});
	  }
	

	UpdateShowCallButton(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateShowCallButton",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateShowCallButton2(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateShowCallButton2",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateShowCheckInButton(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateShowCheckInButton",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateShowEmergencyButton(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateShowEmergencyButton",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateShowWeather(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateShowWeather",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateShowCallControls(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateShowCallButtonsBar",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateCallPhoneFromDevice(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateCallPhoneFromDevice",
		  data: data,
		  callback: callback,
		});
	  }

	  UpdateEnableMemoryBox(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateEnableMemoryBox",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateEnableBlankMemoryBox(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateBlankEnableMemoryBox",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateZipCode(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateZipCode",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateEnableBiometrics(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateEnableBiometrics",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateEnableBluetoothButtons(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateEnableBluetoothButtons",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateEnableAutoAnswer(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateEnableAutoAnswer",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateEnableAppIncomingCall(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateEnableAppIncomingCall",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateEnableImpairedRingtone(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateEnableImpairedRingtone",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateFamilyEnableAutoAnswer(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateFamilyEnableAutoAnswer",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateFamilyAdmin(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdateFamilyAdmin",
		  data: data,
		  callback: callback,
		});
	  }
	
	  UpdateBloodPressureMetrics(data, callback) {
		NucleusNetwork.request({
		  context: this.context,
		  type: "POST",
		  url: Config.domain + "CarePatient.asmx/UpdatePatientBloodPressureAlert",
		  data: data,
		  callback: callback,
		});
	  }
	

}


export default PatientApi;