import React from "react";
import NucleusModal from "../../../../components/NucleusModal";
import NucleusButton from "../../../../components/NucleusButton";
import { useSelector } from "react-redux";
import { setPolicyScreen } from '@nucleus-care/nucleuscare-backend-client';

const UnsavedAlert = ({
  loading,
  handeDontSave,
  handleSave,
  setShowUnsavedChangesAlert,
}) => {
  const { policyInformation, policyFormStatus } = useSelector(({ mdmPolicy }) => mdmPolicy.state);
  return (
    <NucleusModal setIsOpen={setShowUnsavedChangesAlert}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          minHeight: 520,
          padding: 20,
        }}
      >
        <div
          style={{
            borderRadius: 15,
            padding: 20,
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <span
            style={{
              fontSize: 60,
              color: "#FE3824",
              fontWeight: "bold",
            }}
          >
            WARNING!
          </span>
        </div>
        <p style={{ fontSize: 25, fontWeight: "bold" }}>
          An attempt to exit “MDM” without first saving any changes was
          detected! <br /> <br /> All data entered will be lost. <br /> <br />{" "}
          Would you like to save?
        </p>
        <div style={{ display: "flex" }}>
          <div className="nucleus-mr-1">
            <NucleusButton
              rounded
              disabled={loading}
              label="Don't Save"
              backGroundColor="#FFFFFF"
              color={"#0A313F"}
              width={155}
              action={() => {
                handeDontSave();
              }}
            />
          </div>
          <div className="nucleus-ml-1">
            <NucleusButton
              label="Save"
              disabled={loading}
              backGroundColor="#0A313F"
              action={() => {
                handleSave();
                if (!policyInformation?.Name) {
                  return;
                }
              }}
            />
          </div>
        </div>
      </div>
    </NucleusModal>
  );
};

export default UnsavedAlert;
