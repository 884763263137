import React, { useState, useEffect, useRef} from 'react';
import ReactDOM from "react-dom";
import Message from '../utils/Message';

import NucleuscareConnectData,
{
	RootState,
	getClassSessionConfig,
	accountSliceClean,
	enableClassSession,
	updateReminders,
	updateConfig
} from '@nucleus-care/nucleuscare-backend-client';

import {
	useSelector,
	useDispatch
	//DefaultRootState 
} from 'react-redux';

import { 
    PasswordWithRules,
	Rules,
	RegexRule,
	LengthRule,
	BlackListRule,
	MustMatchRule 
} from '@nucleus-care/react-native-nucleuscare-connect-security-client'

import Loader from "./Loader";

type FormParams = {
    onSubmitForm: Function;
    userData:Object;
};

const CompleteProfileForm = (props: FormParams) => {
    const dispatch = useDispatch();

    const [stateNewPassword1, setStateNewPassword1] = useState("");
    const [stateNewPassword2, setStateNewPassword2] = useState("");
    
    // const [nucleusNewPasswordRef, setNucleusNewPasswordRef] = useState("");
    // const [nucleusConfirmPasswordRef, setNucleusConfirmPasswordRef] = useState("");
    const nucleusNewPasswordRef = useRef("");
    const nucleusConfirmPasswordRef = useRef("");

    const [stateValidForm, setStateValidForm] = useState(false);
    const [stateValidNewPassword, setStateValidNewPassword] = useState(false);

    const [stateInput1Secret, setStateInput1Secret] =  useState(true);
    const [stateInput2Secret, setStateInput2Secret] =  useState(true);

    const [stateBtnMode, setStateBtnMode] = useState(" nucleus-submit-btn-disabled");
    const [stateLoading, setStateLoading] = useState(false);

    useEffect(() => {
		console.log("PasswordForm useEffect");
		console.log("PasswordForm props", props);
        //setStateLoading(props.updatingUser);
	}, []);

    const validateFieldsFromKeyboard =(e)=>{
        //console.log("validateFieldsFromKeyboard this.validateFields");
        if ((e.charCode == 13) || (e.keyCode == 13) || (e.key == 13) ){
            
            if (stateValidForm){
                console.log("validateFieldsFromKeyboard stateValidForm");
                onSubmit();
            }
        }
    }

    const validateTxtInput1 = (e)=>{
        //console.log("validateTxtInput1", e.target.value);
        //setNucleusNewPasswordRef(e.target.value);
        setStateNewPassword1(e.target.value);
        if (e.target.value == ""){
            let state = {
                isValid : false
            }
            onPasswordHasChanged(state);
        }
    }

    const validateTxtInput2 = (e)=>{
        //console.log("validateTxtInput2", e.target.value);
        //setNucleusConfirmPasswordRef(e.target.value)
        setStateNewPassword2(e.target.value);
        
        if (e.target.value == ""){
            let state = {
                isValid : false
            }
            onPasswordHasChanged(state);
        }
    }

    const onSwitchPassword1View = ()=>{
        setStateInput1Secret(!stateInput1Secret);
    }
    const onSwitchPassword2View = ()=>{
        setStateInput2Secret(!stateInput2Secret);
    }

    const onSubmit = () =>{
        console.log("onSubmit");
        console.log("P1", stateNewPassword1);
        setStateLoading(true);
        props.onSubmitForm(stateNewPassword1);
    }

    const onPasswordHasChanged = (passwordsData)=>{
		console.log("onPasswordHasChanged", passwordsData);
        setStateLoading(false);
        if (passwordsData.isValid != stateValidForm){
            setStateValidForm(passwordsData.isValid);
            if (passwordsData.isValid){
                setStateBtnMode(" nucleus-submit-btn");
            }
            else {
                setStateBtnMode(" nucleus-submit-btn-disabled");
            }
        }
	}

    const renderPasswordForm = (errors: ReactElement) => {
		//console.log("renderPasswordForm errors", errors);
		return(
			<div>

                <div class="row center-align no-margin">
                    <div class="input-field col s1 m3 l3"></div>
                    <div class="input-field col s10 m6 l6" >
                        <div style={{display:'flex', flex:1}}>
                            <input 
                            //id="nucleusNewPasswordRef"
                            ref={nucleusNewPasswordRef} 
                            type={stateInput1Secret ? "password" : "text"}
                            name="nucleus-password" 
                            //value={nucleusNewPasswordRef}
                            class="validate nucleus-input-login2 nucleus-font"
                            placeholder="New password" 
                            onKeyPress={validateFieldsFromKeyboard } 
                            onChange={validateTxtInput1}
                            style={{fontSize:16}} />

                            <img
                                src="img/view_password.png"
                                class="nucleus-link"
                                style={{width:24, height:16, marginLeft:-24, marginTop:8}}
                                onClick={onSwitchPassword1View}
                            />
                        </div>
                    </div>
                </div>
                <div class="input-field row no-margin left-align" style={{width:'120%'}}>
                    
                    <div class="col s12 m12">
                        <p class="login-info txt-white nucleus-font-family-medium" style={{fontSize:14, marginBottom:8, paddingLeft:5}}> Password must: </p>
                    </div>   
                </div>
                <div class="input-field row no-margin no-padding left-align" style={{width:'120%', paddingLeft:'25%'}} >  

                    <div class="col s12 m12 no-padding">
                        <div class="row no-margin no-padding" >  
                        {
                            errors
                        }
                        </div>                   
                    </div> 

                </div>
                <br/>
                <div class="row center-align no-margin">
                    <div class="input-field col s1 m3 l3"></div>
                    <div class="input-field col s10 m6 l6">
                        <div style={{display:'flex', flex:1}}>
                            <input 
                            //id="nucleusConfirmPasswordRef"
                            ref={nucleusConfirmPasswordRef}
                            type={stateInput2Secret ? "password" : "text"}
                            name="nucleus-confirm" 
                            //value={nucleusConfirmPasswordRef}
                            class="validate nucleus-input-login2 nucleus-font"
                            placeholder="Confirm password" 
                            onKeyPress={validateFieldsFromKeyboard } 
                            onChange={validateTxtInput2}
                            style={{fontSize:16}} />
                            
                            <img
                                src="img/view_password.png"
                                class="nucleus-link"
                                style={{width:24, height:16, marginLeft:-24, marginTop:8}}
                                onClick={onSwitchPassword2View}
                                />
                        </div>
                    </div>
                    <div class="input-field col s1 m3 l3"></div>
                </div>

                <br/>
                <div class="row center-align">
                    <div class="col s12">
                    <a 
                        class={"txt-white nucleus-font-small nucleus-font " + stateBtnMode} 
                        style={{fontSize:14}} onClick={onSubmit}>
                            {stateLoading ? 
                                <Loader style={{ top : 5}} /> : 
                                "Submit"
                            }
                    </a>
                    </div>
                </div>
			</div>
		);
	}


    const errorRow = (hasError, label)=>{
        return (
            <div class="col s6 m6 l6" style={{margin:0, padding:0}}>
                <p class="login-info txt-white nucleus-font-small nucleus-font-book" style={{margin:0, lineHeight:1, padding:0}}>
                    <span style={{color:(hasError ? 'white': "#bac5cd"), fontSize:20, fontWeight:'bolder'}}>&nbsp;·&nbsp;</span>
                    {
                        (hasError) && 
                        <span style={{color:'white', fontSize:13.5}}>{label}</span>
                    }
                    {
                        (!hasError) && 
                        <span style={{color:'#bac5cd', fontSize:13.5}} >{label}</span>
                    }
                </p>
            </div>
        )
    }

    return (
        <div>
            <PasswordWithRules
                password={stateNewPassword1}
                confirmationPassword={stateNewPassword2}
                renderContent={renderPasswordForm}
                onChange={onPasswordHasChanged}
                >
                <Rules>
                    <LengthRule
                        min={8}
                        renderError={(hasError, min)=> (errorRow(hasError, "Be at least 8 characters long."))}
                    /> 
                         {/* <BlackListRule
                            list={[props.userData.firstName, props.userData.lastName]}
                            renderError={(hasError) => (errorRow(hasError, "Not contain your username"))}
                        /> */}
                    <RegexRule
                        regex={/([A-Z])/}
                        renderError={(hasError) => (errorRow(hasError, "Contain at least 1 uppercase character."))}
                    />
                        {/* <BlackListRule
                            list={[props.userData.firstName, props.userData.lastName, props.userData.email]}
                            renderError={(hasError) => (errorRow(hasError, "Not contain your contact info"))}
                        /> */}
                    <RegexRule
                        regex={/([a-z])/}
                        renderError={(hasError) => (errorRow(hasError, "Contain at least 1 lowercase character."))}
                    />
                        {/* <BlackListRule
                            list={['12345678']}
                            renderError={(hasError) => (errorRow(hasError, "Not be one that is disallowed"))}
                        /> */}
                        <RegexRule
                            regex={/([0-9])/}
                            renderError={(hasError) => (errorRow(hasError, "Contain at least 1 numeric character."))}
                        />
                        <MustMatchRule
                            value={stateNewPassword2}
                            renderError={(hasError) => (errorRow(hasError, "Password and Confirm Password must match."))}
                        />
                        
                </Rules>
            </PasswordWithRules> 
        </div>
    )

}


export default CompleteProfileForm;