import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import classNames from "classnames";

import Message from "../utils/Message";
import CarePatientStore from "../stores/CarePatientStore";


class CallLogItemRow extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            callLogStatus: '',
            fixedNotesVisibility : ' ',
            collapsibleNotesVisibility : ' hide',
            notesLinkLabel : '... show more',
            visibleNotes : '',
            notes: '',
            shortedNotes: '',


        };

        this.handleCallLogDetails = this.handleCallLogDetails.bind(this);
        //this.handleNotes = this.handleNotes.bind(this);
    }

    componentDidMount()
    {
        window.$('#modal1').modal();

        this.setState({
            callLogStatus : this.props.Status || "", //this.props.WasAnswered ? "Connected" : "Missed",
            callLogStatusColor : this.props.WasAnswered ? "" : "txt-red",
            callStartTime : this.props.DateString + " - " + this.props.TimeString + " - " + this.props.PatientTimeZone,
            callDuration : "",
            participants : this.props.Participants,
            notes : this.props.Notes
        });

        let callTime = "";
        let callTimeSeconds = "";

        // if (this.props.InProgress){

        //     this.setState({
        //         callLogStatus : "In Progress",
        //         callLogStatusColor : ""
        //     });
        // }
        // else

        if (this.props.WasAnswered && (this.props.EndTime && this.props.EndTime > 0)){

            // get total seconds between the times
            let delta = Math.abs(new Date(this.props.EndTime) - new Date(this.props.StartTime)) / 1000;

            // calculate (and subtract) whole days
            let days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            let hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            let minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            let seconds = Math.floor(delta % 60);

            // //callTime = ((new Date(this.props.EndTime - this.props.StartTime).getTime()));
            // callTime = (new Date(this.props.EndTime) - new Date(this.props.StartTime));
            // callTime = (callTime/1000)/60;
            // callTimeSeconds = callTime;
            // // console.log("==============");
            // // console.log("StartTime ", new Date(this.props.StartTime));
            // // console.log("EndTime ", new Date(this.props.EndTime));
            // // console.log("CallTime ", callTimeSeconds);
            // // console.log();
            // callTime = Math.floor(callTime);
            // callTime = callTime + " min ";

            // callTimeSeconds = Math.floor((callTimeSeconds % 1)*60);
            // callTimeSeconds = callTimeSeconds + "s";
            let duration = this.pad2(hours) + ":" + this.pad2(minutes) + ":" + this.pad2(seconds);
            this.setState({
                callDuration : duration
            });
        }

        /*
        let notesLength = 0;
        if (this.props.Notes != null){
            notesLength = this.props.Notes.length;
            console.log("lenght >> ", notesLength);

            this.setState({
                fixedNotesVisibility : ' hide',
                collapsibleNotesVisibility : ' ',
                notesLinkLabel : '... show more',
                shortedNotes: this.props.Notes.substr(0, 75),
                visibleNotes : this.props.Notes.substr(0, 75)
            });
        }
        */
    }

    componentWillUnmount()
    {

    }

    pad2(number) {
        return (number < 10 ? '0' : '') + number;
    }

    handleCallLogDetails(){
        console.log("handleCallLogDetails");

        console.log(this.props.Notes);
        CarePatientStore.getCallLogDetails({
            LogID : this.props.LogID,
            LogStartTime : this.state.callStartTime,
            LogStatus : this.state.callLogStatus,
            LogDuration : this.state.callDuration,
            LogParticipants : this.state.participants,
            LogRequest : {
                ID : this.props.RequestID,
                Type : this.props.RequestType,
                Time : this.props.RequestTime + " - " + this.props.PatientTimeZone,
                Escalated : this.props.RequestEscalated,
            } ,
            Notes : this.props.Notes,
            NotesUserID : this.props.NotesUserID
        });
    }

    /*
    //Function to Make Notes Collapsible
    handleNotes(){
        if (this.state.notesLinkLabel == '... show more'){
            this.setState({
                notesLinkLabel : ' Show less',
                visibleNotes : this.state.notes
            });
        } else {
            this.setState({
                notesLinkLabel : '... show more',
                visibleNotes : this.state.shortedNotes
            });
        }
    }
    */


    render()
    {
        let requestTypeIndicator = this.props.RequestType == 'Emergency' ? '!' : '';
        let participantList = "";

        for (var i = 0; i < this.props.Participants.length; i++){
           //console.log("Participante", this.props.Participants[i]);
           let callerName = this.props.Participants[i].Description;
           if (callerName.includes(this.props.PatientLabel + ":")){
            continue;
           }
           if (this.props.Class.ID){
            //callerName = callerName.replace("Concierge:", "");
            //callerName = callerName.replace(" ", "");
           }
           participantList = participantList + callerName + ", ";
        }
        participantList = participantList.substring(0, participantList.length - 2);

        let callType = "Regular";
        if (this.props.Class.ID){
            callType = "Class Session";
        }
        else if (this.props.RequestID){
            callType = "Requested";
        }

        return (

            <tr  onClick={ this.handleCallLogDetails} >
                <td class="nucleus-row">
                    { this.state.callStartTime}
                </td>
                <td class={"nucleus-row left-align " } >
                    { callType }
                </td>
                <td class={"nucleus-row left-align " + this.state.callLogStatusColor } >
                    { this.state.callLogStatus }
                </td>
                <td class="nucleus-row left-align" >
                    { this.state.callDuration }
                </td>
                <td class="nucleus-row left-align" >
                    { participantList }
                </td>
                <td class="nucleus-row center-align" >
                    <span class={"txt-red nucleus-font-family-big"}><b>{ requestTypeIndicator }</b></span>
                    {
                        /*

                        <span class={"shortNote " + this.state.fixedNotesVisibility }>{ this.state.notes }</span>

                        <span class={"longNote " + this.state.collapsibleNotesVisibility}><b>Notes:</b> { this.state.visibleNotes }
                        <a class="nucleus-link nucleus-font-small" onClick={ this.handleNotes}>{this.state.notesLinkLabel}</a>
                        </span>
                        */
                    }
                </td>
                <td class="nucleus-row right-align padding-b0">
                    {/*
                    <a class="nucleus-link nucleus-font-14 row-action" onClick={ this.handleCallLogDetails}><b>Edit&nbsp;&nbsp;&nbsp;</b></a>
                    */}
                    {
                        this.props.HasNotes ?
                            (
                                <span class="no-margin"> <i class="material-icons txt-green"> done </i> &nbsp;&nbsp;</span>
                            ) : <a/>

                    }

                </td>
            </tr>


        );
    }
}

export default CallLogItemRow;
