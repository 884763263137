import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import AuthStore from "../stores/AuthStore";
import CareAccountStore from "../stores/CareAccountStore";
import GeneralStore from "../stores/GeneralStore";

import AccountScheduleItemRow from "../components/AccountScheduleItemRow";

import Message from "../utils/Message";


import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
	KeyboardDatePicker,
    KeyboardTimePicker,
	MuiPickersUtilsProvider
} from "@material-ui/pickers";

class ScheduleView extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            accountScheduleArray : [],
            accountScheduleModified : (GeneralStore.getDefaultScheduleModified().toString() == "true"),
            accountScheduleUpdateDefaultStyle : 'disabled', // (GeneralStore.getDefaultScheduleModified().toString() == "true") ? '' : 'disabled',

            newScheduleButtonsStyle : '',

            selectedScheduleID : '',
            editScheduleButtonsStyle : '',

            selectedScheduleData : '',
            deleteScheduleBtnStyle : 'nucleus-submit-btn',

            newScheduleItemError : ' hide',
            editScheduleItemError : ' hide',

            updateDefaultScheduleBtnStyle : 'nucleus-submit-btn',

            newScheduleFromTime : null,
            newScheduleFromTimeValue : "",

            newScheduleToTime : null,
            newScheduleToTimeValue : "",

            editScheduleFromTime : null,
            editScheduleFromTimeValue : "",

            editScheduleToTime : null,
            editScheduleToTimeValue : "",
        };

        this.onGetAccountScheduleAction = this.onGetAccountScheduleAction.bind(this);

        this.handleNewScheduleItem = this.handleNewScheduleItem.bind(this);
        this.handleCloseNewScheduleModal = this.handleCloseNewScheduleModal.bind(this);
        this.handleSaveNewSchedule = this.handleSaveNewSchedule.bind(this);
        this.onNewAccountScheduleAddedAction = this.onNewAccountScheduleAddedAction.bind(this);

        this.onOpenEditScheduleModalAction = this.onOpenEditScheduleModalAction.bind(this);
        this.handleCloseEditScheduleModal = this.handleCloseEditScheduleModal.bind(this);
        this.handleSaveEditSchedule = this.handleSaveEditSchedule.bind(this);
        this.onAccountScheduleEditedAction = this.onAccountScheduleEditedAction.bind(this);

        this.onOpenRemoveScheduleModalAction = this.onOpenRemoveScheduleModalAction.bind(this);
        this.handleCancelDeleteSchedule = this.handleCancelDeleteSchedule.bind(this);
        this.handleDeleteSchedule = this.handleDeleteSchedule.bind(this);
        this.onAccountScheduleRemovedAction = this.onAccountScheduleRemovedAction.bind(this);

        this.handleUpdateDefaultSchedule = this.handleUpdateDefaultSchedule.bind(this);
        this.handleCancelUpdateDefaultSchedule = this.handleCancelUpdateDefaultSchedule.bind(this);
        this.handleDefaultScheduleOnlyNewPatients = this.handleDefaultScheduleOnlyNewPatients.bind(this);
        this.handleDefaultScheduleToAllPatients = this.handleDefaultScheduleToAllPatients.bind(this);
        this.onDefaultScheduleAppliedAction = this.onDefaultScheduleAppliedAction.bind(this);

        this.handleChangeScheduleTimezone = this.handleChangeScheduleTimezone.bind(this);
        this.onScheduleTimezoneUpdatedAction = this.onScheduleTimezoneUpdatedAction.bind(this);

        this.timeOptions = { hour12 : true, hour: "2-digit", minute: "2-digit" };
    }

    componentDidMount(){
        //Register Listener

        CareAccountStore.on("onGetAccountSchedule", this.onGetAccountScheduleAction);
        CareAccountStore.on("onNewAccountScheduleAdded", this.onNewAccountScheduleAddedAction);

        CareAccountStore.on("onOpenEditScheduleModal", this.onOpenEditScheduleModalAction);
        CareAccountStore.on("onAccountScheduleEdited", this.onAccountScheduleEditedAction);

        CareAccountStore.on("onOpenRemoveScheduleModal", this.onOpenRemoveScheduleModalAction);
        CareAccountStore.on("onAccountScheduleRemoved", this.onAccountScheduleRemovedAction);

        CareAccountStore.on("onDefaultScheduleApplied", this.onDefaultScheduleAppliedAction);

        CareAccountStore.on("onScheduleTimezoneUpdated", this.onScheduleTimezoneUpdatedAction);


        CareAccountStore.getAccountSchedule({
            UserID : AuthStore.getCsUserID(),
            Token : AuthStore.getCsUserToken(),
            AccountID : this.props.accountId
        });

        // window.$('#newScheduleFromTime').datetimepicker({
        //     datepicker:false,
        //     format:'h:i A',
        //     formatTime:'h:i A',
        //     step:30,
        //     timepickerScrollbar : false,
        //     scrollInput: true
        // }).on("change", (e) => {

        //     if ( this.refs.newScheduleToTime.value != ""){

        //         let dateOk = true;
        //         //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleToTime.value);

        //         let fromDateTime = new Date(Date.parse('2018/07/19 ' + e.target.value));
        //         let toDateTime = new Date(Date.parse('2018/07/19 ' + this.refs.newScheduleToTime.value));

        //         // let toDateTime = new Date('1970-01-01T' + this.props.To);
        //         // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
        //         if (fromDateTime > toDateTime){
        //             dateOk = false;
        //         }
        //         //console.log("Las Fechas estan : ", dateOk);
        //         if (!dateOk){
        //             this.setState({
        //                 newScheduleItemError : '',
        //                 newScheduleButtonsStyle : '-disabled'
        //             });
        //         } else {
        //             this.setState({
        //                 newScheduleItemError : ' hide',
        //                 newScheduleButtonsStyle : ''
        //             });
        //         }
        //     }
        //     return true;
        // });

        // window.$('#newScheduleToTime').datetimepicker({
        //     datepicker:false,
        //     format:'h:i A',
        //     formatTime:'h:i A',
        //     step:30,
        //     timepickerScrollbar : false,
        //     scrollInput: true
        // }).on("change", (e) => {

        //     if ( this.refs.newScheduleFromTime.value != ""){

        //         let dateOk = true;
        //         //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleFromTime.value);

        //         let fromDateTime = new Date(Date.parse('2018/07/19 ' + this.refs.newScheduleFromTime.value));
        //         let toDateTime = new Date(Date.parse('2018/07/19 ' +  e.target.value));

        //         // let toDateTime = new Date('1970-01-01T' + this.props.To);
        //         // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
        //         if (fromDateTime > toDateTime){
        //             dateOk = false;
        //         }
        //         //console.log("Las Fechas estan : ", dateOk);
        //         if (!dateOk){
        //             this.setState({
        //                 newScheduleItemError : '',
        //                 newScheduleButtonsStyle : '-disabled'
        //             });
        //         } else {
        //             this.setState({
        //                 newScheduleItemError : ' hide',
        //                 newScheduleButtonsStyle : ''
        //             });
        //         }
        //     }

        //     return true;
        // });



        // window.$('#editScheduleFromTime').datetimepicker({
        //     datepicker:false,
        //     format:'h:i A',
        //     formatTime:'h:i A',
        //     step:30,
        //     timepickerScrollbar : false,
        //     scrollInput: true
        // }).on("change", (e) => {

        //     if ( this.refs.editScheduleToTime.value != ""){

        //         let dateOk = true;
        //         //console.log("Date changed: scheduleTime ", e.target.value, this.refs.editScheduleToTime.value);

        //         let fromDateTime = new Date(Date.parse('2018/07/19 ' + e.target.value));
        //         let toDateTime = new Date(Date.parse('2018/07/19 ' + this.refs.editScheduleToTime.value));

        //         // let toDateTime = new Date('1970-01-01T' + this.props.To);
        //         // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
        //         if (fromDateTime > toDateTime){
        //             dateOk = false;
        //         }
        //         //console.log("Las Fechas estan : ", dateOk);
        //         if (!dateOk){
        //             this.setState({
        //                 editScheduleItemError : '',
        //                 editScheduleButtonsStyle : '-disabled'
        //             });
        //         } else {
        //             this.setState({
        //                 editScheduleItemError : ' hide',
        //                 editScheduleButtonsStyle : ''
        //             });
        //         }
        //     }
        //     return true;
        // });

        // window.$('#editScheduleToTime').datetimepicker({
        //     datepicker:false,
        //     format:'h:i A',
        //     formatTime:'h:i A',
        //     step:30,
        //     timepickerScrollbar : false,
        //     scrollInput: true
        // }).on("change", (e) => {

        //     if ( this.refs.editScheduleFromTime.value != ""){

        //         let dateOk = true;
        //         //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleFromTime.value);

        //         let fromDateTime = new Date(Date.parse('2018/07/19 ' + this.refs.editScheduleFromTime.value));
        //         let toDateTime = new Date(Date.parse('2018/07/19 ' +  e.target.value));

        //         // let toDateTime = new Date('1970-01-01T' + this.props.To);
        //         // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
        //         if (fromDateTime > toDateTime){
        //             dateOk = false;
        //         }
        //         //console.log("Las Fechas estan : ", dateOk);
        //         if (!dateOk){
        //             this.setState({
        //                 editScheduleItemError : '',
        //                 editScheduleButtonsStyle : '-disabled'
        //             });
        //         } else {
        //             this.setState({
        //                 editScheduleItemError : ' hide',
        //                 editScheduleButtonsStyle : ''
        //             });
        //         }
        //     }

        //     return true;
        // });
    }

    componentWillUnmount(){
        //Remove Listener
        CareAccountStore.removeListener("onGetAccountSchedule", this.onGetAccountScheduleAction);
        CareAccountStore.removeListener("onNewAccountScheduleAdded", this.onNewAccountScheduleAddedAction);
        CareAccountStore.removeListener("onOpenEditScheduleModal", this.onOpenEditScheduleModalAction);
        CareAccountStore.removeListener("onAccountScheduleEdited", this.onAccountScheduleEditedAction);

        CareAccountStore.removeListener("onOpenRemoveScheduleModal", this.onOpenRemoveScheduleModalAction);
        CareAccountStore.removeListener("onAccountScheduleRemoved", this.onAccountScheduleRemovedAction);

        CareAccountStore.removeListener("onDefaultScheduleApplied", this.onDefaultScheduleAppliedAction);

        CareAccountStore.removeListener("onScheduleTimezoneUpdated", this.onScheduleTimezoneUpdatedAction);
    }


    onGetAccountScheduleAction(response){
        console.log("onGetAccountScheduleAction", response);

        this.setState({
            accountScheduleArray : []
        });

        if (response.ok){
            this.setState({
                accountScheduleArray : response.Schedule,
                //accountScheduleUpdateDefaultStyle : (GeneralStore.getDefaultScheduleModified().toString() == "true") ? '' : 'disabled',
                accountScheduleUpdateDefaultStyle : (response.Schedule.length > 0) ? '' : 'disabled'
            });

            if (response.ScheduleTimezone != null  && response.ScheduleTimezone != ''){
                setTimeout(()=>{
                    this.refs.selectScheduleTimezone.value = response.ScheduleTimezone;
                }, 90);
            } else {
                this.refs.selectScheduleTimezone.value = "";
            }

            //this.buttonsInfoReloaded = this.buttonsInfoReloaded + 1;
            if (false){
                Message.show("Account schedule reloaded.");
            }
        } else {
            Message.show("Error getting account schedule, please try again.");
        }
    }

    handleNewScheduleItem(){

        this.setState({
            newScheduleButtonsStyle : '',
            newScheduleItemError : ' hide',

            newScheduleFromTime : null,
            newScheduleFromTimeValue : "",

            newScheduleToTime : null,
            newScheduleToTimeValue : ""
        });

        window.$('#modalNewAccountSchedule').modal('open');

        this.refs.selectNewScheduleDay.value = "0";
        //this.refs.newScheduleFromTime.value = "";
        //this.refs.newScheduleToTime.value = "";
    }

    handleCloseNewScheduleModal(){

        if (this.state.newScheduleButtonsStyle == '-disabled'){
            return;
        }
        window.$('#modalNewAccountSchedule').modal('close');
    }

    onNewScheduleFromTimeChanged=(time)=>{
        console.log("onNewScheduleFromTimeChanged", time);
        let timeString = moment(time).format("hh:mm A");
        this.setState({
            newScheduleFromTime : time,
            newScheduleFromTimeValue : timeString
        });
        if ( this.state.newScheduleToTimeValue != ""){

            let dateOk = true;
            //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleToTime.value);

            let fromDateTime = new Date(Date.parse('2018/07/19 ' + timeString));
            let toDateTime = new Date(Date.parse('2018/07/19 ' + this.state.newScheduleToTimeValue));

            // let toDateTime = new Date('1970-01-01T' + this.props.To);
            // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
            if (fromDateTime > toDateTime){
                dateOk = false;
            }
            //console.log("Las Fechas estan : ", dateOk);
            if (!dateOk){
                this.setState({
                    newScheduleItemError : '',
                    newScheduleButtonsStyle : '-disabled'
                });
            } else {
                this.setState({
                    newScheduleItemError : ' hide',
                    newScheduleButtonsStyle : ''
                });
            }
        }
    }

    onNewScheduleToTimeChanged=(time)=>{
        console.log("onNewScheduleToTimeChanged", time);
        let timeString = moment(time).format("hh:mm A");
        this.setState({
            newScheduleToTime : time,
            newScheduleToTimeValue : timeString
        });
        if ( this.state.newScheduleFromTimeValue != ""){

            let dateOk = true;
            //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleFromTime.value);

            let fromDateTime = new Date(Date.parse('2018/07/19 ' + this.state.newScheduleFromTimeValue));
            let toDateTime = new Date(Date.parse('2018/07/19 ' +  timeString));

            // let toDateTime = new Date('1970-01-01T' + this.props.To);
            // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
            if (fromDateTime > toDateTime){
                dateOk = false;
            }
            //console.log("Las Fechas estan : ", dateOk);
            if (!dateOk){
                this.setState({
                    newScheduleItemError : '',
                    newScheduleButtonsStyle : '-disabled'
                });
            } else {
                this.setState({
                    newScheduleItemError : ' hide',
                    newScheduleButtonsStyle : ''
                });
            }
        }
    }


    onEditScheduleFromTimeChanged=(time)=>{
        console.log("onEditScheduleFromTimeChanged", time);
        let timeString = moment(time).format("hh:mm A");
        this.setState({
            editScheduleFromTime : time,
            editScheduleFromTimeValue : timeString
        });
        if ( this.state.editScheduleToTimeValue != ""){

            let dateOk = true;
            //console.log("Date changed: scheduleTime ", e.target.value, this.refs.editScheduleToTime.value);

            let fromDateTime = new Date(Date.parse('2018/07/19 ' + timeString));
            let toDateTime = new Date(Date.parse('2018/07/19 ' + this.state.editScheduleToTimeValue));

            // let toDateTime = new Date('1970-01-01T' + this.props.To);
            // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
            if (fromDateTime > toDateTime){
                dateOk = false;
            }
            //console.log("Las Fechas estan : ", dateOk);
            if (!dateOk){
                this.setState({
                    editScheduleItemError : '',
                    editScheduleButtonsStyle : '-disabled'
                });
            } else {
                this.setState({
                    editScheduleItemError : ' hide',
                    editScheduleButtonsStyle : ''
                });
            }
        }
    }

    onEditScheduleToTimeChanged=(time)=>{
        console.log("onEditScheduleToTimeChanged", time);
        let timeString = moment(time).format("hh:mm A");
        this.setState({
            editScheduleToTime : time,
            editScheduleToTimeValue : timeString
        });

        if ( this.state.editScheduleFromTimeValue != ""){

            let dateOk = true;
            //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleFromTime.value);

            let fromDateTime = new Date(Date.parse('2018/07/19 ' + this.state.editScheduleFromTimeValue));
            let toDateTime = new Date(Date.parse('2018/07/19 ' +  timeString));

            // let toDateTime = new Date('1970-01-01T' + this.props.To);
            // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
            if (fromDateTime > toDateTime){
                dateOk = false;
            }
            //console.log("Las Fechas estan : ", dateOk);
            if (!dateOk){
                this.setState({
                    editScheduleItemError : '',
                    editScheduleButtonsStyle : '-disabled'
                });
            } else {
                this.setState({
                    editScheduleItemError : ' hide',
                    editScheduleButtonsStyle : ''
                });
            }
        }
    }

    handleSaveNewSchedule(){

        if (this.state.newScheduleFromTimeValue == ''){
            Message.show("Please select From Time");
            //this.newScheduleFromTimeRef.current.focus = true;
            return;
        }
        if (this.state.newScheduleToTimeValue == ''){
            Message.show("Please select To Time");
            //this.newScheduleToTimeRef.current.focus();
            return;
        }

        CareAccountStore.addNewSchedule({
            AccountID : this.props.accountId,
            Day : this.refs.selectNewScheduleDay.value,
            From : this.state.newScheduleFromTimeValue,
            To : this.state.newScheduleToTimeValue
        });
        // Action: onNewAccountScheduleAdded
    }

    onNewAccountScheduleAddedAction(response){
        console.log("onNewAccountScheduleAddedAction", response);
        window.$('#modalNewAccountSchedule').modal('close');

        this.setState({
            newScheduleButtonsStyle : ''
        });

        if (response.ok){
            Message.show("Schedule Item added.");

            CareAccountStore.getAccountSchedule({
                UserID : AuthStore.getCsUserID(),
                Token : AuthStore.getCsUserToken(),
                AccountID : this.props.accountId
            });

            GeneralStore.setDefaultScheduleModified(true);
            // this.setState({
            //     accountScheduleUpdateDefaultStyle : '',
            // });

        } else {
            Message.show("Error adding new Schedule Item ");
        }
    }


    // EDIT EXISTING SCHEDULE
    onOpenEditScheduleModalAction(scheduleInfo){
        console.log("onOpenEditScheduleModalAction", scheduleInfo);

        this.refs.selectEditScheduleDay.value = scheduleInfo.ScheduleDay;
        this.state.editScheduleFromTime = new Date(Date.parse('2018/07/19 ' + scheduleInfo.ScheduleFrom));
        this.state.editScheduleToTime = new Date(Date.parse('2018/07/19 ' + scheduleInfo.ScheduleTo));
        this.state.editScheduleFromTimeValue = moment(this.state.editScheduleFromTime).format("hh:mm A");
        this.state.editScheduleToTimeValue = moment(this.state.editScheduleToTime).format("hh:mm A");

        window.$('#modalEditAccountSchedule').modal('open');

        this.setState({
            selectedScheduleID : scheduleInfo.ScheduleID,
            editScheduleItemError : ' hide',
            editScheduleButtonsStyle : ''
        });
    }

    handleCloseEditScheduleModal(){

        if (this.state.editScheduleButtonsStyle == '-disabled'){
            return;
        }
        window.$('#modalEditAccountSchedule').modal('close');
    }

    handleSaveEditSchedule(){
        if (this.state.editScheduleFromTimeValue == ''){
            Message.show("Please select From Time");
            //this.refs.editScheduleFromTime.focus();
            return;
        }
        if (this.state.editScheduleToTimeValue == ''){
            Message.show("Please select To Time");
            //this.refs.editScheduleToTime.focus();
            return;
        }

        this.setState({
            editScheduleButtonsStyle : '-disabled'
        });

        CareAccountStore.editSchedule({
            ID : this.state.selectedScheduleID,
            Day : this.refs.selectEditScheduleDay.value,
            From : this.state.editScheduleFromTimeValue,
            To : this.state.editScheduleToTimeValue
        });
        // Action: onAccountScheduleEdited
    }
    onAccountScheduleEditedAction(response){
        console.log("onAccountScheduleEditedAction", response);

        window.$('#modalEditAccountSchedule').modal('close');

        this.setState({
            editScheduleButtonsStyle : ''
        });

        if (response.ok){
            Message.show("Schedule Item updated.");

            CareAccountStore.getAccountSchedule({
                UserID : AuthStore.getCsUserID(),
                Token : AuthStore.getCsUserToken(),
                AccountID : this.props.accountId
            });

            GeneralStore.setDefaultScheduleModified(true);
            // this.setState({
            //     accountScheduleUpdateDefaultStyle : '',
            // });

        } else {
            Message.show("Error updating Schedule Item ");
        }
    }

    // REMOVE SCHEDULE ITEM
    onOpenRemoveScheduleModalAction(scheduleInfo){
        console.log("onOpenRemoveScheduleModalAction", scheduleInfo);

        window.$('#modalDeleteAccountSchedule').modal('open');

        let dayName = "Sunday";
        switch(scheduleInfo.ScheduleDay){
            case "0":
                dayName = "Sunday";
                break;
            case "1":
                dayName = "Monday";
                break;
            case "2":
                dayName = "Tuesday";
                break;
            case "3":
                dayName = "Wednesday";
                break;
            case "4":
                dayName = "Thursday";
                break;
            case "5":
                dayName = "Friday";
                break;
            case "6":
                dayName = "Saturday";
                break;
        }

        this.setState({
            selectedScheduleID : scheduleInfo.ScheduleID,
            selectedScheduleData : dayName + ", " + scheduleInfo.ScheduleFrom + " - " + scheduleInfo.ScheduleTo
        });
    }

    handleCancelDeleteSchedule(){

        window.$('#modalDeleteAccountSchedule').modal('close');
    }

    handleDeleteSchedule(){

        this.setState({
            deleteScheduleBtnStyle : 'nucleus-submit-btn-disabled'
        });

        CareAccountStore.removeSchedule({
            ID : this.state.selectedScheduleID
        });
    }
    onAccountScheduleRemovedAction(response){
        console.log("onAccountScheduleRemovedAction", response);

        window.$('#modalDeleteAccountSchedule').modal('close');

        this.setState({
            deleteScheduleBtnStyle : 'nucleus-submit-btn'
        });

        if (response.ok){
            Message.show("Schedule Item removed.");

            CareAccountStore.getAccountSchedule({
                UserID : AuthStore.getCsUserID(),
                Token : AuthStore.getCsUserToken(),
                AccountID : this.props.accountId
            });

            GeneralStore.setDefaultScheduleModified(true);
            // this.setState({
            //     accountScheduleUpdateDefaultStyle : '',
            // });

        } else {
            Message.show("Error removing Schedule Item ");
        }
    }

    handleUpdateDefaultSchedule(){
        window.$('#modalUpdateDefaultAccountSchedule').modal('open');
    }

    handleCancelUpdateDefaultSchedule(){
        window.$('#modalUpdateDefaultAccountSchedule').modal('close');
    }

    handleDefaultScheduleOnlyNewPatients(){
        this.setState({
            updateDefaultScheduleBtnStyle : 'nucleus-submit-btn-disabled'
        });

        GeneralStore.setDefaultScheduleModified(false);

        this.setState({
            updateDefaultScheduleBtnStyle : 'nucleus-submit-btn',
            //accountScheduleUpdateDefaultStyle : '',
        });
        window.$('#modalUpdateDefaultAccountSchedule').modal('close');
    }

    handleDefaultScheduleToAllPatients(){

        this.setState({
            updateDefaultScheduleBtnStyle : 'nucleus-submit-btn-disabled'
        });

        CareAccountStore.applyDefaultScheduleToAllPatients({
            AccountID : this.props.accountId
        });
        //Action: onDefaultScheduleApplied
    }
    onDefaultScheduleAppliedAction(response){

        if (response.ok){
            Message.show("Default Schedule applied to All Patients.");

            GeneralStore.setDefaultScheduleModified(false);

            this.setState({
                updateDefaultScheduleBtnStyle : 'nucleus-submit-btn',
                //accountScheduleUpdateDefaultStyle : '',
            });
            window.$('#modalUpdateDefaultAccountSchedule').modal('close');

        } else {
            Message.show("Error applying Default Schedule to All Patients");
        }

    }

    handleChangeScheduleTimezone(){

        CareAccountStore.updateScheduleTimezone({
            AccountID : this.props.accountId,
            Timezone : this.refs.selectScheduleTimezone.value
        });
        //onScheduleTimezoneUpdated
    }
    onScheduleTimezoneUpdatedAction(response){
        console.log("onScheduleTimezoneUpdatedAction", response);

        if (response.ok){
            Message.show("Schedule Timezone updated");

        }else {
            Message.show("Error updating Schedule Timezone");
        }
    }



    render()
    {

        let accountScheduleList = [];
        if (this.state.accountScheduleArray != null){
          this.state.accountScheduleArray.map((schedule, key) => {
            accountScheduleList.push(
                <AccountScheduleItemRow
                    key={key}
                    ID={ schedule.ID}
                    Timezone={ schedule.Timezone}
                    Day={ schedule.DayOfWeek}
                    From={ schedule.FromTime}
                    To={ schedule.ToTime}
                />
              );
          });
        }


        return (
            <div class="border1">
                <div class="row vbottom-align no-margin">
                    <div class="col s5 no-margin top-separated">
                        <span class="nucleus-labels-small span-text-bottom">Schedule Timezone: </span>
                    </div>
                    <div class="col s7 no-margin control-panels-container">
                        <select ref="selectScheduleTimezone" class="browser-default accountSelect100" disabled={this.state.btnOffHoursMessageStyle} onChange={this.handleChangeScheduleTimezone}>
                            <option value="" disabled selected> { "Select a Timezone" } </option>
                            <option value="Morocco Standard Time">(GMT) Casablanca</option>
                            <option value="GMT Standard Time">(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                            <option value="Greenwich Standard Time">(GMT) Monrovia, Reykjavik</option>
                            <option value="W. Europe Standard Time">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                            <option value="Central Europe Standard Time">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                            <option value="Romance Standard Time">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                            <option value="Central European Standard Time">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                            <option value="W. Central Africa Standard Time">(GMT+01:00) West Central Africa</option>
                            <option value="Jordan Standard Time">(GMT+02:00) Amman</option>
                            <option value="GTB Standard Time">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                            <option value="Middle East Standard Time">(GMT+02:00) Beirut</option>
                            <option value="Egypt Standard Time">(GMT+02:00) Cairo</option>
                            <option value="South Africa Standard Time">(GMT+02:00) Harare, Pretoria</option>
                            <option value="FLE Standard Time">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                            <option value="Israel Standard Time">(GMT+02:00) Jerusalem</option>
                            <option value="E. Europe Standard Time">(GMT+02:00) Minsk</option>
                            <option value="Namibia Standard Time">(GMT+02:00) Windhoek</option>
                            <option value="Arabic Standard Time">(GMT+03:00) Baghdad</option>
                            <option value="Arab Standard Time">(GMT+03:00) Kuwait, Riyadh</option>
                            <option value="Russian Standard Time">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                            <option value="E. Africa Standard Time">(GMT+03:00) Nairobi</option>
                            <option value="Georgian Standard Time">(GMT+03:00) Tbilisi</option>
                            <option value="Iran Standard Time">(GMT+03:30) Tehran</option>
                            <option value="Arabian Standard Time">(GMT+04:00) Abu Dhabi, Muscat</option>
                            <option value="Azerbaijan Standard Time">(GMT+04:00) Baku</option>
                            <option value="Mauritius Standard Time">(GMT+04:00) Port Louis</option>
                            <option value="Caucasus Standard Time">(GMT+04:00) Yerevan</option>
                            <option value="Afghanistan Standard Time">(GMT+04:30) Kabul</option>
                            <option value="Ekaterinburg Standard Time">(GMT+05:00) Ekaterinburg</option>
                            <option value="Pakistan Standard Time">(GMT+05:00) Islamabad, Karachi</option>
                            <option value="West Asia Standard Time">(GMT+05:00) Tashkent</option>
                            <option value="India Standard Time">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                            <option value="Sri Lanka Standard Time">(GMT+05:30) Sri Jayawardenepura</option>
                            <option value="Nepal Standard Time">(GMT+05:45) Kathmandu</option>
                            <option value="N. Central Asia Standard Time">(GMT+06:00) Almaty, Novosibirsk</option>
                            <option value="Central Asia Standard Time">(GMT+06:00) Astana, Dhaka</option>
                            <option value="Myanmar Standard Time">(GMT+06:30) Yangon (Rangoon)</option>
                            <option value="SE Asia Standard Time">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                            <option value="North Asia Standard Time">(GMT+07:00) Krasnoyarsk</option>
                            <option value="China Standard Time">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                            <option value="North Asia East Standard Time">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                            <option value="Singapore Standard Time">(GMT+08:00) Kuala Lumpur, Singapore</option>
                            <option value="W. Australia Standard Time">(GMT+08:00) Perth</option>
                            <option value="Taipei Standard Time">(GMT+08:00) Taipei</option>
                            <option value="Tokyo Standard Time">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                            <option value="Korea Standard Time">(GMT+09:00) Seoul</option>
                            <option value="Yakutsk Standard Time">(GMT+09:00) Yakutsk</option>
                            <option value="Cen. Australia Standard Time">(GMT+09:30) Adelaide</option>
                            <option value="AUS Central Standard Time">(GMT+09:30) Darwin</option>
                            <option value="E. Australia Standard Time">(GMT+10:00) Brisbane</option>
                            <option value="AUS Eastern Standard Time">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                            <option value="West Pacific Standard Time">(GMT+10:00) Guam, Port Moresby</option>
                            <option value="Tasmania Standard Time">(GMT+10:00) Hobart</option>
                            <option value="Vladivostok Standard Time">(GMT+10:00) Vladivostok</option>
                            <option value="Central Pacific Standard Time">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                            <option value="New Zealand Standard Time">(GMT+12:00) Auckland, Wellington</option>
                            <option value="Fiji Standard Time">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                            <option value="Tonga Standard Time">(GMT+13:00) Nuku'alofa</option>
                            <option value="Azores Standard Time">(GMT-01:00) Azores</option>
                            <option value="Cape Verde Standard Time">(GMT-01:00) Cape Verde Is.</option>
                            <option value="Mid-Atlantic Standard Time">(GMT-02:00) Mid-Atlantic</option>
                            <option value="E. South America Standard Time">(GMT-03:00) Brasilia</option>
                            <option value="Argentina Standard Time">(GMT-03:00) Buenos Aires</option>
                            <option value="SA Eastern Standard Time">(GMT-03:00) Georgetown</option>
                            <option value="Greenland Standard Time">(GMT-03:00) Greenland</option>
                            <option value="Montevideo Standard Time">(GMT-03:00) Montevideo</option>
                            <option value="Newfoundland Standard Time">(GMT-03:30) Newfoundland</option>
                            <option value="Atlantic Standard Time">(GMT-04:00) Atlantic Time (Canada)</option>
                            <option value="SA Western Standard Time">(GMT-04:00) La Paz</option>
                            <option value="Central Brazilian Standard Time">(GMT-04:00) Manaus</option>
                            <option value="Pacific SA Standard Time">(GMT-04:00) Santiago</option>
                            <option value="Venezuela Standard Time">(GMT-04:30) Caracas</option>
                            <option value="SA Pacific Standard Time">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                            <option value="Eastern Standard Time">(GMT-05:00) Eastern Time (US & Canada)</option>
                            <option value="US Eastern Standard Time">(GMT-05:00) Indiana (East)</option>
                            <option value="Central America Standard Time">(GMT-06:00) Central America</option>
                            <option value="Central Standard Time">(GMT-06:00) Central Time (US & Canada)</option>
                            <option value="Central Standard Time (Mexico)">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                            <option value="Canada Central Standard Time">(GMT-06:00) Saskatchewan</option>
                            <option value="US Mountain Standard Time">(GMT-07:00) Arizona</option>
                            <option value="Mountain Standard Time (Mexico)">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                            <option value="Mountain Standard Time">(GMT-07:00) Mountain Time (US & Canada)</option>
                            <option value="Pacific Standard Time">(GMT-08:00) Pacific Time (US & Canada)</option>
                            <option value="Pacific Standard Time (Mexico)">(GMT-08:00) Tijuana, Baja California</option>
                            <option value="Alaskan Standard Time">(GMT-09:00) Alaska</option>
                            <option value="Hawaiian Standard Time">(GMT-10:00) Hawaii</option>
                            <option value="Samoa Standard Time">(GMT-11:00) Midway Island, Samoa</option>
                            <option value="Dateline Standard Time">(GMT-12:00) International Date Line West</option>

                        </select>
                        &nbsp;&nbsp;&nbsp;
                    </div>
                </div>
                <div class="assignments-table-pending" >
                        <table class="bordered highlight nucleus-table" >
                            <thead>
                                <tr>
                                    <th class="table-col-25"><span class="nucleus-table-header">Day</span></th>
                                    <th class="table-col-25"><span class="nucleus-table-header">From</span></th>
                                    <th class="table-col-25"><span class="nucleus-table-header">To</span></th>
                                    <th class="table-col-25 center-align">
                                        <span class="nucleus-table-header">
                                        {/*
                                        <span class="nucleus-table-icon nucleus-link tooltipped" data-position="top" data-tooltip="Edit">
                                            <i class="Tiny material-icons">edit</i>
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span class="nucleus-table-icon nucleus-link tooltipped" data-position="top" data-tooltip="Remove">
                                            <i class="Tiny material-icons">not_interested</i>
                                        </span>
                                        */}
                                        </span>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {accountScheduleList}
                            </tbody>
                        </table>
                    </div>

                    <div class="fixed-action-btn btn-add-new">
                        <a class={ "btn waves-effect waves-light light-blue darken-4 "  + this.state.accountScheduleUpdateDefaultStyle}  onClick={this.handleUpdateDefaultSchedule} >Apply changes to all Patients</a>
                        &nbsp;&nbsp;&nbsp;
                        <a class="btn-floating btn-large waves-effect waves-light orange tooltipped nucleus-floating-button" data-position="left" data-delay="50" data-tooltip="New Schedule" onClick={this.handleNewScheduleItem}>
                            <i class="material-icons nucleus-floating-icon">add</i>
                        </a>
                        &nbsp;
                    </div>



                <div id="modalNewAccountSchedule" class="modal modalNewAccountSchedule">
                    <div class="modal-content modal-content-delete center-align">
                        <h3 class="nucleus-page-subtitle">Add Schedule Item</h3>
                        <br/><br/><br/>

                        <div class="row center-align">
                            <div class="col s2">
                            </div>
                            <div class="col s3 left-align">
                                <span class="nucleus-labels"><b>Day:</b></span>
                            </div>
                            <div class="col s6 left-align">
                                {/* <input ref="selectNewAccountButtonsType" type="text" class="validate nucleus-search"/><br/> */ }
                                <select ref="selectNewScheduleDay" class="browser-default height30" >
                                    <option value="0" >Sunday</option>
                                    <option value="1">Monday</option>
                                    <option value="2">Tuesday</option>
                                    <option value="3">Wednesday</option>
                                    <option value="4">Thursday</option>
                                    <option value="5">Friday</option>
                                    <option value="6">Saturday</option>
                                </select>
                            </div>
                            <div class="col s1">
                            </div>
                        </div>

                        <div class="row center-align">
                            <div class="col s2">
                            </div>
                            <div class="col s3 left-align">
                                <span class="nucleus-labels"><b>From:</b></span>
                            </div>
                            <div class="col s6 left-align">
                                {/* <input
                                    id="newScheduleFromTime"
                                    ref="newScheduleFromTime"
                                    type="text"
                                    class="validate nucleus-date-picker"
                                    placeholder="Pick a Time"/> */}
                                <div style={{width:'100%', display:'inline-flex', backgroundColor:'white', paddingLeft:10}}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardTimePicker
                                            //variant="dialog"
                                            //ref={this.newScheduleFromTimeRef}
                                            class="browser-default"
                                            value={this.state.newScheduleFromTime}
                                            onChange={date => {
                                                this.onNewScheduleFromTimeChanged(date);
                                            }}
                                            emptyLabel="Pick a Time"
                                            onError={console.log}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div class="col s1">
                            </div>
                        </div>

                        <div class="row center-align">
                            <div class="col s2">
                            </div>
                            <div class="col s3 left-align">
                                <span class="nucleus-labels"><b>To:</b></span>
                            </div>
                            <div class="col s6 left-align">
                                {/* <input
                                    id="newScheduleToTime"
                                    ref="newScheduleToTime"
                                    type="text"
                                    class="validate nucleus-date-picker"
                                    placeholder="Pick a Time"/> */}
                                <div style={{width:'100%', display:'inline-flex', backgroundColor:'white', paddingLeft:10}}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardTimePicker
                                            //variant="dialog"
                                            //ref={this.newScheduleToTimeRef}
                                            class="browser-default"
                                            value={this.state.newScheduleToTime}
                                            onChange={date => {
                                                this.onNewScheduleToTimeChanged(date);
                                            }}
                                            emptyLabel="Pick a Time"
                                            onError={console.log}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div class="col s1">
                            </div>
                        </div>

                        <span class={ "nucleus-form-error center-align " + this.state.newScheduleItemError}>Please select a 'To Time' greater than 'From Time'.</span>

                        <br/><br/>
                        <div class="row ">
                            <div class="col s12 center-align ">
                                <div class="col s6 right-align">
                                    <a class="txt-white nucleus-font-small nucleus-link " onClick={this.handleCloseNewScheduleModal}>Cancel</a>
                                </div>
                                <div class="col s6 left-align">
                                    <a class={"txt-white nucleus-font-small nucleus-submit-btn-small" + this.state.newScheduleButtonsStyle} onClick={this.handleSaveNewSchedule}>Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="modalEditAccountSchedule" class="modal modalEditAccountSchedule">
                    <div class="modal-content modal-content-delete center-align">
                        <h3 class="nucleus-page-subtitle">Edit Schedule Item</h3>
                        <br/><br/><br/>

                        <div class="row center-align">
                            <div class="col s2">
                            </div>
                            <div class="col s3 left-align">
                                <span class="nucleus-labels"><b>Day:</b></span>
                            </div>
                            <div class="col s6 left-align">
                                {/* <input ref="selectNewAccountButtonsType" type="text" class="validate nucleus-search"/><br/> */ }
                                <select ref="selectEditScheduleDay" class="browser-default height30" >
                                    <option value="0" selected>Sunday</option>
                                    <option value="1">Monday</option>
                                    <option value="2">Tuesday</option>
                                    <option value="3">Wednesday</option>
                                    <option value="4">Thursday</option>
                                    <option value="5">Friday</option>
                                    <option value="6">Saturday</option>
                                </select>
                            </div>
                            <div class="col s1">
                            </div>
                        </div>

                        <div class="row center-align">
                            <div class="col s2">
                            </div>
                            <div class="col s3 left-align">
                                <span class="nucleus-labels"><b>From:</b></span>
                            </div>
                            <div class="col s6 left-align">
                                {/* <input
                                    id="editScheduleFromTime"
                                    ref="editScheduleFromTime"
                                    type="text"
                                    class="validate nucleus-date-picker"
                                    placeholder="Pick a Time"/> */}
                                <div style={{width:'100%', display:'inline-flex', backgroundColor:'white', paddingLeft:10}}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardTimePicker
                                            //variant="dialog"
                                            //ref={this.editScheduleFromTimeRef}
                                            class="browser-default"
                                            value={this.state.editScheduleFromTime}
                                            onChange={date => {
                                                this.onEditScheduleFromTimeChanged(date);
                                            }}
                                            emptyLabel="Pick a Time"
                                            onError={console.log}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div class="col s1">
                            </div>
                        </div>

                        <div class="row center-align">
                            <div class="col s2">
                            </div>
                            <div class="col s3 left-align">
                                <span class="nucleus-labels"><b>To:</b></span>
                            </div>
                            <div class="col s6 left-align">
                                {/* <input
                                    id="editScheduleToTime"
                                    ref="editScheduleToTime"
                                    type="text"
                                    class="validate nucleus-date-picker"
                                    placeholder="Pick a Time"/> */}
                                <div style={{width:'100%', display:'inline-flex', backgroundColor:'white', paddingLeft:10}}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardTimePicker
                                            //variant="dialog"
                                            //ref={this.editScheduleToTimeRef}
                                            class="browser-default"
                                            value={this.state.editScheduleToTime}
                                            onChange={date => {
                                                this.onEditScheduleToTimeChanged(date);
                                            }}
                                            emptyLabel="Pick a Time"
                                            onError={console.log}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div class="col s1">
                            </div>
                        </div>

                        <span class={ "nucleus-form-error center-align " + this.state.editScheduleItemError}>Please select a 'To Time' greater than 'From Time'.</span>

                        <br/><br/>
                        <div class="row ">
                            <div class="col s12 center-align ">
                                <div class="col s6 right-align">
                                    <a class="txt-white nucleus-font-small nucleus-link " onClick={this.handleCloseEditScheduleModal}>Cancel</a>
                                </div>
                                <div class="col s6 left-align">
                                    <a class={"txt-white nucleus-font-small nucleus-submit-btn-small" + this.state.editScheduleButtonsStyle} onClick={this.handleSaveEditSchedule}>Save</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modalDeleteAccountSchedule" class="modal modalDeleteAccountSchedule">
                    <div class="modal-content nucleus-modal-wrapper-scrollable2">
                        <img class="responsive-img img-close-modal" src="img/close_modal.png" onClick={this.handleCancelDeleteSchedule}/>
                        <h3 class="nucleus-page-subtitle"> &nbsp;&nbsp;Delete Schedule Item </h3>
                        <br/><br/>
                        <div class="row">
                            <div class="col s12">
                                <span class="nucleus-labels"> Are you sure you want to remove this schedule item from the account? </span>
                                <br/>
                                <span class="nucleus-labels"> &nbsp;&nbsp;&nbsp;&nbsp; · { this.state.selectedScheduleData } </span>
                            </div>
                        </div>
                        <div class="input-field col s12 modalCallLogContent2">
                            <br/>
                            <div class="row no-margin">
                                <div class="col s12 center-align no-margin">
                                    <p></p>
                                    <span class=" modalSeparator"> <label> &nbsp;</label></span>
                                    <a class={"txt-white nucleus-font-small " + this.state.deleteScheduleBtnStyle } onClick={this.handleDeleteSchedule}>Delete</a>
                                    <div>&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modalUpdateDefaultAccountSchedule" class="modal modalUpdateDefaultAccountSchedule">
                    <div class="modal-content nucleus-modal-wrapper-scrollable2">
                        <img class="responsive-img img-close-modal" src="img/close_modal.png" onClick={this.handleCancelUpdateDefaultSchedule}/>
                        <h3 class="nucleus-page-subtitle"> &nbsp;&nbsp;Call Buttons Schedule Update</h3>
                        <br/><br/>
                        <div class="row">
                            <div class="col s12">
                                <span class="nucleus-labels"> You are about to apply changes to the buttons schedule.</span>
                                <br/>
                                <span class="nucleus-labels">Do you want these changes to apply to all patients?</span>
                            </div>
                        </div>
                        <div class="input-field col s12 modalCallLogContent2">
                            <br/>
                            <div class="row no-margin">
                                <div class="col s6 center-align no-margin">
                                    <p></p>
                                    {/*
                                    <a class={"txt-white nucleus-font-medium " + this.state.updateDefaultScheduleBtnStyle } onClick={this.handleDefaultScheduleOnlyNewPatients}>Newly added</a>
                                    */}
                                    <a class="txt-white nucleus-font-medium nucleus-link " onClick={this.handleCancelUpdateDefaultSchedule}>Cancel</a>
                                    <div>&nbsp;</div>
                                </div>
                                <div class="col s6 center-align no-margin">
                                    <p></p>
                                    <a class={"txt-white nucleus-font-medium " + this.state.updateDefaultScheduleBtnStyle } onClick={this.handleDefaultScheduleToAllPatients}>Confirm</a>
                                    <div>&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            );
    }
}

export default ScheduleView;
