import React from "react";
import { connect } from "react-redux";
import AuthStore from "../stores/AuthStore";
import Message from "../utils/Message";
import { withRouter } from "react-router-dom";
import ResetPasswordForm from "../components/ResetPasswordForm";
class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      btnStyle: " nucleus-submit-btn-disabled",
      errorView1: " hide",
      errorText: "",
    };

    this.onResetPasswordAction = this.onResetPasswordAction.bind(this);
    //this.validateFields = this.validateFields.bind(this);
    //this.validateFieldsFromKeyboard = this.validateFieldsFromKeyboard.bind(this);
    //this.validateTxtInput = this.validateTxtInput.bind(this);
  }

  componentDidMount() {
    console.log("Las meras props", this.props);
    const { match, dispatch } = this.props;
    //Register Listener
    AuthStore.on("onResetPasswordAction", this.onResetPasswordAction);

    //AuthStore.justLogout(dispatch)
    console.log("URL PARAMS");
    console.log(match.params.email);
    console.log(match.params.token);
  }

  componentWillUnmount() {
    //Remove Listener
    AuthStore.removeListener(
      "onResetPasswordAction",
      this.onResetPasswordAction
    );
  }

  onResetPasswordAction(response) {
    console.log("onResetPasswordAction");
    console.log(response);

    if (response.ok) {
      Message.show("Password reset complete.\nYou can login now.");
      setTimeout(() => {
        window.location.assign("#/");
      }, 1000);
    } else {
      Message.show("Error on reset your password please, try again.");
      // this.setState({
      //     errorView1 : " ",
      //     errorText : "Error on reset your password please, try again."
      // });
    }
  }

  // validateFieldsFromKeyboard(e){

  //     if ((e.charCode == 13) || (e.keyCode == 13) || (e.key == 13) ){
  //         this.validateFields();
  //     }
  // }

  // validateTxtInput(e){
  //     //console.log(e.target.value);
  //     this.setState({
  //         errorView1 : " hide",
  //     });

  //     if (this.refs.nucleusNewPassword.value.length > 0 && this.refs.nucleusConfirmPassword.value.length > 0){
  //         this.setState({
  //             btnStyle : " nucleus-submit-btn"
  //         });
  //     } else {

  //         this.setState({
  //             btnStyle : " nucleus-submit-btn-disabled"
  //         });
  //     }
  // }

  // validateFields() {

  //     if(this.refs.nucleusNewPassword.value === ""){
  //         Materialize.toast('Hey, you forgot the password!', 3000, 'rounded');
  //         return;
  //     }
  //     if(this.refs.nucleusConfirmPassword.value === ""){
  //         Materialize.toast('Hey, you forgot the password!', 3000, 'rounded');
  //         return;
  //     }
  //     if (this.refs.nucleusNewPassword.value != this.refs.nucleusConfirmPassword.value){
  //         //Materialize.toast('Your passwords do not match', 3000, 'rounded');
  //         this.setState({
  //             errorView1 : " ",
  //             errorText : "Your passwords do not match"
  //         });
  //         return;
  //     }

  //     let newPassword = this.refs.nucleusConfirmPassword.value;

  //     if (newPassword.length < 6) {
  //         this.setState({
  //             errorView1 : " ",
  //             errorText : "Your password must be at least 6 characters"
  //         });
  //         return;
  //     }

  //     if (newPassword.search(/[a-zA-Z]/)< 0) {
  //         this.setState({
  //             errorView1 : " ",
  //             errorText : "Your password must contain at least one letter"
  //         });
  //         return;
  //     }

  //     if (newPassword.search(/\d/) < 0 && newPassword.search(/[\!\@\#\$\%\^\&\*\(\)\_\+]/) < 0){
  //         this.setState({
  //             errorView1 : " ",
  //             errorText : "Your password must contain at least one digit or one special character"
  //         });
  //         return;
  //     }

  //     this.resetPassword();

  // }

  resetPassword = (newPassword) => {
    const { match } = this.props;

    console.log("match.params.email", match.params.email);
    console.log("match.params.token", match.params.token);
    console.log("Password", newPassword);

    AuthStore.resetPassword({
      Email: match.params.email,
      Token: match.params.token,
      Password: newPassword,
    });
  };

  render() {
    let style = {
      height: window.innerHeight,
      background: "#008FFF",
      overflowY: "auto",
    };

    const { match } = this.props;

    return (
      <div class="nucleus-login" style={style}>
        <div class="alto valign-wrapper" style={{ height: 760 }}>
          <div class="row ancho">
            <div class="col s2 m3 l3 border"></div>
            <div class="col s8 m6 l6 border center-align no-padding">
              <img class="responsive-img" src="img/nucleusCareLogo_new.png" />
              <br />
              <br />
              <p class="txt-white nucleus-page-subtitle">Password Reset</p>
              <br />
              <br />
              {/* 
                <div class="login-info login-error nucleus-font-medium nucleus-font ">
                    &nbsp; <span class={this.state.errorView1 }> {this.state.errorText}</span> &nbsp; 
                </div> 
                */}
              <ResetPasswordForm
                onSubmitForm={this.resetPassword}
                userData={{
                  email: AuthStore.getCsUserEmail(),
                }}
              ></ResetPasswordForm>
            </div>
            <div class="col s2 m3 l3 border"></div>
          </div>
        </div>
      </div>
    );
  }
}

function dispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(null, dispatchToProps)(withRouter(ResetPassword));
