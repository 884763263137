import React from 'react';
import ReactDOM from "react-dom";
import CareFamilyStore from "../stores/CareFamilyStore";
import Message from "../utils/Message";
import AuthStore from "../stores/AuthStore";
import Dispatcher from "../Dispatcher";
import moment from 'moment';
import { SpinnerCircular } from 'spinners-react';

import FamilyMemberItemRow from '../components/FamilyMemberItemRow';

class FamilyDetails extends React.Component {
	
	constructor(props) {
        super(props);

        this.state = {
            loading :true,
            familyDetails : {}
        };
        const {match} = this.props;
        this.familyId = match.params.familyId;
        console.log("this.familyId", this.familyId);

        this.onGetFamilyDetailsResponseAction = this.onGetFamilyDetailsResponseAction.bind(this);
        this.generateFamilyInfo = this.generateFamilyInfo.bind(this);
    }

    componentDidMount() {
        CareFamilyStore.getFamilyDetails({
			FamilyMemberID: this.familyId,
			//Token: AuthStore.getCsUserToken(),
			//AccountID: this.state.accountID,
		});
        CareFamilyStore.on("onGetFamilyDetailsResponse", this.onGetFamilyDetailsResponseAction);
    }

    componentWillUnmount(){
    	CareFamilyStore.removeListener("onGetFamilyDetailsResponse", this.onGetFamilyDetailsResponseAction);
    }

    onGetFamilyDetailsResponseAction = function(response) {
        console.log("onGetFamilyDetailsResponseAction", response);

        if (response.ok){
            this.setState({
                familyDetails : response,
                loading:false
            });
        }
    }

    generateFamilyInfo = function() {
        console.log("generateFamilyInfo");
        let data = this.state.familyDetails;

        console.log(data);
        if (!data.Patients || (data.Patients && data.Patients.length == 0)){
            return (
                <div style={{padding:50, textAlign:'center'}}>
                    <span class="nucleus-table-header-medium text-gray"> 
                        {`There are no Patients connected to this Family Member`}
                    </span>
                </div>
            );
        }

        let deviceOnlineIcon = "img/icon_device_active.png";
        let deviceOfflineIcon = "img/icon_device_inactive.png";
        
        let patientsArray = [];
        data.Patients.forEach((patient, index) => {
            let devicesArray = [];

            let devicesNumber = patient.Devices.length;
            patient.Devices.forEach((device, index) => {
                let styleString = ((devicesNumber -1) == index) ? "" : "right-line-separator";
                devicesArray.push(
                    <div key={(100 + index)} class={styleString} style={{ flex:1, flexDirection:'column', display:'inline-block', paddingLeft:10, paddingRight:15, width:174}}>
                        <span class="nucleus-label-book">
                            {device.DeviceMac || ""}
                        </span>
                        <br/>
                        <span class="nucleus-label-book">
                            {device.HexnodeID || ""}
                        </span>
                        <br/>
                        <span class="nucleus-label-book">
                            {device.Name || ""}
                        </span>
                        <br/>
                        <span class="nucleus-label-book">
                            <img class=" " src={(device.Online) ? deviceOnlineIcon : deviceOfflineIcon} />
                        </span>
                        <br/>
                    </div>
                );
            });
            patientsArray.push(
                <div key={index} class="bottom-line" style={{paddingTop:10, paddingBottom:10, flexDirection:'column'}}>
                    
                    <div class="row" style={{marginBottom:2}}>
                        <div class="col s2 m2 l2" style={{paddingLeft:0}}>
                            <span class="nucleus-tools-page-text-title" style={{marginRight:25}}>
                                {"Account"}
                            </span>
                        </div>
                        <div class="col s10 m10 l10">
                            <span class="nucleus-tools-page-text-label">
                                {patient.AccountName}
                            </span>
                        </div>
                    </div>
                    <div class="row" style={{marginBottom:5}}>
                        <div class="col s2 m2 l2" style={{paddingLeft:0}}>
                            <span class="nucleus-tools-page-text-title" style={{marginRight:25}}>
                                {"Patient Name"}
                            </span>
                        </div>
                        <div class="col s10 m10 l10">
                            <span class="nucleus-tools-page-text-label">
                                {patient.Name}
                            </span>
                        </div>
                    </div>
                    <div class="row" style={{flexDirection:'row', marginBottom:2}}>
                        <div class="col s2 m2 l2" style={{ paddingLeft:0, flexDirection:'column', display:'inline-block', paddingRight:25, lineHeight:1.5}}>
                            <span class="nucleus-tools-page-text-title">
                                {"Mac"}
                            </span>
                            <br/>
                            <span class="nucleus-tools-page-text-title">
                                {"Hexnode ID"}
                            </span>
                            <br/>
                            <span class="nucleus-tools-page-text-title">
                                {"Device Name"}
                            </span>
                            <br/>
                            <span class="nucleus-tools-page-text-title">
                                {"Status"}
                            </span>
                            <br/>
                        </div>
                        <div class="col s10 m10 l10"  style={{paddingLeft:0, lineHeight:1.5, display:'inline-grid', overflowX:'auto'}}>
                            <div style={{ flex:'auto', flexDirection:'row', width:'max-content'}}>
                                {devicesArray}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
        return(
            patientsArray
        );
    }

    render(){
        let tableResultsView = ' hide';
		let tableLoadingView = '';
		if (this.state.loading){
			tableResultsView = ' hide';
			tableLoadingView = '';
		} else {
			tableResultsView = '';
			tableLoadingView = 'hide';
		}

        // let familyMembersList = [];
		// if (this.state.familyMembersArray != null) {
        //     //if (this.state.searchText == ""){
        //         this.state.familyMembersArray.map((family, key) => {
        //             familyMembersList.push(
        //                 <FamilyMemberItemRow
        //                     key={key}
        //                     MemberID={family.ID}
        //                     Name={family.Name}
        //                     Email={family.Email}
        //                     Telephone={family.Telephone}
        //                     //ReceiveUpdates={family.ReceiveUpdates}
        //                     ProfileCreated={family.ProfileCreated}
        //                     AccountAutoAnswer={family.AccountAutoAnswer}
        //                     AutoAnswer={family.AutoAnswer}
        //                     FamilyAppInfo={family.FamilyAppInfo}
        //                     VerificationCode={family.VerificationCode}
        //                 />
        //             );
        //         });
		// }

		let errorMessage = this.state.errorMessage;
		return(
			 <div class="nucleus-tools-container">
                <p class="nucleus-tools-page-title"><b>Family member</b></p>
                {
                    (this.state.familyDetails.ok) &&
                    <div class="">
                        <div class="bottom-line">
                            <span class="nucleus-tools-page-text-title a-bit-lower" style={{fontSize:17.5}}>
                                {this.state.familyDetails.FirstName + " " + this.state.familyDetails.LastName}
                            </span>
                        </div>
                        {
                            (this.generateFamilyInfo())
                        }
                    </div>
                }
               
                <div class={"row center-align " + tableLoadingView}>
                    <br />
                    <br />
                    <br />
                    <SpinnerCircular 
                        color="#2096F3"
                        secondaryColor='rgba(0,0,0,0.16)'
                        size='50'
                        thickness='100'
                        />
                    <br />
                    <br />
                    <br />
                </div>

            </div>
		);
	}
}

export default FamilyDetails;