import React from 'react';
import AuthStore from '../stores/AuthStore';
import CareAccountStore from '../stores/CareAccountStore';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

interface DashboardContentProps {}


interface DashboardContentState {
}


class DashboardContent extends React.Component<DashboardContentProps, DashboardContentState> {
  
  root1: am5.Root | null;
  root2: am5.Root | null;
  
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onGetDailyCallsAction = this.onGetDailyCallsAction.bind(this);
    this.onGetDailyHandledCallsAction = this.onGetDailyHandledCallsAction.bind(this);

    this.root1 = null;
    this.root2 = null;
  }

  componentDidMount() {
    CareAccountStore.on('onGetDailyCallsAction', this.onGetDailyCallsAction);
    CareAccountStore.on('onGetDailyHandledCallsAction', this.onGetDailyHandledCallsAction);

    CareAccountStore.getDailyCallRequests({
      Token: AuthStore.getCsUserToken(),
    });
    CareAccountStore.getDailyCallsMade({
      Token: AuthStore.getCsUserToken(),
    });
  }

  componentWillUnmount() {
    CareAccountStore.removeListener('onGetDailyCallsAction', this.onGetDailyCallsAction);
    CareAccountStore.removeListener('onGetDailyHandledCallsAction', this.onGetDailyHandledCallsAction);

    if (this.root1) {
      this.root1.dispose();
    }
    if (this.root2) {
      this.root2.dispose();
    }
  }

  onGetDailyCallsAction(data) {

    this.drawChartCallsRequested(data.calls);
  }

  onGetDailyHandledCallsAction(data) {
    this.drawChartCallsHandled(data.calls);
  }

  drawChartCallsRequested(calls) {
    console.log('drawChartCallsRequested');
    console.log(calls);

    const root = am5.Root.new('chartdiv');

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
        maxTooltipDistance: 0,
        arrangeTooltips: false,
      }),
    );
    let data = calls;
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: 'date',
      }),
    );
    xAxis.data.setAll(data);
    const seriesColumnsConfig = {
      fillOpacity: 0.5,
      strokeWidth: 2,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    };
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Calls',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'request',
        valueXField: 'date',
        categoryXField: 'date',
        fill: am5.color(0x018fff),
        stroke: am5.color(0x018fff),
      }),
    );
    series1.data.setAll(data);
    series1.set('active', am5.color(0x018fff));
    series1.columns.template.setAll(seriesColumnsConfig);
    let series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Emergency',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'emergency',
        valueXField: 'date',
        categoryXField: 'date',
        fill: am5.color(0xffcd01),
        stroke: am5.color(0xffcd01),
      }),
    );
    series2.data.setAll(data);
    series2.set('fill', am5.color('#FFCD01'));
    series2.columns.template.setAll(seriesColumnsConfig);
    let legend = chart.children.push(am5.Legend.new(root, {}));
    legend.data.setAll(chart.series.values);
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        maxTooltipDistance: 0,
        arrangeTooltips: false,
      }),
    );

    this.root1 = root;
  }

  drawChartCallsHandled(calls) {
    console.log('drawChartCallsHandled');
    console.log(calls);

    const root = am5.Root.new('chartdiv2');

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
        maxTooltipDistance: 0,
        arrangeTooltips: false,
      }),
    );
    let data = calls;
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: 'date',
      }),
    );
    xAxis.data.setAll(data);
    const seriesColumnsConfig = {
      fillOpacity: 0.5,
      strokeWidth: 2,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    };
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Answered',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'answered',
        valueXField: 'date',
        categoryXField: 'date',
        fill: am5.color(0x018fff),
        stroke: am5.color(0x018fff),
      }),
    );
    series1.data.setAll(data);
    series1.set('active', am5.color(0x018fff));
    series1.columns.template.setAll(seriesColumnsConfig);
    let series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Not Answered',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'not_answered',
        valueXField: 'date',
        categoryXField: 'date',
        fill: am5.color(0xffcd01),
        stroke: am5.color(0xffcd01),
      }),
    );
    series2.data.setAll(data);
    series2.set('fill', am5.color('#FFCD01'));
    series2.columns.template.setAll(seriesColumnsConfig);
    let legend = chart.children.push(am5.Legend.new(root, {}));
    legend.data.setAll(chart.series.values);
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        maxTooltipDistance: 0,
        arrangeTooltips: false,
      }),
    );

    this.root2 = root;
  }

  render() {
    return (
      <div className="nucleus-dashboard-news-pending">
        <br />
        <div className="row content">
          <div className="col s6 center-align">
            <div className="nucleus-chart center-align">
              <div className="nucleus-chart-title valign-wrapper" style={{paddingLeft:16}}>
                <span className="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Daily Call Request</span>
              </div>
              <div id="chartdiv" className="nucleus-chart-body"></div>
            </div>
          </div>
          <div className="col s6 center-align">
            <div className="nucleus-chart center-align">
              <div className="nucleus-chart-title valign-wrapper" style={{paddingLeft:16}}>
                <span className="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Daily Calls Made</span>
              </div>
              <div id="chartdiv2" className="nucleus-chart-body"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardContent;
