import Axios, {AxiosInstance} from 'axios';
import { ControlSecret } from './NucleusNetwork'

interface NucleusAxiosInstance extends AxiosInstance {
  setAuthorization?(jwtToken, entity, secret): void;
  setInternalAuthorization?(clientID, clientSecret): void;
  setAuthorizationToken?(jwtToken): void;
}

const axios: NucleusAxiosInstance = Axios.create({
  baseURL: 'https://care.nucleuslife.io/ws',
});

//axios.defaults.timeout = 20000;

const requestTimeoutInterceptor = (config) => {
  //console.log('=======>', config.timeout);
  if (config.timeout === undefined || config.timeout === 0) {
    return config;
  }

  const source = Axios.CancelToken.source();

  setTimeout(() => {
    source.cancel(
      `Cancelled request timeout. Took longer than ${config.timeout}ms to get complete response.`
    );
  }, config.timeout);

  // If caller configures cancelToken, preserve cancelToken behaviour.
  if (config.cancelToken) {
    config.cancelToken.promise.then((cancel) => {
      source.cancel(cancel.message);
    });
  }

  return { ...config, cancelToken: source.token };
};

axios.interceptors.request.use(requestTimeoutInterceptor);

//type EntityType = 'Caregiver' | 'Family' | 'Control' | 'Cs'

axios.setAuthorization = (jwtToken, entity, secret) => {
  if(jwtToken){
    axios.defaults.headers["Authorization"] = jwtToken
    axios.defaults.headers["X-EntityType"] = entity
    axios.defaults.headers["X-Secret"] = secret
  }
}

axios.setInternalAuthorization = (clientID, clientSecret) => {

    axios.defaults.headers["X-Client"] = clientID
    axios.defaults.headers["X-Secret"] = clientSecret
}

axios.setAuthorizationToken = (jwtToken) => {
  if(jwtToken){
    axios.defaults.headers["Authorization"] = `Bearer ${jwtToken}`
    axios.defaults.headers["X-EntityType"] = 'Control'
    axios.defaults.headers["X-Secret"] = ControlSecret
  }
}

export const serializeJSON = (data) => {
  return Object.keys(data)
    .map(function (keyName) {
      return (
        encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])
      );
    })
    .join('&');
};

export default axios;
