import React from "react";

const NucleusModalButton = ({ id, dataTooltip, handleOpenModal }) => {
  return (
    <div className="fixed-action-btn btn-add-new">
      <a
        className="btn-floating btn-large waves-effect waves-light orange tooltipped"
        data-position="left"
        data-delay="50"
        data-tooltip="New Account"
        onClick={handleOpenModal}
      >
        <i className="material-icons">add</i>
      </a>
      &nbsp;&nbsp;
    </div>
  );
};

export default NucleusModalButton;
