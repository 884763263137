import React, { useEffect, useState } from 'react';
import AuthStore from '../stores/AuthStore';
import CarePatientStore from '../stores/CarePatientStore';
import TelephoneInputUnderline from '../components/UI/Inputs/TelephoneInputUnderlined';
import { Link, useParams } from 'react-router-dom';
import CompressUtil from '../components/CompressUtil';
import classNames from 'classnames';
import Message from '../utils/Message';

let clientImageFile = null;

const NewAccountPatient = () => {
  let params = useParams();
  const [clientImageFilePreviewURL, setClientImageFilePreviewURL] = useState('');
  //const [clientImageFile, setClientImageFile] = useState(null);
  const [panel] = useState('Form');
  const [errorTelephoneVisibility, setErrorTelephoneVisibility] = useState('hide');
  const [errorTelephoneText, setErrorTelephoneText] = useState('Telephone already registered');
  const [newPatientFullName] = useState('');
  const [btnStyle, setBtnStyle] = useState('disabled');
  const [newPatient, setNewPatient] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    telephone: '',
    address: '',
  });

  let formVisibility = classNames({
    hide: panel !== 'Form',
  });
  let messageVisible = classNames({
    hide: panel !== 'Message',
  });

  const validateTxtInput = (event) => {
    event.persist();
    setFormValues((values) => ({
      ...values,
      [event.target.id]: event.target.value,
    }));

    if (formValues.firstName.length > 0 && formValues.lastName.length > 0) {
      setBtnStyle('');
    } else {
      setBtnStyle('disabled');
    }
    if (formValues.telephone.length > 0) {
      setErrorTelephoneVisibility('hide');
    }
  };

  const onTelephoneInputChange = (telephoneNumber) => {
    console.log("onTelephoneInputChange");
    console.log("onTelephoneInputChange number", telephoneNumber);
    setFormValues((values) => ({
      ...values,
      telephone: telephoneNumber,
    }));
    if (telephoneNumber && telephoneNumber.length > 0) {
      setErrorTelephoneVisibility('hide');
    }
  };

  const submitCreatePatientForm = () => {
    if (formValues.firstName === '') {
      Message.show('FirstName is required.');
      return;
    }

    if (formValues.lastName === '') {
      Message.show('LastName is required.');
      return;
    }
    let dateParam = '';
    let newPatientTelephone = formValues.telephone;
    if (newPatientTelephone === '+1') {
      newPatientTelephone = '';
    }
    if (newPatientTelephone.length > 0) {
      console.log('the length of the phone: ', newPatientTelephone.length);
      newPatientTelephone = newPatientTelephone.replace(/[()-\s]/g, '');

      if (newPatientTelephone.length < 12) {
        setErrorTelephoneVisibility('');
        setErrorTelephoneText('Invalid Telephone. It must be at least 10 digits.');
        return;
      }
      let containsLetter = /[a-zA-Z]/.test(newPatientTelephone);
      if (containsLetter) {
        setErrorTelephoneVisibility('');
        setErrorTelephoneText('Invalid Telephone.');
        return;
      }
    }
    // if (this.refs.txtNewPatientContactNumber.value.length > 0 && this.refs.txtNewPatientContactNumber.value.length < 10){
    //     this.setState({
    //         errorContactTelephoneVisibility : '',
    //         errorContactTelephoneText : 'Invalid Telephone. It must be at least 10 digits.'
    //     });
    //     return;
    // }
    const CreateNewPatientData = {
      UserID: '',
      AccountID: params.accountId,
      FirstName: formValues.firstName,
      LastName: formValues.lastName,
      Telephone: newPatientTelephone,
      Address: formValues.address,
      DateOfBirth: dateParam,
      EmergencyContactName: '', //this.refs.txtNewPatientContactName.value,           //  Deprecated on Feb 2023
      EmergencyContactTelephone: '', //this.refs.txtNewPatientContactNumber.value     // Deprecated on Feb 2023     CARE-10239 Remove Emergency Contact and Date of Birth from patient
      //ShowWeather : this.refs.checkShowWeather.checked
    };

    setBtnStyle('disabled');
    CarePatientStore.CreateNewPatient(CreateNewPatientData);
    setErrorTelephoneVisibility('hide');
  };

  useEffect(() => {
    if (newPatient) {
      return;
    }
    CarePatientStore.on('onCreatePatientAction', onCreatePatientAction);

    return () => {
      CarePatientStore.removeListener('onCreatePatientAction', onCreatePatientAction);
    };
  }, [newPatient]);

  const onCreatePatientAction = (response) => {
    if (response.ok) {
      /*CarePatientStore.getAllPatientsData({
                UserID : AuthStore.getCsUserID()
            });*/
      console.log('RESPONSE NEW PATIENT', response);
      console.log('image: ', clientImageFile);
      if (clientImageFile != null) {
        console.log('uploading image', clientImageFile);
        CarePatientStore.UploadPatientImage({
          PatientID: response.PatientID,
          File: clientImageFile,
        });
        clientImageFile = null;
      }
      window.location.assign('#/accountDetails/' + params.accountId);
      setTimeout(function () {
        Message.show(AuthStore.getPatientLabel() + ' created');
      }, 100);
      setNewPatient(true);
    } else if (response.telephoneRegistered) {
      setErrorTelephoneVisibility('');
      setErrorTelephoneText('Telephone number already registered ');
      setNewPatient(true);
    } else {
      Message.show('Error creating new ' + AuthStore.getPatientLabel());

      if (response.error) {
        Message.show('Error: ' + response.error);
      }
      setNewPatient(true);
    }
    setBtnStyle('');
  };
  const fallbackImageURL = true ? `url("img/user_placeholder.png")` : `url("https://images.unsplash.com/photo-1633113216120-53ca0a7be5bc")`;
  return (
    <div className="nucleus-hard-container">
      <p id={'patient-page-title'} className="nucleus-page-title">
        New {AuthStore.getPatientLabel()}
      </p>
      <br />
      <div className={'nucleus-patient-form-pending ' + formVisibility}>
        <br />
        <div className="row no-margin">
          <div className="col s2 m2 l2 no-margin">
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
              <style>
                {`
                            .img-circle {
                                border-radius: 50%;
                            }
                            .img-circle {
                                border-radius: 0;
                            }
                            
                            .ratio {
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: cover;
                            
                                height: 0;
                                padding-bottom: 100%;
                                position: relative;
                                width: 100%;
                            }
                            .img-circle {
                                border-radius: 50%;
                            }
                            .img-responsive {
                                display: block;
                                height: auto;
                                max-width: 100%;
                            }
                            .inputfile {
                                width: 0.1px;
                                height: 0.1px;
                                opacity: 0;
                                overflow: hidden;
                                position: absolute;
                                z-index: -1;
                            }
                            .inputfile + label {
                                margin-top:10px;
                                font-size: 1.25em;
                                // font-weight: 700;
                                text-decoration: underline;
                                color: gray;
                                // background-color: black;
                                display: inline-block;
                            }
                            
                            .inputfile:focus + label,
                            .inputfile + label:hover {
                                cursor: pointer;
                                // background-color: red;
                            }
                            .inputfile {
                                cursor: pointer;
                            }
                            `}
              </style>
              <div style={{ width: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <div
                  className="ratio img-responsive img-circle"
                  style={{
                    backgroundImage: clientImageFilePreviewURL ? `url(${clientImageFilePreviewURL})` : fallbackImageURL,
                    borderWidth: '1px solid lightgray',
                  }}
                ></div>
                <div>
                  <input
                    accept="image/*"
                    onChange={(e) => {
                      const files = e.target?.files;
                      if (files && files?.length > 0) {
                        let value = files[0];
                        console.log('value:', value);
                        let reader = new FileReader();
                        let url = reader.readAsDataURL(value);
                        let urlImage = URL.createObjectURL(value);
                        setClientImageFilePreviewURL(urlImage);
                        reader.onloadend = function (e) {
                          CompressUtil(value).then((data) => {
                            console.log('the data: ', data);
                            clientImageFile = data;
                          });
                        };
                        console.log('Value image:image:', [reader.result]);
                        console.log(url); // Would see a path?
                      }
                    }}
                    multiple={false}
                    type="file"
                    name="file"
                    id="file"
                    className="inputfile"
                  />
                  <label htmlFor="file">Upload Photo</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col s8 m8 l8">
            <div className="row">
              <div className="col s3 valgin-wrapper left-align">
                <span className=" nucleus-labels"> First Name</span> <span className="nucleus-labels txt-red">*</span>
              </div>
              <div className="col s9">
                <input id="firstName" type="text" value={formValues.firstName} className="validate nucleus-input-form" placeholder="First Name" onChange={validateTxtInput} />
              </div>
            </div>

            <div className="row">
              <div className="col s3 valgin-wrapper left-align">
                <span className=" nucleus-labels"> Last Name</span> <span className="nucleus-labels txt-red">*</span>
              </div>
              <div className="col s9">
                <input 
                  id="lastName" 
                  value={formValues.lastName} 
                  type="text" 
                  className="validate nucleus-input-form" 
                  placeholder="Last Name" 
                  onChange={validateTxtInput} 
                />
              </div>
            </div>

            <div className="row">
              <div className="col s3 left-align">
                <span className=" nucleus-labels"> Telephone</span>
              </div>
              <div className="col s9" style={{ paddingRight: 0 }}>
                <TelephoneInputUnderline 
                  inputId="txtNewPatientTelephone" 
                  value={formValues.telephone}
                  onChange={onTelephoneInputChange} 
                  style={{marginRight:10}}
                  //onBlur={this.validateForm} 
                />
                <br />
                <span className={'nucleus-form-error left-align txt-black-forced '}>
                  Click the country code icon for more country codes.
                </span>
                <br />
                <span className={'nucleus-form-error center-align ' + errorTelephoneVisibility}>
                  {errorTelephoneText}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col s3 left-align">
                <span className=" nucleus-labels"> Address</span>
              </div>
              <div className="col s9">
                <input
                  id="address"
                  value={formValues.address}
                  type="text"
                  className="validate nucleus-input-form"
                  placeholder="1234 Street Lane, New City, NC 00000"
                  onChange={validateTxtInput}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="fixed-action-btn btn-add-new">
          <a
            id={'save-patient-btn'}
            className={`btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped ${btnStyle ? 'disabled' : ''}`}
            data-position="left"
            data-tooltip={`Save new ${AuthStore.getPatientLabel().toLowerCase()}`}
            onClick={(e) => {
              if (!btnStyle) {
                submitCreatePatientForm();
              } else {
                e.preventDefault();
              }
            }}
          >
            <i className="material-icons">save</i>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </div>

      <div className={'nucleus-new-patient-form-pending ' + messageVisible}>
        <br />
        <div className="row">
          <div className="col s12">
            <p className=" nucleus-labels"> Complete! The {AuthStore.getPatientLabel().toLowerCase() + ' ' + newPatientFullName} has been created. </p>
            <Link to="/patients">
              <span className="nucleus-link nucleus-font-medium">Go to {AuthStore.getPatientLabelPlural()} List </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAccountPatient;
