import { useCallback, useEffect, useRef, useState } from 'react';
import CareAccountStore from '../../../stores/CareAccountStore';
import Message from '../../../utils/Message';
import AuthStore from '../../../stores/AuthStore';
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { set } from 'react-hook-form';

export type AccountData = {
  APIToken: string;
  APIUsageConsecutiveLimitViolation: number;
  AccountGroupID: string;
  AccountGroupName: string;
  AccountTemplateBulletinButtons: number;
  AccountTemplateImage: string;
  AccountTemplateName: string;
  AddFamilyButtonText: string;
  AlertOnDutyUsers: boolean;
  AlertOnExtremeRoomTemperature: number;
  AudioCallShortcut: boolean;
  AudioCallsOnly: boolean;
  AutoLogoutTimeout: number;
  AutomaticCallRequestBargeIn: boolean;
  BackgroundImage: string;
  BackgroundLogoImage: string;
  BargeInCallShortcut: boolean;
  BiometricsButtonText: string;
  CallButtonText: string;
  CallButtonText2: string;
  CallTimeoutDelayInSeconds: number;
  CaregiverRoleName: string;
  CaregiverRoleNamePlural: string;
  ClientRoleName: string;
  ClientRoleNamePlural: string;
  CountryCode: string;
  CreatedDate: string;
  CustomLinkButtonImageUrl: string | null;
  CustomLinkButtonText: string | null;
  CustomLinkButtonUrl: string | null;
  DaysSinceCreated: number;
  DeviceTemplate: number;
  DimFrom: string;
  DimOffValue: number;
  DimOnValue: number;
  DimTo: string;
  EmergencyButtonText: string;
  EnableAppIncomingCall: boolean;
  EnableAutoAnswer: boolean;
  EnableAutoAnswerForFamily: boolean;
  EnableAutoAnswerForFamilyApp: boolean;
  EnableBackgroundImage: boolean;
  EnableBackgroundLogo: boolean;
  EnableBiometrics: boolean;
  EnableBluetoothButtons: boolean;
  EnableCallButtonSchedule: boolean;
  EnableControlIncomingCall: boolean;
  EnableEmergencyButtonSchedule: boolean;
  EnableImportClients: boolean;
  EnableMemoryBox: boolean;
  EnableMessageResponseByEmail: boolean;
  EnableMessageToAllPatients: boolean;
  EnableMoveFamily: boolean;
  EnablePhoneCallFromDevice: boolean;
  EnableRingOnIncomingCall: boolean;
  EnableScheduleADoctor: boolean;
  EnableTalkToADoctorNow: boolean;
  EnableVoiceMessage: boolean;
  EscalationAlertEmailAddress: string;
  FamilyCallButtonText: string;
  FamilyRoleName: string;
  FamilyRoleNamePlural: string;
  ForceLandscapeVideo: boolean;
  GroupsButtonText: string;
  HexnodeAPI: string;
  HexnodeGroupID: number;
  HexnodeIdRequired: boolean;
  HexnodePolicyID: number;
  HexnodeUrl: string;
  HexnodeUserName: string;
  HomeButtonEnabled: boolean;
  HoursFormat: string;
  ID: string;
  InactiveDays: number;
  InactiveDaysAlert: number;
  IsCheckedInScreenStaysOn: boolean;
  IsHipaaCompliant: boolean;
  LocaleTag: string;
  Logo: string;
  MemoryBoxButtonText: string;
  MobileCallResponse: string;
  Name: string;
  NotifyOnDeviceOffline: number;
  NucleusDeviceUXVersion: string;
  OffHoursMessage: string;
  OfflineTimeSpan: number;
  OnDutyName: string;
  PagerDutyEmailAddress: string;
  PalCareXMPPAddressForAlarm: string;
  PhoneCallFromDeviceNumber: string;
  ReceiveEscalationCallRequestTimeout: number;
  RemoteLogs: boolean;
  RequestTimeoutMessageTextForPatient: string;
  RestartOfflineDeviceEnabled: boolean;
  RestartOfflineDeviceTimeSpan: number;
  RingOnRequest: boolean;
  SalesforceClientID: string;
  SalesforceClientSecret: string;
  SalesforceEnabled: boolean;
  SalesforceLoginUrl: string;
  SalesforceName: string;
  SalesforceOrgUrl: string;
  SalesforcePassword: string;
  SalesforceRedirectUri: string;
  SalesforceSecurityToken: string;
  SalesforceUserName: string;
  SalesforceValidCredentials: boolean;
  ScheduleTimezone: string;
  ScreenOffBatterySaver: boolean;
  ScreenSaverDelayInSeconds: number;
  SendEmergencyAlarmToPalCare: boolean;
  SendMessageResponseEmail: string;
  SendToPagerDuty: boolean;
  SendWelcomeEmail: boolean;
  SendWelcomeSMS: boolean;
  SettingsAccessPIN: string;
  ShowBatteryStatus: boolean;
  ShowCallButton: boolean;
  ShowCallButton2: boolean;
  ShowCallButtonsBar: boolean;
  ShowCheckInButton: boolean;
  ShowEmergencyButton: boolean;
  ShowFamilyCallButton: boolean;
  ShowVideoStats: boolean;
  SilentBargeInCallShortcut: boolean;
  StateID: string;
  TestBackgroundImage: string;
  TestBackgroundLogo: string;
  TotalBiometricPatients: number;
  TotalDevices: number;
  TotalOnlineDevices: number;
  TotalPatients: number;
  TotalUsers: number;
  TwoFactorAuth: boolean;
  UpdateGroupID: string;
  UseDeviceTemplate: number;
  UsePanicEmergencyRingtone: boolean;
  UserRoleName: string;
  UserRoleNamePlural: string;
  VideoCallShortcut: boolean;
  WeightUnit: string;
  ZipCode: string;
};

export const AccountSettings = ({ accountId, match }: { accountId: string; match: any }) => {
  const txtAccountName = useRef<HTMLInputElement>();
  const txtZipCode = useRef<HTMLInputElement>();
  const txtLogoFile = useRef<HTMLInputElement>();
  const txtCallTimeoutDelay = useRef<HTMLInputElement>();
  const selectAccountGroup = useRef<HTMLSelectElement>();
  const selectAccountLocale = useRef<HTMLSelectElement>();
  const selectMobileCallResponse = useRef<HTMLSelectElement>();
  const selectWeightUnit = useRef<HTMLSelectElement>();
  const selectHoursFormat = useRef<HTMLSelectElement>();
  const [accountGroupsArray, setAccountGroupsArray] = useState<any[]>(CareAccountStore.getAccountGroups());
  const [accountData, setAccountData] = useState<AccountData | undefined>();
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [availableCountries, setAvailableCountries] = useState<any[]>([]);
  const [availableAccountLocales, setAvailableAccountLocales] = useState<any[]>([]);
  const [availableStates, setAvailableStates] = useState<any[]>([]);
  const [selectedState, setSelectedState] = useState<string>();
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState<boolean>(accountData?.SendWelcomeEmail);
  const [sendWelcomeSmsValue, setSendWelcomeSmsValue] = useState<boolean>(accountData?.SendWelcomeSMS);
  const [enableMoveFamilyValue, setEnableMoveFamilyValue] = useState<boolean>(accountData?.EnableMoveFamily);
  const [enableIncomingCallValue, setEnableIncomingCallValue] = useState<boolean>(accountData?.EnableControlIncomingCall);
  const [enableAppIncomingCallValue, setEnableAppIncomingCallValue] = useState<boolean>(accountData?.EnableAppIncomingCall);
  const [ringIncomingCallValue, setRingIncomingCallValue] = useState<boolean>(accountData?.EnableRingOnIncomingCall);
  const [panicRingtoneValue, setPanicRingtoneValue] = useState<boolean>(accountData?.UsePanicEmergencyRingtone);
  const [enableBiometricsValue, setEnableBiometricsValue] = useState<boolean>(accountData?.EnableBiometrics);
  const [enableFamilyAutoAnswerValue, setEnableFamilyAutoAnswerValue] = useState<boolean>(accountData?.EnableAutoAnswerForFamily);
  const [enableFamilyAppAutoAnswerValue, setEnableFamilyAppAutoAnswerValue] = useState<boolean>(accountData?.EnableAutoAnswerForFamilyApp);
  const [enable2FactorAuthValue, setEnable2FactorAuthValue] = useState<boolean>(accountData?.TwoFactorAuth);
  const [enableAutomaticCallRequestBargeInValue, setEnableAutomaticCallRequestBargeInValue] = useState<boolean>(
    accountData?.AutomaticCallRequestBargeIn,
  );

  
 

  const handleUpdateName = () => {
    if (!txtAccountName.current.value) {
      Message.show('Please, insert a name');
      return;
    }
    CareAccountStore.updateAccountName({
      AccountID: accountId,
      AccountName: txtAccountName.current.value,
    });
    
  };

  const onAccountNameUpdatedAction = (response) => {
    console.log('onAccountNameUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Account Name updated!');
    } else {
      Message.show('Error updating account name');
    }
    refreshAccountData(accountId);
  };

  const handleUpdateLogo = () => {
    if (txtLogoFile.current.value == '') {
      Message.show('Select a file please.');
    }

    CareAccountStore.updateAccountLogo({
      AccountID: accountId,
      Logo: txtLogoFile.current.value,
    });
  };
  const onAccountLogoUpdatedAction = (response) => {
    console.log('onAccountLogoUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Account Logo updated!');
    } else {
      Message.show('Error updating account logo');
    }
    refreshAccountData(accountId);
  };

  const onGetAccountGroupsDataAction = useCallback(
    (response) => {
      console.log('onGetAccountGroupsDataAction ==> ', response);
      if (response) {
        setAccountGroupsArray(CareAccountStore.getAccountGroups());

        selectAccountGroup.current.value = accountData?.AccountGroupID.toLowerCase() || '';
      } else {
        Message.show('Sorry, There was a problem getting the Account groups information. Please try again.');
      }
    },
    [accountData],
  );

  const handleEnable2FactorAuth = (event) => {
    console.log('handleEnable2FactorAuth', event.target.checked);
    setEnable2FactorAuthValue(event.target.checked);
    CareAccountStore.update2FactorAuth({
      AccountID: accountId,
      Enabled: event.target.checked,
    });
  };

  const on2FactorAuthUpdatedAction = (response) => {
    if (response.ok) {
      let responseResult = response.TwoFactorAuth ? ' Enabled.' : ' Disabled.';
      CareAccountStore.set2FactorAuthEnabled(response.TwoFactorAuth);
      Message.show('Two Factor Authentication ' + responseResult);
    } else {
      Message.show('Error updating Two Factor Authentication');
    }
    refreshAccountData(accountId);
  };

  const refreshAccountData = (accountId: string) => {
    CareAccountStore.getAccountData({
      AccountID: accountId,
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
    });
  };

  const onGetAccountDataAction = (response: AccountData & { ok: boolean }) => {
    if (response && response.ok) {
      setSelectedState(response.StateID);
      setAccountData(response);

      if (response?.MobileCallResponse) {
        selectMobileCallResponse.current.value = response.MobileCallResponse;
      } else {
        selectMobileCallResponse.current.value = '';
      }

      selectWeightUnit.current.value = response?.WeightUnit;
      selectHoursFormat.current.value = response?.HoursFormat;

      if (response?.AccountGroupID) {
        selectAccountGroup.current.value = response.AccountGroupID.toLowerCase();
      } else {
        selectAccountGroup.current.value = '';
      }

      txtAccountName.current.value = response.Name;
      txtLogoFile.current.value = response.Logo;

      if (response?.CountryCode) {
        setSelectedCountry(response.CountryCode);
      } else {
        setSelectedCountry('');
      }
      if (response?.StateID) {
        setSelectedState(response.StateID);
      }

      if (response?.LocaleTag) {
        selectAccountLocale.current.value = response.LocaleTag;
      } else {
        selectAccountLocale.current.value = '';
      }

      setSendWelcomeEmail(!!response?.SendWelcomeEmail);
      setSendWelcomeSmsValue(!!response?.SendWelcomeSMS);
      setEnableMoveFamilyValue(!!response?.EnableMoveFamily);
      setEnableIncomingCallValue(!!response?.EnableControlIncomingCall);
      setEnableAppIncomingCallValue(!!response?.EnableAppIncomingCall);
      setRingIncomingCallValue(!!response?.EnableRingOnIncomingCall);
      setPanicRingtoneValue(!!response?.UsePanicEmergencyRingtone);
      setEnableBiometricsValue(!!response?.EnableBiometrics);
      setEnableFamilyAutoAnswerValue(!!response?.EnableAutoAnswerForFamily);
      setEnableFamilyAppAutoAnswerValue(!!response?.EnableAutoAnswerForFamilyApp);
      setEnable2FactorAuthValue(!!response?.TwoFactorAuth);
      setEnableAutomaticCallRequestBargeInValue(!!response?.AutomaticCallRequestBargeIn);

    } else {
      Message.show('Sorry, There was a problem getting the account information. Please try again.');
    }
  };

  const handleUpdateZipCode = () => {
    if (txtZipCode.current.value == '') {
      Message.show('Insert a valid Zip Code');
      txtZipCode.current.focus();
    }

    CareAccountStore.updateZipCode({
      AccountID: accountId,
      ZipCode: txtZipCode.current.value,
    });
    // Listen to "onZipCodeUpdated"
  };
  const onZipCodeUpdatedAction = (response) => {
    console.log('onZipCodeUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Account Zip Code updated!');
    } else {
      Message.show('Error updating Account Zip Code');
    }
    refreshAccountData(accountId);
  };

  const handleChangeAccountGroup = () => {
    let accountGroupId = selectAccountGroup.current.value;
    if (accountGroupId === '') {
      accountGroupId = null;
    }
    console.log('New Group ', accountGroupId);

    CareAccountStore.updateAccountGroup({
      AccountID: accountId,
      AccountGroupID: accountGroupId,
    });
  };

  const onAccountGroupUpdatedAction = (response) => {
    console.log('onAccountGroupUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Account Group updated!');
    } else {
      Message.show('Error updating account group');
    }
    refreshAccountData(accountId);
  };

  const handleChangeAccountLocale = () => {
    console.log('New Locale', selectAccountLocale.current.value);

    CareAccountStore.updateAccountLocale({
      AccountID: accountId,
      LocaleTag: selectAccountLocale.current.value,
    });
  };

  const onAccountLocaleUpdatedAction = (response) => {
    console.log('onAccountLocaleUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Account Locale updated!');
    } else {
      Message.show('Error updating account locale');
    }
    refreshAccountData(accountId);
  };

  const handleChangeCountry = (event) => {
    setSelectedCountry(event.target.value);
    CareAccountStore.updateAccountCountry({
      AccountID: accountId,
      CountryCode: event.target.value,
    });
  };

  const updateAccountState = async (stateId) => {
    try {
      console.log('state id to update: ', stateId);

      const data = { stateID: stateId };
      const updateAccountStateResponse = await httpApi.put(`/account/${match.params.accountId}/state`, data);
      return updateAccountStateResponse;
    } catch (error) {
      console.log('Error updating account State');
      throw error;
    }
  };

  const handleChangeState = (e) => {
    updateAccountState(e.target.value)
      .then((response) => {
        console.log('response from updating: ', response);
        Message.show('Updated Account State successfully');
      })
      .catch((error) => {
        console.log('Error updating account state: ', error);
        Message.show('Error updating State');
      });
  };

  const onAccountCountryUpdatedAction = (response) => {
    console.log('onAccountCountryUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Account Country updated!');
    } else {
      Message.show('Error updating Account Country');
    }
    refreshAccountData(accountId);
  };

  const onGetCountriesDataAction = useCallback(
    (response) => {
      console.log('onGetCountriesDataAction: ');

      if (response) {
        let availableCountries = [];
        CareAccountStore.getAccountsCountries().map((country, key) => {
          availableCountries.push(
            <option key={country.Code} value={country.Code}>
              {country.Name}
            </option>,
          );
        });
        setAvailableCountries(availableCountries);
        setSelectedCountry(accountData?.CountryCode);
      } else {
        Message.show('Sorry, There was a problem getting the countries information. Please try again.');
      }
    },
    [accountData],
  );

  const onGetAccountLocaleDataAction = useCallback(
    (response) => {
      console.log('onGetAccountLocaleDataAction: ', response);
      if (response && response.ok) {
        let availableAccountLocales = [];
        response.accountLanguages.map((locale, key) => {
          availableAccountLocales.push(
            <option key={locale.LocaleTag} value={locale.LocaleTag}>
              {locale.Description}
            </option>,
          );
        });
        setAvailableAccountLocales(availableAccountLocales);

        selectAccountLocale.current.value = accountData?.LocaleTag;
      } else {
        Message.show('Sorry, There was a problem getting the update locale information. Please try again.');
      }
    },
    [accountData],
  );

  const getAvailableStates = async () => {
    httpApi
      .get('/account/states')
      .then((response) => {
        const { states } = response?.data;
        console.log('states: ', states);
        setAvailableStates(states);
      })
      .catch((error) => {
        Message.show('Error getting available states: ', error);
      });
  };

  const handleSendWelcomeEmail = (event) => {
    console.log('this has changed ', event.target.checked);
    setSendWelcomeEmail(event.target.checked);

    CareAccountStore.updateSendWelcomeEmail({
      AccountID: accountId,
      ShouldSendWelcomeEmail: event.target.checked,
    });
    //onSendWelcomeEmailUpdated
    
  };

  const onSendWelcomeEmailUpdatedAction = (response) => {
    if (response.ok) {
      Message.show('Should send Welcome Email updated');
      
    } else {
      Message.show('Error updating Should send Welcome Email');
    }
    refreshAccountData(accountId);
  };

  const handleSendWelcomeSms = (event) => {
    console.log('this has changed ', event.target.checked);
    setSendWelcomeSmsValue(event.target.checked);
    CareAccountStore.updateSendWelcomeSms({
      AccountID: accountId,
      ShouldSendWelcomeSms: event.target.checked,
    });
    //onSendWelcomeSmsUpdated
  };

  const onSendWelcomeSmsUpdatedAction = (response) => {
    if (response.ok) {
      Message.show('Should send Welcome Sms updated');
      
      
    } else {
      Message.show('Error updating Should send Welcome Sms');
    }
    refreshAccountData(accountId);
  };

  const handleEnableMoveFamily = (event) => {
    console.log('this has changed ', event.target.checked);
    setEnableMoveFamilyValue(event.target.checked);
    CareAccountStore.updateEnableMoveFamily({
      AccountID: accountId,
      EnableMoveFamily: event.target.checked,
    });
    //onEnableMoveFamilyUpdated
  };

  const onEnableMoveFamilyUpdatedAction = (response) => {
    console.log('onEnableMoveFamilyUpdatedAction', response);

    if (response.ok) {
      let stringResponse = response.enabled ? ' enabled.' : ' disabled.';
      Message.show('Move Family Members ' + stringResponse);
      
    } else {
      Message.show('Error checking Move Family Members ');
    }
    refreshAccountData(accountId);
  };

  const handleEnableIncomingCall = (event) => {
    console.log('this has changed ', event.target.checked);
    setEnableIncomingCallValue(event.target.checked);
    CareAccountStore.updateControlIncomingCall({
      AccountID: accountId,
      EnableControlIncomingCall: event.target.checked,
    });
  };

  const onEnableControlIncomingCallUpdatedAction = (response) => {
    if (response.ok) {
      let responseResult = response.status ? ' Enabled.' : ' Disabled.';
      Message.show('Incoming Call on Web Control ' + responseResult);
    } else {
      Message.show('Error updating Incoming Call on Web Control');
    }
    refreshAccountData(accountId);
  };

  const handleEnableAppIncomingCall = (event) => {
    console.log('this has changed ', event.target.checked);
    setEnableAppIncomingCallValue(event.target.checked);
    CareAccountStore.updateAppIncomingCall({
      AccountID: accountId,
      EnableAppIncomingCall: event.target.checked,
    });
  };

  const onEnableAppIncomingCallUpdatedAction = (response) => {
    if (response.ok) {
      let responseResult = response.status ? ' Enabled.' : ' Disabled.';
      Message.show('App Incoming Call ' + responseResult);
    } else {
      Message.show('Error updating App Incoming Call');
    }
    refreshAccountData(accountId);
  };

  const handleRingIncomingCall = (e) => {
    console.log('this has changed ', e.target.checked);
    setRingIncomingCallValue(e.target.checked);
    CareAccountStore.updateRingIncomingCall({
      AccountID: accountId,
      EnableRingOnIncomingCall: e.target.checked,
    });
  };

  const onEnableRingOnIncomingCallUpdatedAction = (response) => {
    console.log('onEnableRingOnIncomingCallUpdatedAction', response);

    if (response.ok) {
      let responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('Ring on Incoming Call ' + responseResult);
    } else {
      Message.show('Error updating Ring on Incoming Call');
    }
    refreshAccountData(accountId);
  };

  const handleUsePanic = (event) => {
    console.log('this has changed ', event.target.checked);
    setPanicRingtoneValue(event.target.checked);
    CareAccountStore.updateUsePanicRingtone({
      AccountID: accountId,
      UsePanicEmergencyRingtone: event.target.checked,
    });
  };
  const onUsePanicRingtoneUpdatedAction = (response) => {
    if (response.ok) {
      Message.show('Use Panic Emergency Ringtone updated');
    } else {
      Message.show('Error updating Use Panic Emergency Ringtone');
    }
    refreshAccountData(accountId);
  };

  const handleEnableBiometrics = (e) => {
    console.log('this has changed ', e.target.checked);
    setEnableBiometricsValue(e.target.checked);
    CareAccountStore.updateEnableBiometrics({
      AccountID: accountId,
      EnableBiometrics: e.target.checked,
    });
    //onEnableBiometricsUpdated
  };

  const onEnableBiometricsUpdatedAction = (response) => {
    console.log('onEnableBiometricsUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Enable Biometrics set to: ' + response.enable);
    } else {
      Message.show('Error updating Enable Biometrics state');
    }
    refreshAccountData(accountId);
  };

  //Call Timeout Delay
  const handleUpdateCallTimeoutDelay = () => {
    if (txtCallTimeoutDelay.current.value == '') {
      Message.show('Enter the Call timeout delay (in seconds) please.');
      txtCallTimeoutDelay.current.focus();
      return;
    }

    CareAccountStore.updateCallTimeoutDelay({
      AccountID: accountId,
      CallTimeoutDelayInSeconds: txtCallTimeoutDelay.current.value,
    });
  };

  const onCallTimeoutDelayUpdatedAction = (response) => {
    console.log('onCallTimeoutDelayUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Call Timeout Delay updated');
    } else {
      Message.show('Error updating Call Timeout Delay');
    }
    refreshAccountData(accountId);
  };

  const handleFamilyAutoAnswer = (e) => {
    console.log('this has changed ', e.target.checked);
    setEnableFamilyAutoAnswerValue(e.target.checked);
    CareAccountStore.updateEnableFamilyAutoAnswer({
      AccountID: accountId,
      EnableFamilyAutoAnswer: e.target.checked,
    });
    //onEnableFamilyAutoAnswerUpdated
  };
  const onEnableFamilyAutoAnswerUpdatedAction = (response) => {
    console.log('onEnableFamilyAutoAnswerUpdatedAction', response);

    if (response.ok) {
      let responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('Auto Answer For Family Member' + responseResult);
    } else {
      Message.show('Error updating Auto Answer For Family Member');
    }
  };

  const handleFamilyAppAutoAnswer = (e) => {
    console.log('this has changed ', e.target.checked);
    setEnableFamilyAppAutoAnswerValue(e.target.checked);
    CareAccountStore.updateEnableFamilyAppAutoAnswer({
      AccountID: accountId,
      EnableAutoAnswerForFamilyApp: e.target.checked,
    });
  };
  const onEnableAutoAnswerForFamilyAppUpdatedAction = (response) => {
    console.log('onEnableAutoAnswerForFamilyAppUpdatedAction', response);

    if (response.ok) {
      let responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('Auto Answer For Family App' + responseResult);
    } else {
      Message.show('Error updating Auto Answer For Family App');
    }
  };

  const handleMobileCallResponse = (e) => {
    console.log('Mobile Call Response Value: ', e.target.value);

    CareAccountStore.updateMobileCallResponse({
      AccountID: accountId,
      MobileCallResponse: e.target.value,
    });
  };

  const onMobileCallResponseUpdatedAction = (response) => {
    console.log('onMobileCallResponseUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Mobile Call Response updated!');
    } else {
      Message.show('Error updating mobile call response');
    }
  };

  const handleChangeAccountWeightUnit = (e) => {
    CareAccountStore.updateWeightUnit({
      AccountID: accountId,
      WeightUnit: e.target.value,
    });
  };

  const onWeightUnitUpdatedAction = (response) => {
    if (response.ok) {
      Message.show('Account Weight Unit set to ' + response.WeightUnit);
    } else {
      Message.show('Error updating Account Weight Unit');
    }
  };

  const handleChangeAccountHoursFormat = (e) => {
    //console.log("handleChangeAccountHoursFormat",e);
    CareAccountStore.updateHoursFormat({
      AccountID: accountId,
      HoursFormat: e.target.value,
    });
  };

  const onHoursFormatUpdatedAction = (response) => {
    //console.log("onHoursFormatUpdatedAction response", response);

    if (response.ok) {
      Message.show('Account Hours Format set to ' + response.HoursFormat + ' hrs');
    } else {
      Message.show('Error updating Account Hours Format');
    }
  };

  const handleEnableAutomaticCallRequestBargeIn = (event) => {
    console.log('this has changed ', event.target.checked);

    CareAccountStore.updateControlAutomaticCallRequestBargeIn({
      AccountID: accountId,
      EnableControlAutomaticCallRequestBargeIn: event.target.checked,
    });
  };

  useEffect(() => {
    if (accountId) {
      refreshAccountData(accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (accountData) {
      CareAccountStore.getAccountGroupsData();
      CareAccountStore.getAccountLocaleData(accountId);
      CareAccountStore.getAccountsCountriesData();
    }
    return () => {};
  }, [accountData, accountId]);

  useEffect(() => {
    CareAccountStore.on('onGetAccountLocaleData', onGetAccountLocaleDataAction);
    CareAccountStore.on('onGetAccountGroupsData', onGetAccountGroupsDataAction);
    CareAccountStore.on('onGetCountriesData', onGetCountriesDataAction);
    return () => {
      CareAccountStore.removeListener('onGetAccountGroupsData', onGetAccountGroupsDataAction);
      CareAccountStore.removeListener('onGetAccountLocaleData', onGetAccountLocaleDataAction);
      CareAccountStore.removeListener('onGetCountriesData', onGetCountriesDataAction);
    };
  }, [onGetAccountGroupsDataAction, onGetAccountLocaleDataAction, onGetCountriesDataAction]);

  useEffect(() => {
    CareAccountStore.on('onAccountNameUpdated', onAccountNameUpdatedAction);
    CareAccountStore.on('onAccountLogoUpdated', onAccountLogoUpdatedAction);

    CareAccountStore.on('on2FactorAuthUpdated', on2FactorAuthUpdatedAction);
    CareAccountStore.on('onGetAccountData', onGetAccountDataAction);
    CareAccountStore.on('onZipCodeUpdated', onZipCodeUpdatedAction);
    CareAccountStore.on('onAccountGroupUpdated', onAccountGroupUpdatedAction);
    CareAccountStore.on('onAccountLocaleUpdated', onAccountLocaleUpdatedAction);

    CareAccountStore.on('onAccountCountryUpdated', onAccountCountryUpdatedAction);

    CareAccountStore.on('onSendWelcomeEmailUpdated', onSendWelcomeEmailUpdatedAction);
    CareAccountStore.on('onSendWelcomeSmsUpdated', onSendWelcomeSmsUpdatedAction);
    CareAccountStore.on('onEnableMoveFamilyUpdated', onEnableMoveFamilyUpdatedAction);
    CareAccountStore.on('onEnableControlIncomingCallUpdated', onEnableControlIncomingCallUpdatedAction);
    CareAccountStore.on('onEnableAppIncomingCallUpdated', onEnableAppIncomingCallUpdatedAction);
    CareAccountStore.on('onUsePanicRingtoneUpdated', onUsePanicRingtoneUpdatedAction);
    CareAccountStore.on('onEnableRingOnIncomingCallUpdated', onEnableRingOnIncomingCallUpdatedAction);
    CareAccountStore.on('onEnableBiometricsUpdated', onEnableBiometricsUpdatedAction);
    CareAccountStore.on('onCallTimeoutDelayUpdated', onCallTimeoutDelayUpdatedAction);
    CareAccountStore.on('onEnableFamilyAutoAnswerUpdated', onEnableFamilyAutoAnswerUpdatedAction);
    CareAccountStore.on('onEnableAutoAnswerForFamilyAppUpdated', onEnableAutoAnswerForFamilyAppUpdatedAction);
    CareAccountStore.on('onMobileCallResponseUpdated', onMobileCallResponseUpdatedAction);
    CareAccountStore.on('onWeightUnitUpdated', onWeightUnitUpdatedAction);
    CareAccountStore.on('onHoursFormatUpdated', onHoursFormatUpdatedAction);

    //Fetch Account Data after the listeners are set

    getAvailableStates();

    return () => {
      CareAccountStore.removeListener('onAccountNameUpdated', onAccountNameUpdatedAction);
      CareAccountStore.removeListener('onAccountLogoUpdated', onAccountLogoUpdatedAction);
      CareAccountStore.removeListener('on2FactorAuthUpdated', on2FactorAuthUpdatedAction);
      CareAccountStore.removeListener('onGetAccountData', onGetAccountDataAction);
      CareAccountStore.removeListener('onZipCodeUpdated', onZipCodeUpdatedAction);
      CareAccountStore.removeListener('onAccountGroupUpdated', onAccountGroupUpdatedAction);
      CareAccountStore.removeListener('onAccountLocaleUpdated', onAccountLocaleUpdatedAction);

      CareAccountStore.removeListener('onAccountCountryUpdated', onAccountCountryUpdatedAction);
      CareAccountStore.removeListener('onSendWelcomeEmailUpdated', onSendWelcomeEmailUpdatedAction);
      CareAccountStore.removeListener('onSendWelcomeSmsUpdated', onSendWelcomeSmsUpdatedAction);
      CareAccountStore.removeListener('onEnableMoveFamilyUpdated', onEnableMoveFamilyUpdatedAction);
      CareAccountStore.removeListener('onEnableControlIncomingCallUpdated', onEnableControlIncomingCallUpdatedAction);
      CareAccountStore.removeListener('onEnableAppIncomingCallUpdated', onEnableAppIncomingCallUpdatedAction);
      CareAccountStore.removeListener('onUsePanicRingtoneUpdated', onUsePanicRingtoneUpdatedAction);
      CareAccountStore.removeListener('onEnableRingOnIncomingCallUpdated', onEnableRingOnIncomingCallUpdatedAction);
      CareAccountStore.removeListener('onEnableBiometricsUpdated', onEnableBiometricsUpdatedAction);
      CareAccountStore.removeListener('onCallTimeoutDelayUpdated', onCallTimeoutDelayUpdatedAction);
      CareAccountStore.removeListener('onEnableFamilyAutoAnswerUpdated', onEnableFamilyAutoAnswerUpdatedAction);
      CareAccountStore.removeListener('onEnableAutoAnswerForFamilyAppUpdated', onEnableAutoAnswerForFamilyAppUpdatedAction);
      CareAccountStore.removeListener('onMobileCallResponseUpdated', onMobileCallResponseUpdatedAction);
      CareAccountStore.removeListener('onWeightUnitUpdated', onWeightUnitUpdatedAction);
      CareAccountStore.removeListener('onHoursFormatUpdated', onHoursFormatUpdatedAction);
    };
  }, []);

  const availableAccountGroups = [];
  if (accountGroupsArray?.length) {
    accountGroupsArray.map((group, key) => {
      availableAccountGroups.push(
        <option key={group.ID} value={group.ID}>
          {group.Name}
        </option>,
      );
    });
  }

  const filteredSelectedState = availableStates.filter((state) => state.ID === selectedState);
  const selectedStateToShow = filteredSelectedState[0]?.StateName ? filteredSelectedState[0]?.StateName : '';
  

  return (
    <div className={'row '}>
      <div className="col s4 m4 l4 xl4 no-margin">
        <div className="row vbottom-align no-margin top-separated">
          <div className="col s4 no-margin">
            <span className="nucleus-labels-small"> Account Name </span>
          </div>
          <div className="col s6 no-margin">
            <input ref={txtAccountName} type="text" className="validate nucleus-input-form-small accountTextFull" />
          </div>
          <div className="col s2 no-margin a-little-right">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={handleUpdateName}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>
        <div className="row vbottom-align no-margin top-separated">
          <div className="col s4 no-margin">
            <span className="nucleus-labels-small"> Account Logo URL </span>
          </div>
          <div className="col s6 no-margin">
            <input ref={txtLogoFile} type="text" className="validate nucleus-input-form-small accountTextFull" />
          </div>
          <div className="col s2 no-margin a-little-right">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={handleUpdateLogo}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s5 m5 l4 no-margin top-separated">
            <span className="nucleus-labels-small span-text-bottom">Account Group </span>
          </div>
          <div className="col s6 m6 l7 no-margin control-panels-container">
            <select ref={selectAccountGroup} className="browser-default accountSelect" onChange={handleChangeAccountGroup}>
              <option value={''} selected>
                {' '}
                {'None Group'}{' '}
              </option>
              {availableAccountGroups}
            </select>
          </div>
          <div className="col s1 m1 l1 no-margin "></div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s4 no-margin">
            <span className="nucleus-labels-small "> ZIP Code </span>
          </div>
          <div className="col s6 no-margin">
            <input ref={txtZipCode} type="text" className="validate nucleus-input-form-small accountTextFull" defaultValue={accountData?.ZipCode} />
          </div>
          <div className="col s2 no-margin a-little-right">
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={handleUpdateZipCode}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s4 no-margin top-separated">
            <span className="nucleus-labels-small span-text-bottom">Account Locale </span>
          </div>
          <div className="col s7 no-margin control-panels-container">
            <select ref={selectAccountLocale} className="browser-default accountSelect" onChange={handleChangeAccountLocale}>
              <option value="" disabled selected>
                {' '}
                {'Select a Locale'}{' '}
              </option>
              {availableAccountLocales}
            </select>
          </div>
          <div className="col s1 no-margin "></div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s5 m5 l4 no-margin top-separated">
            <span className="nucleus-labels-small span-text-bottom">Account Country </span>
          </div>
          <div className="col s6 m6 l7 no-margin control-panels-container">
            <select value={selectedCountry} className="browser-default accountSelect" onChange={handleChangeCountry}>
              <option value="" disabled selected>
                {' '}
                {'Select a Country'}{' '}
              </option>
              {availableCountries}
            </select>
          </div>
          <div className="col s1 m1 l1 no-margin "></div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s5 m5 l4 no-margin top-separated">
            <span className="nucleus-labels-small span-text-bottom">Account State </span>
          </div>
          <div className="col s6 m6 l7 no-margin control-panels-container">
            <select className="browser-default accountSelect" onChange={handleChangeState}>
              <option value="" disabled selected>
                {selectedState !== '' ? selectedStateToShow : 'Select a State'}
              </option>
              {availableStates.map((state, index) => (
                <option key={index} value={state.ID}>
                  {state.StateName}
                </option>
              ))}
            </select>
          </div>
          <div className="col s1 m1 l1 no-margin "></div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small"> Send Welcome Email</span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id={'checkWelcomeEmail' + accountId} checked={sendWelcomeEmail} onChange={handleSendWelcomeEmail} />
              <label htmlFor={'checkWelcomeEmail' + accountId}>&nbsp;</label>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small"> Send Welcome SMS</span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id={'checkWelcomeSms' + accountId} checked={!!sendWelcomeSmsValue} onChange={handleSendWelcomeSms} />
              <label htmlFor={'checkWelcomeSms' + accountId}>&nbsp;</label>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small"> Enable Moving Family Members</span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id={'checkEnableMoveFamily' + accountId} checked={!!enableMoveFamilyValue} onChange={handleEnableMoveFamily} />
              <label htmlFor={'checkEnableMoveFamily' + accountId}>&nbsp;</label>
            </span>
          </div>
        </div>
      </div>

      <div className="col s4 m4 l4 xl4 no-margin">
        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small"> Enable Incoming Call on Web Control</span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id="checkIncomingCall" checked={!!enableIncomingCallValue} onChange={handleEnableIncomingCall} />
              <label htmlFor="checkIncomingCall">&nbsp;</label>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small"> App Incoming Call Screen Notification for Family App </span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id="checkAppIncomingCallValue" checked={!!enableAppIncomingCallValue} onChange={handleEnableAppIncomingCall} />
              <label htmlFor="checkAppIncomingCallValue">&nbsp;</label>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small"> Web Control will Ring instead of Ding </span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id="checkRingIncomingCall" checked={!!ringIncomingCallValue} onChange={handleRingIncomingCall} />
              <label htmlFor="checkRingIncomingCall">&nbsp;</label>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small"> Use Panic Emergency Ringtone</span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id={'checkPanicRingtone' + accountId} checked={!!panicRingtoneValue} onChange={handleUsePanic} />
              <label htmlFor={'checkPanicRingtone' + accountId}>&nbsp;</label>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin bit-top-separated ">
          <div className="col s10 no-margin">
            <span className="nucleus-labels"> Call Timeout Delay (Seconds): </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input
              ref={txtCallTimeoutDelay}
              type="text"
              className="validate nucleus-input-form accountTextBitShort"
              defaultValue={accountData?.CallTimeoutDelayInSeconds}
              placeholder="Seconds"
            />
          </div>
          <div className="col s2 no-margin">
            &nbsp;
            <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left" onClick={handleUpdateCallTimeoutDelay}>
              <i className="material-icons nucleus-floating-btn-icon">save</i>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small">Enable Biometrics</span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id={'checkBiometrics' + accountId} checked={!!enableBiometricsValue} onChange={handleEnableBiometrics} />
              <label htmlFor={'checkBiometrics' + accountId}>&nbsp;</label>
            </span>
            <span className="nucleus-labels-special txt-red">*</span>
          </div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small">Enable Auto-Answer For Family Member</span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id={'checkFamilyAutoAnswer'} checked={!!enableFamilyAutoAnswerValue} onChange={handleFamilyAutoAnswer} />
              <label htmlFor={'checkFamilyAutoAnswer'}>&nbsp;</label>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small">Enable Auto-Answer For Family App</span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id={'checkFamilyAppAutoAnswer'} checked={enableFamilyAppAutoAnswerValue} onChange={handleFamilyAppAutoAnswer} />
              <label htmlFor={'checkFamilyAppAutoAnswer'}>&nbsp;</label>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="row no-margin">
            <div className="col s12 no-margin top-separated">
              <span className="nucleus-labels-small span-text-bottom">Mobile incoming call response action</span>
            </div>
          </div>
          <div className="row no-margin">
            <div className="col s12 no-margin control-panels-container">
              <select ref={selectMobileCallResponse} className="browser-default accountSelect" onChange={handleMobileCallResponse}>
                <option value="" disabled selected>
                  {' '}
                  {'Select a call response action'}{' '}
                </option>
                <option value="VideoCall">Place a video call</option>
                <option value="PatientProfile">Open patient's profile</option>
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s6 no-margin top-separated">
            <span className="nucleus-labels-small span-text-bottom">Weight Unit (Kg/Lb)</span>
          </div>
          <div className="col s5 no-margin control-panels-container">
            <select ref={selectWeightUnit} className="browser-default accountSelect" onChange={handleChangeAccountWeightUnit}>
              <option value="Kg"> {'Kg'} </option>
              <option value="Lb"> {'Lb'} </option>
            </select>
          </div>
          <div className="col s1 no-margin "></div>
        </div>
      </div>

      <div className="col s4 m4 l4 xl4 no-margin">
        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small"> Automatic Barge In in Call Request</span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input
                type="checkbox"
                className="filled-in"
                id={'checkAutomaticCallRequestBargeInCall' + accountId}
                checked={enableAutomaticCallRequestBargeInValue}
                onChange={handleEnableAutomaticCallRequestBargeIn}
              />
              <label htmlFor={'checkAutomaticCallRequestBargeInCall' + accountId}>&nbsp;</label>
            </span>
          </div>
        </div>

        <div className="row vbottom-align no-margin ">
          <div className="col s6 no-margin top-separated">
            <span className="nucleus-labels-small span-text-bottom">Time Format</span>
          </div>
          <div className="col s5 no-margin control-panels-container">
            <select ref={selectHoursFormat} className="browser-default accountSelect" onChange={handleChangeAccountHoursFormat}>
              <option value="12"> {'12 Hrs'} </option>
              <option value="24"> {'24 Hrs'} </option>
            </select>
          </div>
          <div className="col s1 no-margin "></div>
        </div>

        <div className="row vbottom-align no-margin top-separated">
          <div className="col s10 no-margin">
            <span className="nucleus-labels-small"> Enable 2 Factor Authentication</span>
          </div>
          <div className="col s2 no-margin">
            <span>
              &nbsp;
              <input type="checkbox" className="filled-in" id={'check2FactorAuthentication' + accountId} checked={enable2FactorAuthValue} onChange={handleEnable2FactorAuth} />
              <label htmlFor={'check2FactorAuthentication' + accountId}>&nbsp;</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
