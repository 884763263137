import React, { useEffect, useState } from 'react'
import ProviderNavTabs from './components/ProviderNavTabs'
import { UINucleusContentContainer } from '../../components/UI/NucleusContainer/Content'
import { UINucleusPageTitle } from '../../components/UI/NucleusPageTitle/Default'
import { ProviderStringsResponse, useNucleusProviders } from '../../context-api/nucleusProvidersContext/NucleusProvidersContext'
import { NucleusControlledWhiteBgInput } from './../../components/NucleusControlledInput';
import { useForm } from "react-hook-form";
import ProviderDetailsWrapper from './components/ProviderDetailsWrapper'
import { getProviderStrings, updateProviderStrings, updateProviderAccountGroupingStrings } from './utils/providerApi'

import { UICard, UINucleusContainer } from '../../components/UI';
import styled from 'styled-components';
import Message from '../../utils/Message';

const responseUpdateStringsMessage = {
  Tier1 : "1st tier (singular)",
  Tier1Plural : "1st tier (plural)",
  Tier2 : "2nd tier (singular)",
  Tier2Plural : "2nd tier (plural)",
  Tier3 : "3rd tier (singular)",
  Tier3Plural : "3rd tier (plural)"
}

const NucleusControlledWhiteBgInputForStrings = (props) => {
  return (
    <NucleusControlledWhiteBgInput
      {...props} 
      containerStyle={{
        marginBottom: 18,
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      inputStyle={{
        minHeight: 48
      }}
      labelStyle ={{
        fontSize: 18
      }}
    />
  );
}

const ProviderStrings = () => {
  const { state: { provider: { data, state } } } = useNucleusProviders()
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userRoleName = watch("User") || "";
  const userRoleNamePlural = watch("Users") || "";

  // AccountGroupingStrings
  const tier1 = watch("Tier1") || "";
  const tier1Plural = watch("Tier1Plural") || "";
  const tier2 = watch("Tier2") || "";
  const tier2Plural = watch("Tier2Plural") || "";
  const tier3 = watch("Tier3") || "";
  const tier3Plural = watch("Tier3Plural") || "";

  useEffect(() => {
    const fetchProviderStrings = async (providerId): Promise<ProviderStringsResponse> => {
      setIsLoading(true)
      const response = await getProviderStrings(providerId);
      setValue('User', response?.UserRoleName)
      setValue('Users', response?.UserRoleNamePlural)
      
      setValue('Tier1', response?.Tier1)
      setValue('Tier1Plural', response?.Tier1Plural)
      setValue('Tier2', response?.Tier2)
      setValue('Tier2Plural', response?.Tier2Plural)
      setValue('Tier3', response?.Tier3)
      setValue('Tier3Plural', response?.Tier3Plural)
      setIsLoading(false)
      return response;
    }
    fetchProviderStrings(data?.ID)
  }, [data?.ID]);

  const handleSaveProviderStrings = async ({ providerId }) => {
    setIsLoading(true);
    const formValues = {
      localeTag: "EN",
      userRoleName: watch('User'),
      userRoleNamePlural: watch('Users'),
    }
    const response = await updateProviderStrings({ providerId, form: formValues }) as {
      LocaleTag: string,
      ProviderID: string,
      UserRoleName: string,
      UserRoleNamePlural: string
    }
    if (response?.ProviderID) {
      setValue('User', response.UserRoleName)
      setValue('Users', response.UserRoleNamePlural)
      Message.show('Provider strings updated successfully')
    } else {
      Message.error('Something went wrong')
    }
    setIsLoading(false);
  }

  const handleSaveAccountGroupingStrings = async({providerId, stringId}) =>{
    setIsLoading(true);
    const formValues = {
      [stringId]: watch(stringId)
    }
    const response = await updateProviderAccountGroupingStrings({ providerId, stringsForm: formValues });
    if (response?.accountStringsConfigUpdateResult) {
      Message.show('String ' + responseUpdateStringsMessage[stringId] + ' updated');
    } else {
      Message.error('Something went wrong saving the string ' + responseUpdateStringsMessage[stringId])
    }
    setIsLoading(false);
  }

  const checkFieldsLength = (value: string) => {
    return value.length === 0
  }

  useEffect(() => {
    setIsLoading(state.loading);
  }, [state])

  return (
    <ProviderDetailsWrapper>
      <UINucleusContainer>
        <UINucleusPageTitle>
          Provider: {data?.Name}
        </UINucleusPageTitle>
        <ProviderNavTabs activeTab='provider-strings' />
        <UINucleusContentContainer style={{ flexDirection:'row', display:'inline-flex', paddingTop: 36, paddingBottom:36, columnGap:30 }}>
          <UIStringsCardContainer>
            <UICard
              title='Role Name Configurations'
              cardHeaderStyle={{
                fontFamily: "Barlow",
                fontSize: "20px",
                fontStyle: "italic",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left"
              }}
              style={{
                backgroundColor: "#EBF2FE",
                width: '28vw',
                paddingBottom: 20
              }}
            >
              <NucleusControlledWhiteBgInputForStrings
                disabledSave={checkFieldsLength(userRoleName)}
                label={"User:"}
                name={"User"}
                register={register("User", { required: true })}
                error={errors.User}
                clearErrors={clearErrors}
                setValue={setValue}
                onChange={(e) => {
                  setValue('User', e.target.value)
                }}
                saveHandler={() => {
                  if (data?.ID || isLoading) {
                    handleSaveProviderStrings({ providerId: data?.ID })
                  }
                }}
              />
              <NucleusControlledWhiteBgInputForStrings
                disabledSave={checkFieldsLength(userRoleNamePlural)}
                label={"Users:"}
                name={"Users"}
                register={register("Users", { required: true })}
                error={errors.User}
                clearErrors={clearErrors}
                setValue={setValue}
                onChange={(e) => {
                  setValue('Users', e.target.value)
                }}
                saveHandler={() => {
                  if (data?.ID || isLoading) {
                    handleSaveProviderStrings({ providerId: data?.ID })
                  }
                }}
              />
            </UICard>
          </UIStringsCardContainer>
          <UIStringsCardContainer>
            <UICard
              title='Account Group Name Configurations'
              cardHeaderStyle={{
                fontFamily: "Barlow",
                fontSize: "20px",
                fontStyle: "italic",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left"
              }}
              style={{
                backgroundColor: "#EBF2FE",
                width: '32vw',
                paddingBottom: 20
              }}
            >

              <NucleusControlledWhiteBgInputForStrings
                label={"1st tier (singular):"}
                name={"Tier1"}
                register={register("Tier1", { required: true })}
                setValue={setValue}
                onChange={(e) => {
                  setValue('Tier1', e.target.value)
                }}
                saveHandler={() => {
                  if (data?.ID || isLoading) {
                    handleSaveAccountGroupingStrings({ providerId: data?.ID, stringId:"Tier1" })
                  }
                }}
                error={errors.Tier1}
                clearErrors={clearErrors}
                disabledSave={checkFieldsLength(tier1)}
              />

              <NucleusControlledWhiteBgInputForStrings
                label={"1st tier (plural):"}
                name={"Tier1Plural"}
                register={register("Tier1Plural", { required: true })}
                setValue={setValue}
                onChange={(e) => {
                  setValue('Tier1Plural', e.target.value)
                }}
                saveHandler={() => {
                  if (data?.ID || isLoading) {
                    handleSaveAccountGroupingStrings({ providerId: data?.ID, stringId:"Tier1Plural" })
                  }
                }}
                error={errors.Tier1Plural}
                clearErrors={clearErrors}
                disabledSave={checkFieldsLength(tier1Plural)}
              />

              <NucleusControlledWhiteBgInputForStrings
                label={"2nd tier (singular):"}
                name={"Tier2"}
                register={register("Tier2", { required: true })}
                setValue={setValue}
                onChange={(e) => {
                  setValue('Tier2', e.target.value)
                }}
                saveHandler={() => {
                  if (data?.ID || isLoading) {
                    handleSaveAccountGroupingStrings({ providerId: data?.ID, stringId:"Tier2" })
                  }
                }}
                error={errors.Tier2}
                clearErrors={clearErrors}
                disabledSave={checkFieldsLength(tier2)}
              />

              <NucleusControlledWhiteBgInputForStrings
                label={"2nd tier (plural):"}
                name={"Tier2Plural"}
                register={register("Tier2Plural", { required: true })}
                setValue={setValue}
                onChange={(e) => {
                  setValue('Tier2Plural', e.target.value)
                }}
                saveHandler={() => {
                  if (data?.ID || isLoading) {
                    handleSaveAccountGroupingStrings({ providerId: data?.ID, stringId:"Tier2Plural" })
                  }
                }}
                error={errors.Tier2Plural}
                clearErrors={clearErrors}
                disabledSave={checkFieldsLength(tier2Plural)}
              />


              <NucleusControlledWhiteBgInputForStrings
                label={"3rd tier (singular):"}
                name={"Tier3"}
                register={register("Tier3", { required: true })}
                setValue={setValue}
                onChange={(e) => {
                  setValue('Tier3', e.target.value)
                }}
                saveHandler={() => {
                  if (data?.ID || isLoading) {
                    handleSaveAccountGroupingStrings({ providerId: data?.ID, stringId:"Tier3" })
                  }
                }}
                error={errors.Tier3}
                clearErrors={clearErrors}
                disabledSave={checkFieldsLength(tier3)}
              />

              <NucleusControlledWhiteBgInputForStrings
                label={"3rd tier (plural):"}
                name={"Tier3Plural"}
                register={register("Tier3Plural", { required: true })}
                setValue={setValue}
                onChange={(e) => {
                  setValue('Tier3Plural', e.target.value)
                }}
                saveHandler={() => {
                  if (data?.ID || isLoading) {
                    handleSaveAccountGroupingStrings({ providerId: data?.ID, stringId:"Tier3Plural" })
                  }
                }}
                error={errors.Tier3Plural}
                clearErrors={clearErrors}
                disabledSave={checkFieldsLength(tier3Plural)}
              />
            </UICard>
          </UIStringsCardContainer>
        </UINucleusContentContainer>
      </UINucleusContainer>
    </ProviderDetailsWrapper>
  )
}

const UIStringsCardContainer = styled.div`
  display:block;
`;

export default ProviderStrings