import React, { useMemo } from 'react';
import { useState, useEffect, useRef } from 'react';
import {
  getExternalAppsListThunk,
  getExternalAppsUsageReportDataThunk,
} from '@nucleus-care/nucleuscare-backend-client';
import { useSelector, useDispatch } from 'react-redux';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import NucleusTable from '../../components/NucleusTable';
import NucleusButtonV2 from '../../components/NucleusButtonV2';
import { SpinnerCircular } from 'spinners-react';

const formatTotalMinutes =(totalMinutes) =>{
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let result = '';
  try {
    if (hours > 0) {
      result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    }
    if (minutes > 0) {
      if (result.length > 0) {
          result += ' ';
      }
      result += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    }
  } catch (error) {
    result = totalMinutes.toString();
    console.log("getExternalAppsUsageReportDataThunk formatTotalMinutes error");
  }
  return result;
}

const AppsUsageReport = () => {
  const dispatch = useDispatch();
  const appsUsageReport = useSelector((state) => {
    return state.reports.apps_usage;
  });
  const externalAppsStore = useSelector((state) => {
    return state.accountExternalApps.appsConfig;
  });
  const [stateLoading, setStateLoading] = useState(true);
  const dateParamFormat = 'YYYY-MM-DD HH:mm:ss';
  const [stateFromDate, setStateFromDate] = useState(null);
  const [stateToDate, setStateToDate] = useState(null);

  const fromDateTimePickerRef = useRef('');
  const toDateTimePickerRef = useRef('');

  const [stateExportFileName, setStateExportFileName] =
    useState('AppsUsageReport_');

  const columns = useMemo(() => {
    const returnCols = [
      {
        Header: 'Name',
        accessor: 'Name',
        Cell: ({ cell: { row } }) => {
          const original = row.original;
          const app = externalAppsStore?.apps?.find(
            (app) => app.Name === original.Name,
          );
          const iconUrl = app ? app.IconUrl : '';
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <img src={iconUrl} style={{ height: 60, borderRadius: 10 }} />
              <span style={{ marginLeft: 10 }}>{original.Name}</span>
            </span>
          );
        },
        key: 'Name',
      },
      {
        Header: 'Resident Used',
        accessor: 'ResidentUsed',
        key: 'ResidentUsed',
      },
      {
        Header: 'Total Usage',
        accessor: 'TotalUsage',
        key: 'TotalUsage',
      },
      {
        Header: 'Total Time Used',
        accessor: 'TotalTime',
        key: 'TotalTime',
      },
    ];
    return returnCols;
  }, [appsUsageReport.data, externalAppsStore?.appsConfig?.apps]);

  const tableData = useMemo(()=>{
    return appsUsageReport.data.map((row)=>{
      return {
        ...row,
        TotalTime: formatTotalMinutes(row.TotalMinutes)
      }
    })
  },[appsUsageReport.data])

  const appsUsageReportRef = useRef([]);
  appsUsageReportRef.current = tableData;

  const csvFileHeadersRef = useRef([]);
  csvFileHeadersRef.current = [
    { label: 'Name', key: 'Name' },
    { label: 'Resident Used', key: 'ResidentUsed' },
    { label: 'Total Usage', key: 'TotalUsage' },
    { label: 'Total Time Used', key: 'TotalTime' },
  ];

  useEffect(() => {
    if (!externalAppsStore.getPending && !appsUsageReport.loading) {
      setStateLoading(false);
    }
  }, [externalAppsStore.getPending, appsUsageReport.loading]);

  useEffect(() => {
    setStateLoading(true);
    dispatch(getExternalAppsListThunk());
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);
    let currentDate = new Date();
    setStateFromDate(fromDate);
    setStateToDate(currentDate);
    refreshReport(fromDate, currentDate);
  }, []);

  const onFromDateChange = (date, value) => {
    setStateFromDate(value);
  };
  const onToDateChange = (date, value) => {
    setStateToDate(value);
  };

  const refreshReport = (fromDate, toDate) => {
    let fromDateParam = moment(fromDate).utc().format(dateParamFormat);
    let toDateParam = moment(toDate).utc().add(1, 'minute').format(dateParamFormat);
    dispatch(
      getExternalAppsUsageReportDataThunk({
        startDate: fromDateParam,
        endDate: toDateParam,
      }),
    );
    let fromExportParam = moment(fromDate).format('MM_DD_YYYY');
    let toExportParam = moment(toDate).format('MM_DD_YYYY');

    let exportCSVFileName =
      'AppsUsageReport_' +
      fromExportParam +
      '_to_' +
      toExportParam +
      '.csv';
    setStateExportFileName(exportCSVFileName);
  };

  return (
    <div class="nucleus-tools-container">
      <div>
        <p class="nucleus-tools-page-title">
          <b>Reports</b>
        </p>
      </div>
      <div
        style={{
          backgroundColor: '#ebf2fe',
          minHeight: '75vh',
          padding: 10,
          paddingBottom: 10,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <div>
          <p style={{ color: '#0A313F', fontSize: 20 }}>
            <b>Apps Usage Report</b>
          </p>
        </div>
        <div
          class=""
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            paddingBottom: 20,
          }}
        >
          <div style={{}}>
            <span class="" style={{ marginRight: 28, color: '#0092FF' }}>
              From
            </span>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                style={{
                  backgroundColor: 'white',
                  padding: 10,
                  borderRadius: 10,
                }}
                ref={fromDateTimePickerRef}
                variant="dialog"
                class="browser-default"
                value={stateFromDate}
                onChange={onFromDateChange}
                onError={console.warn}
                onClose={() => {}}
                format="MM/DD/YYYY hh:mm A"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div style={{ fontSize: 28, fontWeight: 'bold', margin: 20 }}>_</div>
          <div style={{}}>
            <span class="" style={{ marginRight: 28, color: '#0092FF' }}>
              To
            </span>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                style={{
                  backgroundColor: 'white',
                  padding: 10,
                  borderRadius: 10,
                }}
                ref={toDateTimePickerRef}
                variant="dialog"
                class="browser-default"
                value={stateToDate}
                onChange={onToDateChange}
                onError={console.warn}
                format="MM/DD/YYYY hh:mm A"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div style={{ marginLeft: 55 }}>
            <NucleusButtonV2
              onClick={() => refreshReport(stateFromDate, stateToDate)}
              styleType="outline"
            >
              Apply
            </NucleusButtonV2>
          </div>
        </div>
        <div style={{ height: '55vh', overflowY: 'scroll', borderRadius: 10 }}>
          {stateLoading ? (
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SpinnerCircular
                color="#2096F3"
                secondaryColor="rgba(0,0,0,0.16)"
                size="50"
                thickness="100"
              />
            </div>
          ) : (
            <NucleusTable
              stickyThead
              tableStyles={{
                backgroundColor: 'white',
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 10,
              }}
              columns={columns}
              data={tableData}
            />
          )}
        </div>
      </div>
      <div
        class=""
        style={{
          backgroundColor: '#ebf2fe',
          padding: 10,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          justifyContent: 'end',
          display: 'flex',
        }}
      >
        {tableData && tableData.length > 0 && (
          <CSVLink
            headers={csvFileHeadersRef.current}
            data={tableData}
            filename={stateExportFileName}
            target="_blank"
          >
            <NucleusButtonV2 styleType="outline">Export to CSV</NucleusButtonV2>
          </CSVLink>
        )}
      </div>
    </div>
  );
};

export default AppsUsageReport;
