import React, { useCallback, useEffect, useState } from 'react'
import ProviderNavTabs from './components/ProviderNavTabs'
import { UIModal, UINucleusContainer, useModal } from '../../components/UI'
import NucleusTable from '../../components/NucleusTable';
import NucleusSearchInput from '../../components/NucleusSearchInput';
import { UINucleusContentContainer } from '../../components/UI/NucleusContainer/Content'
import { UINucleusPageTitle } from '../../components/UI/NucleusPageTitle/Default'
import ProviderDetailsWrapper from './components/ProviderDetailsWrapper'
import { IProviderAccount, useNucleusProviders } from '../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { addProviderAccounts, getProviderAccounts, removeProviderAccount } from './utils/providerApi';
import Message from '../../utils/Message';
import { useForm } from 'react-hook-form';
import { SpinnerCircular } from 'spinners-react';
import SelectProviderAccounts from './components/SelectProviderAccounts';
import styled from 'styled-components'

const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;
const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;
const BoldNote = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
`;
const Name = styled.p`
  color: #0a313f;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;

const RedText = styled.p`
  font-family: Fira Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: right;
`;


const ProviderAccounts = () => {
  const fastFilter = true
  const { dispatch, state: { provider: { assignedAccounts, data, state: { error, loading, success } } } } = useNucleusProviders()
  const [providerAccountsData, setProviderAccountsData] = React.useState<IProviderAccount[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const { register, setValue, watch, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedAccountsIDs, setSelectedAccountsIDs] = useState<string[]>([])
  const [selectedInvalidAccounts, setSelectedInvalidAccounts] = useState<number>(0)
  const [accountToRemove, setAccountToRemove] = useState<IProviderAccount | null>(null)
  const {
    closeModal: closeRemoveModal,
    isOpen: isRemoveModalOpen,
    openModal: openRemoveModal,
    toggleModal: toggleRemoveModal
  } = useModal()
  const {
    closeModal,
    isOpen,
    openModal,
    toggleModal
  } = useModal()
  const columns = [
    {
      Header: "Account Name",
      accessor: "AccountName",
    },
    {
      Header: "Residents",
      accessor: "PatientCount",
      centerAlign: true,
    },
    {
      Header: "Total Devices",
      accessor: "DeviceCount",
      centerAlign: true,
    },
    {
      Header: "Online Devices",
      accessor: "OnlineDeviceCount",
      centerAlign: true,
    },
    {
      Header: "Users",
      accessor: "UsersCount",
      centerAlign: true,
    },
    {
      Header: '',
      accessor: 'ID',
      disableSortBy: true,
      Cell: ({ row, cell }) => {
        if (!row.original.Protected) {
          return (
            <span
              onClick={(event) => {
                event.stopPropagation();
                setAccountToRemove(row.original as IProviderAccount)
                openRemoveModal()
              }}
              className="txt-red" style={{ fontFamily: 'Fira Sans' }
              }>
              remove
            </span>
          );
        } else {
          return <img src="./img/lock.svg" />;
        }
      },
    },
  ];
  async function fetchProviderAccounts(providerId: string) {
    setIsLoading(true)
    dispatch({
      type: 'fetch_provider_accounts', payload: {
        loading: true,
        error: false,
        success: false,
        assignedAccounts: assignedAccounts || [],
      }
    })
    const providerAccounts = await getProviderAccounts(providerId)
    setProviderAccountsData(providerAccounts)
    dispatch({
      type: 'fetch_provider_accounts', payload: {
        loading: false,
        error: false,
        success: true,
        assignedAccounts: providerAccounts
      }
    })
    setIsLoading(false)
  }
  useEffect(() => {
    if (!data?.ID) return
    fetchProviderAccounts(data?.ID)
  }, [data?.ID])

  const handleSubmitSearch = (data) => {
    setSearchText(data.search);
  };
  const addAccounts = useCallback(async () => {
    if (selectedAccountsIDs.length <= 0) return
    if (selectedInvalidAccounts > 0) return
    setIsLoading(true);
    if (data?.ID) {
      try {
        const response = await addProviderAccounts(data?.ID, selectedAccountsIDs)
        if (response) {
          Message.show(`Account${response > 1 ? 's' : ''} added successfully`)
          closeModal()
          setSelectedAccountsIDs([])
          reset();
          setSearchText("")
          fetchProviderAccounts(data.ID)
        } else {
          Message.show(`Error adding account${selectedAccountsIDs.length > 1 ? 's' : ''}`);
        }
      } catch (error) {
        console.log('error', JSON.stringify((error as any)?.response?.data))
        if ((error as any)?.response?.data?.code === 'ACCOUNT_ASSIGNED_TO_PROVIDER') {
          if (selectedAccountsIDs.length > 1) {
            Message.show(`Some of the selected accounts have already been assigned to a provider`)
          } else {
            Message.show(`Selected account is already assigned to a provider`)
          }
        } else if ((error as any)?.response?.data?.code === 'ADDING_ACCOUNTS_TO_PROVIDER_WITH_NO_STATE') { 
          Message.show('Error : ' + (error as any)?.response?.data?.message)
        }
        else {
          Message.show(`Error adding account${selectedAccountsIDs.length > 1 ? 's' : ''}`)
        }
      }
    }
    setIsLoading(false);
  }, [selectedAccountsIDs]);

  const removeAccounts = useCallback(async () => {
    if (!accountToRemove) return
    setIsLoading(true);
    if (data?.ID && accountToRemove.AccountID) {
      const success = await removeProviderAccount(data?.ID, accountToRemove.AccountID)
      if (!!success) {
        Message.show(`Account${!!(success > 1) ? 's' : ''} removed successfully`)
        closeRemoveModal()
        fetchProviderAccounts(data.ID)
      } else {
        Message.show(`Error removing account${!!(success.length > 1) ? 's' : ''}`)
      }
    }
    setIsLoading(false);
  }, [accountToRemove])
  return (
    <ProviderDetailsWrapper>
      <UINucleusContainer>
        <UINucleusPageTitle>
          Provider: {data?.Name}
        </UINucleusPageTitle>
        <ProviderNavTabs activeTab='provider-accounts' />
        <UINucleusContentContainer style={{ paddingTop: 30 }}>
          <form onSubmit={handleSubmit(handleSubmitSearch)}>
            <NucleusSearchInput
              name='search'
              value={watch('search')}
              register={register('search', {
                onChange: () => {
                  setValue('search', watch('search'))
                  if (fastFilter) {
                    setSearchText(watch('search'))
                  }
                }
              })}
              placeholder='Filter by Account Name'
            />
          </form>
          {isLoading ? <UINucleusContentContainer centerContent>
            <SpinnerCircular
              color="#2096F3"
              secondaryColor="rgba(0,0,0,0.16)"
              size="50"
              thickness={100}
            />
          </UINucleusContentContainer> : <NucleusTable
            columns={columns}
            data={providerAccountsData.filter(data => data.ProviderName.toLowerCase().includes(searchText.toLowerCase()) || data.AccountName.toLowerCase().includes(searchText.toLowerCase()))}
          />}
        </UINucleusContentContainer>
        <UIModal
          actions={[
            {
              label: 'Cancel',
              onClick: closeModal,
              buttonVariant: 'secondary',
              disabled: isLoading
            },
            {
              label: 'Add',
              onClick: addAccounts,
              buttonVariant:  (selectedAccountsIDs.length <= 0 || selectedInvalidAccounts > 0) ? 'disabled' : 'primary',
              disabled: isLoading || (selectedAccountsIDs.length <= 0 || selectedInvalidAccounts > 0)
            }

          ]}
          close={closeModal}
          isOpen={isOpen}
          title={'Add Accounts'}
          size='large'
          style={{
            minWidth: '60%',
          }}
        >
          <SelectProviderAccounts 
            setSelectedIDs={setSelectedAccountsIDs} 
            setInvalidAccounts={setSelectedInvalidAccounts} />
        </UIModal>
        <UIModal
          actions={[
            {
              label: 'Cancel',
              onClick: closeRemoveModal,
              buttonVariant: 'secondary',
              disabled: isLoading
            },
            {
              label: 'Proceed',
              onClick: removeAccounts,
              buttonVariant: 'primary',
              disabled: isLoading
            }

          ]}
          close={closeRemoveModal}
          isOpen={isRemoveModalOpen}
          title={''}
          size='medium'
        >
          <Title>WARNING!</Title>
          <Note>You are about to remove</Note>
          <Name>Account: {accountToRemove?.AccountName}</Name>
          <Note>from Provider: {data?.Name}</Note>
          <Note>Would you like to proceed?</Note>
        </UIModal>
        <div className="fixed-action-btn btn-add-new">
          <a
            className="btn-floating btn-large waves-effect waves-light orange tooltipped"
            data-position="left"
            data-delay="50"
            data-tooltip="New Account"
            onClick={openModal}
          >
            <i className="material-icons">add</i>
          </a>
          &nbsp;&nbsp;
        </div>
      </UINucleusContainer>
    </ProviderDetailsWrapper>
  )
}

export default ProviderAccounts