import nucleusNetwork from './NucleusNetwork'
import { setAuthorizationToken as setAuthorizationTokenAuth } from './auth-client'
import { setAuthorizationToken as setAuthorizationTokenBackend } from '@nucleus-care/nucleuscare-backend-client'
import httpLegacyClient from './httpLegacyClient'

export function setTokenToHttpClients (token) {
  nucleusNetwork.setToken(token)
  httpLegacyClient.setAuthorizationToken(token)
  setAuthorizationTokenAuth(token)
  setAuthorizationTokenBackend(token)
}

export function getToken () {
  const token = localStorage.getItem('nucleusTokenCs')
  setTokenToHttpClients(token)
}
