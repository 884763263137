import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SpinnerCircular } from "spinners-react";
import { AccountApi } from "../AccountApi";
import Message from "../../../utils/Message";
import AccountConfigCheckBoxLine from './AccountConfigCheckBoxLine';
import CareAccountStore from "../../../stores/CareAccountStore";

type AccountThemeSettingsProps = {
  accountId: string;
};

type AccountPartialData = {
  ID?: number;
  HomeButtonEnabled?: boolean;
  EnableTalkToADoctorNow?: boolean;
  EnableScheduleADoctor?: boolean;
};

const AccountThemeSettings = (props:AccountThemeSettingsProps) =>{
	console.log("AccountThemeSettings");
	const [loading, setLoading] = useState(false);
	const [accountThemeSettings, setAccountThemeSettings] = useState<AccountPartialData>({});

	useEffect(() => {
    if(props.accountId){
      getAccountGeneralData();
    }
  }, [props.accountId]);

	const getAccountGeneralData = async () => {

		setLoading(true);
		const response = await AccountApi.getAccountData({ accountId : props.accountId });
		setLoading(false);
		if (response?.data) {
			setAccountThemeSettings({
				ID: response?.data?.ID,
				HomeButtonEnabled: response?.data?.HomeButtonEnabled,
				EnableTalkToADoctorNow: response?.data?.EnableTalkToADoctorNow,
				EnableScheduleADoctor: response?.data?.EnableScheduleADoctor
			});
		} else {
			Message.warning(response?.error || 'Error loading Account theme settings, please try again.');
			setAccountThemeSettings({});
		}
  }


  const handleAccountHomeButton=(value: boolean)=> {
    CareAccountStore.updateAccountHomeButtonEnabled(props.accountId, { homeButtonEnabled: value }, (response) => {
      if (response.ok) {
        Message.show('Account Home Button ' + (value ? 'enabled' : 'disabled'));
      } else {
        Message.show('Error updating Account Home Button');
      }
    });
  }

  const handleTalkToADoctor=(value: boolean)=> {
    CareAccountStore.updateAccountEnableTalkToADoctor(props.accountId, { enableTalkToADoctorNow: value }, (response) => {
      if (response.ok) {
        Message.show('Account Enable Talk to a Doctor ' + (value ? 'enabled' : 'disabled'));
      } else {
        Message.show('Error updating Account Enable Talk to a Doctor');
      }
    });
  }

  const handleScheduleADoctor=(value: boolean) =>{
    CareAccountStore.updateAccountEnableScheduleADoctor(props.accountId, { enableScheduleADoctor: value }, (response) => {
      if (response.ok) {
        Message.show('Account Enable Schedule a Doctor ' + (value ? 'enabled' : 'disabled'));
      } else {
        Message.show('Error updating Account Enable Schedule a Doctor');
      }
    });
  }

 
	if (loading){
		return(
			<LoadingWrapper>
				<SpinnerCircular 
					color="#2096F3" 
					secondaryColor="rgba(0,0,0,0.16)" 
					size="30" 
					thickness={100} 
				/>
			</LoadingWrapper>
		)
	}

	return(
		<MainContainer>
			<div style={{display:'flex', flex:1}}>
				<div>
					<AccountConfigCheckBoxLine 
						id="accountHomeButton" 
						label={'Home button'} 
						state={accountThemeSettings.HomeButtonEnabled} 
						onChangeAction={handleAccountHomeButton} 
					/>
					<AccountConfigCheckBoxLine 
						id="talkToADoctor" 
						label={'Talk to a doctor'} 
						state={accountThemeSettings.EnableTalkToADoctorNow} 
						onChangeAction={handleTalkToADoctor} 
					/>
					<AccountConfigCheckBoxLine 
						id="scheduleADoctor" 
						state={accountThemeSettings.EnableScheduleADoctor} 
						label={'Schedule a doctor'} 
						onChangeAction={handleScheduleADoctor} 
					/>
				</div>
			</div>
			<div style={{display:'flex', flex:1}}></div>
			<div style={{display:'flex', flex:1}}></div>
		</MainContainer>
	)
}

export default AccountThemeSettings;

const MainContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;
const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	height: 240px;
`;