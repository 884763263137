import React from "react";
import { GoToArrow } from "../NucleusTable/assets/NucleusTableSVGs";

const NucleusWhiteBgInput = ({
  noLabel = false,
  register,
  name,
  error,
  id,
  label,
  clearErrors,
  setValue,
  flexDirection,
  containerStyle,
  inputContainerStyle,
  inputStyle,
  labelStyle,
  mainContainerStyle,
  showPasswordAction,
  saveHandler,
  saveText,
  saveStyle,
  goToAction,
  disabled = false,
  disabledSave = false,
  editable = true,
  ...props
}) => {
  const disabledHandler = () => {}
  return (
    <div style={{ minHeight: 70, ...mainContainerStyle }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          ...containerStyle,
        }}
      >
        {!noLabel && (
          <label
            style={{
              minWidth: 130,
              textAlign: "start",
              fontSize: 16,
              color: "#0A313F",
              fontFamily: "Barlow",
              marginBottom: '2%',
              ...labelStyle,
            }}
            htmlFor={name}
            id={id}
          >
            {label}
          </label>
        )}
        <div
          style={{
            backgroundColor: "#fff",
            width: "100%",
            borderRadius: 8,
            padding: "0px 10px 0px 10px",
            border: error ? "1px solid #FE3824" : "none",
            display: "flex",
            flexDirection: "row",
            ...inputContainerStyle,
          }}
        >
          <style>
            {`
            .white-bg-input:focus {
              border-bottom: 0 !important;
              box-shadow: none !important;
              padding-bottom: 0px !important;
              height: 2rem !important;
              margin: 0 !important;
            }
            .white-bg-input {
              border-bottom: 0 !important;
              box-shadow: none !important;
              padding-bottom: 0px !important;
              height: 2rem !important;
              margin: 0 !important;
            }
            `}
          </style>
          <input
            disabled={disabled}
            onChange={(e) => {
              if (!disabled) return;
              const value = e.target.value;
              if (error) {
                clearErrors(name);
              }
              setValue(value);
            }}
            placeholder="Empty"
            className="white-bg-input"
            name={name}
            id={id}
            style={{
              opacity: disabled ? 0.5 : 1,
              ...inputStyle,
            }}
            {...register}
            {...props}
          />
          {showPasswordAction && (
            <img
              src="img/view_password.png"
              class="nucleus-link"
              style={{ width: 24, height: 16, marginLeft: -24, marginTop: 8 }}
              onClick={showPasswordAction}
            />
          )}
          {!!goToAction && (
            <span
              onClick={goToAction}
              role="button"
              style={{
                alignSelf: "center",
                cursor: "pointer",
                color: "#0092FF",
                textAlign: "center",
                fontWeight: "bold",
                marginLeft: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                ...saveStyle,
              }}
            >
              GO
              <GoToArrow className="nucleus-ml-1" />
            </span>
          )}
          {!!saveHandler && (
            <span
              onClick={disabledSave ? disabledHandler : saveHandler}
              role="button"
              style={{
                alignSelf: "center",
                cursor: "pointer",
                color: disabledSave ? "#D8F0FE" : "#0092FF",
                textAlign: "center",
                fontWeight: "bold",
                marginLeft: 10,
                ...saveStyle,
              }}
            >
              {saveText ? saveText : "Save"}
            </span>
          )}
        </div>
      </div>
      {error && (
        <p
          style={{
            color: "#FE3824",
            fontWeight: "bold",
            marginTop: 5,
            textAlign: "end",
          }}
        >
          {error.message}
        </p>
      )}
    </div>
  );
};

export default NucleusWhiteBgInput;
