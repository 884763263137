import React from "react";
import MDMNavTabs from "../components/MDMNavTabs";
import MdmDevicesTable from "../MdmDevicesTable";

const MDMDevicesPage = () => {
  return (
    <div class="nucleus-tools-container">
      <div>
        <p class="nucleus-tools-page-title">
          <div className="row a-bit-lower left-align">
            <MDMNavTabs activeTab="devices" type="main" />
            <div class="nucleus-news-separator">&nbsp;</div>
          </div>
        </p>
      </div>
      <style>
        {`
          .nucleus-table-container-s-90{
            /*overflow: scroll;*/
            overflow-y: scroll;
          }
        `}
      </style>
      <div class="row nucleus-fixed-container nucleus-table-container-s-90">
        <MdmDevicesTable />
      </div>
    </div>
  );
};

export default MDMDevicesPage;
