import { useSelector } from 'react-redux';
import { createSelector } from 'reselect'

export function useUserMeSelector () {
  return useSelector(({ me }) => {
    return me && me.data
  })
}

const getUserCreated = createSelector(
  ({ me }) => {
    return me && me.data
  },
  (me) => {
    if (!me) return false
    return me.Created
  })

export function useUserCreatedSelector () {
  return useSelector(getUserCreated)
}
