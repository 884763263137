import React, { useEffect, useState } from "react";
import NucleusSearchInput from "../../../components/NucleusSearchInput";
import MDMNavTabs from "../components/MDMNavTabs";
import NucleusModalButton from "../../../components/NucleusModal/NucleusModalButton";
import NucleusModal from "../../../components/NucleusModal";
import MDMEnterprisesTable from "./components/MDMEnterprisesTable";
import { createEnterprise, updateEnterprise, fetchEnterprisesValues } from '@nucleus-care/nucleuscare-backend-client';
import { useDispatch, useSelector } from "react-redux";
const MDMEnterprises = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [formTitle, setFormTitle] = useState("Add a New Enterprise");
  const [OrganizationName, setOrganizationName] = useState("");
  const [OrganizationID, setOrganizationID] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [saved, setSaved] = useState(false);
  const [enterpriseIdToUpdate, setEnterpriseIdToUpdate] = useState("");
  const { enterprisesOptions } = useSelector(({ mdmPolicy }) => mdmPolicy.data);
  useEffect(() => {
    dispatch(fetchEnterprisesValues())
  }, []);
  useEffect(() => {
    console.log("modal updated")
    dispatch(fetchEnterprisesValues())
  }, [saved]);
  const handleInputs = (e) => {
    if(e.target.id === 'OrganizationName') {
        setOrganizationName(e.target.value);
    } else {
        setOrganizationID(e.target.value);
    }
  }
  const handleSave = () => {
    if(!isUpdate) {
        dispatch(createEnterprise({enterpriseData: {OrganizationName, OrganizationID}}));
    } else {
        dispatch(updateEnterprise({enterpriseId: enterpriseIdToUpdate, payload: {enterpriseData: {OrganizationName, OrganizationID}}}));
    }
    setIsOpen(false);
    setIsUpdate(false);
    setSaved(true);
    setOrganizationName("");
    setOrganizationID("");
    setFormTitle("Add a New Enterprise");
  }
  const handleCancel = () => {
    setIsOpen(false);
    setIsUpdate(false);
    setOrganizationName("");
    setOrganizationID("");
    setFormTitle("Add a New Enterprise");
  }
  const handleOpenModal = () => {
    setIsOpen(true);
    setSaved(false);
  } 
  const handleRowClick = (info) => {
    console.log("clicked row:", info)
    const {ID, OrganizationID, OrganizationName} = info.row.original;
    setEnterpriseIdToUpdate(ID);
    setSaved(false);
    setIsUpdate(true);
    setOrganizationName(OrganizationName);
    setOrganizationID(OrganizationID);
    setIsOpen(true);
    setFormTitle("Edit an Existing Enterprise");
  }
  return (
    <div class="nucleus-tools-container">
      <div>
        <p class="nucleus-tools-page-title">
          <div className="row a-bit-lower left-align">
            <MDMNavTabs activeTab="enterprises" type="main" />
            <div class="nucleus-news-separator">&nbsp;</div>
          </div>
        </p>
        <div class="row no-margin">
          <NucleusSearchInput placeholder={"Filter by Name / Organization ID"} />
        </div>
        <br />
      </div>
      <div class="row nucleus-fixed-container nucleus-table-container-s-90">
        <style>
            {`
                .nucleus-table-container-s-90{
                    /*overflow: scroll;*/
                    overflow-y: scroll;
                    }
            `}
        </style>
        <MDMEnterprisesTable handleRowClick={handleRowClick} enterprisesOptions={enterprisesOptions} />
      </div>
        {isOpen && (
            <NucleusModal setIsOpen={handleCancel} backgroundColor="#E5E5E5" width={400}>
                <div className="enterpriseFormTitleContainer">
                    <p className="enterpriseFormTitleTxt">{formTitle}</p>
                </div>
                <div className="enterpriseFormContainer">
                    <div className="enterpriseInputContainer">
                        <div className="enterpriseModalLabelContainer"><label className="enterpriseInputTxt">Name: </label></div>
                        <div className="enterpriseModalInputContainer">
                            <input type="text" placeholder="Name" className="enterpriseInput" id="OrganizationName" onChange={handleInputs}  value={OrganizationName}/>
                        </div>
                    </div>
                    <div className="enterpriseInputContainer">
                        <div className="enterpriseModalLabelContainer"><label className="enterpriseInputTxt">Organization ID: </label></div>
                        <div className="enterpriseModalInputContainer">
                            <input type="text" placeholder="Organization ID" className="enterpriseInput" id="OrganizationID" onChange={handleInputs} value={OrganizationID} />
                        </div>
                    </div>
                </div>
                <div className="modalActions">
                    <div className="actionsContainer mt-30">
                        <button className="deleteBtnEnterpriseModal" onClick={handleCancel}>
                        CANCEL
                        </button>
                        <button className="cancelBtnEnterpriseModal" onClick={handleSave}>
                        { !isUpdate ? "ADD" : "SAVE" }
                        </button>
                    </div>
                </div>
            </NucleusModal>
        )}
        <NucleusModalButton
            dataTooltip={"New Enterprise"}
            id={"enterpriseModal"}
            handleOpenModal={handleOpenModal}
        />
    </div>
  );
};

export default MDMEnterprises;