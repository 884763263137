import React, { useEffect, useState } from "react";
import NucleusModal from "../../../../components/NucleusModal";
import NucleusButton from "../../../../components/NucleusButton";
import { useDispatch, useSelector } from "react-redux";
import NucleusSearchInput from "../../../../components/NucleusSearchInput";
import Message from "../../../../utils/Message";
import NucleusTable from "../../../../components/NucleusTable";
import {
  fetchMdmAccountByID,
  assignPolicyTemplatesToMdmAccount,
  resetAssignPolicyTemplatesToMdmAccount,
} from '@nucleus-care/nucleuscare-backend-client';
import { SpinnerCircular } from "spinners-react/lib/esm";
import { useForm } from "react-hook-form";
import { Tooltip } from "@mui/material";
// import { useForm } from "react-hook-form";

const MdmAccountSelectPoliciesModal = ({
  mdmAccountId,
  loading,
  isOpen,
  setIsOpen,
  policyTemplateIdToAccountPolicyDataDict,
  setPolicyTemplateIDToAccountPolicyDataDict,
  generateDependentData,
}) => {
  const dispatch = useDispatch();

  const { fetchAccountInformation } = useSelector(
    ({ mdmAccounts }) => mdmAccounts.data
  );
  const { mdmAccount } = fetchAccountInformation;

  // load the policy ID for the account
  const [policyId, setPolicyId] = useState(null);
  useEffect(() => {
    if (mdmAccount) {
      setPolicyId(mdmAccount.PolicyID);
    } else {
      dispatch(fetchMdmAccountByID(mdmAccountId));
    }
  }, [mdmAccount]);
  const [
    policyIdInModalPriorityInputFocused,
    setPolicyIdInModalPriorityInputFocused,
  ] = useState(null);
  useEffect(() => {
    return () => {
      setSearchValue("");
      setPolicyIdInModalPriorityInputFocused(null);
    };
  }, []);
  const columns = [
    {
      Header: "",
      accessor: "policyTemplateId",
      show: false,
      disableSortBy: true,
    },
    {
      Header: "Policy",
      accessor: "name",
    },
    {
      Header: "Version",
      accessor: "version",
    },
    {
      Header: "Priority",
      accessor: "priority",
      Cell: ({ cell: { row } }) => {
        const { register, handleSubmit, setValue, watch } = useForm();
        const original = row.original;
        if (!row.original.policyTemplateId) return;
        const isFocused =
          policyIdInModalPriorityInputFocused === row.original.policyTemplateId;
        console.log("isFocused", isFocused);
        const isProtected = row.original.isInTable; //row.original.protected || row.original.isInTable;
        const onSubmit = async (data) => {
          if (isProtected) return;
          console.log("data", data);
          if (isFocused) {
            const newPriority = Number(data.priority);
            setPolicyTemplateIDToAccountPolicyDataDict((prevDict) => {
              const updatedDict = { ...prevDict };
              const newAccountPolicyData =
                updatedDict[original.policyTemplateId];
              newAccountPolicyData.priority = newPriority;
              return updatedDict;
            });
            setPolicyIdInModalPriorityInputFocused(null);
          }
        };
        return (
          <span
            style={{
              display: "flex",
              color: "#0A313F",
              // height: "100%",
              alignItems: "center",
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <Tooltip
                title={isProtected && "Protected policy"}
                placement="right"
                disableHoverListener={!isProtected}
              >
                <input
                  placeholder="priority"
                  disabled={isProtected}
                  id={original.policyTemplatesId}
                  type="number"
                  style={{
                    minWidth: 50,
                    width: 80,
                    textAlign: "end",
                    marginRight: 2,
                    marginBottom: 0,
                    borderColor: "#0A313F",
                    cursor: isProtected ? "not-allowed" : "text",
                    userSelect: isProtected ? "none" : "auto",
                  }}
                  value={watch("priority")}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (isFocused) setValue("priority", e.target.value);
                  }}
                  register={register("priority", {
                    value: original.priority,
                  })}
                  onBlur={() => {
                    if (isProtected) return;
                    if (isFocused) {
                      const newPriority = Number(watch("priority"));
                      setPolicyTemplateIDToAccountPolicyDataDict((prevDict) => {
                        const updatedDict = { ...prevDict };
                        const newAccountPolicyData =
                          updatedDict[original.policyTemplateId];
                        newAccountPolicyData.priority = newPriority;
                        return updatedDict;
                      });
                      setPolicyIdInModalPriorityInputFocused(null);
                    }
                  }}
                  onFocus={() => {
                    setValue("priority", original.priority);
                    setPolicyIdInModalPriorityInputFocused(
                      original.policyTemplateId
                    );
                  }}
                />
              </Tooltip>
              <div style={{ minWidth: 65, minHeight: 35 }}>
                {!isProtected && isFocused && (
                  <React.Fragment>
                    <img
                      role="button"
                      src={"./img/untick.svg"}
                      alt={"X icon"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setValue("priority", "");
                        setPolicyTemplateIDToAccountPolicyDataDict(
                          (prevDict) => {
                            const updatedDict = { ...prevDict };
                            const newAccountPolicyData =
                              updatedDict[original.policyTemplateId];
                            newAccountPolicyData.priority = undefined;
                            return updatedDict;
                          }
                        );
                        setPolicyIdInModalPriorityInputFocused(null);
                      }}
                    />
                    <button
                      style={{ backgroundColor: "transparent", border: "none" }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      type="submit"
                    >
                      <img
                        role="button"
                        src={"./img/big_check.svg"}
                        alt={"Check icon"}
                      />
                    </button>
                  </React.Fragment>
                )}
              </div>
            </form>
          </span>
        );
      },
      disableSortBy: true,
    },
    {
      Header: "Select",
      accessor: "selectComponent",
      disableSortBy: true,
    },
  ];

  const {
    assignPolicyTemplatesToMdmAccount: assignPolicyTemplatesToMdmAccountState,
  } = useSelector(({ mdmAccounts }) => mdmAccounts.data);

  useEffect(() => {
    if (
      assignPolicyTemplatesToMdmAccountState.success === true ||
      !assignPolicyTemplatesToMdmAccountState.loading
    ) {
      dispatch(resetAssignPolicyTemplatesToMdmAccount());
      setIsOpen(false);
    }
  }, [assignPolicyTemplatesToMdmAccountState]);

  /**
   * Handles a row click in the table
   *   - Toggles checked status for the selected app
   */
  const handleAccountPoliciesModalRowClick = (e) => {
    const policyTemplateId = e.row.original.policyTemplateId;

    setPolicyTemplateIDToAccountPolicyDataDict((prevDict) => {
      const updatedDict = { ...prevDict };
      const accountPolicyData = updatedDict[policyTemplateId];

      // if isInTable, then checked status cannot be toggled
      if (accountPolicyData.isInTable) {
        return updatedDict;
      }

      accountPolicyData.isChecked = !accountPolicyData.isChecked;
      updatedDict[policyTemplateId] = generateDependentData(accountPolicyData);
      return updatedDict;
    });
  };

  /**
   * Handles a cancel click in the modal
   *   - Sets checked status for all policies to false
   *   - Sets priority for all policies not in the table to undefined
   */
  const handleCancelClick = () => {
    if (assignPolicyTemplatesToMdmAccountState.loading) return;
    setIsOpen(false);
    dispatch(resetAssignPolicyTemplatesToMdmAccount());
    // reset the isChecked and priority state of the policies
    setPolicyTemplateIDToAccountPolicyDataDict((prevDict) => {
      const updatedDict = { ...prevDict };
      for (const accountPolicyData of Object.values(updatedDict)) {
        accountPolicyData.isChecked = false;
        // reset the priority if the policy is not in the table
        if (!accountPolicyData.isInTable) {
          accountPolicyData.priority = undefined;
        }
        updatedDict[accountPolicyData.policyTemplateId] = generateDependentData(
          accountPolicyData
        );
      }
      return updatedDict;
    });
  };

  /**
   * Handles a proceed click in the modal
   *   - adds the selected policies to the account
   */
  const handleProceedClick = () => {
    // check if no policies are checked
    if (assignPolicyTemplatesToMdmAccountState.loading) return;
    if (
      Object.values(policyTemplateIdToAccountPolicyDataDict).filter(
        (accountPolicyData) => accountPolicyData.isChecked
      ).length == 0
    ) {
      Message.error("No policy templates were selected.");
      return;
    }

    // setIsOpen(false);
    // first sort according to name so we have a deterministic way priorities are set
    const sortedAccountPolicyData = Object.values(
      policyTemplateIdToAccountPolicyDataDict
    ).sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    // get the max priority from policyTemplateIdToAccountPolicyDataDict, or -1
    // set the highestPriority from the accountPolicyTemplates
    let highestPriority = -1;
    if (sortedAccountPolicyData.length > 0) {
      highestPriority = Math.max(
        ...sortedAccountPolicyData
          // filter out protected policies
          .filter((accountPolicyData) => !accountPolicyData.protected)
          .map((policyTemplate) => policyTemplate.priority || -1)
      );
    }

    let policyIdToPriorityDict = {};
    const accountPolicyTemplatesToAdd = sortedAccountPolicyData
      .filter((accountPolicyData) => accountPolicyData.isChecked)
      .map((accountPolicyData) => {
        let priorityToSet = accountPolicyData.priority;
        if (priorityToSet == undefined) {
          priorityToSet = highestPriority + 1;
          highestPriority += 1;
        }

        // extract data according to schema of TMdmPolicyTemplates
        policyIdToPriorityDict[
          accountPolicyData.policyTemplateId
        ] = priorityToSet;
        return {
          PolicyID: policyId,
          PolicyTemplateID: accountPolicyData.policyTemplateId,
          Priority: priorityToSet,
        };
      });

    dispatch(
      assignPolicyTemplatesToMdmAccount({
        mdmAccountId: mdmAccountId,
        MdmPolicyTemplates: accountPolicyTemplatesToAdd,
      })
    );
  };

  const [searchValue, setSearchValue] = useState("");
  return (
    <NucleusModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          minHeight: 400,
          maxHeight: "80vh",
          minWidth: 800,
          padding: 20,
          overflowY: "clip",
        }}
      >
        <span
          style={{
            alignContent: "center",
            color: "#0A313F",
            fontSize: 24,
            marginTop: 5,
            marginBottom: 40,
            fontWeight: "bold",
          }}
        >
          Add a New Policy
        </span>
        <div style={{ display: "flex", width: "100%" }}>
          <NucleusSearchInput
            placeholder="Search by Policy"
            value={searchValue}
            onChange={(e) => {
              const value = e.target.value || "";
              setSearchValue(value);
              setPolicyTemplateIDToAccountPolicyDataDict((prevDict) => {
                const updatedDict = { ...prevDict };
                for (const accountPolicyData of Object.values(
                  policyTemplateIdToAccountPolicyDataDict
                )) {
                  accountPolicyData.isInModal = accountPolicyData.name
                    .toLowerCase()
                    .includes(value.toLowerCase());
                }
                return updatedDict;
              });
            }}
          />
        </div>
        <div
          style={{
            "overflow-y": "auto",
            height: "400px",
            width: "100%",
          }}
        >
          <NucleusTable
            onRowClick={handleAccountPoliciesModalRowClick}
            columns={columns}
            data={Object.values(policyTemplateIdToAccountPolicyDataDict)
              .filter((accountPolicyData) => accountPolicyData.isInModal)
              .sort((a, b) => {
                return b.isAssigned - a.isAssigned;
              })}
            checkForDisabled={true}
          />
        </div>
        <div style={{ display: "flex" }}>
          {assignPolicyTemplatesToMdmAccountState.loading ? (
            <SpinnerCircular
              color="#2096F3"
              secondaryColor="rgba(0,0,0,0.16)"
              size="50"
              thickness="100"
            />
          ) : (
            <React.Fragment>
              <div className="nucleus-mr-1">
                <NucleusButton
                  rounded
                  disabled={
                    loading || assignPolicyTemplatesToMdmAccountState.loading
                  }
                  label="Cancel"
                  backGroundColor="#FFFFFF"
                  action={handleCancelClick}
                />
              </div>
              <div className="nucleus-ml-1">
                <NucleusButton
                  disabled={
                    loading || assignPolicyTemplatesToMdmAccountState.loading
                  }
                  label="Proceed"
                  backGroundColor="#0A313F"
                  action={handleProceedClick}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </NucleusModal>
  );
};

export default MdmAccountSelectPoliciesModal;
