import React from 'react';
import ReactDOM from "react-dom";

//import 'dotenv/config';

import CareAccountStore from "../stores/CareAccountStore";

import classNames from 'classnames';
import Message from "../utils/Message";

import SettingsMessageCategories from "../components/SettingsMessageCategories";

import AccountsGroups from './accountsGroups/AccountsGroups';

import Config from "../Config";
import packageJson from '../../../package.json';
import AuthStore from "../stores/AuthStore";
import { SpinnerCircular } from 'spinners-react';

class Settings extends React.Component {

    constructor() {
        super();

        this.state = {
            appVersion : packageJson.version,
            activeTabNumber : 1,

            messageCategoriesTab : ' staffTab',
            messageCategoriesLoading: false,

            accountsGroupsTab : ' ',

            editCategoryBtnStyle : 'nucleus-submit-btn',
            selectedCatID : '',
            selectedCatName : '',
            selectedCatIcon : '',
            selectedCatSound : '',
            selectedCatTTL : '',
            selectedCatRepeat : '',

            editCategoryNameError : ' hide',
            editCategoryIconError : ' hide',
            editCategorySoundError : ' hide',
            editCategoryTTLError : ' hide',
            editCategoryDingRepeatError : ' hide',


            newCategoryNameError : ' hide',
            newCategoryIconError : ' hide',
            newCategorySoundError : ' hide',
            newCategoryTTLError : ' hide',
            newCategoryDingRepeatError : ' hide',

            newCategoryBtnStyle : 'nucleus-submit-btn',
        };


        this.onOpenCategoryModalAction = this.onOpenCategoryModalAction.bind(this);
        this.handleCancelEditMessageCategory = this.handleCancelEditMessageCategory.bind(this);
        this.handleResetEditMessageErrors = this.handleResetEditMessageErrors.bind(this);
        this.handleUpdateCategory = this.handleUpdateCategory.bind(this);
        this.onMessageCategoryUpdatedAction = this.onMessageCategoryUpdatedAction.bind(this);

        this.handleNewCategoryModal = this.handleNewCategoryModal.bind(this);
        this.handleCancelNewMessageCategory = this.handleCancelNewMessageCategory.bind(this);
        this.handleResetNewMessageErrors = this.handleResetNewMessageErrors.bind(this);
        this.handleSaveNewCategory = this.handleSaveNewCategory.bind(this);
        this.onMessageCategorySavedAction = this.onMessageCategorySavedAction.bind(this);

        this.handleRefreshMessageCategories = this.handleRefreshMessageCategories.bind(this);
        this.selectAccountsGroupsTab = this.selectAccountsGroupsTab.bind(this);
        this.resetLoading = this.resetLoading.bind(this);
    }


    componentDidMount() {

        if (!AuthStore.superUser()){
            window.location.assign("#/dashboard");
        }
        //Register Listener
        CareAccountStore.on("onOpenCategoryModal", this.onOpenCategoryModalAction);
        CareAccountStore.on("onMessageCategorySaved", this.onMessageCategorySavedAction);
        CareAccountStore.on("onMessageCategoryUpdated", this.onMessageCategoryUpdatedAction);


        /*
        UserStore.on("onUserGetData", this.onUserGetDataResult);
        UserStore.on("onUserUpdateData", this.onUserUpdateDataResult);

        //Get the User Data from WS
        UserStore.getUserData({
            UserID : AuthStore.getUserID()
        });
        */

        window.$('.modal').modal({
              dismissible: true,
              complete: function() {  } // Callback for Modal close
            }
        );
    }

    componentWillUnmount() {
        //Remove Listener
        CareAccountStore.removeListener("onOpenCategoryModal", this.onOpenCategoryModalAction);
        CareAccountStore.removeListener("onMessageCategorySaved", this.onMessageCategorySavedAction);
        CareAccountStore.removeListener("onMessageCategoryUpdated", this.onMessageCategoryUpdatedAction);
        /*
        UserStore.removeListener("onUserGetData", this.onUserGetDataResult);
        UserStore.removeListener("onUserUpdateData", this.onUserUpdateDataResult);
        */
    }

    onOpenCategoryModalAction(categoryData){
        console.log("onOpenCategoryModalAction", categoryData);

        this.setState({
            selectedCatID : categoryData.CatID,
            selectedCatName : categoryData.CatName,
            selectedCatIcon : categoryData.CatIcon,
            selectedCatSound : categoryData.CatSound,
            selectedCatTTL : categoryData.CatTTL,
            selectedCatRepeat : categoryData.CatDingRepeat,

            editCategoryBtnStyle : 'nucleus-submit-btn'
        });

        this.refs.txtCategoryName.value = categoryData.CatName;
        this.refs.txtCategoryIcon.value = categoryData.CatIcon;
        this.refs.txtCategorySound.value = categoryData.CatSound;
        this.refs.txtCategoryTTL.value = categoryData.CatTTL;
        this.refs.txtCategoryDingRepeat.value = categoryData.CatDingRepeat;

        window.$('#modalEditCategory').modal('open');
    }


    handleCancelEditMessageCategory(){
        window.$('#modalEditCategory').modal('close');
    }

    handleResetEditMessageErrors(){
        this.setState({
            editCategoryNameError : ' hide',
            editCategoryIconError : ' hide',
            editCategorySoundError : ' hide',
            editCategoryTTLError : ' hide',
            editCategoryDingRepeatError : ' hide',
        });
    }

    handleUpdateCategory(){

        let formVerified = false;
        if (this.refs.txtCategoryName.value.length <= 0){
            this.setState({
                editCategoryNameError : ' '
            });
            formVerified = true;
        }

        if (this.refs.txtCategoryIcon.value.length <= 0){
            this.setState({
                editCategoryIconError : ' '
            });
            formVerified = true;
        }

        if (this.refs.txtCategorySound.value.length <= 0){
            this.setState({
                editCategorySoundError : ' '
            });
            formVerified = true;
        }

        if (this.refs.txtCategoryTTL.value.length <= 0){
            this.setState({
                editCategoryTTLError : ' '
            });
            formVerified = true;
        }

        if (this.refs.txtCategoryDingRepeat.value.length <= 0){
            this.setState({
                editCategoryDingRepeatError : ' '
            });
            formVerified = true;
        }

        if (formVerified){
            return;
        }

        this.setState({
            editCategoryBtnStyle : 'nucleus-submit-btn-disabled'
        });

        CareAccountStore.updateMessageCategory({
            ID : this.state.selectedCatID,
            Category : this.refs.txtCategoryName.value,
            Icon : this.refs.txtCategoryIcon.value,
            SoundEffect : this.refs.txtCategorySound.value,
            TTLHours : this.refs.txtCategoryTTL.value,
            DingRepeatInMin : this.refs.txtCategoryDingRepeat.value
        });
    }

    onMessageCategoryUpdatedAction(response){
        console.log("onMessageCategoryUpdatedAction", response);

        window.$('#modalEditCategory').modal('close');
        this.setState({
            editCategoryBtnStyle : 'nucleus-submit-btn'
        });

        if (response.ok){
            Message.show("Message Category updated.");
            CareAccountStore.getMessageCategories();
        } else {
            Message.show("Error updating Message Category");
        }
    }


    // NEW
    handleNewCategoryModal(){
        window.$('#modalNewCategory').modal('open');

        this.refs.txtNewCategoryName.value = "";
        this.refs.txtNewCategoryIcon.value = "";
        this.refs.txtNewCategorySound.value = "";
        this.refs.txtNewCategoryTTL.value = "";
        this.refs.txtNewCategoryDingRepeat.value = "";

        this.handleResetNewMessageErrors();

        this.setState({
            newCategoryBtnStyle : 'nucleus-submit-btn'
        });
    }

    handleCancelNewMessageCategory(){
        window.$('#modalNewCategory').modal('close');
    }

    handleResetNewMessageErrors(){
        this.setState({
            newCategoryNameError : ' hide',
            newCategoryIconError : ' hide',
            newCategorySoundError : ' hide',
            newCategoryTTLError : ' hide',
            newCategoryDingRepeatError : ' hide'
        });
    }


    handleSaveNewCategory(){

        let formVerified = false;
        if (this.refs.txtNewCategoryName.value.length <= 0){
            this.setState({
                newCategoryNameError : ' '
            });
            formVerified = true;
        }

        if (this.refs.txtNewCategoryIcon.value.length <= 0){
            this.setState({
                newCategoryIconError : ' '
            });
            formVerified = true;
        }

        if (this.refs.txtNewCategorySound.value.length <= 0){
            this.setState({
                newCategorySoundError : ' '
            });
            formVerified = true;
        }

        if (this.refs.txtNewCategoryTTL.value.length <= 0){
            this.setState({
                newCategoryTTLError : ' '
            });
            formVerified = true;
        }

        if (this.refs.txtNewCategoryDingRepeat.value.length <= 0){
            this.setState({
                newCategoryDingRepeatError : ' '
            });
            formVerified = true;
        }

        if (formVerified){
            return;
        }

        this.setState({
            newCategoryBtnStyle : 'nucleus-submit-btn-disabled'
        });

        CareAccountStore.saveMessageCategory({
            Category : this.refs.txtNewCategoryName.value,
            Icon : this.refs.txtNewCategoryIcon.value,
            SoundEffect : this.refs.txtNewCategorySound.value,
            TTLHours : this.refs.txtNewCategoryTTL.value,
            DingRepeatInMin : this.refs.txtNewCategoryDingRepeat.value
        });
    }


    onMessageCategorySavedAction(response){
        console.log("onMessageCategorySavedAction", response);

        window.$('#modalNewCategory').modal('close');
        this.setState({
            newCategoryBtnStyle : 'nucleus-submit-btn'
        });

        if (response.ok){
            Message.show("New Message Category created.");
            CareAccountStore.getMessageCategories();
        } else {
            Message.show("Error creating New Message Category");
        }
    }

    handleRefreshMessageCategories(){
        this.setState({
            messageCategoriesLoading :true,
            activeTabNumber : 1,
            messageCategoriesTab : ' staffTab',
            accountsGroupsTab : ' '
        });
        CareAccountStore.getMessageCategories();
    }

    selectAccountsGroupsTab = () =>{
        this.setState({
            activeTabNumber : 2,
            messageCategoriesTab : ' ',
            accountsGroupsTab : ' staffTab'
        });
    }

    resetLoading = function(){
        this.setState({
            messageCategoriesLoading :false
        });
    }

    render() {
        console.warn("Env Log ->", process.env)
        /*
         *
         */
         //let websiteVersion = window.location.hostname + '.';
         //websiteVersion = ' ' + websiteVersion.substr(0, websiteVersion.indexOf('.'));
        return (
            <div class="nucleus-hard-container">
                <p class="nucleus-page-title">Settings &nbsp;&nbsp;&nbsp;v {this.state.appVersion}</p>
                <div class="no-margin">
                    <br/>
                    <a
                        class={"staffTabs nucleus-link " + this.state.messageCategoriesTab }
                        onClick={this.handleRefreshMessageCategories}>
                            &nbsp;&nbsp;Message Category&nbsp;&nbsp;
                    </a>
                    <span style={{width:20, display:'inline-flex'}}></span>
                    <a
                        class={"staffTabs nucleus-link " + this.state.accountsGroupsTab }
                        onClick={this.selectAccountsGroupsTab}>
                            &nbsp;&nbsp;Accounts Groups&nbsp;&nbsp;
                    </a>
                    <br/>
                    <br/>
                    {
                        (this.state.activeTabNumber == 1) &&
                        <div class={"row nucleus-fixed-container "}>
                            <SettingsMessageCategories
                                messageCategoriesLoading={this.state.messageCategoriesLoading}
                                messageCategoriesLoadingComplete={this.resetLoading}/>

                            <div class="fixed-action-btn btn-add-new">
                                <a class="btn-floating btn-large waves-effect waves-light orange tooltipped" data-position="left" data-delay="50" data-tooltip="New Message Category" onClick={this.handleNewCategoryModal}>
                                    <i class="material-icons">add</i>
                                </a>
                                &nbsp;&nbsp;
                            </div>
                        </div>
                    }
                    {
                        (this.state.activeTabNumber == 2) &&
                        <div class={"row nucleus-fixed-container "}>
                            <AccountsGroups></AccountsGroups>
                        </div>
                    }

                </div>

                {

                    <div id="modalEditCategory" class="modal modalEditCategory">
                        <div class="modal-content nucleus-modal-wrapper-scrollable2">
                            <img class="responsive-img img-close-modal" src="img/close_modal.png" onClick={this.handleCancelEditMessageCategory}/>
                            <h3 class="nucleus-page-subtitle"> Edit Message Category </h3>
                            <br/><br/>
                            <div class="row no-margin">
                                <div class="col s3">
                                    <span class="nucleus-labels"> <b>Name</b> </span>
                                </div>
                                <div class="col s9">
                                    <input id="txtCategoryName" ref="txtCategoryName" type="text" class="validate nucleus-search no-margin-bottom" onChange={this.handleResetEditMessageErrors} />
                                    <br/>
                                    <span>
                                        <span class={"nucleus-form-error " + this.state.editCategoryNameError }> Please enter the Category Name</span>&nbsp;
                                    </span>
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="col s3">
                                    <span class="nucleus-labels"> <b>Icon Url</b> </span>
                                </div>
                                <div class="col s9">
                                    <input id="txtCategoryIcon" ref="txtCategoryIcon" type="text" class="validate nucleus-search no-margin-bottom" onChange={this.handleResetEditMessageErrors} />
                                    <br/>
                                    <span>
                                        <span class={"nucleus-form-error " + this.state.editCategoryIconError }> Please enter the Icon Url</span> &nbsp;
                                    </span>
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="col s3">
                                    <span class="nucleus-labels"> <b>Sound Url</b> </span>
                                </div>
                                <div class="col s9">
                                    <input id="txtCategorySound" ref="txtCategorySound" type="text" class="validate nucleus-search no-margin-bottom" onChange={this.handleResetEditMessageErrors} />
                                    <br/>
                                    <span>
                                        <span class={"nucleus-form-error " + this.state.editCategorySoundError }> Please enter the Sound Url</span>&nbsp;
                                    </span>
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="col s3">
                                    <span class="nucleus-labels"> <b>TTL (Hrs)</b> </span>
                                </div>
                                <div class="col s9">
                                    <input id="txtCategoryTTL" ref="txtCategoryTTL" type="text" class="validate nucleus-search no-margin-bottom" onChange={this.handleResetEditMessageErrors} />
                                    <br/>
                                    <span>
                                        <span class={"nucleus-form-error " + this.state.editCategoryTTLError }> Please enter the TTL (Hours)</span>&nbsp;
                                    </span>
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="col s3">
                                    <span class="nucleus-labels"> <b>Ding Repeat (mins)</b> </span>
                                </div>
                                <div class="col s9">
                                    <input id="txtCategoryDingRepeat" ref="txtCategoryDingRepeat" type="text" class="validate nucleus-search no-margin-bottom" onChange={this.handleResetEditMessageErrors} />
                                    <br/>
                                    <span>
                                        <span class={"nucleus-form-error " + this.state.editCategoryDingRepeatError }> Please enter the Ding Repeat (mins)</span>&nbsp;
                                    </span>
                                </div>
                            </div>
                            <div class="input-field col s12 modalCallLogContent2">
                                <br/>
                                <div class="row no-margin">
                                    <div class="col s12 center-align no-margin">
                                        <p></p>
                                        <span class=" modalSeparator"> <label> &nbsp;</label></span>
                                        <a class={"txt-white nucleus-font-small " + this.state.editCategoryBtnStyle } onClick={this.handleUpdateCategory}>Save</a>
                                        <div>&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {

                    <div id="modalNewCategory" class="modal modalNewCategory">
                        <div class="modal-content nucleus-modal-wrapper-scrollable2">
                            <img class="responsive-img img-close-modal" src="img/close_modal.png" onClick={this.handleCancelNewMessageCategory}/>
                            <h3 class="nucleus-page-subtitle"> New Message Category </h3>
                            <br/><br/>
                            <div class="row no-margin">
                                <div class="col s3">
                                    <span class="nucleus-labels"> <b>Name</b> </span>
                                </div>
                                <div class="col s9">
                                    <input id="txtNewCategoryName" ref="txtNewCategoryName" type="text" class="validate nucleus-search no-margin-bottom" onChange={this.handleResetNewMessageErrors}/>
                                    <br/>
                                    <span>
                                        <span class={"nucleus-form-error " + this.state.newCategoryNameError }> Please enter the Category Name</span>&nbsp;
                                    </span>
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="col s3">
                                    <span class="nucleus-labels"> <b>Icon Url</b> </span>
                                </div>
                                <div class="col s9">
                                    <input id="txtNewCategoryIcon" ref="txtNewCategoryIcon" type="text" class="validate nucleus-search no-margin-bottom" onChange={this.handleResetNewMessageErrors}/>
                                    <br/>
                                    <span>
                                        <span class={"nucleus-form-error " + this.state.newCategoryIconError }> Please enter the Icon Url</span> &nbsp;
                                    </span>
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="col s3">
                                    <span class="nucleus-labels"> <b>Sound Url</b> </span>
                                </div>
                                <div class="col s9">
                                    <input id="txtNewCategorySound" ref="txtNewCategorySound" type="text" class="validate nucleus-search no-margin-bottom" onChange={this.handleResetNewMessageErrors}/>
                                    <br/>
                                    <span>
                                        <span class={"nucleus-form-error " + this.state.newCategorySoundError }> Please enter the Sound Url</span>&nbsp;
                                    </span>
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="col s3">
                                    <span class="nucleus-labels"> <b>TTL (Hrs)</b> </span>
                                </div>
                                <div class="col s9">
                                    <input id="txtNewCategoryTTL" ref="txtNewCategoryTTL" type="text" class="validate nucleus-search no-margin-bottom" onChange={this.handleResetNewMessageErrors}/>
                                    <br/>
                                    <span>
                                        <span class={"nucleus-form-error " + this.state.newCategoryTTLError }> Please enter the TTL (Hours)</span>&nbsp;
                                    </span>
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="col s3">
                                    <span class="nucleus-labels"> <b>Ding Repeat (mins)</b> </span>
                                </div>
                                <div class="col s9">
                                    <input id="txtNewCategoryDingRepeat" ref="txtNewCategoryDingRepeat" type="text" class="validate nucleus-search no-margin-bottom" onChange={this.handleResetNewMessageErrors}/>
                                    <br/>
                                    <span>
                                        <span class={"nucleus-form-error " + this.state.newCategoryDingRepeatError }> Please enter the Ding Repeat (mins)</span>&nbsp;
                                    </span>
                                </div>
                            </div>
                            <div class="input-field col s12 modalCallLogContent2">
                                <br/>
                                <div class="row no-margin">
                                    <div class="col s12 center-align no-margin">
                                        <p></p>
                                        <span class=" modalSeparator"> <label> &nbsp;</label></span>
                                        <a class={"txt-white nucleus-font-small " + this.state.newCategoryBtnStyle } onClick={this.handleSaveNewCategory}>Save</a>
                                        <div>&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    }
}

export default Settings;
