import React from 'react';
import ReactDOM from "react-dom";
import { IndexLink, Link } from "react-router-dom";
import Message from "../utils/Message";

//import _ from "underscore";

class AccountPatientItemRow extends React.Component
{
    constructor(props)
    {
        super();
        this.state = {
            patientID : null
        };

        this.handleRowClicked = this.handleRowClicked.bind(this);
    }

    componentDidMount()
    {
        //Register Listener

        // this.setState({
        //     userID : this.props.UserID,
        //     userName: this.props.FirstName + ' ' + this.props.LastName,
        //     onDuty :this.props.IsOnDuty
        // });
        
    }

    componentWillUnmount()
    {
        //Remove Listener
    }


    handleRowClicked(){
        
    }


    render()
    {

        return (
            <tr >
                <td class="" >{ this.props.PatientName }</td>
                <td class="center-align" >{ this.props.Devices }</td>
                <td class="center-align" >{ this.props.MemoryBox  }</td>
                <td class="center-align" >{ this.props.ActiveFamilyMembers + " / " + this.props.TotalFamilyMembers }</td>
                <td class="center-align">{this.props.MessagesRead + " / " + this.props.Messages}</td>
                <td class="center-align">{this.props.MessagesExpired + " / " + this.props.Messages}</td>
            </tr>
              
        );
    }
}

export default AccountPatientItemRow;
