import React from 'react';
import ReactDOM from "react-dom";
import Dispatcher from "../Dispatcher";
import { IndexLink, Link } from "react-router-dom";
import classNames from "classnames";
import GeneralStore from "../stores/GeneralStore";
import Message from "../utils/Message";
import AuthStore from '../stores/AuthStore';
//import _ from "underscore";

class AccountUserItemRow extends React.Component
{
    constructor(props)
    {
        super();
        this.state = {
            userID : null,
            userName:'',
            onDuty : false
        };

        this.handleRowClicked = this.handleRowClicked.bind(this);
        this.handleRowResetClicked = this.handleRowResetClicked.bind(this);
    }

    componentDidMount()
    {
        //Register Listener
        this.setState({
            userID : this.props.UserID,
            userName: this.props.FirstName + ' ' + this.props.LastName,
            onDuty :this.props.IsOnDuty
        });
    }

    componentWillUnmount()
    {
        //Remove Listener
    }


    handleRowClicked(){
        window.location.assign("#/accountUserProfile/" + this.props.UserID + "/" + this.props.AccountID );
    }

    handleRowResetClicked(){
        this.props.onUserResetPassword && this.props.onUserResetPassword(this.props.UserID, this.props.Email); //this.props.FirstName + ' ' + this.props.LastName);
    }

    render()
    {
        let status = this.props.Status ?  "Active" : "Pending";
        let statusStyle = this.props.Status ? "" : "nucleus-bold";
        let userIsAdminIndicator = this.props.IsAdmin ? "img/staff_active_icon.png" : "img/staff_inactive_icon.png";  // Deprecated Oct 18th 2017
        userIsAdminIndicator = this.props.IsAdmin ? "Y" : "";
        
        let userName = this.props.FirstName + ' ' + this.props.LastName;
        if (userName != null && userName.length > 29){
            userName = userName.substring(0, 29) + " ...";
        }

        let coordinatorPhone = this.props.Telephone;
        if (coordinatorPhone != null && coordinatorPhone.length > 18){
            coordinatorPhone = coordinatorPhone.substring(0, 18) + " ...";
        }

        return (
            <tr >
                <td class="nucleus-row" onClick={this.handleRowClicked}>{ userName }</td>
                <td class="nucleus-row" onClick={this.handleRowClicked}>{ this.props.Email }</td>
                <td class="nucleus-row" onClick={this.handleRowClicked}>{ coordinatorPhone }</td>
                <td class={"nucleus-row " + statusStyle } onClick={this.handleRowClicked}>{ status }</td>
                <td class="center-align nucleus-row" onClick={this.handleRowClicked}>
                    {/* <img src={userIsAdminIndicator}/> */}
                    { userIsAdminIndicator }
                </td>
                <td>
                    {
                        ( AuthStore.superUser()) && 
                        <span class="nucleus-table-icon nucleus-link" onClick={this.handleRowResetClicked}>
                            <i class="Tiny material-icons">lock_outline</i>
                        </span>
                    }
                </td>
            </tr>
              
        );
    }
}

export default AccountUserItemRow;
