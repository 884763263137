import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import NucleusButtonV2 from "../../../../components/NucleusButtonV2";
import {
  fetchEnterprisesValues,
  resetCreateAccount,
  createMdmAccount as createMdmAccountRequest,
} from '@nucleus-care/nucleuscare-backend-client';
import { useDispatch, useSelector } from "react-redux";
import {
  NucleusControlledSelect,
  NucleusControlledWhiteBgInput,
} from "../../../../components/NucleusControlledInput";
import { SpinnerCircular } from "spinners-react";
import Message from "../../../../utils/Message";
import { useHistory } from "react-router-dom";

const CreateMdmAccountForm = ({ setIsModalOpen }) => {
  
  const dispatch = useDispatch();
  const history = useHistory();
  const { enterprisesOptions, loadingEnterprises } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.data
  );
  const { fetchAccounts, createMdmAccount } = useSelector(
    ({ mdmAccounts }) => mdmAccounts.data
  );
  const { loading, success, error, newMdmAccountID } = createMdmAccount;
  const { mdmAccounts } = fetchAccounts;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log("onSubmit data");
    if (!data.Name || data.Name === "") {
      setError("Name", {
        type: "manual",
        message: "Name is required.",
      });
      return;
    }

    const nameAlreadyRegistered = mdmAccounts.some((a) => a.Name === data.Name);
    if (nameAlreadyRegistered){
      setError("Name", {
        type: "manual",
        message: "Account name already exists.",
      });
      return;
    }
    
    if (!data.EnterpriseID || data.EnterpriseID === "") {
      setError("EnterpriseID", {
        type: "required",
        message: "Enterprise is required.",
      });
      return;
    }
    dispatch(createMdmAccountRequest(data));
  };

  useEffect(() => {
    dispatch(fetchEnterprisesValues());
  }, []);
  useEffect(() => {
    if (success && newMdmAccountID) {
      const mdmAccountID = newMdmAccountID;
      Message.show("MDM Account created successfully");
      setIsModalOpen(false);
      dispatch(resetCreateAccount());
      history.push(`/policies/mdmAccountDetails/${mdmAccountID}`);
      return;
    }
    if (error) {
      Message.error("An error occurred");
      return;
    }
  }, [success, error, newMdmAccountID]);
  return (
    <form
      style={{
        color: "#000",
        flex: 1,
        height: "100%",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          height: "100%",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "#0A313F",
              fontSize: 24,
              marginTop: 5,
              marginBottom: 40,
              fontWeight:'bold'
            }}
          >
            Add a New Account
          </span>
          <NucleusControlledWhiteBgInput
            disabled={loading || success}
            label={"Name:"}
            name={"Name"}
            register={register("Name")}
            error={errors.Name}
            clearErrors={clearErrors}
            setValue={() => setValue("Name")}
          />
          <NucleusControlledSelect
            disabled={loading || success || loadingEnterprises}
            label={"Enterprise:"}
            name={"EnterpriseID"}
            register={register("EnterpriseID")}
            options={enterprisesOptions}
            loadingOptions={loadingEnterprises}
            error={errors.EnterpriseID}
            clearErrors={clearErrors}
            setValue={setValue}
            valueAccessor={"ID"}
            labelAccessor={"OrganizationName"}
          />
        </div>
        {(loading || loadingEnterprises) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCircular
              color="#2096F3"
              secondaryColor="rgba(0,0,0,0.16)"
              size="50"
              thickness="100"
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 10,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <NucleusButtonV2
              disabled={loading || success}
              onClick={() => {
                reset();
                setIsModalOpen(false);
              }}
              styleType="secondary"
              type="submit"
            >
              CANCEL
            </NucleusButtonV2>
            <NucleusButtonV2
              disabled={loading || success}
              styleType="primary"
              type="submit"
            >
              SAVE
            </NucleusButtonV2>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateMdmAccountForm;
