import React from 'react';
import PropTypes from 'prop-types';
import MdmDevicesTable from '../../MdmDevicesTable';

const MdmAccountDevices = ({ mdmAccountId }) => {
  return (
    <MdmDevicesTable fetchDataIntervalMs={60000} mdmAccountId={mdmAccountId} />
  );
};

MdmAccountDevices.propTypes = {
  mdmAccountId: PropTypes.string.isRequired,
};

export default MdmAccountDevices;
