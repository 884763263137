import React, {useDeferredValue, useEffect, useState} from 'react';
import $ from "jquery";
import CarePatientStore from '../stores/CarePatientStore';
import Message from "../utils/Message";
import GeneralStore from '../stores/GeneralStore';
import AuthStore from '../stores/AuthStore';
import PatientProfileEdit from "../components/PatientProfileEdit";


const AccountUserPatientProfileHeader = ({currentSection, infoVisibility, patientId, setEditPatientActive}) => {

    const [patientData, setPatientData] = useState([]);
    const [updatedPatient, setUpdatedPatient] = useState(false);
    const [currentMode, setCurrentMode] = useState("Info");
    const [linkModeLabel, setLinkModeLabel] = useState("Edit");
    const [removeBtnState, setRemoveBtnState] = useState('nucleus-submit-btn-small');
    const [accountId, setAccountId] = useState("");

    useEffect(() => {
        
        //CarePatientStore.on("onDeletePatientAction", onDeletePatientAction );  
        // Not used because the endpoint DeletePatient takes some seconds

        CarePatientStore.on("onUpdatePatientAction", onUpdatePatientAction);
        GeneralStore.on("onGoBackOnPatientProfileEdit", onGoBackOnPatientProfileEdit);
        return () => {
            //CarePatientStore.removeListener("onDeletePatientAction", onDeletePatientAction);

            CarePatientStore.removeListener("onUpdatePatientAction", onUpdatePatientAction);
            GeneralStore.removeListener("onGoBackOnPatientProfileEdit", onGoBackOnPatientProfileEdit);
        }
    }, [])

    useEffect(() => {
        if(updatedPatient) {
            return;
        }
        //CarePatientStore.on("onUpdatePatientAction", onUpdatePatientAction);
        return () => {
            //CarePatientStore.removeListener("onUpdatePatientAction", onUpdatePatientAction);
        }
    }, [updatedPatient])

    const handleProfileMode = () => {
        console.log("handleProfileMode", currentMode);
        if ( currentMode == "Info"){
            setCurrentMode("Edit");
            setLinkModeLabel("Cancel");
            GeneralStore.setProfileMode("Edit");
            setEditPatientActive(true);
        } else {
            setCurrentMode("Info");
            setLinkModeLabel("Edit");
            GeneralStore.setProfileMode("Info");
            setEditPatientActive(false);
        }
    }


    const onGoBackOnPatientProfileEdit = () =>{
        setCurrentMode("Info");
            setLinkModeLabel("Edit");
            GeneralStore.setProfileMode("Info");
            setEditPatientActive(false);    // Called from the topBar to cancel the Edit Mode on the Go Back 
    }

    const handleRemovePatientModal = () => {
        window.$('#modalDeletePatient').modal();
        window.$('#modalDeletePatient').modal('open');

        /*this.setState({
            removeBtnState : 'nucleus-submit-btn-small'
        });*/
        setRemoveBtnState('nucleus-submit-btn-small');
    }

    const handleCloseModal = () => {
        window.$('#modal1').modal('close');
    }

    const handleCancelDelete = () => {
        window.$('#modalDeletePatient').modal('close');
    }

    const handleDeletePatient = () => {
        setRemoveBtnState('nucleus-submit-btn-small-disabled');
        CarePatientStore.deletePatient({
            PatientID : patientId
        });
        window.$('#modalDeletePatient').modal('close');
        window.location.replace("#/accountDetails/" + accountId);
        Message.show(AuthStore.getPatientLabel() + " deleted!");
        //Message.error("Test");
    }

    const onDeletePatientAction =(response) => {
        console.log("onDeletePatientAction", response);
        if (response.ok){
            window.$('#modalDeletePatient').modal('close');
            window.location.replace("#/accountDetails/" + accountId);
            Message.show(AuthStore.getPatientLabel() + " deleted!");
        } 
        else if (!response.telephoneRegistered) {
            Message.error("There was a problem deleting the " + AuthStore.getPatientLabel().toLowerCase());
        }
    }

    const copyPatientIdToClipboard = () => {
        var patientID = patientId;

        var copyText = document.createTextNode(patientID);
        var textArea = document.createElement("textarea");

        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();

        Message.show("Patien ID copied to clipboard");
    }

    useEffect(() => {
        if(patientData.FirstName) {
            return;
        }
        CarePatientStore.getPatientData({
            PatientID : patientId
        });
        CarePatientStore.on("onPatientGetData", (response) => {
            if(response) {
                setPatientData(response);
                setAccountId(response.AccountID.toUpperCase());
            } else {
                Message.show("Error getting patient, please try again.");
            }
            return  () => CarePatientStore.removeListener("onPatientGetData", () =>{});
        });
    }, [patientData]);


      const onUpdatePatientAction = (response) => {
        console.log("onUpdatePatientAction", response);
        if (response.ok){
            setCurrentMode("Info");
            setLinkModeLabel("Edit");
            Message.show(AuthStore.getPatientLabel() + " successfully updated");
            //this.onUserGetDataAction(true);
            setUpdatedPatient(true);
            setPatientData([]);
            setEditPatientActive(false);
        } 
        else if (!response.telephoneRegistered) {
            Message.error("There was a problem updating the " + AuthStore.getPatientLabel() + " profile");
        }
    }

    return(
        <React.Fragment>
            <div className={ currentMode === "Info" ? "nucleus-page-title toper-container " + infoVisibility : "" }>
                <div class="row no-margin">
                    <div class="col s1">

                    </div>
                    <div class="col s7">
                        {
                            currentMode === 'Info' &&
                            <React.Fragment>
                                <span class="new-page-title" style={{paddingLeft:3}}>
                                    {  patientData.FirstName && patientData.FirstName + " " + patientData.LastName }
                                    &nbsp;&nbsp;
                                </span>
                                <a class="btn-floating btn waves-effect waves-light dark-blue nucleus-floating-btn a-left tooltipped" data-position="top" data-delay="50" data-tooltip="Copy Patient ID for API" onClick={copyPatientIdToClipboard}>
                                    <i class="material-icons nucleus-floating-btn-icon-copy-to-cb">file_copy</i>
                                </a>
                            </React.Fragment>
                        }
                    </div>
                    <div class="col s4 right-align">
                        {
                            currentMode !== 'Edit' &&
                            <React.Fragment>
                                <a id={'edit-patient'} class="nucleus-font-small nucleus-link profile-header-btn1" onClick={handleProfileMode}>
                                    {linkModeLabel}
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a id={'remove-patient'} class="nucleus-font-small nucleus-link profile-header-btn2" onClick={handleRemovePatientModal}>
                                    <span class="txt-red">Remove</span>
                                </a>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
            {
                currentMode=== 'Info' &&
                    <p class={"nucleus-page-title " + "hide" }>
                        { patientData.FirstName && patientData.FirstName + " " + patientData.LastName }
                        &nbsp;&nbsp;&nbsp;
                        <a class="nucleus-font-small  nucleus-link" onClick={handleProfileMode}> {linkModeLabel}</a>
                    </p>
            }

            <div class="altoImportantContainer">
                    <PatientProfileEdit
                        patientImageThumb={patientData.ImageThumb}
                        currentMode={currentMode}
                        patientId={patientId}
                        patientFirstName={patientData.FirstName}
                        patientLastName={patientData.LastName}
                        patientEmail={patientData.Email}
                        patientTelephone={patientData.Telephone || ""}
                        patientAddress={patientData.Address}
                        patientZipCode={patientData.ZipCode}
                        patientDateOfBirth={patientData.patientBirthDate}
                        patientContactName={patientData.emergencyContactName}
                        patientContactTelephone={patientData.emergencyContactTelephone}
                        handleProfileMode={handleProfileMode}
                        //patientDeviceMac={deviceMacAddress}
                        //patientDeviceStatus={deviceStatus}
                        patientShowWeather={patientData.showWeather}
                        //patientCallLogs={this.state.callLogs}

                    />
                    {
                        currentMode === 'Info' &&
                <div class={"nucleus-user-pending altoImportant " + currentSection}>
                    <div class="row valign-wrapper profile-header-body">
                        <br />
                        <div class="col s1">
                            <div style={{display:'flex',alignItems: 'flex-start', marginBottom:10}}>
                                <div style={{position: "absolute", marginTop:-55, marginLeft:2}}>
                                    <img
                                        class=""
                                        style={{width:90,height:90, borderRadius:45, objectFit:'cover'}}
                                        //src="img/user_placeholder.png"
                                        src={patientData.ImageThumb ? patientData.ImageThumb : "img/user_placeholder.png"}
                                        //ref={img=>this.imgRef = img}
                                        //onError={() =>this.imgRef.src= 'img/user_placeholder.png'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col s3">
                            <span class=" nucleus-labels-bold" style={{paddingLeft:6}}>Address</span>
                        </div>
                        <div class="col s2">
                            <span class=" nucleus-labels-bold">Phone Number</span>
                        </div>
                        <div class="col s2">
                            {/* <span class=" nucleus-labels-bold">Birthday</span> */}
                        </div>
                        <div class="col s1">
                            {/* <span class=" nucleus-labels-bold">Age</span> */}
                        </div>
                        <div class="col s3">
                            {/* <span class=" nucleus-labels-bold">Emergency Contact</span> */}
                        </div>
                    </div>
                    <div class="row profile-header-bottom">
                        <div class="col s1 ">
                            <span class=" nucleus-labels">
                            </span>
                        </div>
                        <div class="col s3 " style={{paddingLeft:17}}>
                            <span class=" nucleus-labels">
                                {patientData.Address && patientData.Address}
                            </span>
                        </div>
                        <div class="col s2">
                            <span class=" nucleus-labels"> {patientData.Telephone && patientData.Telephone}</span>
                        </div>
                        <div class="col s2">
                            <span class=" nucleus-labels">
                                {/* {" "}{this.props.patientDateOfBirth} */}
                            </span>
                        </div>
                        <div class="col s1">
                            {/* <span class=" nucleus-labels"> {this.props.patientAge}</span> */}
                        </div>
                        <div class="col s3">
                            {/*
                            <span class=" nucleus-labels">
                                {" "}{this.props.patientContactName}
                            </span>
                            <br />
                            <span class=" nucleus-labels">
                                {" "}{this.props.patientContactTelephone}{" "}
                            </span>
                            */}
                            <br />
                        </div>
                        &nbsp;
                        <br />
                    </div>
                </div>
                    }
                <div id="modalDeletePatient" class="modal modalPatientProfile modalDeletePatient">
                        <div class="modal-content modal-content-delete">
                            <h3 class="nucleus-page-subtitle"> Delete {AuthStore.getPatientLabel()}</h3>
                            <p></p>
                            <div class="row">
                                <div class="col s12 left-align nucleus-font-medium">
                                  Are you sure you want to remove this {AuthStore.getPatientLabel().toLowerCase()}?
                                  <br/> <br/> <br/>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col s12 right-align ">
                                    <p></p>
                                    <a class="txt-white nucleus-font-small nucleus-link" onClick={handleCancelDelete}>Cancel</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a class={"txt-white nucleus-font-small " + removeBtnState } onClick={handleDeletePatient}>Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

        </React.Fragment>
    )
};

export default AccountUserPatientProfileHeader;
