import React from 'react';

interface DeviceOption {
  value: string;
  name: string;
}

interface ParamSelectProps {
  id:string;
  placeholder: string;
  selectedValue: string;
  options: DeviceOption[];
  handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  style?: React.CSSProperties; 
}

const ParamSelect: React.FC<ParamSelectProps> = ({
  id,
  placeholder,
  selectedValue,
  options = [],
  handleSelectChange,
  style
}) => {
  const defaultStyle: React.CSSProperties = {
    display:'block',
    width:120,
    height:35,
    borderRadius:10,
    paddingRight:12,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
  };
  const finalStyle = { ...defaultStyle, ...style };
  return (
    <select 
      id={id}
      name={id}
      value={selectedValue}
      onChange={handleSelectChange} 
      style={finalStyle}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option) => (
        <option style={{color: '#FE3824'}} key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default ParamSelect;
