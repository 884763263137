
/**
 * Validates an email address based on a specified regex pattern and maximum length.
 * Note: RFC 5322 standard was taken into consideration for this regex pattern
 *
 * @param {string} email - The email address to validate.
 * @param {number} [maxLength=50] - The maximum length allowed for the email address. Defaults to 50 characters, based on the database column size.
 * @returns {boolean} `true` if the email is valid according to the regex and within the length constraints, otherwise `false`.
 */
export function isEmailValid({ email, maxLength = 50 }) {
    const regex = /^\w+([\.-]?\w+)*((\+([\.-]?\w)+)?)@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
    return regex.test(email) && email.length <= maxLength;
}
