import React, { useReducer, useContext, createContext, ReactNode } from 'react';

type IMdmDevice = {
    CareAccountName: string;
    CareDeviceBattery: number;
    CarePatientName: string | null;
    CareStatus: boolean;
    IMEI: string | null;
    MdmAccountName: string;
    MdmID: string;
    MdmDeviceID: string;
    MdmLastStatusReportTime: string;
    MdmNonCompliance: number;
}
interface State {
    selectedDevices: IMdmDevice[];
    selectedDevicesIds: string[];
    selectedPageIndex: number;
}

type Action =
    | { type: 'add_selected_device'; device: IMdmDevice }
    | { type: 'remove_selected_device'; device: IMdmDevice }
    | { type: 'select_all_devices'; devices: IMdmDevice[] }
    | { type: 'accumulate_all_devices_ids'; devices: IMdmDevice[] }
    | { type: 'remove_selected_devices_page'; devices: IMdmDevice[] }
    | { type: 'clear_all_selected_devices' }
    | {type: 'set_page_index'; pageIndex: number}
    | { type: 'unknown' };

interface MdmDevicesContextProps {
    state: State;
    dispatch: React.Dispatch<Action>;
}

const MdmDevicesContext = createContext<MdmDevicesContextProps | undefined>(undefined);

const mdmDevicesReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'add_selected_device':
            return {
                ...state,
                selectedDevices: state.selectedDevices.includes(action.device) ? state.selectedDevices : [...state.selectedDevices, action.device],
                selectedDevicesIds: state.selectedDevicesIds.includes(action.device.MdmDeviceID) ? state.selectedDevicesIds : [...state.selectedDevicesIds, action.device.MdmDeviceID]
            };
        case 'remove_selected_device':
            return {
                ...state,
                selectedDevicesIds: state.selectedDevicesIds.filter(id => id !== action.device.MdmDeviceID),
                selectedDevices: state.selectedDevices.filter(device => device.MdmDeviceID !== action.device.MdmDeviceID)
            };
        case 'select_all_devices':
            return {
                ...state,
                selectedDevicesIds: action.devices.map(device => device.MdmDeviceID),
                selectedDevices: action.devices
            };
        case 'clear_all_selected_devices':
            return {
                ...state,
                selectedDevicesIds: [],
                selectedDevices: []
            };
        case 'accumulate_all_devices_ids': {
            const newIds = action.devices.map(device => device.MdmDeviceID);
            const allSelectedIds = new Set([...state.selectedDevicesIds, ...newIds]);
            return {
                ...state,
                selectedDevicesIds: Array.from(allSelectedIds),
                selectedDevices: [
                    ...state.selectedDevices,
                    ...action.devices.filter(device => !state.selectedDevicesIds.includes(device.MdmDeviceID))
                ],
            };
        }
        case 'remove_selected_devices_page': {
            const idsToRemove = action.devices.map(device => device.MdmDeviceID);
            const allSelectedToRemoveIds = new Set([...state.selectedDevicesIds, ...idsToRemove]);
            return {
                ...state,
                selectedDevicesIds: Array.from(allSelectedToRemoveIds),
                selectedDevices: [
                    ...state.selectedDevices,
                    ...action.devices.filter(device => !state.selectedDevicesIds.includes(device.MdmDeviceID))
                ],
            };
        }
        case 'set_page_index': {
            return {
                ...state,
                selectedPageIndex: action.pageIndex,
            }
        }
        default:
            console.warn('Unknown action type: ', action.type);
            return state;
    }
};

interface MdmDevicesProviderProps {
    children: ReactNode;
}

export const MdmDevicesProvider: React.FC<MdmDevicesProviderProps> = ({ children }) => {
    const initialState: State = { selectedDevices: [], selectedDevicesIds: [], selectedPageIndex: 0 };
    const [state, dispatch] = useReducer(mdmDevicesReducer, initialState);

    const contextValue = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return (
        <MdmDevicesContext.Provider value={contextValue}>
            {children}
        </MdmDevicesContext.Provider>
    );
};

export const useMdmDevices = (): MdmDevicesContextProps => {
    const context = useContext(MdmDevicesContext);
    if (context === undefined) {
        throw new Error('useMdmDevices must be used within a MdmDevicesProvider');
    }
    return context;
};
