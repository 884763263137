import React from "react";
import cx from "classnames";
export default function Select({
  value,
  placeholder,
  onChange,
  children,
  type,
  className,
  style,
  name,
  id,
  disabled
}) {
  return (
    <select
      disabled={disabled}
      value={value}
      name={name}
      id={id}
      placeholder={placeholder}
      style={{ ...style }}
      className={cx(
        className || type !== "apps"
          ? "browser-default accountSelect"
          : "browser-default accountSelectApps"
      )}
      onChange={onChange}
    >
      {children}
    </select>
  );
}
