import { httpApi } from "@nucleus-care/nucleuscare-backend-client";

type AccountFeature = {
  EnablePatientGroups: boolean;
  ID: string;
  ok: boolean
  v: number;
}

export const AccountApi = ({
  getFeatures: async ({ accountId }: { accountId: string }) => {
    return httpApi.get<AccountFeature>(`/account/${accountId}/features_config`)
  },
  togglePatientGroups: async ({ accountId, state }: { accountId: string; state: boolean }) => {
    return httpApi.put(`/account/${accountId}/enable_patient_groups`, { enable: state })
  }
})