import React from 'react';
import ReactDOM from "react-dom";
import { SpinnerCircular } from 'spinners-react';
import CareAccountStore from "../stores/CareAccountStore";
import MessageCategoryItemRow from "../components/MessageCategoryItemRow";

class SettingsMessageCategories extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            categoriesArray : [],
            loading : false
        };
        this.onGetMessageCategoriesAction = this.onGetMessageCategoriesAction.bind(this);
    }

    componentDidMount(){
         //Register Listener
        CareAccountStore.on("onGetMessageCategories", this.onGetMessageCategoriesAction);
        this.setState({
            loading:true
        });
        CareAccountStore.getMessageCategories();
    }

    componentWillUnmount(){
         //Remove Listener
        CareAccountStore.removeListener("onGetMessageCategories", this.onGetMessageCategoriesAction);
    }

    shouldComponentUpdate(updatedProps){
        
        if (updatedProps.messageCategoriesLoading != this.state.loading){
            this.state.loading = updatedProps.messageCategoriesLoading;
            console.log("Components!!!!!!", updatedProps);
            this.setState({
                loading: updatedProps.messageCategoriesLoading
            });
        }
        return true;
    }

     onGetMessageCategoriesAction(data){
        console.log("onGetMessageCategoriesAction", data);
        this.props.messageCategoriesLoadingComplete && this.props.messageCategoriesLoadingComplete();
        if (data.ok){
            this.setState({
                categoriesArray : data.Categories,
                loading: false
            });
        }
        // this.setState({
        //     chartData1 : data.calls
        // });
        
    }


    render()
    {
        let categoriesRows = [];
        if (this.state.categoriesArray != null){
          this.state.categoriesArray.map((category, key) => {
            categoriesRows.push(
              <MessageCategoryItemRow
                  key={key}
                  ID={ category.ID}
                  Category={category.Category}
                  Icon={category.Icon}
                  Sound={category.Sound}
                  TTL={category.TTL}
                  DingRepeat={category.DingRepeat}
                />
              );
          });
        }

        return (
        
            <div class="row no-margin ">
                <div class="assignments-table-pending" >
                    <table class="bordered highlight nucleus-table" >
                        <thead>
                            <tr>
                                <th class="table-col-20"><span class="nucleus-table-header">Name</span></th>
                                <th class="table-col-25"><span class="nucleus-table-header">Icon</span></th>
                                <th class="table-col-25"><span class="nucleus-table-header">Sound</span></th>
                                <th class="table-col-12 center-align"><span class="nucleus-table-header">TTL Hours</span></th>
                                <th class="table-col-18 center-align"><span class="nucleus-table-header">Ding Repeat (Mins)</span></th>
                              
                            </tr>
                        </thead>

                        <tbody>
                            {
                                (this.state.loading) &&
                                    <td class="nucleus-row center-align" rowSpan={3} colSpan={5}>
                                         <div style={{marginTop:120}}>
                                            <SpinnerCircular 
                                                color="#2096F3"
                                                secondaryColor='rgba(0,0,0,0.16)'
                                                size='50'
                                                thickness='100'
                                                />
                                        </div>
                                    </td>
                            }

                            { 
                                (!this.state.loading) && 
                                categoriesRows 
                            }
                        </tbody>
                    </table>
                </div>

            </div>    

        );
    }
}

export default SettingsMessageCategories;