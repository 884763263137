import React, { useEffect, useRef, useState, useMemo } from 'react';
import NucleusPaginatedTable from '../../components/NucleusPaginatedTableMdmDevices';
import NucleusSearchInput from '../../components/NucleusSearchInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMdmDevicesList,
  resetFetchDeviceList,
} from '@nucleus-care/nucleuscare-backend-client';
import { Checkbox } from '@material-ui/core';
import { Tooltip } from 'react-tooltip';
import { useHistory, useLocation } from 'react-router-dom';
import { dateFormatter } from '../../utils/dateFormatter';
import { useForm } from 'react-hook-form';
import Message from '../../utils/Message';
import { useMdmDevices } from '../../context-api/mdmContext/MdmDevicesTableContext';
import MDMDevicesSelect from '../../components/MDMDevicesSelect';
import PropTypes from 'prop-types';
import message from '../../utils/Message';
import MdmDeviceApi from '../../apis/MdmDeviceApi';

const MdmDevicesTable = ({ mdmAccountId, fetchDataIntervalMs = 0 }) => {
  const dispatch = useDispatch();
  const mdmDeviceApi = new MdmDeviceApi();
  const history = useHistory();
  const { fetchDeviceList } = useSelector(({ mdmDevice }) => mdmDevice.data);
  const {
    data: deviceData,
    data_count: totalDevicesCount,
    containCareDevices,
    loading,
    success,
  } = fetchDeviceList;
  const fetchTimeoutRef = useRef(null);
  const { state: mdmDevicesState, dispatch: dispatchMdmDevices } =
    useMdmDevices();
  const { deleteMdmDevice } = useSelector(({ mdmDevice }) => mdmDevice.data);
  const { success: deletingRequestEnd } = deleteMdmDevice;
  const handleCheckboxChange = (device) => {
    const alreadyInTheArray = mdmDevicesState.selectedDevicesIds.includes(
      device.MdmDeviceID,
    );
    if (alreadyInTheArray) {
      dispatchMdmDevices({
        type: 'remove_selected_device',
        device,
      });
    } else {
      dispatchMdmDevices({
        type: 'add_selected_device',
        device,
      });
    }
  };
  const handleCopyDeviceID = (ID, event, type) => {
    event.stopPropagation();

    let copiedValue = ID;

    let copyText = document.createTextNode(copiedValue);
    let textArea = document.createElement('textarea');

    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    Message.show(
      type === 'IMEI'
        ? 'IMEI ' + copiedValue + ' has been copied!'
        : 'MDM ID ' + copiedValue + ' has been copied!',
    );
  };
  const handleSelectAll = () => {
    const deviceIds = deviceData.map((device) => device.MdmDeviceID);

    const areAllDevicesSelected = deviceIds.every((id) =>
      mdmDevicesState.selectedDevicesIds.includes(id),
    );

    if (!areAllDevicesSelected) {
      dispatchMdmDevices({
        type: 'accumulate_all_devices_ids',
        devices: deviceData,
      });
    } else {
      dispatchMdmDevices({
        type: 'clear_all_selected_devices',
      });
    }
  };

  const SelectAllComponent = () => {
    const selectedDeviceIds = mdmDevicesState.selectedDevicesIds;
    const deviceIds = deviceData.map((device) => device.MdmDeviceID);
    const areAllDevicesSelected = deviceIds.every((id) =>
      selectedDeviceIds.includes(id),
    );
    const isAllSelected = deviceIds.length > 0 && areAllDevicesSelected;
    return (
      <Checkbox
        checked={isAllSelected}
        onClick={(e) => {
          e.stopPropagation();
          handleSelectAll();
        }}
        color="primary"
        style={{ marginLeft: 6 }}
      />
    );
  };
  const columns = useMemo(() => {
    const commonColumns = [
      {
        Header: <SelectAllComponent />,
        accessor: 'MdmID',
        Cell: ({ row }) => (
          <Checkbox
            checked={mdmDevicesState.selectedDevicesIds.includes(
              row.original.MdmDeviceID,
            )}
            onClick={(e) => {
              const deviceData = {
                ...row.values,
                ...{
                  CareAccountName: row.original.CareAccountName,
                  MdmAccountName: row.original.MdmAccountName,
                },
              };
              e.stopPropagation();
              handleCheckboxChange(deviceData);
            }}
            color="primary"
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'MdmDeviceID',
        Cell: ({ cell: { row } }) => {
          const value = row.original.MdmDeviceID;
          const deviceMdmState = row.original.MdmDeviceState;
          let iconUrl = null;
          switch (deviceMdmState) {
            case 'PROVISIONING':
              iconUrl = 'img/exclamation-mark-circle.svg';
              break;
            case 'LOST':
              iconUrl = 'img/non-compliant.svg';
              break;
            case 'DISABLED':
              iconUrl = 'img/help-circle.svg';
              break;
            case 'ACTIVE':
              iconUrl = null;
              break;
            default:
              iconUrl = null;
              break;
          }
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: containCareDevices ?? 'space-evenly',
                width: containCareDevices ?? '100%',
              }}
            >
              <div
                style={{
                  width: containCareDevices ?? '50%',
                  display: 'flex',
                  justifyContent: containCareDevices ?? 'center',
                }}
              >
                <span
                  style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {value}
                  {iconUrl != null && (
                    <>
                      <img
                        data-tip={deviceMdmState}
                        src={iconUrl}
                        style={{ width: 20, height: 20, marginLeft: 5 }}
                      />
                      <Tooltip
                        place="top"
                        type="dark"
                        effect="float"
                        delayShow={50}
                      />
                    </>
                  )}
                </span>
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <button
                  className="waves-light nucleus-floating-btn a-left"
                  onClick={(event) => handleCopyDeviceID(value, event, 'MDMID')}
                  onKeyDown={() => {}}
                  data-tip={'Copy MdmID'}
                  style={{
                    color: '#0092FF',
                    border: 'none',
                    background: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginTop: '4%',
                  }}
                >
                  <span className="material-icons" style={{ fontSize: 15 }}>
                    content_copy
                  </span>
                  <Tooltip
                    place="top"
                    type="dark"
                    effect="float"
                    delayShow={50}
                  />
                </button>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Last Checked In',
        accessor: 'MdmLastStatusReportTime',
        Cell: ({ cell: { row } }) => {
          const {
            CareDeviceLastOnlineDate,
            MdmLastStatusReportTime,
            MdmEnrollmentTime,
          } = row.original;

          const dateValue =
            MdmLastStatusReportTime ||
            MdmEnrollmentTime ||
            CareDeviceLastOnlineDate;

          if (dateValue) {
            const date = dateFormatter(dateValue);
            return <span>{date}</span>;
          }

          return <span style={{ color: '#B4C6D3' }}>unknown</span>;
        },
      },
      {
        Header: 'Battery Level',
        accessor: 'CareDeviceBattery',
        Cell: ({ value }) => {
          let textColor = '#0A313F'; // black default color
          if (value == undefined) {
            textColor = '#B4C6D3'; // grey if battery level is unknown
          } else if (value < 50) {
            textColor = '#f2564b'; // red if battery level is less than 50%
          }
          return (
            <span style={{ color: textColor }}>
              {value ? `${value}%` : 'unknown'}
            </span>
          );
        },
        disableSortBy: true,
      },
    ];

    const nucleusPatientColumns = [
      {
        Header: 'Nucleus Patient',
        accessor: 'CarePatientName',
        show: containCareDevices,
      },
      {
        Header: 'IMEI',
        accessor: 'IMEI',
        Cell: ({ cell: { row } }) => {
          const value = row.original.IMEI;
          return (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-evenly',
              }}
            >
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {value}
                </span>
              </div>
              <div
                style={{
                  //width: '40%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {value && (
                  <button
                    className="waves-light nucleus-floating-btn a-left"
                    onClick={(event) =>
                      handleCopyDeviceID(value, event, 'IMEI')
                    }
                    onKeyDown={() => {}}
                    data-tip={'Copy IMEI'}
                    style={{
                      color: '#0092FF',
                      border: 'none',
                      background: 'transparent',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginTop: '3%',
                    }}
                  >
                    <span className="material-icons" style={{ fontSize: 15 }}>
                      content_copy
                    </span>
                    <Tooltip
                      place="top"
                      type="dark"
                      effect="float"
                      delayShow={50}
                    />
                  </button>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Nucleus Status',
        accessor: 'CareStatus',
        show: containCareDevices,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          const deviceStatusIcon = value
            ? 'img/icon_device_active.png'
            : 'img/icon_device_inactive.png';

          return (
            <img
              className="nucleus-ml-3"
              style={{ alignSelf: 'center' }}
              src={deviceStatusIcon}
            />
          );
        },
      },
    ];

    const mdmComplianceColumn = {
      Header: 'Compliance',
      accessor: 'MdmNonCompliance',
      disableSortBy: true,

      Cell: ({ cell: { row } }) => {
        const nonComplianceValue = !!row.original.MdmNonCompliance;
        const complianceIcon = nonComplianceValue
          ? 'img/non-compliant.svg'
          : 'img/compliant.svg';
        return (
          <span
            style={{
              color: '#0A313F',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              onKeyDown={() => {}}
              src={complianceIcon}
              style={{
                marginLeft: 30,
                width: 20,
                height: 20,
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (!nonComplianceValue) return;
                const deviceId = row.original.MdmID;
                history.push(
                  `/policies/mdmDeviceDetails/${deviceId}?redirectTab=compliance`,
                );
              }}
            />
          </span>
        );
      },
    };
    const rebootColumn = {
      Header: '',
      accessor: 'ID',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (!row.original?.MdmID) {
                message.show('Failed to send device reboot request', 'error');
                return;
              }
              console.log('handleDeviceReboot');
              mdmDeviceApi.requestDeviceReboot(
                {
                  MdmID: row.original?.MdmID,
                },
                (response) => {
                  console.log('requestDeviceReboot response', response);
                  if (response.ok) {
                    message.show(
                      'Device reboot request has been sent',
                      'success',
                    );
                  } else {
                    message.show(
                      'Failed to send device reboot request',
                      'error',
                    );
                  }
                },
              );
            }}
            style={{ border: 'none', background: 'transparent' }}
            className="nucleus-table-icon nucleus-link tooltipped "
            data-delay="50"
            data-position="top"
            data-tooltip="Remote restart"
          >
            <img
              src="img/power.svg"
              className="nucleus-link"
              style={{
                marginTop: 5,
                width: 18,
                height: 18,
              }}
            />
          </button>
        );
      },
    };
    const selectedColumns = containCareDevices
      ? [...commonColumns, ...nucleusPatientColumns, mdmComplianceColumn]
      : commonColumns;
    selectedColumns.push(rebootColumn);
    return selectedColumns;
  }, [
    containCareDevices,
    deviceData,
    mdmDevicesState.selectedDevices,
    mdmDevicesState.selectedDevicesIds,
  ]);
  const [lastFetchParams, setLastFetchParams] = useState({
    pageIndex: 0,
    limit: 10,
  });
  const [lastFetchDate, setLastFetchDate] = useState('');
  useEffect(() => {
    if (success) {
      const lastFetchDate = Date.now();
      const lastFetchDateFormatted = dateFormatter(lastFetchDate);
      setLastFetchDate(lastFetchDateFormatted);
    }
  }, [success]);
  useEffect(() => {
    return () => {
      dispatch(resetFetchDeviceList());
    };
  }, []);
  const fetchData = ({ pageIndex = 0, limit = 10, _sort = [] }) => {
    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current);
      fetchTimeoutRef.current = null;
    }
    const offset = pageIndex * limit;
    const searchText = watch('devicesSearch');
    let sortParam = null;
    if (_sort !== null && _sort.length > 0) {
      const sortColumn = _sort[0];
      const sortByDir = sortColumn.desc ? 'DESC' : 'ASC';
      sortParam = `${sortColumn.id}:${sortByDir}`;
    }
    dispatch(
      fetchMdmDevicesList({
        limit,
        offset,
        searchText,
        mdmAccountId,
        _sort: sortParam,
      }),
    );
    setLastFetchParams({ pageIndex, limit, _sort });
    if (fetchDataIntervalMs && fetchDataIntervalMs > 30000) {
      fetchTimeoutRef.current = setTimeout(() => {
        fetchData(lastFetchParams);
      }, fetchDataIntervalMs);
    }
  };
  const handleIndexChange = (index) => {
    dispatchMdmDevices({
      type: 'set_page_index',
      pageIndex: index,
    });
  };
  const [initialTableState, setInitialTableState] = useState(undefined);

  const location = useLocation();
  const search = location.search;
  /**
   * Sets the initial table state based on the URL query params.
   */
  useEffect(() => {
    const initialTableState = {};
    const queryParams = new URLSearchParams(search);
    const page = queryParams.get('page');
    if (page) {
      initialTableState.pageIndex = parseInt(page) - 1;
    }
    const pageSize = queryParams.get('pageSize');
    if (pageSize) {
      initialTableState.pageSize = parseInt(pageSize);
    }
    const sortBy = queryParams.get('sortBy');
    const sortDir = queryParams.get('sortDir');
    if (sortBy && sortDir) {
      initialTableState.sortBy = [
        {
          id: sortBy,
          desc: sortDir === 'DESC',
        },
      ];
    }
    setInitialTableState(initialTableState);
  }, [search]);

  const onTableLoad = ({ pageIndex, pageSize, sortBy }) => {
    fetchData({ pageIndex, limit: pageSize, _sort: sortBy });
  };

  /**
   * Handles page changes for a table view.
   * @param {Object} param0 - Object containing table state.
   * @param {number} param0.pageIndex - Current page index.
   * @param {number} param0.pageSize - Number of items per page.
   * @param {Array} param0.sortBy - Sorting criteria.
   * Performs two actions:
   * 1. Fetches data based on the current table state.
   * 2. Updates the browser's URL to reflect the current table state.
   */
  const onPageChange = ({ pageIndex, pageSize, sortBy }) => {
    fetchData({ pageIndex, limit: pageSize, _sort: sortBy });
    let url = location.pathname + '?';
    let queryParams = [];
    if (pageIndex !== undefined) {
      queryParams.push(`page=${pageIndex + 1}`);
    }
    if (pageSize !== undefined) {
      queryParams.push(`pageSize=${pageSize}`);
    }
    if (sortBy && sortBy.length > 0) {
      const sortColumn = sortBy[0];
      const sortDir = sortColumn.desc ? 'DESC' : 'ASC';
      queryParams.push(`sortBy=${sortColumn.id}&sortDir=${sortDir}`);
    }
    history.push(url + queryParams.join('&'));
  };

  useEffect(() => {
    return () => {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
    };
  }, []);

  const onRowClick = ({ row }) => {
    const deviceId = row.original.MdmID;
    history.push(`/policies/mdmDeviceDetails/${deviceId}`);
  };
  const { register, setValue, watch, handleSubmit } = useForm();
  const handleSubmitSearch = () => {
    fetchData(lastFetchParams);
  };

  useEffect(() => {
    if (deletingRequestEnd) {
      dispatchMdmDevices({
        type: 'clear_all_selected_devices',
      });
      fetchData(lastFetchParams);
    }
  }, [deletingRequestEnd]);
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <form onSubmit={handleSubmit(handleSubmitSearch)}>
          <NucleusSearchInput
            clearAction={() => {
              setValue('devicesSearch', '');
              fetchData(lastFetchParams);
            }}
            buttonType="submit"
            register={register('devicesSearch')}
            loading={loading}
            lastRefresh={lastFetchDate}
            onRefresh={() => {
              fetchData(lastFetchParams);
            }}
            onChange={(e) => {
              const value = e.target.value;
              setValue('devicesSearch', value);
            }}
            value={watch('devicesSearch')}
            placeholder="Filter by ID / Nucleus Patient / IMEI"
            inputMinWidth={380}
            actionsActive={true}
          />
        </form>
        <div style={{ marginTop: 5, marginRight: 20 }}>
          <span style={{ marginLeft: 10, alignSelf: 'flex-end' }}>
            {mdmDevicesState.selectedDevices.length > 0 && totalDevicesCount > 0
              ? `Selected ${mdmDevicesState.selectedDevices.length} of ${totalDevicesCount} records`
              : ''}
          </span>
          <MDMDevicesSelect />
        </div>
      </div>
      {initialTableState !== undefined && (
        <NucleusPaginatedTable
          loading={loading}
          onRowClick={onRowClick}
          columns={columns}
          data={deviceData}
          resultsCount={totalDevicesCount}
          fetchData={fetchData}
          stateToRowClassMap={{
            ACTIVE: 'row-active',
            PROVISIONING: 'row-provisioning',
            LOST: 'row-lost',
            DISABLED: 'row-disabled',
          }}
          onTableLoad={onTableLoad}
          pageIndexState={mdmDevicesState.selectedPageIndex}
          indexChangeCallback={handleIndexChange}
          stateField="MdmDeviceState"
          tableState={initialTableState}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default MdmDevicesTable;

MdmDevicesTable.propTypes = {
  // ...
  mdmAccountId: PropTypes.string.isRequired,
  fetchDataIntervalMs: PropTypes.number,
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        CareDeviceLastOnlineDate: PropTypes.string,
        MdmLastStatusReportTime: PropTypes.string,
        MdmEnrollmentTime: PropTypes.string,
        MdmDeviceID: PropTypes.string,
        MdmID: PropTypes.string,
        MdmDeviceState: PropTypes.string,
        MdmNonCompliance: PropTypes.bool,
        IMEI: PropTypes.string,
      }),
    }),
  }),
};
