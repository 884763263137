import { EventEmitter } from "events";
//import Dispatcher from "../Dispatcher";
//import Config from "../Config";
import QueueApi from "../apis/QueueApi";
 
class CareQueueStore extends EventEmitter {
	constructor() {
		super();

		this.QueueApi = new QueueApi(this);
		this.QueueList = localStorage.QueueList;
		this.QueueNumber = localStorage.QueueNumber;

		this.QueueEmergencyList = localStorage.QueueEmergencyList;
		this.QueueRequestList = localStorage.QueueRequestList;

		this.LastCallRequest = localStorage.LastCallRequest || {};

		this.PollingQueueNumber = false;

		//console.warn(this);
	}

	getQueue(){
		return this.QueueList;
	}

	getQueueNumber(){
		return this.QueueNumber;
	}

	getLastRequest(){
		return this.LastCallRequest;
	}

	getPendingRequestByPatient(patientID){
		console.log("getPendingRequestByPatient");
		console.log(patientID);

		var pendingRequestID = '';
		for (var i = 0; i < this.QueueList.length; i++){
			if (this.QueueList[i].PatientID == patientID){
				pendingRequestID = this.QueueList[i].RequestID;
				break;
			}
		}
		return pendingRequestID;
	}

	getEmergencyList(){
		return this.QueueEmergencyList;
	}
	getRequestList(){
		return this.QueueRequestList;
	}

	setPollingQueueNumber(polling){
		this.PollingQueueNumber = polling;
	}

	startPollingQueue(){
		this.emit("onStartPollingQueue");
	}

	broadcastStopNotification(){
		this.emit("onQueueItemClicked");
	}

	removeRequestFromQueue(requestID){

		console.log("CLEANING QUEUE: ", this.QueueNumber);

		let emergencyList = [];
		let requestList = [];
		let requestToRemoveIndex = -1;
		for(var i = 0; i < this.QueueList.length; i++){
            
            if (this.QueueList[i].RequestID != requestID){
	            if (this.QueueList[i].IsEmergency) {
	            	emergencyList.push(this.QueueList[i]);
	            } 
	            else {
	            	requestList.push(this.QueueList[i]);
	            }
	        } else{
	        	requestToRemoveIndex = i;
	        }
        }
        this.QueueEmergencyList = emergencyList;
        this.QueueRequestList = requestList;
		this.saveToLocalStorage();

		if (requestToRemoveIndex > -1){
			this.QueueList.splice(requestToRemoveIndex, 1);
			console.log("CLEANING Removing... ", requestToRemoveIndex);
		}

		this.QueueNumber = this.QueueList.length;
		var index = this.QueueList.length;
		if ( index > 0){
			this.LastCallRequest = this.QueueList[index-1];
		} else {
			this.LastCallRequest = null;
		}

		this.emit("onHandledRequestRemovedFromStore");
		console.log("CLEANING QUEUE: ", this.QueueNumber);
	}

	getCareCallRequestsQueueForUser ({ UserID }) {
		console.log("getCareCallRequestsQueueForUser ", UserID );

		this.QueueApi.getCareCallRequestsQueueForUser({ UserID },(rsp) => {

			//console.log("getCareCallRequestsQueueForUser Response");
			//console.log(rsp);
			console.log("MERO RESPONSE ", rsp, this.PollingQueueNumber);
			if (rsp.ok && !this.PollingQueueNumber){
				
				this.QueueList = rsp.requests;
				this.QueueNumber = rsp.requests.length;
				console.log("GETTING QUEUE: ", this.QueueNumber);

				let emergencyList = [];
				let requestList = [];
				for(var i = 0; i < rsp.requests.length; i++){
		            
		            if (rsp.requests[i].IsEmergency) {
		            	emergencyList.push(rsp.requests[i]);
		            } 
		            else {
		            	requestList.push(rsp.requests[i]);
		            }
		        }
		        this.QueueEmergencyList = emergencyList;
		        this.QueueRequestList = requestList;
				this.saveToLocalStorage();

				var index = rsp.requests.length;
				if ( index > 0){
					this.LastCallRequest = rsp.requests[index-1];
				} else {
					this.LastCallRequest = null;
				}

				// console.log("Num QUEUES! " + this.QueueNumber);
				// console.log("EMERGENCIES: ", this.QueueEmergencyList);
				// console.log("REQUEST: ", this.QueueRequestList);
			}
			
			this.emit("onGetCareCallRequestsQueue", rsp.ok);
		});
	}

	getCareQueueNumber ({ UserID }) {
		//console.log("getCareQueueNumber ", AccountID );

		this.QueueApi.getCareCallRequestsQueueForUser({ UserID },(rsp) => {

			console.log("getCareCallRequestsQueueNumber Response");
			console.log(rsp);

			var index = rsp.requests.length;
			if ( index > 0){
				this.LastCallRequest = rsp.requests[index-1];
			} else {
				this.LastCallRequest = null;
			}

			this.emit("onGetQueueNumber", { ok: rsp.ok, number : rsp.requests.length });
		});
	}

	reportCallHandled ({ RequestID, UserID }) {
		console.log("reportCallHandled ", RequestID, UserID  );

		this.QueueApi.ReportCallHandled({ RequestID, UserID  },(rsp) => {

			this.emit("onReportCallHandled", { ok: rsp.ok, requestID : RequestID });
		});
	}


	saveToLocalStorage(){
		console.log("saveToLocalStorage");

		localStorage.QueueList = this.QueueList;
		localStorage.QueueNumber = this.QueueNumber;

		localStorage.QueueEmergencyList = this.QueueEmergencyList;
		localStorage.QueueRequestList = this.QueueRequestList;
	}


}

const careQueueStore = new CareQueueStore;

export default careQueueStore;