import React, { useMemo } from 'react'
import { UIModal } from '../../../components/UI'
import styled from 'styled-components';
import { ModalAction } from '../../../components/UI/Modals/Base/ActionButtons';

const SuspendDeviceModal = ({
    order,
    isOpen,
    onClose,
    onProceed,
    isSuspending,
}) => {
    const actions: ModalAction[] = useMemo(() => {
        return [
            {
                label: 'Cancel',
                onClick: onClose,
                buttonVariant: 'secondary',
            },
            {
                label: 'Proceed',
                onClick: onProceed,
                buttonVariant: 'primary',
            },
        ]
    }, [onProceed, onClose, order]);

    return (
        <UIModal
            isOpen={isOpen}
            close={onClose}
            actions={actions}
            size='large'
        >
            <Title style={{
                fontSize: 64
            }}>{'WARNING!'}</Title>
            <RowContainer>
                <Note>You are about to {isSuspending ? 'suspend' : 'reactivate'}</Note>
            </RowContainer>
            <RowContainer>
                <LabelContainer>
                    <BoldNote>Shopify Order ID:</BoldNote>
                </LabelContainer>
                <ValueContainer>
                    <NotBoldNote>{order?.shopifyOrderNumber}</NotBoldNote>
                </ValueContainer>
            </RowContainer>
            <RowContainer>
                <LabelContainer>
                    <BoldNote>Device Orderer:</BoldNote>
                </LabelContainer>
                <ValueContainer>
                    <NotBoldNote>{order?.deviceOrdererFirstName ?? ''}{' '}{order?.deviceOrdererLastName ?? ''}</NotBoldNote>
                </ValueContainer>
            </RowContainer>
            <RowContainer>
                <LabelContainer>
                    <BoldNote>Patient:</BoldNote>
                </LabelContainer>
                <ValueContainer>
                    <NotBoldNote>{order?.patientFirstName || ""}{' '}{order?.patientLastName || ""}</NotBoldNote>
                </ValueContainer>
            </RowContainer>
            <ColContainer>
                <Text style={{ marginBottom: isSuspending ? 0 : 10 }}>{isSuspending ? 'This action will suspend all calling and video features on the Nucleus Device(s).' : 'This action will re-enable, all features and functionality on the Nucleus Device(s).'}</Text>
                {isSuspending && <Text style={{ marginTop: 0 }}>{'The user will only be able to use the device(s) as a Photo Frame.'}</Text>}
                <Text>{"Would you like to proceed?"}</Text>
            </ColContainer>
        </UIModal>
    )
}

export default SuspendDeviceModal

const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;
const LabelContainer = styled.div`
    display: flex;
    flex:1;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 10px;
`;
const ValueContainer = styled.div`
    display: flex;
    flex:1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 10px;
`;
const RowContainer = styled.div`
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
`;
const ColContainer = styled.div`
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
`;
const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 16px;
`;
const Text = styled.span`
  color: #0a313f;
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const BoldNote = styled.span`
    color: #0a313f;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
`;
const NotBoldNote = styled.span`
    color: #0a313f;
    font-size: 16px;
    margin-left: 5px;
`;
