import React from 'react';
import ReactDOM from "react-dom";
import AuthStore from "../stores/AuthStore";
import Dispatcher from "../Dispatcher";
import { IndexLink, Link } from "react-router-dom";
import Message from "../utils/Message";
class ForgotPassword extends React.Component {
    
    constructor() {
        super();
        this.state = {
            loading : false,
            errorView1 : " hide",
            errorText1 : "",
            btnStyle : " nucleus-submit-btn-disabled"
        };
        
        this.validateEmailInput = this.validateEmailInput.bind(this);
        this.validateFieldsFromKeyboard = this.validateFieldsFromKeyboard.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.onResetRequestedAction = this.onResetRequestedAction.bind(this);
    }

    componentDidMount() {
        //Register Listener
        AuthStore.on("onResetRequestedAction", this.onResetRequestedAction);
    }

    componentWillUnmount() {
        //Remove Listener
        AuthStore.removeListener("onResetRequestedAction", this.onResetRequestedAction);
    }

    onResetRequestedAction(response){

        console.log("onResetRequestedAction");
        console.log(response);

        if (response.ok){
            Message.show("Reset Password request email has been successfully to this email, please review your inbox.");
        }else {
            if (!response.existingUser){
                this.setState({
                    errorView1 : " ",
                    errorText1 : "Email account does not exist."
                });
            }else{
                Message.show("Error requesting reset password");
            }
        }
        this.setState({
            btnStyle : " nucleus-submit-btn"
        });
    }
    

    validateFieldsFromKeyboard(e){
        if ((e.charCode == 13) || (e.keyCode == 13) || (e.key == 13) ){
            this.validateFields();
        }
    }

    validateEmailInput(e){
        //console.log(e.target.value);
        this.setState({
            errorView1 : " hide",
        });

        if (this.refs.txtUserEmail.value.length > 0 ){
            this.setState({
                btnStyle : " nucleus-submit-btn"
            });
        } else {
            this.setState({
                btnStyle : " nucleus-submit-btn-disabled"
            });
        }
    }

    validateFields() {

        if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(this.refs.txtUserEmail.value) ){
            //Materialize.toast('Ups! The email is not valid', 3000, 'rounded');
            this.setState({
                errorView1 : " ",
                errorText1 : "Email invalid."
            });
            return;
        }

        AuthStore.requestResetPassword({
            Email : this.refs.txtUserEmail.value
        });
        this.setState({
            btnStyle : " nucleus-submit-btn-disabled"
        });
    }


    render() {
        let style = {
            height:window.innerHeight,
            background:'#008FFF',
            overflowY:'auto'
        };

        return (
            <div class="nucleus-login" style={style}>
                <div class="alto valign-wrapper" style={{height:760}}>
                    <div class="row ancho">
                        <div class="col s2 m3 l3 border">
                        </div>
                        <div class="col s8 m6 l6 border center-align no-padding">
                            <img class="responsive-img" src="img/nucleusCareLogo_new.png"/>
                            <br/><br/>
                            <p class="txt-white nucleus-page-subtitle">Forgot CS Password</p>
                            <br/><br/>
                            <p class="login-info txt-white nucleus-font "> Enter your account email address and we will send you reset instructions. </p>
                            <br/>
                            <div class="login-info login-error nucleus-font-medium nucleus-font ">
                                &nbsp; 
                                <span class={this.state.errorView1 }> {this.state.errorText1} </span> 
                                &nbsp;
                            </div>
                            
                            <div class="row center-align no-margin">
                                <div class="input-field col s1 m3 l3"></div>
                                <div class="input-field col s10 m6 l6">
                                  <input 
                                    ref="txtUserEmail" 
                                    type="email" 
                                    name="nucleus-user" 
                                    class="validate nucleus-input-login nucleus-font" 
                                    placeholder="Email" 
                                    onKeyPress={this.validateFieldsFromKeyboard } 
                                    onChange={this.validateEmailInput} />
                                </div>
                                <div class="input-field col s1 m3 l3"></div>
                            </div>
                            <br/>
                            <div class="row center-align">
                              <div class="col s12">
                                <a class={"txt-white nucleus-font-small nucleus-font-book " + this.state.btnStyle } 
                                    style={{fontSize:15}} 
                                    onClick={this.validateFields}>
                                        Submit
                                </a>
                              </div>
                            </div>

                            <div class="row no-margin">
                                <div class="input-field col s12 center-align">
                                    <span style={{display:"table", margin:'0 auto'}}>
                                    <Link to="/">
                                        <span class="nucleus-link txt-black nucleus-font-book" style={{fontSize:13.9}}>Back to Login screen</span>
                                    </Link>
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div class="col s2 m3 l3 border">
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
