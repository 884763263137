import React from 'react'
import PropTypes from 'prop-types';
import { UIModal } from '../../../components/UI'
import styled from 'styled-components'

const Message = styled.span`
font-family: 'Barlow';
font-style: normal;
font-size: 14px;
display: flex;
align-items: center;
justify-content: center;
color: #0A313F;
text-align: center;
`
const MissingDataErrorModal = ({ closeModal, isOpen, errorMessage }) => {
    return (
        <UIModal
            size='large'
            isOpen={isOpen}
            close={closeModal}
            title='Missing Data'
            actions={[
                {
                    label: 'Ok',
                    onClick: closeModal,
                    buttonVariant: 'secondary'
                }
            ]}
        >
                <Message>
                    {errorMessage}
                </Message>
        </UIModal>
    )
}
MissingDataErrorModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default MissingDataErrorModal