import React, { useEffect, useState } from "react";
import NucleusInput from "../../components/NucleusInput";
import Select from "../../components/Select";
import NucleusLabel from "../../components/NucleusLabel";
import NucleusTable from "../../components/NucleusTable";
import NucleusModalButton from "../../components/NucleusModal/NucleusModalButton";
import NucleusModal from "../../components/NucleusModal";
import NucleusCheckBox from "../../components/NucleusCheckBox";
import MDMNucleusSelect from "../../components/MDMNucleusSelect";
import {
  addPolicyJSONProperties,
  removeNetworkByGUID,
  removePolicyJSONProperty,
} from '@nucleus-care/nucleuscare-backend-client';
import { useDispatch, useSelector } from "react-redux";
import MDMNucleusCheckBox from "../../components/MDMNucleuCheckBox";
import MDMNewNetworkForm from "./components/MDMNewNetworkForm";
const Networks = ({ policyDetails }) => {
  const { policyFormStatus, policyInformation, policyJSON } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.state
  );
  const dispatch = useDispatch();
  const [savedNetworks, setSavedNetworks] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [allowUserConfigure, setAllowUserConfigure] = useState(null);
  const [enableWifi, setEnableWifi] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const configureWifiOptions = [
    {
      name: "Allow",
      value: "ALLOW_CONFIGURING_WIFI",
    },
    {
      name: "Disallow",
      value: "DISALLOW_ADD_WIFI_CONFIG",
    },
    {
      name: "Deny Add",
      value: "DISALLOW_CONFIGURING_WIFI",
    },
  ];
  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleDeleteNetwork = ({ GUID }) => {
    dispatch(removeNetworkByGUID(GUID));
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "SSID",
        accessor: "WiFi.SSID",
      },
      {
        Header: "Security",
        accessor: "WiFi.Security",
        align: "center",
      },
      {
        Header: "AutoConnect",
        accessor: "WiFi.AutoConnect",
        align: "center",
        Cell: ({ cell: { value } }) => <span>{value ? "Yes" : "No"}</span>,
      },
      {
        Header: "MAC Randomization",
        accessor: "WiFi.MACAddressRandomizationMode",
      },
      {
        Header: " ",
        Cell: ({ cell: { row } }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: "transparent",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                }}
                onClick={() => handleDeleteNetwork(row.original)}
              >
                <img src="./img/trash.svg" alt="trash" />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  useEffect(() => {
    if (policyJSON && policyJSON.openNetworkConfiguration) {
      const networkConfigurations =
        policyJSON.openNetworkConfiguration.NetworkConfigurations;
      if (networkConfigurations && networkConfigurations.length <= 0) {
        dispatch(removePolicyJSONProperty("openNetworkConfiguration"));
      }
      setSavedNetworks(networkConfigurations);
    }
  }, [policyJSON]);

  return (
    <div className="nucleus-all-scrollable-page">
      <div className="nucleus-tools-page-content">
        <div class="row no-margin">
          <div className="nucleus-mb-2">
            <MDMNucleusCheckBox
              name="bluetoothDisabled"
              label={"Disable Bluetooth"}
              operationType={policyFormStatus.operationType}
            />
          </div>
          <MDMNucleusSelect
            labelMinWidth={260}
            activeBadge={true}
            name={"deviceConnectivityManagement.configureWifi"}
            objectAccessor={"deviceConnectivityManagement"}
            propertyAccessor={"configureWifi"}
            valueAccessor="value"
            label={"User Can Configure Wifi:"}
            options={configureWifiOptions}
            onClickClear={() => {
              // get the nested deviceConnectivityManagement object from policyJSON
              const deviceConnectivityManagement = { ...policyJSON.deviceConnectivityManagement } || {};
              // delete the configureWifi property
              delete deviceConnectivityManagement.configureWifi
              
              // if the object is now empty, remove it from the policyJSON, otherwise update it
              if (Object.keys(deviceConnectivityManagement).length === 0) {
                dispatch(removePolicyJSONProperty("deviceConnectivityManagement"))
              } else {
                dispatch(addPolicyJSONProperties({ deviceConnectivityManagement: deviceConnectivityManagement }))
              }
            }}
            setPropertyValue={(value) => {
              // get the nested deviceConnectivityManagement object from policyJSON
              const deviceConnectivityManagement = { ...policyJSON.deviceConnectivityManagement } || {};
              // set the configureWifi value to the selected value
              deviceConnectivityManagement.configureWifi = value
              // dispatch the update
              dispatch(addPolicyJSONProperties({ deviceConnectivityManagement: deviceConnectivityManagement }))
            }}
          />
          <div className="nucleus-mb-2">
            <MDMNucleusCheckBox
              name="cellBroadcastsConfigDisabled"
              label={"Disable Cell Broadcast Configuration:"}
              operationType={policyFormStatus.operationType}
            />
          </div>
          <div className="nucleus-mb-2">
            <MDMNucleusCheckBox
              name="mobileNetworksConfigDisabled"
              label={"Disable Mobile Network Configuration:"}
              operationType={policyFormStatus.operationType}
            />
          </div>
          <div className="nucleus-mb-2">
            <MDMNucleusCheckBox
              name="dataRoamingDisabled"
              label={"Disable Data Roaming:"}
              operationType={policyFormStatus.operationType}
            />
          </div>
          <div className="nucleus-mb-2">
            <MDMNucleusCheckBox
              name="networkEscapeHatchEnabled"
              label={"Enable Network Escape Hatch:"}
              operationType={policyFormStatus.operationType}
            />
          </div>
          <span className="nucleus-tools-page-title">Wifi Networks</span>
          <NucleusTable data={savedNetworks} columns={columns} />
          {/* MODAL */}
          {isOpen && (
            <NucleusModal setIsOpen={setIsOpen}>
              <MDMNewNetworkForm closeModalAction={setIsOpen} />
            </NucleusModal>
          )}
          {!policyDetails?.PolicyTemplate?.Protected &&
            <NucleusModalButton
              dataTooltip={"New Network"}
              id={"networkModal"}
              handleOpenModal={handleOpenModal}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default Networks;
