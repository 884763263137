import React from 'react';
const RefreshPolicyButton = ({ onClick }) => {
    return(
        <div style={{width: '12%', display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: 0}}>
            <button 
                style={{
                    width: 157, 
                    height: 55, 
                    borderRadius: 100, 
                    color: '#3C92F7', 
                    backgroundColor: 'transparent', 
                    borderColor: '#3C92F7',
                    marginRight: '2%',
                    marginTop: '1%',
                    borderStyle: 'solid',
                }}
                onClick={onClick}
            >
                Refresh Policy
            </button>
        </div>
    );
};

export default RefreshPolicyButton;