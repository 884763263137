import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { IndexLink, Link } from "react-router-dom";
import Message from "../../utils/Message";


import { useState, useEffect, useRef } from 'react';

import NucleuscareConnectData,
{
	RootState,
	getSystemDevices,
    getDeviceInfo,
    csDevicesSliceClean
} from '@nucleus-care/nucleuscare-backend-client';

import {
	useSelector,
	useDispatch
	//DefaultRootState
} from 'react-redux';

import { SpinnerCircular } from 'spinners-react';

import moment from 'moment';

import AuthStore from "../../stores/AuthStore";

//Components
import DeviceTableRow from './DeviceTableRow';

import CareAccountStore from "../../stores/CareAccountStore";

const Devices = () => {

    const DEVICES_PER_PAGE = 1000;
    const devicesStore = useSelector((state: RootState) => {
		//console.log("Devices useSelector", state);
		return state.csDevicesStore.data;
	});

    const [stateLoading, setStateLoading] = useState(true);
	const [stateSearchText, setStateSearchText] = useState("");
    const [stateResultsState, setStateResultsState] = useState("");
    const [stateDevicesTableRows, setDevicesTableRows] = useState([]);


    const dispatch = useDispatch();

    const [stateSelectedDevice, setStateSelectedDevice] = useState({});
    const [stateSelectedDeviceDetails, setStateSelectedDeviceDetails] = useState({DeviceInfo:{}, DeviceDetails:{}});
    const [stateDetailsModalOpen, setStateDetailsModalOpen] = useState(false);

    const [stateDeviceUnassign, setStateDeviceUnassign] = useState(false);
    const [stateDeviceRemoveFromHexnode, setStateRemoveFromHexnode] = useState(false);
    const [stateDeviceRemoveFromSystem, setStateDeviceRemoveFromSystem] = useState(false);
    const [stateDeviceRemoveFromMDM, setStateDeviceRemoveFromMDM] = useState(false);
    const [stateDeviceBtnStyle, setStateDeviceBtnStyle] = useState("nucleus-submit-btn");

    //const refDevicesArrayIndex = useRef(0);
    const [stateDevicesTableRowsLimit, setStateDevicesTableRowsLimit] = useState(DEVICES_PER_PAGE);
    const refDevicesTableRowsGroup = useRef(DEVICES_PER_PAGE);
    const refDevicesTableRowsCounter = useRef(0);
    const refDevicesTableRowsTotal = useRef(0);

    const [stateSortingStyles, setStateSortingStyles] = useState({

        accountNameAsc: " nucleus-icon-active ",
        accountNameDesc : " nucleus-icon-inactive hide ",

		sortPatientDesc : ' nucleus-icon-inactive hide ',
        sortPatientAsc : ' nucleus-icon-inactive ',

        sortDeviceNameDesc: ' nucleus-icon-inactive hide ',
        sortDeviceNameAsc: ' nucleus-icon-inactive ',

        sortConnectedDesc : ' nucleus-icon-inactive hide ',
        sortConnectedAsc : ' nucleus-icon-inactive ',

        sortMacDesc : ' nucleus-icon-inactive hide ',
        sortMacAsc : ' nucleus-icon-inactive ',

        // sortPreNameDesc : ' nucleus-icon-inactive hide ',
        // sortPreNameAsc : ' nucleus-icon-inactive ',
        sortVersionDesc : ' nucleus-icon-inactive hide ',
        sortVersionAsc : ' nucleus-icon-inactive ',

        sortStatusDesc : ' nucleus-icon-inactive hide ',
        sortStatusAsc : ' nucleus-icon-inactive ',

        sortBatteryDesc : ' nucleus-icon-inactive hide ',
        sortBatteryAsc : ' nucleus-icon-inactive ',

        // sortTimeZoneDesc : ' nucleus-icon-inactive hide ',
        // sortTimeZoneAsc : ' nucleus-icon-inactive '
    });

    const [stateSortingMode, setStateSortingMode] = useState("accountNameAsc");

    const handleSortingMode = (tag, tag2) => {
        console.log("handleSortingMode", tag, tag2);

        let object = {
            accountNameAsc: " nucleus-icon-inactive ",
            accountNameDesc : " nucleus-icon-inactive hide ",
            sortPatientDesc : ' nucleus-icon-inactive hide ',
            sortPatientAsc : ' nucleus-icon-inactive ',
            sortDeviceNameDesc: ' nucleus-icon-inactive hide ',
            sortDeviceNameAsc: ' nucleus-icon-inactive ',
            sortConnectedDesc : ' nucleus-icon-inactive hide ',
            sortConnectedAsc : ' nucleus-icon-inactive ',
            sortMacDesc : ' nucleus-icon-inactive hide ',
            sortMacAsc : ' nucleus-icon-inactive ',
            sortVersionDesc : ' nucleus-icon-inactive hide ',
            sortVersionAsc : ' nucleus-icon-inactive ',
            sortStatusDesc : ' nucleus-icon-inactive hide ',
            sortStatusAsc : ' nucleus-icon-inactive ',
            sortBatteryDesc : ' nucleus-icon-inactive hide ',
            sortBatteryAsc : ' nucleus-icon-inactive '
        };
        object[tag] = " nucleus-icon-active ";
        object[tag2] = " nucleus-icon-inactive hide ";
        setStateSortingStyles(object);
        setStateSortingMode(tag);
    }

	useEffect(() => {
		console.log("Devices useEffect");
        window.$('.modal').modal({
            dismissible: false,
            complete: () => {  } // Callback for Modal close
        });

        refDevicesTableRowsGroup.current = DEVICES_PER_PAGE;
		refreshReport();

        CareAccountStore.on("onRebootDeviceRequested",onRebootDeviceRequestedAction);
        CareAccountStore.on("onDeleteDeviceModal", onDeleteDeviceModalAction);
		CareAccountStore.on("onDeviceFromAccountRemoved",onDeviceRemovedAction);
        CareAccountStore.on("onEnableRemoteLogsChanged", onEnableRemoteLogsUpdatedAction);
        CareAccountStore.on("onDownloadDeviceLogsModal", onDownloadDeviceLogsModalAction);
        return () =>{
            setStateDetailsModalOpen(false);
            CareAccountStore.removeListener("onRebootDeviceRequested",onRebootDeviceRequestedAction);
            CareAccountStore.removeListener("onDeleteDeviceModal",onDeleteDeviceModalAction);
            CareAccountStore.removeListener("onDeviceFromAccountRemoved",onDeviceRemovedAction);
		    CareAccountStore.removeListener("onEnableRemoteLogsChanged", onEnableRemoteLogsUpdatedAction);
            CareAccountStore.removeListener("onDownloadDeviceLogsModal", onDownloadDeviceLogsModalAction);
        }
	}, []);


	useEffect(() => {
		console.log("Devices useEffect devicesStore");
		//console.log("devicesStore", devicesStore);
		if (!devicesStore.loading){
			if (devicesStore.complete){
                setStateLoading(false);
				//Message.show("Report updated");
			}
		} else if (devicesStore.error){
			Message.show("Error in Devices");
		}
        dispatch(
            csDevicesSliceClean()
        );
	}, [devicesStore.loading, devicesStore.complete])

    useEffect(() => {
		console.log("Devices:useEffect devicesStore:details");

		if (!devicesStore.detailsLoading){

			if (devicesStore.detailsComplete){
                setStateLoading(false);
                updateDeviceDetails();
                setStateDetailsModalOpen(true);
			}
		} else if (devicesStore.error){
			Message.show("Error in Devices");
		}
        dispatch(
            csDevicesSliceClean()
        );
	}, [devicesStore.detailsLoading, devicesStore.detailsComplete])

    const refreshReport = ()=>{
		console.log("refreshReport");

        //refDevicesArrayIndex.current = 0;
        refDevicesTableRowsCounter.current = 0;
        refDevicesTableRowsTotal.current = 0;
        setStateDevicesTableRowsLimit(DEVICES_PER_PAGE);
        setStateLoading(true);
        dispatch(
            getSystemDevices(AuthStore.getCsUserID())
        );
	}

    const onSearchTextChange = (textInput) =>{
        console.log("onSearchTextChange", textInput.target.value);
		setStateSearchText(textInput.target.value);
    }

    const onRowClicked = (deviceData)=>{
        console.log("onRowClicked");
        let device = Object.assign({}, deviceData);
        try {
            device.DeviceDetails = {};
            if (device.DeviceInfo && device.DeviceInfo != "" && device.DeviceInfo != "{}"){
                device.DeviceInfo = JSON.parse(device.DeviceInfo);
            }else{
                device.DeviceInfo = {};
            }
        } catch (error) {
            device.DeviceInfo = {};
            device.DeviceDetails = {};
        }
        console.log("onRowClicked", device);
        setStateSelectedDeviceDetails(device);

        window.$('#modalDeviceDetails').modal('open');
        setStateLoading(true);
        dispatch(
            getDeviceInfo(deviceData.DeviceID)
        );
    }

    const updateDeviceDetails= ()=>{
        console.log("updateDeviceDetails");
        let wifiInfo = {};
        let hexnodeInfo = {};
        try {
            if (devicesStore.data[0] && devicesStore.data[0].HardwareInfo){
                wifiInfo = JSON.parse(devicesStore.data[0].HardwareInfo);
                //console.log("WifiInfo", wifiInfo);
                if (wifiInfo.wifi){
                    //console.log("Listo", wifiInfo.wifi);
                } else{
                    wifiInfo = JSON.parse(wifiInfo);
                    //console.log("Listo 2", wifiInfo);
                }
            }
            if (devicesStore.data[0] && devicesStore.data[0].HexnodeInfo){
                hexnodeInfo = JSON.parse(devicesStore.data[0].HexnodeInfo);
                //console.log("hexnodeInfo", hexnodeInfo);
                if (hexnodeInfo.device){
                    hexnodeInfo = hexnodeInfo.device;
                }
            }
        } catch (error) {
            console.error("updateDeviceDetails handledError = ", error);
        }
        let tempDeviceDetails = Object.assign({}, stateSelectedDeviceDetails);
        tempDeviceDetails.DeviceDetails.Wifi = wifiInfo.wifi;
        tempDeviceDetails.DeviceDetails.SerialNumber = hexnodeInfo.serial_number;
        console.log("Modified state DeviceDetails", tempDeviceDetails);
        setStateSelectedDeviceDetails(tempDeviceDetails);
    }

    const handleCloseDeviceDetailsModal = ()=>{
        console.log("handleCloseDeviceDetailsModal");
        window.$('#modalDeviceDetails').modal('close');
        setStateDetailsModalOpen(false);
        setStateSelectedDeviceDetails({DeviceInfo:{}, DeviceDetails:{}});
    }

    const onDeleteDeviceModalAction = (deviceData) => {
        console.log("onDeleteDeviceModalAction", deviceData);
        setStateSelectedDevice({
            ID: deviceData.DeviceID,
			Mac: deviceData.DeviceMac,
            Name : deviceData.DeviceName || deviceData.PreAssignedName || "",
            AccountID :  deviceData.DeviceAccountID,
			Assigned: deviceData.DeviceAssigned,
            OnHexnode: deviceData.OnHexnode,
            OnNucleusMDM: deviceData.OnNucleusMDM,
            MdmDeviceID : deviceData.MdmDeviceID
        });
        setStateDeviceUnassign(false);
        setStateRemoveFromHexnode(false);
        setStateDeviceRemoveFromSystem(false);
        setStateDeviceRemoveFromMDM(false);
        setStateDeviceBtnStyle("nucleus-submit-btn");
        window.$("#modalDeleteDevice").modal("open");
    }

    const handleCancelDeleteDevice = ()=>{
        window.$("#modalDeleteDevice").modal("close");
    }

    const handleDeleteDevice = ()=>{
        console.log("handleDeleteDevice");
        console.log("stateDeviceUnassign", stateDeviceUnassign);
        console.log("stateDeviceRemoveFromHexnode", stateDeviceRemoveFromHexnode);
        console.log("stateDeviceRemoveFromSystem", stateDeviceRemoveFromSystem);
        console.log("stateDeviceRemoveFromMDM", stateDeviceRemoveFromMDM);

        if (stateSelectedDevice.AccountID == null){
            Message.show("This device is not assigned to any Account");
            return;
        }

        if (stateDeviceUnassign ||
			stateDeviceRemoveFromHexnode ||
			stateDeviceRemoveFromSystem || 
            stateDeviceRemoveFromMDM  ){
				console.log("stateDeviceUnassign", stateDeviceUnassign);
				console.log("stateDeviceRemoveFromHexnode", stateDeviceRemoveFromHexnode);
				console.log("stateDeviceRemoveFromSystem", stateDeviceRemoveFromSystem);
                console.log("stateDeviceRemoveFromMDM", stateDeviceRemoveFromMDM);

                setStateDeviceBtnStyle("nucleus-submit-btn-disabled");

				CareAccountStore.removeDevice({
					AccountID: stateSelectedDevice.AccountID,
                    CsUserID : AuthStore.getCsUserID(),
					DeviceID: stateSelectedDevice.ID,
					Unassign: stateDeviceUnassign && stateSelectedDevice.Assigned,
					DisenrollHexnode: stateDeviceRemoveFromHexnode,
					RemoveFromHexnode: false,
					RemoveFromSystem: stateDeviceRemoveFromSystem,
                    RemoveFromNucleusMdm : stateDeviceRemoveFromMDM,
                    MdmDeviceID : stateSelectedDevice.MdmDeviceID
				});
			}
			else{
				Message.show("Please check at least one option")
			}
    }

    const onDeviceRemovedAction =(response) => {
		console.log("onDeviceRemovedAction");
		console.log(response);
		window.$("#modalDeleteDevice").modal("close");
		if (response.ok) {
			refreshReport();
            if (response.RemoveFromNucleusMDM){
                Message.show("Device removed from the Nucleus MDM");
            }
            if (response.RemoveFromSystem){
                Message.show("Device removed from the system");
            }
            if (response.DisenrollHexnode){
                Message.show("Device removed from Hexnode");
            }
            if (response.Unassign){
                Message.show("Device unassigned");
            }
			//
		} else {
			Message.show("Error deleting the device, please try again.");
		}
	}

    const onEnableRemoteLogsUpdatedAction = (response) =>{
		console.log("onEnableRemoteLogsUpdatedAction", response);
		if (response.ok) {
			let responseResult = response.Enabled ? " enabled" : " disabled";
			Message.show("Remote Logs " + responseResult);
		} else {
			Message.show("Error setting Remote Logs, please try again.");
		}
	}

    const onDownloadDeviceLogsModalAction = (deviceInfo) =>{
		console.log("onDownloadDeviceLogsModalAction 2");
		console.log(deviceInfo);

        if (deviceInfo.DeviceMac == "" || (deviceInfo.DeviceMac && deviceInfo.DeviceMac.length < 12)){
            Message.show("This device has no mac address");
            return;
        }
        setStateSelectedDevice({
            Mac : deviceInfo.DeviceMac
        })
		window.$("#modalDownloadDeviceLogs").modal("open");
	}

	const handleCancelDownloadDeviceLogs =() =>{
		window.$("#modalDownloadDeviceLogs").modal("close");
	}
	const handleDownloadDeviceLogs=()=> {
		let macString = stateSelectedDevice.Mac.replace(/:/g, "");
		var url = "https://analytics.nucleuslife.io/upload/" + macString + ".txt";
		window.$("#modalDownloadDeviceLogs").modal("close");
		window.open(url, "_blank");
	}

    const onRebootDeviceRequestedAction=(response)=>{
        console.log("onRebootDeviceRequestedAction");
		console.log(response);
		if (response.ok) {
			Message.show("Reboot device requested, the device will restart soon.");

			// setTimeout(function(){
			//     this.handleRefresh();
			// }, 30000);
		} else {
			Message.show(
				"There was a error requesting the device reboot, please refresh."
			);
		}
    }
    //useEffect to reRender the table!
    useEffect(() => {
        getTableRows()
    },[
        stateSearchText,
        stateSortingMode,
        devicesStore,
        stateDevicesTableRowsLimit
    ])

    const showMoreDevices = ()=>{
        console.log("showMoreDevices");
        const newLimitToShow = refDevicesTableRowsCounter.current + refDevicesTableRowsGroup.current;
        console.log("showMoreDevices newLimitToShow", newLimitToShow);

        setStateDevicesTableRowsLimit(newLimitToShow);
    }

    const getTableRows = ()=>{
        //console.log("render getTableRows>");

        let devicesRowsArray = [];
        if (devicesStore && devicesStore.devices && devicesStore.devices.length > 0){

            let devicesDataArray = [].concat(devicesStore.devices);

            if (stateSortingMode == "accountNameDesc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                // if (!a.AccountName || !b.AccountName) {
                //   return -1
                // }
					let textA = a.AccountName ? a.AccountName.toLowerCase() : "";
                    let textB = b.AccountName ? b.AccountName.toLowerCase() : "";
                    if (textB < textA) //sort string ascending
                        return -1
                    if (textB > textA)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "accountNameAsc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                // if (!a.AccountName || !b.AccountName) {
                //   return 1
                // }
					let textA = a.AccountName ? a.AccountName.toLowerCase() : "";
                    let textB = b.AccountName ? b.AccountName.toLowerCase() : "";
                    if (textA < textB) //sort string ascending
                        return -1
                    if (textA > textB)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortPatientDesc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    // if (!a.PatientName || !b.PatientName) {
                    //   return 1
                    // }
					let textA = a.PatientName ? a.PatientName.toLowerCase() : "";
                    let textB = b.PatientName ? b.PatientName.toLowerCase() : "";
                    if (textA < textB) //sort string ascending
                        return -1
                    if (textA > textB)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortPatientAsc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    // if (!a.PatientName || !b.PatientName) {
                    //   return -1
                    // }
					let textA = a.PatientName ? a.PatientName.toLowerCase() : "";
                    let textB = b.PatientName ? b.PatientName.toLowerCase() : "";
                    if (textB < textA) //sort string ascending
                        return -1
                    if (textB > textA)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortDeviceNameDesc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    // if (!a.DeviceName || !b.DeviceName) {
                    //   return 1
                    // }
					let textA = a.DeviceName ? a.DeviceName.toLowerCase() : "";
                    let textB = b.DeviceName ? b.DeviceName.toLowerCase() : "";
                    if (textA < textB) //sort string ascending
                        return -1
                    if (textA > textB)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortDeviceNameAsc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    // if (!a.DeviceName || !b.DeviceName) {
                    //   return -1
                    // }
					let textA = a.DeviceName ? a.DeviceName.toLowerCase() : "";
                    let textB = b.DeviceName ? b.DeviceName.toLowerCase() : "";
                    if (textB < textA) //sort string ascending
                        return -1
                    if (textB > textA)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortMacDesc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    let textA = a?.Mac?.toLowerCase();
                    if (a.HexnodeID != null){
                        textA = a?.HexnodeID?.toLowerCase();
                    }
                    let textB = b?.Mac?.toLowerCase();
                    if (b.HexnodeID != null){
                        textA = b?.HexnodeID?.toLowerCase();
                    }
                    if (textA < textB) //sort string ascending
                        return -1
                    if (textA > textB)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortMacAsc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    let textA = a?.Mac?.toLowerCase();
                    if (a.HexnodeID != null){
                        textA = a?.HexnodeID?.toLowerCase();
                    }
                    let textB = b?.Mac?.toLowerCase();
                    if (b.HexnodeID != null){
                        textA = b?.HexnodeID?.toLowerCase();
                    }
                    if (textB < textA) //sort string ascending
                        return -1
                    if (textB > textA)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortVersionDesc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    // if (!a.Version || !b.Version) {
                    //   return 1
                    // }
					let textA = a.Version ? a.Version.toLowerCase() : "";
                    let textB = b.Version ? b.Version.toLowerCase() : "";
                    if (textA < textB) //sort string ascending
                        return -1
                    if (textA > textB)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortVersionAsc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    // if (!a.Version || !b.Version) {
                    //   return -1
                    // }
					let textA = a.Version ? a.Version.toLowerCase() : "";
                    let textB = b.Version ? b.Version.toLowerCase() : "";
                    if (textB < textA) //sort string ascending
                        return -1
                    if (textB > textA)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortStatusDesc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    let textA = 0;
                    if (a.Online){
                        textA = 1;
                    }
                    let textB = 0;
                    if (b.Online){
                        textB = 1;
                    }
                    if (textB < textA) //sort string ascending
                        return -1
                    if (textB > textA)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortStatusAsc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    let textA = 0;
                    if (a.Online){
                        textA = 1;
                    }
                    let textB = 0;
                    if (b.Online){
                        textB = 1;
                    }
                    if (textA < textB) //sort string ascending
                        return -1
                    if (textA > textB)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "sortBatteryDesc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    let batteryA = 0;
                    let batteryB = 0;
                    if (a.Battery) batteryA = a.Battery;
                    if (b.Battery) batteryB = b.Battery;

                    if (batteryA < batteryB) //sort string ascending
                        return -1
                    if (batteryA > batteryB)
                        return 1

                    return 0
                });
            }
            else if (stateSortingMode == "sortBatteryAsc"){
                devicesDataArray = devicesDataArray.sort((a, b) => {
                    let batteryA = 0;
                    let batteryB = 0;
                    if (a.Battery) batteryA = a.Battery;
                    if (b.Battery) batteryB = b.Battery;

                    if (batteryB < batteryA) //sort string ascending
                        return -1
                    if (batteryB > batteryA)
                        return 1
                    return 0
                });
            }
			else if (stateSortingMode == "sortConnectedDesc"){
                devicesDataArray = devicesDataArray.sort((a, b) => (new Date(b.LastOnLineDate)) - (new Date(a.LastOnLineDate)));
            }
            else if (stateSortingMode == "sortConnectedAsc"){
                devicesDataArray = devicesDataArray.sort((a, b) => (new Date(a.LastOnLineDate)) - (new Date(b.LastOnLineDate)));
            }

            let devicesCounter = 0;
            let devicesCounterString = "";
            //refDevicesArrayIndex.current
				if (stateSearchText.length == 0){

                    let devicesArrayLastIndex = 0;
                    const deviceArrayTotal = devicesDataArray.length;
                    if (deviceArrayTotal <= stateDevicesTableRowsLimit){
                        devicesArrayLastIndex = devicesDataArray.length;
                    }
                    else{
                        devicesArrayLastIndex = stateDevicesTableRowsLimit;
                    }
                    // while (condition) {

                    // }
                    for (let index = 0; index < devicesArrayLastIndex; index++) {
                        const device = devicesDataArray[index];
                        devicesRowsArray.push(
                            <DeviceTableRow key={index} index={index} data={device} onClick={onRowClicked} ></DeviceTableRow>
                        );
                        //refDevicesArrayIndex.current++;
                        devicesCounter++;
                    }
                    devicesCounterString = `Showing ${devicesCounter} of ${deviceArrayTotal}`;
					refDevicesTableRowsCounter.current = devicesCounter;
                    refDevicesTableRowsTotal.current = deviceArrayTotal;
				}
				else {
                    // Render for Row according to the search
                    let index = 0;
                    devicesDataArray.map((device, key) => {
                        let deviceTextToSearch = stateSearchText; //.toLocaleLowerCase();
                        let version = device.Version || "";
                        if (
                            (device.AccountName && device.AccountName.indexOf(deviceTextToSearch) >= 0) ||
                            (device.PatientName && device.PatientName.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
                            // DEPRECATED 6/16/2023 (device.Mac && device.Mac.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
                            (device.HexnodeID && (device.HexnodeID.toString()).indexOf(deviceTextToSearch) >= 0) ||
                            (device.MdmDeviceID && (device.MdmDeviceID.toString()).indexOf(deviceTextToSearch) >= 0) ||
                            (device.DeviceName && device.DeviceName.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
                            (device.PreAssignedName &&  device.PreAssignedName.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
                            (version &&  version.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
                            (deviceTextToSearch == "online" && device.Online) ||
                            (deviceTextToSearch == "offline" && !device.Online) ||
                            (deviceTextToSearch == "logsOn" && device.RemoteLogs)
                        ){
                            index++;

                            devicesRowsArray.push(
                                <DeviceTableRow key={key} index={index} data={device} onClick={onRowClicked} ></DeviceTableRow>
                            );
                            //refDevicesArrayIndex.current++;
                        }
                    });
                    devicesCounterString = `Showing ${devicesRowsArray.length} Results for \"${stateSearchText}\"`;

				}

            setDevicesTableRows(devicesRowsArray);
            setStateResultsState(devicesCounterString);

                // return (
                //     <tbody class="tbody-s">
                //         {devicesRowsArray}
                //     </tbody>
                // );
        }

        return(
            <tbody class="center-align ">
                <tr style={{borderBottomStyle:"none"}}>
                    <td colSpan="10" class="center-align ">
                        <br/><br/><br/>
                        <span class="nucleus-table-header-medium text-gray"> {`There are no Devices in the Account`} </span>
                        <br/><br/><br/>
                    </td>
                </tr>
            </tbody>
        );
    }


    return(
        <div class="nucleus-tools-container-devices">
            <div class="nucleus-all-scrollable-page">
            <div >
                <p class="nucleus-tools-page-title"><b>Devices</b></p>
            </div>
            <div>
				<div class="row a-bit-lower left-align"  style={{height:40}}>
					<div class="col s11 m11 l10 no-margin">
						<span class="nucleus-tools-page-text-title a-bit-lower">
							Search &nbsp;&nbsp;&nbsp;
						</span>
						<input
							type="text"
							class="nucleus-input-form"
							onChange={onSearchTextChange}
                            placeholder="Account / Patient / ID / Device Name / Pre A Name / Version / 'offline' / 'online' / 'logsOn'"
                            style={{width:760}}
						/>
                        &nbsp;&nbsp;&nbsp;
                        <a class="txt-white nucleus-font-small nucleus-submit-btn-tools" style={{display:'inline-block'}} onClick={refreshReport}> Refresh </a>
					</div>

					<div class="col s1 m1 l2 no-margin right-align">
                        {
                            (stateLoading) &&
							<div style={{margin:0, padding:0, marginTop:0, marginBottom:0}}>
                            	<SpinnerCircular
                                    color="#2096F3"
                                    secondaryColor='rgba(0,0,0,0.16)'
                                    size='50'
                                    thickness='100'
                                    />
							</div>
                        }
                    </div>
				</div>
            </div>
            <div>
                <p style={{fontWeight: 500,fontSize:16, color:"#0A313F"}}>
                    {stateResultsState}
                    &nbsp;&nbsp;&nbsp;
                    {
                        (stateResultsState && stateSearchText.length == 0 && refDevicesTableRowsCounter.current < refDevicesTableRowsTotal.current ) &&
                        <a class={"nucleus-link-button"} onClick={showMoreDevices}> show more </a>
                    }
                </p>
            </div>

            <table class="b-table b-table-hover b-table-active" style={{}}>
                <thead class="sticky-thead">
                    <tr>
                        {/* <th class="table-col-2">
                            <span class="nucleus-table-header-small ">{""}</span>
                        </th> */}
                        <th class="b-col-2">
                            <span class="nucleus-table-header-medium ">{"Account Name"}</span>
                            <a class={ "nucleus-font-small " + stateSortingStyles.accountNameDesc } onClick={()=>handleSortingMode("accountNameAsc","accountNameDesc")}> &nbsp;▼</a>
                            <a class={ "nucleus-font-small " + stateSortingStyles.accountNameAsc  } onClick={()=>handleSortingMode("accountNameDesc","accountNameAsc")} >&nbsp;▲</a>
                        </th>
                        <th class="b-col-2">
                            {/* <span class="" style={{fontWeight:'normal'}}>Mac / Hexnode ID</span> */}
                            <span class="nucleus-table-header-medium"> ID</span>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortMacDesc } onClick={()=>handleSortingMode("sortMacAsc","sortMacDesc")}> &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortMacAsc } onClick={()=>handleSortingMode("sortMacDesc","sortMacAsc")} >&nbsp;▼</a>
                        </th>
                        <th class="b-col">
                            <span class="nucleus-table-header-medium">Patient Name</span>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortPatientDesc } onClick={()=>handleSortingMode("sortPatientAsc","sortPatientDesc")} > &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortPatientAsc } onClick={()=>handleSortingMode("sortPatientDesc","sortPatientAsc")} >&nbsp;▼</a>
                        </th>
                        <th class="">
                            <span class="nucleus-table-header-medium">Device Name</span>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortDeviceNameDesc } onClick={()=>handleSortingMode("sortDeviceNameAsc","sortDeviceNameDesc")}> &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortDeviceNameAsc } onClick={()=>handleSortingMode("sortDeviceNameDesc","sortDeviceNameAsc")}>&nbsp;▼</a>
                        </th>
                        {/*
                        <th class="table-col-10 left-align">
                            <span class="nucleus-table-header-medium">Pre A. Name</span>
                        </th>
                        */}
                        <th class="">
                            <span class="nucleus-table-header-medium">Version</span>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortVersionDesc } onClick={()=>handleSortingMode("sortVersionAsc","sortVersionDesc")} > &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortVersionAsc } onClick={()=>handleSortingMode("sortVersionDesc","sortVersionAsc")} >&nbsp;▼</a>
                        </th>
                        {/*
                        <th class="table-col-6 center-align">
                            <span class="nucleus-table-header-medium">Batt.</span>
                        </th>
                        <th class="table-col-5 center-align">
                            <span class="nucleus-table-header-medium">Wifi</span>
                        </th>
                        */}
                        <th class="">
                            <span class="nucleus-table-header-medium">Status</span>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortStatusDesc  } onClick={()=>handleSortingMode("sortStatusAsc","sortStatusDesc")}  > &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortStatusAsc } onClick={()=>handleSortingMode("sortStatusDesc","sortStatusAsc")} >&nbsp;▼</a>
                        </th>
                        <th class="">
                            <span class="nucleus-table-header-medium">Battery</span>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortBatteryDesc  } onClick={()=>handleSortingMode("sortBatteryAsc","sortBatteryDesc")}  > &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortBatteryAsc } onClick={()=>handleSortingMode("sortBatteryDesc","sortBatteryAsc")} >&nbsp;▼</a>
                        </th>
                        <th class="b-col-2">
                            <span class="nucleus-table-header-medium">Connected on</span>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortConnectedDesc  } onClick={()=>handleSortingMode("sortConnectedAsc","sortConnectedDesc")} > &nbsp;▲</a>
                            <a class={ "nucleus-font-medium " + stateSortingStyles.sortConnectedAsc } onClick={()=>handleSortingMode("sortConnectedDesc","sortConnectedAsc")}  >&nbsp;▼</a>
                        </th>
                        {/*
                        <th class="table-col-4 center-align">
                            <span class="nucleus-table-header-medium">Timezone</span>
                        </th>
                        */}
                        <th class="">
                            <span class="nucleus-table-header-medium">Details</span>
                        </th>

                        <th class="">
                            <span class="nucleus-table-header-medium">Log</span>
                        </th>
                        {/*
                        <th class="table-col-7 center-align">
                            <span class="nucleus-table-header-medium">Move</span>
                        </th>
                        */}
                        <th class="">
                            <div style={{ display:'flex', alignItems:'center', justifyContent: 'space-around'}}>
                                <span
                                    class="nucleus-table-icon nucleus-link tooltipped"
                                    data-delay="50"
                                    data-position="top"
                                    data-tooltip="Remove"
                                >
                                    <i
                                        class="Tiny material-icons"
                                    >
                                        delete
                                    </i>
                                </span>
                                <span
                                    class="nucleus-table-icon nucleus-link tooltipped "
                                    data-delay="50"
                                    data-position="top"
                                    data-tooltip="Remote restart"
                                >
                                    {
                                        <img
                                        src="img/icon_device_restart.png"
                                        class="nucleus-link"
                                        width="28"
                                        />
                                    }
                                </span>
                            </div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {stateDevicesTableRows}
                </tbody>

            </table>

            </div>
            <div id="modalDeleteDevice" class="modal modalDeleteAccountDevice">
                <div class="modal-content nucleus-modal-wrapper-scrollable2">
                    <img
                        class="responsive-img img-close-modal"
                        src="img/close_modal.png"
                        onClick={handleCancelDeleteDevice}
                    />
                    <h3 class="nucleus-page-subtitle"> Delete Device Options</h3>
                    <br />
                    <br />
                    <div class="row">
                        <div class="col s12 no-margin">
                            <span class="nucleus-labels">
                                Are you sure you want to remove the device {'"' + stateSelectedDevice.Name + '"'} from the account?
                            </span>
                        </div>
                        <br />
                        <br />
                        <div class="col s12 no-margin">
                            <span class="nucleus-labels">
                                Please select one of the following options listed bellow:
                            </span>
                        </div>
                    </div>
                    {
                        (stateSelectedDevice.Assigned) &&
                        <div class="row ">
                            <div class="col s6 no-margin">
                                <span class="nucleus-labels-small">Unassign Device</span>
                            </div>
                            <div class="col s6 no-margin">
                                <span>
                                    <input
                                        //ref="checkUnassignDevice"
                                        type="checkbox"
                                        class="filled-in"
                                        id="checkUnassignDevice"
                                        checked={stateDeviceUnassign}
                                        onChange={(e)=>{
                                            console.log("Checked->", e.target.checked);
                                            setStateDeviceUnassign(e.target.checked);
                                        }}
                                    />
                                    <label for={"checkUnassignDevice"}></label>
                                </span>
                            </div>
                        </div>
                    }
                    {/* <div class="row ">
                        <div class="col s6 no-margin">
                            <span class="nucleus-labels-small">Disenroll from Hexnode</span>
                        </div>
                        <div class="col s6 no-margin">
                            <span>
                                <input
                                    //ref="checkDisenrollHexnode"
                                    type="checkbox"
                                    class="filled-in"
                                    id="checkDisenrollHexnode"
                                    checked={this.state.selectedDeviceDisenrollHexnode}
                                        onChange={(e)=>{
                                            console.log("Checked->", e.target.checked);
                                            this.setState({
                                                selectedDeviceDisenrollHexnode : e.target.checked
                                            });
                                        }}
                                />
                                <label for={"checkDisenrollHexnode"}></label>
                            </span>
                        </div>
                    </div> */}
                    {
                        (stateSelectedDevice.OnHexnode) &&
                        <div class="row ">
                            <div class="col s6 no-margin">
                                <span class="nucleus-labels-small">Remove from Hexnode</span>
                            </div>
                            <div class="col s6 no-margin">
                                <span>
                                    <input
                                        //ref="checkRemoveFromHexnode"
                                        type="checkbox"
                                        class="filled-in"
                                        id="checkRemoveFromHexnode"
                                        checked={stateDeviceRemoveFromHexnode}
                                            onChange={(e)=>{
                                                console.log("Checked->", e.target.checked);
                                                setStateRemoveFromHexnode(e.target.checked);
                                            }}
                                    />
                                    <label for={"checkRemoveFromHexnode"}></label>
                                </span>
                            </div>
                        </div>
                    }
                    <div class="row ">
                        <div class="col s6 no-margin">
                            <span class="nucleus-labels-small">Remove from NucleusCare</span>
                        </div>
                        <div class="col s6 no-margin">
                            <span>
                                <input
                                    //ref="checkRemoveDevice"
                                    type="checkbox"
                                    class="filled-in"
                                    id="checkRemoveDevice"
                                    checked={stateDeviceRemoveFromSystem}
                                        onChange={(e)=>{
                                            console.log("Checked->", e.target.checked);
                                            setStateDeviceRemoveFromSystem(e.target.checked);
                                            (!e.target.checked) && setStateDeviceRemoveFromMDM(false);
                                        }}
                                />
                                <label for={"checkRemoveDevice"}></label>
                            </span>
                        </div>
                    </div>
                    {
                        (stateSelectedDevice.OnNucleusMDM) &&
                        <div class="row ">
                            <div class="col s6 no-margin">
                                <span class="nucleus-labels-small">Remove from Nucleus MDM</span>
                            </div>
                            <div class="col s6 no-margin">
                                <span>
                                    <input
                                        //ref="checkRemoveDeviceFromMDM"
                                        type="checkbox"
                                        class="filled-in"
                                        id="checkRemoveDeviceFromMDM"
                                        checked={stateDeviceRemoveFromMDM}
                                        onChange={(e)=>{
                                            console.log("Checked->", e.target.checked);
                                            setStateDeviceRemoveFromMDM(e.target.checked);
                                        }}
                                        disabled={!stateDeviceRemoveFromSystem}
                                    />
                                    <label for={"checkRemoveDeviceFromMDM"}></label>
                                </span>
                            </div>
                        </div>
                    }
                    
                    <div class="input-field col s12">
                        <br />
                        <div class="row center-align">
                            <div class="col s5 center-align no-margin">
                                <p></p>
                                <a class={"txt-white nucleus-font-medium " + stateDeviceBtnStyle }  onClick={handleCancelDeleteDevice}>Cancel</a>
                                <div>&nbsp;</div>
                            </div>
                            <div class="col s2"></div>
                            <div class="col s5 center-align no-margin">
                                <p></p>
                                <a class={"txt-white nucleus-font-medium " + stateDeviceBtnStyle } onClick={handleDeleteDevice}>Confirm</a>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modalDownloadDeviceLogs" class="modal modalDownloadDeviceLogs">
                <div class="modal-content nucleus-modal-wrapper-scrollable2">
                    <img
                        class="responsive-img img-close-modal"
                        src="img/close_modal.png"
                        onClick={handleCancelDownloadDeviceLogs}
                    />
                    <h3 class="nucleus-page-subtitle"> Download Device Logs</h3>
                    <br />
                    <br />
                    <div class="row no-margin">
                        <div class="col s12 no-margin">
                            <span class="nucleus-labels">
                                Download the log file for the following device {stateSelectedDevice.Mac} ?
                            </span>
                        </div>
                    </div>
                    <div class="input-field col s12 modalCallLogContent2">
                        <br />
                        <div class="row no-margin">
                            <div class="col s12 center-align no-margin">
                                <p></p>
                                <span class=" modalSeparator">
                                    {" "}<label> &nbsp;</label>
                                </span>
                                <a class={"txt-white nucleus-font-small " + " nucleus-submit-btn"} onClick={handleDownloadDeviceLogs}>
                                    Download
                                </a>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modalDeviceDetails" class="modal modalDeviceDetails">
                <div class="modal-content nucleus-modal-wrapper-scrollable2">
                    <img class="responsive-img img-close-modal" src="img/close_modal.png" onClick={handleCloseDeviceDetailsModal}/>
                    <h3 class="nucleus-page-subtitle"> Device Details</h3>
                    <br/><br/>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500"> Pre Assigned Name:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {stateSelectedDeviceDetails.PreAssignedName} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500"> Timezone:</span>
                        </div>
                        <div class="col s8">
                            {/* <span class="nucleus-labels"> {moment(stateSelectedDeviceDetails.Created).format("MM/DD/YYYY hh:mm A")} </span> */}
                            <span class="nucleus-labels"> {stateSelectedDeviceDetails.Timezone} </span>
                        </div>
                    </div>
                    {/* <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500"> Battery:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(stateSelectedDeviceDetails.Battery ? (stateSelectedDeviceDetails.Battery + " %") : "-") } </span>
                        </div>
                    </div> */}
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500"> Wifi:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(stateSelectedDeviceDetails.DeviceDetails ? stateSelectedDeviceDetails.DeviceDetails.Wifi : "")} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">Brand:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {stateSelectedDeviceDetails.DeviceInfo.brand} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">Model:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {stateSelectedDeviceDetails.DeviceInfo.model} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">SIM Number:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(stateSelectedDeviceDetails.DeviceDetails ? stateSelectedDeviceDetails.DeviceDetails.Sim: "")} </span>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">Hexnode Info</span>
                        </div>
                        <div class="col s8">
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">OS version:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(stateSelectedDeviceDetails.DeviceInfo ? (stateSelectedDeviceDetails.DeviceInfo.systemName + " " + stateSelectedDeviceDetails.DeviceInfo.systemVersion): "")} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">Serial Number:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(stateSelectedDeviceDetails.DeviceDetails ? stateSelectedDeviceDetails.DeviceDetails.SerialNumber: "")} </span>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 no-margin">
                            <span class="nucleus-labels bold-500">SIM Carrier Network:</span>
                        </div>
                        <div class="col s8">
                            <span class="nucleus-labels"> {(stateSelectedDeviceDetails.DeviceDetails ? stateSelectedDeviceDetails.DeviceDetails.SimCarrier : "")} </span>
                        </div>
                    </div>
                    {/* {
                        generateDeviceDetails()
                    } */}
                </div>
            </div>

        </div>
    );
}

export default Devices;
