import React from 'react';
import ReactDOM from "react-dom";
import Dispatcher from "../Dispatcher";
import { IndexLink, Link } from "react-router-dom";

import GeneralStore from "../stores/GeneralStore";
import Message from "../utils/Message";

//import _ from "underscore";

class AccountItemRow extends React.Component
{
    constructor(props)
    {
        super();
        this.state = {
            userID : null,
            userName:'',
            onDuty : false
        };
        this.handleRowClicked = this.handleRowClicked.bind(this);
    }

    componentDidMount()
    {
        this.setState({
            userID : this.props.UserID,
            userName: this.props.FirstName + ' ' + this.props.LastName,
            onDuty :this.props.IsOnDuty
        });   
    }

    componentWillUnmount()
    {
        //Remove Listener

    }

    handleRowClicked(){
        window.location.assign("#/accountDetails/" + this.props.ID);
        GeneralStore.setClientTab(1);
    }

    render()
    {
        //let companyLogoUrl = this.props.Logo ? this.props.Logo  : "";
        
        let accountName = this.props.Name;
        if (accountName != null && accountName.length > 22){
            accountName = accountName.substring(0, 22) + " ...";
        }

        //let showCallButtonValue = this.props.ShowCallButton ? "Y" : "N";
        //let showCheckInButtonValue = this.props.ShowCheckInButton ? "Y" : "N";
        //let showEmergencyButtonValue = this.props.ShowEmergencyButton ? "Y" : "N";
        //let ringOnRequestValue = this.props.RingOnRequest ? "Y" : "N";
        //let enableBiometricsValue = this.props.EnableBiometrics ? "Y" : "N";

        // DEPRECATED:
        // 
        // let updateGroupName = this.props.UpdateGroupName;
        // if (updateGroupName != null && updateGroupName.length > 22){
        //     updateGroupName = updateGroupName.substring(0, 22) + " ...";
        // }

        return (
            <tr onClick={this.handleRowClicked}>
                {/*
                <td class="nucleus-row-small center-align">
                    <img src={companyLogoUrl} class="accountLogo"/>
                </td>
                */}
                <td class="nucleus-row" >{ this.props.AccountGroupName }</td>
                <td class="nucleus-row" >{ accountName }</td>
                <td class="nucleus-row center-align" >{ this.props.TotalPatients }</td>

                <td class="nucleus-row center-align" >{ this.props.TotalDevices }</td>
                <td class="nucleus-row center-align" >{ this.props.TotalOnlineDevices }</td>
                <td class="nucleus-row center-align" >{ this.props.TotalBiometricPatients }</td>
                <td class="nucleus-row center-align" >{ this.props.TotalUsers }</td>
                
            </tr>
        );
    }
}

export default AccountItemRow;
