import React from 'react';
import AuthStore from '../stores/AuthStore';
import DashboardContent from '../components/DashboardContent';
import '../../css/nucleus_care_customer_support.css';

interface DashboardState {
  csUserID: string;
  userFullName: string;
}


class Dashboard extends React.Component<{}, DashboardState> {
  constructor(props:{}) {
    super(props);

    this.state = {
      csUserID: AuthStore.getCsUserID(),
      userFullName: AuthStore.getCsUserEmail(),
      //    isNewSession : AuthStore.getIsNewSession(),
      //    companyName : AuthStore.getCompanyName(),
      //    companyLogo : AuthStore.getCompanyLogo()
    };
  }

  componentDidMount() {
    if (AuthStore.getIsNewSession().toString() !== 'yes') {
      AuthStore.clearIsNewSession();
    }
  }

  render() {
    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <div className="row ">
            <div className="col s8 no-margin">
              <p className="nucleus-page-title"> Nucleus Admin - Welcome {this.state.userFullName}!!</p>
            </div>
            <div className="col s4 right-align "> </div>
          </div>
          <DashboardContent />
        </div>
      </div>
    );
  }
}

export default Dashboard;
