import axios from 'axios'
import { axios as authAxios } from '@nucleus-care/react-native-nucleuscare-connect-security-client'
import { httpApi } from '@nucleus-care/nucleuscare-backend-client'

function handleError (error) {
  const { response = {} } = error
  const url = response.config && response.config.url

  if (response.status === 401 && url !== '/cs_user/me') {
    if (!url.includes('reset_cs_password')){
      return window.location.href = '/'
    }
  }
  return Promise.reject(error)
}

export function configureUnauthorizedInterceptor () {
  axios.interceptors.response.use((response) => response, handleError)
  authAxios.interceptors.response.use((response) => response, handleError)
  httpApi.interceptors.response.use((response) => response, handleError)
}
