import React from 'react';
import ReactDOM from "react-dom";
import CareAccountStore from "../stores/CareAccountStore";
import Message from "../utils/Message";
import AuthStore from "../stores/AuthStore";
import Dispatcher from "../Dispatcher";
import moment from 'moment';

class Tools extends React.Component {
	
	constructor() {
        super();

        this.state = {
        	accountName : '',
        	patientName : '',
        	deviceName : '',
        	deviceVersion : '',
        	lastConnected : '',
        	errorMessage: ''
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitAction = this.onSubmitAction.bind(this);
    }

    componentDidMount() {

		if (!AuthStore.superUser()){
			window.location.assign("#/dashboard");
		}
    	CareAccountStore.on("onSubmitData", this.onSubmitAction);
    }

    componentWillUnmount(){
    	CareAccountStore.removeListener("onSubmitData", this.onSubmitAction);
    }

	onSubmit(){
		let reg = /^[0-9A-Fa-f]{1,2}([\.:-])(?:[0-9A-Fa-f]{1,2}\1){4}[0-9A-Fa-f]{1,2}$/;
		let macAddress = this.refs.txtMacAddress.value;
		console.log("onSubmit");
		if (!macAddress == ''){
			//if (reg.test(macAddress)){
				CareAccountStore.getAccountDataByMac({
					Mac: macAddress
				});
			// } else {
			// 	this.setState({
			// 		errorMessage : 'Invalid MAC address',
			// 		accountName : '',
	  //       		patientName : '',
	  //       		deviceName : '',
	  //       		deviceVersion : '',
	  //       		lastConnected : '',
			// 	});
			// }
		} else {
			this.setState({
				errorMessage : 'Please, enter a MAC address',
				accountName : '',
        		patientName : '',
        		deviceName : '',
        		deviceVersion : '',
        		lastConnected : '',
			});
		}
	}

	onSubmitAction(response){
		console.log("onSubmitAction");
        console.log(response);

		if (response.ok){
			let offset = (response.Offset * 60);
			let timezone = response.Timezone == null ? ' ' : response.Timezone;
			let lastOnLineDate = response.LastOnLineDate == null ? ' ' : moment.utc(response.LastOnLineDate).utcOffset(offset).format("MM/DD/YYYY h:mm a") + " - " + timezone; 
			console.log("offset: ", offset);
			this.setState({
				accountName : response.AccountName,
	        	patientName : response.PatientName,
	        	deviceName : response.DeviceName,
	        	deviceVersion : response.Version,
	        	lastConnected : lastOnLineDate,
	        	errorMessage : ''
			});
		} else if (response.error){
			this.setState({
				errorMessage : response.error,
				accountName : '',
        		patientName : '',
        		deviceName : '',
        		deviceVersion : '',
        		lastConnected : '',
			});
		} else {
			Message.show("There was an error getting the account data");
		}
	}

	render(){
		let errorMessage = this.state.errorMessage;
		return(
			 <div class="nucleus-tools-container">
                <p class="nucleus-tools-page-title"><b>Tools</b></p>
                <div class="bit-top-separated">
                   	<span class="nucleus-tools-page-text-title" style={{fontSize:18}}>Retrieve Device Information</span>
                </div>

                <div class="col s5 no-margin">
	                <div class="row vbottom-align no-margin top-separated">
	                    <div class="col s3 no-margin">
	                        <span class="nucleus-tools-page-text-title">MAC Address </span>
	                    </div>
	                    <div class="col s6 no-margin">
	                        <input ref="txtMacAddress" type="text" class="validate nucleus-input-form accountTextFull"/>
	                    </div>
	                    <div class="col s3 no-margin a-little-right">
	                        <a class="txt-white nucleus-font-small nucleus-submit-btn-tools" onClick={this.onSubmit}> Submit </a>
	                    </div>
	                </div>
                </div>
				<div class="col s12 no-margin"></div>
                <div class="col s5 no-margin">
					<div class="col s3 no-margin">
	                </div>
					<div class="col s9 no-margin" key={this.state.errorMessage}>
						<span class="nucleus-form-error-tools">{this.state.errorMessage}</span>
					</div>
                </div>
                
                <div class="col s11 m11 l11 xl11 no-margin">
                	<div class="col s4 m4 l4 xl4 no-margin top-separated">
                		<div class="row vbottom-align no-margin top-separated">
                			<span class="nucleus-tools-page-text-title">Account Name: </span>
                			<span class="nucleus-tools-page-text-label a-more-right">{this.state.accountName}</span>
                		</div>
                		<div class="row vbottom-align no-margin top-separated">
                			<span class="nucleus-tools-page-text-title">Patient Name: </span>
                			<span class="nucleus-tools-page-text-label a-more-right-21">{this.state.patientName}</span>
                		</div>
                		<div class="row vbottom-align no-margin top-separated">
                			<span class="nucleus-tools-page-text-title">Device Name: </span>
                			<span class="nucleus-tools-page-text-label a-more-right-23">{this.state.deviceName}</span>
                		</div>
                	</div>
                	<div class="col s4 m4 l4 xl4 no-margin top-separated">
                		<div class="row vbottom-align no-margin top-separated">
                			<span class="nucleus-tools-page-text-title"> Device Version: </span>
                			<span class="nucleus-tools-page-text-label a-more-right">{this.state.deviceVersion}</span>
                		</div>
                		<div class="row vbottom-align no-margin top-separated">
                			<span class="nucleus-tools-page-text-title"> Last Connected: </span>
                			<span class="nucleus-tools-page-text-label a-few-right">{this.state.lastConnected}</span>
                		</div>
                	</div>
                </div>
            </div>
		);
	}
}

export default Tools;