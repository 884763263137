import React, { useState, useEffect } from 'react';
import General from './General';
import Networks from './Networks';
import Apps from './Apps';
import Screen from './Screen';
import Security from './Security';
import MDMNavTabs from './components/MDMNavTabs';
import MDMPolicyFooter from './components/MDMPolicyFooter';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import {
  fetchMdmPolicyDetailsData,
  resetMDMPolicyState,
  resetFetchMdmPolicyDetailsDataState,
  resetSuccessFormState,
  setAlreadySavedJSON,
  setAlreadyPolicyInformation,
  setPolicyFormStatus,
  setPolicyInformation,
  setPolicyJSON,
  setPolicyScreen,
  resetPolicyJsonState,
} from '@nucleus-care/nucleuscare-backend-client';
import { SpinnerCircular } from 'spinners-react';
import message from '../../utils/Message';

const MDMPolicy = () => {
  const location = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [policyName, setPolicyName] = useState('');
  const [lastLocation, setLastLocation] = useState(null);
  const [mainCancelClicked, setMainCancelClicked] = useState(false);
  const [dontSaveClicked, setDontSaveClicked] = useState(false);
  const [ShowUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState(false);
  const {
    loadingPolicyDetails,
    successPolicyDetails,
    errorPolicyDetails,
    PolicyDetails,
    create_success,
    update_success,
    error,
  } = useSelector(({ mdmPolicy }) => mdmPolicy.data);

  // log PolicyDetails
  useEffect(() => {
    console.log('PolicyDetails', PolicyDetails);
  }, [PolicyDetails]);
  const {
    policyFormStatus,
    policyScreen,
    policyJSON,
    policyInformation,
    alreadyPolicyInformation,
    alreadySavedJSON,
  } = useSelector(({ mdmPolicy }) => mdmPolicy.state);

  useEffect(() => {
    if (policyFormStatus.operationType === 'CREATE') return;
    if (loadingPolicyDetails) return;
    if (successPolicyDetails) {
      // console.log("PolicyDetails", PolicyDetails);
      if (PolicyDetails?.PolicyTemplate?.PolicyDetails) {
        const policyJSONParsed = JSON.parse(
          PolicyDetails.PolicyTemplate.PolicyDetails,
        );
        dispatch(setPolicyJSON(policyJSONParsed));
        dispatch(setAlreadySavedJSON(policyJSONParsed));
        setPolicyName(PolicyDetails.PolicyTemplate.Name);
      }
      if (PolicyDetails?.PolicyTemplate) {
        dispatch(
          setPolicyFormStatus({
            operationType: 'UPDATE',
            PolicyTemplateID: PolicyDetails.PolicyTemplate.ID,
            isEditable: !PolicyDetails.PolicyTemplate.Protected ?? true,
            isProtectedPolicy: PolicyDetails.PolicyTemplate.Protected,
          }),
        );
        dispatch(
          setPolicyInformation({
            EnterpriseID: PolicyDetails.PolicyTemplate.EnterpriseID,
            Version: Number(PolicyDetails.PolicyTemplate.Version),
            Name: PolicyDetails.PolicyTemplate.Name,
          }),
        );
        dispatch(
          setAlreadyPolicyInformation({
            EnterpriseID: PolicyDetails.PolicyTemplate.EnterpriseID,
            Version: Number(PolicyDetails.PolicyTemplate.Version),
            Name: PolicyDetails.PolicyTemplate.Name,
          }),
        );
      }
    }
  }, [
    loadingPolicyDetails,
    successPolicyDetails,
    errorPolicyDetails,
    PolicyDetails,
  ]);

  useEffect(() => {
    dispatch(setPolicyScreen({ activeMainScreen: 'policies' }));
    if (policyFormStatus?.operationType == 'UPDATE') {
      console.log('Editing policy');
      const clickedPolicyID = location.params.policyId;
      dispatch(fetchMdmPolicyDetailsData(clickedPolicyID));
    }
  }, [policyFormStatus.operationType]);

  useEffect(() => {
    // TOODO - why should checking both of these be necessary?
    if (
      policyFormStatus.operationType === 'CREATE' ||
      location.path.includes('/newPolicy')
    ) {
      dispatch(
        setPolicyFormStatus({
          operationType: 'CREATE',
          isProtectedPolicy: false,
          isEditable: true,
          PolicyTemplateID: undefined,
        }),
      );
      return;
    }
    const clickedPolicyID = location.params.policyId;
    dispatch(fetchMdmPolicyDetailsData(clickedPolicyID));
    dispatch(
      setPolicyFormStatus({
        operationType: 'UPDATE',
        PolicyTemplateID: clickedPolicyID,
      }),
    );
  }, [location]);

  const handleTabs = (type, tabName) => {
    switch (type) {
      case 'main':
        history.push('/policies');
        if (tabName === 'policies') {
          dispatch(
            setPolicyScreen({
              activeMainScreen: 'policies',
              activeMDMScreen: 'general',
            }),
          );
        }
        dispatch(setPolicyScreen({ activeMainScreen: tabName }));
        break;
      case 'policies':
        dispatch(setPolicyScreen({ activeMDMScreen: tabName }));
        break;
      default:
        break;
    }
  };

  const renderTabContent = () => {
    if (loadingPolicyDetails) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <SpinnerCircular
            color="#2096F3"
            secondaryColor="rgba(0,0,0,0.16)"
            size="50"
            thickness="100"
          />
        </div>
      );
    }
    switch (policyScreen.activeMDMScreen) {
      case 'general':
        return <General />;
      case 'network':
        return <Networks policyDetails={PolicyDetails} />;
      case 'screen':
        return <Screen policyDetails={PolicyDetails} />;
      case 'security':
        return <Security policyDetails={PolicyDetails} />;
      case 'apps':
        return <Apps />;
    }
  };
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);

  useEffect(() => {
    return () => {
      dispatch(resetPolicyJsonState());
      dispatch(resetFetchMdmPolicyDetailsDataState());
    };
  }, []);

  useEffect(() => {
    if (loadingPolicyDetails) return;
    if (mainCancelClicked) {
      dispatch(resetPolicyJsonState());
      history.push('/policies');
      return;
    }
    const isPolicyJSONChanged =
      JSON.stringify(policyJSON) !== JSON.stringify(alreadySavedJSON);
    const isPolicyInformationChanged =
      JSON.stringify(policyInformation) !==
      JSON.stringify(alreadyPolicyInformation);
    const isPolicyChanged = isPolicyJSONChanged || isPolicyInformationChanged;
    setHasUnsavedChanges(isPolicyChanged);
    if (location.path.includes('/newPolicy')) {
      console.log('isPolicyChanged', isPolicyChanged);
      if (mainCancelClicked && !isPolicyChanged) {
        dispatch(resetPolicyJsonState());
        return history.push('/policies');
      }
    }
    if (!isPolicyChanged) return;

    const unblock = history.block((location) => {
      if (dontSaveClicked) {
        dispatch(resetPolicyJsonState());
        setLastLocation(location.pathname);
        setShowUnsavedChangesAlert(false);
        return true;
      }
      if (mainCancelClicked || create_success || update_success) {
        dispatch(resetPolicyJsonState());
        return true;
      }
      if (!mainCancelClicked || (isPolicyChanged && !update_success)) {
        setLastLocation(location.pathname);
        setShowUnsavedChangesAlert(true);
        return false;
      }
      return undefined;
    });

    return () => {
      unblock();
    };
  }, [
    loadingPolicyDetails,
    history,
    policyJSON,
    alreadySavedJSON,
    create_success,
    update_success,
    mainCancelClicked,
    policyInformation,
  ]);

  useEffect(() => {
    if (dontSaveClicked || create_success || update_success) {
      if (create_success || update_success) {
        console.log('created/update success');
        const messageToShow =
          policyFormStatus.operationType === 'UPDATE'
            ? 'Your policy has been updated'
            : 'Your policy has been created';
        message.show(messageToShow);
        dispatch(setAlreadySavedJSON(policyJSON));
        dispatch(setAlreadyPolicyInformation(policyInformation));
        dispatch(
          setPolicyScreen({
            activeMainScreen: 'policies',
            activeMDMScreen: 'general',
          }),
        );
        history.push('/policies');
      }
      if (lastLocation) {
        history.push(lastLocation);
      } else {
        dispatch(resetPolicyJsonState());
        dispatch(
          setPolicyScreen({
            activeMainScreen: 'policies',
            activeMDMScreen: 'general',
          }),
        );
        history.push('/policies');
      }

      setTimeout(() => {
        console.log('LINE:280');
        if (lastLocation) {
          dispatch(resetPolicyJsonState());
          if (lastLocation === '/policyDetails')
            return history.push('/policies');
          dispatch(
            setPolicyScreen({
              activeMainScreen: 'policies',
              activeMDMScreen: 'general',
            }),
          );
          history.push(lastLocation);
        } else {
          dispatch(resetPolicyJsonState());
          dispatch(
            setPolicyScreen({
              activeMainScreen: 'policies',
              activeMDMScreen: 'general',
            }),
          );
          history.push('/policies');
        }
      }, 500);
    }
    if (error) {
      message.show('An error has occurred');
    }
    return () => {
      dispatch(resetSuccessFormState());
    };
  }, [
    lastLocation,
    history,
    create_success,
    update_success,
    error,
    dontSaveClicked,
  ]);

  const handleDontSave = () => {
    setDontSaveClicked(true);
    dispatch(resetPolicyJsonState());
    setShowUnsavedChangesAlert(false);
  };

  return (
    <React.Fragment>
      <div className="nucleus-tools-container">
        <div className="">
          <p class="nucleus-page-title">
            <span></span>
          </p>
          <div className="row a-bit-lower left-align">
            <MDMNavTabs activeTab={'policies'} type="main" />
            <div class="nucleus-news-separator">&nbsp;</div>
            <div
              style={{
                marginTop: '30px',
              }}
            >
              <div className="nucleus-tools-container">
                <div className="">
                  <p class="nucleus-page-title">
                    <span>
                      {policyFormStatus.operationType === 'CREATE'
                        ? 'New Policy'
                        : `Policy: ${policyName}`}
                    </span>
                  </p>
                  <div className="row a-bit-lower left-align">
                    <MDMNavTabs
                      activeTab={policyScreen.activeMDMScreen}
                      handleTabs={handleTabs}
                      type="policies"
                    />
                    <div class="nucleus-news-separator">&nbsp;</div>
                    <div
                      style={{
                        marginTop: '30px',
                      }}
                    >
                      {renderTabContent()}
                    </div>
                  </div>
                  <MDMPolicyFooter
                    setMainCancelClicked={setMainCancelClicked}
                    setShowUnsavedChangesAlert={setShowUnsavedChangesAlert}
                    showUnsavedChangesAlert={ShowUnsavedChangesAlert}
                    handleDontSave={handleDontSave}
                    disableButtons={
                      PolicyDetails?.PolicyTemplate?.Protected === true
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MDMPolicy;
