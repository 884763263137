import "react-toastify/dist/ReactToastify.css";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const NucleusToastContainer = () => {
  return (
    <ToastContainer
      position="top-right"
      progressStyle={{
        backgroundColor: "#007fff",
      }}
      autoClose={3500}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover={false}
      icon={false}
      theme="colored"
      style={{ width: "480px", verticalAlign: "middle" }}
      bodyClassName={() => "nucleus-page-toast"}
    />
  );
};

export default NucleusToastContainer;
