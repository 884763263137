class AuthApi {

	constructor(context, authClient) {
		this.context = context;
    this.authClient = authClient
	}

  async logout () {
    await this.authClient.logoutCs()
  }

	login (data,callback) {
    console.warn("AuthApi.login request");
    this
      .authClient
      .loginWithCredentialsCs(data.Email, data.Password)
      .then((data) => {
        console.log("loginWithCredentialsCs ==========>", data);
        callback({
          status: data.status,
          ...data.data
        })
      })
      .catch((e) => {
        console.log("Error in Login e", e);
        if (e != undefined){
          const { data } = (e && e.response) ? e.response : e.message ;
          if (data){
            console.log("Error in Login data", data);
            callback({
              status: data?.status ?? 'failed'
            })
          }
        }
      })
	}
  //<--------------------------- Request Reset Password ----------------------------->
	requestResetPassword (data,callback) {
    let response
    return this
      .authClient
      .requestResetCsUserPassword(data.Email)
      .then(() => {
        response = {
          ok: true,
          existingUser: true
        }
      })
      .catch((e) => {
        console.log("T error", e)
        response = {
          ok: false,
          existingUser: true,
        }
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.code === 6) {
            response.existingUser = false
          }
        }
      })
      .finally(() => callback(response))
	}

  resetTempPassword(paramsData,callback) {
    console.log("Cs > resetTempPassword", paramsData);
    let backendResponse;
    return this
      .authClient
      .resetTempPassword(paramsData)
      .then((response) => {
        console.log("resetTempPassword API response", response);
        backendResponse = {
          ok:true,
          ...response.data
        };
      })
      .catch((e) => {
        console.log("resetTempPassword API catch", e);
        let data = {};
        if (e && e.response && e.response.data){
          data = e.response.data
        }
        backendResponse = {
          ok:false,
          ...data
        }
      })
      .finally(() => {
        callback(backendResponse)
      })
	}

  resetPassword2 (paramsData,callback) {
    console.log("Cs > resetPassword2", paramsData);
    return this
      .authClient
      .resetCsUserPassword(paramsData)
      .then((response) => {
        console.log("resetPassword API Point 3.1", response);
        callback({
          ok:true,
          PasswordUpdated : true
        })
      })
      .catch((e) => {
        console.log("resetPassword API Point 3.2", e);
        let data = {};
        if (e && e.response){
          data = {
            error: e.response
          }
        }
        callback({
          status: data.status,
          ok:false,
          PasswordUpdateError: true,
          ...data.data
        })
      })
	}

  createNewCsUser (paramsData,callback) {
    console.log("Cs > createNewCsUser", paramsData);
    return this
      .authClient
      .createCsUser(paramsData)
      .then((response) => {
        console.log("createNewCsUser API>", response);
        callback({
          ok:true
        });
      })
      .catch((e) => {
        console.log("createNewCsUser API", e);
        console.log("createNewCsUser API", e.response);
        let data = {};
        if (e && e.response && e.response.data){
          data = e.response.data
        }
        callback({
          ok:false,
          ...data
        })
      })
	}

}


export default AuthApi;
