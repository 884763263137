import React from 'react';
import AuthStore from '../stores/AuthStore';
import CareAccountStore from '../stores/CareAccountStore';
import NucleusModal from './NucleusModal';
import AccountPatientsTimezoneRow from './AccountPatientsTimezoneRow';
import NucleusButtonV2 from './NucleusButtonV2';
import Message from '../utils/Message';
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { Link } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';

interface Props {
  accountId: string;
  patientsLabel: string;
}

interface Patient {
  ID: number;
  FirstName: string;
  PatientName: string;
  TimezoneOffset: number;
  TimezoneString: string;
}

interface Account {
  Name: string;
  ID: string;
}

interface User {
  name: string;
  value: string;
}

interface State {
  patientsTabArray: Patient[];
  selectedRows: number[];
  loading: boolean;
  results: boolean;
  emptyError: boolean;
  searchText: string;
  accountSearchText: string;
  accountSelected: boolean;
  selectedAccount: string;
  accountSearchResult: Account[];
  moveMessages: boolean;
  accountsArray: Account[];
  moveModalOpen: boolean;
  sortPatientDesc: string;
  sortPatientAsc: string;
  modalBtnStyle: string;
  selectedPatientID: number | null;
  selectedPatientName: string | null;
  selectedPatientTimezoneOffset: number | null;
  selectedPatientTimezoneString: string | null;
  modalLoadingText: string;
  userSearchText: string;
  userSelected: boolean;
  selectedUser: string;
  usersSearchResult: User[];
  usersArray: User[];
  loadingPatientMove: boolean;
}

class AccountPatients extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      patientsTabArray: [],
      selectedRows: [],
      loading: false,
      loadingPatientMove: false,
      results: false,
      emptyError: false,
      searchText: '',
      accountSearchText: '',
      accountSelected: false,
      selectedAccount: '',
      accountSearchResult: [],
      accountsArray: [],
      usersArray: [],
      moveModalOpen: false,
      moveMessages: false,
      userSearchText: '',
      userSelected: false,
      selectedUser: '',
      usersSearchResult: [],
      sortPatientDesc: ' nucleus-icon-inactive hide ',
      sortPatientAsc: ' nucleus-icon-inactive ',
      modalBtnStyle: 'nucleus-submit-btn',
      selectedPatientID: null,
      selectedPatientName: null,
      selectedPatientTimezoneOffset: null,
      selectedPatientTimezoneString: null,
      modalLoadingText: '',
    };

    this.sortedArray1 = [];
    this.sortedArray2 = [];
    this.onGetAllAccountsDataAction = this.onGetAllAccountsDataAction.bind(this);
    this.onGetAccountPatientsAction = this.onGetAccountPatientsAction.bind(this);
    this.handleSortPatientAsc = this.handleSortPatientAsc.bind(this);
    this.handleSortPatientDesc = this.handleSortPatientDesc.bind(this);
    this.resetSortIconsState = this.resetSortIconsState.bind(this);
    this.onRowClicked = this.onRowClicked.bind(this);

    // Timezones changes https://www.timeanddate.com/time/change/usa
    this.isDST = false;
    this.dstStartDate = null;
    this.dstEndDate = null;

    this.StandardTimezones = [
      { valueString: '+1', name: '(GMT+01) Central European Time - Stockholm', shortName: 'CET', selected: false },
      { valueString: '+2', name: '(GMT+02) Israel Standard Time - Jerusalem', shortName: 'IST', selected: false },
      { valueString: '-5', name: '(GMT-05) Eastern Standard Time - New York', shortName: 'EST', selected: false },
      { valueString: '-6', name: '(GMT-06) Central Standard Time - Chicago', shortName: 'CST', selected: false },
      { valueString: '-7', name: '(GMT-07) Mountain Standard Time - Salt Lake City', shortName: 'MST', selected: false },
      { valueString: '-8', name: '(GMT-08) Pacific Standard Time - Los Angeles', shortName: 'PST', selected: false },
      { valueString: '-9', name: '(GMT-09) Alaska Standard Time - Anchorage', shortName: 'AKST', selected: false },
      { valueString: '-10', name: '(GMT-10) Hawaii Standard Time - Honolulu', shortName: 'HST', selected: false },
    ];

    this.DaylightTimezones = [
      { valueString: '+2', name: '(GMT+02) Central European Summer Time - Stockholm', shortName: 'CEST', selected: false },
      { valueString: '+3', name: '(GMT+03) Israel Daylight Time - Jerusalem', shortName: 'IDT', selected: false },
      { valueString: '-4', name: '(GMT-04) Eastern Daylight Time - New York', shortName: 'EDT', selected: false },
      { valueString: '-5', name: '(GMT-05) Central Daylight Time - Chicago', shortName: 'CDT', selected: false },
      { valueString: '-6', name: '(GMT-06) Mountain Daylight Time - Salt Lake City', shortName: 'MDT', selected: false },
      { valueString: '-7', name: '(GMT-07) Pacific Daylight Time - Los Angeles', shortName: 'PDT', selected: false },
      { valueString: '-8', name: '(GMT-08) Alaska Daylight Time - Anchorage', shortName: 'AKDT', selected: false },
      { valueString: '-9', name: '(GMT-09) Hawaii Daylight Time - Honolulu', shortName: 'HDT', selected: false },
    ];

    this.StandardTimezonesKeys = {
      '+1': 'CET',
      '+2': 'IST',
      '-5': 'EST',
      '-6': 'CST',
      '-7': 'MST',
      '-8': 'PST',
      '-9': 'AKST',
      '-10': 'HST',
    };

    this.DaylightTimezonesKeys = {
      '+2': 'CEST',
      '+3': 'IDT',
      '-4': 'EDT',
      '-5': 'CDT',
      '-6': 'MDT',
      '-7': 'PDT',
      '-8': 'AKDT',
      '-9': 'HDT',
    };

    this.filterTimezonesArray = [];
    this.messageFilterTimezonesArray = [];
    this.selectTimeZoneRef = React.createRef();
  }
  getUsers = async () => {
    try {
      const response = await httpApi.get('account/' + this.state.selectedAccount + '/users-catalog');
      const { data } = response;
      const { users } = data;
      this.setState({ usersArray: users });
    } catch (error) {
      Message.show('Error getting users: ', error);
    }
  };

  componentDidMount() {
    console.log('Patient componentDidMount', this.props.accountId);

    //Register Listener
    CareAccountStore.on('onGetAllAccountsData', this.onGetAllAccountsDataAction);
    CareAccountStore.on('onGetAccountPatients', this.onGetAccountPatientsAction);
    //CarePatientStore.on("onUpdatePatientTimeZone",this.onUpdatePatientTimeZoneAction);

    this.setState({
      loading: true,
    });
    CareAccountStore.getAccountPatients({
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
      AccountID: this.props.accountId,
    });
    CareAccountStore.getAllAccountsData({
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
    });
    this.getDSTDates();
    window.$('.modal').modal({
      dismissible: false,
      complete: () => {},
    });
  }

  componentWillUnmount() {
    //Remove Listener
    CareAccountStore.removeListener('onGetAllAccountsData', this.onGetAllAccountsDataAction);
    CareAccountStore.removeListener('onGetAccountPatients', this.onGetAccountPatientsAction);
  }

  getDSTDates = () => {
    console.log('PatientTimezones getDSTDates');
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();

    let firstOfMarch = new Date(currentYear, 2, 1);
    let daysUntilFirstSundayInMarch = (7 - firstOfMarch.getDay()) % 7;
    let secondSundayInMarch = firstOfMarch.getDate() + daysUntilFirstSundayInMarch + 7;
    this.dstStartDate = new Date(currentYear, 2, secondSundayInMarch);

    let firstOfNovember = new Date(currentYear, 10, 1);
    let daysUntilFirstSundayInNov = (7 - firstOfNovember.getDay()) % 7;
    let firstSundayInNovember = firstOfNovember.getDate() + daysUntilFirstSundayInNov;
    this.dstEndDate = new Date(currentYear, 10, firstSundayInNovember);

    let isDST = false;
    let today = new Date();
    if (this.dstStartDate != null && this.dstEndDate != null && today >= this.dstStartDate && today <= this.dstEndDate) {
      isDST = true;
      this.isDST = true;
    }
    console.log('getIsDST =>', isDST);
    this.filterTimezonesArray = isDST ? this.DaylightTimezones : this.StandardTimezones;
  };

  onGetAccountPatientsAction(response) {
    console.log('onGetAllPatientsData');
    console.log(response);

    if (response.ok) {
      this.sortedArray1 = [].concat(response.Data);
      this.sortedArray1 = this.sortedArray1.sort((a, b) => {
        let textA = a.FirstName ? a.FirstName.toLowerCase() : '';
        let textB = b.FirstName ? b.FirstName.toLowerCase() : '';
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });

      this.sortedArray2 = [].concat(response.Data);
      this.sortedArray2 = this.sortedArray2.sort((a, b) => {
        let textA = a.FirstName ? a.FirstName.toLowerCase() : '';
        let textB = b.FirstName ? b.FirstName.toLowerCase() : '';
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.setState({
        patientsTabArray: this.sortedArray2,
        loading: false,
        sortPatientAsc: ' nucleus-icon-active ',
        sortPatientDesc: ' nucleus-icon-inactive hide ',
      });
    } else {
      Message.show('Error getting account patients, please try again.');
    }
  }

  handleSortPatientDesc() {
    console.log('handleSortPatientDesc');

    this.resetSortIconsState();

    this.setState({
      sortPatientAsc: ' nucleus-icon-inactive hide ',
      sortPatientDesc: ' nucleus-icon-active ',
      patientsTabArray: this.sortedArray1,
    });
  }

  handleSortPatientAsc() {
    console.log('handleSortPatientAsc');
    this.resetSortIconsState();
    this.setState({
      sortPatientAsc: ' nucleus-icon-active ',
      sortPatientDesc: ' nucleus-icon-inactive hide ',
      patientsTabArray: this.sortedArray2,
    });
  }

  resetSortIconsState() {
    console.log('resetSortIconsState');

    this.setState({
      sortPatientAsc: ' nucleus-icon-inactive ',
      sortPatientDesc: ' nucleus-icon-inactive hide ',
    });
  }

  onRowClicked = (patientData) => {
    console.log('onRowClicked patientData', patientData);

    this.setState({
      selectedPatientID: patientData.ID,
      selectedPatientName: patientData.PatientName,
      selectedPatientTimezoneOffset: patientData.TimezoneOffset,
      selectedPatientTimezoneString: patientData.TimezoneString,
    });
    window.$('#modalPatientTimezone').modal('open');

    if (patientData.TimezoneOffset != null) {
      let defaultTimezone = patientData.TimezoneOffset.toString();
      console.log('TIMEZONE FORMAT 1 :', defaultTimezone);
      this.selectTimeZoneRef.current.value = defaultTimezone;
    } else {
      let today = new Date();
      let currentTimezone = (today.getTimezoneOffset() / 60) * -1;

      let formatedTimezone = '' + currentTimezone;
      if (currentTimezone > 0) {
        formatedTimezone = '+' + currentTimezone;
      }
      console.log('TIMEZONE FORMAT 2 :', formatedTimezone);
      this.selectTimeZoneRef.current.value = formatedTimezone;
    }
  };

  handleEditClicked = (onClick, data) => {
    onClick(data);
  };

  handlePatientsRowClick = (PatientID) => {
    CareAccountStore.setFamilyMemberAccountId(this.props.accountId);
    window.location.assign('#/accountUserPatientProfile/' + PatientID + '/' + this.props.accountId);
    console.log('clicked row');
  };

  handleCheckboxes = (data) => {
    this.setState((prevState) => {
      const { selectedRows } = prevState;
      if (selectedRows.includes(data.PatientID)) {
        return {
          selectedRows: selectedRows.filter((id) => id !== data.PatientID),
        };
      } else {
        return {
          selectedRows: [...selectedRows, data.PatientID],
        };
      }
    });
  };

  getTableRows = () => {
    console.log('render getTableRows>');

    let patientRowArray = [];
    if (this.state.patientsTabArray && this.state.patientsTabArray.length > 0) {
      let array = [].concat(this.state.patientsTabArray);
      array.forEach((patient) => {
        patientRowArray.push(
          <AccountPatientsTimezoneRow
            key={patient.PatientID}
            data={patient}
            onClick={this.onRowClicked}
            editOnClick={this.handleEditClicked}
            handlePatientsRowClick={this.handlePatientsRowClick}
            handleCheckboxes={this.handleCheckboxes}
            selectedRows={this.state.selectedRows}
            type="patients"
          />,
        );
      });
      return <tbody className="tbody-ss">{patientRowArray}</tbody>;
    }
    return (
      <tbody className="center-align">
        <tr style={{ borderBottomStyle: 'none' }}>
          <td colSpan={6} className="center-align ">
            <br />
            <br />
            <br />
            <span className="nucleus-table-header-medium text-gray"> {`There are no ${this.props.patientsLabel} for this account`} </span>
            <br />
            <br />
            <br />
          </td>
        </tr>
      </tbody>
    );
  };

  handleModal = () => {
    this.setState((prevState) => ({
      moveModalOpen: !prevState.moveModalOpen,
      selectedUser: '',
      selectedAccount: '',
      accountSearchText: '',
      userSearchText: '',
      accountSelected: false,
      userSelected: false,
      usersSearchResult: [],
      accountSearchResult: [],
      moveMessages: false,
    }));
  };

  handleAccountSearch = (e) => {
    this.setState({ accountSearchText: e.target.value, accountSelected: false });
    const filteredResults = this.state.accountsArray.filter((account) => account?.Name?.toLowerCase().includes(e.target.value.toLowerCase()));
    this.setState({ accountSearchResult: filteredResults });
  };
  handleUserSearch = (e) => {
    const { usersArray } = this.state;
    this.setState({ userSearchText: e.target.value, userSelected: false });
    const filteredResults = usersArray.filter((account) => account?.name?.toLowerCase().includes(e.target.value.toLowerCase()));
    this.setState({ usersSearchResult: filteredResults });
  };
  onGetAllAccountsDataAction(success) {
    this.setState({
      loading: false,
    });
    if (success) {
      this.setState({ accountsArray: [] });
      this.setState({
        accountsArray: CareAccountStore.getAllAccounts(),
      });
    } else {
      Message.show('Sorry, There was a problem getting the accounts information. Please try again.');
    }
  }

  movePatients = async () => {
    try {
      if (this.state.selectedAccount.length === 0) {
        Message.show('Please select an account');
        return;
      }
      if (this.state.moveMessages) {
        if (this.state.selectedUser.length === 0) {
          Message.show('Please select a user');
          return;
        }
      }
      let payload = {
        accountId: this.state.selectedAccount,
        patientIds: this.state.selectedRows,
        moveMessages: this.state.moveMessages,
        userId: this.state.selectedUser,
      };
      this.setState({ loadingPatientMove: true });
      const response = await httpApi.put('patient/move-account', payload);
      const { data } = response;
      if (data.patientsMigrated) {
        data.patientsMigrated.map((patient) => Message.show('Migrated patient: ' + patient));
      }
      if (data.patientsMigratedErrors) {
        data.patientsMigratedErrors.map((patient) => Message.show('Error migrating patient: ' + patient.patientId + ' ' + 'Reason: ' + patient.error));
      }
      this.setState({ moveModalOpen: false, accountSearchText: '', selectedAccount: '', accountSelected: false, selectedRows: [], loadingPatientMove: false });
      CareAccountStore.getAccountPatients({
        UserID: AuthStore.getCsUserID(),
        Token: AuthStore.getCsUserToken(),
        AccountID: this.props.accountId,
      });
    } catch (error) {
      Message.show(error);
    }
  };
  handleSelectAll = () => {
    this.state.patientsTabArray.map((patient) => this.handleCheckboxes(patient));
  };

  render() {
    let tableLoadingView;
    if (this.state.loading) {
      tableLoadingView = '';
    } else {
      tableLoadingView = 'hide';
    }

    return (
      <div className="">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.state.selectedRows.length > 0 && (
            <>
              <p>{this.state.selectedRows.length} selected</p>
              <button
                onClick={this.handleModal}
                style={{ width: 53, height: 27, backgroundColor: '#0A313F', color: 'white', borderRadius: 10, borderStyle: 'none', marginLeft: 5 }}
              >
                Move
              </button>
            </>
          )}
        </div>
        <table className="bordered highlight nucleus-table">
          <thead className="thead-ss">
            <tr>
              <th className="table-col-35 left-align">
                <input type="checkbox" style={{ position: 'relative', opacity: 1, pointerEvents: 'auto', width: 18, height: 18 }} onClick={this.handleSelectAll} />
              </th>
              <th className="table-col-35 left-align">
                <span className="nucleus-table-header-medium">Name</span>
                <a className={'nucleus-font-medium ' + this.state.sortPatientAsc} onClick={this.handleSortPatientDesc}>
                  {' '}
                  &nbsp;▲
                </a>
                <a className={'nucleus-font-medium ' + this.state.sortPatientDesc} onClick={this.handleSortPatientAsc}>
                  &nbsp;▼
                </a>
              </th>
              <th className="table-col-20 center-align">
                <span className="nucleus-table-header-medium">Telephone</span>
              </th>

              <th className="table-col-35 center-align">
                <span className="nucleus-table-header-medium">Device Count</span>
              </th>
              <th className="table-col-35 center-align">
                <span className="nucleus-table-header-medium">Contacts</span>
              </th>
              <th className="table-col-35 center-align">
                <span className="nucleus-table-header-medium">Memory Box Items</span>
              </th>
              <th className="table-col-35 center-align">
                <span className="nucleus-table-header-medium">Status</span>
              </th>
            </tr>
          </thead>
          {this.getTableRows()}
        </table>
        <div className={'row center-align ' + tableLoadingView}>
          <br />
          <br />
          <br />
          <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />

          <br />
          <br />
          <br />
        </div>

        <div className="fixed-action-btn btn-add-new">
          <Link id={'btn-new-patient'} to={'/newPatient/' + this.props.accountId}>
            <span
              className="btn-floating btn-large waves-effect waves-light orange tooltipped "
              data-position="left"
              data-delay="50"
              data-tooltip={'New ' + AuthStore.getPatientLabel()}
            >
              <i className="material-icons">add</i>
            </span>
          </Link>
          &nbsp;
        </div>
        {this.state.moveModalOpen && (
          <NucleusModal setIsOpen={this.handleModal}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <h1 style={{ fontSize: 24, color: '#0A313F', fontWeight: '300' }}>Move to a Different Account</h1>
              <input
                type="text"
                placeholder="Search accounts"
                value={this.state.accountSearchText}
                onChange={this.handleAccountSearch}
                style={{ backgroundColor: 'white', width: 444, height: '50px !important', borderRadius: 5 }}
              />
              {!this.state.accountSelected && this.state.accountSearchText.length > 0 && (
                <div
                  style={{
                    backgroundColor: 'white',
                    width: 444,
                    minHeight: 200,
                    maxHeight: 400,
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    padding: '10px',
                  }}
                >
                  {this.state.accountSearchResult.length > 0 ? (
                    this.state.accountSearchResult.map((result, index) => (
                      <p
                        onClick={() => this.setState({ accountSearchText: result.Name, accountSelected: true, selectedAccount: result.ID }, () => this.getUsers())}
                        key={result.ID}
                        style={{ textAlign: 'left', cursor: 'pointer' }}
                      >
                        {result.Name}
                      </p>
                    ))
                  ) : (
                    <p>No results found.</p>
                  )}
                </div>
              )}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  style={{
                    position: 'relative',
                    opacity: 1,
                    pointerEvents: 'auto',
                    width: 18,
                    height: 18,
                  }}
                  checked={this.state.moveMessages}
                  onChange={() => this.setState((prevState) => ({ moveMessages: !prevState.moveMessages }))}
                />
                <p style={{ fontSize: 16, color: '#0A313F', fontWeight: '300', marginLeft: 10, marginRight: 85 }}>Move All Recurrent Messages With the Patient</p>
              </div>
              {this.state.moveMessages && (
                <>
                  <p style={{ fontSize: 16, fontWeight: '500', color: '#0A313F', margin: 0, marginRight: 15 }}>
                    Choose the user who will create the scheduled messages<span style={{ color: '#FF0000' }}>*</span>
                  </p>
                  <input
                    type="text"
                    placeholder="Search users"
                    value={this.state.userSearchText}
                    onChange={this.handleUserSearch}
                    style={{ backgroundColor: 'white', width: 444, height: '50px !important', borderRadius: 5 }}
                  />
                  {!this.state.userSelected && this.state.userSearchText.length > 0 && (
                    <div
                      style={{
                        backgroundColor: 'white',
                        width: 444,
                        minHeight: 200,
                        maxHeight: 400,
                        overflowY: 'auto',
                        border: '1px solid #ccc',
                        padding: '10px',
                      }}
                    >
                      {this.state.usersSearchResult.length > 0 ? (
                        this.state.usersSearchResult.map((result, index) => (
                          <p
                            onClick={() => this.setState({ userSearchText: result.name, userSelected: true, selectedUser: result.value })}
                            key={result.value}
                            style={{ textAlign: 'left', cursor: 'pointer' }}
                          >
                            {result.name}
                          </p>
                        ))
                      ) : (
                        <p>No results found.</p>
                      )}
                    </div>
                  )}
                </>
              )}
              {this.state.loadingPatientMove && <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  marginTop: 20,
                }}
              >
                <NucleusButtonV2 onClick={this.handleModal} styleType="secondary" type="submit" extraStyle={{ width: 180 }}>
                  CANCEL
                </NucleusButtonV2>
                <NucleusButtonV2 styleType="primary" type="submit" onClick={this.movePatients} extraStyle={{ width: 180 }}>
                  MOVE
                </NucleusButtonV2>
              </div>
            </div>
          </NucleusModal>
        )}
      </div>
    );
  }
}
export default AccountPatients;
