import React from 'react';
import NucleusBaseContainer from "../Base"

type Props = {
    centerContent?: boolean,
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

const centerStyle: React.CSSProperties = { flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }
export const UINucleusContentContainer = (props: Props) => {
    return <NucleusBaseContainer {...props} style={props.centerContent ? { ...centerStyle, ...props?.style } : {
        ...props?.style
    }} />
}