import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';

interface IDeviceRebootData {
  MdmID: string;
}

interface IClearDeviceCacheData {
  CareDeviceID: string;
}

class MdmDeviceApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<----------------------------------Reboot Mdm Device--------------------------->
  requestDeviceReboot(data: IDeviceRebootData, callback: (rsp: { ok: boolean; [key: string]: any }) => void) {
    console.log("requestDeviceReboot", data);
    let rsp;
    httpNodeApi
      .post(`/mdm/devices/${data.MdmID}/reboot`)
      .then((response) => {
        console.log("devices/reboot response", response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch((e) => {
        const { data } = e.response || { error: "Error on devices/reboot" };
        console.log("Error devices/reboot error", e);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log("devices/reboot finally");
        console.log("rsp", rsp);
        callback(rsp);
      });
  }

  async requestClearDeviceCache(data:IClearDeviceCacheData){
    try {
      const response = await httpNodeApi.post(`/mdm/devices/${data.CareDeviceID}/clear_nucleus_device_info_cache`);
      const { data: responseData } = response;
      return {
        ok: true,
        status: response.status, 
        ...responseData,
      };
    } catch (error) {
      const errorData = error.response?.data || { error: "Network Error on requestClearDeviceCache" };
      return {
        ok: false,
        status: error.response?.status || 500,
        ...errorData,
      };
    } finally {
      //console.log("requestClearDeviceCache finally");
    }
  }
}

export default MdmDeviceApi;
