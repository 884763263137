import React from 'react';
import ReactDOM from "react-dom";
import Dispatcher from "../Dispatcher";
import { IndexLink, Link } from "react-router-dom";
import classNames from "classnames";

import {createHistory } from 'history';
//import createBrowserHistory from 'history/lib/createBrowserHistory';
//import createMemoryHistory from 'history/lib/createMemoryHistory';
import createHashHistory from 'history/lib/createHashHistory';
import CareAccountStore from '../stores/CareAccountStore'
import GeneralStore from "../stores/GeneralStore";
import AuthStore from '../stores/AuthStore';

class TopBar extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            backVisibility : " hide",
        };
        this.history = createHistory();
        /*
        this.browserHistory = createBrowserHistory({
              basename: '',             // The base URL of the app (see below)
              forceRefresh: false,      // Set true to force full page refreshes
              keyLength: 6,             // The length of location.key
              // A function to use to confirm navigation with the user (see below)
              getUserConfirmation: (message, callback) => callback(window.confirm(message))
            });
        */
        /*
        this.memoryHistory = createMemoryHistory({
          initialEntries: [ '/' ],  // The initial URLs in the history stack
          initialIndex: 0,          // The starting index in the history stack
          keyLength: 6,             // The length of location.key
          // A function to use to confirm navigation with the user. Required
          // if you return string prompts from transition hooks (see below)
          getUserConfirmation: null
        });
        */

        this.hashHistory = createHashHistory({
          basename: '',             // The base URL of the app (see below)
          hashType: 'slash',        // The hash type to use (see below)
          // A function to use to confirm navigation with the user (see below)
          getUserConfirmation: (message, callback) => callback(window.confirm(message))
        });
        this.currentPath = "";
        this.handleBack = this.handleBack.bind(this);
        this.onRequestHandleBackAction = this.onRequestHandleBackAction.bind(this);
 
    }

    componentDidMount()
    {
        // Get the current location.
        ////console.log("TOPBAR --->", this.hashHistory);

        this.hashHistory.listen((location, action) => {
          ////console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
          this.handleBackVisibility(location.pathname);
          //console.log(`The last navigation action was ${action}`);
        });
        GeneralStore.on("onRequestHandleBack", this.onRequestHandleBackAction);

    }

    componentWillUnmount()
    {
        GeneralStore.removeListener("onRequestHandleBack", this.onRequestHandleBackAction);
    }

    handleBackVisibility(currentPath){
        console.log("PAGE : ", currentPath, this.history);
        const pages = ["consumerPatientProfile", "accountDetails", "newAccountUser", "accountUserProfile", "tools", "reportDevicesForBilling", "reportNewDevices", "appsUsageReport", "accountsUsageReport",
          "newCsUser", "familyDetails", "accountUserPatientProfile", "newPatient", "newFamilyMember", 'policyDetails', 'newPolicy', 'mdmAccountDetails', 'mdmDeviceDetails',
          "/providerDetails"
        ];
        let showBack = false;
        for(var i = 0; i < pages.length; i++){
            showBack = currentPath.includes(pages[i]);
            if (showBack){
                break;
            }
        }
        this.setState({
            backVisibility : showBack ? " " : " hide"
        });
        this.currentPath = currentPath;
    }

    handleBack(){

        //console.log("HISTORY: ", Router.History.length);
        /*if (Router.History.length > 1) {
          // this will take you back if there is history
          Router.History.back();
        } else {
          // this will take you to the parent route if there is no history,
          // but unfortunately also add it as a new route
          var currentRoutes = this.context.router.getCurrentRoutes();
          var routeName = currentRoutes[currentRoutes.length - 2].name;
          this.context.router.transitionTo(routeName);
        }*/
        if (this.currentPath.includes("#/consumerPatientProfile")){
          window.location.assign("#/consumers");
        }
        if (this.currentPath.includes("providerDetails")){
          window.location.assign("#/providers");
        } else if (this.currentPath.includes("patientProfile")){
          console.log("Going back");
          window.location.assign("#/patients");
          
        } else if (this.currentPath.includes("accountUserPatientProfile")){
          console.log("Going back from adding new family member", GeneralStore.getProfileMode());
          if (GeneralStore.getProfileMode() == "Info"){
            window.location.assign("#/accountDetails/" + CareAccountStore.getFamilyMemberAccountId());
          } 
          else{
            GeneralStore.cancelAccountUserPatientProfileEditMode();
          }
        } else{
          //this.hashHistory.goBack();
          this.history.goBack();
        }
    }

    onRequestHandleBackAction(){
      this.handleBack();
    }

    render()
    {

        return (
            <div class="nucleus-topbar">
                <div class="row alto ancho">
                    <div class="col s4 left-align alto valign-wrapper">
                      <a class={ "nucleus-link " + this.state.backVisibility } onClick={this.handleBack}>
                          &nbsp;&nbsp;&nbsp;
                          <img class="responsive-img" src="img/back_arrow.png"/>
                          &nbsp;&nbsp;&nbsp;
                          <span class="nucleus-font nucleus-font-family-medium"> Back</span>
                      </a>
                    </div>
                    <div class="col s3 m4 l5" style={{paddingLeft: "10%", paddingTop:12, fontSize:16}}>
                      <span class="nucleus-font nucleus-font-family-medium">
                       ADMIN
                      </span>
                    </div>
                    <div class="col s5 m4 l3 alto" style={{textAlign:'end', paddingTop:12, fontSize:16}}>
                      <span class="nucleus-font nucleus-font-family-medium">
                        {
                          AuthStore.getCsUserEmail()
                        }
                      </span>
                    
                          {/* <div class="ancho right-align">
                          <a class="nucleus-link">
                            <img class="search-icon" src="img/search_icon.png"/>
                          </a>
                          </div> 
                          */}
                    </div>
                </div>
            </div>
               
        );
    }
}

export default TopBar;