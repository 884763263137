import React, { ReactNode } from 'react';
import { MdmDevicesProvider } from './mdmContext/MdmDevicesTableContext';
import { NucleusProvidersProvider } from './nucleusProvidersContext/NucleusProvidersContext';

interface ContextProviderProps {
    children: ReactNode;
}

export const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
    return (
        <MdmDevicesProvider>
            <NucleusProvidersProvider>
                {children}
            </NucleusProvidersProvider>
        </MdmDevicesProvider>
    );
};
