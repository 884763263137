let authUrl = 'https://auth-dev.nucleuscare.io'
let domain = "";
let client = "";

// eslint-disable-next-line no-restricted-globals
switch(location.hostname){
    case "localhost":
    case "admin-dev.nucleuscare.com":
    case "admin-dev.nucleusportal.com":
      domain = "https://care-dev.nucleuslife.io";
      authUrl = 'https://auth-dev.nucleuscare.io'
      break;
    case "admin-test.nucleuscare.com":
    case "admin-test.nucleusportal.com":
      domain = "https://care-test.nucleuslife.io"
      authUrl = 'https://auth-test.nucleuscare.io'
      break;
    default:
      domain = "https://care.nucleuslife.io"
      authUrl = 'https://auth.nucleuscare.io'
      break;
}


const Config = {
  // "domain" : "http://127.0.0.1:3000/",
	//"domain" : "http://nucleuscare.azurewebsites.net/ws/",
  "rootDomain" : domain,
	"domain" : domain + "/ws/",
	// "domain" : "https://care-dev.nucleuslife.io/ws/",
	"colors" : {
		"nucleusBlue" : "#008FFF"
	},
  authUrl
}
export default Config;
