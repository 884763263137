import React from 'react';
import PropTypes from 'prop-types';
const ActionSelect = ({handleSelectChange, selectedValue, deviceOptions}) => {
    return(
        <select name="deviceOptions" id="deviceOptions" className="nucleusInputSelect" onChange={handleSelectChange} value={selectedValue}>
            <option value="" disabled selected>Actions</option>
            {deviceOptions.map((option) => (
                <option style={{color: '#FE3824'}} key={option.value} value={option.value}>
                {option.name}
                </option>
            ))}
        </select>
    );
};

ActionSelect.propTypes = {
    handleSelectChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.string.isRequired,
    deviceOptions: PropTypes.array.isRequired,
}

export default ActionSelect;