import React, { useEffect } from 'react';
import classNames from 'classnames';
import AuthStore from '../stores/AuthStore';
import CareAccountStore from '../stores/CareAccountStore';
import CareUserStore from '../stores/CareUserStore';
import Message from '../utils/Message';
import { AccessCheckbox } from './AccessCheckbox';
import { useConfigAccessSelector } from '../pages/selector';
import { useDispatch } from 'react-redux';
import TelephoneInputUnderline from './UI/Inputs/TelephoneInputUnderlined';
import {
  getUserConfig,
  getLoggedInUserConfig,
  clearUserConfig,
  enableClassSessionAccessToUser,
  enableSalesforceImportToUser,
  enableReportsToUser,
  updateUserConfigPromise,
} from '@nucleus-care/nucleuscare-backend-client';
import {
  useClassSessionPluralLabelSelector,
  useClassSessionAccessSelector,
} from '../selectors/accountConfig';

function BackendClientFetcher({ render, userId }) {
  const dispatch = useDispatch();
  const label = useClassSessionPluralLabelSelector();

  useEffect(() => {
    if (userId) {
      dispatch(getUserConfig(userId));
    }
  }, [userId]);

  return render(dispatch, label);
}

function ClassSessionAccessCheckbox(props) {
  const dispatch = useDispatch();
  const { allow, success, error } = useConfigAccessSelector('allowClassSession');
  const hasAccess = useClassSessionAccessSelector();
  const label = useClassSessionPluralLabelSelector();
  useEffect(() => {
    if (success) {
      const responseResult = allow ? 'Enabled' : 'Disabled';
      Message.show(`Access to ${label} Screen ${responseResult}`);
    }
    return () => {
      dispatch(clearUserConfig());
    };
  }, [success, allow]);

  useEffect(() => {
    if (error) {
      Message.show(`Error configuring Access to ${label} Screen`);
    }
  }, [error]);
  if (!hasAccess) return null;
  return <AccessCheckbox checked={allow} {...props} />;
}

function SalesforceImportAccessCheckbox(props) {
  const dispatch = useDispatch();
  const { allow, success, error } = useConfigAccessSelector('allowSalesforceImport');
  useEffect(() => {
    if (success) {
      const responseResult = allow ? 'Enabled' : 'Disabled';
      Message.show(`Import Salesforce Users ${responseResult}`);
    }
    return () => {
      dispatch(clearUserConfig());
    };
  }, [success, allow]);
  useEffect(() => {
    if (error) {
      Message.show('Error enabling salesforce import permission');
    }
  }, [error]);
  return <AccessCheckbox checked={allow} {...props} />;
}

function ReportsAccessCheckbox(props) {
  const dispatch = useDispatch();
  const { allow, success, error } = useConfigAccessSelector('allowReports');
  useEffect(() => {
    if (success) {
      const responseResult = allow ? 'Enabled' : 'Disabled';
      Message.show(`Reports tab ${responseResult}`);
    }
    return () => {
      dispatch(clearUserConfig());
    };
  }, [success, allow]);
  
  useEffect(() => {
    if (error) {
      Message.show('Error enabling reports tab');
    }
  }, [error]);
  return <AccessCheckbox checked={allow} {...props} />;
}
class AccountUserProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: this.props.userId,
      errorEmailVisibility: 'hide',
      errorTelephoneVisibility: 'hide',
      errorEmailText: 'Invalid Email',
      errorTelephoneText: 'Telephone already registered',
      userTelephone: this.props.userTelephone || '',
      saveBtnState: '',
    };

    this.onTelephoneInputChange = this.onTelephoneInputChange.bind(this);
    this.handleUpdateAccountUser = this.handleUpdateAccountUser.bind(this);
    //this.handleSelectAdmin = this.handleSelectAdmin.bind(this);

    this.handleIsAdminProfile = this.handleIsAdminProfile.bind(this);
    this.onSetUserIsAdminAction = this.onSetUserIsAdminAction.bind(this);

    this.onAccountUserUpdatedAction = this.onAccountUserUpdatedAction.bind(this);

    //this.onUpdateUserBroadcastMessageAction = this.onUpdateUserBroadcastMessageAction.bind(this);     DEPRECATED - January 24th, 2018 - CARE-2304
    //this.handleReceiveEscalation = this.handleReceiveEscalation.bind(this);
    this.handleStaffAccess = this.handleStaffAccess.bind(this);
    this.handleClientAccess = this.handleClientAccess.bind(this);
    this.handleGroupsTabAccess = this.handleGroupsTabAccess.bind(this);
    this.handleQueueAccess = this.handleQueueAccess.bind(this);
    this.handleMessagesAccess = this.handleMessagesAccess.bind(this);
    this.handleAlertsAccess = this.handleAlertsAccess.bind(this);
    this.handleMemoryAccess = this.handleMemoryAccess.bind(this);
    this.handleBulletinBoardAccess = this.handleBulletinBoardAccess.bind(this);

    //this.onUpdateReceiveEscalationAction = this.onUpdateReceiveEscalationAction.bind(this);
    this.onSetStaffAccessAction = this.onSetStaffAccessAction.bind(this);
    this.onSetClientsAccessAction = this.onSetClientsAccessAction.bind(this);
    this.onSetQueueAccessAction = this.onSetQueueAccessAction.bind(this);
    this.onSetMessagesAccessAction = this.onSetMessagesAccessAction.bind(this);
    this.onSetAlertsAccessAction = this.onSetAlertsAccessAction.bind(this);
    this.onSetMemoryAccessAction = this.onSetMemoryAccessAction.bind(this);
    this.onSetBulletinBoardAccessAction = this.onSetBulletinBoardAccessAction.bind(this);
  }

  componentDidMount() {
    CareAccountStore.on('onAccountUserUpdated', this.onAccountUserUpdatedAction);
    CareAccountStore.on('onSetStaffAccess', this.onSetStaffAccessAction);
    CareAccountStore.on('onSetClientsAccess', this.onSetClientsAccessAction);
    CareAccountStore.on('onSetQueueAccess', this.onSetQueueAccessAction);
    CareAccountStore.on('onSetMessagesAccess', this.onSetMessagesAccessAction);
    CareAccountStore.on('onSetAlertsAccess', this.onSetAlertsAccessAction);
    CareAccountStore.on('onSetMemoryAccess', this.onSetMemoryAccessAction);
    CareAccountStore.on('onSetBulletinBoardAccess',this.onSetBulletinBoardAccessAction);
    CareUserStore.on('onSetUserIsAdminAction', this.onSetUserIsAdminAction);

    this.setState({
      userTelephone:  this.props.userTelephone || '',
    });
    console.log('this.props.userId', this.props.userId);
  }

  componentWillReceiveProps() {
    console.log('UserProfileEdit componentWillReceiveProps');
    this.setState({
      userTelephone:  this.props.userTelephone || '',
    });
  }

  componentWillUnmount() {
    CareAccountStore.removeListener('onAccountUserUpdated', this.onAccountUserUpdatedAction);
    CareAccountStore.removeListener('onSetStaffAccess', this.onSetStaffAccessAction);
    CareAccountStore.removeListener('onSetClientsAccess', this.onSetClientsAccessAction);
    CareAccountStore.removeListener('onSetQueueAccess', this.onSetQueueAccessAction);
    CareAccountStore.removeListener('onSetMessagesAccess', this.onSetMessagesAccessAction);
    CareAccountStore.removeListener('onSetAlertsAccess', this.onSetAlertsAccessAction);
    CareAccountStore.removeListener('onSetMemoryAccess', this.onSetMemoryAccessAction);
    CareAccountStore.removeListener('onSetBulletinBoardAccess', this.onSetBulletinBoardAccessAction);
    CareUserStore.removeListener('onSetUserIsAdminAction', this.onSetUserIsAdminAction);
  }

  onTelephoneInputChange(telephoneNumber) {
    console.log('onTelephoneInputChange NUMBER ', telephoneNumber);

      this.state.userTelephone = telephoneNumber;
      if (telephoneNumber.length > 0) {
        this.setState({
          errorTelephoneVisibility: 'hide',
        });
      }
  }

  handleUpdateAccountUser() {
    console.log('handleUpdateAccountUser');

    if (this.refs.txtUserUpdateFirstName.value === '') {
      Message.show('FirstName is required.');
      return;
    }

    if (this.refs.txtUserUpdateLastName.value === '') {
      Message.show('LastName is required.');
      return;
    }

    if (this.refs.txtUserUpdateEmail.value === '') {
      Message.show('Ups! The email is not valid.');
      return;
    }

    if (this.refs.txtUserUpdateEmail.value.length > 0) {
      this.setState({
        errorEmailVisibility: 'hide',
      });
    }

    let userTelephone = this.state.userTelephone;
    if (userTelephone === '+1') {
      userTelephone = '';
    }
    if (userTelephone.length > 0) {
      this.setState({
        errorTelephoneVisibility: 'hide',
      });
    }

    if (userTelephone.length > 0) {
      userTelephone = userTelephone.replace(/[()-\s]/g, '');
      console.log("Le nombre est:", userTelephone, userTelephone.length);
     
        if (userTelephone.length < 12){
          this.setState({
            errorEmailVisibility : 'hide',
            errorTelephoneVisibility : '',
            errorTelephoneText : 'Telephone must be at least 10 digits'
          });
          return;
        }
        let containsLetter = /[a-zA-Z]/.test(userTelephone);
        if (containsLetter){
          this.setState({
            errorEmailVisibility : 'hide',
            errorTelephoneVisibility : '',
            errorTelephoneText : 'Invalid Telephone.'
          });
          return;
        } 
        const phoneNumberRegex = /^(?:\+\d{1,3})?\d{10,10}$/;   
        if (!phoneNumberRegex.test(userTelephone)) {
          this.setState({
            errorEmailVisibility : 'hide',
            errorTelephoneVisibility : '',
            errorTelephoneText : 'Invalid Telephone.'
          });
          return;
        }   
    } else if (userTelephone.length === 0) {
      if(this.props.twoAuth) { 
        this.setState({
          errorEmailVisibility : 'hide',
          errorTelephoneVisibility : '',
          errorTelephoneText : 'Two Auth is active, phone is required'
        });
        return;
      } else {
        userTelephone = "_";
      }
    } 

    const updateAccountUserData = {
      UserID: this.props.userId,
      FirstName: this.refs.txtUserUpdateFirstName.value,
      LastName: this.refs.txtUserUpdateLastName.value,
      Email: this.refs.txtUserUpdateEmail.value,
      Telephone: userTelephone,
      Address: this.refs.txtUserUpdateAddress.value ?? "_",
      IsAdmin: this.refs.checkIsAdmin.checked,
      IsOnDuty: this.props.userIsOnDuty,
    }

    this.setState({
      saveBtnState: 'disabled',
    });
    CareAccountStore.updateAccountUser(updateAccountUserData);
  }

  onAccountUserUpdatedAction(response) {
    console.log('onAccountUserUpdatedAction', response);
    console.log(response);
    this.setState({
      saveBtnState: '',
    });

    if (!response.ok) {
      if (response.emailRegistered) {
        this.setState({
          errorEmailVisibility: '',
          errorTelephoneVisibility: 'hide',
          errorEmailText: 'Email already exists',
        });
      } else if (response.telephoneRegistered) {
        this.setState({
          errorEmailVisibility: 'hide',
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Telephone already registered',
        });
      } else {
        Message.show('Error updating Account User');
      }
    }
  }

  handleIsAdminProfile(e) {
    console.log('Checkbox');
    var isAdmin = e.target.checked;
    CareUserStore.setUserIsAdmin({
      UserID: this.props.userId,
      IsAdmin: isAdmin,
    });
  }

  onSetUserIsAdminAction(result) {
    console.log('onSetUserIsAdminAction');
    console.log(result);
    console.log(this.props.userId);

    if (result.ok) {
      if (result.isAdmin) {
        Message.show(this.props.userFirstName + ' ' + this.props.userLastName + ' is Admin now!');
      } else {
        Message.show(this.props.userFirstName + ' ' + this.props.userLastName + ' has no privileges now!');
      }
    } else {
      Message.show('Error updating ' + this.props.userFirstName + ' ' + this.props.userLastName + ' IsAdmin, please try again.');
    }
  }

  handleStaffAccess(e) {
    let access = e.target.checked;
    console.log('handleStaffAccess', access);

    CareAccountStore.setStaffAccess({
      UserID: this.props.userId,
      CanSeeStaffTab: access,
    });
  }

  onSetStaffAccessAction(response) {
    console.log('onSetStaffAccessAction', response);
    if (response.ok) {
      let responseResult = response.enable ? ' enabled' : ' disabled';
      Message.show('Staff Access ' + responseResult);
    } else {
      Message.show('Error configuring Staff Access ');
    }
  }

  handleClientAccess(e) {
    let access = e.target.checked;
    console.log('handleClientAccess', access);
    CareAccountStore.setClientsAccess({
      UserID: this.props.userId,
      CanSeeClientsTab: access,
    });
  }

  onSetClientsAccessAction(response) {
    console.log('onSetClientsAccessAction', response);
    if (response.ok) {
      let responseResult = response.enable ? ' enabled' : ' disabled';
      Message.show('Clients Access ' + responseResult);
    } else {
      Message.show('Error configuring Clients Access ');
    }
  }

  handleGroupsTabAccess(e) {
    let enabled = e.target.checked;
    console.log('handleGroupsTabAccess', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanAccessGroups: enabled,
    })
      .then(({ data }) => {
        let responseResult = data.CanAccessGroups ? ' enabled' : ' disabled';

        Message.show('Access to Groups Screen ' + responseResult);
      })
      .catch((e) => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Access to Groups Screen');
      });
  }

  handleQueueAccess(e) {
    let access = e.target.checked;
    console.log('handleQueueAccess', access);
    CareAccountStore.setQueueAccess({
      UserID: this.props.userId,
      CanSeeQueueTab: access,
    });
  }

  onSetQueueAccessAction(response) {
    console.log('onSetQueueAccessAction', response);
    if (response.ok) {
      let responseResult = response.enable ? ' enabled' : ' disabled';
      Message.show('Queue Access ' + responseResult);
    } else {
      Message.show('Error configuring Queue Access ');
    }
  }

  handleMessagesAccess(e) {
    let access = e.target.checked;
    console.log('handleMessagesAccess', access);
    CareAccountStore.setMessagesAccess({
      UserID: this.props.userId,
      CanSeeMessageCenterTab: access,
    });
  }

  onSetMessagesAccessAction(response) {
    console.log('onSetMessagesAccessAction', response);
    if (response.ok) {
      let responseResult = response.enable ? ' enabled' : ' disabled';
      Message.show('Messages Access ' + responseResult);
    } else {
      Message.show('Error configuring Messages Access ');
    }
  }

  handleAlertsAccess(e) {
    let access = e.target.checked;
    console.log('handleAlertsAccess', access);
    CareAccountStore.setAlertsAccess({
      UserID: this.props.userId,
      CanSeeAlertsTab: access,
    });
  }

  onSetAlertsAccessAction(response) {
    console.log('onSetAlertsAccessAction', response);
    if (response.ok) {
      let responseResult = response.enable ? ' enabled' : ' disabled';
      Message.show('Alerts Access ' + responseResult);
    } else {
      Message.show('Error configuring Alerts Access ');
    }
  }

  handleMemoryAccess(e) {
    let access = e.target.checked;
    console.log('handleMemoryAccess', access);
    CareAccountStore.setMemoryAccess({
      UserID: this.props.userId,
      CanSeeMemoryBox: access,
    });
  }

  onSetMemoryAccessAction(response) {
    console.log('onSetMemoryAccessAction', response);
    if (response.ok) {
      let responseResult = response.enable ? ' enabled' : ' disabled';
      Message.show('Memory Box Access ' + responseResult);
    } else {
      Message.show('Error configuring Memory Box Access ');
    }
  }

  handleBulletinBoardAccess(e) {
    let access = e.target.checked;
    console.log('handleBulletinBoardAccess', access);
    CareAccountStore.setBulletinBoardAccess({
      UserID: this.props.userId,
      CanSeeBulletinBoard: access,
    });
  }

  handleDeleteDevices = (e) => {
    let enabled = e.target.checked;
    console.log('handleDeleteDevices', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanDeleteDevices: enabled,
    })
      .then(({ data }) => {
        let responseResult = data.CanDeleteDevices ? ' enabled' : ' disabled';
        Message.show('Delete Devices ' + responseResult);
      })
      .catch((e) => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Delete Devices');
      });
  };

  handleDeletePatients = (e) => {
    let enabled = e.target.checked;
    console.log('handleDeletePatients', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanDeletePatients: enabled,
    })
      .then(({ data }) => {
        let responseResult = data.CanDeletePatients ? ' enabled' : ' disabled';
        Message.show('Delete ' + AuthStore.getPatientLabelPlural() + ' ' + responseResult);
      })
      .catch((e) => {
        console.warn('updateUserConfig error', e);
        Message.show(
          'Error configuring Delete ' + AuthStore.getPatientLabelPlural(),
        );
      });
  };

  handleBargeInCallsEnabled = (e) => {
    let enabled = e.target.checked;
    console.log('handleBargeInCallsEnabled', enabled);

    updateUserConfigPromise(this.props.userId, {
      BargeInCall: enabled,
    })
      .then(({ data }) => {
        let responseResult = data.BargeInCall ? ' enabled' : ' disabled';
        Message.show('Barge In Calls ' + responseResult);
      })
      .catch((e) => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Barge In Calls');
      });
  };

  handleDevicesAccess = (e) => {
    let enabled = e.target.checked;
    console.log('handleDevicesAccess', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanSeeDevicesTab: enabled,
    })
      .then(({ data }) => {
        let responseResult = data.CanSeeDevicesTab ? ' enabled' : ' disabled';

        Message.show('Access to Devices Screen ' + responseResult);
      })
      .catch((e) => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Access to Devices Screen');
      });
  };

  handleClassesAccess = async (dispatch, { currentTarget }) => {
    const { userId } = this.props;
    dispatch(
      enableClassSessionAccessToUser({
        userId,
        currentStatus: currentTarget.checked,
      }),
    );
  };

  handleSalesforceImport = async (dispatch, { currentTarget }) => {
    const { userId } = this.props;
    dispatch(
      enableSalesforceImportToUser({
        userId,
        currentStatus: currentTarget.checked,
      }),
    ).then(() => {
      if (userId === AuthStore.getCsUserID()) {
        dispatch(getLoggedInUserConfig(userId));
      }
    });
  };

  handleRestartDevicesAcces = (e) => {
    let enabled = e.target.checked;
    console.log('handleRestartDevicesAccess', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanRestartDevices: enabled,
    })
      .then(({ data }) => {
        let responseResult = data.CanRestartDevices ? ' enabled' : ' disabled';
        Message.show('Access to Restart Devices ' + responseResult);
      })
      .catch((e) => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Access to Restart Devices');
      });
  };

  handleReportsAccess = async (dispatch, { currentTarget }) => {
    const { userId } = this.props;
    console.log('handleReportsAccess');
    dispatch(
      enableReportsToUser({
        userId,
        currentStatus: currentTarget.checked,
      }),
    ).then(() => {
      if (userId === AuthStore.getCsUserID()) {
        dispatch(getLoggedInUserConfig(userId));
      }
    });
  };

  handleCanImportClientsConfig = (e) => {
    let enabled = e.target.checked;
    console.log('handleCanImportClientsConfig', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanImportClients: enabled,
    })
      .then(({ data }) => {
        console.log('updateUserConfigPromise response', data);
        let responseResult = data.CanImportClients ? ' enabled' : ' disabled';
        Message.show('User can import Clients ' + responseResult);
      })
      .catch((e) => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring: User can import Clients');
      });
  };

  handleDeletePatients = (e) => {
    let enabled = e.target.checked;
    console.log('handleDeletePatients', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanDeletePatients: enabled,
    })
      .then(({ data }) => {
        let responseResult = data.CanDeletePatients ? ' enabled' : ' disabled';

        Message.show(
          'Delete ' + AuthStore.getPatientLabelPlural() + ' ' + responseResult,
        );
      })
      .catch((e) => {
        console.warn('updateUserConfig error', e);
        Message.show(
          'Error configuring Delete ' + AuthStore.getPatientLabelPlural(),
        );
      });
  };

  onSetBulletinBoardAccessAction(response) {
    console.log('onSetBulletinBoardAccessAction', response);

    if (response.ok) {
      let responseResult = response.enable ? ' enabled' : ' disabled';

      Message.show('Bulletin Board Access ' + responseResult);
    } else {
      Message.show('Error configuring Bulletin Board Access ');
    }
  }

  render() {
    let currentSection = classNames({
      hide: this.props.currentMode != 'Edit',
    });

    let isAdminValue = this.props.userIsAdmin ? 'checked' : '';
    // let canSendMsjValue = this.props.userCanSendBroadcastMessage ? "checked" : "";    DEPRECATED - January 24th, 2018 - CARE-2304
    // let receiveEscalationValue = this.props.userCanReceiveAlerts ? "checked" : "";
    let staffAccessValue = this.props.userStaffAccess ? 'checked' : '';
    let clientAccessValue = this.props.userClientsAccess ? 'checked' : '';
    let groupsAccessValue = this.props.userGroupsAccess ? 'checked' : '';
    let queueAccessValue = this.props.userQueueAccess ? 'checked' : '';
    let messagesAccessValue = this.props.userMessagesAccess ? 'checked' : '';
    let alertsAccessValue = this.props.userAlertsAccess ? 'checked' : '';
    let memoryAccessValue = this.props.userPicturesAccess ? 'checked' : '';
    let bulletinBoardAccessValue = this.props.userBulletinBoardAccess ? 'checked' : '';
    let canDeleteDevicesValue = this.props.canDeleteDevices ? 'checked' : '';
    let canDeletePatientsValue = this.props.canDeletePatients ? 'checked' : '';
    let bargeInCallEnabledValue = this.props.bargeInCallEnabled ? 'checked' : '';
    let devicesAccessValue = this.props.devicesAccess ? 'checked' : '';
    let canImportClientsAccessValue = this.props.canImportClients ? 'checked' : '';
    let restartDevicesAccessValue = this.props.canRestartDevices ? 'checked' : '';
    return (
      <div
        className={'nucleus-user-pending nucleus-form-container ' + currentSection}
      >
        <br />
        <div className="row">
          <div className="col s2 m3 l2">
            <img className="responsive-img circle" src={''}/>
          </div>
          <div className="col s6 m8 l6">
            <div className="row">
              <div className="col s5 valgin-wrapper">
                <span className=" nucleus-labels"> First Name</span>
              </div>
              <div className="col s7 " key={this.props.userFirstName}>
                <input
                  ref="txtUserUpdateFirstName"
                  type="text"
                  className="validate nucleus-input-form"
                  defaultValue={this.props.userFirstName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col s5 valgin-wrapper">
                <span className=" nucleus-labels"> Last Name</span>
              </div>
              <div className="col s7" key={this.props.userLastName}>
                <input
                  ref="txtUserUpdateLastName"
                  type="text"
                  className="validate nucleus-input-form"
                  defaultValue={this.props.userLastName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col s5">
                <span className=" nucleus-labels"> Email</span>
              </div>
              <div className="col s7" key={this.props.userEmail}>
                <input
                  ref="txtUserUpdateEmail"
                  type="text"
                  className="validate nucleus-input-form-disabled"
                  disabled
                  defaultValue={this.props.userEmail}
                />
                <div>
                  <span className={'nucleus-form-error center-align ' + this.state.errorEmailVisibility } >
                    {this.state.errorEmailText}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s5">
                <span className=" nucleus-labels"> Telephone</span>
              </div>
              <div className="col s7" key={this.props.userTelephone}>
                <TelephoneInputUnderline
                  inputId='txtUserTelephone'
                  value={this.state.userTelephone}
                  onChange={this.onTelephoneInputChange}
                  //onBlur={this.validateForm}
                  style={{ marginLeft: 3 }}
                />
                <div>
                  <span
                    className={'nucleus-form-error center-align ' + this.state.errorTelephoneVisibility }>
                    {this.state.errorTelephoneText}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s5">
                <span className=" nucleus-labels"> Address</span>
              </div>
              <div className="col s7" key={this.props.userAddress}>
                <input
                  ref="txtUserUpdateAddress"
                  type="text"
                  className="validate nucleus-input-form"
                  defaultValue={this.props.userAddress}
                />
              </div>
            </div>

            <div className="row ">
              <div className="col s5">
                <span className=" nucleus-labels"> Admin Access</span>
              </div>
              <div className="col s7">
                <div className="switch">
                  <label key={isAdminValue}>
                    <input
                      type="checkbox"
                      ref="checkIsAdmin"
                      defaultChecked={isAdminValue}
                      onChange={this.handleIsAdminProfile}
                    />
                    <span className="lever nucleus-check"></span>
                  </label>
                </div>
              </div>
            </div>

            {/*
              // DEPRECATED - January 24th, 2018 - CARE-2304 
              <div className="row ">
                  <div className="col s5">
                      <span className=" nucleus-labels"> Can Send Message to All Patients</span> 
                  </div>
                  <div className="col s7">
                      <span key={canSendMsjValue}>
                          <input ref="checkMessageAllPatients" type="checkbox" className="filled-in" id="checkMessageAllPatients" defaultChecked={canSendMsjValue}/>
                          <label for="checkMessageAllPatients">&nbsp;</label>
                      </span>
                  </div>
              </div>
            */}

            {/*
              // DEPRECATED - January 17th, 2019 - CARE-4047
              <div className="row  medium-space ">
                  <div className="col s5">
                      <span className=" nucleus-labels"> Receive Escalation Alerts</span> 
                  </div>
                  <div className="col s7">
                      <span key={receiveEscalationValue}>
                          <input ref="checkReceiveEscalation" type="checkbox" className="filled-in" id="checkReceiveEscalation" defaultChecked={receiveEscalationValue} onChange={this.handleReceiveEscalation} />
                          <label for="checkReceiveEscalation">&nbsp;</label>
                      </span>
                  </div>
              </div>
            */}
          </div>
          <div className="col s0 m1 l4"></div>
        </div>
        <div className="row">
          <div className="col s2 m3 l2" />
          <div className="col s8 m8 l8">
            <div className="col s6 pdl-0">
              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">Can Access Staff Tab</span>
                </div>
                <div className="col s2">
                  <span key={staffAccessValue}>
                    <input
                      ref="checkStaffAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkStaffAccess"
                      defaultChecked={staffAccessValue}
                      onChange={this.handleStaffAccess}
                    />
                    <label for="checkStaffAccess">&nbsp;</label>
                  </span>
                </div>
              </div>
              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">
                    Can Access Clients Tab
                  </span>
                </div>
                <div className="col s2">
                  <span key={clientAccessValue}>
                    <input
                      ref="checkClientAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkClientAccess"
                      defaultChecked={clientAccessValue}
                      onChange={this.handleClientAccess}
                    />
                    <label for="checkClientAccess">&nbsp;</label>
                  </span>
                </div>
              </div>
              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">Can Access Queue Tab</span>
                </div>
                <div className="col s2">
                  <span key={queueAccessValue}>
                    <input
                      ref="checkQueueAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkQueueAccess"
                      defaultChecked={queueAccessValue}
                      onChange={this.handleQueueAccess}
                    />
                    <label for="checkQueueAccess">&nbsp;</label>
                  </span>
                </div>
              </div>
              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">
                    Can Access Message Center Tab
                  </span>
                </div>
                <div className="col s2">
                  <span key={messagesAccessValue}>
                    <input
                      ref="checkMessagesAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkMessagesAccess"
                      defaultChecked={messagesAccessValue}
                      onChange={this.handleMessagesAccess}
                    />
                    <label for="checkMessagesAccess">&nbsp;</label>
                  </span>
                </div>
              </div>
              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">
                    Can Access Alerts Tab
                  </span>
                </div>
                <div className="col s2">
                  <span key={alertsAccessValue}>
                    <input
                      ref="checkAlertsAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkAlertsAccess"
                      defaultChecked={alertsAccessValue}
                      onChange={this.handleAlertsAccess}
                    />
                    <label for="checkAlertsAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">
                    Can Access Memory Box Tab
                  </span>
                </div>
                <div className="col s2">
                  <span key={memoryAccessValue}>
                    <input
                      ref="checkMemoryAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkMemoryAccess"
                      defaultChecked={memoryAccessValue}
                      onChange={this.handleMemoryAccess}
                    />
                    <label for="checkMemoryAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">
                    Can Access Bulletin Board Tab
                  </span>
                </div>
                <div className="col s2">
                  <span key={bulletinBoardAccessValue}>
                    <input
                      ref="checkBulletinBoardAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkBulletinBoardAccess"
                      defaultChecked={bulletinBoardAccessValue}
                      onChange={this.handleBulletinBoardAccess}
                    />
                    <label for="checkBulletinBoardAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">
                    Can Access Groups Tab
                  </span>
                </div>
                <div className="col s2">
                  <span key={groupsAccessValue}>
                    <input
                      ref="checkGroupsTabAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkGroupsTabAccess"
                      defaultChecked={groupsAccessValue}
                      onChange={this.handleGroupsTabAccess}
                    />
                    <label for="checkGroupsTabAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">
                    Can Access Devices Tab
                  </span>
                </div>
                <div className="col s2">
                  <span key={devicesAccessValue}>
                    <input
                      ref="checkDevicesAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkDevicesAccess"
                      defaultChecked={devicesAccessValue}
                      onChange={this.handleDevicesAccess}
                    />
                    <label for="checkDevicesAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <BackendClientFetcher
                userId={this.props.userId}
                render={(dispatch, label) => (
                  <div>
                    <ClassSessionAccessCheckbox
                      hasAdminVisibility={this.props.userIsAdmin}
                      label={`Can Access ${label} tab`}
                      id="checkClassesAccess"
                      onChange={(e) => this.handleClassesAccess(dispatch, e)}
                    />
                    <ReportsAccessCheckbox
                      hasAdminVisibility={this.props.userIsAdmin}
                      label="Can Access Reports tab"
                      id="checkReportsAccess"
                      onChange={(e) => this.handleReportsAccess(dispatch, e)}
                    />
                  </div>
                )}
              />
            </div>
            <div className="col s6 pdl-0">
              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Delete Clients</span>
                </div>
                <div className="col s2">
                  <span key={canDeletePatientsValue}>
                    <input
                      ref="checkDeletePatientsEnabled"
                      type="checkbox"
                      className="filled-in"
                      id="checkDeletePatientsEnabled"
                      defaultChecked={canDeletePatientsValue}
                      onChange={this.handleDeletePatients}
                    />
                    <label for="checkDeletePatientsEnabled">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">
                    {' '}
                    Can Edit/Delete Devices
                  </span>
                </div>
                <div className="col s2">
                  <span key={canDeleteDevicesValue}>
                    <input
                      ref="checkDeleteDevicesEnabled"
                      type="checkbox"
                      className="filled-in"
                      id="checkDeleteDevicesEnabled"
                      defaultChecked={canDeleteDevicesValue}
                      onChange={this.handleDeleteDevices}
                    />
                    <label for="checkDeleteDevicesEnabled">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">
                    Can Place Barge In Calls
                  </span>
                </div>
                <div className="col s2">
                  <span key={bargeInCallEnabledValue}>
                    <input
                      ref="checkBargeInCallsEnabled"
                      type="checkbox"
                      className="filled-in"
                      id="checkBargeInCallsEnabled"
                      defaultChecked={bargeInCallEnabledValue}
                      onChange={this.handleBargeInCallsEnabled}
                    />
                    <label for="checkBargeInCallsEnabled">&nbsp;</label>
                  </span>
                </div>
              </div>

              <BackendClientFetcher
                userId={this.props.userId}
                render={(dispatch, label) => (
                  <div>
                    <SalesforceImportAccessCheckbox
                      hasAdminVisibility={this.props.userIsAdmin}
                      label="Can Import Salesforce Users"
                      id="checkSalesforceImport"
                      onChange={(e) => this.handleSalesforceImport(dispatch, e)}
                    />
                  </div>
                )}
              />

              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels">
                    Can Import Clients from CSV
                  </span>
                </div>
                <div className="col s2">
                  <span key={canImportClientsAccessValue}>
                    <input
                      ref="checkCanImportClients"
                      type="checkbox"
                      className="filled-in"
                      id="checkCanImportClients"
                      defaultChecked={canImportClientsAccessValue}
                      onChange={this.handleCanImportClientsConfig}
                    />
                    <label for="checkCanImportClients">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className="row medium-space ">
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Restart Devices</span>
                </div>
                <div className="col s2">
                  <span key={restartDevicesAccessValue}>
                    <input
                      ref="checkRestartDevicesAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkRestartDevicesAccess"
                      defaultChecked={restartDevicesAccessValue}
                      onChange={this.handleRestartDevicesAcces}
                    />
                    <label for="checkRestartDevicesAccess">&nbsp;</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fixed-action-btn btn-add-new">
          <a
            className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped "
            disabled={this.state.saveBtnState}
            data-position="left"
            data-delay="50"
            data-tooltip="Save Coordinator"
            onClick={this.handleUpdateAccountUser}
          >
            <i className="material-icons">save</i>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </div>
    );
  }
}

export default AccountUserProfileEdit;
