import React, { useEffect, useState } from 'react';
import NucleusTable from '../../../../components/NucleusTable';
import { useDispatch, useSelector } from 'react-redux';
import NucleusModalButton from '../../../../components/NucleusModal/NucleusModalButton';
import {
  fetchMdmDevicePolicies,
  fetchMdmPolicyDetailsData,
  unassignPolicyTemplateFromMdmDevice,
} from '@nucleus-care/nucleuscare-backend-client';
import UnassignPolicyAlert from './UnassignPolicyAlert';
import MdmDeviceSelectPoliciesModal from './MdmDeviceSelectPoliciesModal';
import { Tooltip } from '@mui/material';
import Message from '../../../../utils/Message';
import NucleusModal from '../../../../components/NucleusModal';
import { useLocation } from 'react-router-dom';
import AuthStore from '../../../../stores/AuthStore';

const MdmDevicePolicies = ({ mdmDeviceId }) => {
  const dispatch = useDispatch();
  const {
    fetchDevicePolicies,
    assignPolicyTemplatesToMdmDeviceState,
    unassignPolicyTemplateFromMdmDeviceState,
    fetchDeviceDetails,
  } = useSelector(({ mdmDevice }) => mdmDevice.data);

  const { deviceDetails } = fetchDeviceDetails;
  const { devicePolicies } = fetchDevicePolicies;
  const { success: assignSuccess } = assignPolicyTemplatesToMdmDeviceState;

  const {
    success: unassignSuccess,
    loading: unassignLoading,
    error: unassignError,
  } = unassignPolicyTemplateFromMdmDeviceState;

  // for configuring whether the view policy button should be shown
  const isSuperUser = AuthStore.superUser();
  const [viewPolicy, setViewPolicy] = useState(false);
  const { search } = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(search);
    const viewPolicy = params.get('viewPolicy');
    if (isSuperUser && viewPolicy?.toLowerCase() === 'true') {
      setViewPolicy(true);
    }
  }, [search]);

  useEffect(() => {
    if (mdmDeviceId) {
      dispatch(fetchMdmDevicePolicies(mdmDeviceId));
    }
  }, [mdmDeviceId]);

  useEffect(() => {
    // refetch the device policies if a policy is assigned or unassigned
    if (assignSuccess || unassignSuccess) {
      dispatch(fetchMdmDevicePolicies(mdmDeviceId));
    }
    // if unassignSuccess is true then close the alert
    if (unassignSuccess) {
      setUnassignAlertIsOpen(false);
    }
  }, [assignSuccess, unassignSuccess]);
  // unassign alerts
  useEffect(() => {
    if (unassignLoading) return;
    if (unassignError)
      return Message.error(
        'Something went wrong. Please try again later, if the error persists, contact support.',
      );
  }, [unassignLoading, unassignError]);
  const [devicesSelectPolicyModalIsOpen, setDevicesSelectPolicyModalIsOpen] = useState(false);
  const [currentPolicyTemplate, setCurrentPolicyTemplate] = useState(null);
  const [unassignAlertIsOpen, setUnassignAlertIsOpen] = useState(false);

  const devicePolicyTableColumns = [
    {
      Header: 'Priority',
      accessor: 'Priority',
    },
    {
      Header: 'Name',
      accessor: 'Name',
    },
    {
      Header: 'Version',
      accessor: 'Version',
    },
    {
      Header: 'Modified By',
      accessor: 'ModifiedByName',
    },
    {
      Header: 'Assigned Method',
      accessor: 'Method',
    },
    {
      Header: 'Assigned By',
      accessor: 'assignedByName',
    },
    {
      Header: '',
      accessor: 'unassignComponent',
      disableSortBy: true,
      Cell: ({ row }) => {
        // if the policy is not protected then return the unassign button
        if (!row.original.Protected || true) {
          return (
            <span
              onClick={() => {
                setUnassignAlertIsOpen(true);
                setCurrentPolicyTemplate(row.original);
              }}
              role="button"
              style={{
                color: '#0092FF',
                cursor: 'pointer',
                userSelect: 'none',
              }}
            >
              {
                (row.original.Protected) && 
                  <Tooltip title={"Protected policy"} placement="right">
                  <img
                    style={{
                      marginLeft:-20,
                      marginBottom:-4
                    }}
                    src={"./img/lock.svg"}
                    alt={"Lock icon"}
                  />
                </Tooltip>
              }
              unassign
            </span>
          );
        } else {
          return (
            <Tooltip title={'Protected policy'} placement="right">
              <img
                style={{
                  cursor: 'not-allowed',
                }}
                src={'./img/lock.svg'}
                alt={'Lock icon'}
              />
            </Tooltip>
          );
        }
      },
    },
  ];

  // for setting the policy string in the modal
  const [policyString, setPolicyString] = useState('');
  const [policyModalIsOpen, setPolicyModalIsOpen] = useState(false);
  const {
    loading: policyDetailsLoading,
    success: policyDetailsSuccess,
    PolicyDetails,
  } = useSelector(({ mdmPolicy }) => mdmPolicy.data);

  useEffect(() => {
    if (policyDetailsLoading || !policyDetailsSuccess || !PolicyDetails?.PolicyTemplate?.PolicyDetails) {
      return;
    }
    const policyJSONParsed = JSON.parse(
      PolicyDetails.PolicyTemplate.PolicyDetails,
    );
    const policyString = JSON.stringify(policyJSONParsed, null, 4);
    setPolicyString(policyString);
  }, [PolicyDetails, policyDetailsLoading, policyDetailsSuccess]);
  if (viewPolicy) {
    devicePolicyTableColumns.push({
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true,
      Cell: ({ row }) => (
        <button
          onClick={(event) => {
            event.stopPropagation();
            setPolicyModalIsOpen(true);
            dispatch(fetchMdmPolicyDetailsData(row.original.TemplateID));
          }}
        >
          View
        </button>
      ),
    });
  }

  return (
    <React.Fragment>
      <NucleusTable
        columns={devicePolicyTableColumns}
        data={Object.values(devicePolicies)
          // sort by descending priority
          .sort((a, b) => b.Priority - a.Priority)}
      />
      {policyModalIsOpen && (
        <NucleusModal
          setIsOpen={() => setPolicyModalIsOpen(!policyModalIsOpen)}
          maxHeight={'600px'}
        >
          <pre style={{ textAlign: 'left' }}>{policyString}</pre>
        </NucleusModal>
      )}
      <UnassignPolicyAlert
        handleCancel={() => {
          setUnassignAlertIsOpen(false);
          setCurrentPolicyTemplate(null);
        }}
        handleUnassign={() => {
          dispatch(
            unassignPolicyTemplateFromMdmDevice({
              devicePolicyTemplatesId: currentPolicyTemplate?.ID,
            }),
          );
        }}
        setIsOpen={setUnassignAlertIsOpen}
        isOpen={unassignAlertIsOpen}
        loading={unassignLoading}
        deviceName={deviceDetails.DeviceName}
        policyName={currentPolicyTemplate?.Name}
        policyProtected={currentPolicyTemplate?.Protected ?? false}
      />
      <MdmDeviceSelectPoliciesModal
        mdmDeviceId={mdmDeviceId}
        isOpen={devicesSelectPolicyModalIsOpen}
        setIsOpen={setDevicesSelectPolicyModalIsOpen}
        devicePolicyTemplates={devicePolicies}
      />
      <NucleusModalButton
        dataTooltip={'New Policy'}
        id={'newPolicy'}
        handleOpenModal={() => {
          setDevicesSelectPolicyModalIsOpen(true);
        }}
      />
    </React.Fragment>
  );
};

export default MdmDevicePolicies;