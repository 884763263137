import React from 'react';
const MdmAccountDetailsNavTabs = ({handleTabChange, activeTab}) => {
  return(
    <div className="mDMAccountDetailsNavBarContainer">
      <div className="mDMAccountDetailsNavBarOptionsContainer">
        <span>
        <a 
        onClick={() => handleTabChange("devices")} 
        className={ activeTab === 'devices' ? "mDMAccountDetailsNavBarTxt mDMAccountDetailsNavBarTxtActive" : "mDMAccountDetailsNavBarTxt" }
        >
        Devices
        </a>
        </span>
        <span>
        <a 
            onClick={() => handleTabChange("policies")}
            className={ activeTab === 'policies' ? "mDMAccountDetailsNavBarTxt mDMAccountDetailsNavBarTxtActive" : "mDMAccountDetailsNavBarTxt" }
        >
            Policies
        </a>
        </span>
      </div>
        <div class="nucleus-news-separator">&nbsp;</div>
    </div>
  );
}

export default MdmAccountDetailsNavTabs;