import React, { useEffect, useState } from "react";
import NucleusCheckBox from "../../components/NucleusCheckBox";
import MDMNucleusCheckBox from "../../components/MDMNucleuCheckBox";
import MDMNucleusSelect from "../../components/MDMNucleusSelect";
import {
  addPolicyJSONProperties,
  removePolicyJSONProperty,
} from '@nucleus-care/nucleuscare-backend-client';
import { useDispatch, useSelector } from "react-redux";

const Security = ({ operationType, policyDetails }) => {
  const { policyJSON, policyFormStatus } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.state
  );

  const dispatch = useDispatch();

  const allowDeveloperModeAndSafeBootOptions = [
    {
      name: "Allowed",
      value: "DEVELOPER_SETTINGS_ALLOWED",
    },
    {
      name: "Disabled",
      value: "DEVELOPER_SETTINGS_DISABLED",
    },
  ];

  return (
    <div className="nucleus-all-scrollable-page">
      <span className="nucleus-tools-page-title"></span>
      <div className="nucleus-tools-page-content">
        <div className="row">
          <div className="nucleus-mb-1">
            <MDMNucleusCheckBox
              name="factoryResetDisabled"
              label={"Disable Factory Reset:"}
              policyDetails={policyDetails}
              operationType={policyFormStatus.operationType}
            />
          </div>
          <div className="nucleus-mb-1">
            <MDMNucleusCheckBox
              name="installAppsDisabled"
              label={"Prevent user from installing apps:"}
              policyDetails={policyDetails}
              operationType={policyFormStatus.operationType}
            />
          </div>
          <MDMNucleusSelect
            activeBadge={true}
            name={"advancedSecurityOverrides.developerSettings"}
            objectAccessor={"advancedSecurityOverrides"}
            propertyAccessor={"developerSettings"}
            valueAccessor="value"
            label={"Developer mode and safe boot:"}
            options={allowDeveloperModeAndSafeBootOptions}
            onClickClear={() => {
              // get the nested advancedSecurityOverrides object from policyJSON
              const advancedSecurityOverrides =
                { ...policyJSON.advancedSecurityOverrides } || {};
              // delete the developerSettings property
              delete advancedSecurityOverrides.developerSettings;

              // if the object is now empty, remove it from the policyJSON, otherwise update it
              if (Object.keys(advancedSecurityOverrides).length === 0) {
                dispatch(removePolicyJSONProperty("advancedSecurityOverrides"));
              } else {
                // dispatch the update
                dispatch(
                  addPolicyJSONProperties({
                    advancedSecurityOverrides: advancedSecurityOverrides,
                  })
                );
              }
            }}
            setPropertyValue={(value) => {
              // get the nested advancedSecurityOverrides object from policyJSON
              const advancedSecurityOverrides =
                { ...policyJSON.advancedSecurityOverrides } || {};
              // set the developerSettings value to the selected value
              advancedSecurityOverrides.developerSettings = value;
              // dispatch the update
              dispatch(
                addPolicyJSONProperties({
                  advancedSecurityOverrides: advancedSecurityOverrides,
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Security;
