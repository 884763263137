import React from 'react';
import classNames from "classnames";

import Message from "../utils/Message";
import CarePatientStore from "../stores/CarePatientStore";


class CallRequestLogItemRow extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            ID :  null
        };
        
        this.handleCallRequestLogDetails = this.handleCallRequestLogDetails.bind(this);
        //this.handleNotes = this.handleNotes.bind(this);
    }

    componentDidMount()
    {


    }

    componentWillUnmount()
    {
        
    }

    pad2(number) {
        return (number < 10 ? '0' : '') + number;
    }

    handleCallRequestLogDetails(){
        console.log("handleCallRequestLogDetails");

        CarePatientStore.openCallRequestLogDetails({
            ID : this.props.ID,
            RequestDateTime : this.props.RequestTime + " - " + this.props.PatientTimeZone,
            Emergency : this.props.IsEmergency,
            Status : this.props.Status,
            HandledDateTime : this.props.HandledTime + " - " + this.props.PatientTimeZone,
            HandlerName : this.props.HandlerName,
            Escalated : this.props.EscalationCallRequestSent ? "Y" :  "N",
            Num : this.props.NumOfRequests
        });
    }

    

    render()
    {
        let requestTypeIndicator = this.props.IsEmergency ? '!' : '';
        let escalatedValue = this.props.EscalationCallRequestSent ? "Y" : "";
        
        return (

            <tr onClick={ this.handleCallRequestLogDetails}>
                <td class="nucleus-row" >
                    { this.props.RequestTime + " - " + this.props.PatientTimeZone }
                </td>
                <td class="nucleus-row center-align">
                    <span class={"txt-red nucleus-font-family-big"}><b>{ requestTypeIndicator }</b></span>
                </td>
                <td class={"nucleus-row left-align " }>
                    { this.props.Status }
                </td>
                <td class="nucleus-row left-align">
                    { this.props.HandledTime + " - " + this.props.PatientTimeZone }
                </td>
                <td class="nucleus-row left-align">
                    { this.props.HandlerName }
                </td>
                <td class="nucleus-row center-align">
                    { escalatedValue }
                </td>
                <td class="nucleus-row center-align">
                    { this.props.NumOfRequests }
                </td>
                <td class="nucleus-row right-align padding-b0">

                    {
                        this.props.HasNotes ? 
                            (
                                <span class="no-margin"> <i class="material-icons txt-green"> done </i> &nbsp;&nbsp;</span>
                            ) : <a/> 
                        
                    }
                   
                </td>
            </tr>


        );
    }
}

export default CallRequestLogItemRow;