import { CSSProperties, useEffect, useState } from "react";
import AuthStore from "../../../stores/AuthStore";

export enum AccountConfigTab {
  AccountSettings = 'Account Settings',
  AccountStrings = 'Account Strings',
  AccountFeatures = 'Account Features',
  DeviceSettings = 'Device Settings',
  AlertsSettings = 'Alerts Settings',
  ExternalIntegrations = 'External Integrations',
  HipaaCompliance = 'HIPAA Compliance',
  Hexnode = 'Hexnode',
  Salesforce = 'Salesforce',
  ClassSession = 'Class Session',
  Apps = 'Apps',
  LinkButton = 'Link Button',
  SchedulingTemplate = 'Scheduling Template',
  NewThemeSettings = 'New Theme Settings',
}

export interface AccountConfigTabItem {
  name: AccountConfigTab;
}

export const AccountTabsHeader = ({
  onSelectedTab,
  tabs
 
}: {
  onSelectedTab: (tab: AccountConfigTabItem) => void;
  tabs: AccountConfigTabItem[];
}) => {

  
  const [activeTab, setActiveTab] = useState<AccountConfigTabItem>(tabs[0]);

  useEffect(() => {
    
    onSelectedTab(activeTab);
  }, [activeTab]);

  return <div className="row no-margin">
  {tabs.map((tab: AccountConfigTabItem) => {
    const isActive = activeTab.name === tab.name;
    const tabStyle: CSSProperties = isActive ? {
      paddingBottom: '3px',
      borderBottom: '3px solid #2A97FF'
    } : undefined;
    return (
      <span key={tab.name} className={'staffTabs2 nucleus-link '} style={tabStyle} onClick={() => setActiveTab(tab)}>
        <span className="nucleusTabText">{tab.name}</span>
      </span>
    );
  })}
  </div>;
};
