import React, { useEffect } from "react";
import { fetchEnterprisesValues } from '@nucleus-care/nucleuscare-backend-client';
import NucleusTable from "../../../../components/NucleusTable";
import { useDispatch, useSelector } from "react-redux";

const MDMEnterprisesTable = ({handleRowClick, enterprisesOptions}) => {
  const columns = [
    {
      Header: "Name",
      accessor: "OrganizationName",
    },
    {
      Header: "Organization ID",
      accessor: "OrganizationID",
    },
  ];
  
  return (
    <div>
      <NucleusTable columns={columns} data={enterprisesOptions} onRowClick={handleRowClick} />
    </div>
  );
};

export default MDMEnterprisesTable;
