import React, { useEffect, useState } from "react";
import NucleusTable from "../../../../components/NucleusTable";
import NucleusSearchInput from "../../../../components/NucleusSearchInput";
import NucleusModalButton from "../../../../components/NucleusModal/NucleusModalButton";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../../../utils/Message";
import {
  fetchMdmAccountPolicyTemplates,
  deletePolicyTemplatesByID,
  fetchMdmAccountByID,
  fetchMdmPolicyData,
  resetPatchPriorityTemplatesByIDState,
  resetDeletePolicyTemplatesByID,
  patchPriorityTemplatesByID,
} from '@nucleus-care/nucleuscare-backend-client';
import { SpinnerCircular } from "spinners-react";
import moment from "moment";
import UnassignPolicyAlert from "./UnassignPolicyAlert";
import MdmAccountSelectPoliciesModal from "./MdmAccountSelectPoliciesModal";
import { useForm } from "react-hook-form";
import { Tooltip } from "@mui/material";

/*
// for reference only
type AccountPoliciesData = {
  // based on policyTemplate
  policyTemplateId: String, // the ID of the policy template
  name: String, // the name of the policy
  version: Number, // the version of the policy
  created: Date, // the date the policy was created
  lastModified: Date, // the date the policy was last modified
  modifiedByName: String, // the name of the user who last modified the policy
  protected: Boolean, // indicates whether the policy is protected

  // based on accountPolicyTemplate
  policyTemplatesId: String, // the ID of the account policy template
  priority: Number, // the priority of the policy
  devices: Number, // the number of devices affected by this policy template
  isAssigned: Boolean, // indicates whether the policy is assigned to this account
  isInTable: Boolean, // indicates whether the policy is in the table (i.e. assigned to this account)
  disabled: Boolean, // indicates whether the policy should be "disabled" in the modal, (same as isInTable)

  isInModal: Boolean, // indicates whether the policy is in the modal (i.e. matches the search)`
  isChecked: Boolean, // indicates whether the policy is checked in the modal

  // dependent data - generated based off of the other data by calling generateDependentData

  // for the table
  unassignComponent: Object, // the component for the unassign column

  // for the modal
  priorityComponent: Object, // the component for the priority column
  selectComponent: Object, // the component for the select column
}
*/

const MdmAccountPolicies = ({ mdmAccountId }) => {
  const dispatch = useDispatch();
  const [
    policyIdOutPriorityInputFocused,
    setPolicyIdOutPriorityInputFocused,
  ] = useState(null);

  const {
    deletePolicyTemplatesByID: deletePolicyTemplatesByIDState, // for keeping track of the state of the delete policy template request
    patchPriorityTemplatesByIDState,
  } = useSelector(({ mdmPolicyTemplates }) => mdmPolicyTemplates.data);
  const {
    assignPolicyTemplatesToMdmAccount: assignPolicyTemplatesToMdmAccountState, // for keeping track of the state of the assign policy template request
  } = useSelector(({ mdmAccounts }) => mdmAccounts.data);
  const {
    success: PolicyTemplatesDeletedSuccess,
  } = deletePolicyTemplatesByIDState;
  const {
    success: PolicyTemplatesAddedSuccess,
  } = assignPolicyTemplatesToMdmAccountState;

  // update data on initiation and whenever a policy template is assigned or unassigned
  useEffect(() => {
    if (mdmAccountId) {
      dispatch(fetchMdmAccountByID(mdmAccountId));
      dispatch(fetchMdmAccountPolicyTemplates(mdmAccountId));
      dispatch(fetchMdmPolicyData());
    }
  }, [
    dispatch,
    mdmAccountId,
    PolicyTemplatesAddedSuccess,
    PolicyTemplatesDeletedSuccess,
  ]);
  // Handle Change Main Table priority
  useEffect(() => {
    if (patchPriorityTemplatesByIDState.success) {
      const policyName = accountPolicyTemplates.find(
        (templates) =>
          templates.ID.toLowerCase() ===
          patchPriorityTemplatesByIDState.policyTemplatesId.toLowerCase()
      );
      Message.show(
        `Priority has been successfully updated for "${policyName?.Name}"`
      );
      dispatch(resetPatchPriorityTemplatesByIDState());
    }
    if (patchPriorityTemplatesByIDState.error) {
      const policyName = accountPolicyTemplates?.find(
        (templates) =>
          templates?.ID.toLowerCase() ===
          patchPriorityTemplatesByIDState?.policyTemplatesId?.toLowerCase()
      );
      Message.error(
        `Failed to update priority for "${policyName?.Name}". Please try again.`
      );
      dispatch(resetPatchPriorityTemplatesByIDState());
    }
  }, [
    patchPriorityTemplatesByIDState.loading,
    patchPriorityTemplatesByIDState.error,
    patchPriorityTemplatesByIDState.success,
  ]);
  const {
    success: allPolicyTemplatesLoaded,
    Policies: allPolicyTemplates,
  } = useSelector(({ mdmPolicy }) => mdmPolicy.data);

  const { fetchAccountPolicyTemplates } = useSelector(
    ({ mdmAccounts }) => mdmAccounts.data
  );

  const {
    success: accountPolicyTemplatesLoaded,
    loaded: accountPolicyTemplatesLoading,
    policyTemplates: accountPolicyTemplates,
  } = fetchAccountPolicyTemplates;

  const [
    policyTemplateIdToAccountPolicyDataDict,
    setPolicyTemplateIdToAccountPolicyDataDict,
  ] = useState({});
  // generates data for rendering that is dependent on other data
  const generateDependentData = (accountPolicyData) => {
    // table components

    // only allow unassign if the policy is not protected
    const unassignComponent = (!accountPolicyData.protected || true) ? (
      <span
        onClick={() => {
          setUnassignAlertIsOpen(true);
          setCurrentPolicyTemplatesID(accountPolicyData.policyTemplatesId);
          setCurrentPolicyTemplatesDeviceCount(accountPolicyData.devices);
          setCurrentPolicyTemplateIsProtected(accountPolicyData.protected);
        }}
        onKeyDown={() => {}}
        role="button"
        style={{ color: "#0092FF", cursor: "pointer", userSelect: "none", display:'inline-flex' }}
      >
        {
          (accountPolicyData.protected) && 
          <Tooltip title={"Protected policy"} placement="right">
          <img
            style={{
              marginLeft:-20
            }}
            src={"./img/lock.svg"}
            alt={"Lock icon"}
          />
        </Tooltip>
        }
        unassign
      </span>
    ) : (
      <Tooltip title={"Protected policy"} placement="right">
        <img
          style={{
            cursor: "not-allowed",
          }}
          src={"./img/lock.svg"}
          alt={"Lock icon"}
        />
      </Tooltip>
    );

    const selectComponent = (
      <input
        type="checkbox"
        disabled={
          accountPolicyData.disabled ||
          !accountPolicyData.priority ||
          accountPolicyData.priority === undefined
        }
        checked={accountPolicyData.disabled || accountPolicyData.isChecked} // should show as checked if the policy is disabled or if it is checked
        className="addAppsCheckBox"
      />
    );

    return {
      ...accountPolicyData,
      unassignComponent: unassignComponent,
      // priorityComponent: priorityComponent,
      selectComponent: selectComponent,
    };
  };

  // load the initial data
  // triggered on initiation and whenever a policy template is assigned or unassigned
  useEffect(() => {
    // wait for allPolicyTemplates and accountPolicyTemplates to load
    if (!allPolicyTemplatesLoaded || !accountPolicyTemplatesLoaded) return;

    // map all accountPolicyTemplateIDs to the full templates
    const accountPolicyTemplateIDToAccountPolicyTemplateDict = new Map(
      accountPolicyTemplates.map((template) => [
        template.PolicyTemplateID,
        template,
      ])
    );

    // iterate over allPolicyTemplates and map each ID to a AccountPoliciesData object
    const originalDataDict = {};
    allPolicyTemplates.forEach((policyTemplate) => {
      const policyTemplateId = policyTemplate.ID;
      const accountPolicyTemplate = accountPolicyTemplateIDToAccountPolicyTemplateDict.get(
        policyTemplateId
      );
      // map the policy IDs to the account policy template data with
      const accountPolicyData = {
        policyTemplateId: policyTemplateId,
        // based on policyTemplate
        name: policyTemplate.Name,
        version: policyTemplate.Version,
        created: policyTemplate.Created,
        lastModified: policyTemplate.LastModified,
        modifiedByName: policyTemplate.ModifiedByName,
        protected: policyTemplate.Protected,

        // based on accountPolicyTemplate
        policyTemplatesId: accountPolicyTemplate?.ID,
        priority: accountPolicyTemplate?.Priority,
        devices: accountPolicyTemplate?.Devices,
        isAssigned: accountPolicyTemplate != undefined,
        isInTable: accountPolicyTemplate != undefined,
        disabled: accountPolicyTemplate != undefined,

        isInModal: true,
        isChecked: false,
      };
      console.log("accountPolicyData", accountPolicyData);
      originalDataDict[policyTemplateId] = accountPolicyData;

      originalDataDict[policyTemplateId] = generateDependentData(
        originalDataDict[policyTemplateId]
      );
    });
    setPolicyTemplateIdToAccountPolicyDataDict((prevDict) => {
      return originalDataDict;
    });
  }, [allPolicyTemplatesLoaded, accountPolicyTemplatesLoaded]);

  const [unassignAlertIsOpen, setUnassignAlertIsOpen] = useState(false);
  const [currentPolicyTemplatesID, setCurrentPolicyTemplatesID] = useState("");
  const [
    accountsSelectPolicyModalIsOpen,
    setAccountsSelectPolicyModalIsOpen,
  ] = useState(false);
  const [
    currentPolicyTemplatesDeviceCount,
    setCurrentPolicyTemplatesDeviceCount,
  ] = useState(0);
  const [currentPolicyTemplateIsProtected, setCurrentPolicyTemplateIsProtected] = useState(false);

  function formattedDate({ cell }) {
    const formatted = moment(cell.value).format("MM/DD/YYYY hh:mm A");
    return <span>{formatted}</span>;
  }

  const accountPolicyTableColumns = [
    {
      Header: "Priority",
      accessor: "priority",
      Cell: ({ row: { original } }) => {
        const isFocused =
          policyIdOutPriorityInputFocused === original.policyTemplatesId;
        const isProtected = false; //original.protected; Enable all actions even to protected policies
        const { register, handleSubmit, setValue, watch } = useForm();
        const onSubmit = async (data) => {
          if (isProtected) return;
          console.log("data", data);
          if (isFocused) {
            dispatch(
              patchPriorityTemplatesByID({
                policyTemplatesId: original.policyTemplatesId,
                Priority: Number(data.priority),
              })
            );
            const newPriority = Number(data.priority);
            setPolicyTemplateIdToAccountPolicyDataDict((prevDict) => {
              const updatedDict = { ...prevDict };
              const newAccountPolicyData =
                updatedDict[original.policyTemplateId];
              newAccountPolicyData.priority = newPriority;
              return updatedDict;
            });
            setPolicyIdOutPriorityInputFocused(null);
          }
        };
        return (
          <span style={{ display: "flex", color: "#0092FF" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Tooltip
                title={isProtected && "Protected policy"}
                placement="right"
                disableHoverListener={!isProtected}
              >
                <input
                  disabled={isProtected}
                  tabIndex={0}
                  id={original.policyTemplatesId}
                  type="number"
                  style={{
                    width: 50,
                    marginRight: 2,
                    textAlign: "center",
                    borderColor: "#0092FF",
                    cursor: isProtected ? "not-allowed" : "text",
                    userSelect: isProtected ? "none" : "auto",
                  }}
                  value={isFocused ? watch("priority") : original.priority}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (isFocused) setValue("priority", e.target.value);
                  }}
                  register={register("priority", {
                    value: original.priority,
                  })}
                  onFocus={() => {
                    setValue("priority", original.priority);
                    setPolicyIdOutPriorityInputFocused(
                      original.policyTemplatesId
                    );
                  }}
                />
              </Tooltip>
              {!isProtected && isFocused && (
                <React.Fragment>
                  <img
                    role="button"
                    src={"./img/untick.svg"}
                    alt={"X icon"}
                    onClick={(e) => {
                      e.stopPropagation();
                      setPolicyIdOutPriorityInputFocused(null);
                      setValue("priority", original.priority);
                    }}
                    onKeyDown={() => {}}
                  />
                  <button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    type="submit"
                  >
                    <img role="button" src={"./img/big_check.svg"} alt={"Check icon"} />
                  </button>
                </React.Fragment>
              )}
            </form>
          </span>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Version",
      accessor: "version",
    },
    {
      Header: "Devices",
      accessor: "devices",
    },
    {
      Header: "Created",
      accessor: "created",
      Cell: formattedDate,
    },
    {
      Header: "Modified",
      accessor: "lastModified",
      Cell: formattedDate,
    },
    {
      Header: "Modified By",
      accessor: "modifiedByName",
    },
    {
      Header: "",
      accessor: "unassignComponent",
      disableSortBy: true,
    },
  ];

  // for unassigning policy templates
  const resetUnassignState = () => {
    setUnassignAlertIsOpen(false);
    setCurrentPolicyTemplatesID(null);
    setCurrentPolicyTemplatesDeviceCount(0);
  };

  const handleUnassign = (policyTemplatesId) => {
    if (!policyTemplatesId) {
      Message.error("An error occurred");
      setUnassignAlertIsOpen(false);
      return;
    }
    dispatch(deletePolicyTemplatesByID(policyTemplatesId));
  };

  useEffect(() => {
    if (PolicyTemplatesDeletedSuccess) {
      resetUnassignState();
      Message.show("Policy Template successfully unassigned");
      dispatch(resetDeletePolicyTemplatesByID());
    }
  }, [PolicyTemplatesDeletedSuccess]);

  const [searchValue, setSearchValue] = useState("");
  return (
    <React.Fragment>
      <NucleusSearchInput
        placeholder="Filter by Name / Version / Devices / Modified by"
        width={"30%"}
        value={searchValue}
        onChange={(e) => {
          const value = e.target.value;
          setSearchValue(value);
          setPolicyTemplateIdToAccountPolicyDataDict((prevDict) => {
            const updatedDict = { ...prevDict };
            for (const accountPolicyData of Object.values(
              policyTemplateIdToAccountPolicyDataDict
            )) {
              accountPolicyData.isInTable =
                accountPolicyData.isAssigned &&
                (accountPolicyData.name.toLowerCase().includes(value) ||
                  accountPolicyData.version.toString().includes(value) ||
                  accountPolicyData.devices.toString().includes(value) ||
                  accountPolicyData.modifiedByName
                    .toLowerCase()
                    .includes(value));
            }
            return updatedDict;
          });
        }}
        actionsActive={true}
      />
      {accountPolicyTemplatesLoading ? (
        <SpinnerCircular
          color="#2096F3"
          secondaryColor="rgba(0,0,0,0.16)"
          size="50"
          thickness="100"
        />
      ) : (
        <NucleusTable
          columns={accountPolicyTableColumns}
          data={Object.values(policyTemplateIdToAccountPolicyDataDict)
            .filter((value) => value.isInTable)
            // sort by descending priority
            .sort((a, b) => b.priority - a.priority)}
            tableStyles={{marginBottom:64}}
        />
      )}
      <UnassignPolicyAlert
        handleCancel={resetUnassignState}
        handleUnassign={() => handleUnassign(currentPolicyTemplatesID)}
        devicesAffectedCount={currentPolicyTemplatesDeviceCount}
        policyProtected={currentPolicyTemplateIsProtected}
        setIsOpen={setUnassignAlertIsOpen}
        isOpen={unassignAlertIsOpen}
        loading={accountPolicyTemplatesLoading}
      />
      <MdmAccountSelectPoliciesModal
        isOpen={accountsSelectPolicyModalIsOpen}
        setIsOpen={setAccountsSelectPolicyModalIsOpen}
        mdmAccountId={mdmAccountId}
        policyTemplateIdToAccountPolicyDataDict={
          policyTemplateIdToAccountPolicyDataDict
        }
        setPolicyTemplateIDToAccountPolicyDataDict={
          setPolicyTemplateIdToAccountPolicyDataDict
        }
        generateDependentData={generateDependentData}
      />
      <NucleusModalButton
        dataTooltip={"New Policy"}
        id={"newPolicy"}
        handleOpenModal={() => {
          setAccountsSelectPolicyModalIsOpen(true);
        }}
      />
    </React.Fragment>
  );
};

export default MdmAccountPolicies;
