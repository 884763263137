import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

interface RedToggleProps {
  id: string;
  text?: string;
  isToggled: boolean;
  onToggleChange: (value: boolean) => void;
}

interface ToggleProps {
  isToggled: boolean;
}
const RedToggle: React.FC<RedToggleProps> = ({ id, text, isToggled, onToggleChange }) => {
  const toggleCheckboxId = 'disabled-devices-toggle-' + id.toLowerCase();
  const onToggleChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onToggleChange(event.target.checked);
  };
  return (
    <ToggleWrapper>
      {text && <p style={{ margin: 10, fontSize: 16, fontWeight: '300', color: '#0A313F' }}>{text}</p>}
      <HiddenCheckboxToggle id={toggleCheckboxId} checked={isToggled} onChange={onToggleChangeHandler} type="checkbox" />
      <HiddenCheckboxToggleLabel htmlFor={toggleCheckboxId} isToggled={isToggled} style={{ height: 20 }}>
        <ToggleButtonSpan isToggled={isToggled} />
      </HiddenCheckboxToggleLabel>
    </ToggleWrapper>
  );
};

export default RedToggle;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

const HiddenCheckboxToggle = styled.input`
  display: none;
`;

const HiddenCheckboxToggleLabel = styled.label<ToggleProps>`
  width: 32px;
  height: 20px;
  background-color: ${(props) => (props.isToggled ? '#ff0000' : '#ccc')};
  border-radius: 30px;
  display: block;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  &::before {
    content: '';
    display: none;
  }
`;

const ToggleButtonSpan = styled.span<ToggleProps>`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px; // Ensure alignment within the toggle background
  left: ${(props) => (props.isToggled ? '16px' : '2px')};
  transition: left 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`;
