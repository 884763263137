import React, { useEffect, useMemo, useState } from 'react';
import NucleusTable from '../../../../components/NucleusTable';
import { useSelector } from 'react-redux';

const MdmComplianceDetails = () => {
  const { fetchDeviceDetails } = useSelector(({ mdmDevice }) => mdmDevice.data);
  const { deviceDetails, loading } = fetchDeviceDetails;
  const [mdmDeviceInfo, setMdmDeviceInfo] = useState({});
  const [nonComplianceDetailsData, setNonComplianceDetails] = useState([]);
  useEffect(() => {
    if (loading || !('MdmDeviceInfo' in deviceDetails)) return;
    const mdmDeviceInfo = JSON.parse(deviceDetails.MdmDeviceInfo);
    setMdmDeviceInfo(mdmDeviceInfo);
    if ('nonComplianceDetails' in mdmDeviceInfo) {
      const parsedNonComplianceDetails = mdmDeviceInfo.nonComplianceDetails;
      setNonComplianceDetails(parsedNonComplianceDetails);
    }
  }, [deviceDetails]);
  const columns = useMemo(() => {
    const defaultCols = [
      {
        Header: 'Settings',
        accessor: 'settingName',
      },
      {
        Header: 'Non Compliance Reason',
        accessor: 'nonComplianceReason',
      },
      {
        Header: 'Package Name',
        accessor: 'packageName',
      },
      {
        Header: 'Installation Failure Reason',
        accessor: 'installationFailureReason',
      },
    ];
    return defaultCols;
  }, []);

  return (
    <div>
      <NucleusTable columns={columns} data={nonComplianceDetailsData} />
    </div>
  );
};

export default MdmComplianceDetails;
