import React from "react";
import { SpinnerCircular } from "spinners-react";

const NucleusButton = ({
  label,
  backGroundColor,
  color,
  action,
  rounded,
  width,
  loading,
  disabled = false,
  ...props
}) => {
  return (
    <div class="col s12 no-margin nucleus-btn-center ">
      <button
        {...props}
        onClick={() => action()}
        disabled={disabled}
        style={{
          backgroundColor: backGroundColor,
          borderRadius: 50,
          borderColor: "#0A313F",
          border: 1,
          color: color || (label === "Cancel" && "#0A313F"),
          height: 40,
          width: width || 136,
        }}
        className="btn"
      >
        {loading ? (
          <SpinnerCircular
            color="#fff"
            secondaryColor="rgba(0,0,0,0.16)"
            size="20"
            thickness="100"
          />
        ) : (
          label
        )}
      </button>
    </div>
  );
};

export default NucleusButton;
