import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import { AxiosError } from 'axios';

export interface IAccountUsageReportData {
  accountId: string;
  accountName: string;
  totalDevices: number;
  totalDevicesWereOnline: number;
  hoursOnline: number;
  callsMade: number;
  callsConnected: number;
  messagesSent: number;
}

export type tAccountUsageReportData = {
  accountId: string;
  accountName: string;
  totalDevices: number;
  totalDevicesWereOnline: number;
  hoursOnline: number;
  callsMade: number;
  callsConnected: number;
  messagesSent: number;
};

export const getAccountsUsageReportData = async (year: number, month: number): Promise<IAccountUsageReportData[] | any> => {
  try {
    const response = await httpNodeApi.get(`report/accounts-usage-report?year=${year}&month=${month}`);
    if (response?.data) return { data: response?.data };
    return null;
  } catch (error:any) {
    let errorMsg = 'An error occurred';
    if (error && (error as AxiosError).isAxiosError) {
      if (error?.response?.data?.message){
        errorMsg = `Error: ${error.response.data.message}`;
      } else if (error?.message){
        errorMsg = error.message;
      }
    } else if (error instanceof Error) {
      errorMsg = error.message;
    }
    return { error : errorMsg}
  }
};
