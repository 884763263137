import { EventEmitter } from "events";
//import Dispatcher from "../Dispatcher";
//import Config from "../Config";
import FamilyApi from "../apis/FamilyApi";
 
class CareFamilyStore extends EventEmitter {
	constructor() {
		super();

		this.FamilyApi = new FamilyApi(this);
		//this.UsersList = localStorage.UsersList;
		//this.UserName = localStorage.UserName || "Coordinator";
		//this.UserAllPatientsAccess = localStorage.UserAllPatientsAccess || false;
		//console.warn(this);
		this.cachedText = "";
	}

	// getAllUsers(){
	// 	return this.UsersList;
	// }

	setCachedSearchText(searchText){
		this.cachedText = searchText;
		//this.saveToLocalStorage();
	}
	getCachedSearchText(){
		return this.cachedText;
	}
	
	getFamilyDetails ({ FamilyMemberID }) {
		console.log("getFamilyDetails ", FamilyMemberID );

		this.FamilyApi.GetFamilyDetails({ FamilyMemberID },(rsp) => {

			// console.log("GetFamilyDetails Response");
			// console.log(rsp);
			
			this.emit("onGetFamilyDetailsResponse", rsp);
		});
	}



	// saveToLocalStorage(){
	// 	console.log("saveToLocalStorage");

	// 	localStorage.UsersList = this.UsersList;
	// 	localStorage.UserName = this.UserName;
	// 	localStorage.UserAllPatientsAccess = this.UserAllPatientsAccess;
	// }


}

const careFamilyStore = new CareFamilyStore;

export default careFamilyStore;