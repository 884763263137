import React from 'react';
import AuthStore from '../stores/AuthStore';
import CareQueueStore from '../stores/CareQueueStore';
import GeneralStore from '../stores/GeneralStore';
import { connect } from 'react-redux';
import CarePatientStore from '../stores/CarePatientStore';
import { Link } from 'react-router-dom';
import createHashHistory from 'history/lib/createHashHistory';
import {
  AccountsSVG,
  CSSettingsSVG,
  FamilySVG,
  ProvidersSVG,
  CsUsersSVG,
  CsConsumerSVG,
  NucleusLogoSVG,
  ReportsSVG,
  DevicesSVG,
  AppsSVG,
  DeviceMDMPoliciesSVG,
  LogoutSVG,
} from './SideBarSVGs/SidebarSVGs';
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';

class SideBar extends React.Component {
  constructor() {
    super();
    this.state = {
      accountsMenuClass: ' ',
      familyMenuClass: ' ',
      reportsMenuClass: ' ',
      toolsMenuClass: ' ',
      userMenuClass: ' ',
      providersMenuClass: ' ',
      policiesMenuClass: ' ',
      consumerMenuClass: ' ',
      devicesMenuClass: ' ',
      queueMenuClass: ' ',
      appsMenuClass: ' ',
      settingsMenuClass: ' ',
      queueNumber: null,
      queueNumberVisibility: ' hide',
      permissions: {
        canAccessAdminUsersTab: true,
        canAccessAccountsTab: true,
        canAccessReportsTab: true,
        canAccessContactsTab: true,
        canAccessProvidersTab: true,
        canAccessMDMTab: true,
        canAccessConsumerOrdersTab: true,
        canAccessDevicesTab: true,
        canAccessAppsTab: true,
        canAccessSettingsTab: true,
      },
      lastCallRequestID: '',
      lastCallRequestPatientID: '',
      callRequests: CareQueueStore.getQueueNumber(),
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.selectAccountsMenu = this.selectAccountsMenu.bind(this);
    this.selectFamilyMenu = this.selectFamilyMenu.bind(this);
    this.selectToolsMenu = this.selectToolsMenu.bind(this);
    this.selectUserMenu = this.selectUserMenu.bind(this);
    this.selectConsumerMenu = this.selectConsumerMenu.bind(this);
    this.selectDevicesMenu = this.selectDevicesMenu.bind(this);
    this.selectAppsMenu = this.selectAppsMenu.bind(this);
    this.selectSettingsMenu = this.selectSettingsMenu.bind(this);
    this.resetMenus = this.resetMenus.bind(this);
    this.onGetQueueNumberAction = this.onGetQueueNumberAction.bind(this);
    this.onGetCareCallRequestsQueueAction = this.onGetCareCallRequestsQueueAction.bind(this);
    this.showNotifications = this.showNotifications.bind(this);

    this.startPollingQueue = this.startPollingQueue.bind(this);

    this.createNotification = this.createNotification.bind(this);
    this.stopNotifications = this.stopNotifications.bind(this);

    this.onStartPollingQueueAction = this.onStartPollingQueueAction.bind(this);
    this.onQueueItemClickedAction = this.onQueueItemClickedAction.bind(this);

    this.onHandledRequestRemovedFromStoreAction = this.onHandledRequestRemovedFromStoreAction.bind(this);

    this.hashHistory = createHashHistory({
      basename: '',
      hashType: 'slash',
      getUserConfirmation: (message, callback) => callback(window.confirm(message)),
    });

    this.callRequests = CareQueueStore.getQueueNumber();

    this.isOnProd = this.isOnProd.bind(this);
  }

  getUserPermissions = () => {
    let rsp;
    httpApi
      .get('/cs_user/me?includePermissions=true')
      .then((response) => {
        const { data } = response;
        console.log('permissions: ', data);
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch((e) => {
        console.error('/cs_user/me error: ', e);
        const { data } = e.message;
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        if (rsp.permissions) {
          this.setState({ permissions: rsp.permissions });
        }
      });
  };

  componentDidMount() {
    CareQueueStore.on('onGetQueueNumber', this.onGetQueueNumberAction);
    CareQueueStore.on('onGetCareCallRequestsQueue', this.onGetCareCallRequestsQueueAction);
    CareQueueStore.on('onStartPollingQueue', this.onStartPollingQueueAction);
    CareQueueStore.on('onQueueItemClicked', this.onQueueItemClickedAction);
    CareQueueStore.on('onHandledRequestRemovedFromStore', this.onHandledRequestRemovedFromStoreAction);
    this.getUserPermissions();
    this.hashHistory.listen((location, action) => {
      this.handleMenusVisibility(location.pathname);
    });

    if (this.state.queueMenuClass === ' ') {
      this.isPolling = true;
    }
  }

  componentWillUnmount() {
    CareQueueStore.removeListener('onGetQueueNumber', this.onGetQueueNumberAction);
    CareQueueStore.removeListener('onGetCareCallRequestsQueue', this.onGetCareCallRequestsQueueAction);
    CareQueueStore.removeListener('onStartPollingQueue', this.onStartPollingQueueAction);
    CareQueueStore.removeListener('onQueueItemClicked', this.onQueueItemClickedAction);
    CareQueueStore.removeListener('onHandledRequestRemovedFromStore', this.onHandledRequestRemovedFromStoreAction);
  }

  onStartPollingQueueAction() {
    this.startPollingQueue();
    this.getUserPermissions();
  }

  startPollingQueue() {
    GeneralStore.setStaffTab(1);
  }

  onGetQueueNumberAction(response) {
    let newQueues = response.number - CareQueueStore.getQueueNumber();
    this.isPolling = false;

    console.log('>>>>#>: ', newQueues);

    if (this.state.queueMenuClass === ' ') {
      if (newQueues > 0) {
        this.setState({
          queueNumber: newQueues,
          queueNumberVisibility: ' ',
        });

        this.createNotification();
      }

      if (!this.isPolling) {
        this.isPolling = true;
        setTimeout(function () {
          CareQueueStore.getCareQueueNumber({
            UserID: AuthStore.getUserID(),
          });
        }, 10000);
      }
    }
  }

  onGetCareCallRequestsQueueAction(success) {
    console.log('onGetCareCallRequestsQueueAction');

    if (this.state.queueMenuClass === ' menu-active') {
      if (this.callRequests < CareQueueStore.getQueueNumber()) {
        console.log('There was a pending request on Queue');
        this.callRequests = CareQueueStore.getQueueNumber();
        this.createNotification();
      }

      setTimeout(function () {
        CareQueueStore.getCareCallRequestsQueueForUser({
          UserID: AuthStore.getUserID(),
        });
      }, 10000);
    }
  }

  onHandledRequestRemovedFromStoreAction() {
    console.log('Refrescando');
    this.callRequests = CareQueueStore.getQueueNumber();
  }

  onQueueItemClickedAction() {
    this.stopNotifications();
  } 

  selectAccountsMenu() {
    this.setState({
      accountsMenuClass: ' menu-active',
    });
  }

  selectFamilyMenu() {
    this.setState({
      familyMenuClass: ' menu-active',
    });
  }

  selectReportsMenu() {
    this.setState({
      reportsMenuClass: ' menu-active',
    });
  }

  selectToolsMenu() {
    this.setState({
      toolsMenuClass: ' menu-active',
    });
  }

  selectProvidersMenu = () => {
    this.setState({
      providersMenuClass: ' menu-active',
    });
  };

  selectUserMenu() {
    this.setState({
      userMenuClass: ' menu-active',
    });
  }

  selectPoliciesMenu = () => {
    this.setState({
      policiesMenuClass: ' menu-active',
    });
  };

  selectConsumerMenu() {
    this.setState({
      consumerMenuClass: ' menu-active',
    });
  }

  selectDevicesMenu() {
    this.setState({
      devicesMenuClass: ' menu-active',
    });
  }

  selectAppsMenu() {
    this.setState({
      appsMenuClass: ' menu-active',
    });
  }

  selectSettingsMenu() {
    this.setState({
      settingsMenuClass: ' menu-active',
    });
  }

  resetMenus() {
    this.setState({
      accountsMenuClass: ' ',
      familyMenuClass: ' ',
      reportsMenuClass: ' ',
      toolsMenuClass: ' ',
      providersMenuClass: ' ',
      userMenuClass: ' ',
      policiesMenuClass: ' ',
      consumerMenuClass: ' ',
      devicesMenuClass: ' ',
      queueMenuClass: ' ',
      appsMenuClass: ' ',
      settingsMenuClass: ' ',
    });
  }

  handleMenusVisibility(currentPath) {
    console.log('PAGE : ', currentPath);

    this.resetMenus();

    if (this.isAccountsPage(currentPath)) {
      this.selectAccountsMenu();
    } else if (currentPath.includes('/reports')) {
      this.selectReportsMenu();
    } else if (currentPath.includes('/family')) {
      this.selectFamilyMenu();
    } else if (this.isProvidersPage(currentPath)) {
      this.selectProvidersMenu();
    } else if (currentPath.includes('/csUsers')) {
      this.selectUserMenu();
    } else if (this.isPoliciesPage(currentPath)) {
      this.selectPoliciesMenu();
    } else if (currentPath.includes('/consumer')) {
      this.selectConsumerMenu();
    } else if (currentPath.includes('/devices')) {
      this.selectDevicesMenu();
    } else if (currentPath.includes('/externalApps')) {
      this.selectAppsMenu();
    } else if (currentPath.includes('/tools')) {
      this.selectToolsMenu();
    } else if (currentPath.includes('/settings')) {
      this.selectSettingsMenu();
    }
  }

  isAccountsPage(currentPath) {
    return (
      (currentPath.includes('/accounts') && !currentPath.includes('/providerDetails')) ||
      currentPath.includes('/accountDetails') ||
      currentPath.includes('/accountUserProfile') ||
      currentPath.includes('/newAccountUser')
    );
  }

  isProvidersPage(currentPath) {
    return (
      currentPath.includes('/providers') || (currentPath.includes('/providerDetails') && currentPath.includes('/accounts')) || currentPath.includes('/providerDetails/accounts')
    );
  }

  isPoliciesPage(currentPath) {
    return currentPath.includes('/policies') || currentPath.includes('/policyDetails') || currentPath.includes('/newPolicy');
  }

  handleLogout() {
    const { dispatch } = this.props;
    AuthStore.logout(dispatch);
  }

  createNotification() {
    let lastCallRequest = CareQueueStore.getLastRequest();
    console.log('NEW REQUEST: ', lastCallRequest);

    if (AuthStore.getOnDuty().toString() === 'true') {
      if (lastCallRequest.RequestID !== this.state.lastCallRequestID) {
        this.setState({
          lastCallRequestID: lastCallRequest.RequestID,
          lastCallRequestPatientID: lastCallRequest.PatientID,
        });
        const patient = CarePatientStore.getPatientInfo(lastCallRequest.PatientID);
        if (patient) {
          console.log('EMERGECNY PATIENT: ', patient);
          this.showNotifications(patient.Name, lastCallRequest.DeviceName, lastCallRequest.PatientID);
        }
      }
    }
  }

  showNotifications(patientName, deviceName, patientID) {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }

  stopNotifications() {
    console.log('PAUSE');

    if (this.ringingStatus) {
      if (this.notificationSound === undefined) {
        console.log('Notification Click UNDEFINED');
        this.notificationSound = new Audio('https://care.nucleuslife.io/callSound/ring.mp3');
      }
      this.notificationSound.pause();
      this.notificationSound.currentTime = 0;
      this.ringingStatus = false;
    }
  }

  isOnProd() {
    console.log('isOnProd', window.location.hostname);
    const url = window.location.hostname;
    if (url.includes('localhost') || url.includes('dev') || url.includes('test')) {
      return false;
    }
    return true;
  }
  render() {
    return (
      <div className="blue col s3 m2 l1 xl1 no-margin nucleus-sidebar">
        <div className="row txt-white menu-content">
          <Link to="/dashboard" className="txt-white">
            <div className="sidebar-logo center-align" onClick={this.startPollingQueue}>
              <br />
              <NucleusLogoSVG />
            </div>
          </Link>

          {this.state.permissions.canAccessAccountsTab && (
            <Link to="/accounts" className="txt-white">
              <div className={'center-align row nucleus-menu' + this.state.accountsMenuClass}>
                <div className="col m12">
                  {/* <img className="responsive-img" src="img/nucleus_care_user.png"/> */}
                  <AccountsSVG active={this.state.accountsMenuClass.length > 1} />
                </div>
                <div className="col m12 sideBarFont">Accounts</div>
              </div>
            </Link>
          )}

          {AuthStore.superUser() && this.state.permissions.canAccessReportsTab && (
            <Link to="/reports" className="txt-white">
              <div className={'center-align row nucleus-menu' + this.state.reportsMenuClass}>
                <div className="col m12">
                  {/* <img className="responsive-img" src="img/nucleus_care_reports.png"/> */}
                  <ReportsSVG active={this.state.reportsMenuClass.length > 1} />
                </div>
                <div className="col m12 sideBarFont">Reports</div>
              </div>
            </Link>
          )}

          {AuthStore.superUser() && this.state.permissions.canAccessContactsTab && (
            <Link to="/family" className="txt-white">
              <div className={'center-align row nucleus-menu' + this.state.familyMenuClass}>
                <div className="col m12">
                  {/* <img className="responsive-img" src="img/nucleus_care_user.png"/> */}
                  <FamilySVG active={this.state.familyMenuClass.length > 1} />
                </div>
                <div className="col m12 sideBarFont">Family</div>
              </div>
            </Link>
          )}

          {AuthStore.superUser() && this.state.permissions.canAccessProvidersTab && (
            <Link to="/providers" className="txt-white">
              <div className={'center-align row nucleus-menu' + this.state.providersMenuClass}>
                <div className="col m12">
                  <ProvidersSVG active={this.state.providersMenuClass.length > 1} />
                </div>
                <div className="col m12 sideBarFont">Providers</div>
              </div>
            </Link>
          )}

          {AuthStore.superUser() && this.state.permissions.canAccessAdminUsersTab && (
            <Link to="/csUsers" className="txt-white">
              <div className={'center-align row nucleus-menu' + this.state.userMenuClass}>
                <div className="col m12">
                  <CsUsersSVG active={this.state.userMenuClass.length > 1} />
                </div>
                <div className="col m12 sideBarFont">
                  Admin
                  <br />
                  Users
                </div>
              </div>
            </Link>
          )}

          {AuthStore.superUser() && this.state.permissions.canAccessMDMTab && (
            <Link to="/policies" className="txt-white">
              <div className={'center-align row nucleus-menu' + this.state.policiesMenuClass}>
                <div className="col m12">
                  <DeviceMDMPoliciesSVG active={this.state.policiesMenuClass.length > 1} />
                </div>
                <div className="col m12 sideBarFont">MDM</div>
              </div>
            </Link>
          )}

          {AuthStore.superUser() && this.state.permissions.canAccessConsumerOrdersTab && (
            <Link to="/consumers" className="txt-white">
              <div className={'center-align row nucleus-menu' + this.state.consumerMenuClass}>
                <div className="col m12">
                  <CsConsumerSVG active={this.state.consumerMenuClass.length > 1} />
                </div>
                <div className="col m12 sideBarFont">Consumer Orders</div>
              </div>
            </Link>
          )}

          {this.state.permissions.canAccessDevicesTab && (
            <Link to="/devices" className="txt-white">
              <div className={'center-align row nucleus-menu' + this.state.devicesMenuClass}>
                <div className="col m12">
                  <DevicesSVG active={this.state.devicesMenuClass.length > 1} />
                </div>
                <div className="col m12 sideBarFont">Devices</div>
              </div>
            </Link>
          )}

          {AuthStore.superUser() && this.state.permissions.canAccessAppsTab && (
            <Link to="/externalApps" className="txt-white">
              <div className={'center-align row nucleus-menu' + this.state.appsMenuClass}>
                <div className="col m12">
                  <AppsSVG active={this.state.appsMenuClass.length > 1} />
                </div>
                <div className="col m12 sideBarFont">Apps</div>
              </div>
            </Link>
          )}

         
          {AuthStore.superUser() && this.state.permissions.canAccessSettingsTab && (
            <Link to="/settings" className="txt-white">
              <div className={'center-align row nucleus-menu' + this.state.settingsMenuClass}>
                <div className="col m12">
                  {/* <img className="responsive-img" src="img/nucleus_care_settings.png"/> */}
                  <CSSettingsSVG active={this.state.settingsMenuClass.length > 1} />
                </div>
                <div className="col m12 sideBarFont">Settings</div>
              </div>
            </Link>
          )}

          <div>
            <Link to="/" className="txt-white menu-logout">
              <div className="row txt-white nucleus-sidebar-bottom" onClick={this.handleLogout}>
                <div className="center-align row nucleus-menu">
                  <div className="col m12" style={{ paddingLeft: 25 }}>
                    <LogoutSVG active={false} />
                  </div>
                  <div className="col m12 sideBarFont">Logout</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function dispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(null, dispatchToProps)(SideBar);
