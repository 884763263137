import React, { useEffect, useState } from "react";
import classNames from "classnames";

const NucleusControlledInput = ({
  noLabel = false,
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  error,
  onFocus,
  onClickClear,
  hideClear,
  style,
  required,
  ...props
}) => {
  return (
    <div className="input-field row">
      <style>
        {`
            .input-field label {
            position: relative; !important;
            }
        `}
      </style>
      {!noLabel && (
        <label htmlFor={name} style={{ minWidth: "190px", marginLeft: 2 }}>
          {label + ":"}
        </label>
      )}
      <input
        style={style}
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={classNames(
          style ? "" : "nucleus-input-mdm",
          ` ${error ? "invalid" : ""}`
        )}
        required={required}
        {...props}
      />
      {error && <span className="helper-text" data-error={error}></span>}
    </div>
  );
};

export default NucleusControlledInput;
