import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import Message from "../utils/Message";
import { IndexLink, Link } from "react-router-dom";

import { useState, useEffect, useRef } from 'react';

import {
	useSelector,
	useDispatch
	//DefaultRootState
} from 'react-redux';

import AuthStore from '../stores/AuthStore';
import { SpinnerCircular } from 'spinners-react';

type AccountUserResetPasswordModalParams = {
    UserID:String;
    UserName:String;
    OnDismiss: Function;
}

const AccountUserResetPasswordModal = (props:AccountUserResetPasswordModalParams) => {

    const dispatch = useDispatch();
    const ResetState = {
        CLEAN : 1,
        INITIATED: 2,
        COMPLETE:3,
        FAILED: 4
    }
    const ButtonStyle = {
        ENABLED : "nucleus-submit-btn",
        DISABLED:  "nucleus-submit-btn-disabled",
    }

    const [stateLoading, setStateLoading] = useState(false);

    const [stateSelectedUserID, setStateSelectedUserID] = useState(null);
    const [stateSelectedUserName, setStateSelectedUserName] = useState("");
    const [stateSelectedUserNewCode, setStateSelectedUserNewCode] = useState("");

    const [stateSaveButton1Style, setStateSaveButton1Style] = useState(ButtonStyle.ENABLED);
    const [stateSaveButton2Style, setStateSaveButton2Style] = useState(ButtonStyle.ENABLED);
    const [stateBtnAllAppsStyle, setStateBtnAllAppsStyle] = useState("nucleus-submit-btn-small");

    const [stateResetState, setStateResetState] = useState(ResetState.CLEAN);


	useEffect(() => {
		console.log("AccountUserResetPasswordModal Initial useEffect", props);
        window.$('#modalResetUserPassword').modal({
            dismissible: false,
            complete: function() {  } // Callback for Modal close
        });
        return ()=>{
            console.log("AccountUserResetPasswordModal Initial useEffect Bye!!!!!");
            props.OnDismiss && props.OnDismiss();
        }
		//refreshReport();
	}, []);

	useEffect(() => {
		console.log("AccountUserResetPasswordModal Initial useEffect props.UserID", props.UserID);

        setStateSelectedUserID(props.UserID);
        setStateSelectedUserName(props.UserName);

        if (props.UserID && props.UserName){
            setStateSaveButton1Style(ButtonStyle.ENABLED);
            setStateResetState(ResetState.CLEAN);
            setStateLoading(false);
            window.$('#modalResetUserPassword').modal('open');
        }

	}, [props.UserID]);


    const handleCloseModal=()=>{
        props.OnDismiss && props.OnDismiss();
        window.$('#modalResetUserPassword').modal('close');
    }

    const cancelReset = () =>{
        console.log("AccountUserResetPasswordModal cancelReset");
        window.$('#modalResetUserPassword').modal('close');
        props.OnDismiss && props.OnDismiss();
    }

    const confirmReset = () =>{
        console.log("AccountUserResetPasswordModal confirmReset");
        setStateSaveButton1Style(ButtonStyle.DISABLED);
        setStateResetState(ResetState.INITIATED);
        setStateLoading(true);

        AuthStore.resetTempPassword(stateSelectedUserID,
            (response)=>{
                console.log("AuthStore.resetTempPassword for ", stateSelectedUserID, response);
                setStateLoading(false);
                if (response.ok && response.tempPassword){
                    setStateResetState(ResetState.COMPLETE);
                    setStateSelectedUserNewCode(response.tempPassword);
                }
                else{
                    setStateSaveButton1Style(ButtonStyle.ENABLED);
                    setStateResetState(ResetState.FAILED);
                }
            }
        )
        // setTimeout(() => {
        //     //setStateResetState(ResetState.COMPLETE);
        //     setStateSaveButton1Style(ButtonStyle.ENABLED);
        //     setStateResetState(ResetState.FAILED);
        //     setStateSelectedUserNewCode("blabla");
        //     setStateLoading(false);
        // }, 2000);
    }

    const copySelectedUserNewCode = async ()=>{

        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(stateSelectedUserNewCode);
            Message.show("Copied!")
        } else {
            document.execCommand('copy', true, stateSelectedUserNewCode);
            Message.show("Copied!")
        }
    }

    return(

        <div class="row">
            <div id="modalResetUserPassword" className="modal modalResetUserPassword modalResetUserPasswordForce">
                <div class="modal-content">
                    <img
                        class="responsive-img img-close-modal"
                        src="img/close_modal.png"
                        onClick={handleCloseModal}
                    />
                    <div class="row center-align">
                        <h3 class="nucleus-page-subtitle"> Generate Temporal Password</h3>
                    </div>

                    {
                        (stateResetState != ResetState.COMPLETE) &&
                        <div class="row">
                            <br />
                            <div class="col s12 no-margin center-align">
                                <span class="nucleus-labels">
                                    Are you sure you want to generate a temporal password code for  <span class="nucleus-labels-bold">{ stateSelectedUserName}</span> ?
                                </span>
                            </div>
                        </div>
                    }


                    <div class="row">
                            <br />
                            {   (stateLoading) &&

                                <div class="row center-align">
                                    <SpinnerCircular
                                        color="#2096F3"
                                        secondaryColor='rgba(0,0,0,0.16)'
                                        size='50'
                                        thickness='100'
                                    />
                                </div>
                            }

                            {
                                (stateResetState == ResetState.COMPLETE) &&
                                <div class="row center-align">
                                     <div class="col s12 no-margin">
                                        <span class="nucleus-labels">
                                            The temporal password code for <span class="nucleus-labels-bold">{stateSelectedUserName }</span> is:
                                        </span>
                                    </div>
                                    <br/>
                                    <br/>
                                    <div class="col s12 no-margin center-align">
                                        <span class="nucleus-labels">
                                            <span class="nucleus-labels-large-bold">
                                            {stateSelectedUserNewCode}
                                            </span>
                                        </span>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div class="col s12 center-align no-margin">
                                        <a  class={"txt-white nucleus-font-medium " + stateSaveButton2Style }
                                            onClick={copySelectedUserNewCode} >
                                            Copy
                                        </a>
                                    </div>
                                    <br/><br/><br/>
                                    <div class="col s12 center-align no-margin">
                                        <a  class={"txt-white nucleus-font-medium " + stateSaveButton2Style }
                                            onClick={handleCloseModal} >
                                            Close
                                        </a>
                                    </div>
                                </div>
                            }
                            {
                                (stateResetState == ResetState.FAILED) &&
                                <div class="row center-align">
                                     <div class="col s12 no-margin">
                                        <span class="nucleus-labels">
                                            There was an error, please try again.
                                        </span>
                                    </div>
                                    <br/>
                                    <br/>
                                    <div class="col s12 no-margin center-align">
                                        <span class="nucleus-labels">
                                            {stateSelectedUserNewCode}
                                        </span>
                                    </div>
                                    <br/>
                                </div>
                            }

                            {
                                (stateResetState != ResetState.COMPLETE) &&
                                <div class="row center-align">
                                     <div class="col s2 center-align no-margin"></div>
                                    <div class="col s4 center-align no-margin">
                                        <p></p>
                                        <a  class={"txt-white nucleus-font-medium " + stateSaveButton1Style }
                                            onClick={cancelReset} >
                                            Cancel
                                        </a>
                                    </div>
                                    <div class="col s4 center-align no-margin">
                                        <p></p>
                                        <a  class={"txt-white nucleus-font-medium " + stateSaveButton1Style }
                                            onClick={confirmReset}>
                                            Confirm
                                        </a>
                                    </div>
                                    <div class="col s2 center-align no-margin"></div>
                                </div>
                            }

                    </div>
                </div>
            </div>

        </div>
    );
}

export default AccountUserResetPasswordModal;
