import React, { useState, useEffect } from 'react';
import moment from 'moment';

import CareAccountStore from "../../stores/CareAccountStore";
import MdmDeviceApi from '../../apis/MdmDeviceApi';
import message from '../../utils/Message';

type RowParams = {
    index: Number;
	data: Object;
    onClick: Function;
};


const DeviceTableRow = (props:RowParams) => {

    const [stateEnabled, setStateEnabled] = useState(false);

    useEffect(() => {
		//console.log("DeviceTableRow useEffect", props.data.RemoteLogs);
        setStateEnabled(props.data.RemoteLogs);
        return () =>{

        }
	}, []);
    
    const onClicked = ()=>{
        console.log("DeviceData", props.data);
        props.onClick(props.data);
    }

    const handleDownloadLogs = ()=>{
        console.log("handleDownloadLogs");
        CareAccountStore.handleOpenDownloadDeviceLogsModal({
			DeviceMac : props.data.Mac
		});
    }

    const handleCheckRemoteLogs = (value)=>{
        console.log("handleCheckRemoteLogs", value);
        CareAccountStore.handleEnabledRemoteLogs({
			DeviceID: props.data.DeviceID,
			Enabled: value
		});
    }

    const handleDeleteDevice = ()=>{
        console.log("handleDeleteDevice");
        CareAccountStore.handleOpenDeleteDeviceModal({
			DeviceID : props.data.DeviceID,
			DeviceMac : props.data.Mac,
            DeviceName : props.data.DeviceName,
            PreAssignedName: props.data.PreAssignedName,
            DeviceAccountID : props.data.AccountID,
			DeviceAssigned : !(props.data.PatientDeviceID == null || props.data.Deleted)
		});
    }

    const handleDeviceReboot = ()=>{
        console.log("handleDeviceReboot");
        if(props.data.HexnodeID) {
            CareAccountStore.requestRebootDevice({
                Mac : props.data.Mac,
                DeviceID : props.data.DeviceID,
            });
        } else {
            const mdmDeviceApi = new MdmDeviceApi();
            mdmDeviceApi.requestDeviceReboot({
                MdmID: props.data.MdmID
            }, (response) => {
                console.log("requestDeviceReboot response", response);
                if (response.ok) {
                    message.show("Device reboot request has been sent", "success");
                } else {
                    message.show("Failed to send device reboot request", "error");
                }
            });
        }
    }

    let patientName = props.data.PatientName;
    let deviceName = props.data.DeviceName;
    if (props.data.PatientDeviceID == null || props.data.Deleted){
        patientName = "N/A";
        deviceName = null;
    }

    let lastOnlineDate = "Device Offline";
    if (props.data.LastOnLineDate){
        lastOnlineDate = moment(props.data.LastOnLineDate).format("MM/DD/YYYY hh:mm A");
    }

    let deviceStatusIcon = "img/icon_device_inactive.png";
    if (props.data.Online){
        deviceStatusIcon = "img/icon_device_active.png";
    }
    //let rebootDeviceVisibility = " hide";
    //let rebootDeviceSpace = " ";
    let deviceVersion = "";
    if (props.data.OS){
        deviceVersion = props.data.OS + " ";
        if (deviceVersion.includes("DVT")){
            deviceVersion = "Legacy ";
        }
    }
    if (props.data.Version){
        deviceVersion += props.data.Version;
    }
    if (props.data.ShellVersion){
        deviceVersion += " " + props.data.ShellVersion;
    }

    let defaultValueCheckbox = '';
    if (props.data.RemoteLogs) {
        defaultValueCheckbox = "checked";
    }
    let wifi = "";
    if (props.data.HardwareInfo){
        wifi = JSON.parse(props.data.HardwareInfo);
        try{
            wifi = JSON.parse(wifi);
        }catch(e){}
        if (wifi.wifi){
            wifi = wifi.wifi;
        }
    }

    let is_iOS = props.data.OS && props.data.OS.includes("ios");

    return(
        <tr key={"classRow" + props.data.DeviceID}>
            {/* <td class="table-col-2">
                {props.index}
            </td> */}
            <td class="b-col-2">
                {/* <span style={{fontSize:12, color:'#F7F7F7'}}> {(props.index +1)}</span> */}
                {props.data.AccountName}
            </td>
            <td class="b-col-2">
                <p style={{width:"100%",textOverflow:'ellipsis'}}>
                    {props.data.HexnodeID ? props.data.HexnodeID :( props.data.MdmDeviceID ? props.data.MdmDeviceID : props.data.Mac)}
                </p>
            </td>
            <td class="b-col">
                <div>
                    {patientName}
                </div> 
            </td>
            <td class="">
                <div>
                    {deviceName}
                </div>    
            </td>
            <td class="">
                <div>
                    {deviceVersion}
                </div> 
            </td>
            {/* <td class="" style={{textAlign : 'center'}}>{props.data.Battery ? props.data.Battery + "%" : ""}</td> */}
            {/* <td class="" style={{textAlign : 'center'}}>{wifi}</td> */}
            <td class="" style={{justifyContent : 'center', textAlign : 'center'}}>
                <div style={{ display:'inline-flex'}}>
                    <img class=" " src={deviceStatusIcon} />
                </div>
            </td>
            <td class="" style={{justifyContent : 'center', textAlign : 'center'}}>
                <div style={{ display:'inline-flex'}}>
                    {(props.data.Battery ? props.data.Battery + "%" : "-")}
                </div>
            </td>
            <td class="b-col-2">{lastOnlineDate}</td>
            {/* <td class="" style={{textAlign : 'center'}}>{props.data.Timezone}</td> */}
            
            <td class="">
                <span class="nucleus-table-icon nucleus-link" onClick={onClicked}>
                    <i class="Tiny material-icons">view_headline</i>
                </span>
            </td>
            <td class="">
                 <span
                    class="nucleus-table-icon nucleus-link"
                    style={{ padding: 3 }}
                > 
                    <i
                        class="Tiny material-icons"
                        onClick={handleDownloadLogs}
                    >
                        file_download
                    </i>
                    </span>  
                <span class="nucleus-table-icon nucleus-link" style={{ padding: 3 }}>
                    {stateEnabled && 
                        <i class="Tiny material-icons"
                            onClick={()=>{
                                setStateEnabled(false);
                                handleCheckRemoteLogs(false);
                            }}>
                            check_box
                        </i>
                    }
                    {!stateEnabled && 
                        <i class="Tiny material-icons"
                            onClick={()=>{
                                setStateEnabled(true);
                                handleCheckRemoteLogs(true);
                            }}>
                            check_box_outline_blank
                        </i>
                    }
                </span>
            </td>
            
            <td class="">
            <div style={{ display:'inline-flex'}}>
				
                <span
                    class="nucleus-table-icon nucleus-link tooltipped"
                    data-delay="50"
                    data-position="top"
                    data-tooltip="Remove"
                >
                    <i
                        class="Tiny material-icons"
                        onClick={handleDeleteDevice}
                    >
                        delete
                    </i>
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                        class="nucleus-table-icon nucleus-link tooltipped "
                        data-delay="50"
                        data-position="top"
                        data-tooltip="Remote restart"
                    >
                        {
                        (!is_iOS) && 
                            <img
                            src="img/icon_device_restart.png"
                            class="nucleus-link"
                            onClick={handleDeviceReboot}
                            width="28"
                            />
                        }
                        {
                        (is_iOS && props.data.HexnodeID) && 
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        }
                    </span>
                </div>
            </td>
        </tr>
    );
}

export default DeviceTableRow;
