import React from 'react';
import PropTypes from 'prop-types';
const DeleteActionModalContent = ({
  selectedDevices,
  openModal,
  deleteDevices,
  failedDevices,
  successDevices,
}) => {
  const renderStatusByMdmDeviceID = (mdmDeviceId) => {
    const failedDevice = failedDevices.find(
      (result) => result.device.MdmDeviceID === mdmDeviceId,
    );
    const successDevice = successDevices.find(
      (result) => result.device.MdmDeviceID === mdmDeviceId,
    );
    if (failedDevice) {
      return (
        <p style={{ color: '#FE3824', fontSize: 14, fontWeight: 'bold' }}>
          Failed: {failedDevice.errors.map((error) => error).join(', ')}
        </p>
      );
    }
    if (successDevice) {
      return (
        <p style={{ color: '#3DBE29', fontSize: 14, fontWeight: 'bold' }}>
          Success
        </p>
      );
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p style={{ color: '#FE3824', fontSize: 60, fontWeight: 'bold' }}>
          WARNING!
        </p>
        <p
          style={{
            color: '#0A313F',
            fontSize: 20,
            fontWeight: '325',
            marginTop: '2%',
          }}
        >{`You are about to unassign and delete the following ${selectedDevices.length} devices from`}</p>
        <p
          style={{
            color: '#0A313F',
            fontSize: 20,
            fontWeight: '350',
            marginTop: '5%',
          }}
        >
          Nucleus MDM{' '}
        </p>
      </div>
      <div
        style={{
          width: '95%',
          backgroundColor: '#FFFFFF',
          marginTop: '5%',
          overflowY: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <table style={{ width: '95%' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ textAlign: 'center' }}>Nucleus Patient</th>
              <th style={{ textAlign: 'center' }}>Nucleus Account</th>
              <th style={{ textAlign: 'center' }}>MDM Account</th>
              <th style={{ textAlign: 'center' }}></th>
            </tr>
          </thead>
          <tbody>
            {selectedDevices.map((device) => (
              <tr
                key={device.MdmDeviceID}
                style={{ borderBottom: '1px solid #F1F2F2' }}
              >
                <td>{device.MdmDeviceID}</td>
                <td style={{ textAlign: 'center' }}>
                  {device.CarePatientName || 'N/A'}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {device.CareAccountName || '-'}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {device.MdmAccountName || 'unknown'}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {renderStatusByMdmDeviceID(device.MdmDeviceID)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            color: '#0A313F',
            fontSize: 20,
            fontWeight: '325',
            marginTop: '5%',
          }}
        >
          This action is permanent. Would you like to proceed?
        </p>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-evenly',
            marginTop: '5%',
            marginBottom: '2%',
          }}
        >
          <button
            onClick={() => openModal(false)}
            style={{
              backgroundColor: 'transparent',
              color: '#0A313F',
              borderWidth: 2,
              borderColor: '#0A313F',
              width: 135,
              height: 40,
              borderRadius: 20,
            }}
          >
            CANCEL
          </button>
          <button
            onClick={() => deleteDevices()}
            style={{
              backgroundColor: '#0A313F',
              color: '#FFFFFF',
              borderWidth: 2,
              borderColor: '#0A313F',
              width: 135,
              height: 40,
              borderRadius: 20,
            }}
          >
            PROCEED
          </button>
        </div>
      </div>
    </div>
  );
};

DeleteActionModalContent.propTypes = {
  selectedDevices: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
  deleteDevices: PropTypes.func.isRequired,
  failedDevices: PropTypes.array.isRequired,
  successDevices: PropTypes.array.isRequired,
};

export default DeleteActionModalContent;
