import React, { useEffect, useState } from 'react';
import NucleusSearchInput from '../../../components/NucleusSearchInput';
import MDMNavTabs from '../components/MDMNavTabs';
import MdmAccountsTable from './components/MdmAccountsTable';
import NucleusModalButton from '../../../components/NucleusModal/NucleusModalButton';
import NucleusModal from '../../../components/NucleusModal';
import CreateMdmAccountForm from './components/CreateMdmAccountForm';
import CreateMDMYellowMessage from './components/CreateMDMYellowMessage';

const MDMAccounts = () => {
  const [searchValue, setSearchValue] = useState('');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  useEffect(() => {
    return () => {
      setCreateModalOpen(false);
    };
  }, []);
  return (
    <div class="nucleus-tools-container">
      <div>
        <p class="nucleus-tools-page-title">
          <div className="row a-bit-lower left-align">
            <MDMNavTabs activeTab="accounts" type="main" />
            <div class="nucleus-news-separator">&nbsp;</div>
          </div>
        </p>
        <NucleusSearchInput
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          value={searchValue}
          placeholder={'Filter by Name / Enterprise'}
        />
        <br />
      </div>
      <div class="row nucleus-fixed-container nucleus-table-container-s-90">
        <style>
          {`
          .nucleus-table-container-s-90{
              /*overflow: scroll;*/
              overflow-y: scroll;
          }
      `}
        </style>
        <MdmAccountsTable searchText={searchValue} />
      </div>
      <NucleusModal
        outsideComponent={<CreateMDMYellowMessage />}
        modalHeight={500}
        setIsOpen={() => {
          setCreateModalOpen(!createModalOpen);
        }}
        isOpen={createModalOpen}
      >
        <CreateMdmAccountForm
          setIsModalOpen={(value) => setCreateModalOpen(value)}
        />
      </NucleusModal>
      <NucleusModalButton
        handleOpenModal={() => setCreateModalOpen(!createModalOpen)}
      />
    </div>
  );
};

export default MDMAccounts;
