import { NucleusTabProps } from "../../../components/UI";

export const ProviderAvailableTabs: NucleusTabProps[] = [
    {
        route: "/providerDetails/:providerId/settings",
        title: "Provider Settings",
        slug: "provider-settings",
        disabled: false,
        fields: ["field1", "field2", "field3"],
    },
    {
        route: "/providerDetails/:providerId/strings",
        title: "Provider Strings",
        slug: "provider-strings",
        disabled: false,
        fields: ["field1", "field2", "field3"],
    },
    {
        route: "/providerDetails/:providerId/accounts",
        title: "Provider Accounts",
        slug: "provider-accounts",
        disabled: false,
        fields: ["field1", "field2", "field3"],
    },
    {
        route: "/providerDetails/:providerId/users",
        title: "Provider Users",
        slug: "provider-users",
        disabled: false,
        fields: ["field1", "field2", "field3"],
        titleInfo: "provider-user-count"
    },
];