import styled from 'styled-components';
import { SwitchContainer } from '../../../ui/SwitchContainer';
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { useEffect, useState } from 'react';
import { AccountApi } from '../../../sources/accountApi';

const MainContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

export const AccountFeatures = ({
  accountId,
}: {
  accountId: string;
}) => {

  const [patientGroupEnabled, setPatientGroupEnabled] = useState<boolean>(false);
  const [loadingFeatures, setLoadingFeatures] = useState<boolean>(true);
  const getFeatures = async () => {
    setLoadingFeatures(true);
    const { data } = await AccountApi.getFeatures({ accountId });
    console.log('data', data);
    setLoadingFeatures(false);
    setPatientGroupEnabled(data.EnablePatientGroups);
  }

  const toggleFeature =  async (state: boolean) => {
    try{
      await AccountApi.togglePatientGroups({ accountId, state });
    }catch(e){
      console.warn('Error toggling feature', e);
    }
  }

  useEffect(() => {
    if(accountId){
      getFeatures();
    }
  }, [accountId]);


  return (
    <MainContainer>
      <Column>
        <SwitchContainer loading={loadingFeatures} label="Enable Patient Groups" onChange={(state: boolean) => {
          toggleFeature(state);
          setPatientGroupEnabled(state);
        }} checked={patientGroupEnabled} />
      </Column>
    </MainContainer>
  );
};
