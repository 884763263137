interface SVGProps {
  active: boolean;
  disabled: boolean;
}
export const SortArrowDown = () => (
  <svg style={{ transform: 'scaleY(-1)', marginLeft: 5 }} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="12" height="9" fill="#0092FF" />
      <g id="1. MDM Policies" clipPath="url(#clip0_0_1)">
        <rect width="1321" height="952" transform="translate(-173 -216)" fill="white" />
        <path
          id="Triangle 17"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.59313 1.56961C5.79254 1.29044 6.20746 1.29044 6.40687 1.56961L10.4353 7.20938C10.6717 7.54031 10.4351 8 10.0284 8L1.9716 8C1.56491 8 1.32835 7.54032 1.56473 7.20938L5.59313 1.56961Z"
          fill="#0092FF"
          stroke="#0092FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect width="1321" height="952" fill="white" transform="translate(-173 -216)" />
        </clipPath>
      </defs>
    </svg>
  </svg>
);
export const SortArrowUp = () => (
  <svg style={{ marginLeft: 5 }} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="12" height="9" fill="#0092FF" />
    <g id="1. MDM Policies" clipPath="url(#clip0_0_1)">
      <rect width="1321" height="952" transform="translate(-173 -216)" fill="white" />
      <path
        id="Triangle 17"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59313 1.56961C5.79254 1.29044 6.20746 1.29044 6.40687 1.56961L10.4353 7.20938C10.6717 7.54031 10.4351 8 10.0284 8L1.9716 8C1.56491 8 1.32835 7.54032 1.56473 7.20938L5.59313 1.56961Z"
        fill="#0092FF"
        stroke="#0092FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect width="1321" height="952" fill="white" transform="translate(-173 -216)" />
      </clipPath>
    </defs>
  </svg>
);

export const SortNeutral = () => (
  <svg style={{ marginLeft: 5, marginBottom: -4 }} width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="CombinedArrows" clipPath="url(#clip0_0_3)">
      {/* Down Arrow */}
      <path
        id="TriangleDown"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59313 1.56961C5.79254 1.29044 6.20746 1.29044 6.40687 1.56961L10.4353 7.20938C10.6717 7.54031 10.4351 8 10.0284 8L1.9716 8C1.56491 8 1.32835 7.54032 1.56473 7.20938L5.59313 1.56961Z"
        fill="#C4C4C4"
        stroke="#C4C4C4"
      />
      {/* Up Arrow */}
      <path
        id="TriangleUp"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59313 16.4304C5.79254 16.7096 6.20746 16.7096 6.40687 16.4304L10.4353 10.7906C10.6717 10.4597 10.4351 10 10.0284 10L1.9716 10C1.56491 10 1.32835 10.4597 1.56473 10.7906L5.59313 16.4304Z"
        fill="#C4C4C4"
        stroke="#C4C4C4"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_3">
        <rect width="1321" height="952" fill="white" transform="translate(-173 -216)" />
      </clipPath>
    </defs>
  </svg>
);

export const FastForwardArrowIcon = ({ active, disabled }: SVGProps) => {
  const ifActive = active ? '#0092FF' : '#999';
  return (
    <svg width={9 * 3} height={9 * 3} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.34481 4.50006L1.98493 2.14018C1.83868 1.99393 1.83868 1.75618 1.98493 1.60993C2.13118 1.46368 2.36893 1.46368 2.51518 1.60993L5.14018 4.23493C5.28681 4.38118 5.28681 4.61893 5.14018 4.76518L2.51518 7.39018C2.36893 7.53643 2.13118 7.53643 1.98493 7.39018C1.83868 7.24393 1.83868 7.00618 1.98493 6.85993L4.34481 4.50006ZM6.21981 4.50006L3.85993 2.14018C3.71368 1.99393 3.71368 1.75618 3.85993 1.60993C4.00618 1.46368 4.24393 1.46368 4.39018 1.60993L7.01518 4.23493C7.16181 4.38118 7.16181 4.61893 7.01518 4.76518L4.39018 7.39018C4.24393 7.53643 4.00618 7.53643 3.85993 7.39018C3.71368 7.24393 3.71368 7.00618 3.85993 6.85993L6.21981 4.50006Z"
        fill={disabled ? '#D8D8D8' : ifActive}
      />
    </svg>
  );
};

export const ForwardArrowIcon = ({ active, disabled }: SVGProps) => {
  const ifActive = active ? '#0092FF' : '#999';
  return (
    <svg width={9 * 3} height={9 * 3} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46981 4.50006L3.10993 2.14018C2.96368 1.99393 2.96368 1.75618 3.10993 1.60993C3.25618 1.46368 3.49393 1.46368 3.64018 1.60993L6.26518 4.23493C6.41181 4.38118 6.41181 4.61893 6.26518 4.76518L3.64018 7.39018C3.49393 7.53643 3.25618 7.53643 3.10993 7.39018C2.96368 7.24393 2.96368 7.00618 3.10993 6.85993L5.46981 4.50006Z"
        fill={disabled ? '#D8D8D8' : ifActive}
      />
    </svg>
  );
};

export const BackArrowIcon = ({ active, disabled }: SVGProps) => {
  const ifActive = active ? '#0092FF' : '#999';
  return (
    <svg width={9 * 3} height={9 * 3} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53019 4.49994L5.89007 6.85982C6.03632 7.00607 6.03632 7.24382 5.89007 7.39007C5.74382 7.53632 5.50607 7.53632 5.35982 7.39007L2.73482 4.76507C2.58819 4.61882 2.58819 4.38107 2.73482 4.23482L5.35982 1.60982C5.50607 1.46357 5.74382 1.46357 5.89007 1.60982C6.03632 1.75607 6.03632 1.99382 5.89007 2.14007L3.53019 4.49994Z"
        fill={disabled ? '#D8D8D8' : ifActive}
      />
    </svg>
  );
};
export const RewindArrowIcon = ({ active, disabled }: SVGProps) => {
  const ifActive = active ? '#0092FF' : '#999';
  return (
    <svg width={9 * 3} height={9 * 3} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Rewind">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.65519 4.49994L7.01507 6.85982C7.16132 7.00607 7.16132 7.24382 7.01507 7.39007C6.86882 7.53632 6.63107 7.53632 6.48482 7.39007L3.85982 4.76507C3.71319 4.61882 3.71319 4.38107 3.85982 4.23482L6.48482 1.60982C6.63107 1.46357 6.86882 1.46357 7.01507 1.60982C7.16132 1.75607 7.16132 1.99382 7.01507 2.14007L4.65519 4.49994ZM2.78019 4.49994L5.14007 6.85982C5.28632 7.00607 5.28632 7.24382 5.14007 7.39007C4.99382 7.53632 4.75607 7.53632 4.60982 7.39007L1.98482 4.76507C1.83819 4.61882 1.83819 4.38107 1.98482 4.23482L4.60982 1.60982C4.75607 1.46357 4.99382 1.46357 5.14007 1.60982C5.28632 1.75607 5.28632 1.99382 5.14007 2.14007L2.78019 4.49994Z"
          fill={disabled ? '#D8D8D8' : ifActive}
        />
      </g>
    </svg>
  );
};
