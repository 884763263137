import Compress from 'compress.js'

export default (files, opts = {}) => {
    const compress = new Compress()
    let multiple = true;
    if (!(files instanceof Array)){
        files = [files]
        multiple = false;
    }
    console.log("files", files)
    return compress.compress(files, {
        size: 1, // the max size in MB, defaults to 2MB
        quality: .75, // the quality of the image, max is 1,
        maxWidth: 1920, // the max width of the output image, defaults to 1920px
        maxHeight: 1920, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
        rotate: false, // See the rotation section below
        ...opts
    })
    .then((data) => {
        let files = []
        data.forEach((img) => {
            const base64str = img.data
            const imgExt = img.ext
            const file = Compress.convertBase64ToFile(base64str, imgExt)
            files.push(file)
        })
        if(multiple){
            return files
        }

        return files.pop()
    })
}