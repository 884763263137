import React from 'react';
import Message from "../utils/Message";
import { Redirect } from 'react-router';



class FamilyMemberItemRow extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            MemberID : this.props.MemberID

        };
        this.appInfo = null;
        //console.log(this.state.patientID);
        this.handleRowClicked = this.handleRowClicked.bind(this);
    }

    componentDidMount()
    {
        
    }

    componentWillUnmount()
    {

    }

    handleRowClicked(){
        console.log("handleRowClicked");
        console.log("", this.props.MemberID);
        console.log(this.appInfo);
        this.props.onRowClicked && this.props.onRowClicked();
        window.location.assign("#/familyDetails/" + this.props.MemberID);
    }


    render()
    {

        //let receiveUpdates = this.props.ReceiveUpdates ? "Y" : "N";
        let profileCompleted = this.props.ProfileCreated ? "Active" : "Pending";

        // let telephone = this.props.Telephone;
        // if (telephone != null && telephone.length > 18){
        //     telephone = telephone.substring(0, 18) + " ...";
        // }

        // DEPRECATED
        // FamilyAppInfo was removed from the response due to the hight performance cost to get that info
        //  from the table TCareLoginLog
        // let appInfo = "";
        // if (this.props.FamilyAppInfo != "" && this.props.FamilyAppInfo != null ){
        //     try{
        //         this.appInfo = JSON.parse(this.props.FamilyAppInfo);
        //         appInfo = this.appInfo.systemName + " " + this.appInfo.version;
        //     }
        //     catch (e){}
        // }

        return (

            <tr>
                <td>{ this.props.Name }</td>
                <td>{ this.props.Email }</td>
                <td>{ this.props.Telephone }</td>
                <td class="center-align" >{ profileCompleted }</td>

                <td class="center-align" >{ this.props.VerificationCode }</td>

                <td class="nucleus-row center-align"  onClick={this.handleRowClicked}>
                    <span class="nucleus-table-icon nucleus-link">
                        <i class="Tiny material-icons">chevron_right</i>
                    </span>
                </td>
                
            </tr>
               
        );
    }
}

export default FamilyMemberItemRow;
