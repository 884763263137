import React from "react";
import {
  Container,
  ContentContainer,
  BlueContainer,
  DownloadImage,
  NucleusLogoImage,
  SpacingComponent,
} from "./components";
const UpdateRequired = ({ updateAction }) => {
  return (
    <Container>
      <BlueContainer
        style={{
          borderRadius: 15,
          width: "90vw",
          height: "90vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ContentContainer>
          <img src="./img/nucleuscare-icon.png" />
          <span
            style={{
              color: "#fff",
              width: 300,
              textAlign: "center",
              fontWeight: "700",
              marginTop: 75,
              fontSize: 50,
            }}
          >
            New version available !
          </span>
          <a
            href="#"
            className=""
            style={{
              marginTop: 10,
              // padding : 10,
              paddingTop: 20,
              paddingBottom: 20,
              fontSize: 30,
              paddingLeft: 40,
              paddingRight: 40,
              color: "#fff",
              fontWeight: "700",
              borderRadius: 5,
              backgroundColor: "#0A313F",
              width: "32%",
              textAlign: "center",
            }}
            onClick={updateAction}
          >
            UPDATE VERSION
          </a>
        </ContentContainer>
        <div
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <DownloadImage />
          {/* Spacing */}
          <SpacingComponent />
          <span
            style={{
              flex: 1,
              color: "#fff",
              textAlign: "center",
              fontSize: "1.6em",
              whiteSpace: "nowrap",
            }}
          >
            Thoughtful Telehealth Company
          </span>
          <NucleusLogoImage />
        </div>
      </BlueContainer>
    </Container>
  );
};

export default UpdateRequired;
