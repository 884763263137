import React, { useCallback, useEffect, useRef, useState } from "react";
import MDMNavTabs from "../components/MDMNavTabs";
import NucleusCard from "../../../components/NucleusCard";
import MdmAccountDevices from "./components/MdmAccountDevices";
import MdmAccountPolicies from "./components/MdmAccountPolicies";
import MdmAccountDetailsNavTabs from "./components/MdmAccountDetailsNavTabs";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import {
  NucleusControlledSelect,
  NucleusControlledWhiteBgInput,
} from "./../../../components/NucleusControlledInput";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMdmAccountByID,
  fetchMdmAccountDevices,
  patchMdmAccount,
  resetPatchAccount,
  handleRenewEnrollmentToken,
  resetFetchAccountInformationByID,
  resetHandleCreateOrRenewEnrollmentTokenState,
  fetchEnterprisesValues,
} from '@nucleus-care/nucleuscare-backend-client';
import QRCode from "qrcode.react";
import NucleusButtonV2 from "../../../components/NucleusButtonV2";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Message from "../../../utils/Message";
import { SpinnerCircular } from "spinners-react";

const NoQrCodeComponent = ({ loading }) => {
  return (
    <div
      style={{
        backgroundColor: "#c9c7c7",
        color: "white",
        justifyContent: "center",
        width: 280,
        height: 280,
        display: "flex",
        borderRadius: 20,
      }}
    >
      <p
        style={{
          alignSelf: "center",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 18,
          width: "60%",
        }}
      >
        {loading ? (
          <SpinnerCircular
            color="#2096F3"
            secondaryColor="rgba(0,0,0,0.16)"
            size="50"
            thickness="100"
          />
        ) : (
          "Please, assign a policy to the account to have a QR code display"
        )}
      </p>
    </div>
  );
};
const MDMAccountDetails = () => {
  const history = useHistory();
  const provisioningWifiPasswordKey =
    "android.app.extra.PROVISIONING_WIFI_PASSWORD";
  const provisioningWifiSSIDKey = "android.app.extra.PROVISIONING_WIFI_SSID";
  const provisioningWifiSecurityKey =
    "android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE";
  const route = useRouteMatch();
  const dispatch = useDispatch();
  const {
    fetchAccountInformation,
    patchMdmAccount: patchMdmAccountState,
    handleRenewEnrollmentTokenState,
    assignPolicyTemplatesToMdmAccount: assignPolicyTemplatesToMdmAccountState,
    fetchMdmAccountDevices: fetchMdmAccountDevicesState,
  } = useSelector(({ mdmAccounts }) => mdmAccounts.data);
  const {
    enterprisesOptions,
    loadingEnterprises,
    // successEnterprises,
    // errorEnterprises,
  } = useSelector(({ mdmPolicy }) => mdmPolicy.data);

  const { loading, success, error, mdmAccount } = fetchAccountInformation;
  const {
    loading: loadingDevices,
    success: successGettingDevices,
    error: errorGettingDevices,
    devices,
  } = fetchMdmAccountDevicesState;
  const {
    loading: loadingEnrollmentRenew,
    success: successEnrollmentRenew,
    error: errorEnrollmentRenew,
  } = handleRenewEnrollmentTokenState;
  const {
    loading: loadingPatch,
    success: successPatch,
    error: errorPatch,
    patchMdmAccountID,
  } = patchMdmAccountState;
  const mdmAccountIdParam = route?.params?.mdmAccountId;
  const [mdmAccountId, setMdmAccountId] = useState(mdmAccountIdParam);
  const [CurrentMDMAccountName, setCurrentMDMAccountName] = useState("");
  const [passphraseRequired, setPassphraseRequired] = useState(false);
  const [enterpriseEditable, setEnterpriseEditable] = useState(false);
  const [enrollmentTokenObject, setEnrollmentTokenObject] = useState(null);
  const [enrollmentQrCodeObject, setEnrollmentQrCodeObject] = useState(null);
  const [showWifiPassword, setShowWifiPassword] = useState(null);
  const [
    enrollmentQrCodeStringified,
    setEnrollmentQrCodeStringified,
  ] = useState(null);
  const [selectedWifi, setSelectedWifi] = useState(false);
  const [activeTab, setActiveTab] = useState("devices");
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    reset,
    watch,
  } = useForm();
  const [showSpinner, setShowSpinner] = useState(false);
  const [noQrCodeData, setNoQrCodeData] = useState(true);
  const timeoutRef = useRef(null);
  useEffect(() => {
    console.log("mdmAccountId", mdmAccountIdParam);
    if (!mdmAccountIdParam) return;
    dispatch(fetchMdmAccountByID(mdmAccountIdParam));
    dispatch(fetchMdmAccountDevices(mdmAccountIdParam)); // WE DON'T REALLY NEED THIS
  }, []);

  useEffect(() => {
    if (
      !loading &&
      (successEnrollmentRenew ||
        assignPolicyTemplatesToMdmAccountState?.success === true)
    ) {
      timeoutRef.current = setTimeout(() => {
        dispatch(fetchMdmAccountByID(mdmAccountId));
        dispatch(resetHandleCreateOrRenewEnrollmentTokenState());
        setShowSpinner(false);
      }, 7000);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [successEnrollmentRenew, assignPolicyTemplatesToMdmAccountState]);

  useEffect(() => {
    setShowSpinner(loadingEnrollmentRenew || showSpinner);
  }, [loadingEnrollmentRenew, mdmAccountId]);

  const handleWifiChange = (e) => {
    if (noQrCodeData) return;
    console.log("e.target.value", e.target.value);
    setSelectedWifi(e.target.value === "true");
    if(e.target.value === "false") {
      handleAccountDetailsSubmit({data: {Passphrase : "", SSID: "", security: ""}})
    }
  };

  useEffect(() => {
    setShowSpinner(loading);
    if (loading) return;
    if (success || mdmAccount) {
      if (!watch("Name") || watch("Name") === "") {
        setValue("Name", mdmAccount?.Name);
        setCurrentMDMAccountName(mdmAccount?.Name);
      }
      if (!watch("EnterpriseName") || watch("EnterpriseName") === "") {
        setValue("EnterpriseName", mdmAccount?.EnterpriseName);
        setValue("EnterpriseID", mdmAccount?.EnterpriseID);
      }
    }
  }, [loading, success, error, mdmAccount]);

  useEffect(() => {
    const securityValue = watch("security");
    console.log("Security", watch("security"));
    if (securityValue == "NONE" || !securityValue) {
      console.log(watch("security"), false);
      setPassphraseRequired(false);
    } else {
      console.log(watch("security"), true);
      setPassphraseRequired(true);
    }
  }, [watch("security")]);
  
  useEffect(() => {
    if (loadingDevices) {
      setEnterpriseEditable(false);
      return;
    }
    if (successGettingDevices) {
      if (devices.length > 0) {
        setEnterpriseEditable(false);
        return;
      }
      if (devices.length <= 0) {
        setEnterpriseEditable(true);
        dispatch(fetchEnterprisesValues());
        return;
      }
    }
    if (errorGettingDevices) {
      setEnterpriseEditable(false);
      return;
    }
    return () => setEnterpriseEditable(false);
  }, [loadingDevices, successGettingDevices, errorGettingDevices]);

  useEffect(() => {
    setNoQrCodeData(
      !(
        !!enrollmentQrCodeStringified &&
        !!enrollmentTokenObject?.expirationTimestamp &&
        !showSpinner
      )
    );
  }, [enrollmentQrCodeStringified, showSpinner]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
  const getTabContent = useCallback(() => {
    switch (activeTab) {
      case 'policies':
        return <MdmAccountPolicies mdmAccountId={mdmAccountId} />;
      case 'devices':
        return <MdmAccountDevices mdmAccountId={mdmAccountId} />;
    }
  }, [mdmAccountId, activeTab]);

  useEffect(() => {
    const networkJson = mdmAccount?.EnrollmentToken;
    if (networkJson) {
      // console.log("networkJson", networkJson);
      const enrollmentTokenParsed = JSON.parse(networkJson);
      setEnrollmentTokenObject(enrollmentTokenParsed);
      const qrCodeObject = JSON.parse(enrollmentTokenParsed?.qrCode);
      setEnrollmentQrCodeObject(qrCodeObject);
      setEnrollmentQrCodeStringified(JSON.stringify(qrCodeObject));
      // console.log("qrCodeObject", qrCodeObject);
      if ([provisioningWifiSSIDKey] in qrCodeObject) {
        setValue("SSID", qrCodeObject[provisioningWifiSSIDKey]);
        if (
          qrCodeObject[provisioningWifiSSIDKey] &&
          qrCodeObject[provisioningWifiSSIDKey] !== ""
        ) {
          setSelectedWifi(true);
        }
      }
      if ([provisioningWifiSecurityKey] in qrCodeObject) {
        setValue("security", qrCodeObject[provisioningWifiSecurityKey]);
      }
      if ([provisioningWifiPasswordKey] in qrCodeObject) {
        setValue("Passphrase", qrCodeObject[provisioningWifiPasswordKey]);
      }
    }
  }, [mdmAccount]);
  // reset the state when leaving the page
  useEffect(() => {
    return () => {
      reset();
      dispatch(resetFetchAccountInformationByID());
      setMdmAccountId("");
      setPassphraseRequired(false);
      setEnrollmentTokenObject(null);
      setEnrollmentQrCodeObject(null);
      setShowWifiPassword(null);
    };
  }, []);
  const wifiSecurityTypes = [
    { label: "NONE", value: "NONE" },
    { label: "WPA", value: "WPA" },
    { label: "WEP", value: "WEP" },
    { label: "EAP", value: "EAP" },
  ];

  const handleAccountDetailsSubmit = (data) => {
    const { Passphrase, SSID, security } = data;
    const updatedQrCodeObject = { ...enrollmentQrCodeObject };
    const updatedEnrollmentTokenObject = { ...enrollmentTokenObject };

    updatedQrCodeObject[provisioningWifiPasswordKey] = Passphrase;
    updatedQrCodeObject[provisioningWifiSecurityKey] = security;
    updatedQrCodeObject[provisioningWifiSSIDKey] = SSID;

    const updatedStringifiedQrCode = JSON.stringify(updatedQrCodeObject);
    updatedEnrollmentTokenObject.qrCode = updatedStringifiedQrCode;

    setEnrollmentQrCodeObject(updatedQrCodeObject);
    setEnrollmentQrCodeStringified(updatedStringifiedQrCode);
    saveMdmAccountDetails(
      "EnrollmentToken",
      JSON.stringify(updatedEnrollmentTokenObject)
    );
    console.log("MDM Account Details", data);
  };
  const labelStyles = {
    width: "100%",
    color: "#0A313F",
    fontWeight: "bold",
    fontSize: 18,
  };

  useEffect(() => {
    setShowSpinner(loadingPatch);
    if (errorPatch) {
      Message.error("An error has occurred while updating the data");
    }
    if (successPatch) {
      Message.show("Field updated successfully");
    }
    dispatch(resetPatchAccount());
  }, [loadingPatch, errorPatch, successPatch]);

  const saveMdmAccountDetails = (field, value) => {
    const dataToPatch = { [field]: value };
    dispatch(resetPatchAccount());
    dispatch(
      patchMdmAccount({
        mdmAccountId: mdmAccount.ID,
        mdmAccountData: dataToPatch,
      })
    );
  };
  const onClickRenewEnrollmentToken = useCallback(() => {
    console.log("onClickRenewEnrollmentToken");
    setShowSpinner(true);
    dispatch(handleRenewEnrollmentToken(mdmAccountId));
  }, [mdmAccountId]);
  return (
    <div class="nucleus-tools-container">
      <div>
        <p class="nucleus-tools-page-title">
          <div className="row a-bit-lower left-align">
            <MDMNavTabs activeTab="accounts" type="main" />
            <div class="nucleus-news-separator">&nbsp;</div>
            <br />
            <b>Account: {CurrentMDMAccountName}</b>
            <div class="nucleus-news-separator">&nbsp;</div>
          </div>
        </p>
        <br />
      </div>
      <div class="">
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: 40,
          }}
        >
          <NucleusCard width={"30%"}>
            <div style={{ flexDirection: "column", padding: "10%" }}>
              <form>
                <NucleusControlledWhiteBgInput
                  disabled={loading || loadingEnrollmentRenew || loadingPatch}
                  saveHandler={() => {
                    const accountNameValue = watch("Name");
                    saveMdmAccountDetails("Name", accountNameValue);
                  }}
                  label={"Name"}
                  name={"Name"}
                  register={register("Name")}
                  error={errors.Name}
                  clearErrors={clearErrors}
                  setValue={() => setValue("Name")}
                  containerStyle={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                />
                <br />
                <NucleusControlledWhiteBgInput
                  goToAction={
                    mdmAccount?.CareAccountID
                      ? () =>
                          history.push(
                            `/accountDetails/${mdmAccount?.CareAccountID}`
                          )
                      : false
                  }
                  disabled={true}
                  editable={false}
                  label={"Nucleus Account Name"}
                  name={"CareAccountName"}
                  value={mdmAccount?.CareAccountName || "Empty"}
                  containerStyle={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                />
                <br />
                {enterpriseEditable ? (
                  <React.Fragment>
                    <label
                      disabled={showSpinner}
                      style={{
                        minWidth: 130,
                        textAlign: "start",
                        fontSize: 16,
                        color: "#0A313F",
                      }}
                      htmlFor={"security"}
                      id={"security"}
                    >
                      Enterprise*
                    </label>

                    <NucleusControlledSelect
                      disabled={
                        showSpinner || loadingDevices || loadingEnterprises
                      }
                      noLabel
                      label={"Enterprise"}
                      register={register("EnterpriseID", {
                        required: enterpriseEditable
                          ? "Enterprise is required"
                          : false,
                      })}
                      error={errors.EnterpriseID}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      name={"EnterpriseID"}
                      options={enterprisesOptions}
                      loadingOptions={loadingEnterprises}
                      value={watch("EnterpriseID")}
                      valueAccessor={"ID"}
                      labelAccessor={"OrganizationName"}
                      saveHandler={() => {
                        const accountEnterpriseIDValue = watch("EnterpriseID");
                        saveMdmAccountDetails(
                          "EnterpriseID",
                          accountEnterpriseIDValue
                        );
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <NucleusControlledWhiteBgInput
                    disabled={true}
                    label={"Enterprise*"}
                    name={"EnterpriseName"}
                    register={register("EnterpriseName")}
                    error={errors.EnterpriseName}
                    clearErrors={clearErrors}
                    setValue={() => setValue("EnterpriseName")}
                    containerStyle={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  />
                )}
              </form>
              <br />
              <br />
              <label className="nucleusCardFooterTxt">
                *can only be changed if there are no devices in the account
              </label>
            </div>
          </NucleusCard>
          <NucleusCard width={"60%"}>
            <div
              style={{
                display: "flex",
                width: "50%",
                flexDirection: "column",
                padding: "3%",
              }}
            >
              <label
                style={{ fontSize: 20, color: "#0A313F", fontWeight: "bold" }}
              >
                WiFi Settings
              </label>
              <input
                disabled={noQrCodeData}
                type="radio"
                name="selectedWifiFalse"
                id="selectedWifiFalse"
                value="false"
                checked={!selectedWifi}
                onChange={handleWifiChange}
                className="with-gap"
              />
              <label
                htmlFor="selectedWifiFalse"
                id="selectedWifiFalse"
                style={{ color: "#0A313F" }}
              >
                <span>No Wifi network configuration</span>
              </label>
              <input
                disabled={noQrCodeData}
                type="radio"
                name="selectedWifi"
                id="selectedWifi"
                value="true"
                checked={selectedWifi}
                onChange={handleWifiChange}
                className="with-gap"
              />
              <label
                htmlFor="selectedWifi"
                id="selectedWifi"
                style={{ color: "#0A313F" }}
              >
                <span>Add Wifi network configuration to QR code</span>
              </label>
              <br />
              {selectedWifi && (
                <form onSubmit={handleSubmit(handleAccountDetailsSubmit)}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <NucleusControlledWhiteBgInput
                      disabled={showSpinner}
                      mainContainerStyle={{ width: "100%" }}
                      labelStyle={labelStyles}
                      label={"SSID"}
                      name={"SSID"}
                      register={register("SSID", {
                        required: "SSID is required",
                      })}
                      error={errors.SSID}
                      clearErrors={clearErrors}
                      setValue={() => setValue("SSID")}
                      containerStyle={{
                        flexDirection: "column",
                      }}
                    />
                    {/* <SaveButton /> */}
                  </div>
                  <label
                    disabled={showSpinner}
                    style={{
                      minWidth: 130,
                      textAlign: "start",
                      fontSize: 16,
                      color: "#0A313F",
                      opacity: showSpinner ? 0.4 : 1,
                      ...labelStyles,
                    }}
                    htmlFor={"security"}
                    id={"security"}
                  >
                    Security Type
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <NucleusControlledSelect
                      disabled={showSpinner}
                      mainContainerStyle={{
                        height: 30,
                      }}
                      noLabel
                      label={"Security Type"}
                      name={"security"}
                      register={register("security", {
                        required: "Security type is required",
                      })}
                      options={wifiSecurityTypes}
                      error={errors.security}
                      clearErrors={clearErrors}
                      setValue={setValue}
                    />
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <NucleusControlledWhiteBgInput
                      showPasswordAction={() =>
                        setShowWifiPassword((prev) => !prev)
                      }
                      mainContainerStyle={{
                        width: "100%",
                      }}
                      type={showWifiPassword ? "text" : "password"}
                      labelStyle={labelStyles}
                      disabled={!passphraseRequired || showSpinner}
                      label={"Passphrase:"}
                      name={"Passphrase"}
                      editable={!passphraseRequired || showSpinner}
                      register={register("Passphrase", {
                        required: passphraseRequired
                          ? "Passphrase is required"
                          : false,
                      })}
                      error={errors.Passphrase}
                      clearErrors={clearErrors}
                      setValue={() => setValue("Passphrase")}
                      inputStyle={{
                        fontWeight: showWifiPassword ? "" : "bold",
                      }}
                      containerStyle={{
                        opacity: passphraseRequired ? 1 : 0.4,
                        flexDirection: "column",
                      }}
                    />
                  </div>
                  {/* <SaveButton /> */}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 15,
                    }}
                  >
                    <NucleusButtonV2
                      extraStyle={{
                        textAlign: "center",
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        opacity: showSpinner ? 0.4 : 1,
                      }}
                      styleType="clear"
                      onClick={handleSubmit}
                      disabled={showSpinner}
                    >
                      <span style={{textAlign:'center'}}>Save changes</span>
                    </NucleusButtonV2>
                  </div>
                </form>
              )}
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                flexDirection: "column",
                padding: "3%",
                alignItems: "center",
              }}
            >
              <label
                style={{ fontSize: 20, color: "#0A313F", fontWeight: "bold" }}
              >
                QR Code
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!noQrCodeData ? (
                  <QRCode size={280} value={enrollmentQrCodeStringified} />
                ) : (
                  <NoQrCodeComponent loading={showSpinner} />
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontSize: "14px",
                    }}
                  >
                    {/* {!!enrollmentTokenObject?.expirationTimestamp &&
                    !showSpinner
                      ? `Expires On: ${moment(
                          enrollmentTokenObject?.expirationTimestamp
                        ).format("MM/DD/YYYY hh:mm A")}`
                      : ""} */}
                    {!noQrCodeData
                      ? `Expires On: ${moment(
                          enrollmentTokenObject?.expirationTimestamp
                        ).format("MM/DD/YYYY hh:mm A")}`
                      : ""}
                  </p>
                </div>
                <div style={{ marginLeft: 5 }}>
                  {!noQrCodeData && (
                    <button
                      onClick={onClickRenewEnrollmentToken}
                      disabled={showSpinner}
                      style={{
                        backgroundColor: "transparent",
                        border: "2px solid",
                        fontWeight: "bold",
                        color: "#0092FF",
                        opacity: showSpinner ? 0.5 : 1,
                        paddingLeft: 25,
                        textAlign: "center",
                        paddingRight: 25,
                        borderRadius: 15,
                        paddingTop: 2,
                        paddingBottom: 2,
                        minWidth: 90,
                      }}
                    >
                      {"Renew"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </NucleusCard>
        </div>
        <MdmAccountDetailsNavTabs
          handleTabChange={handleTabChange}
          activeTab={activeTab}
        />
        {getTabContent()}
      </div>
    </div>
  );
};

export default MDMAccountDetails;
