import React from "react";
import classNames from "classnames";

const NucleusLabel = ({
  label,
  htmlFor,
  name,
  id,
  value,
  children,
  className,
  containerClassName,
  activeBadge,
  labelMinWidth,
}) => {
  return (
    <div
      style={{
        minWidth: labelMinWidth ? labelMinWidth : "220px",
      }}
    >
      <label
        style={{ color: "#000", fontSize: "1rem" }}
        htmlFor={htmlFor || name}
        className={classNames(`${className} ${value ? "active" : ""}`)}
      >
        {children || label}
      </label>
    </div>
  );
};

export default NucleusLabel;
