import React from 'react'
import styled from 'styled-components';
type ToastVariant = 'error' | 'info' | 'success' | 'warning';
type ToastVariantColors = {
    bgColor: string;
    color: string;
};

const ToastContainer = styled.div<{ show: boolean, x: number, y: number, variantValues: ToastVariantColors }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.variantValues.bgColor};
    color: ${props => props.variantValues.color};
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    z-index: 9999;
    top: ${props => props.y}px;
    left: ${props => props.x}px;
    visibility: ${props => (props.show ? 'visible' : 'hidden')};
    display: ${props => (props.show ? 'flex' : 'none')};
    opacity: ${props => (props.show ? 1 : 0)};
    transition: opacity 0.3s ease;
    pointer-events: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
`;

const ToastText = styled.span<{ variantValues: ToastVariantColors }>`
    font-family: 'Barlow';
    font-style: normal;
    font-size: 14px;
    line-height: 23px;
    color: ${props => props.variantValues.color};
`;

type ToastInfoProps = {
    message: string;
    show: boolean;
    x: number;
    y: number;
    variant?: ToastVariant;
    style?: React.CSSProperties;
};

const ToastInfo = ({ message, show, x, y, variant = "info", style }: ToastInfoProps) => {
    const colors: Record<ToastVariant, ToastVariantColors>
        = {
        'error': { bgColor: '#f8d7da', color: '#4c141a' },
        'info': { bgColor: '#cce5ff', color: '#002d5c' },
        'success': { bgColor: '#d4edda', color: '#0e3e18' },
        'warning': { bgColor: '#fff3cd', color: '#5a3a02' },
    };

    return (
        <ToastContainer variantValues={colors[variant]} show={show} x={x + 12} y={y -55} style={style}>
            <ToastText variantValues={colors[variant]}>{message}</ToastText>
        </ToastContainer>
    );
};

type HoverToastProps = {
    showToast: boolean;
    coords: { x: number, y: number };
    message: string;
    handleMouseEnter: (e: React.MouseEvent<HTMLSpanElement>) => void;
    handleMouseMove: (e: React.MouseEvent<HTMLSpanElement>) => void;
    handleMouseLeave: () => void;
    children: React.ReactNode;
    variant?: ToastVariant;
    toastInfoStyle?: React.CSSProperties;
};

const HoverToast = ({
    showToast,
    coords,
    message,
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    toastInfoStyle,
    variant,
    children
}: HoverToastProps) => {
    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <span
                onMouseEnter={handleMouseEnter}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                style={{ cursor: 'pointer' }}
            >
                {children}
            </span>
            <ToastInfo variant={variant} message={message} show={showToast} x={coords.x} y={coords.y} style={toastInfoStyle} />
        </div>
    )
}

export default HoverToast