import React, { useEffect, useState } from "react";
import NucleusInput from "../NucleusInput";
import { useDispatch, useSelector } from "react-redux";
import {
  addPolicyInformationProperties,
  addPolicyJSONProperties,
  removePolicyInformationProperty,
  removePolicyJSONProperty,
} from '@nucleus-care/nucleuscare-backend-client';

const MDMNucleusInput = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  error,
  onFocus,
  activeBadge,
  hideActiveBadge,
  onClickClear,
  setPropertyValue,
  fromPolicyInformation,
  disabled,
  style,
  dataAccessor,
  required,
  hideClear,
  defaultValue,
  ...props
}) => {
  const { policyJSON, policyInformation, policyFormStatus } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.state
  );
  const dispatch = useDispatch();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    if (
      defaultValue &&
      !value &&
      [dataAccessor || name] in policyJSON === false
    ) {
      setInputValue(defaultValue);
      // dispatch(
      //   addPolicyJSONProperties({
      //     [dataAccessor || name]: defaultValue || "",
      //   })
      // );
    }
    if (value) {
      return setInputValue(value);
    }
    if (fromPolicyInformation) {
      if ([dataAccessor || name] in policyInformation) {
        setInputValue(policyInformation[dataAccessor || name]);
      }
      return;
    }
    if ([dataAccessor || name] in policyJSON)
      setInputValue(policyJSON[dataAccessor || name]);
  }, [policyJSON, policyInformation, value]);
  const onFocusAction = () => {
    if (onFocus) return onFocus();
    setIsInputFocused(true);
  };
  return (
    <NucleusInput
      style={style}
      disabled={disabled || !policyFormStatus.isEditable}
      onFocus={() => {
        if (fromPolicyInformation) {
          dispatch(
            addPolicyInformationProperties({
              [dataAccessor || name]: inputValue || "",
            })
          );
        } else {
          dispatch(
            addPolicyJSONProperties({
              [dataAccessor || name]: inputValue || "",
            })
          );
        }
      }}
      hideClear={
        hideClear
          ? hideClear
          : !([dataAccessor || name] in policyJSON) ||
            (fromPolicyInformation &&
              !([dataAccessor || name] in policyInformation)) ||
            disabled
      }
      onClickClear={() => {
        if (fromPolicyInformation) {
          removePolicyInformationProperty(name);
          setInputValue("");
          return;
        }
        setInputValue("");
        dispatch(removePolicyJSONProperty(name));
      }}
      onChange={(e) => {
        if (setPropertyValue) {
          setInputValue(e.target.value);
          setPropertyValue(e.target.value);
          return;
        }
        if (fromPolicyInformation) {
          setInputValue(e.target.value);
          dispatch(
            addPolicyInformationProperties({
              [dataAccessor || name]: e.target.value,
            })
          );
          return;
        }
        setInputValue(e.target.value);
        dispatch(
          addPolicyJSONProperties({ [dataAccessor || name]: e.target.value })
        );
      }}
      value={inputValue || defaultValue}
      activeBadge={
        hideActiveBadge
          ? false
          : [dataAccessor || name] in policyJSON ||
            [dataAccessor || name] in policyInformation
      }
      label={label}
      placeholder={placeholder}
      required={required}
      defaultValue={defaultValue}
    />
  );
};

export default MDMNucleusInput;
