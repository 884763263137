import React from 'react';

const CreateMDMYellowMessage = () => {
  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '15%',
        minHeight: 260,
        top: 40,
        left: 20,
      }}
    >
      <div style={{ marginTop: 25 }}>
        <img
          src="img/yellow-arrow.svg"
          style={{ width: '50%', height: '50%' }}
        />
      </div>
      {/* <div
        style={{
          display: 'inline-block',
          width: '0',
          height: '0',
          marginTop: 40,
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderTop: '20px solid #FFCA20',
          transform: 'rotate(90deg)',
        }}
      ></div> */}
      <div
        style={{
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
          transition: 'all 0.3s ease-in-out',
          backgroundColor: '#FFCA20',
          padding: 20,
          borderRadius: 20,
          color: '#0A313F',
          fontSize: 18,
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        If you would like to create a Nucleus account, navigate to this tab and
        follow instructions.
        <br />
        <br />
        *Creating a Nucleus account will automatically create an MDM account for
        it.
      </div>
    </div>
  );
};

export default CreateMDMYellowMessage;
