import React, { useMemo } from 'react';

const MdmDeviceDetailsNavTabs = ({ handleTabChange, activeTab }) => {
  const tabs = useMemo(
    () => [
      { id: 'network', label: 'Network Info' },
      { id: 'software', label: 'Software Info' },
      { id: 'hardware', label: 'Hardware Info' },
      { id: 'policies', label: 'Policies' },
      { id: 'applications', label: 'Applications' },
      { id: 'compliance', label: 'Compliance' },
      { id: 'location', label: 'Location' },
    ],
    [],
  );

  return (
    <div className="mDMAccountDetailsNavBarContainer">
      <div className="mDMAccountDetailsNavBarOptionsContainer" style={{ width: '80%' }}>
        {tabs.map((tab) => (
          <span key={tab.id}>
            <a
              onClick={() => handleTabChange(tab.id)}
              className={activeTab === tab.id ? 'mDMAccountDetailsNavBarTxt mDMAccountDetailsNavBarTxtActive' : 'mDMAccountDetailsNavBarTxt'}
            >
              {tab.label}
            </a>
          </span>
        ))}
      </div>
      <div className="nucleus-news-separator">&nbsp;</div>
    </div>
  );
};

export default MdmDeviceDetailsNavTabs;
