import Config from "../Config";
import NucleusNetwork from "../utils/NucleusNetwork";

class FamilyApi {

	constructor(context) {
		this.context = context;
	}

	//<----------------------------------Get User Data-------------------------------->
	GetFamilyDetails (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "FamilyMember.asmx/GetFamilyDetails",
			data : data,
			callback : callback,
		});
	}

}

export default FamilyApi;