import React, {useState} from 'react';
import classNames from "classnames";
import AccountUserPatientProfileHeader from '../components/AccountUserPatientProfileHeader';
import AccountUserPatientProfileDevices from '../components/AccountUserPatientProfileDevices';
import AccountUserPatientProfileActivityAndConfiguration from '../components/AccountUserPatientProfileActivityAndConfiguration';
import { useParams } from 'react-router-dom';

const AccountUserPatientProfile = () => {
    const [editPatientActive, setEditPatientActive] = useState(false);
    let currentSection = classNames({
        hide: false
    });
    let infoVisibility = " ";
    let params = useParams();
    console.log("in main componnent: ", params.patientId)
return (
    <div class="nucleus-hard-container">
            <div class="nucleus-all-scrollable-page">
                <AccountUserPatientProfileHeader 
                    currentSection={currentSection} 
                    patientId={params.patientId} 
                    infoVisibility={infoVisibility} 
                    setEditPatientActive={setEditPatientActive} 
                />
                {
                    (!editPatientActive) && 
                    <AccountUserPatientProfileDevices 
                        patientId={params.patientId} 
                        accountId={params.accountId}
                    />
                }
                <br />
				<br />
                {   
                    (!editPatientActive) && 
                    <AccountUserPatientProfileActivityAndConfiguration 
                        patientId={params.patientId} 
                        accountId={params.accountId} 
                    />
                }
            </div>
    </div>)
};

export default AccountUserPatientProfile;