import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import { AxiosError } from 'axios';

export const AccountApi = {
  getAccountData: async ({ accountId }: { accountId: string }) => {
    try {
      const response = await httpNodeApi.get(`account/${accountId}/get_data`);
      if (response?.data) return { data: response?.data };
      return null;
    } catch (error) {
      let errorMsg = 'An error occurred';
      if (error && (error as AxiosError).isAxiosError) {
        if (error?.response?.data?.message) {
          errorMsg = `Error: ${error.response.data.message}`;
        } else if (error?.message) {
          errorMsg = error.message;
        }
      } else if (error instanceof Error) {
        errorMsg = error.message;
      }
      return { error: errorMsg };
    }
  },
};
