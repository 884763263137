import 'react-toastify/dist/ReactToastify.css';

import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { getToken } from './js/utils/get-token';

import { configureUnauthorizedInterceptor } from './js/utils/interceptor';
import BackendClientLauncher from './js/utils/BackendClientLauncher';
import './css/nucleus_care_customer_support.css';

//import { BrowserRouter as Router } from 'react-router-dom';

import Main from './js/pages/Main';
import Login from './js/pages/Login';
import ForgotPassword from './js/pages/ForgotPassword';
import ResetPassword from './js/pages/ResetPassword';

import NucleuscareBackendClient from '@nucleus-care/nucleuscare-backend-client';
import { Provider } from 'react-redux';
import { PrivateRoute } from './js/components/PrivateRoute';

import { createTheme, ThemeProvider } from '@material-ui/core';

import { useClearCacheCtx } from 'react-clear-cache';
import UpdateRequired from './js/components/UpdateRequired';
import NucleusToastContainer from './js/components/NucleusToastContainer';
import { ContextProvider } from './js/context-api/ContextProvider';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { useEffect } from 'react';

if(process.env.REACT_APP_BUGSNAG_API_KEY){
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    releaseStage: process.env.REACT_APP_ENVIRONMENT,
    plugins: [new BugsnagPluginReact()]
  })
  BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_KEY })
}

// import token from localstorage to libraries
getToken();

// configure 401 interceptor
configureUnauthorizedInterceptor();

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3', //Nucleus Blue
    },
    secondary: {
      main: '#FFCA20', //Nucleus Yellow
    },
  }, //,fontFamily: font // as an aside, highly recommend importing roboto font for Material UI projects! Looks really nice
});

const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  console.warn('isLatestVersion?', isLatestVersion);

  if (isLatestVersion != undefined && !isLatestVersion) {
    return (
      <UpdateRequired
        updateAction={(e) => {
          e.preventDefault();
          emptyCacheStorage();
          window.location.reload(true);
        }}
      ></UpdateRequired>
    );
  }


  return (
    <Provider store={NucleuscareBackendClient}>
      <ContextProvider>
        <ThemeProvider theme={theme}>
          <BackendClientLauncher />
          <Router>
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <Route path="/forgotMyPassword">
                <ForgotPassword />
              </Route>
              <Route path="/resetMyPassword/:email/:token">
                <ResetPassword />
              </Route>
              <PrivateRoute path="/">
                <Main />
              </PrivateRoute>
            </Switch>
          </Router>
          <NucleusToastContainer />
        </ThemeProvider>
      </ContextProvider>
    </Provider>
  );
};
export default App;
