import React, { useCallback, useEffect, useState } from 'react';
import NucleusModal from '../../../../components/NucleusModal';
import NucleusButton from '../../../../components/NucleusButton';
import { useDispatch, useSelector } from 'react-redux';
import NucleusSearchInput from '../../../../components/NucleusSearchInput';
import Message from '../../../../utils/Message';
import NucleusTable from '../../../../components/NucleusTable';
import {
  assignPolicyTemplatesToMdmDevice,
  resetAssignPolicyTemplatesToMdmDeviceState,
  fetchMdmPolicyData,
  fetchMdmDeviceDetails,
} from '@nucleus-care/nucleuscare-backend-client';
import { SpinnerCircular } from 'spinners-react/lib/esm';
import { set } from 'lodash';
import { useForm } from 'react-hook-form';

const MdmDeviceSelectPoliciesModal = ({
  mdmDeviceId,
  loading,
  isOpen,
  setIsOpen,
  devicePolicyTemplates,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (mdmDeviceId) {
      dispatch(fetchMdmPolicyData()); // fetch all the policy templates
      dispatch(fetchMdmDeviceDetails(mdmDeviceId)); // fetch the device details
    }
  }, [mdmDeviceId]);

  // load all the policy templates
  const { Policies: allPolicyTemplates } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.data,
  );

  // load the device details
  const [policyId, setPolicyId] = useState(null); // stores the policy ID for the device
  const [componentLoading, setComponentLoading] = useState(false); // stores the policy ID for the device
  const { fetchDeviceDetails, assignPolicyTemplatesToMdmDeviceState } =
    useSelector(({ mdmDevice }) => mdmDevice.data);
  const { deviceDetails: mdmDeviceDetails } = fetchDeviceDetails; // TODO - This is returning a list of length 1 which should be changed
  useEffect(() => {
    if ('MdmDevicePolicyID' in mdmDeviceDetails) {
      const mdmDevicePolicyId = mdmDeviceDetails.MdmDevicePolicyID;
      setPolicyId(mdmDevicePolicyId);
    }
  }, [mdmDeviceDetails]);
  useEffect(() => {
    if (assignPolicyTemplatesToMdmDeviceState.success) {
      Message.show('Policy Template(s) successfully assigned');
      setCheckedPolicyTemplateIDs([]);
      setPolicyTemplateIDToPriorityDict({});
      setIsOpen(false);
      dispatch(resetAssignPolicyTemplatesToMdmDeviceState());
    }
    if (assignPolicyTemplatesToMdmDeviceState.error) {
      Message.error(
        'Something went wrong. Please try again later, if the error persists, contact support.',
      );
      dispatch(resetAssignPolicyTemplatesToMdmDeviceState());
    }
  }, [assignPolicyTemplatesToMdmDeviceState]);
  useEffect(() => {
    setComponentLoading(
      loading || assignPolicyTemplatesToMdmDeviceState.loading,
    );
  }, [loading, assignPolicyTemplatesToMdmDeviceState]);
  useEffect(() => {
    return () => {
      setSearchValue('');
      setPolicyInModalFocused(null);
      dispatch(resetAssignPolicyTemplatesToMdmDeviceState());
    };
  }, []);

  // to keep track of the checked policy templates
  const [checkedPolicyTemplateIDs, setCheckedPolicyTemplateIDs] = useState([]);
  // to keep track of the priority of the policy templates being added
  const [policyTemplateIDToPriorityDict, setPolicyTemplateIDToPriorityDict] =
    useState({});

  const [PolicyInModalFocused, setPolicyInModalFocused] = useState(null);

  const columns = [
    {
      Header: 'Policy',
      accessor: 'Name',
    },
    {
      Header: 'Version',
      accessor: 'Version',
    },
    {
      Header: 'Priority',
      accessor: 'Priority',
      disableSortBy: true,
      Cell: ({ row }) => {
        // console.log("CellRow", row);
        const { register, handleSubmit, setValue, watch } = useForm();
        const devicePolicyData = devicePolicyTemplates.find(
          (policy) => policy.TemplateID === row.original.ID,
        );
        const isFocused = PolicyInModalFocused === row.original.ID;
        // console.log("devicePolicyData", devicePolicyData);
        const isDisabled = !!devicePolicyData;
        let inputValue = policyTemplateIDToPriorityDict[row.original.ID];
        if (inputValue == undefined) {
          inputValue = devicePolicyData ? devicePolicyData.Priority : '';
        }
        const onSubmit = async (data) => {
          if (isDisabled) return;
          if (isFocused) {
            // console.log("data", data);
            const newPriority = Number(data.priority);
            setPolicyTemplateIDToPriorityDict((prevDict) => ({
              ...prevDict,
              [row.original.ID]: newPriority,
            }));
          }
          setPolicyInModalFocused(null);
        };

        return (
          <span style={{ display: 'flex' }}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}
            >
              <input
                onFocus={() => {
                  setPolicyInModalFocused(row.original.ID);
                }}
                type="number"
                disabled={isDisabled}
                register={register('priority', { value: inputValue })}
                value={watch('priority')}
                style={{
                  width: 55,
                  marginRight: 2,
                  textAlign: 'center',
                }}
                onClick={
                  (e) => e.stopPropagation() // make sure the row click is not triggered
                }
                onChange={(e) => {
                  if (isDisabled) {
                    return;
                  }
                  setValue('priority', e.target.value);
                }}
                onBlur={() => {
                  if (isDisabled) return;
                  if (isFocused) {
                    const newPriority = Number(watch('priority'));
                    setPolicyTemplateIDToPriorityDict((prevDict) => ({
                      ...prevDict,
                      [row.original.ID]: newPriority,
                    }));
                  }
                  setPolicyInModalFocused(null);
                }}
              />
              <div style={{ minWidth: 65, minHeight: 35 }}>
                {isFocused && (
                  <React.Fragment>
                    <img
                      role="button"
                      src={'./img/untick.svg'}
                      alt={'X icon'}
                      onClick={(e) => {
                        e.stopPropagation();
                        setValue('priority', '');
                        setPolicyTemplateIDToPriorityDict((prevDict) => ({
                          ...prevDict,
                          [row.original.ID]: undefined,
                        }));
                        setPolicyInModalFocused(null);
                      }}
                    />
                    <button
                      style={{ backgroundColor: 'transparent', border: 'none' }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      type="submit"
                    >
                      <img
                        role="button"
                        src={'./img/big_check.svg'}
                        alt={'Check icon'}
                      />
                    </button>
                  </React.Fragment>
                )}
              </div>
            </form>
          </span>
        );
      },
    },
    {
      Header: 'Select',
      disableSortBy: true,
      Cell: ({ row }) => {
        const devicePolicyData = devicePolicyTemplates.find(
          (policy) => policy.TemplateID === row.original.ID,
        );
        const isDisabled = !!devicePolicyData;
        // should be checked if the policy is already assigned to the device or the policy was checked by the user
        const isChecked = !!devicePolicyData || checkedPolicyTemplateIDs.includes(row.original.ID);
        return (
          <input
            type="checkbox"
            disabled={!!isDisabled}
            checked={isChecked}
            className="addAppsCheckBox"
          />
        );
      },
    },
  ];

  /**
   * Handles a cancel click in the modal
   *   - Sets checked status for all policies to false
   *   - Sets priority for all policies not in the table to undefined
   */
  const handleCancelClick = () => {
    setIsOpen(false);
    // reset checked policy templates
    setCheckedPolicyTemplateIDs([]);
    // reset policyTemplateIDToPriorityDict
    setPolicyTemplateIDToPriorityDict({});
    dispatch(resetAssignPolicyTemplatesToMdmDeviceState());
  };

  /**
   * Handles a proceed click in the modal
   *   - adds the selected policies to the device
   */
  const handleProceedClick = useCallback(() => {
    // check if no policies are checked
    if (checkedPolicyTemplateIDs.length === 0) {
      Message.error('No policy templates were selected.');
      return;
    }
    // get the max priority from the assigned non-protected policies
    let highestPriority = -1;
    if (devicePolicyTemplates.length > 0) {
      highestPriority = Math.max(
        ...devicePolicyTemplates
          // filter out protected policies
          .filter((policyTemplate) => !policyTemplate.Protected)
          .map((policyTemplate) => policyTemplate.Priority || -1),
      );
    }

    const devicePolicyTemplatesToAssign = checkedPolicyTemplateIDs
      // map the IDs to the policy data
      .map((checkedPolicyTemplateIDs) => {
        // find the policy template data in allPolicyTemplates
        const policyTemplateData = allPolicyTemplates.find(
          (policyTemplate) => policyTemplate.ID === checkedPolicyTemplateIDs,
        );
        return policyTemplateData;
      })
      // sort by name so we have a deterministic way priorities are set
      .sort((a, b) => {
        return a.Name.localeCompare(b.Name);
      })
      .map((checkedDevicePolicyTemplate) => {
        let priorityToSet =
          policyTemplateIDToPriorityDict[checkedDevicePolicyTemplate.ID];
        if (priorityToSet == undefined) {
          priorityToSet = highestPriority + 1;
          highestPriority += 1;
        }

        return {
          PolicyID: policyId,
          PolicyTemplateID: checkedDevicePolicyTemplate.ID,
          Priority: priorityToSet,
        };
      });

    dispatch(
      assignPolicyTemplatesToMdmDevice({
        mdmDeviceId: mdmDeviceId,
        MdmPolicyTemplates: devicePolicyTemplatesToAssign,
      }),
    );
  }, [policyId, checkedPolicyTemplateIDs, policyTemplateIDToPriorityDict]);
  // const handleProceedClick = () => {
  //   // check if no policies are checked
  //   if (checkedPolicyTemplateIDs.length === 0) {
  //     Message.error('No policy templates were selected.');
  //     return;
  //   }

  //   // get the max priority from the assigned non-protected policies
  //   let highestPriority = -1;
  //   if (devicePolicyTemplates.length > 0) {
  //     highestPriority = Math.max(
  //       ...devicePolicyTemplates
  //         // filter out protected policies
  //         .filter((policyTemplate) => !policyTemplate.Protected)
  //         .map((policyTemplate) => policyTemplate.Priority || -1),
  //     );
  //   }

  //   const devicePolicyTemplatesToAssign = checkedPolicyTemplateIDs
  //     // map the IDs to the policy data
  //     .map((checkedPolicyTemplateIDs) => {
  //       // find the policy template data in allPolicyTemplates
  //       const policyTemplateData = allPolicyTemplates.find(
  //         (policyTemplate) => policyTemplate.ID === checkedPolicyTemplateIDs,
  //       );
  //       return policyTemplateData;
  //     })
  //     // sort by name so we have a deterministic way priorities are set
  //     .sort((a, b) => {
  //       return a.Name.localeCompare(b.Name);
  //     })
  //     .map((checkedDevicePolicyTemplate) => {
  //       let priorityToSet =
  //         policyTemplateIDToPriorityDict[checkedDevicePolicyTemplate.ID];
  //       if (priorityToSet == undefined) {
  //         priorityToSet = highestPriority + 1;
  //         highestPriority += 1;
  //       }

  //       return {
  //         PolicyID: policyId,
  //         PolicyTemplateID: checkedDevicePolicyTemplate.ID,
  //         Priority: priorityToSet,
  //       };
  //     });

  //   dispatch(
  //     assignPolicyTemplatesToMdmDevice({
  //       mdmDeviceId: mdmDeviceId,
  //       MdmPolicyTemplates: devicePolicyTemplatesToAssign,
  //     }),
  //   );
  // };

  const [searchValue, setSearchValue] = useState('');
  return (
    <NucleusModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: 400,
          maxHeight: '80vh',
          minWidth: 800,
          padding: 20,
          overflowY: 'clip',
        }}
      >
        <span
          style={{
            alignContent: 'center',
            color: '#0A313F',
            fontSize: 24,
            marginTop: 5,
            marginBottom: 40,
            fontWeight: 'bold',
          }}
        >
          Add a New Policy
        </span>
        <div style={{ display: 'flex', width: '100%' }}>
          <NucleusSearchInput
            placeholder="Search by Policy"
            value={searchValue}
            onChange={(e) => {
              const value = e.target.value || '';
              setSearchValue(value);
            }}
          />
        </div>
        <div
          style={{
            'overflow-y': 'auto',
            height: '400px',
            width: '100%',
          }}
        >
          <NucleusTable
            onRowClick={(e) => {
              const policyTemplateId = e.row.original.ID;

              // if the ID is in devicePolicyTemplates then don't allow check and return
              if (
                devicePolicyTemplates.some(
                  (devicePolicy) =>
                    devicePolicy.TemplateID === policyTemplateId,
                )
              ) {
                return;
              }

              // if the ID is in the checkedPolicyTemplateIDs, remove it, othwerwise add it
              if (checkedPolicyTemplateIDs.includes(policyTemplateId)) {
                setCheckedPolicyTemplateIDs(
                  checkedPolicyTemplateIDs.filter(
                    (id) => id !== policyTemplateId,
                  ),
                );
              } else {
                setCheckedPolicyTemplateIDs([
                  ...checkedPolicyTemplateIDs,
                  policyTemplateId,
                ]);
              }
            }}
            columns={columns}
            data={allPolicyTemplates
              // add a disabled property to each element if it's already assigned so that it gets grayed out
              .map((template) => ({
                ...template,
                disabled: devicePolicyTemplates.some(
                  (devicePolicy) => devicePolicy.TemplateID === template.ID,
                ),
              }))
              .filter((template) => {
                if ('Name' in template && typeof template.Name === 'string') {
                  const nameToLowercase = template.Name.toLowerCase();
                  let searchToLowercase = '';
                  if (searchValue && typeof searchValue === 'string')
                    searchToLowercase = searchValue.toLowerCase();
                  return nameToLowercase.includes(searchToLowercase);
                } else return;
              })
              .sort((a, b) => b.disabled - a.disabled)}
            checkForDisabled={true}
          />
        </div>
        <div style={{ display: 'flex' }}>
          {componentLoading ? (
            <SpinnerCircular
              color="#2096F3"
              secondaryColor="rgba(0,0,0,0.16)"
              size="50"
              thickness="100"
            />
          ) : (
            <React.Fragment>
              <div className="nucleus-mr-1">
                <NucleusButton
                  rounded
                  disabled={componentLoading}
                  label="Cancel"
                  backGroundColor="#FFFFFF"
                  action={handleCancelClick}
                />
              </div>
              <div className="nucleus-ml-1">
                <NucleusButton
                  disabled={componentLoading}
                  label="Proceed"
                  backGroundColor="#0A313F"
                  action={handleProceedClick}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </NucleusModal>
  );
};

export default MdmDeviceSelectPoliciesModal;
