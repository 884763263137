import React, { FC } from "react";
import styled from "styled-components";

export const UIDividerLine = () => {
    return (
        <DividerLine>
        </DividerLine>
    );
};

const DividerLine = styled.div`
    padding: 4px;
    width:100%;
    border-bottom: 1px solid #ccc;
`;