import { useEffect, useState } from "react";
import { httpApi } from "@nucleus-care/nucleuscare-backend-client";

export type IState = {
  ID: string;
  CountryCode?: string;
  CountryName?: string;
  StateCode?: string;
  StateName?: string;
};

export type IUserState = {
  CreatedAt?: string;
  ID?: string;
  StateID: string;
  UserID: string;
};

const useStateSelector = () => {
  const [allStates, setAllStates] = useState<IState[]>([]);
  const [filteredStates, setFilteredStates] = useState<IState[]>([]);
  const [selectedStates, setSelectedStates] = useState<IState[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    filterStates();
  }, [searchTerm]);

  const fetchStates = () => {
    setIsLoading(true);
    httpApi
      .get(
        `/account/states`
      )
      .then(({ data }) => {
        console.log('Fetching states', data);

        const fetchedStates = data.states;
        setAllStates(fetchedStates.filter((fp) => !selectedStates.some((sp) => sp.ID === fp.ID)));
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  const filterStates = () => {
    setFilteredStates(
      allStates.filter((fp) => {
        const isSelected = selectedStates.some((sp) => sp.ID === fp.ID);
        const matchesSearch = fp.StateName?.toLowerCase().includes(searchTerm.toLowerCase());
        return !isSelected && matchesSearch;
      })
    );
  };

  const toggleStateSelection = (stateId: string) => {
    const isCurrentlySelected = selectedStates.some(
      (p) => p.ID === stateId
    );

    if (isCurrentlySelected) {
      setSelectedStates(selectedStates.filter((p) => p.ID !== stateId));
    } else {
      const stateToAdd = allStates.find((p) => p.ID === stateId);
      if (stateToAdd)
        setSelectedStates((prev) => [...prev, stateToAdd]);
    }
  };

  const reset = () => {
    setSelectedStates([]);
  };

  return {
    searchTerm,
    states: filteredStates,
    selectedStates,
    toggleStateSelection,
    reset,
    setSearchTerm,
    isLoading,
  };
};

export default useStateSelector;
