import React from 'react';
import ReactDOM from "react-dom";
import Dispatcher from "../Dispatcher";
import { IndexLink, Link } from "react-router-dom";

import CareAccountStore from "../stores/CareAccountStore";
import Message from "../utils/Message";


//import _ from "underscore";

class MessageCategoryItemRow extends React.Component
{
    constructor(props)
    {
        super();
        this.state = {
            categoryID : null
        };

        this.handleRowClicked = this.handleRowClicked.bind(this);
    }

    componentDidMount()
    {
        //Register Listener

        this.setState({
            categoryID : this.props.ID
        });
        
    }

    componentWillUnmount()
    {
        //Remove Listener
    }


    handleRowClicked(){
        
        CareAccountStore.handleOpenCategoryModal({
            CatID : this.props.ID,
            CatName : this.props.Category,
            CatIcon : this.props.Icon,
            CatSound : this.props.Sound,
            CatTTL : this.props.TTL,
            CatDingRepeat : this.props.DingRepeat 
        });
    }


    render()
    {


        let categoryIcon = this.props.Icon;
        if (categoryIcon != null && categoryIcon.length > 28){
            categoryIcon = categoryIcon.substring(0, 28) + " ...";
        }

        let categorySound = this.props.Sound;
        if (categorySound != null && categorySound.length > 28){
            categorySound = categorySound.substring(0, 28) + " ...";
        }

        return (
            <tr onClick={this.handleRowClicked}>
                <td class="nucleus-row" >{ this.props.Category }</td>
                <td class="nucleus-row" >{ categoryIcon }</td>
                <td class="nucleus-row" >{ categorySound }</td>
                <td class="nucleus-row center-align" >{ this.props.TTL }</td>
                <td class="nucleus-row center-align" >{ this.props.DingRepeat }</td>
            </tr>
              
        );
    }
}

export default MessageCategoryItemRow;
