import React, { useReducer, useContext, createContext, ReactNode } from 'react';
import { IState, IUserState } from '../../pages/providers/components/StateSelector/useStateSelector';

export interface ProviderStringsResponse {
    LocaleTag: string,
    ProviderID: string,
    UserRoleName: string,
    UserRoleNamePlural: string,

    Tier1?: string,
    Tier1Plural?: string,
    Tier2?: string,
    Tier2Plural?: string,
    Tier3?: string,
    Tier3Plural?: string,
}
export interface ProviderAccountGroupingStringsForm {
    Tier1?: string,
    Tier1Plural?: string,
    Tier2?: string,
    Tier2Plural?: string,
    Tier3?: string,
    Tier3Plural?: string,
}

export interface ProviderEscalationConfigResponse {
    ID: string,
    Name: string,
    EscalationPhoneNumber: string|null,
    EscalationEmail: string|null,
    FirstEscalationMinutes: number,
    SecondEscalationMinutes: number
}

export interface ProviderEscalationConfigForm {
    EscalationPhoneNumber: string|null,
    EscalationEmail: string|null,
    FirstEscalationMinutes: number,
    SecondEscalationMinutes: number
}

export interface ProviderCallRequestConfigResponse {
    ID: string,
    Name: string,
    CallRequestRingtone: number
}
export interface ProviderCallRequestConfigForm {
    CallRequestRingtone: number
}


export interface IProviderData {

    AccountCount: number,
    CreatedAt: string,
    DefaultScheduleSlotLengthInMinutes: number,
    Deleted: boolean,
    DevicesCount: number,
    ID: string,
    Logo: string,
    Name: string,
    UsersCount: number,
    EscalationPhoneNumber: string|null,
    EscalationEmail: string|null,
    FirstEscalationMinutes: number, 
    SecondEscalationMinutes: number
}
export interface IProviderListItem {
    ID?: string;
    providerName?: string;
    AccountName: string,
    PatientCount: number;
    DeviceCount: number;
    OnlineDeviceCount: number,
    UsersCount: number
}
export interface IAccountCatalogListItem {
    AccountID: string,
    AccountName: string,
    AlreadyConnected: number,
    DeviceCount: number,
    OnlineDeviceCount: number,
    PatientCount: number,
    UsersCount: number,
    State:string|null
}

export interface IProviderAccount {
    AccountID: string,
    AccountName: string,
    DeviceCount: number,
    OnlineDeviceCount: number,
    PatientCount: number,
    ProviderID: string,
    ProviderName: string,
    UsersCount: number
}
export interface IProviderUser {

    id?: string,
    email: string,
    firstName: string,
    lastName: string,
    name?: string,
    isAdmin: boolean,
    status: "Active" | "Pending" | string,
    telephone?: string,
    address?: string,
    thumbnailUrl?: string,

    isHippa: boolean,
    canAccessStaff: boolean,
    canAccessClients: boolean,
    canAccessQueue: boolean,

    canAccessMessages: boolean,
    canAccessAlerts: boolean,
    canAccessPhotos: boolean,
    canAccessBulletin: boolean
}

export interface INewProviderUser {

    email: string,
    firstName: string,
    lastName: string,
    telephone?: string,
    address?: string,
    states?: IState[],
    statesToRemove?: IState[],

    accountPermissions: {
        isAdmin: boolean,
        isHipaaAuthorized: boolean,
        canAccessStaff: boolean,
        canAccessClients: boolean,
        canAccessQueue: boolean,
        canAccessMessageCenter: boolean,
        canAccessAlerts: boolean,
        canAccessPhotos: boolean,
        canAccessBulletinBoard: boolean,
    }
    providerPermissions: {
        canAccessProvidersSchedule: boolean,
        canAccessTodaysSchedule: boolean,
        canAccessScheduleAvailability: boolean,
        canAccessPatientAppointments: boolean,
        canAccessProvidersQueue: boolean,
    }
}

export interface ProviderFeaturesResponse {
    ProviderID?: string;
    PointClickCareButton: boolean;
    PointClickCareButtonLabel: string;
    PointClickCareButtonPackageName: string;
  }
export interface IEditProviderUser extends INewProviderUser {
    id: string;
}

interface State {
    providers: IProviderListItem[];
    provider: {
        data: IProviderData | null,
        assignedAccounts: IProviderAccount[]
        features: ProviderFeaturesResponse | null,
        state: {
            loading: boolean;
            success: boolean;
            error: boolean;
        }
    };
}

type Action =
    | { type: 'fetch_providers_list', payload: { loading: boolean, success: boolean, error: boolean, providers: IProviderListItem[] } }
    | { type: 'fetch_provider', payload: { loading: boolean, success: boolean, error: boolean, provider: IProviderData | null, features: ProviderFeaturesResponse | null } }
    | { type: 'fetch_provider_accounts', payload: { loading: boolean, success: boolean, error: boolean, assignedAccounts: IProviderAccount[]; } }
    | { type: 'unknown' };

interface NucleusProvidersContextProps {
    state: State;
    dispatch: React.Dispatch<Action>;
}

const NucleusProvidersContext = createContext<NucleusProvidersContextProps | undefined>(undefined);

const nucleusProvidersReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'fetch_providers_list':
            return {
                ...state,
                ...action.payload
            };
        case 'fetch_provider':
            return {
                ...state,
                provider: {
                    ...state.provider,
                    data: action.payload.provider,
                    features: action.payload.features,
                    state: {
                        loading: action.payload.loading,
                        success: action.payload.success,
                        error: action.payload.error,
                    }
                }
            };
        default:
            console.warn('Unknown action type: ', action.type);
            return state;
    }
};

interface NucleusProvidersProviderProps {
    children: ReactNode;
}

export const NucleusProvidersProvider: React.FC<NucleusProvidersProviderProps> = ({ children }) => {
    const initialState: State = {
        providers: [],
        provider: {
            assignedAccounts: [],
            features: {
                PointClickCareButton: false,
                PointClickCareButtonLabel: '',
                PointClickCareButtonPackageName: '',
            },
            data: {
                ID: '',
                Name: '',
                AccountCount: 0,
                CreatedAt: '',
                DefaultScheduleSlotLengthInMinutes: 0,
                Deleted: false,
                DevicesCount: 0,
                Logo: '',
                UsersCount: 0,
                EscalationPhoneNumber: null,
                EscalationEmail: null,
                FirstEscalationMinutes: 0, 
                SecondEscalationMinutes: 0
            },
            state: {
                loading: false,
                success: false,
                error: false,
            }
        }
    };
    const [state, dispatch] = useReducer(nucleusProvidersReducer, initialState);

    const contextValue = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return (
        <NucleusProvidersContext.Provider value={contextValue}>
            {children}
        </NucleusProvidersContext.Provider>
    );
};

export const useNucleusProviders = (): NucleusProvidersContextProps => {
    const context = useContext(NucleusProvidersContext);
    if (context === undefined) {
        throw new Error('useNucleusProviders must be used within a NucleusProvidersProvider');
    }
    return context;
};
