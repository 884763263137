import NucleusBaseContainer from "../Base"

type Props = {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

export const UINucleusContainer = (props: Props) => {
    const containerStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        border: '1px none orange',
        display: 'inline-block',
        paddingLeft: '30px',
        paddingRight: '10px',
        minHeight: '88% !important',
        marginBottom: '10px !important',
        position: 'relative',
        overflow: 'auto',
        overflowY: 'scroll',
        overflowX: 'scroll',
        ...props.style
    };

    return <NucleusBaseContainer style={containerStyle} {...props} />
}