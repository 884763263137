import Config from "../Config";
import { getAccountDevicesPromise } from '@nucleus-care/nucleuscare-backend-client';
import NucleusNetwork from "../utils/NucleusNetwork";

import { httpApi as httpNodeApi} from '@nucleus-care/nucleuscare-backend-client';
import api from '../utils/httpLegacyClient'

class CareAccountApi {

	constructor(context, authClient) {
		this.context = context;
    	this.authClient = authClient
	}

	//< Get All Care Accounts ------------------------------------->
	GetAllAccounts (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCs.asmx/GetAccounts",
			data : data,
			callback : callback,
		});
	}

	GetAccount (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCs.asmx/GetAccountInformation",
			data : data,
			callback : callback,
		});
	}

	//< Get All Care Accounts ------------------------------------->
	CreateNewAccount (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/AddAccount",
			data : data,
			callback : callback,
		});
	}

	//< DELETE ACCOUNT ------------------------------------->
	DeleteAccount (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/DeleteAccount",
			data : data,
			callback : callback,
		});
	}

	GetAccountGroupsData (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCs.asmx/GetAccountGroups",
			data : data,
			callback : callback,
		});
	}

	GetUpdateGroupsData (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCs.asmx/GetUpdateGroups",
			data : data,
			callback : callback,
		});
	}

	GetAccountsCountriesData(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCs.asmx/GetAccountsCountries",
			data : data,
			callback : callback,
		});
	}

	GetLocalesData (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCs.asmx/GetLocales",
			data : data,
			callback : callback,
		});
	}

	GetAccountAlerts(data, callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareCs.asmx/GetAccountAlerts",
			data : data,
			callback : callback,
		});
	}

	GetTemplatesData(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareDevice.asmx/GetDeviceTemplates",
			data : data,
			callback : callback,
		});
	}

	UpdateAccountName (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateAccountName",
			data : data,
			callback : callback,
		});
	}

	UnblockApi (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UnblockApi",
			data : data,
			callback : callback,
		})
	}

	UpdateApiToken (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateApiToken",
			data : data,
			callback : callback,
		})
	}

	UpdateAccountLogo (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateLogo",
			data : data,
			callback : callback,
		});
	}

	// UpdateAccountLogo (data,callback) {
	// 	NucleusNetwork.requestWithFile({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateLogo",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// = = = = = = = = = = = = = = = = = = = = = = =
	//
	// = = = = = = = = = = = = = = = = = = = = = = =
	UpdateAccountConfigurationFlag(data, endpoint, callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + endpoint,
			data : data,
			callback : callback,
		});
	}

	UpdateUpdatesGroup(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateUpdateGroupID",
			data : data,
			callback : callback,
		});
	}

	UpdateAccountCountry(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateCountryCode",
			data : data,
			callback : callback,
		});
	}

	UpdateZipCode(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateZipCode",
			data : data,
			callback : callback,
		});
	}

	UpdateAccountLocale(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateLocale",
			data : data,
			callback : callback,
		});
	}


	UpdateShowCallButton (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateShowCallButton",
			data : data,
			callback : callback,
		});
	}

	UpdateShowCallButton2(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateShowCallButton2",
			data : data,
			callback : callback,
		});
	}

	UpdateShowCheckInButton (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateShowCheckInButton",
			data : data,
			callback : callback,
		});
	}

	UpdateShowEmergencyButton (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateShowEmergencyButton",
			data : data,
			callback : callback,
		});
	}

	UpdateRingOnRequest (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateRingOnRequest",
			data : data,
			callback : callback,
		});
	}

	UpdateEnableBiometrics(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableBiometrics",
			data : data,
			callback : callback,
		});
	}


	UpdateEnableBluetoothButtons(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableBluetoothButtons",
			data : data,
			callback : callback,
		});
	}

	UpdateShowCallControls(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateShowCallButtonsBar",
			data : data,
			callback : callback,
		});
	}

	UpdateShowVideoStats(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateShowVideoStats",
			data : data,
			callback : callback,
		});
	}

	UpdateShowFamilyCallBtn(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateShowFamilyCallButton",
			data : data,
			callback : callback,
		});
	}

	UpdateEnableAutoAnswer(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableAutoAnswer",
			data : data,
			callback : callback,
		});
	}

	UpdateEnableFamilyAutoAnswer(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableAutoAnswerForFamily",
			data : data,
			callback : callback,
		});
	}

	UpdateEnableAutoAnswerForFamilyApp(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableAutoAnswerForFamilyApp",
			data : data,
			callback : callback,
		});
	}

	UpdateMobileCallResponse(data,callback){
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateMobileCallResponse",
			data : data,
			callback : callback,
		})
	}

	UpdateForceLandscapeVideo(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateForceLandscapeVideo",
			data : data,
			callback : callback,
		});
	}

	UpdateRingIncomingCall(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableRingOnIncomingCall",
			data : data,
			callback : callback,
		});
	}

	UpdateVoiceMessage(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableVoiceMessage",
			data : data,
			callback : callback,
		});
	}

	// DEPRECATED on March 1st 2023, https://nucleusintercom.atlassian.net/browse/CARE-10228
	// UpdateEnablePhoneCall(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateEnablePhoneCallFromDevice",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdatePhoneNumberDevice(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdatePhoneCallFromDeviceNumber",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	UpdateEmergencyBtnText(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEmergencyButtonText",
			data : data,
			callback : callback,
		});
	}

	UpdateEmergencySchedule(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEmergencyButtonSchedule",
			data : data,
			callback : callback,
		});
	}

	UpdateCallSchedule(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateCallButtonSchedule",
			data : data,
			callback : callback,
		});
	}

	// UpdateOffHoursMessage(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateOffHoursMessage",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	UpdateScheduleTimezone(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateScheduleTimezone",
			data : data,
			callback : callback,
		});
	}

	GetAccountSchedule(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCs.asmx/GetAccountSchedule",
			data : data,
			callback : callback,
		});
	}
	AddNewAccountSchedule(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareSchedule.asmx/AddSchedule",
			data : data,
			callback : callback,
		});
	}
	EditAccountSchedule(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareSchedule.asmx/UpdateAccountSchedule",
			data : data,
			callback : callback,
		});
	}
	RemoveAccountSchedule(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareSchedule.asmx/RemoveAccountSchedule",
			data : data,
			callback : callback,
		});
	}
	ApplyDefaultScheduleToAllPatients(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/ApplyDefaultScheduleToAllPatients",
			data : data,
			callback : callback,
		});
	}


	//<----------------------------------Get Daily Calls --------------------------->
	getDailyCallRequests (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccountReports.asmx/GetDailyCallRequestsForAllAccounts",
			data : data,
			callback : callback,
		});
	}

	//<-----------------------------Get Daily Handled Calls ------------------------>
	getDailyCallsMade (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccountReports.asmx/GetDailyCallsMadeForAllAccounts",
			data : data,
			callback : callback,
		});
	}


	GetAccountDevices(data,callback) {
		getAccountDevicesPromise(data.AccountID)
		.then(callback)
		.catch((e) => {
			console.log('Error: ', e)
			callback((e.response && e.response.data) ? e.response.data : e.response)
		})
	}

	GetDeviceInfo(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCs.asmx/GetDeviceDetails",
			data : data,
			callback : callback,
		});
	}

	EnableRemoteLogs(data, callback) {
		NucleusNetwork.request({
			context: this.context,
			type: "POST",
			url: Config.domain + "CareDevice.asmx/UpdateRemoteLogsEnabled",
			data: data,
			callback: callback,
		});
	}

	RemoveDeviceFromAccount(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareDevice.asmx/RemoveDeviceFromAccount",
			data : data,
			callback : callback,
		});
	}

	AddDeviceToAccount(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareDevice.asmx/AddDeviceValidated",  // "CareDevice.asmx/AddDevice",
			data : data,
			callback : callback,
		});
	}

	RequestRebootDevice (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareDevice.asmx/RebootDevice",
			data : data,
			callback : callback,
		});
	}


	GetAccountUsers(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCs.asmx/GetAccountUsers",
			data : data,
			callback : callback,
		});
	}

	NewGetAccountUsers(data, callback) {
		let rsp;
		httpNodeApi.get(
					"account/" + data.AccountID + "/users"
				)
				.then((response) => {
					const { data } = response;
					rsp = {
						...data,
					};
				})
				.catch((e) => {
					const { data } = e.response || { error: "Error on account/users"};
					console.log("Error account/users error", e);
					rsp = {
						...data,
					};
				})
				.finally(() => {
					console.log("account/users finally");
					console.log("rsp",rsp);

					callback(rsp);
				});
	}


	GetAccountPatients(data,callback) {
		// NucleusNetwork.request({
		// 	context : this.context,
		// 	type : "POST",
		// 	url: Config.domain + "CareCs.asmx/GetAccountPatients",
		// 	data : data,
		// 	callback : callback,
		// });

		let rsp;
		httpNodeApi.get(
					"account/" + data.AccountID + "/patients"
				)
				.then((response) => {
					//console.log("/user/" + UserID + "/patients Response:", response);
					//console.log("account/patients response", response);
					const { data } = response;
					rsp = {
						...data,
					};
				})
				.catch((e) => {
					const { data } = e.response || { error: "Error on account/patients"};
					console.log("Error account/patients error", e);
					rsp = {
						...data,
					};
				})
				.finally(() => {
					console.log("account/patients finally");
					console.log("rsp",rsp);

					callback(rsp);
				});

	}

	GetAccountPatientsStats(data,callback) {

		let rsp;
		httpNodeApi.get("account/" + data.AccountID + `/patients_stats?page=${1}&limit=${2000}`)
				.then((response) => {
					//console.log("/user/" + UserID + "/patients Response:", response);
					//console.log("account/patients response", response);
					const { data } = response;
					rsp = {
						...data,
					};
				})
				.catch((e) => {
					const { data } = e.response || { error: "Error on account/patients"};
					console.log("Error account/patients error", e);
					rsp = {
						...data,
					};
				})
				.finally(() => {
					console.log("account/patients finally");
					console.log("rsp",rsp);

					callback(rsp);
				});

	}


	GetAccountPatientsTimezones(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareCs.asmx/GetAccountPatientsTimezones",
			data : data,
			callback : callback,
		});
	}

	UpdatePatientTimeZone(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareDevice.asmx/UpdatePatientTimeZone",
			data : data,
			callback : callback,
		});
	}

	// Buttons
	// DEPRECATED - Fri Dec 6th 2019
    // https://nucleusintercom.atlassian.net/browse/CARE-6239

	// GetAccountButtons(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "BLEButton.asmx/GetButtonsForAccount",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// AddButton(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "BLEButton.asmx/AddButton",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// RemoveButton(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "BLEButton.asmx/RemoveButtonFromAccount",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateButtonType(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "BLEButton.asmx/UpdateButtonType",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }


	GetAccountBulletinButtons(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "BulletinBoard.asmx/GetAccountBulletinButtons",
			data : data,
			callback : callback,
		});
	}

	//Biometric Devices

	GetAccountBiometricDevices(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "BiometricDevices.asmx/GetBiometricDevicesForAccount",
			data : data,
			callback : callback,
		});
	}

	// DEPRECATED Nov 20th 2019, Change on Bluetooth Biometric Devices
    // https://nucleusintercom.atlassian.net/browse/CARE-6158
	// AddBiometricDevice(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "BiometricDevices.asmx/AddDevice",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateBioDeviceType(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "BiometricDevices.asmx/UpdateBiometricDeviceType",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// RemoveBioDevice(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "BiometricDevices.asmx/RemoveBiometricDeviceFromAccount",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }



	CreateNewUser (data,callback) {
		console.log("CareAccountApi> CreateNewUser data ", data);
    	let response;
		this
		.authClient
		.createUser(data)
		.then((rsp) => {
			console.log("CareAccountApi> CreateNewUser Response", rsp);
			response = {
				ok: true
			}
		})
		.catch((e) => {
			console.log("CareAccountApi> CreateNewUser error", e);
			console.log("CareAccountApi> CreateNewUser error.response", e.response); //Working
			const { data } = e.response
			switch (data.code) {
			case 1:
				response = {
				ok: false,
				emailRegistered: true,
				telephoneRegistered: false,
				error: data.error,
				}
				break
			case 2:
				response = {
				ok: false,
				emailRegistered: false,
				telephoneRegistered: true,
				error: data.error,
				}
				break
			default:
				response = {
				ok: false,
				error: data.error || 'Unexpected error'
				}
			}
		})
      	.finally(() => {
			callback(response)
		})
	}

	UpdateAccountUser (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateUser",
			data : data,
			callback : callback,
		});
	}


	UpdateReceiveEscalation(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateReceiveEscalationCallRequest",
			data : data,
			callback : callback,
		});
	}

	SetStaffAccess(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateCanSeeStaffTab",
			data : data,
			callback : callback,
		});
	}

	SetClientsAccess(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateCanSeeClientsTab",
			data : data,
			callback : callback,
		});
	}

	SetQueueAccess(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateCanSeeQueueTab",
			data : data,
			callback : callback,
		});
	}

	SetMessagesAccess(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateCanSeeMessageCenterTab",
			data : data,
			callback : callback,
		});
	}


	SetAlertsAccess(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateCanSeeAlertsTab",
			data : data,
			callback : callback,
		});
	}


	SetMemoryAccess(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateCanSeeMemoryBoxTab",
			data : data,
			callback : callback,
		});
	}

	SetBulletinBoardAccess(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateCanSeeBulletinBoardTab",
			data : data,
			callback : callback,
		});
	}

	//<----------------------Update User Data Send BroadcastMessage------------------->
	UpdateUserBroadcastMessage (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateCanSendMessageToAllPatients",
			data : data,
			callback : callback,
		});
	}


	DeleteAccountUser (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/DeleteUser",
			data : data,
			callback : callback,
		});
	}

	// UpdateAccountClientRoleName (data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateClientRoleName",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateAccountFamilyRoleName (data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateFamilyRoleName",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }
	// updateAccountFamilyRoleNamePlural (data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateFamilyRoleNamePlural",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateAccountClientRoleNamePlural(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateClientRoleNamePlural",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateAccountUserRoleName(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateUserRoleName",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateAccountUserRoleNamePlural(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateUserRoleNamePlural",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateAccountCaregiverRoleName(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateCaregiverRoleName",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateAccountCaregiverRoleNamePlural(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateCaregiverRoleNamePlural",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateActionButtonText(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateCallButtonText",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateCallButtonText2(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateCallButtonText2",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }


	UpdateSendToPagerDuty(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSendToPagerDuty",
			data : data,
			callback : callback,
		});
	}

	UpdatePagerDutyEmail(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdatePagerDutyEmailAddress",
			data : data,
			callback : callback,
		});
	}

	UpdateSendWelcomeEmail(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateShouldSendWelcomeEmail",
			data : data,
			callback : callback,
		});
	}


	UpdateSendWelcomeSms(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateShouldSendWelcomeSms",
			data : data,
			callback : callback,
		});
	}

	UpdateEnableMoveFamily(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableMoveFamily",
			data : data,
			callback : callback,
		});
	}

	UpdateOnDutyUsersByEmail(data,callback){
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateAlertForOnDutyUsers",
			data : data,
			callback : callback,
		});
	}

	UpdateNotifyDeviceOffline(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateNotifyOnDeviceOffline",
			data : data,
			callback : callback,
		});
	}

	ClearSMSandEmailAccountAlerts(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/ClearSMSandEmailAccountAlerts",
			data : data,
			callback : callback,
		})
	}

	UpdateAccountAlertSettingEnable(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateAccountAlertSettingEnable",
			data : data,
			callback : callback,
		})
	}

	UpdateAccountAlertSettingSMS(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateAccountAlertSettingSMS",
			data : data,
			callback : callback,
		})
	}

	UpdateAccountAlertSettingEmail(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateAccountAlertSettingEmail",
			data : data,
			callback : callback,
		})
	}

	UpdateAlertExtremeTemperature(data,callback){
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateAlertOnExtremeTemperature",
			data : data,
			callback : callback,
		});
	}

	UpdateUsePanicRingtone(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateUsePanicEmergencyRingtone",
			data : data,
			callback : callback,
		});
	}

	UpdateMessageToAllPatients(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableMessageToAllPatients",
			data : data,
			callback : callback,
		});
	}

	UpdateControlIncomingCall(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableControlIncomingCall",
			data : data,
			callback : callback,
		});
	}

	UpdateAppIncomingCall(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableAppIncomingCall",
			data : data,
			callback : callback,
		});
	}

	UpdateControlVideoCallShortcut(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableControlVideoCallShortcut",
			data : data,
			callback : callback,
		});
	}

	UpdateControlAudioCallShortcut(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableControlAudioCallShortcut",
			data : data,
			callback : callback,
		});
	}
	UpdateControlBargeInCallShortcut(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableControlBargeInCallShortcut",
			data : data,
			callback : callback,
		});
	}
	UpdateControlSilentBargeInCallShortcut(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableControlSilentBargeInCallShortcut",
			data : data,
			callback : callback,
		});
	}
	UpdateControlAutomaticCallRequestBargeIn(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableControlAutomaticCallRequestBargeIn",
			data : data,
			callback : callback,
		});
	}

	Update2FactorAuth(data, callback) {
		NucleusNetwork.request({
			context: this.context,
			type: "POST",
			url: Config.domain + "CareAccount.asmx/UpdateTwoFactorAuth",
			data: data,
			callback: callback,
		});
	}

	UpdateRemoteLogs(data, callback) {
		NucleusNetwork.request({
			context: this.context,
			type: "POST",
			url: Config.domain + "CareAccount.asmx/UpdateRemoteLogs",
			data: data,
			callback: callback,
		});
	}

	UpdateEnableMemoryBox(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableMemoryBox",
			data : data,
			callback : callback,
		});
	}

	// UpdateMemoryBoxLabel(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateMemoryBoxButtonText",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateFamilyCallButtonText(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateFamilyCallButtonText",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateBiometricsButtonText(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateBiometricsButtonText",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	// UpdateAddFamilyButtonText(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateAddFamilyButtonText",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	UpdateUseDeviceTemplate(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateDeviceTemplate",
			data : data,
			callback : callback,
		});
	}

	UpdateDeviceTemplate(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateDeviceTemplate",
			data : data,
			callback : callback,
		});
	}

	UpdateEnableBackgroundImage(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableBackgroundImage",
			data : data,
			callback : callback,
		});
	}

	UpdateEnableBackgroundLogo(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableBackgroundLogo",
			data : data,
			callback : callback,
		});
	}

	UpdateEnableBulletinButton(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "BulletinBoard.asmx/UpdateEnableBulletinButton",
			data : data,
			callback : callback,
		});
	}

	UpdateBulletinButtonName(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "BulletinBoard.asmx/UpdateBulletinButtonName",
			data : data,
			callback : callback,
		});
	}


	UploadBackgroundImage(body,callback) {
		
		// DEPRECATED Not JQuery anymore Aug 2nd 2023
		// NucleusNetwork.requestWithFile({
		// 	context : this.context,
		// 	type : "POST",
		// 	url: Config.domain + "CareDevice.asmx/UploadBackgroundImage",
		// 	data : data,
		// 	callback : callback,
		// });

		api({
		  url: '/CareDevice.asmx/UploadBackgroundImage',
		  method: 'post',
		  data: body
		})
		.then((response) => {
			callback(response.data)
		})
		.catch((e) => {
			console.log("Error on CareDevice.asmx/UploadBackgroundImage", e);
			callback({
				ok:false,
				...e
			})
		})
	}

	UploadBackgroundLogo(body,callback) {
		
		// DEPRECATED Not JQuery anymore Aug 2nd 2023
		// NucleusNetwork.requestWithFile({
		// 	context : this.context,
		// 	type : "POST",
		// 	url: Config.domain + "CareDevice.asmx/UploadBackgroundLogoImage",
		// 	data : data,
		// 	callback : callback,
		// });

		api({
			url: '/CareDevice.asmx/UploadBackgroundLogoImage',
			method: 'post',
			data: body
		  })
		  .then((response) => {
			  callback(response.data)
		  })
		  .catch((e) => {
			  console.log("Error on CareDevice.asmx/UploadBackgroundLogoImage", e);
			  callback({
				  ok:false,
				  ...e
			  })
		  })
	}

	UpdateBackgroundImgUrl(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateBackgroundImageUrl",
			data : data,
			callback : callback,
		});
	}




	// UpdateRequestTimeoutMessage(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateRequestTimeoutText",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	UpdateScreenSaverDelay(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateScreenSaverDelayInSeconds",
			data : data,
			callback : callback,
		});
	}

	UpdateDimOffValue(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateDimOffValue",
			data : data,
			callback : callback,
		});
	}

	UpdateDimOnValue(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateDimOnValue",
			data : data,
			callback : callback,
		});
	}

	UpdateDimFrom(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateDimFrom",
			data : data,
			callback : callback,
		});
	}

	UpdateDimTo(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateDimTo",
			data : data,
			callback : callback,
		});
	}

	UpdateCallTimeoutDelay(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateCallTimeoutDelayInSeconds",
			data : data,
			callback : callback,
		});
	}

	UpdateEscalationTimeOut(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateReceiveEscalationCallRequestTimeout",
			data : data,
			callback : callback,
		});
	}

	UpdateOfflineTimeSpan(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateOfflineTimeSpan",
			data : data,
			callback : callback,
		});
	}

	UpdateInactiveDays(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateInactiveDays",
			data : data,
			callback : callback,
		});
	}

	UpdateInactiveDaysAlert(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateInactiveDaysAlert",
			data : data,
			callback : callback,
		});
	}

	updateHexnodeAPI(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateHexnodeAPI",
			data : data,
			callback : callback,
		});
	}

	UpdateHexnodeGroupID(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateHexnodeGroupID",
			data : data,
			callback : callback,
		});
	}
	UpdateHexnodePolicyID(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateHexnodePolicyID",
			data : data,
			callback : callback,
		});
	}
	UpdateHexnodeUrl(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateHexnodeUrl",
			data : data,
			callback : callback,
		});
	}

	UpdateHexnodeUserName(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateHexnodeUsername",
			data : data,
			callback : callback,
		});
	}

	UpdateHexnodeIdRequired(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateHexnodeRequired",
			data : data,
			callback : callback,
		});
	}

	UpdateSettingsPinCode(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSettingsAccessPIN",
			data : data,
			callback : callback,
		});
	}

	UpdateIsCheckedInScreenStaysOn(data,callback){
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateIsCheckedInScreenStaysOn",
			data : data,
			callback : callback,
		})
	}

	UpdateRestartDeviceOfflineEnabled(data,callback){
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateRestartOfflineDeviceEnabled",
			data : data,
			callback : callback,
		})
	}

	UpdateRestartOfflineDeviceTimeSpan(data,callback){
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateRestartOfflineDeviceTimeSpan",
			data : data,
			callback : callback,
		})
	}

	UpdateShowBatteryStatus(data,callback){
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url : Config.domain + "CareAccount.asmx/UpdateShowBatteryStatus",
			data : data,
			callback : callback,
		})
	}

	/* MOVED FROM USER */
	//<----------------------------------Get Daily Calls --------------------------->
	getDailyCalls (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccountReports.asmx/GetDailyCallRequests",
			data : data,
			callback : callback,
		});
	}

	//<-----------------------------Get Daily Handled Calls ------------------------>
	getDailyHandledCalls (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccountReports.asmx/GetCallAnswered",
			data : data,
			callback : callback,
		});
	}

	GetFamilyMembers(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccountReports.asmx/GetFamilyMembers",
			data : data,
			callback : callback,
		});
	}


	GetMessagesReport(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccountReports.asmx/GetMessagesReport",
			data : data,
			callback : callback,
		});
	}


	GetFamilyCalls(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccountReports.asmx/GetFamilyCallLogs",
			data : data,
			callback : callback,
		});
	}

	GetMediaItemsPerDayReport(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccountReports.asmx/GetMemoryBoxItemsPerDay",
			data : data,
			callback : callback,
		});
	}

	GetMessageCategories(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareMessage.asmx/GetMessageCategories",
			data : data,
			callback : callback,
		});
	}


	SaveMessageCategory(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareMessage.asmx/AddMessageCategory",
			data : data,
			callback : callback,
		});
	}

	UpdateMessageCategory(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareMessage.asmx/UpdateMessageCategory",
			data : data,
			callback : callback,
		});
	}

	// DEPRECATED on March 1st 2023, https://nucleusintercom.atlassian.net/browse/CARE-10228
	// UpdateSendXmppPalCare(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdateSendEmergencyAlarmToPalCare",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }
	// UpdateXmppPalCareAddress(data,callback) {
	// 	NucleusNetwork.request({
	// 		context : this.context,
	// 		type : "POST",
	// 		url: Config.domain + "CareAccount.asmx/UpdatePalCareXMPPAddressForAlarm",
	// 		data : data,
	// 		callback : callback,
	// 	});
	// }

	UpdateEnableMessageResponse(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEnableMessageResponseByEmail",
			data : data,
			callback : callback,
		});
	}


	UpdateMessageResponseEmail(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSendMessageResponseEmail",
			data : data,
			callback : callback,
		});
	}

	UpdateEscalationEmail(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateEscalationEmailAddress",
			data : data,
			callback : callback,
		});
	}

	GetAccountDataByMac(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareDevice.asmx/GetAccountDataByMac",
			data : data,
			callback : callback,
		});
	}

	UpdateIsHipaaCompliant(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateIsHipaaCompliant",
			data : data,
			callback : callback,
		});
	}

	UpdateAutoLogoutTimeout(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateAutoLogoutTimeout",
			data : data,
			callback : callback,
		});
	}


	UpdateWeightUnit(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateWeightUnit",
			data : data,
			callback : callback,
		});
	}

	UpdateHoursFormat(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateHoursFormat",
			data : data,
			callback : callback,
		});
	}


	UpdateSalesforceEnabled(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSalesforceEnabled",
			data : data,
			callback : callback,
		});
	}

	UpdateSalesforceOrgUrl(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSalesforceOrgUrl",
			data : data,
			callback : callback,
		});
	}

	UpdateSalesforceLoginUrl(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSalesforceLoginUrl",
			data : data,
			callback : callback,
		});
	}

	UpdateSalesforceClientID(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSalesforceClientID",
			data : data,
			callback : callback,
		});
	}

	UpdateSalesforceClientSecret(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSalesforceClientSecret",
			data : data,
			callback : callback,
		});
	}

	UpdateSalesforceUserName(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSalesforceUserName",
			data : data,
			callback : callback,
		});
	}

	UpdateSalesforcePassword(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSalesforcePassword",
			data : data,
			callback : callback,
		});
	}


	UpdateSalesforceRedirectUri(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSalesforceRedirectUri",
			data : data,
			callback : callback,
		});
	}

	UpdateSalesforceSecurityToken(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/UpdateSalesforceSecurityToken",
			data : data,
			callback : callback,
		});
	}

	//
	onNucleusApiRequest(apiUrl, data, callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + apiUrl,
			data : data,
			callback : callback,
		});
	}

	// FAMILY MEMBERS
	GetFamilyMembersData(data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "FamilyMember.asmx/ListAll",
			data : data,
			callback : callback,
		});
	}
}


export default CareAccountApi;
