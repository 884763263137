import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import TopBar from '../components/TopBar';
import SideBar from '../components/SideBar';
import AuthStore from '../stores/AuthStore';
import CareAccountStore from '../stores/CareAccountStore';
import { PrivateRoute, PrivateRouteComponent } from '../components/PrivateRoute';
import Dashboard from './Dashboard';
import Accounts from './Accounts';
import Providers from '../pages/providers';
import Family from './Family';
import Settings from './Settings';
import Tools from './Tools';
import Reports from './Reports';
import ReportDevicesForBilling from './reports/ReportDevicesForBilling';
import CsUsers from './CsUsers';
import MDMPolicies from './MDMPolicies/MDMPolicies';
import MDMPolicy from './MDMPolicies/MDMPolicy';
import MDMAccounts from './MDMPolicies/MDMAccounts';
import MDMEnterprises from './MDMPolicies/MDMEnterprises';
import MDMAccountDetails from './MDMPolicies/MDMAccounts/MDMAccountDetails';
import MDMDeviceDetails from './MDMPolicies/MDMDevices/MDMDeviceDetails';
import Consumers from './consumer/Consumers';
import Devices from './devices/Devices';
import ExternalApps from './externalApps/ExternalApps';
import AccountDetails from './AccountDetails/AccountDetails';
import NewAccountUser from './NewAccountUser';
import NewAccountPatient from './NewAccountPatient';
import AccountUserProfile from './AccountUserProfile';
import AccountUserPatientProfile from './AccountUserPatientProfile';
import NewCsUser from './NewCsUser';
import NewFamilyMember from './NewFamilyMember';
import FamilyDetails from './FamilyDetails';

import { Switch } from 'react-router-dom';
import MDMDevicesPage from './MDMPolicies/MDMDevices/MDMDevicesPage';
import AccountsUsageReport from './reports/AccountsUsageReport';
import AppsUsageReport from './reports/AppsUsageReport';
import ProviderSettings from './providers/ProviderSettings';
import ProviderStrings from './providers/ProviderStrings';
import ProviderAccounts from './providers/ProviderAccounts';
import ProviderUsers from './providers/ProviderUsers';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: true,
      patientID: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      CareAccountStore.getAllAccountsData({
        UserID: AuthStore.getCsUserID(),
        Token: AuthStore.getCsUserToken(),
      });
    }, 5000);

    setTimeout(() => {
      //CareAccountStore.getAccountGroupsData(); // Not needed to call it on Main screen
      //CareAccountStore.getUpdateGroupsData(); // Not needed to call it on Main screen
      //CareAccountStore.getLocalesData(); // Not needed to call it on Main screen
      CareAccountStore.getAccountsCountriesData();
      CareAccountStore.getTemplatesData();
    }, 10000);
  }
  render() {

    return (
      <div className="row nucleus-main-content">
        <SideBar />
        <div ref="mainContent" className="col s9 m10 l11 xl11 general-content">
          <TopBar />
          <div className="general-containter">
            <Switch>
              <PrivateRoute path={`/accounts`}>
                <Accounts />
              </PrivateRoute>

              <PrivateRoute path={`/reports`}>
                <Reports />
              </PrivateRoute>
              {/* <PrivateRoute path={`/reportNewDevices`}>
                <ReportNewDevices />
              </PrivateRoute> */}
              <PrivateRoute path={`/accountsUsageReport`}>
                <AccountsUsageReport />
              </PrivateRoute>
              <PrivateRoute path={`/appsUsageReport`}>
                <AppsUsageReport />
              </PrivateRoute>
              {/* TODO: DeviceUsageReport screen */}
              {/* <PrivateRoute path={`/deviceUsageReport`}>
                <DeviceUsageReport />
              </PrivateRoute> */}

              <PrivateRoute path={`/reportDevicesForBilling`}>
                <ReportDevicesForBilling />
              </PrivateRoute>

              <PrivateRoute path={`/family`}>
                <Family />
              </PrivateRoute>

              <PrivateRoute path={`/providers`} exact>
                <Providers />
              </PrivateRoute>
              <PrivateRoute path={`/providerDetails/:providerId/settings`}>
                <ProviderSettings />
              </PrivateRoute>
              <PrivateRoute path={`/providerDetails/:providerId/strings`}>
                <ProviderStrings />
              </PrivateRoute>
              <PrivateRoute path={`/providerDetails/:providerId/accounts`}>
                <ProviderAccounts />
              </PrivateRoute>
              <PrivateRoute path={`/providerDetails/:providerId/users`}>
                <ProviderUsers />
              </PrivateRoute>

              <PrivateRoute path={`/csUsers`}>
                <CsUsers />
              </PrivateRoute>

              <PrivateRouteComponent path={`/policies/mdmAccounts`} component={MDMAccounts} />
              <PrivateRouteComponent path={`/policies/mdmDevices`} component={MDMDevicesPage} />

              <PrivateRoute path={`/policies/mdmEnterprises`}>
                <MDMEnterprises />
              </PrivateRoute>
              <PrivateRoute exact path={`/policies`}>
                <MDMPolicies />
              </PrivateRoute>
              <PrivateRoute path={`/consumers`}>
                <Consumers />
              </PrivateRoute>
              <PrivateRoute path={`/devices`}>
                <Devices />
              </PrivateRoute>
              <PrivateRoute path={`/externalApps`}>
                <ExternalApps />
              </PrivateRoute>

              <PrivateRoute path={`/settings`}>
                <Settings />
              </PrivateRoute>

              <PrivateRoute path={`/tools`}>
                <Tools />
              </PrivateRoute>

              <PrivateRoute path={`/policyDetails/:policyId`}>
                <MDMPolicy />
              </PrivateRoute>
              <PrivateRoute path={`/policies/mdmDeviceDetails/:mdmDeviceId`}>
                <MDMDeviceDetails />
              </PrivateRoute>
              <PrivateRoute path={`/policies/mdmAccountDetails/:mdmAccountId`}>
                <MDMAccountDetails />
              </PrivateRoute>
              <PrivateRoute path={`/newPolicy`}>
                <MDMPolicy />
              </PrivateRoute>
              <PrivateRoute path={`/accountDetails/:accountId`}>
                <AccountDetails />
              </PrivateRoute>
              <PrivateRoute path={`/familyDetails/:familyId`}>
                <FamilyDetails />
              </PrivateRoute>
              <PrivateRoute path={`/newAccountUser/:accountId`}>
                <NewAccountUser />
              </PrivateRoute>
              <PrivateRoute path={`/newPatient/:accountId`}>
                <NewAccountPatient />
              </PrivateRoute>
              <PrivateRoute path={`/newFamilyMember/:patientId`}>
                <NewFamilyMember />
              </PrivateRoute>
              <PrivateRoute path={`/newCsUser`}>
                <NewCsUser />
              </PrivateRoute>
              <PrivateRoute path={`/accountUserProfile/:userId/:accountId`}>
                <AccountUserProfile />
              </PrivateRoute>
              <PrivateRoute
                path={`/consumerPatientProfile/:patientId/:accountId`}
              >
                <AccountUserPatientProfile />
              </PrivateRoute>
              <PrivateRoute
                path={`/accountUserPatientProfile/:patientId/:accountId`}
              >
                <AccountUserPatientProfile />
              </PrivateRoute>
              <PrivateRoute exact path={`/dashboard`}>
                <Dashboard />
              </PrivateRoute>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
