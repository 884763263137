import React, { useEffect, useState } from "react";
import PoliciesTable from "./components/PoliciesTable";
import MDMPolicy from "./MDMPolicy";
import NucleusSearchInput from "../../components/NucleusSearchInput";
import NucleusModalButton from "../../components/NucleusModal/NucleusModalButton";
import NucleusModal from "../../components/NucleusModal";
import { useDispatch, useSelector } from "react-redux";
import {
  resetMDMPolicyState,
  setPolicyFormStatus,
  setPolicyScreen,
} from '@nucleus-care/nucleuscare-backend-client';
import { useHistory } from "react-router-dom";

const Policies = () => {
  const history = useHistory();
  const { policyFormStatus } = useSelector(({ mdmPolicy }) => mdmPolicy.state);
  const [policyID, setPolicyID] = useState(null);
  const [currentUi, setCurrentUi] = useState("table");
  const [operationType, setOperationType] = useState(" ");
  const dispatch = useDispatch();
  const newPolicyModalID = "networkModal";
  
  const handleOpenModal = () => {
    dispatch(resetMDMPolicyState());
    setCurrentUi("new");
    setOperationType("new");
    dispatch(
      setPolicyFormStatus({
        operationType: "CREATE",
        isProtectedPolicy: false,
        isEditable: true,
        PolicyTemplateID: undefined,
      })
    );
    dispatch(
      setPolicyScreen({
        activeMainScreen: "policies",
        activeMDMScreen: "general",
      })
    );
    history.push("/newPolicy");
  };

  const handleCloseModal = () => {
    // $(`#${newPolicyModalID}`).modal("close");
  };

  const handleUiChange = (info) => {
    console.log("this row: ", info);
    setCurrentUi(info.title);
    setOperationType("edit");
    setPolicyID(info.row.original.ID);
  };
  useEffect(() => {
    if (policyFormStatus?.operationType) {
      setCurrentUi("Update Policy");
    } else {
      setCurrentUi("table");
    }
  }, [policyFormStatus]);

  return (
    <React.Fragment>
      <div className="nucleus-all-scrollable-page">
        <p class="nucleus-page-title">
          <span></span>
        </p>
        <div className="nucleus-tools-page-content">
          <div className="row a-bit-lower left-align">
            <div
              style={{
                marginTop: "40px",
              }}
            >
              <PoliciesTable />
            </div>
          </div>
          <NucleusModalButton
            dataTooltip={"New Policy"}
            id={"newPolicy"}
            handleOpenModal={handleOpenModal}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Policies;
