import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { IndexLink, Link } from "react-router-dom";
import Message from "../../utils/Message";

import { useState, useEffect, useRef } from 'react';

import NucleuscareConnectData,
{
	RootState,
	getExternalAppsListThunk, addExternalAppThunk,
    getAppInfoThunk, deleteExternalAppThunk
} from '@nucleus-care/nucleuscare-backend-client';

import {
	useSelector,
	useDispatch
	//DefaultRootState
} from 'react-redux';

import { SpinnerCircular } from 'spinners-react';

import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
//import AuthStore from "../stores/AuthStore";

//Components
import AccountGroupRow from './AccountGroupRow';

const AccountsGroups = () => {

    const externalAppsStore = useSelector((state: RootState) => {
		//console.log("AccountsGroups useSelector", state.accountExternalApps.appsConfig);
		return state.accountExternalApps.appsConfig;
	});

    // const externalAppStore = useSelector((state: RootState) => {
	// 	console.log("ExternalApp useSelector", state.accountExternalApps.app);
	// 	return state.accountExternalApps.app;
	// });

    const [stateAccountsGroups, setStateAccountsGroups] = useState([]);

    const [stateLoading, setStateLoading] = useState(false);
	const [stateSearchText, setStateSearchText] = useState("");

    const dispatch = useDispatch();

    const [stateNewGroupInfo, setStateNewGroupInfo] = useState({
        ID: null,
        Name: ""
    });
    const [stateSelectedGroupData, setStateSelectedGroupData] = useState({
        ok:false,
        id:null,
        name:"",
        accounts:[],
        csUsers:[]
    });

    const [stateMissingNameError, setStateMissingNameError] = useState(false);

    const [stateSortingStyles, setStateSortingStyles] = useState({
        groupNameAsc: " nucleus-icon-inactive ",
        groupNameDesc : " nucleus-icon-inactive hide ",
		onlineDateAsc : " nucleus-icon-inactive ",
        onlineDateDesc : " nucleus-icon-inactive hide ",
    });

    const [stateSortingMode, setStateSortingMode] = useState("groupNameAsc");

    const handleSortingMode = (tag, tag2) => {
        console.log("handleSortingMode", tag, tag2);

        let object = {
            groupNameAsc: " nucleus-icon-inactive ",
            groupNameDesc : " nucleus-icon-inactive hide ",
			onlineDateAsc : " nucleus-icon-inactive ",
        	onlineDateDesc : " nucleus-icon-inactive hide ",
        };

        object[tag] = " nucleus-icon-active ";
        object[tag2] = " nucleus-icon-inactive hide ";
        setStateSortingStyles(object);
        setStateSortingMode(tag);
    }

    const [stateSaveBtnStyle, setStateSaveBtnStyle] = useState("nucleus-submit-btn");
    const [stateSaveButtonLabel, setStateSaveButtonLabel] = useState("Save");

	useEffect(() => {
		console.log("AccountsGroups Initial useEffect");
        window.$('.modal').modal({
            dismissible: true,
            complete: () => {  }
        });
		refreshReport();
	}, []);




    // useEffect(() => {
	// 	console.log("AccountsGroups:useEffect externalAppsStore:Add");
	// 	console.log("externalAppsStore", externalAppsStore);

	// 	if (!externalAppsStore.addLoading){
	// 		setStateLoading(false);
	// 		if (externalAppsStore.addComplete){
    //             refreshReport();
	// 			Message.show("New App created");
    //             handleCloseAddModal();
	// 		}
	// 	} else if (externalAppsStore.error){
	// 		Message.show("Error in Adding a new app...");
	// 	}
    //     // dispatch(
    //     //     csUsersSliceClean()
    //     // );
	// }, [externalAppsStore.addLoading, externalAppsStore.addComplete])

    // useEffect(() => {
	// 	console.log("ExternalApp:useEffect externalAppStore:Info");
	// 	console.log("externalAppStore", externalAppStore);

	// 	if (!externalAppStore.infoLoading){
	// 		setStateLoading(false);
	// 		if (externalAppStore.infoComplete){
    //             handleExternalAppInfo();
	// 		}
	// 	} else if (externalAppStore.error){
	// 		Message.show("Error getting App info...");
	// 	}

	// }, [externalAppStore.infoLoading, externalAppStore.infoComplete])


    // useEffect(() => {
	// 	console.log("CsUsers:useEffect externalAppsStore:Delete");
	// 	console.log("externalAppsStore", externalAppsStore);
	// 	if (!externalAppsStore.deleteLoading){
	// 		setStateLoading(false);
	// 		if (externalAppsStore.deleteComplete){
    //             refreshReport();
	// 			Message.show("User deleted");
    //             setStateDeleteUserBtnStyle("nucleus-submit-btn-small");
    //             window.$('#modalDeleteUser').modal('close');
    //             handleCloseEditModal();
	// 		}
	// 	} else if (externalAppsStore.error){
	// 		Message.show("Error in CsUsers");
	// 	}
    //     dispatch(
    //         csUsersSliceClean()
    //     );
	// }, [externalAppsStore.deleteLoading, externalAppsStore.deleteComplete])


    const onSearchTextChange = (textInput) =>{
        console.log("onSearchTextChange", textInput.target.value);
		setStateSearchText(textInput.target.value);
    }

    const refreshReport = ()=>{
		console.log("refreshReport");

        setStateLoading(true);
        // dispatch(
        //     getExternalAppsListThunk()
        // );
        //httpApi.get("/account/"+ AuthStore.getCsUserID() + "/list")
        httpApi.get("cs_user/get_account_groups_data")
		.then(({data})=>{
			console.log("get_account_groups_data response",data);
            setStateLoading(false);
            if (data.ok){
                if (data.groups){
                    setStateAccountsGroups(data.groups);
                }
            }
            else{
                Message.show("Error getting Accounts Groups");
            }
			//let currentAccountIndex = data.findIndex(acc=>acc.ID.toLowerCase()==this.state.accountID)
			//console.log("currentAccountIndex",data,currentAccountIndex,this.state.accountID);
			// data.splice(currentAccountIndex,1)
			// data.sort((a, b) => {
			// 	let textA = a.AccountName.toLowerCase();
			// 	let textB = b.AccountName.toLowerCase();
			// 	if(textA === "" || textA === null) return 1;
			// 	if(textB === "" || textB === null) return -1;
			// 	if(textA === textB) return 0;
			// 	return textA < textB ? -1 : 1;
			// });

		})
		.catch(err=>{
			console.log("get_account_groups_data catch",err);
            Message.show("Error getting Accounts Groups");
			setStateLoading(false);
		})
	}

    const onRowClicked = (groupData)=>{
        console.log("onRowClicked", groupData);
        // setStateSelectedGroupData({
        //    id: groupData.ID,
        //    name: groupData.Name
        // });
        getAccountGroupData(groupData.ID.toLowerCase(), groupData.Name);
    }

    const openGroupModal=()=>{
        window.$('#modalNewGroup').modal('open');
        setStateNewGroupInfo({
            ID: null,
            Name: ""
        });
        setStateSaveBtnStyle('nucleus-submit-btn');
        setStateSaveButtonLabel('Save')
    }

    const handleCloseAddModal = ()=>{
        console.log("handleCloseAddModal");
        window.$('#modalNewGroup').modal('close');
    }

    const handleSaveNewGroup = ()=>{
        console.log("handleSaveNewGroup", stateNewGroupInfo);

        if (stateNewGroupInfo.Name == ""){
            setStateMissingNameError(true);
            return;
        }

        setStateSaveBtnStyle("nucleus-submit-btn-disabled");
        setStateSaveButtonLabel('Saving...');
        setStateLoading(true);
        addNewAccountGroup();
        // dispatch(
        //     addExternalAppThunk({
        //         name: stateNewGroupInfo.Name,
        //         androidIdentifier: stateNewGroupInfo.AndroidPackage,
        //         iconUrl: stateNewGroupInfo.IconUrl
        //     })
        // );
    }

    const addNewAccountGroup=()=>{

        httpApi.post("cs_user/new_account_group",
            {
                name : stateNewGroupInfo.Name
            })
		.then(({data})=>{
			console.log("cs_user/new_account_group response",data);
            setStateLoading(false);
            if (data.ok){
                refreshReport();
				Message.show("New Account Group created");
                handleCloseAddModal();
            }
            else{
                Message.show("Error creating new Account Group");
            }
		})
		.catch(err=>{
			console.log("cs_user/new_account_group catch",err);
            Message.show("Error creating new Account Group");
			setStateLoading(false);
		})
    }
    // const handleExternalAppInfo = ()=>{
    //     console.log("handleExternalAppInfo", stateSelectedGroup);
    //     setStateSelectedGroup({
    //         ID: stateSelectedGroup.ID,
    //         Name: stateSelectedGroup.Name,
    //         Accounts: externalAppStore.accounts,
    //         Patients: externalAppStore.patients
    //      });
    //      if (externalAppStore.accounts == 0 && externalAppStore.patients == 0){
    //         setStateDeleteBtnStyle("nucleus-submit-btn");
    //      }
    //      else{
    //         setStateDeleteBtnStyle("nucleus-submit-btn-disabled");
    //      }
    //      window.$('#modalGroupDetails').modal('open');
    // }

    const handleCloseGroupInfoModal = ()=>{
        console.log("handleCloseGroupInfoModal");
        window.$('#modalGroupDetails').modal('close');
    }


    const getAccountGroupData = (groupId, groupName)=>{
        console.log("getAccountGroupData", groupId, groupName);

        let httpResponse;
			httpApi
				.get(`cs_user/${groupId}/get_group_data`)
				.then((response) => {
					//console.log(`cs_user/${groupId}/get_group_data`, response);
					httpResponse = response.data;
				})
				.catch((e) => {
					console.error(`cs_user/${groupId}/get_group_data`, e)
					const { data } = e.response;
					httpResponse = {
						ok: false,
						...data,
					};
				})
			.finally(() => {
				console.log("finally", httpResponse);
				//setStateModalLoading(false);
				if (httpResponse && httpResponse.ok){
                    console.log("stateSelectedGroupData", stateSelectedGroupData);
                    let tempData = Object.assign({}, stateSelectedGroupData);
                    tempData.id = groupId;
                    tempData.name = groupName;
                    tempData.accounts = httpResponse.accounts;
                    tempData.csUsers = httpResponse.csUsers;
                    setStateSelectedGroupData(tempData);
                    console.log("stateSelectedGroupData", tempData);
                    window.$('#modalGroupDetails').modal('open');
				}
				else{
					Message.show("Error getting Account Group Data");
				}
			})

        // let editable = (AuthStore.getCsUserID().toLowerCase() == appData.ID.toLowerCase());
        // setStateEditUserAllowed(editable);
        // setStateSaveBtnStyle(editable ? "nucleus-submit-btn": "nucleus-submit-btn-disabled");
        // dispatch(
        //     getAppInfoThunk(appData.ID)
        // )
    }


    const getTableRows = ()=>{
        //console.log("render getTableRows>", externalAppsStore);

        let groupRowsArray = [];
        if (stateAccountsGroups && stateAccountsGroups.length > 0){

            let array = [].concat(stateAccountsGroups);

            if (stateSortingMode == "groupNameDesc"){
                array = array.sort((a, b) => {
					let textA = a.Name.toLowerCase();
                    let textB = b.Name.toLowerCase();
                    if (textB < textA) //sort string ascending
                        return -1
                    if (textB > textA)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "groupNameAsc"){
                array = array.sort((a, b) => {
					let textA = a.Name.toLowerCase();
                    let textB = b.Name.toLowerCase();
                    if (textA < textB) //sort string ascending
                        return -1
                    if (textA > textB)
                        return 1
                    return 0
                });
            }

			//devicesReportRef.current = array;
            array.map((group, key) => {

				if (stateSearchText.length == 0){
					groupRowsArray.push(
                        <AccountGroupRow key={key} data={group} onClick={onRowClicked} ></AccountGroupRow>
                    );
				}
				else {
					let text = stateSearchText.toLocaleLowerCase();
					if (group.Name.toLowerCase().indexOf(text) >= 0)
                    {
						groupRowsArray.push(
                            <AccountGroupRow key={key} data={group} onClick={onRowClicked} ></AccountGroupRow>
                        );
					}
				}
            });

            return (
                <tbody >
                    {groupRowsArray}
                </tbody>
            );
        }
        return(
            <tbody class="center-align ">
                <tr style={{borderBottomStyle:"none"}}>
                    <td colSpan="7" class="center-align ">
                        <br/><br/><br/>
                        <span class="nucleus-table-header-medium text-gray"> {`There are no Accounts Groups`} </span>
                        <br/><br/><br/>
                    </td>
                </tr>
            </tbody>
        );
    }

    return(
        <div class="row no-margin ">

            {/*
            <div style={{}}>
				<div class="row a-bit-lower left-align">
					<div class="col m11 no-margin">
						<span class="nucleus-tools-page-text-title a-bit-lower">
							Search &nbsp;&nbsp;&nbsp;
						</span>
						<input
							type="text"
							class="nucleus-input-form-small-left"
							style={{width:500}}
							onChange={onSearchTextChange}
							placeholder={"Search for an App"}
						/>
                        &nbsp;&nbsp;&nbsp;
                            <a  class="txt-white nucleus-font-small nucleus-submit-btn-small"
                                onClick={()=>{refreshReport();}}> Refresh
                            </a>
					</div>

					<div class="col m1 no-margin right-align">
                        {
                            (false) &&
							<div style={{margin:0, padding:0, marginTop:-16, marginBottom:0}}>
                            	<Spinner color="#2096F3" size={32} speed={0.5} animating={true} />
							</div>
                        }
                    </div>
				</div>
            </div>
            */}

                <div class="nucleus-table-pending" >
                    <table class="bordered highlight nucleus-table" style={{tableLayout:"fixed"}}>
                    <thead>
                        <tr>
                            <th class="table-col-50">
                                <span class="nucleus-table-header ">{"Name"}</span>
                                <a class={ "nucleus-font-small " + stateSortingStyles.groupNameDesc } onClick={()=>handleSortingMode("groupNameAsc","groupNameDesc")}> &nbsp;▼</a>
                                <a class={ "nucleus-font-small " + stateSortingStyles.groupNameAsc  } onClick={()=>handleSortingMode("groupNameDesc","groupNameAsc")} >&nbsp;▲</a>
                            </th>
                            <th class="table-col-20 center-align">
                                <span class="nucleus-table-header">{"Accounts"}</span>
                            </th>
                            <th class="table-col-20 center-align">
                                <span class="nucleus-table-header">{"Users Associated"}</span>
                            </th>
                            <th class="table-col-10 center-align">
                                <span class="nucleus-table-header-medium">Details</span>
                             </th>

                            {/*
                            <th class="table-col-15 ">
                                <span class="nucleus-table-header">{""}</span>
                                <a class={ "nucleus-font-small " + stateSortingStyles.onlineDateDesc }  onClick={()=>handleSortingMode("onlineDateAsc","onlineDateDesc")}> &nbsp;▼</a>
                                <a class={ "nucleus-font-small " + stateSortingStyles.onlineDateAsc }   onClick={()=>handleSortingMode("onlineDateDesc","onlineDateAsc")} >&nbsp;▲</a>
                            </th>
                            */}
                        </tr>
                    </thead>
                    {
                        (!stateLoading) &&
                        getTableRows()
                    }

                    {
                        (stateLoading) &&
                        <td class="nucleus-row center-align" rowSpan={3} colSpan={5}>
                            <div style={{marginTop:120}}>
                                <SpinnerCircular
                                    color="#2096F3"
                                    secondaryColor='rgba(0,0,0,0.16)'
                                    size='50'
                                    thickness='100'
                                    />
                            </div>
                        </td>
                    }
                </table>
            </div>

            <div class="fixed-action-btn btn-add-new">
                <a
                    class="btn-floating btn-large waves-effect waves-light orange tooltipped"
                    data-position="left"
                    data-delay="50"
                    data-tooltip="New User"
                    onClick={()=>{openGroupModal() }}
                >
                    <i class="material-icons">add</i>
                </a>
                &nbsp;&nbsp;
            </div>

            <div id="modalNewGroup" class="modal modalNewGroup">
                <div class="modal-content nucleus-modal-wrapper-scrollable2">
                    <img
                        class="responsive-img img-close-modal"
                        src="img/close_modal.png"
                        onClick={handleCloseAddModal}
                    />
                    <h3 class="nucleus-page-subtitle"> New Accounts Group </h3>
                    <br/><br/>
                    <div class="row no-margin">
                        <div class="col s3">
                            <span class="nucleus-labels">
                                <b>Name</b>
                            </span>
                        </div>
                        <div class="col s9">
                            <input
                                type="text"
                                value={stateNewGroupInfo.Name}
                                placeholder="Name"
                                class="validate nucleus-search no-margin-bottom"
                                onChange={(e)=>{
                                    //console.log("Value", e.target.value);
                                    let tempData = Object.assign({}, stateNewGroupInfo);
                                    tempData.Name = e.target.value;
                                    setStateNewGroupInfo(tempData);
                                    setStateMissingNameError(false);
                                }}
                                //disabled={!stateEditUserAllowed}
                            />
                            <br/>
                            {
                                (stateMissingNameError) &&
                                <span class={"nucleus-form-error "}> Please enter Name</span>
                            }
                        </div>
                    </div>
                    <br/>

                    <div class="input-field col s12 modalCallLogContent2">
                        <br/>
                        <div class="row no-margin">
                            <div class="col s12 center-align no-margin">
                                <p></p>
                                <span class=" modalSeparator">
                                    <label> &nbsp;</label>
                                </span>
                                <a
                                    class={"txt-white nucleus-font-small " + stateSaveBtnStyle }
                                    onClick={handleSaveNewGroup}>
                                        {stateSaveButtonLabel}
                                </a>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modalGroupDetails" class="modal modalGroupDetails">
                <div class="modal-content nucleus-modal-wrapper-scrollable2">
                    <img class="responsive-img img-close-modal" src="img/close_modal.png" onClick={handleCloseGroupInfoModal}/>
                    <h3 class="nucleus-page-subtitle"> Account Group: {stateSelectedGroupData.name} </h3>
                    <br/><br/>
                    <div class="row no-margin">
                        <div class="col s6">
                            <span class="nucleus-labels-bold">
                                Accounts
                            </span>
                        </div>
                        <div class="col s6">
                            <span class="nucleus-labels-bold">
                                Admin Users
                            </span>
                        </div>
                    </div>
                    <br/>
                    <div class="row no-margin">
                        <div class="col s6">
                        {
                            (stateSelectedGroupData.accounts && stateSelectedGroupData.accounts.length >= 0) &&
                            stateSelectedGroupData.accounts.map(account => {
                                return (
                                    <div class="no-margin"><span class="nucleus-labels"> {account.AccountName}</span></div>
                                )
                            })
                        }
                        </div>
                        <div class="col s6">
                        {
                            (stateSelectedGroupData.csUsers && stateSelectedGroupData.csUsers.length >= 0) &&
                            stateSelectedGroupData.csUsers.map(csUser => {
                                return (
                                    <span class="nucleus-labels"> {csUser.Email}</span>
                                )
                            })
                        }
                        </div>
                    </div>

                    <br/>
                </div>
            </div>

        </div>
    );
}

export default AccountsGroups;
