import React from 'react';
import ReactDOM from "react-dom";
import Dispatcher from "../Dispatcher";
import { IndexLink, Link } from "react-router-dom";

import Message from "../utils/Message";
import { Redirect } from 'react-router';

import CallRequestLogItemRow from "../components/CallRequestLogItemRow";
import CarePatientStore from "../stores/CarePatientStore";

import AuthStore from "../stores/AuthStore";
import GeneralStore from "../stores/GeneralStore";

import Spinner from 'react-activity/dist/Spinner';

import MomentUtils from '@date-io/moment';
import { 
	KeyboardDatePicker,
	KeyboardDateTimePicker, 
	MuiPickersUtilsProvider 
} from "@material-ui/pickers";
class PatientRequestSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountID: AuthStore.getUserAccountID(),
            patientID: this.props.patientID,

            patientRequestsArray: [],
            loadingRequests: true,

            // reqID : null,
            // reqDateTime : null,
            // reqEmergency : null,
            // reqStatus : null,
            // reqHandledDateTime : null,
            // reqHandler : null,
            // reqEscalated : null, 
            // reqRequests : null,
            // reqNotes : null,

            // reqCallID : null,
            // reqCallDuration : null,
            // reqCallStatus : null, 
            // reqCallParticipants : [], 
            // reqCallNotes : null,

            // saveNoteBtnStyle : 'nucleus-submit-btn',

            datesErrorVisible: ' hide',
            datesErrorText: 'The From Date has to be before the To Date.',

            // newScheduleButtonsStyle : '',

            // selectedScheduleID : '',
            // selectedScheduleSource : '',
            // editScheduleButtonsStyle : '',

            // selectedScheduleData : '',
            // deleteScheduleBtnStyle : 'nucleus-submit-btn',

            // newScheduleItemError : ' hide',
            // editScheduleItemError : ' hide',

            fromDateRequests : null,
            toDateRequests: null,
            patientTimezone: ""
            //pendingScheduleItems : [],
            //updateDefaultScheduleBtnStyle : 'nucleus-submit-btn',
        };

        this.onPatientGetRequestLogsAction = this.onPatientGetRequestLogsAction.bind(this);
        this.handleFilterRequestLogs = this.handleFilterRequestLogs.bind(this);

        //this.onGetCallRequestLogDetailsAction = this.onGetCallRequestLogDetailsAction.bind(this);
        //this.handleCloseModal = this.handleCloseModal.bind(this);

        //this.handleSaveRequestNotes = this.handleSaveRequestNotes.bind(this);
        this.onUpdateCallRequestLogNotesAction = this.onUpdateCallRequestLogNotesAction.bind(this);

        this.loadPatientRequestLogsAction = this.loadPatientRequestLogsAction.bind(this);

        this.dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
        this.timeOptions = { hour12: true, hour: "2-digit", minute: "2-digit" };

        //this.fromDateRequestsRef = React.createRef();
		//this.toDateRequestsRef = React.createRef();
    }

    componentDidMount() {
        CarePatientStore.on("onPatientGetRequestLogs", this.onPatientGetRequestLogsAction);
        //CarePatientStore.on("onGetCallRequestLogDetails", this.onGetCallRequestLogDetailsAction);

        CarePatientStore.on("onUpdateCallRequestLogNotes", this.onUpdateCallRequestLogNotesAction);
        CarePatientStore.on("loadPatientRequestLogs", this.loadPatientRequestLogsAction);

        // CarePatientStore.on("onOpenRemoveScheduleModal", this.onOpenRemoveScheduleModalAction);
        // CarePatientStore.on("onPatientScheduleRemoved", this.onPatientScheduleRemovedAction);

        // CarePatientStore.on("onDefaultScheduleCopied", this.onDefaultScheduleCopiedAction);
        // CarePatientStore.on("onModifiedDefaultScheduleCopied", this.onModifiedDefaultScheduleCopiedAction);
        // CarePatientStore.on("onModifiedPatientScheduleAdded", this.onModifiedPatientScheduleAddedAction);
        // CarePatientStore.on("onFilteredDefaultScheduleCopied", this.onFilteredDefaultScheduleCopiedAction);

        let currentDate = new Date();
        let passedDate = new Date();

        passedDate.setDate(passedDate.getDate() - 8);
        passedDate.setHours(0);
        passedDate.setMinutes(0);
        passedDate.setSeconds(0);

        currentDate.setHours(23);
        currentDate.setMinutes(59);
        currentDate.setSeconds(0);

        console.log("RequestsLogs.js", "fromDate " + passedDate);
        console.log("RequestsLogs.js", "toDate " + currentDate);
        this.state.fromDateRequests = new Date(passedDate); //passedDate.toLocaleDateString('en-US', this.dateOptions); // + " " + fromDate.toLocaleTimeString('en-US', this.timeOptions);
        this.state.toDateRequests = new Date(currentDate); //.toLocaleDateString('en-US', this.dateOptions); // + " " + toDate.toLocaleTimeString('en-US', this.timeOptions);

        passedDate.setHours(passedDate.getHours() + (this.props.currentOffset * -1));
        currentDate.setHours(currentDate.getHours() + (this.props.currentOffset * -1));

        let fromParamString = passedDate.toLocaleDateString('en-US', this.dateOptions) + " " + passedDate.toLocaleTimeString('en-US', this.timeOptions);
        let toParamString = currentDate.toLocaleDateString('en-US', this.dateOptions) + " " + currentDate.toLocaleTimeString('en-US', this.timeOptions);

        CarePatientStore.getPatientCallRequestLogs({
            PatientID: this.props.patientID,
            From: fromParamString,
            To: toParamString
        });

        this.setState({
            loadingRequests: true
        });
    }

    componentWillUnmount() {

        CarePatientStore.removeListener("onPatientGetRequestLogs", this.onPatientGetRequestLogsAction);
        //CarePatientStore.removeListener("onGetCallRequestLogDetails", this.onGetCallRequestLogDetailsAction);

        CarePatientStore.removeListener("onUpdateCallRequestLogNotes", this.onUpdateCallRequestLogNotesAction);
        CarePatientStore.removeListener("loadPatientRequestLogs", this.loadPatientRequestLogsAction);

        // CarePatientStore.removeListener("onOpenRemoveScheduleModal", this.onOpenRemoveScheduleModalAction);
        // CarePatientStore.removeListener("onPatientScheduleRemoved", this.onPatientScheduleRemovedAction);

        // CarePatientStore.removeListener("onDefaultScheduleCopied", this.onDefaultScheduleCopiedAction);
        // CarePatientStore.removeListener("onModifiedDefaultScheduleCopied", this.onModifiedDefaultScheduleCopiedAction);
        // CarePatientStore.removeListener("onModifiedPatientScheduleAdded", this.onModifiedPatientScheduleAddedAction);

        // CarePatientStore.removeListener("onFilteredDefaultScheduleCopied", this.onFilteredDefaultScheduleCopiedAction);
        // //window.$('.tooltipped').tooltip('remove');
    }

    // componentWillReceiveProps(){  
    // }

    // componentWillUpdate(){
    // }

    // componentDidUpdate(){
    //     console.log("Config");
    //     if (this.props.enableBiometrics && this.props.enableBiometrics.toString() == "true"){
    //         this.loadBiometrics();
    //     } 
    // }

    loadPatientRequestLogsAction() {
        this.handleFilterRequestLogs();
    }

    handleFilterRequestLogs() {
        console.log("handleFilterRequestLogs", this.props.currentOffset);

        let fromDateTimeLogs = new Date(this.state.fromDateRequests);
        let toDateTimeLogs = new Date(this.state.toDateRequests);

        if (toDateTimeLogs < fromDateTimeLogs) {
            this.setState({
                datesErrorVisible: ' ',
                datesErrorText: ' The From Date has to be before the To Date.'
            });
            return;
        }

        fromDateTimeLogs.setHours(0);
        fromDateTimeLogs.setMinutes(0);
        fromDateTimeLogs.setSeconds(0);

        fromDateTimeLogs.setHours(fromDateTimeLogs.getHours() + (this.props.currentOffset * -1));

        toDateTimeLogs.setHours(23);
        toDateTimeLogs.setMinutes(59);
        toDateTimeLogs.setSeconds(0);

        toDateTimeLogs.setHours(toDateTimeLogs.getHours() + (this.props.currentOffset * -1));

        let fromParam = fromDateTimeLogs.toLocaleDateString('en-US', this.dateOptions) + " " + fromDateTimeLogs.toLocaleTimeString('en-US', this.timeOptions);
        let toParam = toDateTimeLogs.toLocaleDateString('en-US', this.dateOptions) + " " + toDateTimeLogs.toLocaleTimeString('en-US', this.timeOptions);

        console.log("handleFilterRequestLogs", "fromDate " + fromParam);
        console.log("handleFilterRequestLogs", "toDate " + toParam);

        CarePatientStore.getPatientCallRequestLogs({
            PatientID: this.props.patientID,
            From: fromParam,
            To: toParam
        });

        this.setState({
            loadingRequests: true
        });
    }

    onPatientGetRequestLogsAction(response) {
        console.log("patientRequest:onPatientGetRequestLogsAction", response);

        this.setState({
            patientRequestsArray: [],
            loadingRequests: false
        });

        if (response.ok) {
            this.setState({
                patientRequestsArray: response.RequestsLogs,
                patientTimezone: response.PatientTimeZone
            });
        } else {
            console.log("Error getting " + AuthStore.getPatientLabel().toLowerCase() + " Request Call Logs");
        }
    }

    // onGetCallRequestLogDetailsAction(requestDetails){
    //     console.log("onGetCallRequestLogDetailsAction", requestDetails );

    //     let callStatus = "";
    //     let callDuration = "";

    //     if (requestDetails.CallLogData.ID != null){

    //         callStatus = requestDetails.CallLogData.Status; //requestDetails.CallLogData.WasAnswered ? "Connected" : "Missed";

    //         let callTimeSeconds = "";
    //         // if (requestDetails.CallLogData.InProgress){

    //         //     callStatus = "In Progress";
    //         // }
    //         // else 

    //         if (requestDetails.CallLogData.WasAnswered){

    //             // get total seconds between the times
    //             let delta = Math.abs(new Date(requestDetails.CallLogData.EndTime) - new Date(requestDetails.CallLogData.StartTime)) / 1000;

    //             // calculate (and subtract) whole days
    //             let days = Math.floor(delta / 86400);
    //             delta -= days * 86400;

    //             // calculate (and subtract) whole hours
    //             let hours = Math.floor(delta / 3600) % 24;
    //             delta -= hours * 3600;

    //             // calculate (and subtract) whole minutes
    //             let minutes = Math.floor(delta / 60) % 60;
    //             delta -= minutes * 60;

    //             // what's left is seconds
    //             let seconds = Math.floor(delta % 60); 

    //             let duration = this.pad2(hours) + ":" + this.pad2(minutes) + ":" + this.pad2(seconds);

    //             callDuration = duration;
    //         }
    //     }

    //     let isAutor = true;
    //     if (requestDetails.NotesUserID && requestDetails.NotesUserID.toUpperCase() != AuthStore.getUserID().toUpperCase()){
    //         isAutor = false;
    //     }

    //     this.setState({
    //         reqID : requestDetails.ID,
    //         reqDateTime : requestDetails.RequestDateTime,
    //         reqEmergency : requestDetails.Emergency ?  "Y" : "N",
    //         reqStatus : requestDetails.Status,
    //         reqHandledDateTime : requestDetails.HandledDateTime,
    //         reqHandler : requestDetails.HandlerName,
    //         reqEscalated : requestDetails.Escalated, 
    //         reqRequests : requestDetails.Num,
    //         reqNotes : requestDetails.Notes,

    //         reqCallID : requestDetails.CallLogData.ID,
    //         reqCallDuration : callDuration,
    //         reqCallStatus : callStatus, 
    //         reqCallParticipants : requestDetails.CallLogData.ID != null ? requestDetails.CallLogData.Participants : [], 
    //         reqCallNotes : requestDetails.CallLogData.ID != null ? requestDetails.CallLogData.CallLogNotes : "",


    //         saveNoteBtnStyle : isAutor ? 'nucleus-submit-btn' : 'nucleus-submit-btn-disabled',
    //     });

    //     this.refs.textCallRequestLogNotes.value = requestDetails.Notes;
    //     window.$('#modalRequestDetails').modal('open');
    // }


    // handleCloseModal(){
    //     window.$('#modalRequestDetails').modal('close');
    // }


    // handleSaveRequestNotes(){
    //     console.log("handleSaveRequestNotes", this.state.reqID, this.refs.textCallRequestLogNotes.value);

    //     CarePatientStore.updateCallRequestLogNotes({
    //         CallRequestLogID : this.state.reqID,
    //         Notes: this.refs.textCallRequestLogNotes.value,
    //         UserID : AuthStore.getUserID()
    //     });

    //     this.setState({
    //         saveNoteBtnStyle : 'nucleus-submit-btn-disabled'
    //     });
    // }

    onUpdateCallRequestLogNotesAction(response) {

        // this.setState({
        //     saveNoteBtnStyle : 'nucleus-submit-btn'
        // });

        if (response.ok) {

            this.handleFilterRequestLogs();

            Message.show("Call Request Log Note updated!");
            //window.$('#modalRequestDetails').modal('close');

        } else {
            //window.$('#modalRequestDetails').modal('close');
            Message.show("There was a problem updating the call Request log note");
        }
    }

    // pad2(number) {
    //     return (number < 10 ? '0' : '') + number;
    // }


    render() {

        let requestLogsList = [];

        if (this.state.patientRequestsArray != null && this.state.patientRequestsArray.length > 0) {

            this.state.patientRequestsArray.map((reqLog, key) => {

                requestLogsList.push(
                    <CallRequestLogItemRow
                        key={key}
                        ID={reqLog.ID}
                        PatientTimeZone={this.state.patientTimezone}
                        RequestTime={reqLog.RequestTime}
                        IsEmergency={reqLog.IsEmergency}
                        Status={reqLog.Status}
                        HandledTime={reqLog.HandledTime}
                        HandlerName={reqLog.HandledByFirstName + " " + reqLog.HandledByLastName}
                        EscalationCallRequestSent={reqLog.EscalationCallRequestSent}
                        NumOfRequests={reqLog.NumOfRequests}
                        HasNotes={reqLog.HasNotes}
                    />
                );
            });
        }

        let logsTable = '';
        let logsLoading = 'hide';

        if (this.state.loadingRequests) {
            logsTable = ' hide';
            logsLoading = ' ';
        }



        return (

            <div class="">

                <div class="row  ">
                    &nbsp;&nbsp;
                    <span class="nucleus-labels nucleus-bold">From:</span> &nbsp;
                    {/* <input 
                        id="fromDateRequests" 
                        ref="fromDateRequests" 
                        type="text" 
                        class="validate nucleus-date-picker-short width90" /> */}
                    <div style={{width:140, display:'inline-flex'}}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                //ref={this.fromDateRequestsRef} 
                                variant="dialog"
                                class="browser-default"
                                value={this.state.fromDateRequests}
                                onChange={date => { 
                                    this.setState({
                                        fromDateRequests : date,
                                        datesErrorVisible: " hide",
                                    });
                                }}
                                autoOk={true}
                                onError={console.log}
                                //minDate={new Date("2018-01-01T00:00")}
                                format={"MM/DD/YYYY"}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <span class="nucleus-labels nucleus-bold">To:</span> &nbsp;
                    {/* <input 
                        id="toDateRequests" 
                        ref="toDateRequests" 
                        type="text" 
                        class="validate nucleus-date-picker-short width90" /> */}
                    <div style={{width:140, display:'inline-flex'}}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                //ref={this.toDateRequestsRef} 
                                variant="dialog"
                                class="browser-default"
                                value={this.state.toDateRequests}
                                onChange={date => { 
                                    this.setState({
                                        toDateRequests : date,
                                        datesErrorVisible: " hide",
                                    });
                                }}
                                autoOk={true}
                                onError={console.log}
                                minDate={(new Date(this.state.fromDateRequests)).setDate(new Date(this.state.fromDateRequests).getDate() + 2)}
                                format={"MM/DD/YYYY"}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <a class={"txt-white nucleus-font-small nucleus-submit-btn-small"} onClick={this.handleFilterRequestLogs}>Filter Results</a>&nbsp;
                    <br />
                    <span class={"nucleus-form-error center-align " + this.state.datesErrorVisible}>{this.state.datesErrorText}</span>
                </div>
                <br />
                <div class={"assignments-table-pending " + logsTable}>
                    <table class="bordered highlight  nucleus-table" >
                        <thead>
                            <tr>
                                <th class="table-col-20"><span class="nucleus-table-header-small">Request Date & Time</span></th>
                                <th class="table-col-10 center-align"><span class="nucleus-table-header-small">Emergency</span></th>
                                <th class="table-col-10 left-align"><span class="nucleus-table-header-small">Status</span></th>
                                <th class="table-col-20 left-align"><span class="nucleus-table-header-small">Handled On</span></th>
                                <th class="table-col-15 left-align"><span class="nucleus-table-header-small">Handled By</span></th>
                                <th class="table-col-10 center-align"><span class="nucleus-table-header-small">Escalated</span></th>
                                <th class="table-col-10 center-align"><span class="nucleus-table-header-small">Requests</span></th>
                                <th class="table-col-5 right-align"><span class="nucleus-table-header-small">Notes&nbsp;</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestLogsList}
                        </tbody>
                    </table>
                </div>

                <div class={"row center-align " + logsLoading}>
                    <br /><br /><br />
                    <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loadingRequests} style={{ display: 'inline-flex' }} />
                    <br /><br /><br />
                </div>

            </div>

        );
    }
}

export default PatientRequestSection;
