import React from "react";
import MDMNucleusCheckBox from "../../components/MDMNucleuCheckBox";
import { useDispatch, useSelector } from "react-redux";
import {
  addPropertyOnPluggedModes,
  removePropertyOnPluggedModes,
} from '@nucleus-care/nucleuscare-backend-client';
import MDMNucleusLabelInput from "../../components/MDMLabelInput";
const Screen = ({ operationType, policyDetails }) => {
  const { policyJSON, policyFormStatus } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.state
  );
  const dispatch = useDispatch();
  const handleCheckBox = (e, mode) => {
    if (e) {
      dispatch(addPropertyOnPluggedModes(mode));
    } else {
      dispatch(removePropertyOnPluggedModes(mode));
    }
  };

  return (
    <div className="nucleus-all-scrollable-page">
      <span className="nucleus-tools-page-title"></span>
      <div className="nucleus-tools-page-content">
        <div className="row">
          <div className="nucleus-mb-1">
            <MDMNucleusCheckBox
              name="keyguardDisabled"
              label={"Disable Lock Screen:"}
              policyDetails={policyDetails}
              operationType={policyFormStatus.operationType}
            />
          </div>
          <div
            style={{ display: "flex", width: "fit-content" }}
            className="screenCounterContainer nucleus-mb-1"
          >
            <MDMNucleusLabelInput
              hideClear={!("maximumTimeToLock" in policyJSON)}
              label1="Lock Screen after"
              label2="seconds of inactivity (0 = never)"
              className="text-center-align"
              type="number"
              name="maximumTimeToLock"
            />
          </div>
          <div className="screenPluggedContainer nucleus-mb-1">
            <label className="mdmText" style={{ minWidth: 150 }}>
              Stay on when plugged into:
            </label>
            <div className="screenPluggedCheckBoxContainer">
              <div className="nucleus-mb-1">
                <MDMNucleusCheckBox
                  value={!!policyJSON?.stayOnPluggedModes?.includes("AC")}
                  activeBadge={!!policyJSON?.stayOnPluggedModes?.includes("AC")}
                  objectValue={"stayOnPluggedModes"}
                  onClickClear={() => {
                    dispatch(removePropertyOnPluggedModes("AC"));
                  }}
                  setPropertyValue={(e) => {
                    if (e) {
                      handleCheckBox(e, "AC");
                    } else {
                      dispatch(removePropertyOnPluggedModes("AC"));
                    }
                  }}
                  name="AC"
                  label={"AC Charger:"}
                  policyDetails={policyDetails}
                  operationType={policyFormStatus.operationType}
                />
              </div>
              <div className="nucleus-mb-1">
                <MDMNucleusCheckBox
                  value={!!policyJSON?.stayOnPluggedModes?.includes("USB")}
                  activeBadge={
                    !!policyJSON?.stayOnPluggedModes?.includes("USB")
                  }
                  objectValue={"stayOnPluggedModes"}
                  onClickClear={() => {
                    dispatch(removePropertyOnPluggedModes("USB"));
                  }}
                  setPropertyValue={(e) => {
                    if (e) {
                      handleCheckBox(e, "USB");
                    } else {
                      dispatch(removePropertyOnPluggedModes("USB"));
                    }
                  }}
                  name="USB"
                  label={"USB Port:"}
                  policyDetails={policyDetails}
                  operationType={policyFormStatus.operationType}
                />
              </div>
              <div className="nucleus-mb-1">
                <MDMNucleusCheckBox
                  value={!!policyJSON?.stayOnPluggedModes?.includes("WIRELESS")}
                  activeBadge={
                    !!policyJSON?.stayOnPluggedModes?.includes("WIRELESS")
                  }
                  objectValue={"stayOnPluggedModes"}
                  onClickClear={() => {
                    dispatch(removePropertyOnPluggedModes("WIRELESS"));
                  }}
                  setPropertyValue={(e) => {
                    if (e) {
                      handleCheckBox(e, "WIRELESS");
                    } else {
                      dispatch(removePropertyOnPluggedModes("WIRELESS"));
                    }
                  }}
                  name="WIRELESS"
                  label={"Wireless Charging:"}
                  policyDetails={policyDetails}
                  operationType={policyFormStatus.operationType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen;
