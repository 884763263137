import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import AuthStore from "../stores/AuthStore";
import CareAccountStore from "../stores/CareAccountStore";
import AccountItemRow from "../components/AccountItemRow";

import Dispatcher from "../Dispatcher";
import { IndexLink, Link } from "react-router-dom";
import Message from "../utils/Message";

import { SpinnerCircular } from 'spinners-react';

class Accounts extends React.Component {
  constructor() {
    super();

    this.state = {
      accountsArray: [],
      enterprisesArray:[],
      newAccountEnterpriseID:null,
      btnStyle: " nucleus-submit-btn-small-disabled",
      loading:false,
      searchText: ""
    };

    this.handleSearchText = this.handleSearchText.bind(this);

    this.onGetAllAccountsDataAction = this.onGetAllAccountsDataAction.bind(this);
    this.onEnterprisesDataAction = this.onEnterprisesDataAction.bind(this);

    this.handleNewAccountModal = this.handleNewAccountModal.bind(this);

    this.handleCloseNewAccountModal = this.handleCloseNewAccountModal.bind(this);
    this.checkNewAccountName = this.checkNewAccountName.bind(this);

    this.handleChangeAccountEnterprise = this.handleChangeAccountEnterprise.bind(this);

    this.handleSubmitNewAccount = this.handleSubmitNewAccount.bind(this);
    this.onCreateAccountActionAction = this.onCreateAccountActionAction.bind(this);

    this.generateAccountRows = this.generateAccountRows.bind(this);

    this.renderSelectRows = this.renderSelectRows.bind(this);
  }

  componentDidMount() {

    this.setState({
      loading:true
    });
     //Get all the Accounts Array Data from WS
    CareAccountStore.getAllAccountsData({
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
    });

    CareAccountStore.getEnterprisesData();

    //Register Listener
    CareAccountStore.on("onGetAllAccountsData",this.onGetAllAccountsDataAction);
    CareAccountStore.on("onEnterprisesData",this.onEnterprisesDataAction);
    CareAccountStore.on("onCreateAccountAction",this.onCreateAccountActionAction);
    

    window.$(".tooltipped").tooltip({ delay: 50 });
    window.$(".modal").modal({
      dismissible: true,
      complete: function () {},
    });

    setTimeout(() => {
			CareAccountStore.getAccountGroupsData();
		}, 400);

		// setTimeout(() => {
		// 	CareAccountStore.getUpdateGroupsData();
		// }, 800);

		setTimeout(() => {
			
      //CareAccountStore.getLocalesData();  
      //Not needed, currently the locales are fetched according to the config of every account
			
      CareAccountStore.getAccountsCountriesData();
			CareAccountStore.getTemplatesData();
		}, 1200);

    try {
      (this.refs.txtAccountSearch) && this.refs.txtAccountSearch.focus();
    } catch (error) {
      console.log("Cs Accounts Search input focus");
    }
    // this.setState({
    //   accountsArray: CareAccountStore.getAllAccounts(),
    // });
  }

  componentWillUnmount() {
    //Remove Listener
    CareAccountStore.removeListener("onGetAllAccountsData", this.onGetAllAccountsDataAction);
    CareAccountStore.removeListener("onEnterprisesData", this.onEnterprisesDataAction);
    CareAccountStore.removeListener("onCreateAccountAction", this.onCreateAccountActionAction);

    window.$(".tooltipped").tooltip("remove");
  }

  onEnterprisesDataAction(response){
    console.log("onEnterprisesDataAction", response);
    if (response.ok){
      this.setState({
        enterprisesArray : response.Enterprises
      });
    } else{
      Message.warning("Error getting info.");
    }
  }

  onGetAllAccountsDataAction(success) {
    this.setState({
      loading:false
    });
    if (success) {
      this.state.accountsArray = [];
      this.setState({
        accountsArray: CareAccountStore.getAllAccounts(),
      });
    } else {
      Message.show(
        "Sorry, There was a problem getting the accounts information. Please try again."
      );
    }
  }

  handleNewAccountModal() {
    console.log("handleNewAccountModal");
    if (!this.state.enterprisesArray || this.state.enterprisesArray.length == 0){
      CareAccountStore.getEnterprisesData();
    }
    window.$("#modalNewAccount").modal("open");

    this.refs.txtNewAccountName.value = "";
    this.refs.selectAccountEnterprise.value = "";
    this.setState({
      newAccountEnterpriseID:null, 
      btnStyle: " nucleus-submit-btn-small-disabled",
    });
    this.refs.txtNewAccountName.focus();
  }

  handleCloseNewAccountModal() {
    window.$("#modalNewAccount").modal("close");
  }

  checkNewAccountName() {
    if (this.refs.txtNewAccountName.value.length > 0 && this.state.newAccountEnterpriseID != null) {
      this.setState({
        btnStyle: " nucleus-submit-btn-small",
      });
    } else {
      this.setState({
        btnStyle: " nucleus-submit-btn-small-disabled",
      });
    }
  }

  handleChangeAccountEnterprise(e){
    console.log("handleChangeAccountEnterprise", e.target.value);
    this.setState({
      newAccountEnterpriseID:e.target.value
    });
    if (this.refs.txtNewAccountName.value.length > 0) {
      this.setState({
        btnStyle: " nucleus-submit-btn-small",
      });
    } else {
      this.setState({
        btnStyle: " nucleus-submit-btn-small-disabled",
      });
    }
  }

  handleSubmitNewAccount() {
    this.setState({
      btnStyle: " nucleus-submit-btn-small-disabled",
    });

    CareAccountStore.createAccount({
      Name: this.refs.txtNewAccountName.value,
      EnterpriseID : this.state.newAccountEnterpriseID
    });
  }

  onCreateAccountActionAction(response) {
    console.log("onCreateAccountActionAction");
    console.log(response);

    this.handleCloseNewAccountModal();
    if (response.ok) {
      Message.show("New Account created");
      CareAccountStore.getAllAccountsData({
        UserID: AuthStore.getCsUserID(),
        Token: AuthStore.getCsUserToken(),
      });
    } else {
      Message.show(
        "Sorry, There was a problem creating the new account. Please try again."
      );
    }
  }

  generateAccountRows() {
      let textToSearch = this.state.searchText?.toLowerCase();
      let accountsList = [];
      if (this.state.accountsArray != null) {
        this.state.accountsArray.forEach((account, key) => {
          let accountName = account.Name.toLowerCase();
          let accountGroupName = account.AccountGroupName?.toLowerCase() || "";
          if (textToSearch.length === 0 || accountName.includes(textToSearch) || accountGroupName.includes(textToSearch)) {
            accountsList.push(
              <AccountItemRow
                key={key}
                Logo={account.Logo}
                ID={account.ID}
                Name={account.Name}
                AccountGroupName={account.AccountGroupName}
                UpdateGroupID={account.UpdateGroupID}
                TotalPatients={account.TotalPatients}
                TotalBiometricPatients={account.TotalBiometricPatients}
                TotalDevices={account.TotalDevices}
                TotalOnlineDevices={account.TotalOnlineDevices}
                TotalUsers={account.TotalUsers}
                DaysSinceCreated={account.DaysSinceCreated}
              />,
            );
          }
        });
      }
      return accountsList;
  }

  handleSearchText(e) {
		this.setState({
			searchText: e.target.value,
		});
	}

  renderSelectRows(){
    let selectEnterpriseOptions = [];
    if (this.state.enterprisesArray && this.state.enterprisesArray.length > 0){
      this.state.enterprisesArray.forEach((enterprise)=>{
        //console.log("renderSelectRows generating row for:", enterprise);
        selectEnterpriseOptions.push(
          <option value={enterprise.ID.toLowerCase()}>{enterprise.OrganizationName}</option>
        );
      })
    }
    return selectEnterpriseOptions;
  }

  render() {

    return (
      <div class="nucleus-tools-container-accounts">
        <div>
          <p class="nucleus-tools-page-title">
            <b>Accounts</b>
          </p>
          <div class="row no-margin">
            <div class="col s6 m6 no-margin left-align">
              <span class="nucleus-tools-page-text-title a-bit-lower">
                Search&nbsp;&nbsp;
              </span>
              <input
                ref="txtAccountSearch"
                type="text"
                class="nucleus-input-form-big-left"
                onChange={this.handleSearchText}
                placeholder={"Filter by Account Name / Account Group"}
                style={{ width:350}}
              />
            </div>
            <div class="col s6 m6 no-margin"></div>
          </div>
          <br/>
        </div>
        <div class="row nucleus-fixed-container nucleus-table-container-s-90">
          <style>
            {`
                .nucleus-table-container-s-90{
                    /*overflow: scroll;*/
                    overflow-y: scroll;
                }
            `}
          </style>
          <table class="b-table b-table-hover b-table-active" style={{}}>
            <thead class="sticky-thead">
              <tr>
                <th class="b-col-2">
                  <span class="nucleus-table-header">Account Group</span>
                </th>
                <th class="b-col-2">
                  <span class="nucleus-table-header">Account Name</span>
                </th>
                <th class="b-col-2 center-align">
                  <span class="nucleus-table-header">Residents</span>
                </th>
                <th class="b-col-2 center-align">
                  <span class="nucleus-table-header">Total Devices</span>
                </th>
                <th class="b-col-2 center-align">
                  <span class="nucleus-table-header">Online Devices</span>
                </th>
                <th class="b-col-2 center-align">
                  <span class="nucleus-table-header">Biometric Residents</span>
                </th>
                <th class="b-col-2 center-align">
                  <span class="nucleus-table-header">Users</span>
                </th>
              </tr>
            </thead>
            <tbody>
            {
                (!this.state.loading) &&
                this.generateAccountRows()
            }

            {
                (this.state.loading) &&
                <td class="nucleus-row center-align" rowSpan={3} colSpan={7}>
                    <div style={{marginTop:120}}>
                        <SpinnerCircular
                            color="#2096F3"
                            secondaryColor='rgba(0,0,0,0.16)'
                            size='50'
                            thickness='100'
                            />
                    </div>
                </td>
            }
            </tbody>
          </table>

          {
            (AuthStore.superUser() &&
            <div class="fixed-action-btn btn-add-new">
              <a
                class="btn-floating btn-large waves-effect waves-light orange tooltipped"
                data-position="left"
                data-delay="50"
                data-tooltip="New Account"
                onClick={this.handleNewAccountModal}
              >
                <i class="material-icons">add</i>
              </a>
              &nbsp;&nbsp;
            </div>
            )
          }

        </div>

        <div id="modalNewAccount" class="modal modalNewAccount">
          <div class="modal-content modal-content-delete center-align">
            <h3 class="nucleus-page-subtitle"> Create New Account</h3>
            <br />
            <br />
            <br />
            <div class="row left-align no-margin">
              <div class="col s2">
              </div>
              <div class="col s4 left-align">
                <span class="nucleus-labels">
                  <b>Account Name</b>
                </span>
              </div>
              <div class="col s6 left-align">
                <input
                  id="txtNewAccountName"
                  ref="txtNewAccountName"
                  type="text"
                  class="validate nucleus-search"
                  onChange={this.checkNewAccountName}
                  placeholder="Account name..."
                />
              </div>
            </div>
            <div>
              <div class="col s2">
              </div>
              <div class="col s4 left-align">
                <span class="nucleus-labels">
                  <b>Enterprise</b>
                </span>
              </div>
              <div class="col s6 left-align">
                <select
                  ref="selectAccountEnterprise"
                  class="browser-default accountSelect"
                  onChange={this.handleChangeAccountEnterprise}
                >
                  <option value={""} selected disabled>{"Select an Enterprise"}</option>
                  {this.renderSelectRows()}
								</select>     
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <div class="row ">
              <div class="col s12 center-align ">
                <div class="col s6 right-align">
                  <a
                    class="txt-white nucleus-font-small nucleus-link"
                    onClick={this.handleCloseNewAccountModal}
                  >
                    Cancel
                  </a>
                </div>
                <div class="col s6 left-align">
                  <a class={"txt-white nucleus-font-small " + this.state.btnStyle }
                    onClick={this.handleSubmitNewAccount}
                  >
                    Confirm
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Accounts;
