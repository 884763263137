import styled from 'styled-components';

const CheckSVG = ({ color = "#0092FF" }) => {
    return (
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z" fill={color} />
        </svg>
    );
};


const SwitchContainer = styled.div`
  height: 30px;
  width: 50px;
  border-radius: 100px;
  padding: 2px;
  transition: background-color 0.1s;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const SwitchIndicator = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${({ color }) => color};;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: transform 0.1s;
`;

export const UISwitch = ({ disabled, isEnabled = true, onToggle, checkColor = '#0092FF', uncheckColor = '#fff', hideCheck }) => {
    const onClick = () => {
        if (disabled) return;
        onToggle();
    }
    return (
        <SwitchContainer style={{
            backgroundColor: isEnabled ? checkColor : uncheckColor,
        }} onClick={onClick}>
            <SwitchIndicator style={{
                transform: isEnabled ? 'translateX(20px)' : 'translateX(2px)'
            }} color={isEnabled ? 'white' : '#B4C6D3'}>
                {hideCheck ? "" : isEnabled && <CheckSVG color={isEnabled ? checkColor : uncheckColor} />}
            </SwitchIndicator>
        </SwitchContainer>
    );
};
