import React, { FC } from "react";
import styled from "styled-components";

interface Props {
    label?: string;
    checked: boolean;
    onClick: (isChecked: boolean) => void;
    disabled?: boolean;
}

interface CheckboxProps {
    tagId: string;
    label?: string;
    checked: boolean;
    onClick: (isChecked: boolean) => void;
}

export const UICheckbox: FC<Props> = ({ disabled, label, checked, onClick, ...props }) => {
    return (
        <Container
            onClick={(e) => {
                e.stopPropagation()
                if (onClick) onClick(!checked)
            }}
        >
            <CheckboxInput
                disabled={disabled}
                onChange={() => { }}
                className="filled-in"
                type="checkbox"
                checked={checked}
                {...props}
            />
            <Label>{label}</Label>
        </Container>
    );
};

export const UICheckboxLine: FC<CheckboxProps> = ({ tagId, label, checked, onClick }) => {

    const inputId = 'Checkbox_' + ((!!tagId) ? tagId.replaceAll(" ", "") : Math.floor(Math.random() * 100));
    const handleCheckboxChange = () => {
        onClick(!checked);
    };

    return (
        <CheckboxContainer>
            <CheckboxLabel>
                <Label>{label}</Label>
            </CheckboxLabel>
            <CheckboxWrapper>
                <Checkbox>
                    <input
                        type="checkbox"
                        id={inputId}
                        className="filled-in"
                        checked={checked}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor={inputId}></label>
                </Checkbox>
            </CheckboxWrapper>
        </CheckboxContainer>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
`;


const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const CheckboxLabel = styled.div`
  flex:1;
  margin: 0;
`;

const CheckboxWrapper = styled.div`
    height: 20px;
    margin: 0;
`;
const Checkbox = styled.span`

`;

const CheckboxInput = styled.input`
    margin-right: 8px;
`;

const Label = styled.label`
    font-size: 16px;
    color: #0A313F;
    font-weight: 300;
`;
