import React, { FC } from "react";
import styled from "styled-components";
import ErrorImg from "../assets/error-icon.svg";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
}

interface TelProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputId: string;
  label: string;
  error?: string;
  info?: string;
  onPhoneChange: (string) => void;
}

export const UIInput: FC<Props> = (props) => {
  const { label, error } = props;

  return (
    <div>
      <Label>{label}</Label>
      <Content>
        <Input
          {...props}
          className={`ui-nucleus ${props.className ? props.className : ""}`}
        />
        {error && <ErrorIcon src={ErrorImg} />}
      </Content>
      <Error visible={!!error}>{error}</Error>
    </div>
  );
};


export const UIWhiteBoxInput: FC<Props> = (props) => {
  const { label, error, required } = props;

  return (
    <div>
      <Label>{label} { required ? <span style={{color:'red'}}>*</span>: <></>}</Label>
      <Content>
        <WhiteBoxInput
          {...props}
          className={`ui-nucleus ${props.className ? props.className : ""}`}
        />
        {error && <WhiteBoxErrorIcon src={ErrorImg} />}
      </Content>
      <WhiteBoxErrorText visible={!!error}>{error}</WhiteBoxErrorText>
    </div>
  );
};

export const UIWhiteBoxTelInput: FC<TelProps> = props => {
  const { inputId, label, error, info, required, onPhoneChange } = props;
  const defaultTelephone: string = props.value ? String(props.value) : '';

  return (
    <div>
      <Label>
        {label} {required ? <span style={{ color: 'red' }}>*</span> : <></>}
      </Label>
      <Content>
        <PhoneInput
          defaultCountry="us"
          preferredCountries={['us']}
          value={defaultTelephone}
          placeholder="201-555-0123"
          inputProps={{ id: inputId }}
          onChange={onPhoneChange}
          style={{
            width:'95%',
            backgroundColor:'white',
            borderRadius:5,
            paddingLeft:5
          }}
          inputStyle={{
            paddingLeft: 10,
            fontSize:16,
            border: 'none',
            paddingTop:6,
            paddingBottom:'9px !important',
          }}
          countrySelectorStyleProps={{
            buttonStyle: {
              backgroundColor: 'transparent',
              border: 'none',
              marginTop:2
            },
          }}
        />
        {error && <WhiteBoxErrorIcon src={ErrorImg} />}
      </Content>
      {<WhiteBoxHelpText visible={!!info}>{info} </WhiteBoxHelpText>}
      <WhiteBoxErrorText visible={!!error}>{error}</WhiteBoxErrorText>
    </div>
  );
};

const WhiteBoxInput = styled.input<{ error?: string }>`
  width: 95% !important;
  margin: 0px !important;
  padding: 4px 10px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: none !important;
  border-color: ${(props: { error?: string }) =>
    props.error ? "#FF4B30" : "#ccc"};
`;

const Input = styled.input<{ error?: string }>`
  width: 100%;
  padding: 16px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  height: 50px;
  box-shadow: none;
  border-color: ${(props: { error?: string }) =>
    props.error ? "#FF4B30" : "#ccc"};
`;

const Label = styled.p`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 16px;
  color: #0a313f;
`;

const Content = styled.div`
  position: relative;
`;

const Error = styled.div<{ visible?: boolean }>`
  color: #fe3824;
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  height: 15px;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 40%;
`;

const WhiteBoxHelpText = styled.div<{ visible?: boolean }>`
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  height: 15px;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const WhiteBoxErrorText = styled.div<{ visible?: boolean }>`
  color: #fe3824;
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  height: 15px;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const WhiteBoxErrorIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 20%;
`;
