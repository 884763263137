import { EventEmitter } from "events";
import { setTokenToHttpClients } from '../utils/get-token'
import { httpApi, setUser, resetUser } from '@nucleus-care/nucleuscare-backend-client'
import { getInstance } from '../utils/auth-client'
import AuthApi from "../apis/AuthApi";

class AuthStore extends EventEmitter {
	private AuthApi: AuthApi;
	private nucleusCsUserID: string | null;
	private nucleusCsUserToken: string | null;
	private nucleusCsUserEmail: string | null;
	private csSuperUser: boolean;
	private deletePatientsAccess: boolean;
	private nucleusAccountID: string | null;
	private nucleusNewSession: boolean;
	private userLabel: string;
	private caregiverLabel: string;
	private patientLabel: string;
	private userLabelPlural: string;
	private caregiverLabelPlural: string;
	private patientLabelPlural: string;
	private familyLabelPlural: string | null;
	private familyLabel: string | null;
	private clientsAccess: boolean;

	constructor() {
		super();

		this.AuthApi = new AuthApi(this, getInstance());
		this.nucleusCsUserID = localStorage.nucleusCsUserID;
		this.nucleusCsUserToken = localStorage.nucleusCsUserToken;
		this.deletePatientsAccess = localStorage.deletePatientsAccess  === "true"  || false;
		this.nucleusCsUserEmail = localStorage.nucleusCsUserEmail;
		this.csSuperUser = (String(localStorage.csSuperUser) === "true") || false;
		this.nucleusAccountID = localStorage.nucleusAccountID;
		this.nucleusNewSession = localStorage.nucleusNewSession === "true" || false;

		this.userLabel = "User";
		this.caregiverLabel = "Caregiver";
		this.patientLabel = "Patient";

		this.userLabelPlural = "Users";
		this.caregiverLabelPlural = "Caregivers";
		this.patientLabelPlural = "Patients";
		this.familyLabelPlural = localStorage.familyLabelPlural || "Family Members";
		this.familyLabel = localStorage.familyLabel || "Family Member";
		this.clientsAccess = localStorage.clientsAccess === "true"  || false;

		console.warn(this);
	}

	getCsUserID() {
		return this.nucleusCsUserID;
	}

	getCsUserToken(){
		return this.nucleusCsUserToken;
	}

	getCsUserEmail() {
		return this.nucleusCsUserEmail;
	}
	superUser() {
		//console.log("AuthStore this.csSuperUser", this.csSuperUser);
		return this.csSuperUser;
	}
	getUserLabel(){
		return this.userLabel;
	}
	getCaregiverLabel(){
		return this.caregiverLabel;
	}
	getPatientLabel(){
		return this.patientLabel;
	}
	getUserLabelPlural(){
		return this.userLabelPlural;
	}
	getFamilyLabel() {
		return this.familyLabel;
	}
	getFamilyLabelPlural() {
		return this.familyLabelPlural;
	}
	getCaregiverLabelPlural(){
		return this.caregiverLabelPlural;
	}
	getPatientLabelPlural(){
		return this.patientLabelPlural;
	}
	canAccessClients() {
		return this.clientsAccess;
	}

	canDeletePatients() {
		//console.log("canDeletePatients", (this.deletePatientsAccess.toString() == "true"));
		return this.deletePatientsAccess;
	}


	getUserAccountID() {
		// NOTE: This is not the accountID of a nucleus account!!!
	 	return this.nucleusAccountID;
	}

	getIsNewSession() {
		this.saveToLocalStorage();
		return this.nucleusNewSession;
	}
	clearIsNewSession() {
		this.nucleusNewSession = false;
		this.saveToLocalStorage();
	}



	login ({Email , Password, dispatch}) {
		//console.log("AuthStore:login", Email, Password );
		this.nucleusCsUserEmail = Email;

		this.AuthApi.login({Email, Password},(rsp) => {

			console.log("Nucleus Login Response");
			//console.log(rsp);
			if (rsp.status === 200) {
				console.log("LOOKING FOR THIS: ", rsp)
				this.nucleusCsUserID = rsp.ID;
				this.nucleusAccountID = rsp.ID;
				this.nucleusCsUserToken = rsp.CacheToken;
				this.csSuperUser = rsp.SuperUser;
				localStorage.setItem("nucleusTokenCs", rsp.Jwt);

				setTokenToHttpClients(rsp.Jwt);
				dispatch(setUser(rsp))
			} else{
				dispatch(resetUser())
			}
			this.emit("onAuthStoreLogin",{
				ok : rsp.status === 200,
				internetError : rsp.errorInternetConnection
			});
		});
	}

	requestResetPassword({ Email }) {
		console.log("AuthStoreCS:requestResetPassword ", Email);

		this.AuthApi.requestResetPassword({Email},(rsp) => {

			console.log("AuthStoreCS:requestResetPassword Response");
			console.log(rsp);
			this.emit("onResetRequestedAction", rsp);
			
		});

		
	}

	resetTempPassword(UserID, callback) {
		console.log("AuthStoreCS:resetTempPassword ", UserID);
		let paramData = {
			userId: UserID
		};
		this.AuthApi.resetTempPassword(paramData, (rsp) => {
			//console.log("AuthStoreCS:resetTempPassword Response", rsp);
			callback && callback(rsp);
		});
	}


	resetPassword({ Email, Token, Password }) {
		console.log("resetPassword2", Email, Token, Password);

		let paramsData = {
			email: Email,
		  password: Password,
		  tempToken: Token,
		};

		this.AuthApi.resetPassword2(paramsData, (rsp) => {
		  console.log("Nucleus Response Reset Cs Password", rsp);
		  let response = Object.assign({}, rsp, {
			ok: rsp.ok
		  });
		  this.emit("onResetPasswordAction", response);
		});
	}

	createNewCsUser(newUserData, responseCallback) {
		console.log("resetPassword2", newUserData, responseCallback);

		this.AuthApi.createNewCsUser(newUserData, (rsp) => {
			console.log("Nucleus Response Create Cs User", rsp);
			let response = Object.assign({}, rsp, {
				ok: rsp.ok
			});
			responseCallback(response);
		});
	}
  	getMe(dispatch) {
		//console.log("/cs_user/me");
		let rsp;
		httpApi
		.get("/cs_user/me")
		.then((response) => {
			const { data } = response;
			rsp = {
			ok: true,
			...data,
			};
		})
		.catch((e) => {
			console.error("/cs_user/me error: ", e)
			const { data } = e.message;
			rsp = {
			ok: false,
			...data,
			};
		})
		.finally(() => {
			if (rsp && rsp.ok) {
				this.nucleusCsUserID = rsp.ID;
				this.nucleusAccountID = rsp.ID;
				this.nucleusCsUserToken = rsp.CacheToken;
				this.familyLabel = rsp.FamilyRoleName || "Family Member";
				this.familyLabelPlural = rsp.FamilyRoleNamePlural || "Family Members";
				this.clientsAccess = rsp.CanSeeClientsTab || true;
				this.deletePatientsAccess = rsp.CanDeletePatients;
				this.saveToLocalStorage()
				dispatch(setUser(rsp))
			}
			else{
				dispatch(resetUser())
			}
			this.emit('onGetMe', rsp)
		})
  	}

	saveToLocalStorage(){
		console.log("saveToLocalStorage");

		localStorage.nucleusCsUserID = this.nucleusCsUserID;
		localStorage.nucleusCsUserToken = this.nucleusCsUserToken;

		localStorage.nucleusCsUserEmail = this.nucleusCsUserEmail;
		localStorage.csSuperUser =this.csSuperUser;
		
		 localStorage.nucleusAccountID = this.nucleusAccountID;
		localStorage.nucleusNewSession = this.nucleusNewSession;
		localStorage.deletePatientsAccess = this.deletePatientsAccess;
		// localStorage.nucleusTemporalPassword = this.nucleusTemporalPassword;

		// localStorage.nucleusCompanyName = this.nucleusCompanyName;
		// localStorage.nucleusCompanyLogo = this.nucleusCompanyLogo;
		localStorage.familyLabel = this.familyLabel;
		localStorage.familyLabelPlural = this.familyLabelPlural;
		localStorage.clientsAccess = this.clientsAccess;
	}

	logout(dispatch){
		console.log("logout");

		this.AuthApi.logout()
			.catch(() => {
			console.log('Error logout request')
		  })
		  .then(() => {
			this.emit("onAuthStateChanged",{
				ok : false
			});
			dispatch(resetUser());


			this.nucleusCsUserID = null;
			this.nucleusCsUserToken = null;
			this.nucleusCsUserEmail = null;
			this.csSuperUser = false;
			this.familyLabel = null;
			this.familyLabelPlural = null;
			this.clientsAccess = false;
			this.deletePatientsAccess = false;
			this.nucleusAccountID = null;
			this.nucleusNewSession = false;
			delete localStorage.nucleusCsUserID ;
			delete localStorage.nucleusCsUserToken;
			delete localStorage.nucleusCsUserEmail;
			delete localStorage.csSuperUser;
			delete localStorage.nucleusAccountID ;
			delete localStorage.nucleusNewSession ;
			localStorage.removeItem('nucleusTokenCs')
			delete localStorage.familyLabel;
			delete localStorage.familyLabelPlural;
			delete localStorage.clientsAccess;
			delete localStorage.deletePatientsAccess;
		})
	}


}

const authStore = new AuthStore();

export default authStore;
