import React from 'react';
import styled from 'styled-components';

interface BasePageTitleProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const BasePageTitleStyled = styled.h1<BasePageTitleProps>`
    //styleName: Header 1;
    font-family: Barlow;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
`;

export const BasePageTitle: React.FC<BasePageTitleProps> = ({ style, children }) => {

    return <BasePageTitleStyled style={style} >{children}</BasePageTitleStyled>
}
