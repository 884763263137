import React from 'react';

const NucleusCard = ({ children, width, style }) => {
 return(
    <div className='nucleusCard' style={{width: width, ...style}}>
        {children}
    </div>
 );
};

export default NucleusCard;