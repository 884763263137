import React, { useState, ChangeEvent, useEffect } from 'react';
import NucleusTable from '../../components/NucleusTable';
import styled from 'styled-components';
import NucleusModalButton from '../../components/NucleusModal/NucleusModalButton';
import { UIModal, UIInput, useModal, UINucleusContainer, UIWhiteBoxInput } from "../../components/UI";
import { ModalAction } from "../../components/UI/Modals/Base/ActionButtons";
import { SpinnerCircular } from 'spinners-react';
import { UINucleusContentContainer } from '../../components/UI/NucleusContainer/Content';
import { UINucleusPageTitle } from '../../components/UI/NucleusPageTitle/Default';
import { useHistory } from 'react-router-dom';
import { IProviderListItem } from '../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { httpApi } from "@nucleus-care/nucleuscare-backend-client";
import Message from '../../utils/Message';
const InputGroup = styled.div`
  margin-bottom: 20px;
`;
const TITLES = {
    add: "Add a New Provider", 
};
const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;
const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;
const BoldNote = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
`;
const Name = styled.p`
  color: #0a313f;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;

const Providers = () => {
    const history = useHistory();
    const columns = [
        {
            Header: "Provider Name",
            accessor: "Name",
        },
        {
            Header: "Number of Accounts",
            accessor: "AccountCount",
            centerAlign: true,
        },
        {
            Header: "Number of Devices",
            accessor: "DevicesCount",
            centerAlign: true,
        },
        {
            Header: "Number of Users",
            accessor: "UsersCount",
            centerAlign: true,
        },
        {
            Header: " ",
            accessor: "delete",
            show: true,
            disableSortBy: true,
            Cell: ({row}) => {
                return (
                    <img src='img/delete-bin.png' className='nucleus-clickable' onClick={(e) => {
                            e.stopPropagation();
                                handleOpenDeleteModal(row.original?.ID, row.original?.Name, row.original?.AccountCount, row.original?.UsersCount);
                            }
                        } 
                    />
                )
            }
        }
    ];

    const [providersData, setProvidersData] = useState<IProviderListItem[]>([])
    const [addProviderModalOpen, setAddProviderModalOpen] = useState(false);
    const [deleteProviderModalOpen, setDeleteProviderModalOpen] = useState(false);
    const [mode, setMode] = useState("add");
    const [providerId, setProviderId] = useState<string>("");
    const [providerName, setProviderName] = useState<string>("");
    const [accountsCount, setAccountsCount] = useState<number>(0)
    const [usersCount, setUsersCount] = useState<number>(0)

    const [title, setTitle] = useState("");
    const [saveProviderError, setSaveProviderError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const {
        isOpen: isDeleteOpen,
        openModal: openDeleteModal,
        closeModal: closeDeleteModal,
    } = useModal();

    useEffect(() => {
        getProviders();
    }, [])

    const getProviders = () => {
        setIsLoading(true);
        httpApi
      .get("/providers")
      .then(({ data }) => {
        if (data) {
            setProvidersData(data);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    }
    const handleRowClick = ({ row }) => {
        const { ID } = row.original;
        history.push(`/providerDetails/${ID}/settings`);
    };
    const handleOpenDeleteModal = (providerId: string, providerName: string, accountCount: number, usersCount: number) => {
        setProviderId(providerId);
        setProviderName(providerName);
        setAccountsCount(accountCount);
        setUsersCount(usersCount);
        setMode("delete");
        setDeleteProviderModalOpen(true);
    }
    const handleOpenModal = () => {
        setMode("add");
        setAddProviderModalOpen(true);
    }
    const handleCloseModal = () => {
        setAddProviderModalOpen(false);
        setDeleteProviderModalOpen(false);
        setTitle("");
        setMode("")
        setSaveProviderError("");
    };

    const handleCloseDeleteModal = () => {
        setDeleteProviderModalOpen(false);
        setProviderId("");
        setProviderName("");
        setAccountsCount(0);
        setUsersCount(0);
        setTitle("");
        setSaveProviderError("");
    };

    const handleAddProvider = () => {
        setIsLoading(true);
        httpApi
      .post("/providers", {
        name: title,
        defaultScheduleSlotLengthInMinutes: 20,
      })
      .then(({ data }) => {
        setIsLoading(false);
        handleCloseModal();
        getProviders();
        if (data.id) {
          Message.show("New Provider created");
        } else {
          Message.show("Error creating new Provider");
        }
      })
      .catch((e) => {
        if (e.response) {
          setSaveProviderError(e.response.data?.message || "Error creating new Provider");
        } else {
          Message.show("Error creating new Provider");
        }
        setIsLoading(false);
      });
    }

    const deleteProvider = () => {
        httpApi
          .delete(`/providers/${providerId}`)
          .then(() => {
            Message.show("Provider deleted");
            handleCloseDeleteModal();
            getProviders();
          })
          .catch(() => {
            Message.show("Error deleting Provider");
            setIsLoading(false);
          });
    };

    const getActions = () => {
        const data: ModalAction[] = [
            {
                label: "Cancel",
                onClick: handleCloseModal,
                buttonVariant: "secondary",
                disabled: false,
            },
        ];
        data.push({
            label: mode === 'delete' ? "Proceed" : "Add",
            onClick: mode === 'delete' ? deleteProvider : handleAddProvider,
            disabled: (title.length > 0 || mode === 'delete') ? false : true,
            buttonVariant: (title.length > 0 || mode === 'delete') ? 'primary' : 'disabled',
        });
        return data;
    }

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    return (
        <UINucleusContainer>
            <UINucleusPageTitle>
                Providers
            </UINucleusPageTitle>
            <UINucleusContentContainer>
                {isLoading ? <UINucleusContentContainer centerContent>
                    <SpinnerCircular
                        color="#2096F3"
                        secondaryColor="rgba(0,0,0,0.16)"
                        size="50"
                        thickness={100}
                    />
                </UINucleusContentContainer> : <NucleusTable onRowClick={handleRowClick} columns={columns} data={providersData} enableExtraRow={true} />}
                <NucleusModalButton
                    handleOpenModal={handleOpenModal}
                />
                <UIModal
                    size="medium"
                    isOpen={addProviderModalOpen}
                    close={handleCloseModal}
                    title={TITLES[mode]}
                    actions={getActions()}
                >
                    <InputGroup>
                        <UIWhiteBoxInput
                            label="Name:"
                            placeholder="Empty"
                            value={title}
                            onChange={handleTitleChange}
                            error={saveProviderError}
                        />
                    </InputGroup>
                </UIModal>
                <UIModal
                    size="medium"
                    isOpen={deleteProviderModalOpen}
                    close={handleCloseDeleteModal}
                    actions={getActions()}
                >
                    <Title>WARNING!</Title>
                    <Note>You are about to delete</Note>
                    <Name>Provider: {providerName}</Name>
                    <Note>This will affect the following</Note>
                    <BoldNote>Accounts:{accountsCount}</BoldNote>
                    <BoldNote>Users:{usersCount}</BoldNote>
                    <Note>This action is permanent</Note>
                    <Note>Would you like to proceed?</Note>
                </UIModal>
            </UINucleusContentContainer>
        </UINucleusContainer>
    )
};

export default Providers;