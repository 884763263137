import React, { useEffect, useState } from 'react';
import Policies from './Policies';
import MDMNavTabs from './components/MDMNavTabs';
import { useDispatch, useSelector } from 'react-redux';
import { setPolicyScreen } from '@nucleus-care/nucleuscare-backend-client';
import { useHistory } from 'react-router-dom';

const MDMPolicies = () => {
  const dispatch = useDispatch();
  const { policyScreen } = useSelector(({ mdmPolicy }) => mdmPolicy.state);
  const handleTabs = (type, tabName) => {
    switch (type) {
      case 'main':
        dispatch(setPolicyScreen({ activeMainScreen: tabName }));
        break;
      case 'policies':
        dispatch(setPolicyScreen({ activeMDMScreen: tabName }));
        break;
      default:
        break;
    }
  };
  const history = useHistory();
  useEffect(() => {
    if (policyScreen && policyScreen.activeMainScreen === 'accounts') {
      dispatch(
        setPolicyScreen({
          activeMainScreen: 'accounts',
          activeMDMScreen: 'general',
        }),
      );
      history.push('/policies/mdmAccounts');
    }
    if (policyScreen && policyScreen.activeMainScreen === 'devices') {
      dispatch(
        setPolicyScreen({
          activeMainScreen: 'devices',
          activeMDMScreen: 'general',
        }),
      );
      history.push('/policies/mdmDevices');
    }
  }, [policyScreen, history]);

  return (
    <React.Fragment>
      <div className="nucleus-tools-container">
        <div className="">
          <p class="nucleus-page-title">
            <span></span>
          </p>
          <div className="row a-bit-lower left-align">
            <MDMNavTabs activeTab={'policies'} type="main" />
            <div class="nucleus-news-separator">&nbsp;</div>
            <div
              style={{
                marginTop: '30px',
              }}
            >
              <Policies />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MDMPolicies;
