import React, { useMemo } from 'react'
import { UIModal } from '../../../components/UI'
import styled from 'styled-components';
import { ModalAction } from '../../../components/UI/Modals/Base/ActionButtons';

const RemoveOrderModal = ({
    order,
    isOpen,
    onClose,
    onRemove,
}) => {
    const actions: ModalAction[] = useMemo(() => {
        if (order?.shippedStatus) {
            return [
                {
                    label: 'Close',
                    onClick: onClose,
                    buttonVariant: 'secondary',
                },
            ]
        }
        return [
            {
                label: 'Cancel',
                onClick: onClose,
                buttonVariant: 'secondary',
            },
            {
                label: 'Proceed',
                onClick: onRemove,
                buttonVariant: 'primary',
            },
        ]
    }, [onRemove, onClose, order]);

    return (
        <UIModal
            isOpen={isOpen}
            close={onClose}
            actions={actions}
            size='large'
        >
            <Title style={{
                fontSize: order?.shippedStatus ? 32 : 64
            }}>{order?.shippedStatus ? 'NOT ABLE TO DELETE' : 'WARNING!'}</Title>
            {!(order?.shippedStatus) && <>
                <RowContainer>
                    <Note>You are about to delete</Note>
                </RowContainer>
                <RowContainer>
                    <LabelContainer>
                        <BoldNote>Order ID:</BoldNote>
                    </LabelContainer>
                    <ValueContainer>
                        <NotBoldNote>{order?.id}</NotBoldNote>
                    </ValueContainer>
                </RowContainer>
                <RowContainer>
                    <LabelContainer>
                        <BoldNote>Device Orderer:</BoldNote>
                    </LabelContainer>
                    <ValueContainer>
                        <NotBoldNote>{order?.deviceOrdererFirstName ?? ''}{' '}{order?.deviceOrdererLastName ?? ''}</NotBoldNote>
                    </ValueContainer>
                </RowContainer>
                <RowContainer>
                    <LabelContainer>
                        <BoldNote>Device Order Phone:</BoldNote>
                    </LabelContainer>
                    <ValueContainer>
                        <NotBoldNote>{order?.deviceOrdererPhoneNumber}</NotBoldNote>
                    </ValueContainer>
                </RowContainer>
            </>}
            <ColContainer>
                <Text>{order?.shippedStatus ? 'This order is not able to be deleted because its' : "This action is permanent"}</Text>
                <Text>{order?.shippedStatus ? 'shipped status is set to "Yes"' : "Would you like to proceed?"}</Text>
            </ColContainer>
        </UIModal>
    )
}

export default RemoveOrderModal

const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;
const LabelContainer = styled.div`
    display: flex;
    flex:1;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 10px;
`;
const ValueContainer = styled.div`
    display: flex;
    flex:1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 10px;
`;
const RowContainer = styled.div`
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
`;
const ColContainer = styled.div`
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
`;
const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 16px;
`;
const Text = styled.span`
  color: #0a313f;
  text-align: center;
  font-size: 16px;
`;

const BoldNote = styled.span`
    color: #0a313f;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
`;
const NotBoldNote = styled.span`
    color: #0a313f;
    font-size: 16px;
    margin-left: 5px;
`;
