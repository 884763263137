import React from 'react';

interface PatientData {
  PatientID: number;
  FirstName?: string;
  LastName?: string;
  Telephone?: string;
  TotalDevices: number;
  OnlineDevices: number;
  FamilyMembers: number;
  FamilyMembersActive: number;
  MemoryBoxItems: number;
  PatientName?: string;
  TimezoneOffset?: string;
  TimezoneString?: string;
}

interface AccountPatientsRowProps {
  type: 'patients' | 'accounts';
  data: PatientData;
  editOnClick: (data: PatientData) => void;
  handlePatientsRowClick: (patientID: number) => void;
  handleCheckboxes: (data: PatientData) => void;
  selectedRows: number[];
}

const AccountPatientsRow: React.FC<AccountPatientsRowProps> = ({ type, data, editOnClick, handlePatientsRowClick, handleCheckboxes, selectedRows }) => {
  let deviceStatusIcon = 'img/icon_no_device.png';
  let deviceStatus = 'No devices assigned to the patient';

  if (data.TotalDevices > 0) {
    if (data.OnlineDevices === data.TotalDevices) {
      deviceStatusIcon = 'img/icon_device_active.png';
      deviceStatus = 'All devices are online';
    } else if (data.OnlineDevices === 0) {
      deviceStatusIcon = 'img/icon_device_inactive.png';
      deviceStatus = 'All devices are offline';
    } else {
      deviceStatusIcon = 'img/icon_device_semi_active.png';
      deviceStatus = 'There are some devices offline';
    }
  }

  return (
    <React.Fragment>
      {type === 'patients' ? (
        <tr
          className="nucleus-row"
          onClick={(e) => {
            if ((e.target as HTMLInputElement).type !== 'checkbox') {
              handlePatientsRowClick(data.PatientID);
            }
          }}
          style={{
            backgroundColor: selectedRows.includes(data.PatientID) ? '#0092FF1A' : undefined,
          }}
        >
          <td>
            <input
              type="checkbox"
              style={{
                position: 'relative',
                opacity: 1,
                pointerEvents: 'auto',
                width: 18,
                height: 18,
              }}
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxes(data);
              }}
              checked={selectedRows.includes(data.PatientID)}
            />
          </td>
          <td className="table-col-35 left-align">{data.FirstName ? `${data.FirstName} ${data.LastName}` : ' - '}</td>
          <td className="table-col-20 center-align">{data.Telephone || ' - '}</td>
          <td className="nucleus-row center-align" style={{ textAlign: 'center' }}>
            {data.TotalDevices}
          </td>
          <td className="nucleus-row center-align">{`${data.FamilyMembersActive} / ${data.FamilyMembers}`}</td>
          <td className="nucleus-row center-align">{data.MemoryBoxItems}</td>
          <td className="center-align">
            <img className="tooltipped" src={deviceStatusIcon} data-position="left" data-delay="50" data-tooltip={deviceStatus} alt="Device status" />
          </td>
        </tr>
      ) : (
        <tr>
          <td className="table-col-35 left-align">{data.PatientName || ' - '}</td>
          <td className="table-col-20 center-align">{data.TimezoneOffset || ' - '}</td>
          <td className="table-col-35 center-align">{data.TimezoneString || ' - '}</td>
          <td className="table-col-10 nucleus-row center-align">
            <span className="nucleus-table-icon nucleus-link" onClick={() => editOnClick(data)}>
              <i className="Tiny material-icons">edit</i>
            </span>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default AccountPatientsRow;
