import React, {useEffect, useState} from 'react';

const LogsDetailModal = ({handleCloseModal, callDetailsColumn1, callLogStartTime, callLogStatus, callLogDuration, callDetailsColumn2, callLogRequest, callLogDetailsParticipants, callLogNotes, textCallLogNotes, callLogModalTxtState, handleNotes, callLogModalBtnState, handleSaveNotes}) => {
    return(
        <div id="modal1" class="modal modalPatientProfile modalScrollableWidth">
                        <div class="modal-content nucleus-modal-content-no-bottom-padding txt-special-color nucleus-modal-wrapper2">
                            <img class="responsive-img img-close-modal" src="img/close_modal.png" onClick={handleCloseModal}/>
                            <h3 class="nucleus-page-subtitle">&nbsp;  Call Details</h3><br/><br/>
                            <div class="row no-margin">
                                <div class={ callDetailsColumn1 + " left-align "}>
                                    <div class="row bottom-separated">
                                        <span class="nucleus-font-label-title">Date & Time:</span><span class="nucleus-labels nucleus-font-label-body"> &nbsp; {callLogStartTime}</span>
                                    </div>
                                    <div class="row bottom-separated">
                                        <span class="nucleus-font-label-title">Status:</span><span class="nucleus-labels nucleus-font-label-body"> &nbsp; {callLogStatus}</span> 
                                    </div>
                                    <div class="row bottom-separated">
                                        <span class="nucleus-font-label-title">Duration:</span> <span class="nucleus-labels nucleus-font-label-body"> &nbsp; {callLogDuration}</span>
                                    </div>
                                </div>
                                <div class={ callDetailsColumn2 + " left-align "}>
                                    {
                                        (callLogRequest != null && callLogRequest.ID) ?
                                        <div class="row no-margin">
                                            <div class="row bottom-separated">
                                                <span class="nucleus-font-label-title">First Requested:</span><span class="nucleus-labels nucleus-font-label-body"> &nbsp; {callLogRequest.Time}</span> 
                                            </div>
                                            
                                            <div class="row bottom-separated">
                                                <span class="nucleus-font-label-title">Request Type:</span><span class="nucleus-labels nucleus-font-label-body"> &nbsp; {callLogRequest.Type}</span> 
                                            </div>
                                            <div class="row bottom-separated">
                                                <span class="nucleus-font-label-title">Escalated:</span><span class="nucleus-labels nucleus-font-label-body"> &nbsp; {callLogRequest.Escalated}</span> 
                                            </div>
                                        </div>
                                        : <div/>
                                    }
                                </div>
                            </div>

                            <div class="row no-margin">
                                <div class="row s4 m3 l3 xl2 left-align a-little-right-11">
                                    <span class="nucleus-font-label-title">Participants: <br/></span>
                                </div>
                                <div class="row s8 m9 l9 xl10 left-align a-bit-upper a-little-right-11">
                                    { callLogDetailsParticipants }
                                </div>
                            </div>
                            <br/>
                            <div class="input-field a-few-upper col s12" key={callLogNotes}>
                                <div class="row bottom-separated">
                                <span class="nucleus-font-label-title">Call notes:</span> <br/>
                                </div>
                                <textarea name="textCallLogNotes" class="nucleus-textarea " defaultValue={callLogNotes} value={textCallLogNotes.length > 0 ? textCallLogNotes : callLogNotes} placeholder="Enter note" disabled={callLogModalTxtState} onChange={handleNotes}></textarea>  
                                <br/>
                                <div class="row no-margin">
                                    <div class="col s12 center-align no-margin">
                                        <p></p>
                                        <span class=" modalSeparator"> <label> &nbsp;</label></span>
                                        { /*
                                        <a class="txt-white nucleus-font-small nucleus-link" onClick={this.handleCloseModal}>Cancel</a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        */ }
                                        <br/>
                                        <a class={ "txt-white nucleus-font-modal-btn " + callLogModalBtnState } onClick={handleSaveNotes}>Save</a>
                                        <div>&nbsp;</div>
                                        <br/> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    )
};

export default LogsDetailModal;