import React, { useState, useEffect } from 'react';
import Message from '../utils/Message';

import { RootState, csUsersSliceClean } from '@nucleus-care/nucleuscare-backend-client';

import { useSelector, useDispatch } from 'react-redux';

import AuthStore from '../stores/AuthStore';

const NewCsUser = () => {
  const csUsersStore = useSelector((state: RootState) => {
    return state.csUsersStore.data;
  });

  const dispatch = useDispatch();

  const [stateFirstName, setStateFirstName] = useState('');
  const [stateLastName, setStateLastName] = useState('');
  const [stateEmail, setStateEmail] = useState('');
  const [stateTelephone, setStateTelephone] = useState('');
  const [statePassword, setStatePassword] = useState('');
  const [checkboxes, setCheckboxes] = useState({
    canAccessAdminUsersTab: true,
    canAccessAccountsTab: true,
    canAccessReportsTab: true,
    canAccessContactsTab: true,
    canAccessProvidersTab: true,
    canAccessMDMTab: true,
    canAccessConsumerOrdersTab: true,
    canAccessDevicesTab: true,
    canAccessAppsTab: true,
    canAccessSettingsTab: true,
  });

  const [stateInputSecret, setStateInputSecret] = useState(true);
  const [stateEditUserBtnStyle, setStateEditUserBtnStyle] = useState('nucleus-submit-btn-disabled');

  const [stateErrorEmail, setStateErrorEmail] = useState(null);
  const [stateErrorTelephone, setStateErrorTelephone] = useState(null);
  const [stateErrorPassword, setStateErrorPassword] = useState(null);

  useEffect(() => {
    console.log('CsUsers useEffect csUsersStore');
    console.log('csUsersStore', csUsersStore);

    if (csUsersStore.error) {
      Message.show('Error in CsUsers');
    }
    dispatch(csUsersSliceClean());
  }, [csUsersStore.loading, csUsersStore.complete]);

  const onSwitchPasswordView = () => {
    setStateInputSecret(!stateInputSecret);
  };

  const validateData = () => {
    if (stateFirstName.length > 0 && stateLastName.length > 0 && stateEmail.length > 0 && statePassword.length > 0) {
      setStateEditUserBtnStyle('nucleus-submit-btn');
    } else {
      setStateEditUserBtnStyle('nucleus-submit-btn-disabled');
    }
  };

  const onSaveUserClick = () => {
    console.log('onSaveUserClick');

    let formValid = true;

    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/.test(stateEmail)) {
      setStateErrorEmail('Invalid Email');
      formValid = false;
    }

    if (stateTelephone.length > 0) {
      let telephone = stateTelephone;
      telephone = telephone.replace(/\(/g, '');
      telephone = telephone.replace(/\)/g, '');
      telephone = telephone.replace(/-/g, '');
      telephone = telephone.replace(/ /g, '');

      telephone.replace(/\s/g, '');
      console.log('Le nombre est:', telephone, telephone.length);

      if (telephone.length > 0 && telephone.length !== 10) {
        setStateErrorTelephone('Telephone must be 10 digits');
        formValid = false;
      }
    }

    if (statePassword.length > 0 && statePassword.length < 8) {
      setStateErrorPassword('Password must be at least 8 digits');
      formValid = false;
    }

    if (formValid) {
      setStateEditUserBtnStyle('nucleus-submit-btn-disabled');
      AuthStore.createNewCsUser(
        {
          FirstName: stateFirstName,
          LastName: stateLastName,
          Email: stateEmail,
          Telephone: stateTelephone,
          Password: statePassword,
          Permissions: checkboxes,
        },
        (response) => {
          console.log('New CS User -> AuthStore.createNewCsUser ->', response);
          if (response.ok) {
            Message.show('User created');
            window.location.assign('#/csUsers');
          } else {
            setStateEditUserBtnStyle('nucleus-submit-btn');
            if (response.error) {
              Message.show(response.error);
            } else {
              Message.show('Error creating user!');
            }
          }
        },
      );
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const checkboxStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const labelStyle: React.CSSProperties = {
    fontSize: 16,
    color: '#0A313F',
    fontWeight: 300,
  };

  const inputStyle: React.CSSProperties = {
    opacity: 1,
    position: 'relative',
    pointerEvents: 'auto',
    boxShadow: 'none',
    width: 18,
    height: 18,
  };
  return (
    <div className="nucleus-hard-container">
      <div className="nucleus-all-scrollable-page">
        <p className="nucleus-page-title">New User</p>
        <br />
        <div className={'nucleus-user-form-pending nucleus-form-container '}>
          <br />
          <div className="row">
            <div className="col s2 m1 l1"></div>
            <div className="col s7 m7 l6">
              <div className="row">
                <div className="col s4 m4 l5 valgin-wrapper left-align">
                  <span className="nucleus-labels">First Name</span> <span className="nucleus-labels txt-red">*</span>
                </div>
                <div className="col s8 m8 l7">
                  <input
                    type="text"
                    className="validate nucleus-input-form"
                    placeholder="First Name"
                    value={stateFirstName}
                    onChange={(e) => {
                      setStateFirstName(e.target.value);
                      validateData();
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s4 m4 l5 valgin-wrapper left-align">
                  <span className="nucleus-labels">Last Name</span> <span className="nucleus-labels txt-red">*</span>
                </div>
                <div className="col s8 m8 l7">
                  <input
                    type="text"
                    className="validate nucleus-input-form"
                    placeholder="Last Name"
                    value={stateLastName}
                    onChange={(e) => {
                      setStateLastName(e.target.value);
                      validateData();
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s4 m4 l5 left-align">
                  <span className="nucleus-labels"> Email</span> <span className="nucleus-labels txt-red">*</span>
                </div>
                <div className="col s8 m8 l7">
                  <input
                    type="email"
                    className="validate nucleus-input-form"
                    placeholder="Email"
                    value={stateEmail}
                    onChange={(e) => {
                      setStateEmail(e.target.value);
                      validateData();
                      setStateErrorEmail('');
                    }}
                    autoComplete="new-password"
                  />
                  {stateErrorEmail && <span className={'nucleus-form-error center-align '}> {stateErrorEmail}</span>}
                </div>
              </div>

              <div className="row">
                <div className="col s4 m4 l5 left-align">
                  <span className=" nucleus-labels"> Telephone</span>
                </div>
                <div className="col s8 m8 l7">
                  <input
                    type="text"
                    className="validate nucleus-input-form"
                    placeholder="201-555-0123"
                    value={stateTelephone}
                    onChange={(e) => {
                      setStateTelephone(e.target.value);
                      validateData();
                      setStateErrorTelephone('');
                    }}
                    autoComplete="new-password"
                  />
                  {stateErrorTelephone && <span className={'nucleus-form-error center-align '}> {stateErrorTelephone}</span>}
                </div>
              </div>
              <div className="row">
                <div className="col s4 m4 l5 left-align">
                  <span className="nucleus-labels"> Password</span> <span className="nucleus-labels txt-red">*</span>
                </div>
                <div className="col s8 m8 l7">
                  <div style={{ display: 'flex' }}>
                    <input
                      type={stateInputSecret ? 'password' : 'text'}
                      className="validate nucleus-input-form"
                      placeholder="Password"
                      value={statePassword}
                      onChange={(e) => {
                        setStatePassword(e.target.value);
                        validateData();
                        setStateErrorPassword('');
                      }}
                      autoComplete="new-password"
                    />
                    <img src="img/view_password.png" className="nucleus-link" style={{ width: 24, height: 16, marginLeft: -24, marginTop: 8 }} onClick={onSwitchPasswordView} />
                  </div>
                  {stateErrorPassword && <span className={'nucleus-form-error center-align '}> {stateErrorPassword}</span>}
                </div>
              </div>
              <br />
              <br />
              <div className="row">
                <div className="col s6">
                  <div className="row">
                    <div className="col s12 checkbox-container" style={checkboxStyle}>
                      <label style={labelStyle}>Can Access Accounts Tab</label>
                      <input type="checkbox" name="canAccessAccountsTab" checked={checkboxes.canAccessAccountsTab} onChange={handleCheckboxChange} style={inputStyle} />
                    </div>
                    <div className="col s12 checkbox-container" style={checkboxStyle}>
                      <label style={labelStyle}>Can Access Reports Tab</label>
                      <input type="checkbox" name="canAccessReportsTab" checked={checkboxes.canAccessReportsTab} onChange={handleCheckboxChange} style={inputStyle} />
                    </div>
                    <div className="col s12 checkbox-container" style={checkboxStyle}>
                      <label style={labelStyle}>Can Access Contacts Tab</label>
                      <input type="checkbox" name="canAccessContactsTab" checked={checkboxes.canAccessContactsTab} onChange={handleCheckboxChange} style={inputStyle} />
                    </div>
                    <div className="col s12 checkbox-container" style={checkboxStyle}>
                      <label style={labelStyle}>Can Access Providers Tab</label>
                      <input type="checkbox" name="canAccessProvidersTab" checked={checkboxes.canAccessProvidersTab} onChange={handleCheckboxChange} style={inputStyle} />
                    </div>
                    <div className="col s12 checkbox-container" style={checkboxStyle}>
                      <label style={labelStyle}>Can Access Admin Users Tab</label>
                      <input type="checkbox" name="canAccessAdminUsersTab" checked={checkboxes.canAccessAdminUsersTab} onChange={handleCheckboxChange} style={inputStyle} />
                    </div>
                  </div>
                </div>

                <div className="col s6">
                  <div className="row">
                    <div className="col s12 checkbox-container" style={checkboxStyle}>
                      <label style={labelStyle}>Can Access MDM Tab</label>
                      <input type="checkbox" name="canAccessMDMTab" checked={checkboxes.canAccessMDMTab} onChange={handleCheckboxChange} style={inputStyle} />
                    </div>
                    <div className="col s12 checkbox-container" style={checkboxStyle}>
                      <label style={labelStyle}>Can Access Consumer Orders Tab</label>
                      <input type="checkbox" name="canAccessConsumerOrdersTab" checked={checkboxes.canAccessConsumerOrdersTab} onChange={handleCheckboxChange} style={inputStyle} />
                    </div>
                    <div className="col s12 checkbox-container" style={checkboxStyle}>
                      <label style={labelStyle}>Can Access Devices Tab</label>
                      <input type="checkbox" name="canAccessDevicesTab" checked={checkboxes.canAccessDevicesTab} onChange={handleCheckboxChange} style={inputStyle} />
                    </div>
                    <div className="col s12 checkbox-container" style={checkboxStyle}>
                      <label style={labelStyle}>Can Access Apps Tab</label>
                      <input type="checkbox" name="canAccessAppsTab" checked={checkboxes.canAccessAppsTab} onChange={handleCheckboxChange} style={inputStyle} />
                    </div>
                    <div className="col s12 checkbox-container" style={checkboxStyle}>
                      <label style={labelStyle}>Can Access Settings Tab</label>
                      <input type="checkbox" name="canAccessSettingsTab" checked={checkboxes.canAccessSettingsTab} onChange={handleCheckboxChange} style={inputStyle} />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="row">
                <div className="col s4 m4 l5 left-align"></div>
                <div className="col s8 m8 l7 center-align">
                  <br />
                  <a
                    className={'txt-white nucleus-font-small ' + stateEditUserBtnStyle}
                    onClick={onSaveUserClick}
                    style={{
                      padding: '12px 24px',
                      fontSize: '18px',
                      lineHeight: '24px',
                      width: '397px',
                      textAlign: 'center',
                      display: 'inline-block',
                    }}
                  >
                    Save
                  </a>
                </div>
              </div>
            </div>
            <div className="col s3 m4 l5"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCsUser;
