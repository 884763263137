import React from "react";
import NucleusModal from "../../../../components/NucleusModal";
import NucleusButton from "../../../../components/NucleusButton";
import { useSelector } from "react-redux";
import { setPolicyScreen } from '@nucleus-care/nucleuscare-backend-client';

const UnassignPolicyAlert = ({
  loading,
  handleCancel,
  handleUnassign,
  setIsOpen,
  isOpen,
  policyName,
  deviceName,
  policyProtected
}) => {
  return (
    <NucleusModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          minHeight: 400,
          padding: 20,
        }}
      >
        <div
          style={{
            borderRadius: 15,
            padding: 20,
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <span
            style={{
              fontSize: 60,
              color: "#FE3824",
              fontWeight: "bold",
            }}
          >
            WARNING!
          </span>
        </div>
        { (policyProtected) &&
          <p style={{ fontSize: 25, fontWeight: "bold" }}>
            {"This is a Protected Policy"}
          </p>
        }
        <p style={{ fontSize: 25, fontWeight: "bold" }}>
          {`You are about to unassign policy "${policyName}" from Device "${deviceName}"`}
        </p>
        <p style={{ fontSize: 25, fontWeight: "bold" }}>
          Would you like to proceed?
        </p>
        <div style={{ display: "flex", marginTop:80 }}>
          <div className="nucleus-mr-1">
            <NucleusButton
              rounded
              disabled={loading}
              label="Cancel"
              backGroundColor="#FFFFFF"
              action={() => {
                handleCancel();
              }}
            />
          </div>
          <div className="nucleus-ml-1">
            <NucleusButton
              label="PROCEED"
              disabled={loading}
              backGroundColor="#0A313F"
              action={() => {
                handleUnassign();
              }}
            />
          </div>
        </div>
      </div>
    </NucleusModal>
  );
};

export default UnassignPolicyAlert;
