import React, { useEffect, useState } from "react";
import NucleusInput from "../NucleusInput";
import { useDispatch, useSelector } from "react-redux";
import {
  addPolicyInformationProperties,
  addPolicyJSONProperties,
  removePolicyJSONProperty,
} from '@nucleus-care/nucleuscare-backend-client';
import classNames from "classnames";
import Select from "../Select";
import NucleusLabel from "../NucleusLabel";

const MDMNucleusSelect = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  error,
  onFocus,
  activeBadge,
  onClickClear,
  containerClassName,
  options = [],
  defaultValue = "",
  setPropertyValue,
  labelMinWidth = "220px",
  fromPolicyInformation,
  valueAccessor = "value",
  labelAccessor = "name",
  hideClear = false,
  defaultValueLabel = "Select...",
  objectAccessor,
  propertyAccessor,
  disabled,
  ...props
}) => {
  const { policyJSON, policyInformation, policyFormStatus } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.state
  );
  const dispatch = useDispatch();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    if (fromPolicyInformation) {
      if (objectAccessor && propertyAccessor) {
        const value = policyInformation[objectAccessor][propertyAccessor];
        setInputValue(value);
        return;
      }
      if ([name] in policyInformation) {
        setInputValue(policyInformation[name]);
      }
      return;
    }
    if (
      objectAccessor &&
      [objectAccessor] in policyJSON &&
      [propertyAccessor] in policyJSON[objectAccessor]
    ) {
      const value = policyJSON[objectAccessor][propertyAccessor];
      setInputValue(value);
      return;
    }
    if ([name] in policyJSON) setInputValue(policyJSON[name]);
  }, [policyJSON, policyInformation]);
  const onFocusAction = () => {
    if (onFocus) return onFocus();
    setIsInputFocused(true);
  };

  const handleSelect = (e) => {
    if (setPropertyValue) {
      setInputValue(e.target.value);
      setPropertyValue(e.target.value);
      return;
    }
    setInputValue(e.target.value);
    if (fromPolicyInformation) {
      dispatch(addPolicyInformationProperties({ [name]: e.target.value }));
      return;
    }
    dispatch(addPolicyJSONProperties({ [name]: e.target.value }));
  };
  useEffect(() => {
    if (disabled) {
      setIsEditable(false);
    }
    setIsEditable(policyFormStatus.isEditable);
  }, [disabled, policyFormStatus]);
  return (
    <div
      className={classNames(
        containerClassName ? containerClassName : "row selectContainer"
      )}
    >
      <div
        style={{
          position: "absolute",
          display: "flex",
        }}
      >
        {!!([name] in policyJSON || [objectAccessor] in policyJSON) && (
          <span
            style={{
              position: "absolute",
              left: -4,
              top: -13,
              // top: "-10px",
              width: 0,
              height: 0,
              borderLeft: "10px solid #00C444",
              borderBottom: "10px solid transparent",
              // borderRight: "10px solid transparent",
              borderRight: "10px solid transparent",
            }}
          ></span>
        )}
      </div>
      <NucleusLabel
        labelMinWidth={labelMinWidth}
        label={label}
        value={inputValue}
      />
      <Select
        style={{
          marginTop: "5px",
          minWidth: 180,
          maxWidth: 220,
          height: "30px",
          display: "block",
        }}
        className="mdmSelect"
        defaultValue={defaultValue}
        onFocus={(e) => {
          if (setPropertyValue) {
            setPropertyValue(e.target.value);
            return;
          }
          if (fromPolicyInformation) {
            dispatch(addPolicyInformationProperties({ [name]: "" }));
            return;
          }
          dispatch(addPolicyJSONProperties({ [name]: "" }));
        }}
        id={name}
        name={name}
        value={inputValue}
        onChange={handleSelect}
        disabled={!isEditable}
      >
        <option value="" disabled selected>
          {defaultValueLabel || "Options"}
        </option>
        {options.map((option) => {
          return (
            <option
              disabled={!isEditable}
              key={option[labelAccessor]}
              value={option[valueAccessor]}
            >
              {option[labelAccessor]}
            </option>
          );
        })}
      </Select>
      {([name] in policyJSON || inputValue) && onClickClear && isEditable && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="nucleus-ml-2"
        >
          <a
            onClick={() => {
              setInputValue("");
              onClickClear();
            }}
            role="button"
            style={{ cursor: "pointer" }}
          >
            Clear
          </a>
        </div>
      )}
    </div>
  );
};

export default MDMNucleusSelect;
