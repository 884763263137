import React, { useEffect, useState, useMemo } from 'react'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import { useTable, useSortBy } from 'react-table'
import Select from '../../components/Select'
import CareAccountStore from '../../stores/CareAccountStore'
import Message from "../../utils/Message";
import MomentUtils from '@date-io/moment';
import { 
	KeyboardDateTimePicker, 
	MuiPickersUtilsProvider 
} from "@material-ui/pickers";
import css from './ReportDevicesForBilling.module.css'
import classnames from 'classnames'
import { getDeviceForBillingReportPromise, httpApi } from '@nucleus-care/nucleuscare-backend-client'
import { CSVLink } from "react-csv";
import { SpinnerCircular } from 'spinners-react';

dayjs.extend(utc)

const uiDateFormat = 'MM/DD/YYYY hh:mm A'
const backendFormat = 'YYYY-MM-DD hh:mm:ss'

function ReportTable ({ table }) {
   const {
     getTableProps,
     getTableBodyProps,
     headerGroups,
     rows,
     prepareRow,
   } = table
  
  return (
    <table className="bordered highlight nucleus-table" {...getTableProps()}>
       <thead>
         {
           headerGroups.map(headerGroup => (
             <tr {...headerGroup.getHeaderGroupProps()}>
               {
                 headerGroup.headers.map(column => (
                   <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <span class="nucleus-table-header-small">{column.render('Header')}</span>
                      <span>
                        {
                          column.isSorted
                            ? column.isSortedDesc
                              ? <span>&nbsp;▼</span>
                              : <span>&nbsp;▲</span>
                            : null
                        }
                      </span>
                   </th>
                 ))
               }
             </tr>
           ))
         }
       </thead>
       <tbody {...getTableBodyProps()}>
         {
           rows.map(row => {
             prepareRow(row)
             return (
               <tr {...row.getRowProps()}>
                 {
                   row.cells.map(cell => {
                     return (
                       <td className='nucleus-row' {...cell.getCellProps()}>
                         {cell.render('Cell')}
                       </td>
                     )
                   })
                 }
               </tr>
             )
           })
         }
       </tbody>
     </table>
  )
}

export default function ReportDevicesForBilling () {
  const [accounts, setAccounts] = useState([])
  const [loadingAccounts, setLoadingAccounts] = useState(true)
  const [LoadingTableData, setLoadingTableData] = useState(false)
  const [account, setAccount] = useState()
	const [stateFromDate, setStateFromDate] = useState(null);
	const [stateToDate, setStateToDate] = useState(null);
  const [reportRequest, setReportRequest] = useState({ data: [] })
  const [resultsNotFound, setResultsNotFound] = useState(false)
  let months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const exportHeaders = useMemo(() => {
    return [
      {
        label: 'Account Name',
        key: 'AccountName'
      },
      {
        label: 'Year',
        key: 'Year'
      },
      {
        label: 'Month',
        key: 'Month'
      },
      {
        label: 'Active Devices',
        key: 'ActiveDevices'
      }
    ]
  }, [])

  const tableData = useMemo(() => {
    let data = []
    if(reportRequest && reportRequest.data != null && reportRequest.data.length > 0) {
      data = reportRequest.data.map((row) => {
        let month = row.Month
        row.Month = months[month]
        return row
      })
    }
    return data
  }, [reportRequest])

  const tableColumns = useMemo(() => {
    return [
      {
        Header: 'Account Name',
        accessor: 'AccountName'
      },
      {
        Header: 'Year',
        accessor: 'Year'
      },
      {
        Header: 'Month',
        accessor: 'Month'
      },
      {
        Header: 'Active Devices',
        accessor: 'ActiveDevices'
      }
    ]
  }, [])


  const tableInstance = useTable({
    columns: tableColumns,
    data: tableData,
  }, useSortBy)
  const handleSubmitSearchDevices = () => {
    setLoadingTableData(true)
    const hasFromDate = !!(stateFromDate && stateFromDate.length)
    const hasToDate = !!(stateToDate && stateToDate.length) 
    const shouldQueryUsingDates = hasFromDate && hasToDate

    console.log("handleSubmitSearchDevices");
    if (account || shouldQueryUsingDates) {
      let query = {};
      if (account){
        query = Object.assign(query, {accountId: account})
      }
      if (shouldQueryUsingDates) {
        query = Object.assign(query, {
          startDate: dayjs(stateFromDate, uiDateFormat).utc().format(backendFormat),
          endDate: dayjs(stateToDate, uiDateFormat).utc().format(backendFormat),
        }) 
      }
      console.log("Query", query);
      // execute endpoint
      getDeviceForBillingReportPromise(query)
        .then((request) => {
          setReportRequest(request)
          console.log("REQUEST!!!",request);
          if(request.data <= 0) {
            setResultsNotFound(true)
          }else {
            setResultsNotFound(false)
          }
          setLoadingTableData(false)
        })
        .catch((e) => {
          console.error("Sorry, There was a problem getting the report information. Please try again.",e)
          Message.show("Sorry, There was a problem getting the report information. Please try again.");
        })
        setLoadingTableData(false)
    }
    else{
      Message.show("Please select an Account or Dates to get the report");
    }
    setLoadingTableData(false)
  }

  const handleAccountSelect = (e) => {
    setAccount(e.currentTarget.value)
  }

	const onFromDateChange = (date, value) => {
    //setAccount('')
		setStateFromDate(value);
	}

  const onToDateChange = (date, value) => {
    //setAccount('')
		setStateToDate(value);
  }

  useEffect(() => {
    httpApi.get("/account").then(({data}) => {
      console.log("accounts_data",data);
      setAccounts(data)
      setLoadingAccounts(false)
    }).catch((err)=>{
      Message.show("Sorry, There was a problem getting the accounts information. Please try again.");
      setAccounts([])
      setLoadingAccounts(false)
    })
    // const onGetAllAccountsDataAction = (success) => {
    //   setLoadingAccounts(true)
    //   if (!success) {
    //     Message.show("Sorry, There was a problem getting the accounts information. Please try again.");
    //     setAccounts([])
    //     setLoadingAccounts(false)
    //     return
    //   }
    //   setAccounts(CareAccountStore.getAllAccounts())
    //   setLoadingAccounts(false)
    // }

    // CareAccountStore.on('onGetAllAccountsData', onGetAllAccountsDataAction);
    // CareAccountStore.getAllAccounts()
    // return () => {
    //   CareAccountStore.removeListener("onGetAllAccountsData", onGetAllAccountsDataAction);
    // }
  }, [])


  
  return (
    <div className="nucleus-tools-container">
        <div >
          <p className="nucleus-tools-page-title"><b>Report: Active devices for billing</b></p>
        </div>
        <div className="row a-bit-lower left-align">
          <div className={classnames("col m1 no-margin", css.accountContainer)}>
            <div className={css.accountElement}>
              <span className={classnames("nucleus-tools-page-text-title", css.noPadding)}>
                Account &nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </div>
          <div className={classnames('col m2 offset-s10', css.selectContainer)}>
            <div className='row'>
                <Select
                  value={account}
                  placeholder='Account'
                  onChange={handleAccountSelect}
                >
                  <option value="" disabled selected>Select an account</option>
                  {accounts.map((account) => {
                    return <option key={account.ID} value={account.ID}>{account.AccountName}</option>
                  })}
                </Select>
              </div>
          </div>
          <div className={classnames('col m2 offset-s10', css.selectContainer)} style={{display:'flex'}}>
            <p style={{textAlign:'center'}}>
            <div class={loadingAccounts ? "" : "hide"}>
                <SpinnerCircular
                    color="#2096F3"
                    secondaryColor='rgba(0,0,0,0.16)'
                    size='20'
                    thickness='100'
                    />
            </div>
            </p>
          </div>

        </div>

        <div className="row left-align">
          <span className="nucleus-tools-page-text-title a-bit-lower" style={{marginRight:28}}>
            From 
          </span>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              variant="dialog"
              className="browser-default"
              value={stateFromDate}
              onChange={onFromDateChange}
              onError={console.log}
              onClose={()=>{ }}
              //minDate={new Date("2018-01-01T00:00")}
              format={uiDateFormat}
            />
          </MuiPickersUtilsProvider>
          <span className="nucleus-tools-page-text-title a-bit-lower" style={{marginLeft:44, marginRight:16}}>
            To 
          </span>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              variant="dialog"
              className="browser-default"
              value={stateToDate}
              onChange={onToDateChange}
              onError={console.log}
              //onClose={()=>{console.log("onClose!");window.focus();}}
              //minDate={new Date("2018-01-01T00:00")}
              format={uiDateFormat}
            />
          </MuiPickersUtilsProvider>
          &nbsp;&nbsp;&nbsp;
          <a class="txt-white nucleus-font-small nucleus-submit-btn-tools" onClick={handleSubmitSearchDevices}> Submit </a>
        </div>

        <div className="row nucleus-fixed-container nucleus-75-table-container">
          <ReportTable table={tableInstance} />
          {resultsNotFound && <p style={{textAlign: 'center', width: '90%'}}>There were no active devices found for this date range</p>}
          <div class={LoadingTableData ? "row center-align" : "row center-align hide"}>
            <br />
            <br />
            <br />
            <SpinnerCircular 
              color="#2096F3"
              secondaryColor='rgba(0,0,0,0.16)'
              size='50'
              thickness='100'
            />
            <br />
            <br />
            <br />
          </div>
        </div>

        {
          (tableData && tableData.length) ? ( 
            <div class="fixed-action-btn">
              <CSVLink headers={exportHeaders} data={tableData} filename='DevicesForBilling' target="_blank">
                <a
                  className={classnames('btn-floating txt-white nucleus-font-small  nucleus-submit-btn-small', css.exportLink)} 
                >
                  Export to CSV
                </a>
              </CSVLink>
            </div>
          ) : null
        }
    </div>
  )
}
