import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { IndexLink, Link } from "react-router-dom";
import Message from "../../utils/Message";

import { useState, useEffect, useRef } from 'react';

import NucleuscareConnectData,
{
	RootState,
	getExternalAppsListThunk, addExternalAppThunk,
    getAppInfoThunk, deleteExternalAppThunk
} from '@nucleus-care/nucleuscare-backend-client';

import {
	useSelector,
	useDispatch
	//DefaultRootState
} from 'react-redux';

import { SpinnerCircular } from 'spinners-react';


import AuthStore from "../../stores/AuthStore";

//Components
import ExternalAppRow from './ExternalAppRow';

const ExternalApps = () => {

    const externalAppsStore = useSelector((state: RootState) => {
		//console.log("ExternalApps useSelector", state.accountExternalApps.appsConfig);
		return state.accountExternalApps.appsConfig;
	});

    const externalAppStore = useSelector((state: RootState) => {
		console.log("ExternalApp useSelector", state.accountExternalApps.app);
		return state.accountExternalApps.app;
	});

    const [stateLoading, setStateLoading] = useState(false);
	const [stateSearchText, setStateSearchText] = useState("");

    const dispatch = useDispatch();

    const [stateNewAppInfo, setStateNewAppInfo] = useState({
        ID: null,
        Name: "",
        AndroidPackage:"",
        IconUrl: ""
    });
    const [stateSelectedApp, setStateSelectedApp] = useState({});

    const [stateMissingNameError, setStateMissingNameError] = useState(false);
    const [stateMissingPackageError, setStateMissingPackageError] = useState(false);
    const packageNameRegex = /^[a-zA-Z][a-zA-Z0-9_.]*$/;
    const [invalidPackageNameFormatError, setInvalidPackageNameFormatError] = useState(false);
    const [stateMissingIconError, setStateMissingIconError] = useState(false);

    // const [stateEditUserAllowed, setStateEditUserAllowed] = useState(false);
    const [stateSaveBtnStyle, setStateSaveBtnStyle] = useState("nucleus-submit-btn");
    const [stateSaveButtonLabel, setStateSaveButtonLabel] = useState("Save");
    // const [stateDeleteUserBtnStyle, setStateDeleteUserBtnStyle] = useState("nucleus-submit-btn-small");

    const [stateDeleteBtnStyle, setStateDeleteBtnStyle] = useState("nucleus-submit-btn");
    const [stateDeviceBtnStyle, setStateDeviceBtnStyle] = useState("nucleus-submit-btn");

    const [stateSortingStyles, setStateSortingStyles] = useState({

        appNameAsc: " nucleus-icon-inactive ",
        appNameDesc : " nucleus-icon-inactive hide ",

		onlineDateAsc : " nucleus-icon-inactive ",
        onlineDateDesc : " nucleus-icon-inactive hide ",
    });

    const [stateSortingMode, setStateSortingMode] = useState("appNameAsc");

    const handleSortingMode = (tag, tag2) => {
        console.log("handleSortingMode", tag, tag2);

        let object = {

            appNameAsc: " nucleus-icon-inactive ",
            appNameDesc : " nucleus-icon-inactive hide ",

			onlineDateAsc : " nucleus-icon-inactive ",
        	onlineDateDesc : " nucleus-icon-inactive hide ",

        };
        object[tag] = " nucleus-icon-active ";
        object[tag2] = " nucleus-icon-inactive hide ";
        setStateSortingStyles(object);
        setStateSortingMode(tag);
    }

	useEffect(() => {
		console.log("ExternalApps Initial useEffect");

        if (!AuthStore.superUser()){
            window.location.assign("#/dashboard");
        }
        window.$('.modal').modal({
            dismissible: false,
            complete: () => {  }
        });
		refreshReport();
	}, []);


	useEffect(() => {
		console.log("ExternalApps useEffect externalAppsStore");
		console.log("externalAppsStore", externalAppsStore);

		if (!externalAppsStore.getPending){
			setStateLoading(false);
			if (externalAppsStore.getComplete){
				//Message.show("Report updated");
			}
		} else if (externalAppsStore.error){
			Message.show("Error getting External Apps");

		}
        // dispatch(
        //     csUsersSliceClean()
        // );
	}, [externalAppsStore.getPending, externalAppsStore.getComplete])

    useEffect(() => {
		console.log("ExternalApps:useEffect externalAppsStore:Add");
		console.log("externalAppsStore", externalAppsStore);

		if (!externalAppsStore.addLoading){
			setStateLoading(false);
			if (externalAppsStore.addComplete){
                refreshReport();
				Message.show("New App created");
                handleCloseAddModal();
			}
		} else if (externalAppsStore.error){
			Message.show("Error in Adding a new app...");
		}
        // dispatch(
        //     csUsersSliceClean()
        // );
	}, [externalAppsStore.addLoading, externalAppsStore.addComplete])

    useEffect(() => {
		console.log("ExternalApp:useEffect externalAppStore:Info");
		console.log("externalAppStore", externalAppStore);

		if (!externalAppStore.infoLoading){
			setStateLoading(false);
			if (externalAppStore.infoComplete){
                handleExternalAppInfo();
			}
		} else if (externalAppStore.error){
			Message.show("Error getting App info...");
		}

	}, [externalAppStore.infoLoading, externalAppStore.infoComplete])

    useEffect(() => {
		console.log("ExternalApp:useEffect externalAppStore:Info");
		console.log("externalAppStore", externalAppStore);

		if (!externalAppStore.deleteLoading){
			setStateLoading(false);
			if (externalAppStore.deleteComplete){
                handleExternalAppDeleted();
			}
		} else if (externalAppStore.error){
			Message.show("Error deleting App...");
		}
	}, [externalAppStore.deleteLoading, externalAppStore.deleteComplete])

    // useEffect(() => {
	// 	console.log("CsUsers:useEffect externalAppsStore:Delete");
	// 	console.log("externalAppsStore", externalAppsStore);
	// 	if (!externalAppsStore.deleteLoading){
	// 		setStateLoading(false);
	// 		if (externalAppsStore.deleteComplete){
    //             refreshReport();
	// 			Message.show("User deleted");
    //             setStateDeleteUserBtnStyle("nucleus-submit-btn-small");
    //             window.$('#modalDeleteUser').modal('close');
    //             handleCloseEditModal();
	// 		}
	// 	} else if (externalAppsStore.error){
	// 		Message.show("Error in CsUsers");
	// 	}
    //     dispatch(
    //         csUsersSliceClean()
    //     );
	// }, [externalAppsStore.deleteLoading, externalAppsStore.deleteComplete])

    const onSearchTextChange = (textInput) =>{
        console.log("onSearchTextChange", textInput.target.value);
		setStateSearchText(textInput.target.value);
    }

    const refreshReport = ()=>{
		console.log("refreshReport");

        setStateLoading(true);
        dispatch(
            getExternalAppsListThunk()
        );
	}

    const onRowClicked = (appData)=>{
        console.log("onRowClicked", appData);
        setStateSelectedApp({
           ID: appData.ID,
           Name: appData.Name
        });
        // let editable = (AuthStore.getCsUserID().toLowerCase() == appData.ID.toLowerCase());
        // setStateEditUserAllowed(editable);
        // setStateSaveBtnStyle(editable ? "nucleus-submit-btn": "nucleus-submit-btn-disabled");
        dispatch(
            getAppInfoThunk(appData.ID)
        )
    }

    const openNewAppModal=()=>{
        window.$('#modalNewApp').modal('open');
        setStateNewAppInfo({
            ID: null,
            Name: "",
            AndroidPackage:"",
            IconUrl: ""
        });
        setStateSaveBtnStyle('nucleus-submit-btn');
        setStateSaveButtonLabel('Save')
    }

    const handleCloseAddModal = ()=>{
        console.log("handleCloseAddModal");
        window.$('#modalNewApp').modal('close');
    }

    const handleSaveNewApp = ()=>{
        console.log("onSaveUserClick", stateNewAppInfo);

        if (stateNewAppInfo.Name == ""){
            setStateMissingNameError(true);
            return;
        }
        if (stateNewAppInfo.AndroidPackage == ""){
            setStateMissingPackageError(true);
            return;
        }
        if (!packageNameRegex.test(stateNewAppInfo.AndroidPackage)){
            setInvalidPackageNameFormatError(true);
            return;
        }
        if (stateNewAppInfo.IconUrl == ""){
            setStateMissingIconError(true);
            return;
        }

        setStateSaveBtnStyle("nucleus-submit-btn-disabled");
        setStateSaveButtonLabel('Saving...');
        setStateLoading(true);
        dispatch(
            addExternalAppThunk({
                name: stateNewAppInfo.Name,
                androidIdentifier: stateNewAppInfo.AndroidPackage,
                iconUrl: stateNewAppInfo.IconUrl
            })
        );
    }

    const handleExternalAppInfo = ()=>{
        console.log("handleExternalAppInfo", stateSelectedApp);
        setStateSelectedApp({
            ID: stateSelectedApp.ID,
            Name: stateSelectedApp.Name,
            Accounts: externalAppStore.accounts,
            Patients: externalAppStore.patients
         });
         if (externalAppStore.accounts == 0 && externalAppStore.patients == 0){
            setStateDeleteBtnStyle("nucleus-submit-btn");
         }
         else{
            setStateDeleteBtnStyle("nucleus-submit-btn-disabled");
         }
         window.$('#modalAppDetails').modal('open');
    }

    const handleCloseAppInfoModal = ()=>{
        console.log("handleCloseAppInfoModal");
        window.$('#modalAppDetails').modal('close');
    }

    const handleDeleteApp = ()=>{
        console.log("onSaveUserClick", stateSelectedApp);
        window.$('#modalDeleteApp').modal('open');
    }

    const handleCancelDeleteApp = ()=>{
        console.log("handleCancelDeleteApp");
        window.$('#modalDeleteApp').modal('close');
        window.$('#modalAppDetails').modal('close');
    }

    const handleConfirmDeleteApp =()=>{
        console.log("handleConfirmDeleteApp");
        setStateDeviceBtnStyle("nucleus-submit-btn-disabled");
        dispatch(
            deleteExternalAppThunk(stateSelectedApp.ID)
        )
    }

    const handleExternalAppDeleted=()=>{
        window.$('#modalDeleteApp').modal('close');
        window.$('#modalAppDetails').modal('close');
        Message.show("External App deleted");
        refreshReport();
    }

    const getTableRows = ()=>{
        //console.log("render getTableRows>", externalAppsStore);

        let appRowsArray = [];
        if (externalAppsStore && externalAppsStore.apps && externalAppsStore.apps && externalAppsStore.apps.length > 0){

            let array = [].concat(externalAppsStore.apps);

            if (stateSortingMode == "appNameDesc"){
                array = array.sort((a, b) => {
					let textA = a.Name.toLowerCase();
                    let textB = b.Name.toLowerCase();
                    if (textB < textA) //sort string ascending
                        return -1
                    if (textB > textA)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "appNameAsc"){
                array = array.sort((a, b) => {
					let textA = a.Name.toLowerCase();
                    let textB = b.Name.toLowerCase();
                    if (textA < textB) //sort string ascending
                        return -1
                    if (textA > textB)
                        return 1
                    return 0
                });
            }

			//devicesReportRef.current = array;
            array.map((app, key) => {

				if (stateSearchText.length == 0){
					appRowsArray.push(
                        <ExternalAppRow key={key} data={app} onClick={onRowClicked} ></ExternalAppRow>
                    );
				}
				else {
					let text = stateSearchText.toLocaleLowerCase();
				// 	let userName = csUser.FirstName + " " + csUser.LastName;
				// 	let email =  "".concat(csUser.Email);
				// 	let telephone = "".concat(csUser.Telephone);
				// 	let onlineDate =  "".concat(csUser.LastLogin);
					if (
						(app.Name.toLowerCase().indexOf(text) >= 0)
						// (email.toLowerCase().indexOf(text) >= 0) ||
						// (telephone.toLowerCase().indexOf(text) >= 0) ||
						// (onlineDate.toLowerCase().indexOf(text) >= 0)
					){
						appRowsArray.push(
                            <ExternalAppRow key={key} data={app} onClick={onRowClicked} ></ExternalAppRow>
                        );
					}
				}
            });

            return (
                <tbody >
                    {appRowsArray}
                </tbody>
            );
        }
        return(
            <tbody class="center-align ">
                <tr style={{borderBottomStyle:"none"}}>
                    <td colSpan="7" class="center-align ">
                        <br/><br/><br/>
                        <span class="nucleus-table-header-medium text-gray"> {`There are no External Apps`} </span>
                        <br/><br/><br/>
                    </td>
                </tr>
            </tbody>
        );
    }

    return(
        <div class="nucleus-tools-container">
            <div class="nucleus-all-scrollable-page">
                <div >
                    <p class="nucleus-tools-page-title"><b>Apps</b></p>
                </div>


                <div style={{}}>
                    <div class="row a-bit-lower left-align">
                        <div class="col m11 no-margin">
                            <span class="nucleus-tools-page-text-title a-bit-lower">
                                Search &nbsp;&nbsp;&nbsp;
                            </span>
                            <input
                                type="text"
                                class="nucleus-input-form-small-left"
                                style={{width:500}}
                                onChange={onSearchTextChange}
                                placeholder={"Search for an App"}
                            />
                            &nbsp;&nbsp;&nbsp;
                                <a  class="txt-white nucleus-font-small nucleus-submit-btn-small"
                                    onClick={()=>{refreshReport();}}> Refresh
                                </a>
                        </div>

                        <div class="col m1 no-margin right-align">
                            {/* {
                                (stateLoading) &&
                                <div style={{margin:0, padding:0, marginTop:-16, marginBottom:0}}>
                                    <Spinner color="#2096F3" size={32} speed={0.5} animating={true} />
                                </div>
                            } */}
                        </div>
                    </div>
                </div>


                <div class="assignments-table-pending" >
                    <table class="bordered highlight nucleus-table" style={{tableLayout:"fixed"}}>
                    <thead>
                        <tr>
                            <th class="table-col-20">
                                <span class="nucleus-table-header ">{"Name"}</span>
                                <a class={ "nucleus-font-small " + stateSortingStyles.appNameDesc } onClick={()=>handleSortingMode("appNameAsc","appNameDesc")}> &nbsp;▼</a>
                                <a class={ "nucleus-font-small " + stateSortingStyles.appNameAsc  } onClick={()=>handleSortingMode("appNameDesc","appNameAsc")} >&nbsp;▲</a>
                            </th>
                            <th class="table-col-20">
                                <span class="nucleus-table-header">{"Android Identifier"}</span>
                            </th>
                            <th class="table-col-50">
                                <span class="nucleus-table-header">{"IconUrl"}</span>
                            </th>
                            <th class="table-col-10">
                                <span class="nucleus-table-header-medium">Details</span>
                             </th>

                            {/*
                            <th class="table-col-15 ">
                                <span class="nucleus-table-header">{""}</span>
                                <a class={ "nucleus-font-small " + stateSortingStyles.onlineDateDesc }  onClick={()=>handleSortingMode("onlineDateAsc","onlineDateDesc")}> &nbsp;▼</a>
                                <a class={ "nucleus-font-small " + stateSortingStyles.onlineDateAsc }   onClick={()=>handleSortingMode("onlineDateDesc","onlineDateAsc")} >&nbsp;▲</a>
                            </th>
                            */}
                        </tr>
                    </thead>
                    {
                        (!stateLoading) &&
                        getTableRows()
                    }

                    {
                        (stateLoading) &&
                            <td class="nucleus-row center-align" rowSpan={3} colSpan={5}>
                                <div style={{marginTop:120}}>
                                    <SpinnerCircular
                                        color="#2096F3"
                                        secondaryColor='rgba(0,0,0,0.16)'
                                        size='50'
                                        thickness='100'
                                        />
                                </div>
                            </td>
                        }
                </table>
            </div>

            <div class="fixed-action-btn btn-add-new">
                <a class="btn-floating btn-large waves-effect waves-light orange tooltipped" data-position="left" data-delay="50" data-tooltip="New User"
                    onClick={()=>{openNewAppModal() }}>
                    <i class="material-icons">add</i>
                </a>
                &nbsp;&nbsp;
            </div>

            <div id="modalNewApp" class="modal modalNewApp">
                <div class="modal-content nucleus-modal-wrapper-scrollable2">
                    <img class="responsive-img img-close-modal" src="img/close_modal.png" onClick={handleCloseAddModal}/>
                    <h3 class="nucleus-page-subtitle"> New External App </h3>
                    <br/><br/>
                    <div class="row no-margin">
                        <div class="col s3">
                            <span class="nucleus-labels"> <b>Name</b> </span>
                        </div>
                        <div class="col s9">
                            <input
                                type="text"
                                value={stateNewAppInfo.Name}
                                placeholder="Name"
                                class="validate nucleus-search no-margin-bottom"
                                onChange={(e)=>{
                                    //console.log("Value", e.target.value);
                                    let tempData = Object.assign({}, stateNewAppInfo);
                                    tempData.Name = e.target.value;
                                    setStateNewAppInfo(tempData);
                                    setStateMissingNameError(false);
                                }}
                                //disabled={!stateEditUserAllowed}
                            />
                            <br/>
                            {
                                (stateMissingNameError) &&
                                <span class={"nucleus-form-error "}> Please enter Name</span>
                            }
                        </div>
                    </div>
                    <br/>
                    <div class="row no-margin">
                        <div class="col s3">
                            <span class="nucleus-labels"> <b>Android Identifier (Package)</b> </span>
                        </div>
                        <div class="col s9">
                            <input
                                type="text"
                                value={stateNewAppInfo.AndroidPackage}
                                placeholder="Android Package"
                                class="validate nucleus-search no-margin-bottom"
                                onChange={(e)=>{
                                    let tempData = Object.assign({}, stateNewAppInfo);
                                    tempData.AndroidPackage = e.target.value;
                                    setStateNewAppInfo(tempData);
                                    setStateMissingPackageError(false);
                                    setInvalidPackageNameFormatError(false);
                                }}
                            />
                            <br/>
                            {
                                (stateMissingPackageError) &&
                                <span class={"nucleus-form-error "}> Please enter Android Package name</span>
                            }
                            {
                                (invalidPackageNameFormatError) &&
                                <span class={"nucleus-form-error "}> Invalid Package name format. Choose a name starting with a letter and containing only letters, underscores, digits, and periods.</span>
                            }
                        </div>
                    </div>
                    <br/>
                    <div class="row no-margin">
                        <div class="col s3">
                            <span class="nucleus-labels"> <b>Icon Url</b> </span>
                        </div>
                        <div class="col s9">
                            <input
                                type="text"
                                value={stateNewAppInfo.IconUrl}
                                placeholder="IconUrl"
                                class="validate nucleus-search no-margin-bottom"
                                onChange={(e)=>{
                                    let tempData = Object.assign({}, stateNewAppInfo);
                                    tempData.IconUrl = e.target.value;
                                    setStateNewAppInfo(tempData);
                                    setStateMissingIconError(false);
                                }}
                            />
                            <br/>
                            {
                                (stateMissingIconError) &&
                                <span class={"nucleus-form-error "}> Please enter App Image url</span>
                            }
                        </div>
                    </div>

                    <div class="input-field col s12 modalCallLogContent2">
                        <br/>
                        <div class="row no-margin">
                            <div class="col s12 center-align no-margin">
                                <p></p>
                                <span class=" modalSeparator"> <label> &nbsp;</label></span>
                                <a
                                    class={"txt-white nucleus-font-small " + stateSaveBtnStyle }
                                    onClick={handleSaveNewApp}>
                                        {stateSaveButtonLabel}
                                </a>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modalAppDetails" class="modal modalAppDetails">
                <div class="modal-content nucleus-modal-wrapper-scrollable2">
                    <img class="responsive-img img-close-modal" src="img/close_modal.png" onClick={handleCloseAppInfoModal}/>
                    <h3 class="nucleus-page-subtitle"> External App: {stateSelectedApp.Name} </h3>
                    <br/><br/>
                    <div class="row no-margin">
                        <div class="col s3">
                            <span class="nucleus-labels"> <b>Active on</b> </span>
                        </div>
                        <div class="col s9">

                        </div>
                    </div>
                    <br/>
                    <div class="row no-margin">
                        <div class="col s6">
                            <span class="nucleus-labels"> <b>{stateSelectedApp.Accounts} accounts</b> </span>
                        </div>
                        <div class="col s6">
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s6">
                            <span class="nucleus-labels"> <b>{stateSelectedApp.Patients} patients</b> </span>
                        </div>
                        <div class="col s6">

                        </div>
                    </div>

                    <div class="input-field col s12 modalCallLogContent2">
                        <br/>
                        <div class="row no-margin">
                            <div class="col s12 center-align no-margin">
                                <p></p>
                                <span class=" modalSeparator"> <label> &nbsp;</label></span>
                                <a
                                    class={"txt-white nucleus-font-small " + stateDeleteBtnStyle }
                                    onClick={handleDeleteApp}>
                                        Delete
                                </a>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <br/>
                </div>
            </div>
            <div id="modalDeleteApp" class="modal modalDeleteApp">
                <div class="modal-content nucleus-modal-wrapper-scrollable2">
                    <img
                        class="responsive-img img-close-modal"
                        src="img/close_modal.png"
                        onClick={handleCancelDeleteApp}
                    />
                    <h3 class="nucleus-page-subtitle"> Delete External App</h3>
                    <br />
                    <br />
                    <div class="row">
                        <div class="col s12 no-margin">
                            <span class="nucleus-labels">
                                Are you sure you want to remove the app {'"' + stateSelectedApp.Name + '"'} ?
                            </span>
                        </div>
                        <br />
                    </div>

                    <div class="input-field col s12">
                        <br />
                        <div class="row center-align">
                            <div class="col s6 center-align no-margin">
                                <p></p>
                                <a class={"txt-white nucleus-font-medium " + stateDeviceBtnStyle }  onClick={handleCancelDeleteApp}>Cancel</a>
                                <div>&nbsp;</div>
                            </div>
                            <div class="col s6 center-align no-margin">
                                <p></p>
                                <a class={"txt-white nucleus-font-medium " + stateDeviceBtnStyle } onClick={handleConfirmDeleteApp}>Confirm</a>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            </div>
        </div>
    );
}

export default ExternalApps;
