import React, { useEffect, useMemo, useState } from 'react';
import NucleusTable from '../../../../components/NucleusTable';
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { dateFormatter } from '../../../../utils/dateFormatter';

type MdmDeviceLocationData = {
  Latitude: string;
  Longitude: string;
  TimeZone: string;
  Country: string;
  AdministrativeArea: string;
  City: string;
  PrimaryPostalCode: string;
  ZipCode: string;
  CreatedDate: string;
  UpdatedDate: string | null;
  Region: string;
};

type LocationDataRowProps = {
  row: {
    original: MdmDeviceLocationData;
  };
};

const ZipCodeCell: React.FC<LocationDataRowProps> = ({ row }) => {
  const { ZipCode, PrimaryPostalCode } = row.original;
  return <span>{ZipCode || PrimaryPostalCode}</span>;
};
const LastSeenCell: React.FC<LocationDataRowProps> = ({ row }) => {
  const createdAt = dateFormatter(row.original.CreatedDate);
  const updatedAt = dateFormatter(row.original.UpdatedDate);
  return <span>{updatedAt || createdAt}</span>;
};

const MdmDeviceLocation = ({ mdmDeviceId }) => {
  const [locations, setLocations] = useState<MdmDeviceLocationData[]>([]);
  useEffect(() => {
    async function fetchDeviceLocation() {
      try {
        console.log('Fetching device location', mdmDeviceId);
        const { data } = await httpApi.get(`/mdm/devices/${mdmDeviceId}/location`);
        console.log('Fetching device location', data);

        setLocations(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDeviceLocation();
  }, [mdmDeviceId]);

  const columns = useMemo(() => {
    const defaultCols = [
      {
        Header: 'Last Seen',
        accessor: 'UpdatedDate',
        Cell: LastSeenCell,
      },
      {
        Header: 'Region',
        accessor: 'Region',
      },
      {
        Header: 'Country',
        accessor: 'Country',
      },
      {
        Header: 'Administrative Area',
        accessor: 'AdministrativeArea',
      },
      {
        Header: 'City',
        accessor: 'City',
      },
      {
        Header: 'Postal Code',
        accessor: 'ZipCode',
        Cell: ZipCodeCell,
      },
      {
        Header: 'Time Zone',
        accessor: 'TimeZone',
      },
      {
        Header: 'Latitude',
        accessor: 'Latitude',
      },
      {
        Header: 'Longitude',
        accessor: 'Longitude',
      },
    ];
    return defaultCols;
  }, []);

  return (
    <div>
      <NucleusTable columns={columns} data={locations} />
    </div>
  );
};

export default MdmDeviceLocation;
