import { EventEmitter } from "events";
//import Dispatcher from "../Dispatcher";
//import Config from "../Config";
import PatientApi from "../apis/PatientApi";
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';	// This two are the same, use backendClient
import backendClient from "../utils/BackendClient";		// This two are the same, use backendClient

class CarePatientStore extends EventEmitter {
	constructor() {
		super();

		this.PatientApi = new PatientApi(this);
		try{
			this.PatientsList = JSON.parse(localStorage.PatientsList) || [];
			this.PatientsHash = JSON.parse(localStorage.PatientsHash) || [];
		}
		catch(e){}
		//console.warn(this);
	}

	getAllPatients(){
		return this.PatientsList;
	}

	getPatientInfo(patientID){
		
		return this.PatientsHash[patientID];
	}

	getPatientDataComplete(){
		console.log(this.PatientsHash);
		console.log("----");
		console.log(this.PatientsList);
		console.log("------|");
		return Object.keys(this.PatientsHash).length;
	}

	
	getPatientData ({ PatientID }) {
		console.log("getPatientData ", PatientID );

		this.PatientApi.GetCarePatientData({ PatientID, Mode: 'web'},(rsp) => {

			console.log("getPatientData Response");
			console.log(rsp);
			this.emit("onPatientGetData", rsp);
		});
	}

	getPatientProfileData ({ PatientID }) {
		console.log("getPatientProfileData ", PatientID );

		this.PatientApi.GetCarePatientProfileData({ PatientID, Mode: 'web'},(rsp) => {

			console.log("getPatientData Response");
			console.log(rsp);
			this.emit("onPatientGetProfileData", rsp);
		});
	}

	refreshPatientCallLogs() {
		this.emit("onRefreshPatientGetCallLogs");
	}

	UploadPatientImage({PatientID,File}) {
		console.log("CS UploadPatientImage ", PatientID, File);
		this.PatientApi.UploadCarePatientImage(
		  {PatientID,File},
		  (rsp) => {
			console.log("UploadCarePatientImage rsp",rsp);
			// this.emit("onUploadPatientImageAction", rsp);
			// this.emit("onUploadPatientImageResponseArrived", rsp);
		  }
		);
	  }
	
	assignPatientMember({ FamilyMemberID, PatientID }) {
		console.log("assignPatientMember ", FamilyMemberID, PatientID);

		this.PatientApi.AssignPatientMember(
			{ FamilyMemberID, PatientID },
			(rsp) => {
			//console.log("Nucleus Response", rsp);

			this.emit("onPatientMemberAssigned", rsp);
			}
		);
	}


	UpdatePatientData({
		PatientID,
		FirstName,
		LastName,
		Telephone,
		Address,
		DateOfBirth,
		EmergencyContactName,
		EmergencyContactTelephone,
		ZipCode,
	  }) {
		console.log(
		  "UpdatePatientData ",
		  PatientID,
		  FirstName,
		  LastName,
		  Telephone,
		  Address,
		  DateOfBirth,
		  EmergencyContactName,
		  EmergencyContactTelephone,
		  ZipCode
		);
	
		this.PatientApi.UpdateCarePatientData(
		  {
			PatientID,
			FirstName,
			LastName,
			Telephone,
			Address,
			DateOfBirth,
			EmergencyContactName,
			EmergencyContactTelephone,
			ZipCode,
		  },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			this.emit("onUpdatePatientAction", rsp);
			this.emit("onUpdatePatientResponseArrived", rsp);
		  }
		);
	  }


	getAllPatientsData ({ UserID }) {
		console.log("getAllPatientsData ", UserID );

		this.PatientApi.getAllPatientDataForUser({ UserID },(rsp) => {

			console.log("getAllPatientsData Response");
			console.log(rsp);

			if (rsp.ok){
				this.PatientsList = rsp.patients;
				//this.saveToLocalStorage();
			}
			//*-
			this.emit("onGetAllPatientsData", rsp.ok);

			if (rsp.ok){
				let patientsArray = [];
				let patientInfo = {};
				for(var i = 0; i < rsp.patients.length; i++){
		            
		            patientInfo ={
		            	Name : rsp.patients[i].FirstName + " " + rsp.patients[i].LastName,
		            	Telephone : rsp.patients[i].Telephone,
		            	Address: rsp.patients[i].Address
		            };
		            patientsArray[rsp.patients[i].PatientID] = patientInfo;
		        }
		        this.PatientsHash = patientsArray;
		        this.saveToLocalStorage();

		        this.emit("onGetAllPatientsDataHashMap");
			}
		});
	}

	CreateNewPatient({
		UserID,
		AccountID,
		FirstName,
		LastName,
		Email,
		Telephone,
		Address,
		DateOfBirth,
		EmergencyContactName,
		EmergencyContactTelephone,
		DeviceID,
	  }) {

		console.log("UserID", UserID);
    console.log("AccountID", AccountID);
    console.log("FirstName", FirstName);
    console.log("LastName", LastName);
    console.log("Email", Email);
    console.log("Telephone", Telephone);
    console.log("Address", Address);
    console.log("DateOfBirth", DateOfBirth);
    console.log("EmergencyContactName", EmergencyContactName);
    console.log("EmergencyContactTelephone", EmergencyContactTelephone);
    console.log("DeviceID", DeviceID);

    this.PatientApi.CreateNewPatient(
      {
        UserID,
        AccountID,
        FirstName,
        LastName,
        Address,
        Telephone,
        DateOfBirth,                // Deprecated 
        EmergencyContactName,       // Deprecated
        EmergencyContactTelephone,  // Deprecated
      },
      (rsp) => {
        //console.log("Nucleus Response", rsp);

        this.emit("onCreatePatientAction", rsp);
      }
    );

		// this.PatientApi.CreateNewPatient(
		// 	{
		// 		AccountID, 
		// 		FirstName, 
		// 		LastName, 
		// 		Address, 
		// 		Telephone, 
		// 		DateOfBirth, 
		// 		EmergencyContactName, 
		// 		EmergencyContactTelephone, 
		// 		ShowWeather, 
		// 		DeviceID
		// },(rsp) => {

		// 	console.log("Nucleus Response");
		// 	console.log(rsp);

		// 	this.emit("onCreatePatientAction", rsp);
		// });
	}

	getCareDevices ({AccountID}) {
		console.log("getCareDevices ", AccountID);

		this.PatientApi.GetCareDevices({AccountID},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onGetDevicesAction", rsp);
		});
	}

	assignDeviceToPatient ({PatientID, DeviceID, DeviceName}) {
		console.log("assignDeviceToPatient ", PatientID, DeviceID, DeviceName);
		backendClient.put(`patient/${PatientID}/devices/${DeviceID}?deviceName=${DeviceName}`, 
			{
				deviceName : DeviceName
			}, 
			(rsp)=>{
				this.emit("onAssignDevicePatientAction", rsp);
			}
		)
	}
	
	updatePatientDevice ({DeviceID, Name}) {
		console.log("updatePatientDevice ", DeviceID, Name);

		this.PatientApi.UpdateDeviceName({DeviceID, Name},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onDeviceUpdated", rsp);
		});
	}

	unassignDeviceToPatient ({PatientID, DeviceID}) {
		console.log("unassignDeviceToPatient ", PatientID, DeviceID);

		this.PatientApi.UnassignDevicePatient({PatientID, DeviceID},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onUnassignDevicePatientAction", rsp);
			this.emit("onUnassignDevicePatientAction2", rsp);
		});
	}

	requestRebootDevice ({Mac}) {
		console.log("requestRebootDevice ", Mac);

		this.PatientApi.RequestRebootDevice({Mac},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onRebootDeviceRequested", rsp);
		});
	}


	getCallLogDetails({
		LogID,
		LogStartTime,
		LogStatus,
		LogDuration,
		LogParticipants,
		LogRequest,
		Notes,
		NotesUserID,
	  }) {
		console.log("getCallLogDetails ", LogID, Notes, NotesUserID);
	
		this.PatientApi.GetCallLogNotes({ CallLogID: LogID }, (rsp) => {
		  //console.log("Nucleus Response", rsp);
	
		  this.emit("onOpenCallLogDetailsAction", {
			LogID,
			ok: rsp.ok,
			LogStartTime,
			LogStatus,
			LogDuration,
			LogParticipants,
			LogRequest,
			Notes: rsp.Notes,
			NotesUserID,
		  });
		});
	  }

	getPatientCallRequestLogs({ PatientID, From, To }) {
		console.log("getPatientCallRequestLogs ", PatientID, From, To);
	
		this.PatientApi.GetPatientRequestLogs({ PatientID, From, To }, (rsp) => {
		  //console.log("getPatientData Response", rsp);
	
		  this.emit("onPatientGetRequestLogs", rsp);
		});
	  }
	
	getLocalesData() {
		// Deprecated!
		console.log("getLocalesData " );
		this.PatientApi.GetLocalesData({ },(rsp) => {
			this.emit("onGetAccountLocalesData", rsp);
		});
	}

	getPatientAccountLocaleData(accountId){
		console.log("getPatientAccountLocaleData =>", accountId);
		if (accountId){
			backendClient.get(`account/${accountId}/get_languages`, (rsp)=>{
				//console.log("getPatientAccountLocaleData NodeResponse", rsp);
				this.emit("onGetPatientAccountLocalesData", rsp);
			})
		}
		else{
			this.emit("onGetPatientAccountLocalesData", {
				ok:false
			});
		}
	}

	deletePatient({PatientID}) {
		console.log("deletePatient ", PatientID);
		// this.PatientApi.DeletePatient({PatientID},(rsp) => {
		// 	console.log("Nucleus Response");
		// 	console.log(rsp);
		// 	this.emit("onDeletePatientAction", rsp);
		// });
		backendClient.delete(`patient/${PatientID}/delete`, (rsp)=>{
			console.log("deletePatient NodeResponse", rsp);
			this.emit("onDeletePatientAction", rsp);
		});
	}

	openDevicesSubTab() {
		this.emit("onOpenDevicesSubTab");
	}

	loadPatientRequestLogs() {
		this.emit("loadPatientRequestLogs");
	}

	reportCallStarted({PatientID, UserID, RequestID}) {
		console.log("reportCallStarted ", PatientID, UserID, RequestID);

		this.PatientApi.ReportCareCallStarted({PatientID, UserID, RequestID},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onReportCallStartedAction", rsp);
		});
	}

	reportCallEnded({CallLogID, WasAnswered}) {
		console.log("reportCallEnded ", CallLogID, WasAnswered);

		this.PatientApi.ReportCareCallEnded({CallLogID, WasAnswered },(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onReportCallEndedAction", rsp);
		});
	}

	checkExistingTelephone({ Telephone }) {
		console.log("checkExistingTelephone ", Telephone);
	
		this.PatientApi.CheckExistingTelephone({ Telephone }, (rsp) => {
		  //console.log("Nucleus Response", rsp);
	
		  this.emit("onCheckExistingTelephone", rsp);
		});
	  }


	updateCallLogNotes ({CallLogID, Notes, UserID}) {
		console.log("updateCallLogNotes ", CallLogID, Notes, UserID);

		this.PatientApi.UpdateCallLogNotes({CallLogID, Notes, UserID},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onUpdateCallLogNotesAction", rsp);
		});
	}

	getPatientAssignments({PatientID, ShowAll}) {
		console.log("getPatientAssignments ", PatientID, ShowAll);

		this.PatientApi.GetPatientAssignments({PatientID, ShowAll},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onGetPatientAssignments", rsp );
		});
	}

	getFamilyMembers({PatientID}) {
		console.log("getFamilyMembers ", PatientID);

		this.PatientApi.GetPatientFamilyMembers({PatientID},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onGetPatientFamilyMembers", rsp );
		});
	}
	
	updatePatientLocale({ PatientID, LocaleTag }) {
		console.log("updatePatientLocale ", PatientID, LocaleTag);
		let endpoint= "CarePatient.asmx/UpdateLocaleTag";
		this.PatientApi.UpdatePatientConfigString(
		  { 
			PatientID, 
			ConfigString: LocaleTag
		  },
		  endpoint,
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
			rsp["locale"] = LocaleTag;
			this.emit("onPatientLocaleUpdated", rsp);
		  }
		);
	}

	updatePatientAllowVideoCall({ PatientID, Enabled }) {
		console.log("updateShowCallButton ", PatientID, Enabled);
		let endpoint= "CarePatient.asmx/UpdateEnableVideoCall";
		this.PatientApi.UpdatePatientConfigFlag(
		  { PatientID, Enabled },
		  endpoint,
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["enabled"] = Enabled;
			this.emit("onAllowVideoCallUpdated", rsp);
		  }
		);
	}
	
	
	  updateShowCallButton({ PatientID, ShowCallButton }) {
		console.log("updateShowCallButton ", PatientID, ShowCallButton);
	
		this.PatientApi.UpdateShowCallButton(
		  { PatientID, ShowCallButton },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["show"] = ShowCallButton;
			this.emit("onShowCallButtonUpdated", rsp);
		  }
		);
	  }
	
	  updateShowCallButton2({ PatientID, ShowCallButton2 }) {
		console.log("updateShowCallButton2 ", PatientID, ShowCallButton2);
	
		this.PatientApi.UpdateShowCallButton2(
		  { PatientID, ShowCallButton2 },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["show"] = ShowCallButton2;
			this.emit("onShowCallButton2Updated", rsp);
		  }
		);
	  }
	
	  updateShowCheckInButton({ PatientID, ShowCheckInButton }) {
		console.log("updateShowCheckInButton ", PatientID, ShowCheckInButton);
	
		this.PatientApi.UpdateShowCheckInButton(
		  { PatientID, ShowCheckInButton },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["show"] = ShowCheckInButton;
			this.emit("onShowCheckInButtonUpdated", rsp);
		  }
		);
	  }
	
	  updateShowEmergencyButton({ PatientID, ShowEmergencyButton }) {
		console.log("updateShowEmergencyButton ", PatientID, ShowEmergencyButton);
	
		this.PatientApi.UpdateShowEmergencyButton(
		  { PatientID, ShowEmergencyButton },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["show"] = ShowEmergencyButton;
			this.emit("onShowEmergencyButtonUpdated", rsp);
		  }
		);
	  }
	
	  updateShowWeather({ PatientID, ShowWeather }) {
		console.log("updateShowWeather ", PatientID, ShowWeather);
	
		this.PatientApi.UpdateShowWeather({ PatientID, ShowWeather }, (rsp) => {
		  //console.log("Nucleus Response", rsp);
	
		  rsp["show"] = ShowWeather;
		  this.emit("onShowWeatherUpdated", rsp);
		});
	  }
	
	  updateShowCallControls({ PatientID, ShowCallButtonsBar }) {
		console.log("updateShowCallControls ", PatientID, ShowCallButtonsBar);
	
		this.PatientApi.UpdateShowCallControls(
		  { PatientID, ShowCallButtonsBar },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["show"] = ShowCallButtonsBar;
			this.emit("onShowCallControlsUpdated", rsp);
		  }
		);
	  }
	
	  updateCallPhoneFromDevice({ PatientID, CallPhoneFromDevice }) {
		console.log("updateCallPhoneFromDevice ", PatientID, CallPhoneFromDevice);
	
		this.PatientApi.UpdateCallPhoneFromDevice(
		  { PatientID, CallPhoneFromDevice },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["enable"] = CallPhoneFromDevice;
			this.emit("onCallPhoneFromDeviceUpdated", rsp);
		  }
		);
	  }

	  updateEnableMemoryBox({ PatientID, EnableMemoryBox }) {
		console.log("updateEnableMemoryBox ", PatientID, EnableMemoryBox);
	
		this.PatientApi.UpdateEnableMemoryBox(
		  { PatientID, EnableMemoryBox },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["enable"] = EnableMemoryBox;
			this.emit("onEnableMemoryBoxUpdated", rsp);
		  }
		);
	  }
	
	  updateEnableBlankMemoryBox({ PatientID, EnableBlankMemoryBox }) {
		console.log("updateEnableBlankMemoryBox ", PatientID, EnableBlankMemoryBox);
	
		this.PatientApi.UpdateEnableBlankMemoryBox(
		  { PatientID, EnableBlankMemoryBox },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["enable"] = EnableBlankMemoryBox;
			this.emit("onEnableBlankMemoryBoxUpdated", rsp);
		  }
		);
	  }
	
	  updateZipCode({ PatientID, ZipCode }) {
		console.log("updateZipCode ", PatientID, ZipCode);
	
		this.PatientApi.UpdateZipCode({ PatientID, ZipCode }, (rsp) => {
		  //console.log("Nucleus Response", rsp);
		  this.emit("onZipCodeUpdated", rsp);
		});
	  }
	
	  updateEnableBiometrics({ PatientID, EnableBiometrics }) {
		console.log("updateEnableBiometrics ", PatientID, EnableBiometrics);
	
		this.PatientApi.UpdateEnableBiometrics(
		  { PatientID, EnableBiometrics },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["enable"] = EnableBiometrics;
			this.emit("onEnableBiometricsUpdated", rsp);
		  }
		);
	  }
	
	  updateEnableBluetoothButtons({ PatientID, EnableBluetoothButtons }) {
		console.log(
		  "updateEnableBluetoothButtons ",
		  PatientID,
		  EnableBluetoothButtons
		);
	
		this.PatientApi.UpdateEnableBluetoothButtons(
		  { PatientID, EnableBluetoothButtons },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["enable"] = EnableBluetoothButtons;
			this.emit("onEnableBluetoothButtonsUpdated", rsp);
		  }
		);
	  }
	
	  updateEnableAutoAnswer({ PatientID, EnableAutoAnswer }) {
		console.log("updateEnableAutoAnswer ", PatientID, EnableAutoAnswer);
	
		this.PatientApi.UpdateEnableAutoAnswer(
		  { PatientID, EnableAutoAnswer },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["enable"] = EnableAutoAnswer;
			this.emit("onEnableAutoAnswerUpdated", rsp);
		  }
		);
	  }
	
	  updateEnableAppIncomingCall({ PatientID, EnableAppIncomingCall }) {
		console.log(
		  "updateEnableAppIncomingCall ",
		  PatientID,
		  EnableAppIncomingCall
		);
	
		this.PatientApi.UpdateEnableAppIncomingCall(
		  { PatientID, EnableAppIncomingCall },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["enable"] = EnableAppIncomingCall;
			this.emit("onEnableAppIncomingCallUpdated", rsp);
		  }
		);
	  }
	
	  updateEnableImpairedRingtone({ PatientID, Enable }) {
		console.log("updateEnableImpairedRingtone ", PatientID, Enable);
	
		this.PatientApi.UpdateEnableImpairedRingtone(
		  { PatientID, Enable },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["enable"] = Enable;
			this.emit("onEnableImpairedRingtoneUpdated", rsp);
		  }
		);
	  }
	
	  updateFamilyMemberEnableAutoAnswer({
		PatientID,
		FamilyID,
		EnableAutoAnswer,
	  }) {
		console.log(
		  "updateFamilyMemberEnableAutoAnswer ",
		  PatientID,
		  FamilyID,
		  EnableAutoAnswer
		);
	
		this.PatientApi.UpdateFamilyEnableAutoAnswer(
		  { PatientID, FamilyID, EnableAutoAnswer },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			rsp["enable"] = EnableAutoAnswer;
			this.emit("onEnableFamilyMemberAutoAnswerUpdated", rsp);
		  }
		);
	  }
	
	  updateFamilyMemberAdmin({
		PatientID,
		FamilyID,
		Admin,
	  }) {
		console.log(
		  "updateFamilyMemberAdmin ",
		  PatientID,
		  FamilyID,
		  Admin
		);
	
		this.PatientApi.UpdateFamilyAdmin(
		  { PatientID, FamilyID, Admin },
		  (rsp) => {
			rsp["admin"] = Admin;
			this.emit("onFamilyMemberAdminUpdated", rsp);
		  }
		);
	  }

	getPatientCallLogs({ PatientID, From, To }) {
		console.log("getPatientCallLogs ", PatientID, From, To);
	
		// this.PatientApi.GetPatientCallLogs(
		//   { PatientID, From, To, Mode: "web" },
		//   (rsp) => {
		//     //console.log("getPatientData Response", rsp);
	
		//     this.emit("onPatientGetCallLogs", rsp);
		//   }
		// );
		let rsp;
		  httpApi
		  .get("/patient/" + PatientID + `/call_logs?fromDate=${From}&toDate=${To}`)
		  .then((response) => {
			  //console.log("/user/" + UserID + "/patients Response:", response);
			  const { data } = response;
			  rsp = {
				ok: true,
				...data,
			  };
		  })
		  .catch((e) => {
			  const { data } = e.response || { error: "Error getting patients call logs"};
			  console.error("Error getting patients call logs", data);
			  rsp = {
				ok: false,
				...data,
			  };
		  })
		  .finally(() => {
			  console.log("Nucleus getPatientCallLogs finally");
			  this.emit("onPatientGetCallLogs", rsp);
		  });
	  }
	

	createNewFamilyMember({PatientID, FirstName, LastName, Email, Telephone, Address, ReceiveUpdates}) {
		console.log("getFamilyMembers ", PatientID, FirstName, LastName, Email, Telephone, Address, ReceiveUpdates);

		this.PatientApi.CreateNewFamilyMember({PatientID, FirstName, LastName, Email, Telephone, Address, ReceiveUpdates},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onCreateNewFamilyMember", rsp );
		});
	}

	updateFamilyMember({
		FamilyMemberID,
		FirstName,
		LastName,
		Telephone,
		Email,
		Password,
	  }) {
		console.log(
		  "updateFamilyMember ",
		  FamilyMemberID,
		  FirstName,
		  LastName,
		  Telephone,
		  Email,
		  Password
		);
	
		this.PatientApi.UpdateFamilyMember(
		  { FamilyMemberID, FirstName, LastName, Telephone, Email, Password },
		  (rsp) => {
			//console.log("Nucleus Response", rsp);
	
			this.emit("onUpdateFamilyMember", rsp);
		  }
		);
	  }

	deleteFamilyMember({ FamilyMemberID, PatientID }) {
		console.log("deleteFamilyMember ", FamilyMemberID, PatientID);

		this.PatientApi.DeleteFamilyMember({ FamilyMemberID, PatientID }, (rsp) => {
			//console.log("Nucleus Response", rsp);

			this.emit("onDeleteFamilyMember", rsp);
		});
	}

	reSendEmail({ ID }) {
		console.log("reSendEmail ", ID );

		this.PatientApi.RequestResendEmail({ ID },(rsp) => {

			console.log("reSendEmail Response");
			console.log(rsp);

			
			this.emit("onResendEmail", rsp);
		});
	}


	getPatientNotes({PatientID}) {
		console.log("getPatientNotes ", PatientID);

		this.PatientApi.GetPatientNotes({PatientID},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onGetPatientNotes", rsp );
		});
	}

	addPatientNote({PatientID, UserID, Note}) {
		console.log("addPatientNote ", PatientID, UserID, Note);

		this.PatientApi.AddPatientNote({PatientID, UserID, Note},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onAddPatientNote", rsp );
		});
	}

	updatePatientNote({ID, Note, UserID}) {
		console.log("updatePatientNote ", ID, Note, UserID);

		this.PatientApi.UpdatePatientNote({ID, Note, UserID},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onUpdatePatientNote", rsp );
		});
	}

	deletePatientNote({ID}) {
		console.log("deletePatientNote ", ID);

		this.PatientApi.DeletePatientNote({ID},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onDeletePatientNote", rsp );
		});
	}

	sendMessageToDevice({PatientID, UserID, Message}) {
		console.log("sendMessageToDevice ", PatientID, UserID, Message);

		this.PatientApi.SendMessageToDevice({PatientID, UserID, Message},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onMessageToDeviceSent", rsp );
		});
	}


	getPatientMessages({PatientID}) {
		console.log("getPatientMessages ", PatientID);

		this.PatientApi.GetPatientMessages({PatientID},(rsp) => {

			console.log("Nucleus Response");
			console.log(rsp);

			this.emit("onGetPatientMessages", rsp );
		});
	}

	handlePatientProfileInfoRefresh ({ DeviceID, DeviceMac }) {
		console.log("handlePatientProfileInfoRefresh", DeviceID, DeviceMac );

		this.emit("onHandleRefresh", {
			DeviceID : DeviceID,
			DeviceMac :DeviceMac
		});
	}

	handlePatientProfileInfoAudioCall ({ DeviceID, DeviceName }) {
		console.log("handlePatientProfileInfoAudioCall", DeviceID, DeviceName );

		this.emit("onHandleAudioCall", {
			DeviceID : DeviceID,
			DeviceName :DeviceName
		});
	}

	handlePatientProfileInfoVideoCall ({ DeviceID, DeviceName }) {
		console.log("handlePatientProfileInfoVideoCall", DeviceID );

		this.emit("onHandleVideoCall", {
			DeviceID : DeviceID,
			DeviceName :DeviceName
		});
	}

	handlePatientProfileInfoBargeCall ({ DeviceID, DeviceName }) {
		console.log("handlePatientProfileInfoBargeCall", DeviceID );

		this.emit("onHandleBargeCall", {
			DeviceID : DeviceID,
			DeviceName :DeviceName
		});
	}

	handlePatientProfileInfoNewMessageToDevice ({ DeviceID, DeviceName }) {
		console.log("handlePatientProfileInfoNewMessageToDevice", DeviceID );

		this.emit("onNewMessageToDevice", {
			DeviceID : DeviceID,
			DeviceName :DeviceName
		});
	}

	handlePatientProfileInfoUnassignDevice ({ DeviceID, DeviceMac, DeviceName }) {
		console.log("handlePatientProfileInfoUnassignDevice", DeviceID, DeviceMac, DeviceName );

		this.emit("onUnassignModalDevice", {
			DeviceID : DeviceID,
			DeviceMac :DeviceMac,
			DeviceName :DeviceName
		});
	}

	saveToLocalStorage(){
		console.log("saveToLocalStorage");
		localStorage.PatientsList = JSON.stringify(this.PatientsList);
		localStorage.PatientsHash = JSON.stringify(this.PatientsHash);
	}


}

const carePatientStore = new CarePatientStore;

export default carePatientStore;