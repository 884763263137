import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPolicyInformationProperties,
  addPolicyJSONProperties,
  fetchEnterprisesValues,
  removePolicyInformationProperty,
  removePolicyJSONProperty,
} from '@nucleus-care/nucleuscare-backend-client';
import message from "../../utils/Message";
import MDMNucleusInput from "../../components/MDMNucleusInput";
import MDMNucleusSelect from "../../components/MDMNucleusSelect";
const General = () => {
  // KIOSK (GENERAL tab)
  const { policyFormStatus, policyJSON, policyInformation } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.state
  );
  // const { enterprisesOptions } = useSelector(({ mdmPolicy }) => mdmPolicy.data);
  const [
    enterpriseOrganizationName,
    setEnterpriseOrganizationName,
  ] = useState();
  const systemUpdateSelectOptions = [
    { name: "Automatic", value: "AUTOMATIC" },
    { name: "Windowed", value: "WINDOWED" },
    { name: "Postpone", value: "POSTPONE" },
  ];
  const systemAutoUpdateNetoworkSelectOptions = [
    { name: "Never", value: "1" },
    { name: "WiFi Only", value: "2" },
    { name: "Always", value: "3" },
  ];
  const defaultPermissionPolicyOptions = [
    { name: "Prompt", value: "1" },
    { name: "Grant", value: "2" },
    { name: "Deny", value: "3" },
  ];
  const appsAutoUpdateOptions = [
    { name: "Never", value: "1" },
    { name: "WiFi Only", value: "2" },
    { name: "Always", value: "3" },
  ];
  const [policyName, setPolicyName] = useState("");
  const [systemUpdate, setSystemUpdate] = useState("");
  const handleSystemUpdateSelect = (e) => {
    setSystemUpdate(e.target.value);
  };
  const [systemAutoUpdateNetowork, setSystemAutoUpdateNetowork] = useState("");
  const handleSystemAutoUpdateNetoworkSelect = (e) => {
    setSystemAutoUpdateNetowork(e.target.value);
  };
  const [defaultPermissionPolicy, setDefaultPermissionPolicy] = useState("");
  const handledefaultPermissionPolicySelect = (e) => {
    setDefaultPermissionPolicy(e.target.value);
  };
  const [appsAutoUpdate, setAppsAutoUpdate] = useState("");
  const handleAppsAutoUpdate = (e) => {
    setAppsAutoUpdate(e.target.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (policyFormStatus.operationType !== "UPDATE") {
      dispatch(fetchEnterprisesValues());
    }
  }, [policyFormStatus]);
  // useEffect(() => {
  //   if (
  //     enterprisesOptions &&
  //     enterprisesOptions?.length > 0 &&
  //     policyInformation &&
  //     "EnterpriseID" in policyInformation
  //   ) {
  //     const foundName = enterprisesOptions.find(
  //       (o) => o.ID === policyInformation.EnterpriseID
  //     );
  //     console.log("foundName", foundName);
  //     if ("OrganizationName" in foundName)
  //       setEnterpriseOrganizationName(foundName.OrganizationName);
  //   }
  // }, [policyInformation]);
  return (
    <div className="nucleus-all-scrollable-page">
      <span className="nucleus-tools-page-title"></span>
      <div className="nucleus-tools-page-content">
        <MDMNucleusInput
          fromPolicyInformation
          hideClear
          hideActiveBadge
          required
          name={"Name"}
          label={"Name"}
          placeholder={"Name"}
        />
        <MDMNucleusInput
          fromPolicyInformation
          name={"Version"}
          label={"Version"}
          placeholder={"Version"}
          defaultValue={1}
          disabled
          hideActiveBadge
          hideClear
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "max-content",
          }}
        >
          <MDMNucleusSelect
            activeBadge={true}
            name={"systemUpdate.type"}
            objectAccessor={"systemUpdate"}
            propertyAccessor={"type"}
            valueAccessor="value"
            label={"Android OS Update:"}
            options={systemUpdateSelectOptions}
            onClickClear={() => {
              setSystemUpdate("");
              dispatch(removePolicyJSONProperty("systemUpdate"));
            }}
            setPropertyValue={(value) => {
              setSystemUpdate(value);
              dispatch(
                addPolicyJSONProperties({ systemUpdate: { type: value } })
              );
            }}
          />
          {policyJSON?.systemUpdate?.type === "WINDOWED" && (
            <div
              style={{
                alignItems: "flex-end",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div>
                Start
                <input
                  type="number"
                  min={0}
                  max={1439}
                  style={{ width: "60px", textAlign: "center" }}
                  required
                  defaultValue={policyJSON?.systemUpdate?.startMinutes || 0}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (value < 0 || value > 1439 || isNaN(value)) {
                      e.target.value = 0;
                      message.error("Please enter a value between 0 and 1439");
                      return;
                    }
                    e.target.value = value;
                    dispatch(
                      addPolicyJSONProperties({
                        systemUpdate: {
                          ...policyJSON?.systemUpdate,
                          startMinutes: value,
                        },
                      })
                    );
                  }}
                />
                minutes after midnight
              </div>
              <div>
                End
                <input
                  type="number"
                  min={0}
                  max={1439}
                  style={{ width: "60px", textAlign: "center" }}
                  defaultValue={policyJSON?.systemUpdate?.endMinutes || 0}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (value < 0 || value > 1439 || isNaN(value)) {
                      e.target.value = 0;
                      message.error("Please enter a value between 0 and 1439");
                      return;
                    }
                    dispatch(
                      addPolicyJSONProperties({
                        systemUpdate: {
                          ...policyJSON?.systemUpdate,
                          endMinutes: value,
                        },
                      })
                    );
                  }}
                />
                minutes after midnight
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default General;
