import React, { useState, useEffect } from "react";
import NucleusTable from "../../components/NucleusTable";
import NucleusModalButton from "../../components/NucleusModal/NucleusModalButton";
import NucleusModal from "../../components/NucleusModal";
import Select from "../../components/Select";
import message from "../../utils/Message";
import { getExternalAppsListThunk, addPolicyJSONProperties} from '@nucleus-care/nucleuscare-backend-client';
import { useSelector, useDispatch} from "react-redux";

// for reference only
// type AppData = {
//   // independent variables - the ones that are updated directly
//   name: String, // the name of the app
//   packageName: String, // the packageName for the app. Is unique for every app
//   defaultPermissionPolicy: String,
//   iconUrl: String, // the url for the icon
//   isKiosk: Boolean, // Indicates whether the app is the Kiosk app. Should only be true for maximum one app
//   isInTable: Boolean, // Indicates whether an app is in the table
//   showInTable: Boolean, // Indicates whether an app should be displayed in the table. True if it's not in the modal and it matches the search
//   autoUpdateMode: String, // indicates the auto update mode
//   isInModal: Boolean, // Indicates whether an app should be displayed in the modal. True if it's not in the table and it matches the search
//   isChecked: Boolean,  // Indicates whether an app in the modal is in a checked state.

//   // dependent variables - the ones that are updated by invoking generateDependentData
//   iconComponent: Object, // for rendering the icon component in the table and modal
//   autoUpdateModeComponent: Object, // for rendering the autoUpdateModeComponent in the table
//   kioskComponent: Object,  // for rendering the kiosk component in the table
//   removeComponent: Object, // for rendering the remove component in the table
//   modalSelectComponent: Object, // For rendering the select component in the modal

//   appOriginalJSON: Object, // the original JSON for the app. Used to update the policy JSON
// }

const Apps = () => {

  const [kioskAppData, setKioskAppData] = useState(null)
  const [disableInputs, setDisableInputs] = useState(false)
  const dispatch = useDispatch();

  const { policyFormStatus } = useSelector(
    ({ mdmPolicy }) => mdmPolicy.state
  );

  useEffect(()=>{
    setDisableInputs(policyFormStatus?.isEditable ?? false)
  },[policyFormStatus])

  const {
    PolicyDetails: policyDetails,
  } = useSelector(({ mdmPolicy }) => mdmPolicy.data);

  const autoUpdateModeOptions = React.useMemo(() => [
    { name: "Default", value: "AUTO_UPDATE_DEFAULT" },
    { name: "High Priority", value: "AUTO_UPDATE_HIGH_PRIORITY" },
    { name: "Postponed", value: "AUTO_UPDATE_POSTPONED" },
  ], []);
  // set the default auto update mode to high priority for fast installations
  const DEFAULT_AUTO_UPDATE_MODE = 'AUTO_UPDATE_HIGH_PRIORITY';
  
  // columns for the modal to add apps
  const addAppModalColumns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "iconComponent",
        disableSortBy: true,
      },
      {
        Header: "App Name",
        accessor: "name",
      },
      {
        Header: "Package",
        accessor: "packageName",
      },
      {
        Header: "Select",
        accessor: "modalSelectComponent",
        disableSortBy: true,
      },
    ],
    []
  );

  // columns for the app table
  const appTableColumns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "iconComponent",
        disableSortBy: true,
        width: '15%',
      },
      {
        Header: "App Name",
        accessor: "name",
        width: '10%',
      },
      {
        Header: "Package",
        accessor: "packageName",
        width: '25%',
      },
      {
        Header: "Auto Update Mode",
        accessor: "autoUpdateModeComponent",
        width: '30%',
        align: "center",
        disableSortBy: true,
      },
      {
        Header: "Kiosk",
        accessor: "kioskComponent",
        width: '15%',
        disableSortBy: true,
        align: "center",
      },
      {
        Header: "",
        accessor: "removeComponent",
        width: '25%',
        disableSortBy: true,
      },
    ],
    []
  );

  // Every app can be uniquely identified by its package.
  // That being the case, we can maintain state by using the App package as the hash
  const [appPackageNameToDataDict, setAppPackageNameToDataDict] = useState({}); // maps apps Android Identifiers to the relevant data for the page 
  // addAppModal variables
  const [addAppsModalIsOpen, setAddAppsModalIsOpen] = useState(false);

  // kioskVariables
  const [kioskModalIsOpen, setKioskModalIsOpen] = useState(false); // stores the open/close state of the modal for setting app as kiosk
  const [kioskModalPackageName, setKioskModalPackageName] = useState(""); // stores the package name for processing kioskAppModal events
  const [kioskModalType, setKioskModalType] = useState("") // stores whether the kiosk modal should be for adding or removing from kiosk mode

  // used to generate data that depends on the variables that get manually updated
  const generateDependentData = (appData) => {
    let iconComponent = (
      <div>
        <img 
          src={`${appData.iconUrl}`} 
          style={{ width: 100, height: 100, borderRadius: 10 }} 
        />
        {appData.isKiosk &&
          <img
            src="./img/small_shop.svg"
            style={{ width: 50, height: 50 }}
          />
        }
      </div>
    );

    let autoUpdateModeComponent = (
      <Select
        disabled={disableInputs}
        id={appData.packageName}
        name={appData.packageName}
        value={appData.autoUpdateMode}
        onChange={(e) => handleAutoUpdateModeChange(appData.packageName, e)}
        type="apps"
        className={"selectAutoUpdateMode"}
        style = {{
          marginTop: '5px',
          width: '50%',
          height: '30px',
        }}
      >
        <option value="" disabled selected hidden>
          Select
        </option>
        {autoUpdateModeOptions.map((option) => (
          <option key={option.name} value={option.value}>
              {option.name}
          </option>
        ))}
      </Select>
    );

    let kioskComponent = (
      <img 
        src={appData.isKiosk ? "./img/untick.svg" : "./img/add.svg"}
        alt={appData.isKiosk ? "X icon" : "Add icon"}
        onClick={() => handleKioskModalOpen(appData.packageName, appData.isKiosk ? "REMOVE" : "ADD")} 
        style={{ 
          padding: "20px",
          margin: "-20px", 
          cursor: "pointer" // This changes the cursor to a hand, indicating it's clickable.
        }}
      />
    )

    let modalSelectComponent = (
      <input
        type="checkbox"
        checked={appData.isChecked}
        className="addAppsCheckBox"
      />
    );

    return {
      ...appData,
      iconComponent: iconComponent,
      autoUpdateModeComponent: autoUpdateModeComponent,
      kioskComponent: kioskComponent,
      modalSelectComponent: modalSelectComponent
    };
  }

  // load the initial app data state
  // TODO - different way to do this??
  useEffect(() => {
    // console.log('mdmAppsDebug=policyDetails@',policyDetails);
    dispatch(getExternalAppsListThunk()).then(response => {
      const originalDataDict = {};
      const policyApps = JSON.parse(policyDetails?.PolicyTemplate?.PolicyDetails || '{}').applications || [] // there might not be apps (for new policies)
      const policyPackageNamesToInstallTypes = {};
      policyApps.forEach(policyApp => {
        policyPackageNamesToInstallTypes[policyApp.packageName] = {
          ...policyApp
        }
      });
      response.payload.apps.forEach(app => {
        const appIsInJSON = [app.AndroidIdentifier] in policyPackageNamesToInstallTypes;
        const defaultPermissionPolicy = policyPackageNamesToInstallTypes[app.AndroidIdentifier]?.defaultPermissionPolicy;
        const isKiosk = policyPackageNamesToInstallTypes[app.AndroidIdentifier]?.installType === 'KIOSK';
        const autoUpdateMode = policyPackageNamesToInstallTypes[app.AndroidIdentifier]?.autoUpdateMode;
        // first set independent values
        originalDataDict[app.AndroidIdentifier] = {
          name: app.Name,
          packageName: app.AndroidIdentifier,
          defaultPermissionPolicy: defaultPermissionPolicy,
          iconUrl: app.IconUrl,
          isKiosk: isKiosk,
          isInTable: appIsInJSON,
          showInTable: appIsInJSON,
          autoUpdateMode: autoUpdateMode,
          removeComponent:
            <a style={{cursor:'pointer'}} onClick={() => handleAppTableRemoveClick(app.AndroidIdentifier)}>
              remove
            </a>,
          isInModal: !appIsInJSON,
          isChecked: false,
          appOriginalJSON: policyPackageNamesToInstallTypes[app.AndroidIdentifier]
        };
        // set the dependent values
        originalDataDict[app.AndroidIdentifier] = generateDependentData({...originalDataDict[app.AndroidIdentifier]});
      });
      setAppPackageNameToDataDict(originalDataDict);
    });
  }, [disableInputs]);

  /**
  * Updates the policy JSON whenever the app data is updated
  */
  useEffect(() => {
    const allAppDataForPolicy = [];
    for (const appData of Object.values(appPackageNameToDataDict)) {
      // only put the app in the policy if it's in the table
      if (!appData.isInTable) {
        continue;
      }

      let appDataForPolicy = {
        ...appData.appOriginalJSON,
      }

      // these fields should always be defined
      appDataForPolicy.packageName = appData.packageName;
      appDataForPolicy.installType = appData.isKiosk ? 'KIOSK' : 'FORCE_INSTALLED' // currently only support two install types

      // fields that might not be defined
      if (appData.autoUpdateMode) {
        appDataForPolicy.autoUpdateMode = appData.autoUpdateMode;
      }
      if (appData.defaultPermissionPolicy) {
        appDataForPolicy.defaultPermissionPolicy = appData.defaultPermissionPolicy;
      };
      console.log('appDataForPolicy', appDataForPolicy);
      allAppDataForPolicy.push(appDataForPolicy)
    }
    dispatch(addPolicyJSONProperties({applications: allAppDataForPolicy}));
  }, [policyDetails, appPackageNameToDataDict])

  /**
  * Handles Kiosk app set
  *   - Sets kiosk status to selected app
  */
  const handleKioskAppSet = () => {  
    if (Object.values(appPackageNameToDataDict).some(app => app.isKiosk)) {
      message.show("An app is already set in kiosk mode, please remove from kiosk mode before adding new one");
      return;
    }
  
    setAppPackageNameToDataDict(prevDict => {
      const updatedDict = { ...prevDict };
      updatedDict[kioskModalPackageName].isKiosk = true;
      updatedDict[kioskModalPackageName] = generateDependentData(updatedDict[kioskModalPackageName]);
      return updatedDict;
    });
  };
  
  /**
  * Handles Kiosk app unset
  *   - Unsets Kiosk status from selected app
  */
  const handleKioskAppUnset = () => {  
    setAppPackageNameToDataDict(prevDict => {
      const updatedDict = { ...prevDict };
      updatedDict[kioskModalPackageName].isKiosk = false;
      updatedDict[kioskModalPackageName] = generateDependentData(updatedDict[kioskModalPackageName]);
      return updatedDict;
    });
  };

  /**
  * Handles a remove click in the app table
  *   - Removes app from table and adds back to modal
  */
  const handleAppTableRemoveClick = (packageName) => {
    setAppPackageNameToDataDict(prevDict => {
      const updatedDict = { ...prevDict };
      const appData = updatedDict[packageName];
  
      appData.isInTable = false;
      appData.showInTable = false;
      appData.isInModal = true;
      appData.isKiosk = false;
      updatedDict[packageName] = generateDependentData(updatedDict[packageName]);
      return updatedDict;
    });
  };

  /**
  * Handles a row click in the app table
  *   - Toggles checked status for the selected app
  */
  const handleAppModalRowClick = (e) => {
    const packageName = e.row.original.packageName;
  
    setAppPackageNameToDataDict(prevDict => {
      const updatedDict = { ...prevDict };
      const appData = updatedDict[packageName];
      appData.isChecked = !appData.isChecked;
      updatedDict[packageName] = generateDependentData(updatedDict[packageName]);
      return updatedDict;
    });
  };
  
  /**
  * Handles a cancel click in the app modal
  *   - Resets the checked status to false for all apps
  */
  const handleAppModalCancelClick = () => {
    setAddAppsModalIsOpen(false);
  
    setAppPackageNameToDataDict(prevDict => {
      return Object.fromEntries(
        Object.entries(prevDict).map(([key, appData]) => {
          let updatedAppData = { ...appData, isChecked: false };
          return [key, generateDependentData(updatedAppData)];
        })
      );
    });
  };
  
  /**
  * Handles an add click in the app modal
  *   - Adds all checked apps to the table
  *   - Removes all checked apps from the modal
  *   - Resets checked status to false for all apps
  */
  const handleAppModalAddClick = () => {
    setAppPackageNameToDataDict(prevDict => {
      const updatedDict = { ...prevDict };
      let appWasChecked = false;
  
      for (const [key, appData] of Object.entries(updatedDict)) {
        if (appData.isChecked) {
          appData.isInTable = true;
          appData.showInTable = true;
          appData.isChecked = false;
          appData.isInModal = false;
          appData.autoUpdateMode = DEFAULT_AUTO_UPDATE_MODE;
          appWasChecked = true;
          Object.assign(appData, generateDependentData(appData));
        }
      }
  
      if (!appWasChecked) {
        message.show("No apps were selected");
        return prevDict;
      }
  
      setAddAppsModalIsOpen(false);
      return updatedDict;
    });
  };

  /**
  * Handles an update to the app table search
  *   - Filters the app table according to the search
  */
  const handleAppTableSearchUpdate = (e) => {
    const searchText = e.target.value.toLowerCase();
    setAppPackageNameToDataDict(prevDict => {
      const updatedDict = { ...prevDict };
      for (const [key, appData] of Object.entries(updatedDict)) {
        const originalShowInTable = appData.originalShowInTable;
        appData.showInTable = (!appData.isInModal) && (appData.name.toLowerCase().includes(searchText) || appData.packageName.toLowerCase().includes(searchText) );

        // if the showInTable value has changed, then we need to update the dependent data
        if (originalShowInTable !== appData.showInTable) {
          Object.assign(appData, generateDependentData(appData));
        }
      }
      return updatedDict;
    });
  };
  
  /**
  * Handles an update to the app modal search
  *   - Filters the app modal according to the search
  */
  const handleAppModalSearchUpdate = (e) => {
    const searchText = e.target.value.toLowerCase();
    setAppPackageNameToDataDict(prevDict => {
      const updatedDict = { ...prevDict };
      for (const [key, appData] of Object.entries(updatedDict)) {
        const originalisInModal = appData.isInModal;
        appData.isInModal = (!appData.isInTable) && appData.name.toLowerCase().includes(searchText);
        if (originalisInModal !== appData.isInModal) {
          Object.assign(appData, generateDependentData(appData));
        }
      }
      return updatedDict;
    });
  };
  
  /**
  * Handles a change to the autoUpdateMode of the selected app
  *   - Sets autoUpdateMode to the selected value
  */
  const handleAutoUpdateModeChange = (packageName, e) => {
    setAppPackageNameToDataDict(prevDict => {
      const updatedDict = { ...prevDict };
      const appData = updatedDict[packageName];
      appData.autoUpdateMode = e.target.value;
      Object.assign(appData, generateDependentData(appData));
      return updatedDict;
    });
  };
    
  /**
  * Handles a change to the autoUpdateMode of the selected app
  *   - Sets the Kiosk Modal to open
  *   - Sets the packageName for the Kiosk Modal
  *   - Sets the type for the Kiosk modal (either "ADD" or "REMOVE")
  */
  const handleKioskModalOpen = (kioskModalPackageNameToSet, kioskModalTypeToSet) => {
    setKioskModalPackageName(kioskModalPackageNameToSet);
    setKioskModalType(kioskModalTypeToSet);
    setKioskModalIsOpen(true);
  }
  useEffect(()=>{
    const kioskDataFound = Object.values(appPackageNameToDataDict).find(app => app.isKiosk)
    setKioskAppData(kioskDataFound)
  },[appPackageNameToDataDict])

  return (
    <React.Fragment>
      <div className="nucleus-all-scrollable-page">
        <span className="nucleus-tools-page-title"></span>
        <div className="nucleus-tools-page-content">
          <div className="row">
            <div
              className={policyFormStatus.operationType !== "UPDATE" ? "kioskAppContainerNew" : "kioskAppContainer"}
            >
              <label className="mdmTextLg">Kiosk App:</label>
              <img style={{width: 75, height:75, resize:'contain', borderRadius:10}} src={kioskAppData !== null && kioskAppData?.iconUrl ? kioskAppData.iconUrl : "./img/icon_shop.svg"} alt="shop logo" />
              <input
                disabled
                style={{ width: 150, marginLeft: 30, color:'#0A313F' }}
                placeholder="Name"
                value={(Object.values(appPackageNameToDataDict).find(app => app.isKiosk) || {}).name || ''}
                />
              <input
                disabled
                style={{ width: 300, marginLeft: 30, color:'#0A313F' }}
                placeholder="Package"
                value={(Object.values(appPackageNameToDataDict).find(app => app.isKiosk) || {}).packageName || ''}
              />
              {policyFormStatus.operationType !== "UPDATE" && (
                <label className="kioskAppWarningTxt">
                  {Object.values(appPackageNameToDataDict).find(app => app.isKiosk) 
                  ? ''
                  : '*There is currently no kiosk app set'}
                </label>
              )}
            </div>
            <div className="kioskAppLine"></div>
            <div className="nucleusAppsTableSearchInputContainer">
              <img src="./img/search_icon.svg" alt="search icon" />
              <input
                className="nucleusSearchInput"
                placeholder={"Filter by Name / Package"}
                onChange={handleAppTableSearchUpdate}
                style={{color: 'black', width: '300px'}}
              />
            </div>
            <NucleusTable
              data={Object.values(appPackageNameToDataDict).filter(appData => appData.showInTable)}
              columns={appTableColumns}
            />
            {(!policyDetails?.PolicyTemplate?.Protected) &&
              <NucleusModalButton
                dataTooltip={"New App"}
                id={"networkModal"}
                handleOpenModal={() => setAddAppsModalIsOpen(true)}
              />
            }
          </div>
        </div>
      </div>
      {addAppsModalIsOpen && (
        <NucleusModal setIsOpen={handleAppModalCancelClick} type="apps" width={950}>
          <div className="mDMAddAppsContainer">
            <div className="nucleusAppsModalSearchInputContainer">
              <img src="./img/search_icon.svg" alt="search icon" />
              <input
                className="nucleusSearchInput"
                placeholder={"Filter by Name / Package"}
                onChange={handleAppModalSearchUpdate}
                style={{color: 'black'}}
              />
            </div>
            <div style={{ "overflow-y": "auto", "height": "500px"}}>
              <NucleusTable
                data={Object.values(appPackageNameToDataDict).filter(appData => appData.isInModal)}
                columns={addAppModalColumns}
                onRowClick={handleAppModalRowClick}
              />
            </div>
            <div className="modalActions">
              <div className="actionsContainer">
                <button className="deleteBtn" onClick={handleAppModalCancelClick}>
                  CANCEL
                </button>
                <button className="cancelBtn" onClick={handleAppModalAddClick}>
                  ADD
                </button>
              </div>
            </div>
          </div>
        </NucleusModal>
      )}
      {kioskModalIsOpen && (
        <NucleusModal setIsOpen={setKioskModalIsOpen}>
          <div className="kioskContainer">
            <label className="kioskWarning">WARNING</label>
            <p className="kioskTxt">
              {kioskModalType === "ADD"
                ? `You are about to set devices included within this policy into Kiosk mode. "${appPackageNameToDataDict[kioskModalPackageName].name}" will be the kiosk app.`
                : "You are about to take the devices included within this policy out of kiosk mode."}
            </p>
            <p className="kioskTxt">Would you like to proceed?</p>
            <div className="modalActions">
              <div className="actionsContainer">
                <button
                  className="deleteBtnKiosk"
                  onClick={() => setKioskModalIsOpen(false)}
                >
                  CANCEL
                </button>
                <button
                  className="cancelBtnKiosk"
                  onClick={ () => {
                    setKioskModalIsOpen(false);
                    if (kioskModalType === 'ADD'){
                      handleKioskAppSet();
                    } else {
                      handleKioskAppUnset();
                    }
                  }}
                >
                  PROCEED
                </button>
              </div>
            </div>
          </div>
        </NucleusModal>
      )}
    </React.Fragment>
  );
}

export default Apps;