import React, { useState, useRef, useEffect } from 'react';

type ImageUploaderProps = {
    imageUrl: string;
    onImageChange: (imageUrl: string) => void;
    errorMessage?: string;
}
const ImageUploader = ({
    register,
    defaultImageUrl, // Default image URL
    onImageChange, // Callback when a new image is selected
    errorMessage, // Custom error message
    setFileData, // Set file data
}) => {
    const [imageUrl, setImageUrl] = useState(defaultImageUrl || "");
    const [missingImageError, setMissingImageError] = useState('');
    const selectedLogoRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (defaultImageUrl) {
            setImageUrl(defaultImageUrl);
        }
        return () => {
            if (defaultImageUrl) {
                URL.revokeObjectURL(defaultImageUrl);
                setImageUrl('');
            };
        }
    }, [defaultImageUrl])
    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileData(file)
            const newImageUrl = URL.createObjectURL(file);
            setImageUrl(newImageUrl);
            onImageChange(newImageUrl); // Call the callback with the new URL
            setMissingImageError(''); // Reset the error message if necessary
        }
    };


    return (
        <div
            style={{
                backgroundColor: 'white',
                padding: '10px',
                margin: '5px',
                border: missingImageError ? '1px solid #FE3824' : 'none',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <p>Upload an Image</p>
            <div
                style={{
                    width: '200px',
                    height: '100px',
                    backgroundColor: imageUrl ? 'transparent' : '#D3D3D3',
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '20px',
                }}
            >
                {imageUrl ? (
                    <img src={imageUrl} alt="Selected Logo" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                ) : (
                    'Logo'
                )}
            </div>
            <p style={{ fontSize: '12px' }}>Minimum Resolution of 500 x 250</p>
            <button
                onClick={() => selectedLogoRef.current && selectedLogoRef.current.click()}
                style={{
                    background: 'none',
                    border: 'none',
                    color: '#0292ff',
                    cursor: 'pointer',
                }}
                type="button"
            >
                Change Logo
            </button>
            <input
                {...register}
                id="logoFile"
                ref={selectedLogoRef}
                type="file"
                style={{ display: 'none' }}
                onChange={handleLogoChange}
                accept={'image/png, image/jpeg'}
            />
            {missingImageError ? (
                <p style={{ color: '#FE3824', fontWeight: 'bold', marginTop: 5, textAlign: 'end' }}>
                    {errorMessage || missingImageError}
                </p>
            ) : (
                <div style={{ height: '30px' }} />
            )}
        </div>
    );
};

export default ImageUploader;
