import React from 'react'

export function AccessCheckbox ({
  hasAdminVisibility,
  label,
  id,
  defaultChecked,
  checked,
  onChange = () => {}
}) {
  if (!hasAdminVisibility) return null
  return (
    <div className='row'>
        <div className='col s10'>
            <span className=' nucleus-labels'> {label}</span> 
        </div>
        <div className='col s2'>
          <span>
            <input
              type='checkbox'
              className='filled-in'
              id={id} 
              onChange={onChange}  
              checked={checked}
              defaultChecked={defaultChecked}
            />
            <label for={id}>&nbsp;</label>
          </span>
        </div>
    </div>
  )
}

export function AccessToggle ({ hasAdminVisibility, label, key, disabled, defaultChecked, onChange = () => {} }) {
  if (!hasAdminVisibility) return null
  return (
    <div className='row'>
        <div className='col s4'>
            <span className='nucleus-labels'> {label}</span> 
        </div>
        <div className='col s8 no-margin'>
            <div className='switch no-margin '>
                <label className='no-margin' key={key}>
                  <input
                    type='checkbox'
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                  />
                  <span className='lever nucleus-check'></span>
                </label>
            </div>

        </div>
    </div>
  )
}
