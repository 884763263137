import React, { useEffect, useState } from "react";
import classNames from "classnames";

const NucleusInput = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  error,
  onFocus,
  activeBadge,
  onClickClear,
  hideClear,
  style,
  required,
  ...props
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const onFocusAction = () => {
    if (onFocus) return onFocus();
    setIsInputFocused(true);
  };
  return (
    <div className="input-field row">
      <style>
        {`
              .input-field label {
                position: relative; !important;
              }
              `}
      </style>
      {activeBadge && (
        <span
          style={{
            position: "absolute",
            left: -4,
            top: "10px",
            // top: "-10px",
            width: 0,
            height: 0,
            borderLeft: "10px solid #00C444",
            borderBottom: "10px solid transparent",
            // borderRight: "10px solid transparent",
            borderRight: "10px solid transparent",
          }}
        ></span>
      )}
      <label htmlFor={name} style={{ minWidth: "190px", marginLeft: 2, marginTop:-5 }}>
        {label + ":"}
      </label>
      <input
        style={style}
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={classNames(
          style ? "" : "nucleus-input-mdm",
          ` ${error ? "invalid" : ""}`
        )}
        onFocus={onFocusAction}
        onBlur={() => setIsInputFocused(false)}
        required={required}
        {...props}
      />
      {/* {error && <span className="helper-text" data-error={error}></span>} */}
      {onClickClear && (
        <div
          style={{
            display: hideClear ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="nucleus-ml-2"
        >
          <a onClick={onClickClear} role="button" style={{ cursor: "pointer" }}>
            Clear
          </a>
        </div>
      )}
    </div>
  );
};

export default NucleusInput;
