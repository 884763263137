import NucleusTable from '../../../../components/NucleusTable';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  fetchMdmPolicyData,
  fetchMdmPolicyDetailsData,
  setPolicyFormStatus,
  setPolicyScreen,
  deleteMdmPolicyData,
  resetDeleteMdmPolicyState,
  resetMDMPolicyState,
} from '@nucleus-care/nucleuscare-backend-client';
import { SpinnerCircular } from 'spinners-react';
import moment from 'moment';
import NucleusSearchInput from '../../../../components/NucleusSearchInput';
import NucleusModal from '../../../../components/NucleusModal';
import AuthStore from '../../../../stores/AuthStore';

const PoliciesTable = () => {
  const history = useHistory();
  const [policiesSearch, setPoliciesSearch] = useState('');
  const [policyString, setPolicyString] = useState('');
  const [viewPolicy, setViewPolicy] = useState(false);
  const [policyModalIsOpen, setPolicyModalIsOpen] = useState(false);
  const [deletePolicyModalIsOpen, setDeletePolicyModalIsOpen] = useState(false);
  const [affectedDevices, setAffectedDevices] = useState(0);
  const [policyId, setPolicyId] = useState('');
  const isSuperUser = AuthStore.superUser();

  const dispatch = useDispatch();
  const {
    loading,
    success,
    error,
    Policies,
    PolicyDetails,
    deleteMdmPolicyState,
  } = useSelector(({ mdmPolicy }) => mdmPolicy.data);

  const { success: deleteMdmPolicyDataSuccess } = deleteMdmPolicyState;

  useEffect(() => {
    dispatch(fetchMdmPolicyData());
  }, []);

  // reload policies whenever a policy template is deleted
  useEffect(() => {
    if (deleteMdmPolicyDataSuccess) {
      dispatch(fetchMdmPolicyData());
      dispatch(resetDeleteMdmPolicyState());
    }
  }, [deleteMdmPolicyDataSuccess]);

  function formattedDate({ cell }) {
    const formatted = moment(cell.value).format('MM/DD/YYYY hh:mm A');

    return <span>{formatted}</span>;
  }

  const caseInsensitiveSort = (rowA, rowB, columnId) => {
    const a = rowA.values[columnId];
    const b = rowB.values[columnId];
    return a?.localeCompare(b);
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'Name',
      sortType: caseInsensitiveSort,
    },
    {
      Header: 'Version',
      accessor: 'Version',
    },
    {
      Header: 'Devices',
      accessor: 'Devices',
    },
    {
      Header: 'Created',
      accessor: 'Created',
      Cell: formattedDate,
    },
    {
      Header: 'Modified',
      accessor: 'LastModified',
      Cell: formattedDate,
    },
    {
      Header: 'Modified by',
      accessor: 'ModifiedByName',
      sortType: caseInsensitiveSort,
      Cell: ({ row, cell }) => {
        return (
          <div
            style={{
              display: 'flex',
              width: '60%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span>{cell.value}</span>
          </div>
        );
      },
    },
    {
      Header: '',
      accessor: 'DeleteIcon',
      disableSortBy: true,
      Cell: ({ row, cell }) => {
        if (!row.original.Protected) {
          return (
            <img
              src="./img/policies_trash.svg"
              onClick={(event) => {
                event.stopPropagation();
                console.log('deleting policy: ', row.original.ID);
                setDeletePolicyModalIsOpen(true);
                setPolicyId(row.original.ID);
                setAffectedDevices(row.original.Devices || 0);
              }}
            />
          );
        } else {
          return <img src="./img/lock.svg" />;
        }
      },
    },
  ];

  if (viewPolicy) {
    columns.push({
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true,
      Cell: ({ row }) => (
        <button
          onClick={(event) => {
            event.stopPropagation();
            setPolicyModalIsOpen(true);
            dispatch(fetchMdmPolicyDetailsData(row.original.ID));
          }}
        >
          View
        </button>
      ),
    });
  }

  const { search } = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(search);
    const viewPolicy = params.get('viewPolicy');
    if (isSuperUser && viewPolicy?.toLowerCase() === 'true') {
      setViewPolicy(true);
    }
  }, [search]);
  useEffect(() => {
    if (loading || !success || !PolicyDetails?.PolicyTemplate?.PolicyDetails) {
      return;
    }
    const policyJSONParsed = JSON.parse(
      PolicyDetails.PolicyTemplate.PolicyDetails,
    );
    const policyString = JSON.stringify(policyJSONParsed, null, 4);
    setPolicyString(policyString);
  }, [PolicyDetails, loading, success]);

  const filteredAndSortedPolicies = Policies.filter((policy) => {
    return (
      (policy.Name || '').toLowerCase().includes(policiesSearch) ||
      (policy.OrganizationName || '').toLowerCase().includes(policiesSearch) ||
      (policy.Version || '').toLowerCase().includes(policiesSearch) ||
      (policy.ModifiedByName || '').toLowerCase().includes(policiesSearch) ||
      (policy.ModifiedByEmail || '').toLowerCase().includes(policiesSearch) ||
      moment(policy.Created)
        .format('MM/DD/YYYY hh:mm A')
        .toLowerCase()
        .includes(policiesSearch) ||
      moment(policy.LastModified)
        .format('MM/DD/YYYY hh:mm A')
        .toLowerCase()
        .includes(policiesSearch)
    );
  }).sort((a, b) => {
    // sort by Protected and then by Name
    if (a.Protected && !b.Protected) {
      return -1;
    } else if (!a.Protected && b.Protected) {
      return 1;
    }
    return a.Name.localeCompare(b.Name);
  });
  const handleRowClick = (info) => {
    dispatch(resetMDMPolicyState());
    const clickedPolicyID = info.row.original.ID;
    dispatch(fetchMdmPolicyDetailsData(clickedPolicyID));
    dispatch(
      setPolicyFormStatus({
        operationType: 'UPDATE',
        PolicyTemplateID: clickedPolicyID,
        isProtectedPolicy: info.row.original.Protected,
        isEditable: !info.row.original.Protected,
      }),
    );
    dispatch(setPolicyScreen({ activeMDMScreen: 'general' }));
    dispatch(setPolicyScreen({ activeMainScreen: 'policies' }));
    history.push(`/policyDetails/${clickedPolicyID}`);
  };
  const handleCancel = () => {
    setDeletePolicyModalIsOpen(false);
  };
  const handleDelete = () => {
    dispatch(deleteMdmPolicyData(policyId));
    setDeletePolicyModalIsOpen(false);
  };
  return (
    <div>
      <NucleusSearchInput
        onChange={(e) => {
          const value = e.target.value;
          setPoliciesSearch(value.toLowerCase());
        }}
        value={policiesSearch}
        placeholder="Filter by Name / Enterprise / Modified"
      />
      <NucleusTable
        columns={columns}
        data={filteredAndSortedPolicies}
        onRowClick={handleRowClick}
      />
      <div class="row center-align">
        {loading && (
          <SpinnerCircular
            color="#2096F3"
            secondaryColor="rgba(0,0,0,0.16)"
            size="50"
            thickness="100"
          />
        )}
        {error && <span>An error occurred</span>}
      </div>
      {policyModalIsOpen && (
        <NucleusModal
          setIsOpen={() => setPolicyModalIsOpen(!policyModalIsOpen)}
          maxHeight={'600px'}
        >
          <pre style={{ textAlign: 'left' }}>{policyString}</pre>
        </NucleusModal>
      )}
      {deletePolicyModalIsOpen && (
        <NucleusModal
          setIsOpen={() => setDeletePolicyModalIsOpen(!deletePolicyModalIsOpen)}
          maxHeight={'600px'}
          width={400}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <label
              style={{ color: '#FF0000', fontSize: 60, fontWeight: 'bold' }}
            >
              WARNING!
            </label>
            <br />
            <label
              style={{ fontSize: 20, color: '#0A313F' }}
            >{`This will affect ${affectedDevices ?? 0} devices`}</label>
            <br />
            <label style={{ fontSize: 20, color: '#0A313F' }}>
              Would you like to proceed?
            </label>
            <div className="modalActions">
              <div className="actionsContainer mt-30">
                <button
                  className="deleteBtnEnterpriseModal"
                  onClick={handleCancel}
                >
                  CANCEL
                </button>
                <button
                  className="cancelBtnEnterpriseModal"
                  onClick={handleDelete}
                >
                  PROCEED
                </button>
              </div>
            </div>
          </div>
        </NucleusModal>
      )}
    </div>
  );
};

export default PoliciesTable;
