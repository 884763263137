import React from 'react';
import AuthStore from "../stores/AuthStore";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import Message from "../utils/Message";
import CarePatientStore from "../stores/CarePatientStore";
import CareAccountStore from '../stores/CareAccountStore';
import GeneralStore from "../stores/GeneralStore";
import TelephoneInputUnderline from '../components/UI/Inputs/TelephoneInputUnderlined';
import { validateAccessGranted } from '../utils/accessGrantedValidator';

class NewFamilyMember extends React.Component {

    constructor() {
        super();
        validateAccessGranted("/newFamilyMember");
        this.state = {
            panel : "Form",
            newFamilyMemberFullName : '',
            btnStyle : "disabled",
            errorEmailVisibility : 'hide',
            errorEmailText : 'Email Address already registered',
            errorTelephoneVisibility : 'hide',
            errorTelephoneText : 'Telephone already registered',
            familyMemberExists : false,
            familyMemberID : '',
            familyMemberFirstName : '',
            familyMemberLastName : '',
            familyMemberEmail : '',
            familyMemberTelephone : '',
            familyMemberAddress : ''
        };
        this.submitFamilyMemberForm = this.submitFamilyMemberForm.bind(this);
        this.onCreateNewFamilyMemberAction = this.onCreateNewFamilyMemberAction.bind(this);
        this.validateTxtInput = this.validateTxtInput.bind(this);
        this.onTelephoneInputChange = this.onTelephoneInputChange.bind(this);
        this.validateExistingTelephone = this.validateExistingTelephone.bind(this);
        this.onCheckExistingTelephoneAction = this.onCheckExistingTelephoneAction.bind(this);
        this.handleCancelExistingFamilyMember = this.handleCancelExistingFamilyMember.bind(this);
        this.handleAddExistingFamilyMember = this.handleAddExistingFamilyMember.bind(this);
        this.onPatientMemberAssignedAction = this.onPatientMemberAssignedAction.bind(this);

        this.typingTimer = null;
        this.doneTypingInterval = 5000;
    }

    componentDidMount() {
        CarePatientStore.on("onCreateNewFamilyMember", this.onCreateNewFamilyMemberAction);
        CarePatientStore.on("onCheckExistingTelephone", this.onCheckExistingTelephoneAction);
        CarePatientStore.on("onPatientMemberAssigned", this.onPatientMemberAssignedAction);
        window.$('.modalNewFamilyMember').modal({
              dismissible: false,
              complete: function() {  }
            }
        );
    }

    componentWillUnmount() {
        CarePatientStore.removeListener("onCreateNewFamilyMember", this.onCreateNewFamilyMemberAction);
        CarePatientStore.removeListener("onCheckExistingTelephone", this.onCheckExistingTelephoneAction);
        CarePatientStore.removeListener("onPatientMemberAssigned", this.onPatientMemberAssignedAction);
        clearTimeout(this.typingTimer);
    }
    
		onTelephoneInputChange(telephoneNumber){
			console.log("onTelephoneInputChange telephoneNumber", telephoneNumber);
			//this.state.familyMemberTelephone = telephoneNumber;
			if (telephoneNumber.length > 0){
				this.setState({
					familyMemberTelephone : telephoneNumber,
					btnStyle : "",
					errorTelephoneVisibility : 'hide'
				});
			}else{
				this.setState({
					familyMemberTelephone : telephoneNumber,
					btnStyle : "disabled"
				});
			}
			this.validateTxtInput();
    };

    validateTxtInput = () => {
        const {
            txtNewFamilyMemberFirstName,
            txtNewFamilyMemberLastName,
        } = this.refs;

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
					let familyMemberTelephone = this.state.familyMemberTelephone;
					if (familyMemberTelephone === '+1') {
						familyMemberTelephone = '';
					}
            if (
                txtNewFamilyMemberFirstName.value.length > 0 &&
                txtNewFamilyMemberLastName.value.length > 0 &&
                familyMemberTelephone.length > 0
            ) {
                this.setState({
                    btnStyle: ''
                });
            } else {
                this.setState({
                    btnStyle: 'disabled'
                });
            }
            if (familyMemberTelephone.length > 0) {
                this.setState({
                    errorTelephoneVisibility: 'hide'
                });
                this.validateExistingTelephone();
            }
        }, this.doneTypingInterval);
    };


    validateExistingTelephone(){
			console.log("validateExistingTelephone");
			let familyMemberTelephone = this.state.familyMemberTelephone;
			if (familyMemberTelephone === '+1') {
				familyMemberTelephone = '';
			}
			if (familyMemberTelephone.length > 0) {
				familyMemberTelephone = familyMemberTelephone.replace(/[()-\s]/g, '');
				if (familyMemberTelephone.length < 12){
					this.setState({
						errorEmailVisibility : 'hide',
						errorTelephoneVisibility : '',
						errorTelephoneText : 'Invalid Telephone. It must be at least 10 digits.'
					});
					return;
				}
				CarePatientStore.checkExistingTelephone({
					Telephone : familyMemberTelephone
				});
			}
		}

    submitFamilyMemberForm(){
        const {match} = this.props
        console.log("submitFamilyMemberForm");
        if (this.refs.txtNewFamilyMemberEmail.value.length > 0 && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(this.refs.txtNewFamilyMemberEmail.value)) {
            Message.show('Invalid Email.');
            this.refs.txtNewFamilyMemberEmail.focus();
            return;
        }

        if (this.refs.txtNewFamilyMemberFirstName.value == "") {
            Message.show('FirstName is required.');
            return;
        }

        if (this.refs.txtNewFamilyMemberLastName.value === "") {
            Message.show('LastName is required.');
            return;
        }

        // if (this.refs.txtNewFamilyMemberAddress.value === "") {
        //     Message.show('Ups! The address is not valid.');
        //     return;
        // }

        let familyMemberTelephone = this.state.familyMemberTelephone;
				if (familyMemberTelephone === '+1') {
					familyMemberTelephone = '';
				}
        if (familyMemberTelephone.length > 0) {
					familyMemberTelephone = familyMemberTelephone.replace(/[()-\s]/g, '');
					if (familyMemberTelephone.length < 12){
						this.setState({
							errorEmailVisibility : 'hide',
							errorTelephoneVisibility : '',
							errorTelephoneText : 'Invalid Telephone. It must be at least 10 digits.'
						});
						return;
					}

					let containsLetter = /[a-zA-Z]/.test(familyMemberTelephone);
					if (containsLetter){
						this.setState({
							errorEmailVisibility : 'hide',
							errorTelephoneVisibility : '',
							errorTelephoneText : 'Invalid Telephone.'
						});
						return;
					}
        }
				console.log("NewFamilyMember PatientID:", match.params.patientId);
				const newFamilyMemberData = {
					PatientID : match.params.patientId,
					FirstName : this.refs.txtNewFamilyMemberFirstName.value,
					LastName : this.refs.txtNewFamilyMemberLastName.value,
					Email: this.refs.txtNewFamilyMemberEmail.value,
					Telephone: familyMemberTelephone,
					Address: this.refs.txtNewFamilyMemberAddress.value,
					ReceiveUpdates : true // Deprecated Oct 13th 2017 CARE-1236 -> this.refs.checkReceiveUpdates.checked
				}
        this.setState({
            btnStyle : "disabled"
        });
        CarePatientStore.createNewFamilyMember(newFamilyMemberData);
    }


    onCreateNewFamilyMemberAction(response){
        const {match} = this.props
        if (response.ok){
            // this.setState({
            //     panel : "Message",
            //     newFamilyMemberFullName : this.refs.txtNewFamilyMemberFirstName.value + ' ' + this.refs.txtNewFamilyMemberLastName.value
            // });

            // CarePatientStore.getAllPatientsData({
            //    UserID : AuthStore.getUserID()
            // });

            window.location.assign("#/accountUserPatientProfile/" + match.params.patientId + "/" + CareAccountStore.getFamilyMemberAccountId());
            setTimeout(function(){
                Message.show(AuthStore.getFamilyLabel()+" created");
            }, 100);
        } else if (response.emailRegistered) {
            this.setState({
                errorEmailVisibility : '',
                errorTelephoneVisibility : 'hide',
                errorEmailText : 'Email Address already registered'
            });
        } else if (response.telephoneRegistered) {
            this.setState({
                errorEmailVisibility : 'hide',
                errorTelephoneVisibility : '',
                errorTelephoneText : 'Telephone already registered'
            });
        }else if (response.errorType == 1) {
            this.setState({
                errorEmailVisibility : '',
                errorTelephoneVisibility : 'hide',
                errorEmailText : response.error
            });
        } else {
            this.setState({
                errorEmailVisibility : 'hide',
                errorTelephoneVisibility : '',
                errorTelephoneText : response.error
            });
        }

        this.setState({
            btnStyle : ""
        });
    }


    onCheckExistingTelephoneAction(response){
        console.log("onCheckExistingTelephoneAction");
        console.log(response);

        if (response.ok){
            if (response.DoesExists){
                this.setState({
                    familyMemberExists : true,
                    familyMemberID : response.FamilyMemberID,
                    familyMemberEmail : this.refs.txtNewFamilyMemberEmail.value,
                    familyMemberFirstName : response.FirstName,
                    familyMemberLastName : response.LastName,
                    familyMemberTelephone : response.Telephone
                });
                window.$('#modalExistingFamilyMember').modal('open');
            }else{
                this.setState({
                    familyMemberExists : false
                });
            }
        } else {
            Message.show("Error getting Information");
        }
    }

    handleCancelExistingFamilyMember(){
        window.$('#modalExistingFamilyMember').modal('close');
        GeneralStore.requestHandleBack();
    }

    handleAddExistingFamilyMember(){
        const {match} = this.props
        CarePatientStore.assignPatientMember({
            FamilyMemberID : this.state.familyMemberID,
            PatientID : match.params.patientId
        });
    }


    onPatientMemberAssignedAction(response){
        const {match} = this.props
        window.$('#modalExistingFamilyMember').modal('close');
        if (response.ok){

            window.location.assign("#/accountUserPatientProfile/" + match.params.patientId + "/" + CareAccountStore.getFamilyMemberAccountId());

            setTimeout(function(){
                Message.show("Family Member assigned.");
            }, 100);

        } else {
            if (response.assigned){
                window.location.assign("#/accountUserPatientProfile/" + match.params.patientId + "/" + CareAccountStore.getFamilyMemberAccountId());
                setTimeout(function(){
                    Message.show("This "+AuthStore.getFamilyLabel()+" is already assigned to this " + AuthStore.getPatientLabel() + ".");
                }, 100);
            }else{
                Message.show("Error assigning new Family Member");
            }
        }
    }

    render() {
        const {match} = this.props
        let formVisibility = classNames({
            hide : (this.state.panel != "Form")
        });
        let messageVisibility = classNames({
            hide : (this.state.panel != "Message")
        });
        return (
            <div className="nucleus-hard-container">
            <div className="nucleus-all-scrollable-page">
                <p className="nucleus-page-title">New {AuthStore.getFamilyLabel()}</p>
                <br/>

                <div className={ "nucleus-patient-form-pending " + formVisibility} >
                    <br/>
                    <div className="row">
                        <div className="col s2 m1 l1">

                        </div>
                        <div className="col s8 m8 l6">

                        <div className="row">
                                <div className="col s4 left-align">
                                    <span className=" nucleus-labels"> Telephone</span> <span className="nucleus-labels txt-red">*</span>
                                </div>
                                <div className="col s8">
																<TelephoneInputUnderline
																	inputId="txtNewFamilyMemberTelephone"
																	fieldId="newPhone"
																	value=""
																	onChange={this.onTelephoneInputChange}
																	onBlur={this.validateExistingTelephone}
																/>
                                    <span className={"nucleus-form-error left-align txt-black-forced " }>Click the country code icon for more country codes</span><br/>
                                    <span className={"nucleus-form-error left-align " + this.state.errorTelephoneVisibility }>{this.state.errorTelephoneText}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s4 valgin-wrapper left-align">
                                    <span className=" nucleus-labels"> Email</span>
                                </div>
                                <div className="col s8">
                                    <input 
																			ref="txtNewFamilyMemberEmail" 
																			type="text" 
																			className="validate nucleus-input-form txtEmail" 
																			placeholder="Email" 
																			onChange={this.validateTxtInput}
																		/>
                                    <span className={"nucleus-form-error center-align " + this.state.errorEmailVisibility }> { this.state.errorEmailText}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s4 valgin-wrapper left-align">
                                    <span className=" nucleus-labels"> First Name</span> <span className="nucleus-labels txt-red">*</span>
                                </div>
                                <div className="col s8">
                                    <input 
																			ref="txtNewFamilyMemberFirstName" 
																			type="text" 
																			className="validate nucleus-input-form" 
																			placeholder="First Name" 
																			onChange={this.validateTxtInput}
																		/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s4 valgin-wrapper left-align">
                                    <span className=" nucleus-labels"> Last Name</span> <span className="nucleus-labels txt-red">*</span>
                                </div>
                                <div className="col s8">
                                    <input 
																			ref="txtNewFamilyMemberLastName" 
																			type="text" 
																			className="validate nucleus-input-form" 
																			placeholder="Last Name" 
																			onChange={this.validateTxtInput}
																		/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s4 left-align">
                                    <span className=" nucleus-labels"> Address</span>
                                </div>
                                <div className="col s8">
                                    <input 
																			ref="txtNewFamilyMemberAddress" 
																			type="text" 
																			className="validate nucleus-input-form" 
																			placeholder="1234 Street Lane, New City, NC 00000" 
																			onChange={this.validateTxtInput}
																		/>
                                </div>
                            </div>

                            { /*
                                // Deprecated Oct 13th 2017, CARE-1236
                            <div class="row ">
                                <div class="col s4 left-align">
                                    <span class=" nucleus-labels"> Receive Updates</span>
                                </div>
                                <div class="col s8">
                                    <div class="switch">
                                        <label >
                                          <input ref="checkReceiveUpdates" type="checkbox"/>
                                          <span class="lever"></span>
                                        </label>
                                      </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col s4">
                                </div>
                                <div class="col s8 m8 l8 center-align">
                                    <a class={"txt-white nucleus-font-medium " + this.state.btnStyle} onClick={this.submitFamilyMemberForm}>Submit</a>
                                </div>
                            </div>
                            */ }
                        </div>
                        <div className="col s2 m3 l5">
                        </div>
                    </div>

                    <div className="fixed-action-btn btn-add-new">
                        <a className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped "
                            disabled={this.state.btnStyle}
                            data-position="left"
                            data-tooltip="Save new Family Member"
                            onClick={this.submitFamilyMemberForm}
														style={{marginRight:24, marginBottom:12}}
													>
                          <i className="material-icons">save</i>
                        </a>
                    </div>

                </div>

                <div ref="nucleus-new-patient-confirmation-pending" className={ "nucleus-new-patient-form-pending " + messageVisibility }>
                    <br/>
                    <div className="row">
                        <div className="col s12">
                            <p className=" nucleus-labels"> Complete! The family member { this.state.newFamilyMemberFullName } has been created.  </p>
                               <Link to={"/patientProfile/" + match.params.patientId + "/null" }>
                                    <span className="nucleus-link nucleus-font-medium">Go to { AuthStore.getPatientLabelPlural() } Page </span>
                               </Link>
                        </div>
                    </div>
                </div>

                <div id="modalExistingFamilyMember" className="modal modalNewFamilyMember modalExistingFamilyMember">
                    <div className="modal-content modal-content-delete">
                        <h3 className="nucleus-page-subtitle"> Existing {AuthStore.getFamilyLabel()}</h3>
                        <p></p>
                        <div className="row">
                            <div className="col s12 left-align nucleus-font-medium">
                                { "The "+AuthStore.getFamilyLabel()+" "+ this.state.familyMemberFirstName + " " + this.state.familyMemberLastName + " with Phone " + this.state.familyMemberTelephone + " is already a "+AuthStore.getFamilyLabel()+" of an existing " + AuthStore.getPatientLabel().toLowerCase() + "." }
                                <br/>
                                Do you want to add him/her to this patient as well?
                                <br/>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col s12 right-align ">
                                <p></p>
                                <a 
																	className="txt-white nucleus-font-small nucleus-link" 
																	onClick={this.handleCancelExistingFamilyMember}
																	style={{marginRight:36}}
																>
																		Cancel
																</a>
                                <a 
																	className="txt-white nucleus-font-small nucleus-submit-btn-small" 
																	onClick={this.handleAddExistingFamilyMember}
																>
																	Confirm
																</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </div>
        );
    }
}

export default NewFamilyMember;
