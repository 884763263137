import classNames from "classnames";
import React from "react";
import { availableMDMTabs } from "../../utils/mdmPoliciesConstants";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPolicyScreen } from '@nucleus-care/nucleuscare-backend-client';

const MDMNavTabs = ({ handleTabs, activeTab, type }) => {
  const { policyJSON } = useSelector(({ mdmPolicy }) => mdmPolicy.state);
  const dispatch = useDispatch();
  const history = useHistory();
  const shouldShowIcon = (tab) => {
    if (type === "policies" && policyJSON) {
      if (tab.slug === "apps") {
        return !!policyJSON?.applications?.length;
      }
      return tab?.fields?.some((field) => policyJSON[field]);
    }
    return false;
  };
  const defaultHandler = (type, tabName, route) => {
    switch (type) {
      case "main":
        dispatch(setPolicyScreen({ activeMainScreen: tabName }));
        if (route) history.push(route);
        break;
      case "policies":
        dispatch(setPolicyScreen({ activeMDMScreen: tabName }));
        if (route) history.push(route);
        break;
      default:
        break;
    }
  };
  return (
    <div
      className={
        type === "main" ? "mdm-main-tabs-container" : "mdm-tabs-container"
      }
    >
      {availableMDMTabs[type].map((tab) => (
        <span key={tab.slug}>
          <div
            style={{
              position: "absolute",
              display: "flex",
            }}
          >
            {shouldShowIcon(tab) && (
              <span
                style={{
                  position: "absolute",
                  left: -4,
                  top: -4,
                  width: 0,
                  height: 0,
                  borderLeft: "10px solid #00C444",
                  borderBottom: "10px solid transparent",
                  borderRight: "10px solid transparent",
                }}
              ></span>
            )}
          </div>
          <a
            className={classNames(
              type === "main" ? "staffTabsMain" : "staffTabs",
              activeTab === tab.slug &&
                (type === "main" ? "staffTabMain" : "staffTab"),
              tab.disabled && "disabledTab"
            )}
            onClick={() => {
              if (tab.disabled) return;
              if (handleTabs) return handleTabs(type, tab.slug);
              defaultHandler(type, tab.slug, tab.route);
            }}
          >
            {tab.title}
          </a>
        </span>
      ))}
    </div>
  );
};

export default MDMNavTabs;
