import React from 'react'
import { Route, useLocation, Redirect, useRouteMatch } from 'react-router-dom'
import { useUserMeSelector } from "../selectors/user";

export function PrivateRoute ({ children, ...rest }) {
  const user = useUserMeSelector()
  const location = useLocation()
  const match = useRouteMatch(rest.path)
  let child = children

  if (!user) {
    child = (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location }
        }}
      />
    )
  }
  child = React.cloneElement(child, {
    match
  })
  // Doing this causes that all the components are re-rendered
  // even if they are not visible, we need to stop using this one
  // unless is necessary
  return <Route {...rest} >{child}</Route>
}


export function PrivateRouteComponent ({ path, component, ...rest }: { readonly path: string, readonly component: React.ComponentType<typeof Route> } ) {
  const user = useUserMeSelector()
  const location = useLocation()

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location }
        }}
      />
    )
  }
  
  return <Route exact component={component} path={path} {...rest} />
}
