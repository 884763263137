import React, { useCallback, useEffect, useState } from 'react';
import { UINucleusContentContainer } from '../../../components/UI/NucleusContainer/Content';
import { NucleusControlledWhiteBgInput } from '../../../components/NucleusControlledInput';
import { UIButton, UICard } from '../../../components/UI';
import { useForm } from 'react-hook-form';
import { useNucleusProviders, ProviderEscalationConfigResponse } from '../../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { getProviderEscalationConfig, updateProviderEscalationConfig } from '../utils/providerApi';
import Message from '../../../utils/Message';

const ProviderEscalationConfig = () => {
  const {
    dispatch,
    state: {
      provider: {
        data,
        state: { loading },
      },
    },
  } = useNucleusProviders();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    if (data?.ID) fetchProviderEscalationConfig(data?.ID);
  }, [data?.ID]);

  const fetchProviderEscalationConfig = async (providerId): Promise<ProviderEscalationConfigResponse> => {
    setIsLoading(true);
    const response = await getProviderEscalationConfig(providerId);
    if (response?.ID) {
      setValue('EscalationPhoneNumber', response?.EscalationPhoneNumber);
      setValue('EscalationEmail', response?.EscalationEmail);
      setValue('FirstEscalationMinutes', response?.FirstEscalationMinutes);
      setValue('SecondEscalationMinutes', response?.SecondEscalationMinutes);
    }
    setIsLoading(false);
    return response;
  };

  const disableForm = useCallback(() => {
    if (isLoading) {
      return true;
    }
    return false;
  }, [isLoading]);

  const disableSubmit = useCallback(() => {
    if (isLoading) return true;
    if (!watch('EscalationPhoneNumber') && !watch('EscalationEmail')) return true;
    if (!(typeof Number(watch('FirstEscalationMinutes')) === 'number' && watch('FirstEscalationMinutes') > 0)) return true;
    if (!(typeof Number(watch('SecondEscalationMinutes')) === 'number' && watch('SecondEscalationMinutes') > 0)) return true;
    return false;
  }, [data, isLoading]);

  const onSubmitEscalationConfig = async (formData) => {
    if (!data?.ID) return;
    const escalationPhoneNumber = formData.EscalationPhoneNumber || '';
    const escalationEmail = formData.EscalationEmail || '';
    const firstMins = formData.FirstEscalationMinutes;
    const secondMins = formData.SecondEscalationMinutes;

    const phoneNumberRegex = /^(?:\+\d{1,3})?\d{10,11}$/;
    if (escalationPhoneNumber !== '' && !phoneNumberRegex.test(escalationPhoneNumber)) {
      Message.warning('Not valild Phone number');
      return;
    }

    const emailRegex = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
    if (escalationEmail !== '' && !emailRegex.test(escalationEmail)) {
      Message.warning('Not valild Email');
      return;
    }

    const firstWarningMins = Number(firstMins);
    const secondWarningMins = Number(secondMins);

    if (firstWarningMins < 0 || firstWarningMins > 999) {
      Message.warning('"1st after" value should be between 1 and 999.');
      return;
    }

    if (secondWarningMins < 0 || secondWarningMins > 999) {
      Message.warning('"2nd after" value should be between 1 and 999.');
      return;
    }

    if (secondWarningMins <= firstWarningMins) {
      Message.warning('"2nd after" value should be greater than "1st after" value.');
      return;
    }

    const formValues = {
      EscalationPhoneNumber: escalationPhoneNumber || null,
      EscalationEmail: escalationEmail || null,
      FirstEscalationMinutes: firstWarningMins,
      SecondEscalationMinutes: secondWarningMins,
    };

    setIsLoading(true);
    const response = await updateProviderEscalationConfig({
      providerId: data?.ID,
      escalationConfigForm: formValues,
    });
    setIsLoading(false);
    if (response?.providerEscalationConfigUpdate) {
      Message.show('Provider Escalation Config updated');
      fetchProviderEscalationConfig(data?.ID);
    } else {
      Message.error('Error updating Provider Escalation Config');
    }
  };

  return (
    <UINucleusContentContainer style={{ paddingTop: 30 }}>
      <UICard
        title="Escalation Notifications"
        cardHeaderStyle={{
          fontFamily: 'Barlow',
          fontSize: '20px',
          fontStyle: 'italic',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0px',
          textAlign: 'left',
        }}
        info="*you must provide a phone number and/or email"
        infoStyle={{
          fontFamily: 'Barlow',
          fontSize: '12px',
          fontStyle: 'italic',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0px',
          textAlign: 'left',
          marginTop: '-24px',
          color: '#FF4C3F',
        }}
        style={{
          backgroundColor: '#EBF2FE',
          width: '27vw',
          paddingBottom: 20,
        }}
      >
        <UINucleusContentContainer>
          <form onSubmit={handleSubmit(onSubmitEscalationConfig)}>
            <NucleusControlledWhiteBgInput
              disabled={disableForm()}
              label={'Phone Number:'}
              name={'EscalationPhoneNumber'}
              register={register('EscalationPhoneNumber', { required: false })}
              error={errors.EscalationPhoneNumber}
              clearErrors={clearErrors}
              setValue={setValue}
              onChange={(e) => {
                setValue('EscalationPhoneNumber', e.target.value);
              }}
              inputStyle={{
                minHeight: 48,
              }}
              containerStyle={{
                marginBottom: 20,
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            />
            <NucleusControlledWhiteBgInput
              disabled={disableForm()}
              label={'Email:'}
              name={'EscalationEmail'}
              register={register('EscalationEmail')}
              error={errors.EscalationEmail}
              clearErrors={clearErrors}
              setValue={setValue}
              onChange={(e) => {
                setValue('EscalationEmail', e.target.value);
              }}
              inputStyle={{
                minHeight: 50,
              }}
              containerStyle={{
                marginBottom: 20,
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            />
            <div style={{ display: 'flex', gap: 20 }}>
              <div style={{ display: 'flex', flex: 1 }}>
                <NucleusControlledWhiteBgInput
                  disabled={disableForm()}
                  label={'1st after (min):'}
                  name={'FirstEscalationMinutes'}
                  register={register('FirstEscalationMinutes', {
                    required: true,
                  })}
                  error={errors.FirstEscalationMinutes}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  onChange={(e) => {
                    setValue('FirstEscalationMinutes', e.target.value);
                  }}
                  type="number"
                  min="0"
                  max="999"
                  maxLength="3"
                  inputStyle={{
                    minHeight: 48,
                  }}
                  containerStyle={{
                    marginBottom: 20,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginRight: 20,
                    with: 40,
                  }}
                />
              </div>
              <div style={{ display: 'flex', flex: 1 }}>
                <NucleusControlledWhiteBgInput
                  disabled={disableForm()}
                  label={'2nd after (min):'}
                  name={'SecondEscalationMinutes'}
                  register={register('SecondEscalationMinutes', {
                    required: true,
                  })}
                  error={errors.SecondEscalationMinutes}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  onChange={(e) => {
                    setValue('SecondEscalationMinutes', e.target.value);
                  }}
                  type="number"
                  min="0"
                  max="999"
                  maxLength="3"
                  inputStyle={{
                    minHeight: 48,
                  }}
                  containerStyle={{
                    marginBottom: 20,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginRight: 20,
                    with: 40,
                  }}
                />
              </div>
            </div>
            <UIButton disabled={disableSubmit()} onClick={handleSubmit(onSubmitEscalationConfig)} text="Save" variant={disableSubmit() === true ? 'disabled' : 'primary'} />
          </form>
        </UINucleusContentContainer>
      </UICard>
    </UINucleusContentContainer>
  );
};

export default ProviderEscalationConfig;
