export const availableMDMTabs = {
  policies: [
    {
      route: "/policies",
      title: "General",
      slug: "general",
      disabled: false,
      fields: ["systemUpdate"],
    },
    {
      route: "/policies",
      title: "Network & Connectivity",
      slug: "network",
      disabled: false,
      fields: [
        "bluetoothDisabled",
        "deviceConnectivityManagement",
        "cellBroadcastsConfigDisabled",
        "mobileNetworksConfigDisabled",
        "dataRoamingDisabled",
        "networkEscapeHatchEnabled",
        "openNetworkConfiguration",
      ],
    },
    {
      route: "/policies",
      title: "Apps",
      slug: "apps",
      disabled: false,
      fields: ["applications"],
    },
    {
      route: "/policies",
      title: "Screen",
      slug: "screen",
      disabled: false,
      fields: ["keyguardDisabled", "maximumTimeToLock", "stayOnPluggedModes"],
    },
    {
      route: "/policies",
      title: "Security",
      slug: "security",
      disabled: false,
      fields: [
        "factoryResetDisabled",
        "installAppsDisabled",
        "advancedSecurityOverrides",
      ],
    },
  ],
  main: [
    {
      title: "Devices",
      slug: "devices",
      disabled: false,
      route: "/policies/mdmDevices",
    },
    {
      title: "Policies",
      slug: "policies",
      disabled: false,
      route: "/policies",
    },
    {
      title: "Accounts",
      slug: "accounts",
      disabled: false,
      route: "/policies/mdmAccounts",
    },
    {
      title: "Enterprises",
      slug: "enterprises",
      disabled: false,
      route: "/policies/mdmEnterprises",
    },
  ],
};
