import React from 'react';
import classNames from 'classnames';
import Message from '../utils/Message';
import CarePatientStore from '../stores/CarePatientStore';
import AuthStore from '../stores/AuthStore';
import TelephoneInputUnderline from './UI/Inputs/TelephoneInputUnderlined';

import CompressUtil from './CompressUtil';

type Props = {
  patientId?:string;
  patientFirstName?:string;
  patientLastName?:string;
  patientDeviceMac?: string; // Especifica las props y si son opcionales o requeridas
  patientTelephone?: string;
  patientDateOfBirth?: string;
  patientImageThumb?:string;
  patientAddress?:string;
  patientZipCode?: string;
  FirstName?:string;
  currentMode: 'Info'|'Edit';
  handleProfileMode: () => void; 
};

type State = {
  clientImageFilePreviewURL: string;
  name: string;
  deviceMacValue: string;
  errorTelephoneVisibility: string;
  errorTelephoneText: string;
  patientTelephone: string;
  txtPatientDate: Date | null;
  wrongDateInfo: string;
  macPanel?: string;
  uploadingPatientImageStatus?:string;
};

class PatientProfileEdit extends React.Component<Props, State> {
  private dateOptions: Intl.DateTimeFormatOptions; 

  private txtNewPatientFirstNameRef: React.RefObject<HTMLInputElement>;
  private txtNewPatientLastNameRef: React.RefObject<HTMLInputElement>;
  private txtNewPatientAddressRef: React.RefObject<HTMLInputElement>;
  private txtNewPatientZipCodeRef: React.RefObject<HTMLInputElement>;
  private imgRef: React.RefObject<HTMLImageElement>;

  constructor(props) {
    super(props);
    this.state = {
      clientImageFilePreviewURL: '',
      name: '',
      deviceMacValue: this.props.patientDeviceMac || '',
      errorTelephoneVisibility: 'hide',
      errorTelephoneText: 'Telephone already registered ',
			patientTelephone: this.props.patientTelephone || '',
      txtPatientDate: this.props.patientDateOfBirth && this.props.patientDateOfBirth != null ? new Date(this.props.patientDateOfBirth) : null,
      wrongDateInfo: '',
    };

    this.txtNewPatientFirstNameRef = React.createRef();
    this.txtNewPatientLastNameRef = React.createRef();
    this.txtNewPatientAddressRef = React.createRef();
    this.txtNewPatientZipCodeRef = React.createRef();
    this.imgRef = React.createRef();
    

    this.submitUpdateCarePatientForm = this.submitUpdateCarePatientForm.bind(this);
    this.validateTxtInput = this.validateTxtInput.bind(this);
    this.onUpdatePatientResponseArrivedAction = this.onUpdatePatientResponseArrivedAction.bind(this);
    this.onZipCodeUpdatedAction = this.onZipCodeUpdatedAction.bind(this);

    this.onTelephoneInputChange = this.onTelephoneInputChange.bind(this);
    this.selectedPatientImageFile = this.selectedPatientImageFile.bind(this);
    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    console.log('MAC, EDIT: ', this.state.deviceMacValue);
  }

  componentDidMount() {
    console.log('PROPS', this.props);
    CarePatientStore.on('onUpdatePatientResponseArrived', this.onUpdatePatientResponseArrivedAction);
    CarePatientStore.on('onZipCodeUpdated', this.onZipCodeUpdatedAction);
    this.setState({
      name: this.props.FirstName,
			patientTelephone: this.props.patientTelephone || '',
      macPanel: this.props.patientDeviceMac == 'No Device assigned' ? ' hide ' : '',
      txtPatientDate: this.props.patientDateOfBirth && this.props.patientDateOfBirth != null ? new Date(this.props.patientDateOfBirth) : null,
    });
  }

  componentWillReceiveProps(updatedProps) {
    //console.log("PatientProfileEdit componentWillReceiveProps", updatedProps);
    this.setState({
			patientTelephone: this.props.patientTelephone || '',
      txtPatientDate: updatedProps.patientDateOfBirth && updatedProps.patientDateOfBirth != null ? new Date(updatedProps.patientDateOfBirth) : null,
    });
  }

  componentWillUnmount() {
    CarePatientStore.removeListener('onUpdatePatientResponseArrived', this.onUpdatePatientResponseArrivedAction);
    CarePatientStore.removeListener('onZipCodeUpdated', this.onZipCodeUpdatedAction);
  }

  validateTxtInput() {
    if (this.state.patientTelephone.length > 0) {
      this.setState({
        errorTelephoneVisibility: 'hide',
      });
    }
  }

  onTelephoneInputChange(phoneNumber) {
		console.log('onTelephoneInputChange', phoneNumber);
      if (phoneNumber > 0) {
        this.setState({
          errorTelephoneVisibility: 'hide',
        });
      }
      this.setState({
				patientTelephone:phoneNumber,
      });
  }

  submitUpdateCarePatientForm() {
    console.log('submitUpdateCarePatientForm');

    if (this.txtNewPatientFirstNameRef.current.value === '') {
      Message.show('FirstName is required.');
      return;
    }

    if (this.txtNewPatientLastNameRef.current.value === '') {
      Message.show('LastName is required.');
      return;
    }

		let patientTelephone = this.state.patientTelephone;
    if (patientTelephone === '+1') {
      patientTelephone = '';
    }
    if (patientTelephone.length > 0) {
      patientTelephone = patientTelephone.replace(/[()-\s]/g, '');
			if (patientTelephone.length > 0 && patientTelephone.length < 12) {
				this.setState({
					errorTelephoneVisibility: '',
					errorTelephoneText: 'Telephone must be at least 10 digits',
				});
				return;
			}
			let containsLetter = /[a-zA-Z]/.test(patientTelephone);
			if (containsLetter) {
				this.setState({
					errorTelephoneVisibility: '',
					errorTelephoneText: 'Invalid Telephone.',
				});
				return;
			}
			// const phoneNumberRegex = /^(?:\+\d{1,3})?\d{10,10}$/;
			// if (!phoneNumberRegex.test(patientTelephone)) {
			// 	this.setState({
			// 		errorTelephoneVisibility: '',
			// 		errorTelephoneText: 'Invalid Telephone.',
			// 	});
			// 	return;
			// }
    }

    const updateAccountPatientData = {
      PatientID: this.props.patientId,
      FirstName: this.txtNewPatientFirstNameRef.current.value,
      LastName: this.txtNewPatientLastNameRef.current.value,
      Telephone: patientTelephone,
      Address: this.txtNewPatientAddressRef.current.value,
      //DateOfBirth: patientDateParam,
      //EmergencyContactName: this.refs.txtNewPatientContactName.value,
      //EmergencyContactTelephone: this.refs.txtNewPatientContactNumber.value,
      ZipCode: this.txtNewPatientZipCodeRef.current.value,
      //ShowWeather : this.refs.checkShowWeather.checked
    };

    CarePatientStore.UpdatePatientData(updateAccountPatientData);
  }

  onUpdatePatientResponseArrivedAction(response) {
    console.log('onUpdatePatientResponseArrivedAction', response);
    if (!response.ok && response.telephoneRegistered) {
      this.setState({
        errorTelephoneVisibility: '',
        errorTelephoneText: response.error,
      });
    }
    // CarePatientStore.updatePatientZipCode({
    //     PatientID : this.props.patientId,
    //     ZipCode : this.refs.txtNewPatientZipCode.value
    // });
  }

  onZipCodeUpdatedAction(response) {
    if (!response.ok) {
      Message.show('Error updating ' + AuthStore.getPatientLabel() + ' Zip Code');
    }
  }
  selectedPatientImageFile(e) {
    let files = e.target.files;
    if (files.length > 0) {
      console.log('selectedPatientImageFile', e);
      let value = files[0];
      CompressUtil(files[0]).then((data) => {
        console.log('data', data);
        let selectedImage = data;
        let reader = new FileReader();
        let url = reader.readAsDataURL(value);
        let urlImage = URL.createObjectURL(value);
        // reader.onloadend = function (e) {
        this.setState({
          clientImageFilePreviewURL: urlImage,
        });
        // };
        console.log('LocalURL image:patient:', [reader.result]);
        console.log('LocalURL:', url); // Would see a path?
        console.log('selectedImage', selectedImage);
        this.setState({
          uploadingPatientImageStatus: 'Uploading...',
        });
        CarePatientStore.UploadPatientImage({
          PatientID: this.props.patientId,
          File: selectedImage,
        });
      });
    } else {
      console.log('No File Selected');
    }
  }

  render() {
    let currentSection = classNames({
      hide: this.props.currentMode != 'Edit',
    });

    // console.log("PROPS2");
    // console.log(this.props);
    // console.log("PROPS2");

    let deviceMacAddress = '00:00:00:00:00';
    let deviceVisibility = ' hide';
    let noDeviceVisibility = '';
    if (this.props.patientDeviceMac != 'No Device assigned') {
      deviceMacAddress = this.props.patientDeviceMac;
      deviceVisibility = '';
      noDeviceVisibility = ' hide';
    }

    //let showWeatherValue = this.props.patientShowWeather ? "checked" : "";

    return (
      <div className={'nucleus-patient-edit-pending nucleus-form-container ' + currentSection}>
        <div className="row">
          <div className="patient-edit-title-container">
            <p className="nucleus-page-title">{this.props.patientFirstName + ' ' + this.props.patientLastName}</p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a id={'edit-patient'} className="nucleus-font-small nucleus-link mt-10" onClick={this.props.handleProfileMode}>
              Cancel
            </a>
          </div>
          <div className="col s4 m3 l3">
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
              <style>
                {`
									.img-circle {
											border-radius: 50%;
									}
									.img-circle {
											border-radius: 0;
									}
									
									.ratio {
											background-position: center center;
											background-repeat: no-repeat;
											background-size: cover;
									
											height: 0;
											padding-bottom: 100%;
											position: relative;
											width: 100%;
									}
									.img-circle {
											border-radius: 50%;
									}
									.img-responsive {
											display: block;
											height: auto;
											max-width: 100%;
									}
									.inputfile {
											width: 0.1px;
											height: 0.1px;
											opacity: 0;
											overflow: hidden;
											position: absolute;
											z-index: -1;
									}
									.inputfile + label {
											margin-top:10px;
											font-size: 1.25em;
											// font-weight: 700;
											text-decoration: underline;
											color: gray;
											// background-color: black;
											display: inline-block;
									}
									
									.inputfile:focus + label,
									.inputfile + label:hover {
											cursor: pointer;
											// background-color: red;
									}
									.inputfile {
											cursor: pointer;
									}
									`
								}
              </style>
              <div style={{ width: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img
                  className=""
                  style={{ width: 140, height: 140, borderRadius: 70, objectFit: 'cover' }}
                  src={
                    this.state.clientImageFilePreviewURL
                      ? this.state.clientImageFilePreviewURL
                      : this.props.patientImageThumb
                        ? this.props.patientImageThumb
                        : 'img/user_placeholder.png'
                  }
                  ref={this.imgRef}
                  onError={() => (this.imgRef.current.src = 'img/user_placeholder.png')}
									alt='Profile'
                />
                <div>
                  <input
                    onChange={(e) => {
                      this.selectedPatientImageFile(e);
                    }}
                    multiple={false}
                    type="file"
                    name="file"
                    id="file"
                    className="inputfile"
                  />
                  <label htmlFor="file">Upload Photo</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col s8 m8 l6">
            <div className="row">
              <div className="col s4 valgin-wrapper">
                <span className=" nucleus-labels">First Name</span> <span className="nucleus-labels txt-red">*</span>
              </div>
              <div className="col s8" key={this.props.patientFirstName}>
                <input 
									id={'edit-firstname'} 
									ref={this.txtNewPatientFirstNameRef}
									type="text" 
									className="validate nucleus-input-form" 
									defaultValue={this.props.patientFirstName} 
								/>
              </div>
            </div>
            <div className="row">
              <div className="col s4 valgin-wrapper">
                <span className=" nucleus-labels">Last Name</span> <span className="nucleus-labels txt-red">*</span>
              </div>
              <div className="col s8" key={this.props.patientLastName}>
                <input 
									id={'edit-lastname'} 
									ref={this.txtNewPatientLastNameRef}
									type="text" 
									className="validate nucleus-input-form" 
									defaultValue={this.props.patientLastName} 
								/>
              </div>
            </div>

            <div className="row">
              <div className="col s4">
                <span className=" nucleus-labels"> Telephone</span>
              </div>
              <div className="col s8" key={this.props.patientTelephone}>
								<TelephoneInputUnderline
                  inputId="txtPatientTelephone"
                  value={this.state.patientTelephone}
                  onChange={this.onTelephoneInputChange}
                  onBlur={this.validateTxtInput}
                  style={{ marginLeft: 3 }}
                />
                <div>
                  <span className={'nucleus-form-error center-align ' + this.state.errorTelephoneVisibility}>{this.state.errorTelephoneText}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <span className=" nucleus-labels"> Address</span>
              </div>
              <div className="col s8" key={this.props.patientAddress}>
                <input 
									id={'edit-address'} 
									ref={this.txtNewPatientAddressRef}
									type="text" 
									className="validate nucleus-input-form" 
									defaultValue={this.props.patientAddress || ''} 
								/>
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <span className=" nucleus-labels"> Zip Code </span>
              </div>
              <div className="col s8" key={this.props.patientZipCode}>
                <input 
									id={'edit-zipcode'} 
									ref={this.txtNewPatientZipCodeRef}
									type="text" 
									className="validate nucleus-input-form" 
									defaultValue={this.props.patientZipCode || ''} 
								/>
              </div>
            </div>

            {/* 
							Removed on Feb 15, 2023
							<div class="row">
								<div class="col s4">
									<span class=" nucleus-labels"> Date of Birth</span>
								</div>
								<div class="col s8">
									<div style={{ width: 190, display: 'inline-flex' }}>
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												variant="dialog"
												class="browser-default"
												value={this.state.txtPatientDate}
												onChange={date => {
													this.setState({
														txtPatientDate: date ? new Date(date) : date
													});
												}}
												autoOk={true}
												onError={console.log}
												emptyLabel="MM/DD/YYYY"
												//minDate={new Date("2018-01-01T00:00")}
												format={"MM/DD/YYYY"}
											/>
										</MuiPickersUtilsProvider>
									</div>
									{
										(this.state.wrongDateInfo != "") &&
										<span class={"nucleus-form-error center-align "} > {this.state.wrongDateInfo}</span>
									}
								</div>
							</div>
							<br />
            */}
          </div>
          <div className="col s0 m1 l3"></div>
        </div>

        <div className="fixed-action-btn btn-add-new">
          <a
            id={'save-edit'}
            className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped "
            data-position="left"
            data-tooltip={'Save ' + AuthStore.getPatientLabel()}
            onClick={this.submitUpdateCarePatientForm}
            style={{ marginRight: 24, marginBottom: 12 }}
          >
            <i className="material-icons">save</i>
          </a>
        </div>
      </div>
    );
  }
}

export default PatientProfileEdit;
