import styled from 'styled-components';
import { ArrowIcon } from '../../../components/UI/assets/SVG';
import { IConsumerOrder } from '../Consumers';

const ColInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GoToShipStationCell = ({ row }) => {
  const { shipstationOrderUrl } = row.original as IConsumerOrder;
  const navigateToShipStation = () => {
    window.open(shipstationOrderUrl, '_blank');
  };
  return (
    <ColInfoContainer>
    {
      (shipstationOrderUrl) &&
      <ArrowIcon size={22} onClick={navigateToShipStation} />
    }
    </ColInfoContainer>
  );
};

export default GoToShipStationCell;
