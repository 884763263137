import React, { CSSProperties } from "react";
import Base, { ButtonProps } from "../Base";

export type ButtonVariant = "primary" | "secondary" | "danger" | "clear" | 'clear-danger' | 'disabled' | "legacy";

type StyleType = {
  containerStyle: CSSProperties;
  textStyle: CSSProperties;
};

const STYLES: Record<ButtonVariant, StyleType> = {
  primary: {
    containerStyle: {
      backgroundColor: "#0092FF",
      boxShadow: "0px 1px 2px 0px rgba(12, 18, 30, 0.05)",
    },
    textStyle: {
      color: "#fff",
    },
  },
  secondary: {
    containerStyle: {
      border: "1px solid #0092FF",
    },
    textStyle: {
      color: "#0092FF",
    },
  },
  disabled: {
    containerStyle: {
      backgroundColor: '#D8F0FE'
    },
    textStyle: {
      color: '#fff'
    }
  },
  danger: {
    containerStyle: {
      border: "1px solid #FF4B30",
    },
    textStyle: {
      color: "#FF4B30",
    },
  },
  clear: {
    containerStyle: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    textStyle: {
      color: "#0092FF",
    },
  },
  legacy: {
    containerStyle: {
      backgroundColor: "#0A313F",
      boxShadow: "0px 1px 2px 0px rgba(12, 18, 30, 0.05)",
    },
    textStyle: {
      color: "#fff",
    },
  },
  ['clear-danger']: {
    containerStyle: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    textStyle: {
      color: "#FF4B30",
    },
  },
};

type Props = ButtonProps & {
  variant?: ButtonVariant;
  showChevron?: boolean;
  numberOfLines?: number;
};

export const UIButton = (props: Props) => {
  const { variant = "primary" } = props;

  return (
    <Base
      {...props}
      numberOfLines={props.numberOfLines}
      containerStyle={{ ...STYLES[variant].containerStyle }}
      textStyle={STYLES[variant].textStyle}
    />
  );
};
