import React, { useState, useEffect } from 'react';
import NucleusModal from '../NucleusModal';
import DeleteActionModalContent from './DeleteActionModalContent';
import ActionSelect from './ActionsSelect';
import {
  deleteMdmDevice as deleteDeviceAction,
  resetDeleteMdmDeviceState,
} from '@nucleus-care/nucleuscare-backend-client';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../utils/Message';
import { useMdmDevices } from '../../context-api/mdmContext/MdmDevicesTableContext';

const MDMDevicesSelect = () => {
  const dispatch = useDispatch();
  const { state: mdmDevicesState } = useMdmDevices();
  const { deleteMdmDevice } = useSelector(({ mdmDevice }) => mdmDevice.data);
  const { data } = deleteMdmDevice;
  const { results: successDevices, failedResults: failedDevices } = data;
  const { error, success } = deleteMdmDevice;
  const [selectedValue, setSelectedValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const deviceOptions = [
    {
      name: 'Delete',
      value: 'delete',
    },
  ];
  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
    if (e.target.value === 'delete') {
      openModal(true);
    }
  };
  const openModal = (value) => {
    setIsOpen(value);
    if (value === false) {
      dispatch(resetDeleteMdmDeviceState());
      setSelectedValue('');
    }
  };
  const deleteDevices = () => {
    const devices = mdmDevicesState.selectedDevicesIds.map((mdmDeviceId) => ({
      MdmDeviceID: mdmDeviceId,
    }));
    dispatch(
      deleteDeviceAction({
        removeFromSystem: true,
        unassign: true,
        disenrollMdm: true,
        disenrollHexnode: false,
        devices: devices,
      }),
    );
    openModal(false);
  };
  useEffect(() => {
    if (success && failedDevices.length === 0) {
      Message.show('Deleted device successfully!');
    }
    if (success && successDevices.length > 0 && failedDevices.length > 0) {
      Message.show(
        'Some devices were deleted successfully, but some failed to delete.',
      );
    }
    if (success && successDevices.length === 0 && failedDevices.length > 0) {
      Message.show('Failed to delete devices');
    }
  }, [success]);
  useEffect(() => {
    if (error) {
      Message.show('Something went wrong deleting a device');
    }
  }, [error]);
  return (
    <React.Fragment>
      <ActionSelect
        handleSelectChange={handleSelectChange}
        deviceOptions={deviceOptions}
        selectedValue={selectedValue}
      />
      {isOpen && (
        <NucleusModal setIsOpen={openModal} width={650}>
          <DeleteActionModalContent
            failedDevices={failedDevices ?? []}
            successDevices={successDevices ?? []}
            selectedDevices={mdmDevicesState.selectedDevices}
            openModal={openModal}
            deleteDevices={deleteDevices}
          />
        </NucleusModal>
      )}
    </React.Fragment>
  );
};

export default MDMDevicesSelect;
