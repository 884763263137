import React from 'react';
import ReactDOM from "react-dom";
import moment from 'moment';

type RowParams = {
	data: Object;
    onClick: Function;
};


const AccountGroupRow = (props:RowParams) => {

    const onClicked = ()=>{
        props.onClick(props.data);
    }

    // let lastOnLineDate = "-";
    // if (props.data.LastLogin){
    //     lastOnLineDate = moment(props.data.LastLogin).format("MM/DD/YYYY hh:mm A");
    // }

    // let deviceStatusIcon = "img/icon_device_inactive.png";
    // if (props.data.LastLogin){
    //     deviceStatusIcon = "img/icon_device_active.png";
    // }
    return(
        <tr key={"classRow"} >
            <td style={{display:'flex'}}> 
                <span style={{ alignSelf: 'center'}}>
                    {props.data.Name }
                </span>
            </td>
            <td class="center-align" style={{wordWrap:'break-word'}}> 
                <span>{ props.data.Accounts || 0 }</span>
            </td>
            <td class="center-align" > 
                <span>{ props.data.Users || "0" }</span>
            </td>
            <td class="center-align">
                <span class="nucleus-table-icon nucleus-link" onClick={onClicked}>
                    <i class="Tiny material-icons">view_headline</i>
                </span>
            </td>
        </tr>
    );
}

export default AccountGroupRow;
