import React, {useState, useEffect, useRef} from 'react';
import CarePatientStore from '../stores/CarePatientStore';
import AuthStore from "../stores/AuthStore";
import Message from "../utils/Message";
import AccountUserPatientProfileDevicesItemRow from '../components/AccountUserPatientProfileDevicesItemRow';
import Select from "react-select";

const AccountUserPatientProfileDevices = ({ patientId, accountId}) => {
    const [patientDevicesData, setPatientDevicesData] = useState([]);
    const [assignableDevices, setAssignableDevices] = useState([]);
    const [assignableDevicesResponse, setAssignableDevicesResponse] = useState(false);
    const [patientDevicesDataResponse, setPatientDevicesDataResponse] = useState(false);
    const [deviceModeInstructionVisibility, setDeviceModeInstructionVisibility] = useState(" hide");
    const [deviceModeAssignVisibility, setDeviceModeAssignVisibility] = useState(" hide");
    const [divDeviceAssignVisibility, setDivDeviceAssignVisibility] = useState(" hide");
    const [divDevicesTableVisibility, setDivDevicesTableVisibility] = useState(" ");
    const [showDeviceControlsForAdmin, setShowDeviceControlsForAdmin] = useState(" ");
    const [deviceModeButtonTitle, setDeviceModeButtonTitle] = useState("Assign New");
    const [btnAddDeviceStyle, setBtnAddDeviceStyle] = useState("nucleus-submit-btn-small-disabled");
    const [btnAnotherDeviceStyle, setBtnAnotherDeviceStyle] = useState("nucleus-submit-btn-small-disabled");
    const [txtAnotherDeviceName, setTxtAnotherDeviceName] = useState("");
    const [txtDeviceName, setTxtDeviceName] = useState("");
    const [newDeviceMacID, setNewDeviceMacID] = useState("");
    const [notAssignedDevices, setNotAssignedDevices] = useState([]);
    const [macOption, setMacOption] = useState({});
    const [newDeviceMacLabel, setNewDeviceMacLabel] = useState("");
    const [newDevicePreassignedName, setNewDevicePreassignedName] = useState("");
    const [patientDeviceMac, setPatientDeviceMac] = useState("");
    const [updated, setUpdated] = useState(false);
    const [reloaded, setReloaded] = useState(false);
    const [patientTimezone, setPatientTimezone] = useState("");
    const [assignedDevice, setAssignedDevice] = useState(false);
    const [patientFullName, setPatientFullName] = useState("");


    useEffect(() => {

        CarePatientStore.on("onAssignDevicePatientAction", onAssignDevicePatientAction);
        CarePatientStore.on("onPatientGetData",onPatientGetDataAction);
        CarePatientStore.on("onGetDevicesAction",onGetDevicesAction)

        return () => {
            CarePatientStore.removeListener("onAssignDevicePatientAction", onAssignDevicePatientAction);
            CarePatientStore.removeListener("onPatientGetData", onPatientGetDataAction);
            CarePatientStore.removeListener("onGetDevicesAction", onGetDevicesAction);
        }
    }, []);
    


    // useEffect(() => {
    //     if(assignedDevice) {
    //         return;
    //     }
    //     CarePatientStore.on(
	// 		"onAssignDevicePatientAction", 
	// 	);
    //     return () => CarePatientStore.removeListener(
	// 		"onAssignDevicePatientAction",
	// 		() => {}
	// 	);
    // }, [assignedDevice])
    
    useEffect(() => {
        if(patientDevicesDataResponse) {
            return;
        }
        CarePatientStore.getPatientData({
            PatientID : patientId
        });
        
      }, [patientDevicesDataResponse]);

    useEffect(() => {
        console.log("UseEffect Rendering--->", patientDevicesData.length);
        
        if(patientDevicesData.length > 0) {
            if (deviceModeButtonTitle == "Assign New") {
                console.log("UseEffect Rendering---> A.1");
                setDeviceModeButtonTitle("Assign New");
                setDeviceModeInstructionVisibility(" ");
                setDeviceModeAssignVisibility (" hide");
                setDivDevicesTableVisibility(" ");
                setDivDeviceAssignVisibility(" hide");
                
            } 
            else if (deviceModeButtonTitle == "Cancel") {
                console.log("UseEffect Rendering---> A.2");
                setDeviceModeButtonTitle("Cancel");
                setDeviceModeInstructionVisibility(" hide");
                setDeviceModeAssignVisibility (" ");
                setDivDevicesTableVisibility(" ");

            }
        }
        else{
            

            if (deviceModeButtonTitle == "Assign New") {
                console.log("UseEffect Rendering---> B");
                setDeviceModeButtonTitle("Assign New");
                setDeviceModeInstructionVisibility(" ");
                setDeviceModeAssignVisibility (" hide");
                
                setDivDeviceAssignVisibility(" ");
    
            } 
            else if (deviceModeButtonTitle == "Cancel") {
                console.log("UseEffect Rendering---> C");
    
                setDeviceModeButtonTitle("Cancel");
                setDeviceModeInstructionVisibility(" hide");
                setDeviceModeAssignVisibility (" ");
                
            }
            setDivDevicesTableVisibility(" hide");
        } 
        
        /*if (patientDeviceMac == "Empty") {
            setDivDevicesTableVisibility(" hide");
            setDivDeviceAssignVisibility(" hide");
        } else if (
            patientDeviceMac == "No Device assigned" &&
            !updated
        ) {
            setDivDevicesTableVisibility(" hide ");
            setDivDeviceAssignVisibility(" ");
        } else {
            setDivDevicesTableVisibility(" ");
            setDivDeviceAssignVisibility(" hide");
        }*/
    });

    useEffect(() => {
        if(assignableDevicesResponse) {
            return;
        }
        CarePatientStore.getCareDevices({
            AccountID: accountId,
        });

    }, [assignableDevicesResponse]);


    const onPatientGetDataAction = (response) => {
        if(response) {
            setPatientDevicesData(response.Devices);
            setPatientTimezone(response.TimezoneString)
            setPatientFullName(response.FirstName + " " + response.LastName);
        } else {
            Message.show("Error getting patient devices, please try again.");
        }
        setPatientDevicesDataResponse(true);
        setReloaded(true);
    }

    const onGetDevicesAction =  (response) => {
        console.log("onGetDevicesAction", response);

        if (response.ok) {
            let availableDevices = [].concat(response.devices);
            availableDevices = availableDevices.sort((a, b) => {
                let textA = "";
                if (a.PreAssignedName) {
                    textA = a.PreAssignedName.toLowerCase();
                }
                else if (a.Mac) {
                    textA = a.Mac.toLowerCase();
                }
                else if (a.HexnodeID) {
                    textA = a.HexnodeID.toLowerCase();
                }

                let textB = "";
                if (b.PreAssignedName) {
                    textB = b.PreAssignedName.toLowerCase();
                }
                else if (b.Mac) {
                    textB = b.Mac.toLowerCase();
                }
                else if (b.HexnodeID) {
                    textB = b.HexnodeID.toLowerCase();
                }

                if (textA < textB)
                    //sort string ascending
                    return -1;
                if (textA > textB) return 1;
                return 0;
            });

            setAssignableDevices(availableDevices);
        } else {
            Message.show("There was a problem getting the available devices, please try again.");
        }
        setAssignableDevicesResponse(true);
    }

    const handlePanelAssignDevice = () => {
        console.log("handlePanelAssignDevice");

		if (deviceModeButtonTitle == "Assign New") {

            console.log("handlePanelAssignDevice = 1");
            //setPatientDevicesData([]);
            setDeviceModeButtonTitle("Cancel");
            setDeviceModeInstructionVisibility(" hide");
            setDeviceModeAssignVisibility(" ");
            setBtnAddDeviceStyle("nucleus-submit-btn-small-disabled");
            setBtnAnotherDeviceStyle("nucleus-submit-btn-small-disabled");
			setTxtAnotherDeviceName("");
			setTxtDeviceName("");
			setNewDeviceMacID("");
			setNewDeviceMacLabel("");
			setNewDevicePreassignedName("");
            setPatientDevicesDataResponse(false);
            
			/*setTimeout(() => {
                this.refs.txtAnotherDeviceName.focus();
				this.refs.txtDeviceName.focus();
			}, 100);*/
            
		}
		else if (deviceModeButtonTitle == "Cancel") {
            console.log("handlePanelAssignDevice = 2");
            setDeviceModeButtonTitle("Assign New");
            //setDeviceModeInstructionVisibility(" hide");
            
            setDeviceModeInstructionVisibility(" ");
            setDeviceModeAssignVisibility (" hide");
            
            setDivDeviceAssignVisibility(" ");
            
            setTxtAnotherDeviceName("");
			setTxtDeviceName("");
			setNewDeviceMacLabel("");
			setNewDevicePreassignedName("");
            setPatientDevicesDataResponse(false);
            
		}
    }

    useEffect(() => {
        if(assignableDevicesResponse) {
            if (assignableDevices != null && assignableDevices.length > 0) {

                assignableDevices.map((device, key) => {
                    let label2 = (device.HexnodeID != null) ? (" - " + device.HexnodeID) : "";
                    let label = device.PreAssignedName
                        ? device.PreAssignedName + label2
                        : device.Mac;

                    setMacOption({ value: device.ID, label });
                    setNotAssignedDevices(notAssignedDevices => [...notAssignedDevices, { value: device.ID, label }]);
                    //availableDevicesList.push(<option key={device.ID} value={device.ID} tag={device.Mac}>{device.Mac}</option>);
                });
            } else{
                setNotAssignedDevices(notAssignedDevices => [...notAssignedDevices, {
                    value: null,
                    label: "No available devices..."
                }]);
            }
        }
    }, [assignableDevicesResponse])

    const handleAddDeviceName = (e)  => {
		console.log("handleAddDeviceName", newDeviceMacID);
		if (
			!(newDeviceMacID == "") &&
			txtDeviceName.length > 0
		) {
            setBtnAddDeviceStyle("nucleus-submit-btn-small")
		} else {
            setBtnAddDeviceStyle("nucleus-submit-btn-small-disabled");
		}

		if (!(newDeviceMacID == "")) {
			//this.refs.txtDeviceName.focus();
		}
        setTxtDeviceName(e.target.value);
	}

    useEffect(() => {
        console.log("handleAddDeviceName", newDeviceMacID);
		if (
			!(newDeviceMacID == "") &&
			txtDeviceName.length > 0
		) {
            setBtnAddDeviceStyle("nucleus-submit-btn-small")
		} else {
            setBtnAddDeviceStyle("nucleus-submit-btn-small-disabled");
		}

		if (!(newDeviceMacID == "")) {
			//this.refs.txtDeviceName.focus();
		}

    }, [newDeviceMacID])

    const handleSelectNewDeviceMac = (selectedOption) => {
		console.log("Option selected:", selectedOption);
		let preAssignedName = "";
        notAssignedDevices.forEach((device, key) => {
			if (selectedOption && device.value == selectedOption.value) {
				console.log("deviceSelected", device);
				preAssignedName = device.label;
			}
		});
		if (selectedOption && selectedOption.value) {
            console.log("entered here");
            setNewDeviceMacID(selectedOption.value);
			setNewDeviceMacLabel(selectedOption.label);
			setNewDevicePreassignedName(preAssignedName);
			if (txtDeviceName)
				setTxtDeviceName(preAssignedName);
                setBtnAddDeviceStyle("nucleus-submit-btn-small");
                console.log(preAssignedName)
			//this.refs.selectDeviceMacAddress.value = selectedOption.value;
		} else {
			//this.refs.selectDeviceMacAddress.value = "";
			setNewDeviceMacID("");
			setNewDeviceMacLabel("");
		}
		handleAddDeviceName({
			target: {
				value: preAssignedName,
			},
		});
	}



    const handleSelectAnotherDeviceMac = (selectedOption)  => {
		console.log("Option selected:", selectedOption);
		let preAssignedName = "";
        console.log("devices data: ", notAssignedDevices)
		notAssignedDevices.forEach((device, key) => {
			if (selectedOption && device.value == selectedOption.value) {
				console.log("deviceSelected", device);
				preAssignedName = device.label;
			}
		});
		if (selectedOption && selectedOption.value) {
            console.log("entered here");
			setNewDeviceMacID(selectedOption.value);
			setNewDeviceMacLabel(selectedOption.label);
			setNewDevicePreassignedName(preAssignedName);
			if (txtAnotherDeviceName)
				setTxtAnotherDeviceName(preAssignedName);
                console.log(preAssignedName)
			//this.refs.selectDeviceMacAddress.value = selectedOption.value;
		} else {
			//this.refs.selectDeviceMacAddress.value = "";
			setNewDeviceMacID("");
			setNewDeviceMacLabel("");
			setNewDevicePreassignedName("");
		}
		handleAddAnotherDeviceName({
			target: {
				value: preAssignedName,
			},
		});

	}

    useEffect(() => {
        if(newDeviceMacID !== "" ) {
            setBtnAnotherDeviceStyle("nucleus-submit-btn-small");
        } else {
            setBtnAnotherDeviceStyle("nucleus-submit-btn-small-disabled");

        }
    }, [newDeviceMacID])

    const handleAddAnotherDeviceName = (e) => {
		console.log("handleAddAnotherDeviceName", newDeviceMacID);
		if (
			!(newDeviceMacID == "") &&
			txtAnotherDeviceName.length > 0
		) {
            setBtnAnotherDeviceStyle("nucleus-submit-btn-small");
		} else {
            setBtnAnotherDeviceStyle("nucleus-submit-btn-small-disabled");
		}
		if (!(newDeviceMacID == "")) {
			//this.refs.txtAnotherDeviceName.focus();
		}
        setTxtAnotherDeviceName(e.target.value);
	}

    const handleSelectDeviceOpens = () => {
		console.log("handleSelectDeviceOpens");
		//let thisMoment = new Date();
		//let minutesPassed = (thisMoment - this.lastDevicesUpdateTime) / 1000 / 60;
		//if (minutesPassed > 0.5) {
			CarePatientStore.getCareDevices({
				AccountID: AuthStore.getUserAccountID(),
			});
			//this.lastDevicesUpdateTime = thisMoment;
		//}
	}

    const handleAssignDevice = () => {
		console.log("handleAssignDevice");
		console.log("handleAssignDevice ID", newDeviceMacID);
		console.log("handleAssignDevice MAC", newDeviceMacLabel);
		console.log("handleAssignDevice PreAssignedName", newDevicePreassignedName);

		CarePatientStore.assignDeviceToPatient({
			PatientID: patientId,
			DeviceID: newDeviceMacID,
			DeviceName: txtDeviceName
		});

		setNewDevicePreassignedName(txtDeviceName);
        setBtnAddDeviceStyle("nucleus-submit-btn-small-disabled");
        setTxtAnotherDeviceName("");
	}


    const handleAssignAnotherDevice = () => {
		console.log("handleAssignAnotherDevice");
		console.log("handleAssignAnotherDevice ID", newDeviceMacID);
		console.log("handleAssignAnotherDevice MAC", newDeviceMacLabel);

		CarePatientStore.assignDeviceToPatient({
			PatientID: patientId,
			DeviceID: newDeviceMacID,
			DeviceName: txtAnotherDeviceName
		});

        setTimeout(() => {
            handlePanelAssignDevice();
        }, 1000);

		//this.newDevicePreassignedName = this.refs.txtAnotherDeviceName.value;
        setNewDevicePreassignedName(txtAnotherDeviceName);
        setBtnAnotherDeviceStyle( "nucleus-submit-btn-small-disabled");
        setTxtAnotherDeviceName("");
	}

    const onAssignDevicePatientAction = (response) => {
        console.log("onAssignDevicePatientAction", response);
        setPatientDevicesDataResponse(false);
        if (response.ok) {
            setPatientDevicesDataResponse(false);
            setAssignableDevicesResponse(false);
            setPatientDeviceMac(newDeviceMacLabel);
            setUpdated(true);
            setBtnAddDeviceStyle("nucleus-submit-btn-small");
            setBtnAnotherDeviceStyle("nucleus-submit-btn-small");
            //setDivDeviceAssignVisibility(" hide");
            setMacOption({});
            //setNotAssignedDevices([]);
            //setAssignableDevices([]);
            setTxtDeviceName("");
            setTxtAnotherDeviceName("");

            
            setDeviceModeButtonTitle("Assign New");
            //setDeviceModeInstructionVisibility(" hide");
            
            setDeviceModeInstructionVisibility(" ");
            setDeviceModeAssignVisibility (" hide");
            
            setDivDeviceAssignVisibility(" ");
            
            setTxtAnotherDeviceName("");
			setTxtDeviceName("");
			setNewDeviceMacLabel("");
			setNewDevicePreassignedName("");
            setPatientDevicesDataResponse(false);

            Message.show(
                "Device " + 
                newDevicePreassignedName + 
                "was successfully assigned" +
                "."
            );
            //this.refs.selectAnotherDeviceName.value = ""
            //this.selectAnotherDeviceName.state.value.value = ""
        } else {
            Message.error("There was a problem getting the available devices, please try again.");
        }
        setAssignedDevice(true);
    }

    let selectDeviceCaption = notAssignedDevices.length > 0
			? "Mac/HexnodeID..."
			: "No available devices";

    return(
        <React.Fragment>
            <h3 class="new-nucleus-page-subtitle" onClick={() => {}/*this.refreshDevices*/} style={{cursor:"pointer"}}>
				{" "} Device(s) ({patientDevicesData.length > 0 ? patientDevicesData.length : "0"})
			</h3>
            {
                /*(this.state.loadingDevices) &&
                <span class={"row"} style={{marginLeft:15, marginTop:2, position:'fixed'}}>
                    <Spinner
                        size={12}
                        speed={0.6}
                        animating={true}
                        style={{ display: 'inline-flex' }}
                    />
                </span>*/
            }
            
            <div class={"devices-container " + divDevicesTableVisibility}>
                <div class="row no-margin">
                    <div class="assignments-table-pending table-scroll-space">
                        {/* <div class="assignments-table-pending nucleus-mini-table-container" > */}
                        <table class="bordered highlight nucleus-table-small">
                            <thead>
                                <tr>
                                    <th class="table-col-4 left-align ">
                                        <span class="nucleus-table-header-small"></span>
                                    </th>
                                    <th class="table-col-18 left-align ">
                                        <span class="nucleus-table-header-small">
                                            Device Name
                                        </span>
                                    </th>
                                    <th class="table-col-25 left-align ">
                                        <span class="nucleus-table-header-small">
                                            Connected On
                                        </span>
                                    </th>
                                    {/* <th class="table-col-11 center-align">
                                        <span class="nucleus-table-header-small">
                                            Room Temp / Humidity
                                        </span>
                                    </th> */}
                                    <th class="table-col-6 center-align">
                                        <span class="nucleus-table-header-small">Batt.</span>
                                    </th>
                                    <th class="table-col-8 center-align">
                                        <span class="nucleus-table-header-small">Version</span>
                                    </th>
                                    <th class="table-col-7 left-align">
                                        <span class="nucleus-table-header-small"></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    patientDevicesData.map((device, key) =>(
                                        <AccountUserPatientProfileDevicesItemRow
                                            index={key}
                                            key={key}
                                            ID={device.DeviceID}
                                            Mac={device.DeviceMac}
                                            HexnodeID={device.HexnodeID}
                                            DeviceUID={device.DeviceUID}
                                            Name={device.Name}
                                            Battery={device.Battery}
                                            Label={device.Label}
                                            //defaultCallShortcut={this.state.patient}
                                            // Serial={device.Serial} DEPRECATED March 2022
                                            // IP={device.DeviceInfo.IP} DEPRECATED March 2022
                                            LastOnline={device.DeviceInfo.LastCheckinTime}
                                            Online={device.DeviceInfo.Online}
                                            Version={device.DeviceInfo.Version}
                                            //ConnectDevice={true}
                                            //Temperature={device.DeviceInfo.Temp}
                                            //Humidity={device.DeviceInfo.Humidity}
                                            DeviceInfo={device.DeviceInfo}

                                            //currentUserAdmin={AuthStore.canDeleteDevices()} // this.props.currentUserAdmin ||
                                            Volume={device.RingtoneVolume}
                                            Concierge={device.Concierge}
                                            //AutoAnswerAllowed={autoAnswerAllowed}
                                            TimeZone={patientTimezone}
                                            //TimezoneOffset={this.state.patientTimezoneOffset}

                                            //restartDeviceAccess={restartAccess}
                                            //UserBargeInEnabled={userBargeInEnabled}
                                            OS={device.OS}
                                        />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <br />
                <div class={ "row " + deviceModeInstructionVisibility + showDeviceControlsForAdmin}>
                    <div class="col s12 no-margin">
                        {"Assign another device to this " + AuthStore.getPatientLabel()}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a
                            id="device-assign-more"
                            class="txt-white nucleus-font-small nucleus-submit-btn-small"
                            onClick={handlePanelAssignDevice}
                        >
                            {deviceModeButtonTitle}
                        </a>
                    </div>
                </div>
                <div class={"no-margin " + deviceModeAssignVisibility + showDeviceControlsForAdmin}>
                    <div class="row no-margin">
                        <div class="col s12 no-margin">
                            {"Select the mac address of the " +
                                AuthStore.getPatientLabel().toLowerCase() +
                                " new device and give a name to the device."}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a
                                class="txt-white nucleus-font-small nucleus-link"
                                onClick={handlePanelAssignDevice}
                            >
                                {deviceModeButtonTitle}
                            </a>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 m4 l3 no-margin">
                            {
                                (deviceModeButtonTitle == "Cancel") &&
                                <Select
                                    id={'select-more-device'}
                                    defaultValue=""
                                    //name={c => (selectAnotherDeviceName = c)}
                                    className="browser-default select-device-mac selectStyle"
                                    placeholder={selectDeviceCaption}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={handleSelectAnotherDeviceMac}
                                    onMenuOpen={handleSelectDeviceOpens}
                                    closeMenuOnSelect={true}
                                    options={notAssignedDevices}
                                />
                            }
                           {/*<select name="selectAnotherDeviceMacAddress" class="browser-default select-device-mac" onChange={handleAddAnotherDeviceName}>
                                <option value="" disabled selected> { selectDeviceCaption } </option>
                                { availableDevicesList }
                            </select>*/}
                        </div>
                        <div class="col s8 m8 l9 ">
                            <input
                                id={"device-assign-more-name"}
                                name="txtAnotherDeviceName"
                                type="text"
                                value={txtAnotherDeviceName}
                                class="validate nucleus-input-form txt-device-mac"
                                placeholder="Click to name this device"
                                onChange={handleAddAnotherDeviceName}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a
                                id="assign-more-device-submit"
                                class={"txt-white nucleus-font-small " + btnAnotherDeviceStyle}
                                onClick={handleAssignAnotherDevice}
                            >
                                Submit
                            </a>
                        </div>
                    </div>
                    <div class="row a-bit-lower">
                        <div class="col s4 m4 l3 ">
                            {" "}
                            <span class="nucleus-font-small">
                                Select from the dropdown list or filter the selection by typing the MAC address
                            </span>
                        </div>
                        <div class="col s6 m6 l7 ">
                            <span class="nucleus-font-small">
                                &nbsp;&nbsp;&nbsp;&nbsp;For example: Living Room
                            </span>
                        </div>
                        <div class="col s6 m6 l7 ">
                            <span class="nucleus-font-small">
                                &nbsp;&nbsp;&nbsp;&nbsp;This name will show on the device main screen
                            </span>
                        </div>
                    </div>
                </div>
                <br/>

            </div>
            
<div class={"a-bit-lower " + divDeviceAssignVisibility + showDeviceControlsForAdmin}>
                <div class={"row" + deviceModeInstructionVisibility}>
                    <div class="col s12 no-margin">
                        <span style={{marginRight:36}}>
                            {"No Device assigned"}
                        </span>
                        
                        <a
                            id={'assign-device'}
                            class="txt-white nucleus-font-small nucleus-submit-btn-small"
                            onClick={handlePanelAssignDevice}
                        >
                            {deviceModeButtonTitle}
                        </a>
                    </div>
                </div>

                <div class={"no-margin " + deviceModeAssignVisibility}>
                    <div class="row no-margin">
                        <div class="col s12 no-margin">
                            <span style={{marginRight:36}}>
                            {"Select the mac address of the " +
                                AuthStore.getPatientLabel().toLowerCase() +
                                " new device and give a name to the device."}
                            </span>
                            <a
                                class="txt-white nucleus-font-small nucleus-link"
                                onClick={handlePanelAssignDevice}
                            >
                                {deviceModeButtonTitle}
                            </a>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col s4 m4 l3 no-margin">
                            <Select
                                id={'select-device'}
                                defaultValue=""
                                className="browser-default select-device-mac selectStyle"
                                placeholder={selectDeviceCaption}
                                isSearchable={true}
                                isClearable={true}
                                onChange={handleSelectNewDeviceMac}
                                onMenuOpen={handleSelectDeviceOpens}
                                options={notAssignedDevices}
                            />
                            { /*
                            <select name="selectDeviceMacAddress" class="browser-default select-device-mac hide" onChange={handleAddDeviceName}>
                                <option value="" disabled selected> { selectDeviceCaption } </option>
                                { availableDevicesList }
                            </select> */}
                        </div>
                        <div class="col s6 m6 l7 ">
                            <input
                                id="handle-add-device-name"
                                name="txtDeviceName"
                                type="text"
                                value={txtDeviceName}
                                class="validate nucleus-input-form txt-device-mac"
                                placeholder="Click to name this device"
                                onChange={handleAddDeviceName}
                            />
                            <span style={{marginRight:36}}></span>
                            <a
                                id={'submit-device'}
                                class={
                                    "txt-white nucleus-font-small " +
                                    btnAddDeviceStyle
                                }
                                onClick={handleAssignDevice}
                            >
                                Submit
                            </a>
                        </div>
                    </div>
                    <div class="row a-bit-lower">
                        <div class="col s4 m4 l3 no-margin">
                            {" "}
                            <span class="nucleus-font-small">
                                Select from the dropdown list or filter the selection by
                                typing the MAC address
                            </span>
                        </div>
                        <div class="col s6 m6 l7 no-margin">
                            <span class="nucleus-font-small">
                                &nbsp;&nbsp;&nbsp;&nbsp;For example: Living Room
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default AccountUserPatientProfileDevices
