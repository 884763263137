import React from 'react';
import ReactDOM from "react-dom";
import CareAccountStore from "../stores/CareAccountStore";
import Message from "../utils/Message";
import AuthStore from "../stores/AuthStore";
import CareFamilyStore from "../stores/CareFamilyStore";
import Dispatcher from "../Dispatcher";
import moment from 'moment';
import { SpinnerCircular } from 'spinners-react';

import FamilyMemberItemRow from '../components/FamilyMemberItemRow';

class Family extends React.Component {
	
	constructor() {
        super();

        this.state = {
        	familyMembersArray : [],
            loading :false,
            results :false,
            emptyError:false,
            searchText: "",
        };

        this.rowClicked = this.rowClicked.bind(this);
        this.validateFieldsFromKeyboard = this.validateFieldsFromKeyboard.bind(this);
        this.onSubmitSearch = this.onSubmitSearch.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.handleSearchText = this.handleSearchText.bind(this);
        this.onGetFamilyMembersDataAction = this.onGetFamilyMembersDataAction.bind(this);
        this.cleanStore = true;
    }

    componentDidMount() {

        if (!AuthStore.superUser()){
            window.location.assign("#/dashboard");
        }
        CareAccountStore.on("onGetFamilyMembersData", this.onGetFamilyMembersDataAction);
    	let searchedText = CareFamilyStore.getCachedSearchText();
        if (searchedText != ""){
            this.refs.txtSearch.value = searchedText;
            this.state.searchText = searchedText;
            this.onSubmitSearch();
        }
    }

    componentWillUnmount(){
    	CareAccountStore.removeListener("onGetFamilyMembersData", this.onGetFamilyMembersDataAction);
        if (this.cleanStore){
            CareFamilyStore.setCachedSearchText("");
        }
        else if (this.state.searchText != ""){
            CareFamilyStore.setCachedSearchText(this.state.searchText);
        }
    }

    handleSearchText(e) {
		this.setState({
			searchText: e.target.value,
            emptyError :false
		});
	}

    validateFieldsFromKeyboard(e){
        if ((e.charCode == 13) || (e.keyCode == 13) || (e.key == 13) ){
            this.onSubmitSearch();
        }   
    }

	onGetFamilyMembersDataAction(response){
		console.log("onGetFamilyMembersDataAction");
        console.log(response);

        this.setState({
            loading:false
        });
		if (response.ok){
            if (response.FamilyMembers && response.FamilyMembers.length > 0){
                this.setState({
                    familyMembersArray : response.FamilyMembers
                });
            }
            else{
                Message.show("There is no Family Member registered with:  '" + this.state.searchText + "'");
            }
			
		}  else {
			Message.show("There was an error getting the data");
		}
	}

    onSubmitSearch(){
        console.log("onSubmitSearch >" + this.state.searchText + "<");
        if (this.state.searchText == ""){
            this.setState({
                emptyError :true
            });
        }
        else {
            this.setState({
                loading :true,
                familyMembersArray:[]
            });
            let searchText = "" + this.state.searchText;
            searchText = searchText.trim();

            CareAccountStore.getAllFamilyMembers({
                CsUserID : AuthStore.getCsUserID(),
                SearchText : searchText
            });
        }
    }

    onClearSearch(){
        this.setState({
            loading :false,
            searchText: "",
            familyMembersArray:[]
        });
        this.refs.txtSearch.value = "";
        this.refs.txtSearch.focus();
    }

    rowClicked(){
        this.cleanStore = false;
    }

	render(){
        let tableResultsView = ' hide';
		let tableLoadingView = '';
		if (this.state.loading){
			tableResultsView = ' hide';
			tableLoadingView = '';
		} else {
			tableResultsView = '';
			tableLoadingView = 'hide';
		}

        let familyMembersList = [];
		if (this.state.familyMembersArray != null) {
            this.cleanStore = true;
            //if (this.state.searchText == ""){
                this.state.familyMembersArray.map((family, key) => {
                    familyMembersList.push(
                        <FamilyMemberItemRow
                            key={key}
                            MemberID={family.ID}
                            Name={family.Name}
                            Email={family.Email}
                            Telephone={family.Telephone}
                            //ReceiveUpdates={family.ReceiveUpdates}
                            ProfileCreated={family.ProfileCreated}
                            AccountAutoAnswer={family.AccountAutoAnswer}
                            AutoAnswer={family.AutoAnswer}
                            FamilyAppInfo={family.FamilyAppInfo}
                            VerificationCode={family.VerificationCode}
                            onRowClicked={this.rowClicked}
                        />
                    );
                });
            // }
			// else {
            //     let textToSearch = this.state.searchText.toLowerCase();

            //     this.state.familyMembersArray.map((family, key) => {

            //         if ((family.Name.indexOf(textToSearch) >= 0) ||
            //         (family.Telephone && family.Telephone.toLowerCase().indexOf(textToSearch) >= 0) ||
            //         (family.Email && family.Email.toLowerCase().indexOf(textToSearch) >= 0)){

            //             familyMembersList.push(
            //                 <FamilyMemberItemRow
            //                     key={key}
            //                     MemberID={family.ID}
            //                     Name={family.Name}
            //                     Email={family.Email}
            //                     Telephone={family.Telephone}
            //                     //ReceiveUpdates={family.ReceiveUpdates}
            //                     ProfileCreated={family.ProfileCreated}
            //                     AccountAutoAnswer={family.AccountAutoAnswer}
            //                     AutoAnswer={family.AutoAnswer}
            //                     VerificationCode={family.VerificationCode}
            //                 />
            //             );
            //         }
                    
            //     });
            // }
		}

		let errorMessage = this.state.errorMessage;
        
		return(
			 <div class="nucleus-tools-container">
                <p class="nucleus-tools-page-title"><b>Family</b></p>

                <div class={"row "}>
                    <div class={"no-margin "}>
                        <div class="row no-margin a-bit-lower">
                            <div class="col s9 m8 no-margin left-align ">
                                <span class="nucleus-tools-page-text-title a-bit-lower">
                                    Search &nbsp;&nbsp;&nbsp;
                                </span>
                                <input
                                    ref="txtSearch"
                                    type="text"
                                    class="nucleus-input-form-small-left"
                                    onChange={this.handleSearchText}
                                    placeholder="Name / Phone / Email"
                                    onKeyPress={this.validateFieldsFromKeyboard }
                                />
                                &nbsp;&nbsp;&nbsp;
                                <a class="txt-white nucleus-font-small nucleus-submit-btn-tools" onClick={this.onSubmitSearch}> Submit </a>
                            </div>
                            <div class="col s3 m4 no-margin right-align " style={{paddingRight:25}}>
                                <a class="txt-white nucleus-font-small nucleus-submit-btn-tools" onClick={this.onClearSearch}>Clear</a>
                            </div>
                        </div>
                        <div class="row no-margin a-bit-lower">
                            <div class="col s12 no-margin left-align ">
                                {
                                    (this.state.emptyError) &&
                                    <span class="nucleus-labels-height txt-red">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        Please type something
                                    </span>
                                }
                            </div>
                        </div>
                        <br />
                    </div>
                    
                    <table class="bordered highlight  nucleus-table">
                        <thead>
                            <tr>
                                <th class="table-col-30 left-align">
                                    <span class="nucleus-table-header-small">Name</span>
                                </th>
                                <th class="table-col-24 left-align">
                                    <span class="nucleus-table-header-small">Email</span>
                                </th>
                                <th class="table-col-18 left-align">
                                    <span class="nucleus-table-header-small">Telephone</span>
                                </th>
                                {/* <th class="table-col-12 left-align">
                                    <span class="nucleus-table-header-small">App Version</span>
                                </th> */}
                                <th class="table-col-7 center-align">
                                    <span class="nucleus-table-header-small">Status</span>
                                </th>
                                <th class="table-col-18 center-align">
                                    <span class="nucleus-table-header-small">Verification Code</span>
                                </th>
                                <th class="table-col-3 center-align">
                                    <span class="nucleus-table-header-small"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>{familyMembersList}</tbody>
                    </table>

                </div>

                <div class={"row center-align " + tableLoadingView}>
                    <br />
                    <br />
                    <br />
                    <SpinnerCircular 
                        color="#2096F3"
                        secondaryColor='rgba(0,0,0,0.16)'
                        size='50'
                        thickness='100'
                        />
                    
                    <br />
                    <br />
                    <br />
                </div>

            </div>
		);
	}
}

export default Family;