import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPolicyJSONProperties,
  removePolicyJSONProperty,
} from '@nucleus-care/nucleuscare-backend-client';
import NucleusCheckBox from "../NucleusCheckBox";

const MDMNucleusCheckBox = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  error,
  onFocus,
  activeBadge,
  onClickClear,
  setPropertyValue,
  policyDetails,
  operationType,
  objectValue,
  propertyValue,
  customValue,
  hideClear,
  disabled,
}) => {
  let { policyJSON, policyFormStatus } = useSelector(({ mdmPolicy }) => mdmPolicy.state);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(false);
  useEffect(() => {
    if(customValue || value){
      setInputValue(value);
      return;
    }
    if (policyJSON && [name] in policyJSON) {
      setInputValue(!!policyJSON[name]);
    }
  }, [policyJSON, name, value]);
  //let parsedDetails =  policyDetails.PolicyTemplate && JSON.parse(policyDetails.PolicyTemplate.PolicyDetails);
  return (
    <NucleusCheckBox
      hideClear={!([name] in policyJSON)}
      labelMinWidth={320}
      name={name}
      id={name}
      checked={inputValue}
      onChange={(e) => {
        if (e.target.checked) {
          if (setPropertyValue) {
            setPropertyValue(true);
            setInputValue(true);
            return;
          }
          dispatch(addPolicyJSONProperties({ [name]: true }));
          setInputValue(true);
        } else {
          if (setPropertyValue) {
            setPropertyValue(false);
            setInputValue(false);
            return;
          }
          dispatch(addPolicyJSONProperties({ [name]: false }));
          setInputValue(false);
        }
      }}
      onFocus={() => {
        if (setPropertyValue) {
          setPropertyValue();
          return;
        }
        dispatch(addPolicyJSONProperties({ [name]: "" }));
      }}
      onClickClear={() => {
        if (onClickClear) {
          onClickClear();
          setInputValue(false);
          return;
        }
        dispatch(removePolicyJSONProperty(name));
        setInputValue(false);
      }}
      activeBadge={activeBadge || [name] in policyJSON}
      label={label}
      placeholder={placeholder}
      disabled={disabled || !policyFormStatus.isEditable}
    />
  );
};

export default MDMNucleusCheckBox;
